import {HttpInterceptor, HttpRequest, HttpResponse} from "@gsb/react-http";
import AuthService, {AuthenticationResponse} from "@gsb/react-auth";

export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
                private onUnauthorized: (res: HttpResponse) => void,
                private onAuthResponse: (res?: AuthenticationResponse) => void) {}

    willSendRequest = async (req: HttpRequest) => {
        try {
            const token = await this.authService.getToken();

            if (token && token.accessToken) {
                req.headers.set('Authorization', 'Bearer ' + token.accessToken)
            }
        } catch (err) {
            console.warn(err);
        }

        return req
    };

    willHandleRetry = (req: HttpRequest, res: HttpResponse) => {
        if (res.status === 401) {
            console.log('Attempting retry...');
            return this.authService.getToken()
                .then(this.onAuthResponse)
                .catch(err => {
                console.log('Retry failed...');
                console.warn(err);
                this.onUnauthorized(res)
            });
        }
    };

    willHandleResponse = async (res: HttpResponse) => {
        console.log(JSON.stringify(res));
        return res;
    };
}