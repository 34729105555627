import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {CreateSendGridAccountRequest, SendGridAccount, UpdateSendGridAccountRequest} from "../../../../models/SendGridAccount";
import {initialValuesForSendGridAccount, createSendGridAccountSchema, updateSendGridAccountSchema} from "../../../../utils/schemas/sendgrid-account-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {CreateSendGridAccountFields, UpdateSendGridAccountFields} from "./SendGridFields";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdateSendGridAccountForm(props: { sendgridAccount: SendGridAccount, onSendGridAccountUpdated: (sendgridAccount: SendGridAccount) => void }) {
    const { sendgridAccount, onSendGridAccountUpdated }  = props;
    const { sendgridAccountRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.sendgridAccount.update);

    const onSubmit = updateSubmitHandler<UpdateSendGridAccountRequest, SendGridAccount>(
        body => sendgridAccountRepo.update(sendgridAccount.id, body),
        initialValuesForSendGridAccount,
        onSendGridAccountUpdated,
        console.error,
    );

    return (
        <Formik<UpdateSendGridAccountRequest> enableReinitialize={true} initialValues={initialValuesForSendGridAccount(sendgridAccount)} validationSchema={updateSendGridAccountSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateSendGridAccountFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateSendGridAccountForm(props: { onSendGridAccountCreated: (sendgridAccount: SendGridAccount) => void }) {
    const { onSendGridAccountCreated }  = props;
    const { sendgridAccountRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateSendGridAccountRequest) => sendgridAccountRepo.create(body),
        onSendGridAccountCreated,
        console.error
    );

    return (
        <Formik<CreateSendGridAccountRequest> initialValues={initialValuesForSendGridAccount()} validationSchema={createSendGridAccountSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateSendGridAccountFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}


export const UpdateTemplateModal = withModal(UpdateSendGridAccountForm, "Update SendGrid Account");
export const CreateTemplateModal = withModal(CreateSendGridAccountForm, "Create SendGrid Account");