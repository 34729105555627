import React from "react";
import { Form, Formik } from "formik";
import { VerticalFormLayout, InlineFormLayout } from "../../../../form/FormLayout";
import { FormSubmitButton } from "../../../../form/buttons/FormSubmitButton";
import { useRepo } from "../../../../../repos/useRepo";
import { updateSubmitHandler } from "../../../../form/helpers/formSubmit";
import { AdpEventBody } from "../../../../../repos/adp/AdpRepo";
import AdpEventFormFields from "./AdpEventFormFields";
import { useRbac } from "@gsb/react-rbac";
import { adpEventSchema } from "./adp-event-schema";
import { actions } from "../../../../../rbac";
import AdpProductPicker from "../../../../form/pickers/AdpProductPicker";

interface Props {
    onSuccess?: () => void;
}

export default function AdpEventForm({ onSuccess }: Props) {
    const { can } = useRbac();
    const { adpRepo } = useRepo();

    const disabled = !can(actions.adpSubscription.activate) || !can(actions.adpSubscription.cancel);

    const initialValues: AdpEventBody = { eventUrl: '', appId: '' };

    const onSubmit = updateSubmitHandler(
        (body: AdpEventBody) => adpRepo.activeOrCancelAdpSubscription(body),
        () => initialValues,
        onSuccess,
        console.error,
    );

    return (
        <Formik<AdpEventBody> initialValues={initialValues}
            validationSchema={adpEventSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            <Form>
                <InlineFormLayout fieldset={
                    <>
                        <AdpProductPicker name={'appId'} disabled={disabled} />
                        <AdpEventFormFields disabled={disabled} />
                    </>
                }
                    bottomContent={<FormSubmitButton variant='Update' label='Submit' />} />
            </Form>
        </Formik>
    )
}