import * as React from "react";
import {useState} from "react";
import { StateApplication } from "../../models/sid/StateApplication";
import {UpdateStateApplicationModal} from "../entities/state-id/settings/state-application/StateApplicationForm";

export default function useUpdateStateApplicationModal(onStateApplicationCreated: (stateApplication: StateApplication) => void) {

    const [stateApplication, setStateApplication] = useState<StateApplication>();

    const handleStateApplicationUpdated = (stateApplication: StateApplication) => {
        setStateApplication(undefined);
        onStateApplicationCreated(stateApplication)
    };

    const modal = stateApplication ? (
        <UpdateStateApplicationModal
            open={true}
            onClose={() => setStateApplication(undefined)}
            stateApplication={stateApplication}
            onStateApplicationUpdated={handleStateApplicationUpdated}
        />
    ) : null;

    return { setStateStateApplicationModal: setStateApplication, updateStateApplicationModal: modal }
}
