import * as React from "react";
import {useState} from "react";
import { FieldType } from "../../models/sid/FieldType";
import {UpdateStateIdFieldTypeModal} from "../entities/state-id/settings/field-type/FieldTypeForm";

export default function useCreateStateIdFieldTypeModal(onFieldTypeUpdated: (fieldType: FieldType) => void) {

    const [fieldType, setFieldType] = useState<FieldType>();

    const handleFieldTypeUpdated = (fieldType: FieldType) => {
        setFieldType(undefined);
        onFieldTypeUpdated(fieldType)
    };

    const modal = fieldType ? (
        <UpdateStateIdFieldTypeModal
            open={true}
            onClose={() => setFieldType(undefined)}
            fieldType={fieldType}
            onFieldTypeUpdated={handleFieldTypeUpdated}
        />
    ) : null;

    return { setStateIdFieldTypeModal: setFieldType, updateStateIdFieldTypeModal: modal }
}
