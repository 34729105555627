import moment from 'moment'
import 'moment-business-days';

export enum DateFormat {
    dateWithoutTime = "M/DD/YY",
    slashDelimitedDate = "M/DD/YY",
}

export default class DateFormatter {

    static format(value: string, format?: DateFormat | string): string {
        return moment(value).format(format)
    }

    static formatDate(value: string, format?: string): string {
        return moment(value).format(format)
    }

    static formatRelativeToNow(value: string): string {
        return moment(value).fromNow()
    }

    static getYear(value: string): number {
        return moment(value).year()
    }

    static getBusinessDays(value: string): number {
        return moment(value).businessDiff(moment())
    }

    static isFutureDate(value: string): boolean {
        return moment(value).diff(moment()) > 0
    }

    static isExpired(date: string): boolean {
        const now = moment();
        return moment(date).isAfter(now);
    }
}
