import React from 'react';
import ConfirmationDialog from "./ConfirmationDialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function DeleteConfirmationDialog(props: Props) {
  return (
    <ConfirmationDialog
      id="delete-confirmation"
      keepMounted
      open={props.open}
      onCancel={props.onCancel}
      onConfirm={props.onConfirm}
      body={"Are you sure you want to delete this item?"}
      isDestructive={true}
      confirmationText={"Delete"}
    />
  );
}
