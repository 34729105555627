import React, {useRef, useState} from 'react';
import {TableColumn} from '../../table/Table';
import {Paper} from '@material-ui/core';
import {ColumnFactory} from '../TableColumnFactory';
import {Employee} from "../../../models/Employee";
import EntityList from "../EntityList";
import Detail from "./Detail";
import {useRbac} from "@gsb/react-rbac";
import {EntityDialog} from "../EntityDialog";
import useCrudController from "../useCrudController";
import {useRepo} from "../../../repos/useRepo";
import {actions} from "../../../rbac";
import {makeCreateAction} from "../../table/TableActionFactory";
import {EmployeeTableColumnFactory} from "./EmployeeTableColumnFactory";
import useCreateEmployeeModal from "../../modals/useCreateEmployeeModal";

export default function EmployeeList() {
    const { employeeRepo } = useRepo();
    const { can } = useRbac();

    const permissions = {
        create: can(actions.employee.create),
        update: can(actions.employee.update),
        delete: can(actions.employee.delete),
    };

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const columnFactory: ColumnFactory<Employee> = new EmployeeTableColumnFactory();
    const columns: TableColumn<Employee>[] = columnFactory.makeColumns();

    const [draft, setDraft] = useState<Partial<Employee>>();

    const {_save, _delete, _list} = useCrudController(employeeRepo, reloadData);

    const { createEmployeeModal, setCreateEmployeeModalOpen } = useCreateEmployeeModal(reloadData);

    return (
        <>
            <Paper>
                <EntityList
                    title='Employees'
                    tableRef={tableRef}
                    columns={columns}
                    fetchData={_list}
                    actions={[
                        makeCreateAction(() => setCreateEmployeeModalOpen(true), { hidden: !permissions.create }),
                    ]}
                    renderDetail={(employee, onComplete) => (
                        <EntityDialog
                            permissions={permissions}
                            onSave={_save(employee, draft, onComplete)}
                            onDelete={_delete(employee, onComplete)}
                            onDismiss={onComplete}
                        >
                            {(formProps) => (
                                <Detail
                                    employee={employee}
                                    onValuesChange={setDraft}
                                    isReadOnly={!permissions.update}
                                    {...formProps}
                                />
                            )}
                        </EntityDialog>
                    )}
                />
            </Paper>
            {createEmployeeModal}
        </>
    );
}
