import React, {useEffect, useRef, useState} from "react";
import Table, {getOptionsColumnDefinition, TableRef, getDateColumnDefinition} from "../Table";
import * as moment from "moment";
import {
    Filter as TableFilter,
    SortingRule as TableSort
} from "react-table";
import TableRepoAdapter, { transformTableFilter } from "../TableRepoAdapter";
import SearchBar from "../../form/old/SearchBar";
import {Paper} from "@material-ui/core";
import TopicPicker from "../../form/old/TopicPicker";
import {useRepo} from "../../../repos/useRepo";
import {Faq} from "../../../models/ti/Faq";
import {FaqStatus} from "../../../models/ti/FaqStatus";
import {Topic} from "../../../models/ti/Topic";
import {FaqFilter, FaqFilterOption, FaqSort} from "../../../repos/ti/faq/FaqRepo";
import {useNavigation} from "../../../services/navigation/useNavigation";
import {Consultant} from "../../../models/ti/Consultant";
import { actions } from "../../../rbac";
import QuestionPreview from "../questions/QuestionPreview";
import FaqPreview from "./FaqPreview";
import Routes from "../../../Routes";

export const requiredActions = [
  actions.faq.list,
]

const labelForConsultant = (consultant: Consultant) => {
  if (consultant.name) {
      return consultant.name
  } else {
      return `${consultant.firstName} ${consultant.lastName}`;
  }
};

export default function FaqList() {
    const { faqStatusRepo, faqRepo, consultantRepo } = useRepo();
    const { navigateToFaq } = useNavigation();
    const [faqs, setFaqs] = useState<Faq[]>([]);
    const [pages, setPages] = useState(0);

    const [consultants, setConsultants] = useState<Consultant[]>([]);
    useEffect(() => {
        consultantRepo.listAllConsultants().then(setConsultants);
    }, [consultantRepo]);

    const tableRef = useRef<TableRef>(null);

    const reloadTable = () => {
        if (tableRef && tableRef.current) {
            tableRef.current.reloadData()
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const [topicFilter, setTopicFilter] = useState<Topic>();
    const [tableFilters, setTableFilters] = useState<TableFilter[]>([]);
    const [statuses, setStatuses] = useState<FaqStatus[]>([]);

    useEffect(() => {
        faqStatusRepo.listFaqStatuses().then(setStatuses).catch(console.error);
    }, [faqStatusRepo]);

    useEffect(reloadTable, [searchQuery, topicFilter]);

    const columns = [
        getOptionsColumnDefinition("Title", FaqFilterOption.Title, (i: Faq) => i.title || i.question),
        getOptionsColumnDefinition("Status", FaqFilterOption.Status, (i: Faq) => i.status.name, statuses),
        getDateColumnDefinition("Created", FaqFilterOption.CreatedAt,  (i: Faq) => moment.utc(i.createdAt).fromNow()),
        getDateColumnDefinition("Updated", FaqFilterOption.UpdatedAt,  (i: Faq) => moment.utc(i.updatedAt).fromNow()),
        getOptionsColumnDefinition("Updated By", FaqFilterOption.UpdatedBy,  (i: Faq) => i.updatedBy ? labelForConsultant(i.updatedBy) : "", consultants),
    ];

    async function fetchFaqs(pageSize: number = 10, page: number = 0, sorts: TableSort[] = [], filters: TableFilter[] = []) {

        if (topicFilter) {
            const filter: TableFilter = { id: FaqFilterOption.Topic, value: topicFilter.id };
            filters.push(filter)
        }

        const repoFilters: FaqFilter[] = filters
            .map(transformTableFilter)
            .filter((f): f is FaqFilter => !!f);


        const repoSorts: FaqSort[] = sorts
            .map(TableRepoAdapter.FaqRepo.getSortFromTableSort)
            .filter((s): s is FaqSort => !!s);

        const {results, pages} = await faqRepo.listFaqs(pageSize, page, repoSorts, repoFilters, searchQuery);
        setFaqs(results);
        setPages(pages);
    }

    return (
        <Paper>
            <div style={{
                padding: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                maxWidth: "100%",
                flexWrap: "wrap"
            }}>
                <SearchBar value={searchQuery} onChange={setSearchQuery} />
                <TopicPicker value={topicFilter} onTopicSelected={setTopicFilter}/>
            </div>
            <Table
                ref={tableRef}
                fetchItems={fetchFaqs}
                columns={columns}
                items={faqs}
                filters={tableFilters}
                onFiltersChanged={setTableFilters}
                pages={pages}
                hrefProvider={faq => Routes.ti.forFaqById(faq.id)}
                preview={(faq) => <FaqPreview faq={faq}/>}
            />
        </Paper>
    );
}
