import React, {useRef, useState} from 'react';
import {TableColumn} from '../../table/Table';
import {Paper} from '@material-ui/core';
import {ColumnFactory} from '../TableColumnFactory';
import {User} from "../../../models/User";
import EntityList from "../EntityList";
import Detail from "./Detail";
import {EntityDialog} from "../EntityDialog";
import useCrudController from "../useCrudController";
import {useRepo} from "../../../repos/useRepo";
import {UserTableColumnFactory} from "./UserTableColumnFactory";
import {actions} from "../../../rbac";
import {useRbac} from "@gsb/react-rbac";
import {makeCreateAction} from "../../table/TableActionFactory";
import useCreateUserModal from "./useCreateUserModal";

export const requiredPermissions = [
  actions.user.list,
]

export default function UserList() {
    const { userRepo } = useRepo();
    const { can } = useRbac();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { createUserModal, openCreateUserModal } = useCreateUserModal(reloadData);

    const permissions = {
        create: can(actions.user.create),
        update: can(actions.user.update),
        delete: can(actions.user.delete),
    };

    const columnFactory: ColumnFactory<User> = new UserTableColumnFactory();
    const columns: TableColumn<User>[] =  columnFactory.makeColumns();

    const [draft, setDraft] = useState<Partial<User>>();

    const {_save, _delete, _list} = useCrudController(userRepo, reloadData);

    return (
        <>
            <Paper>
                <EntityList
                    title='Users'
                    columns={columns}
                    actions={[
                        makeCreateAction(openCreateUserModal, { disabled: !permissions.create }),
                    ]}
                    fetchData={_list}
                    tableRef={tableRef}
                    renderDetail={(user, onComplete) => (
                        <EntityDialog
                            permissions={permissions}
                            onSave={_save(user, draft, onComplete)}
                            onDelete={_delete(user, onComplete)}
                            onDismiss={onComplete}
                        >
                            {(formProps) => (
                                <Detail
                                    user={user}
                                    onValuesChange={setDraft}
                                    isReadOnly={!permissions.update}
                                    {...formProps}
                                />
                            )}
                        </EntityDialog>
                    )}
                />
            </Paper>
            {createUserModal}
        </>
    );
}
