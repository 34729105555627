import TextInput from "../../../../../form/inputs/TextInput";
import {verticalFormLayout} from "../../../../../form/styles/layout";
import {Form, Formik} from "formik";
import {useRepo} from "../../../../../../repos/useRepo";
import {updateSubmitHandler} from "../../../../../form/helpers/formSubmit";
import {actions} from "../../../../../../rbac";
import {useRbac} from "@gsb/react-rbac";
import {UpdateEmailRequest} from "../../../../../../repos/user/UserRepo";
import {User} from "../../../../../../models/User";
import React from "react";
import {FormSubmitButton} from "../../../../../form/buttons/FormSubmitButton";
import {updateEmailSchema} from "./update-email-schema";

interface Props {
    user: User;
    onUserUpdated: (user: User) => void
}

export function UpdateEmailForUserForm({ user, onUserUpdated }: Props) {
    const { userRepo } = useRepo();

    const { can } = useRbac();
    const disabled = !can(actions.user.updateEmail);

    const onSubmit = updateSubmitHandler(
        (body: UpdateEmailRequest) => userRepo.updateEmailForUserById(user.id, body),
        initialValues,
        onUserUpdated,
        console.error
    );

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValues(user)}
                validationSchema={updateEmailSchema}
                onSubmit={onSubmit} >
            <Form style={verticalFormLayout}>
                <TextInput name='email' label='Email' disabled={disabled} />

                <FormSubmitButton variant='Update' disabled={disabled}/>
            </Form>
        </Formik>
    )
}

const initialValues = (user: User) => ({
    email: user.email ?? ''
})
