import {BusinessSize, BusinessSizeRepo} from "./BusinessSizeRepo";

export class HardcodedBusinessSizeRepo implements BusinessSizeRepo {
  private options = [
    "None (just me)",
    "1",
    "2",
    "3",
    "4",
    "5-10",
    "11-15",
    "16+"
  ];
  async list(): Promise<BusinessSize[]> {
    return Promise.resolve(this.options);
  }
}
