import React, {useMemo} from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export default function UrlTargetPicker({ ...PickerProps }: Props) {

    const targets: string[] = ['_blank', '_self', '_parent', '_top'];
    
    const options = useMemo(() => targets.map(t => ({ id: t, label: t })), [targets]);

    return <PickerInput label={'URL Target'} options={options} {...PickerProps} />
}
