import React from "react";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

import Demeanors from "../../../../config/demeanors"
import { CompanyNote } from "../../../../models/Company";
import { TimelineDot } from "@material-ui/lab";
import { Avatar, Tooltip } from "@material-ui/core";
import { ApplicationNote } from "../../../../models/sid/ApplicationNote";

export function CompanyNoteDemeanorTimelineDot(props: { companyNote: CompanyNote | ApplicationNote }) {
    const {companyNote} = props;
    const demeanor = getCompanyNoteDemeanor(companyNote);

    return (<TimelineDot style={{ background: demeanor.color }}><Tooltip title={demeanor.title}>{demeanor.icon ?? <NotInterestedIcon style={{ color: 'grey' }} />}</Tooltip></TimelineDot>);
}

export function CompanyNoteDemeanorAvatar(props: {companyNote: CompanyNote | ApplicationNote}) {
    const {companyNote} = props;
    const demeanor = getCompanyNoteDemeanor(companyNote);
    
    return (<Avatar style={{ background: demeanor.color, color: '#fff', margin: '0 auto' }}><Tooltip title={demeanor.title}>{demeanor.icon ?? <NotInterestedIcon style={{ color: 'grey' }} />}</Tooltip></Avatar>);
}


function getCompanyNoteDemeanor(companyNote: CompanyNote | ApplicationNote) {
    const {demeanor} = companyNote;
    const d = Demeanors[demeanor] ?? Demeanors.na;

    switch (d.key) {
        case Demeanors.happy.key:
            d.icon = <SentimentVerySatisfiedIcon />
            break;
        case Demeanors.mildlyUpset.key:
            d.icon = <SentimentSatisfiedIcon />
            break;
        case Demeanors.upset.key:
            d.icon = <SentimentDissatisfiedIcon />
            break;
        case Demeanors.extremelyUpset.key:
            d.icon = <SentimentVeryDissatisfiedIcon />
            break;
        case Demeanors.normal.key:
            d.icon = <SentimentSatisfiedAltIcon />
            break;
        default:
            d.icon = <NotInterestedIcon style={{ color: 'grey' }} />
    }

    return d;
}