import React, {useEffect} from "react";
import {Redirect} from "react-router";
import LoadingIndicator from "../../components/LoadingIndicator";
import Routes from "../../Routes";
import {useAuthState} from "./useAuthState";
import {useServices} from "../useServices";

export default function withAuthentication<T>(Component: React.ComponentType<T>): React.FC<T> {
    return (props) => {
        const { isAuthenticated, setAuthResponse, setAuthError } = useAuthState();
        const { authService } = useServices();

        useEffect(() => {
            if (isAuthenticated === undefined) {
                authService.getToken().then(setAuthResponse).catch(setAuthError)
            }
        }, [authService, isAuthenticated]);


        if (isAuthenticated === true) {
            return <Component {...props}/>;
        } else if (isAuthenticated === false) {
            return <Redirect to={Routes.login + '?redirectUri=' + window.location.href} />;
        } else {
            return (
                <div>
                    <LoadingIndicator/>
                </div>
            );
        }
    };
}
