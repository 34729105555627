import React from "react";
import EmployeeCompanyPicker from "../../form/pickers/EmployeeCompanyPicker";
import TextInput from "../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
    showSubjectId?: boolean;
}

export default function EmployeeFields({ disabled, showSubjectId }: Props) {
    return (
        <>
            <TextInput name='firstName' label='First Name' disabled={disabled}/>

            <TextInput name='lastName' label='Last Name' disabled={disabled}/>

            <TextInput name='email' label='Email' disabled={disabled}/>

            <EmployeeCompanyPicker name='companyId' label='Company' allowEmpty={true} emptyValue='' emptyValueDescription='-' disabled={disabled}/>

            {showSubjectId && (
                    <TextInput name='subjectId' label='Azure Object Id' helperText='Required for "Tarkenton.com" email address.' />
            )}
        </>
    )
}
