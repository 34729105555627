import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker, {DropdownPickerItem} from "../pickers/DropdownPicker";
import React, {useEffect, useMemo, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {Sender} from "../../../models/mail/Sender";

export default function SenderDropdown(props: FilterComponentProps<any>) {
    const { senderRepo } = useRepo();
    const [senders, setSenders] = useState<Sender[]>([]);

    useEffect(() => {
        senderRepo.listAll().then(setSenders).catch(console.error);
    }, [senderRepo]);

    const options: DropdownPickerItem[] = useMemo(() => senders.map(s => ({ id: s.id, name: s.name || s.email })), [senders]);
    
    return (
        <DropdownPicker options={options} {...props}/>
    )
}