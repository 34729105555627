import { User } from "../../../models/User";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { withModal } from "../../modal";
import { FormSubmitButton } from "../../form/buttons/FormSubmitButton";
import { VerticalFormLayout } from "../../form/FormLayout";
import { useRepo } from "../../../repos/useRepo";
import * as Yup from "yup";
import TextInput from "../../form/inputs/TextInput";
import { Typography } from "@material-ui/core";

export function UserMoveForm(props: { user: User, onMoved: (msg?: string) => void }) {
    const { user, onMoved } = props;
    const { userRepo } = useRepo();

    interface b2cForm {
        companyId: string
    };

    const schema = Yup.object<b2cForm>().shape({
        companyId: Yup.string().required("This field is required")
    });

    const onSubmit = (values: b2cForm, { setSubmitting }: FormikHelpers<any>) => {
        // Submit Password Reset
        userRepo.moveUserById(user.id, values.companyId)
            .then(() => onMoved())
            .catch(() => onMoved('Failed to move user.'));

        setSubmitting(false);
    };

    return (
        <Formik<b2cForm>
            initialValues={{ companyId: "" }}
            validationSchema={schema}
            onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout
                    fieldset={
                        <>
                            <TextInput label={'New Company Id'} name={'companyId'} />
                            <Typography variant="caption" display="block" style={{ marginRight: 6, marginLeft: 6 }}>Please enter the company id of the company you wish to move<br />this user to.</Typography>
                        </>
                    }
                    bottomContent={<FormSubmitButton label={"Move"} variant='Create' disabled={false} />}
                />
            </Form>
        </Formik>
    )
}

export const MoveUserModal = withModal(UserMoveForm, 'Move User to Another Company');
