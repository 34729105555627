import React from "react";
import { Paper } from "@material-ui/core";
import SubscriptionReport from "./subscription/List";
import CompanyMetadataReport from "./company-metadata/List";
import StatsReport from "./stats/Index";
import CompanyNotesReport from "./company-notes/List";
import UserSignInReport from "./user-sign-in/List";
import WebsiteAnalysisReport from "./website-analysis/List";
import AdpCompanySignInReport from "./adp-company-sign-in/List";
import TabNavigation, { TabNavigationItem } from "../tabs/TabNavigation";
import {actions} from "../../rbac";
import { useRbac } from "@gsb/react-rbac";


export const requiredActions = [
    actions.report.subscription,
    actions.report.stats,
    actions.report.companyMetadata,
    actions.report.companyNotes,
    actions.report.signIn,
    actions.report.websiteAnalysis,
    actions.report.adpCompanySignIn,
  ];

export function Reports() {

    const { can } = useRbac();

    const items: TabNavigationItem[] = [
        {
            name: "Subscriptions",
            identifier: "subscription-list",
            render: () => <SubscriptionReport />,
            hidden: !can(actions.report.subscription)
        },
        {
            name: "Stats",
            identifier: "stats-chart",
            render: () => <StatsReport />,
            hidden: !can(actions.report.stats)
        },
        {
            name: "Company Metadata",
            identifier: "company-metadata-list",
            render: () => <CompanyMetadataReport />,
            hidden: !can(actions.report.companyMetadata)
        },
        {
            name: "Company Notes",
            identifier: "company-notes",
            render: () => <CompanyNotesReport />,
            hidden: !can(actions.report.companyNotes)
        },
        {
            name: "User Sign-Ins",
            identifier: "user-sign-in",
            render: () => <UserSignInReport />,
            hidden: !can(actions.report.signIn)
        },
        {
            name: "Website Analysis",
            identifier: "website-analysis",
            render: () => <WebsiteAnalysisReport />,
            hidden: !can(actions.report.websiteAnalysis)
        },
        {
            name: "ADP Company Sign In",
            identifier: "adp-company-sign-in",
            render: () => <AdpCompanySignInReport />,
            hidden: !can(actions.report.adpCompanySignIn)
        }
    ];

    return (
        <Paper>
            <TabNavigation items={items} disabledContentPadding />
        </Paper>
    )
}