import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useEffect, useMemo, useState} from "react";
import {User} from "../../../models/User";
import {useRepo} from "../../../repos/useRepo";
import LoadingIndicator from "../../LoadingIndicator";
import {Paper} from "@material-ui/core";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";
import {LicensesTab} from "./tabs/LicensesTab";
import RolesTab from "./tabs/RolesTab";
import UserTab from "./tabs/UserTab";
import UserActionBar from "./UserActionBar";
import {useNavigation} from "../../../services/navigation/useNavigation";
import {
    BreadcrumbFactory,
    UserDetailBreadcrumbFactory
} from "../../../factories/BreadcrumbFactory";
import {Breadcrumbs} from "../../layout/Breadcrumbs";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../rbac";
import AccountTab from "./tabs/account/AccountTab";
import { useConfirmDialog } from "../../dialog/ConfirmDialog";
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from "@material-ui/core";

interface Params {
    userId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
  actions.user.get,
];

function UserDetailController({ match }: Props) {
    const [user, setUser] = useState<User>();
    const { userRepo } = useRepo();
    const { navigateToCompanyById } = useNavigation();

    const [toast, setToast] = useState<{ msg?: string, severity?: "error" | "success" | "info" | "warning" | undefined, show: boolean}>({ show: false });

    const userId = useMemo(() => match.params.userId, [match.params.userId]);

    const loadUser = () => {
        userRepo.getById(userId).then(setUser).catch(console.error);
    };

    const handleUserDelete = () => {
        if(user) {
            const { companyId } = user;
            userRepo.delete(userId).then(() => {
                setToast({ msg: `${user.firstName} User Deleted Successfully!`, severity:'success', show: true });
                navigateToCompanyById(companyId);
            })
            .catch(r => {
                setToast({ msg: `${user.firstName} User Failed to Delete. Please check that the user is NOT the Primary Contact for the company.`, severity:'error', show: true });
            });
        }
    };

    const { openConfirmDialog, confirmDialog } = useConfirmDialog('Delete User', 'Are you sure you want to delete this user? All related user data and B2C account will be permanently deleted.  This action can NOT be reversed!', handleUserDelete);

    useEffect(loadUser, [userRepo, userId]);

    if (user) {
        return <>
        <UserDetail user={user} onUserUpdated={loadUser} onDeleteClicked={openConfirmDialog}/>
        <Snackbar open={toast.show} autoHideDuration={6000} onClose={() => setToast({show: false})}>
            <Alert severity={toast.severity}>{toast.msg}</Alert>
        </Snackbar>
        {confirmDialog}
        </>
    } else {
        return <LoadingIndicator/>
    }
}

function UserDetail({ user, onUserUpdated, onDeleteClicked }: { user: User, onUserUpdated: () => void, onDeleteClicked: () => void }) {
    const { navigateToUsers } = useNavigation();
    const { can } = useRbac();

    const items: TabNavigationItem[] = [
        {
            name: "Profile",
            identifier: "profile",
            render: () => <UserTab user={user} onUserUpdated={onUserUpdated}/>,
            hidden: !can(actions.user.get)
        },
        {
            name: "Account",
            identifier: "account",
            render: () => <AccountTab user={user} onUserUpdated={onUserUpdated}/>,
        },
        {
            name: "Roles",
            identifier: "roles",
            render: () => <RolesTab user={user} roles={user.roles || []} onAddRole={onUserUpdated} onRoleRevoked={onUserUpdated}/>,
            hidden: !can(actions.user.listRoles),
        },
        {
            name: "Licenses",
            identifier: "licenses",
            render: () => <LicensesTab user={user} licenses={user.licenses || []} onLicenseRevoked={onUserUpdated} onLicensesAssigned={onUserUpdated}/>,
            hidden: !can(actions.user.listLicenses),
        },
    ];

    const breadcrumbFactory: BreadcrumbFactory = new UserDetailBreadcrumbFactory(user, navigateToUsers);

    return (
        <>
            <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

            <Paper style={{ marginBottom: 12 }}>
                <UserActionBar user={user} onUserUpdated={onUserUpdated} onDeleteClicked={onDeleteClicked}/>
            </Paper>

            <Paper>
                <TabNavigation items={items}/>
            </Paper>
        </>
    );
}

export default withRouter(UserDetailController);