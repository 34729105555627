import * as React from "react";
import {SalesChannelPicker} from "../../../form/pickers/SalesChannelPicker";
import {ResellerPicker} from "../../../form/pickers/ResellerPicker";
import TextInput from "../../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
}

export function UpdateMailchimpAccountFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <TextInput name='apiKey' label='Api Key' disabled={disabled}/>

            <TextInput name='server' label='Server' disabled={disabled}/>

            <TextInput name='listId' label='Audience Id' disabled={disabled}/>

            <TextInput name='tag' label='Tag' disabled={disabled}/>

            <SalesChannelPicker name='salesChannelId' disabled={disabled}/>

            <ResellerPicker name='resellerId' disabled={disabled}/>
        </>
    )
}

export function CreateMailchimpAccountFields({ disabled }: Props) {
    return (
        <>
            <UpdateMailchimpAccountFields disabled={disabled}/>
        </>
    )
}
