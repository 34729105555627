import { FieldArray, Form, Formik } from "formik";
import { useRbac } from "@gsb/react-rbac";
import React, { useEffect, useState } from "react";
import { useRepo } from "../../../../repos/useRepo";
import { actions } from "../../../../rbac";
import { verticalFormLayout } from "../../../form/styles/layout";
import { updateSubmitHandler } from "../../../form/helpers/formSubmit";
import { StateApplicationSection } from "../../../../models/sid/StateApplicationSection";
import StateApplicationFieldInput from "../../../form/inputs/StateApplicationFieldInput";
import { Typography } from "@material-ui/core";
import theme from "../../../../theme";
import { StateApplicationFieldCondition } from "../../../../models/sid/StateApplicationField";
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from "@material-ui/lab";

interface UpdateApplicationSectionFormProps {
    applicationId: string;
    stateApplicationSection: StateApplicationSection;
    applicationFields: any;
    readOnly: boolean;
    bindSubmit: (value: any) => void;
    onApplicationSectionUpdated: (value: any) => void;
}

export function UpdateApplicationSectionForm({ applicationId, stateApplicationSection, applicationFields, readOnly, bindSubmit, onApplicationSectionUpdated }: UpdateApplicationSectionFormProps) {
    const { stateIdApplicationRepo } = useRepo();
    const { can } = useRbac();
    const canUpdateApplication = can(actions.stateIdApplication.update);

    const [state, setState] = useState<{ open: boolean, message: string }>()

    const fields = stateApplicationSection.fields ?? [];

    const onSubmit = updateSubmitHandler<any, any>(
        (values: any) => {
            const body = {
                stateApplicationSectionId: stateApplicationSection.id,
                fields: values
            }
            return stateIdApplicationRepo.updateFields(applicationId, body);
        },
        (d) => initVals(d),
        onApplicationSectionUpdated,
        console.error);

    const initVals = (appFields: any) => {
        if (!appFields) return appFields;

        const appSectionFields: any = {};
        for (var i = 0; i < fields.length; i++) {
            const field = fields[i];
            if (field.id in appFields) {
                appSectionFields[field.id] = appFields[field.id];
            }
        }
        return appSectionFields;
    }

    const disabled = !canUpdateApplication;

    const conditionalCheck = (conditions: StateApplicationFieldCondition[]): boolean => {
        let result = true;
        for (var i = 0; i < conditions.length; i++) {
            const condition = conditions[i];
            if (applicationFields[condition.fieldId]) {
                result = applicationFields[condition.fieldId] == condition.value;
                if (result === false) break;
            }
        }
        return result;
    }

    const handleCopyClick = (val: { name: string, value: string }) => {
        navigator.clipboard.writeText(val.value);
        setState({ open: true, message: `Field ${val.name} copied to clipboard.` });
    }

    return (
        <>
            <Formik enableReinitialize={true}
                initialValues={initVals(applicationFields)}
                onSubmit={onSubmit}
                render={formikProps => {
                    bindSubmit(formikProps.submitForm);
                    return (
                        <Form style={verticalFormLayout}>
                            <FieldArray
                                name=""
                                render={arrayHelpers => (
                                    <div>
                                        {fields.length > 0 ? (
                                            fields.map((field, index) => {
                                                const fieldType = {
                                                    component: 'text',
                                                    sensitive: field.sensitive ?? false,
                                                    ...field.fieldType
                                                }
                                                if (fieldType.component === 'repeatable') {
                                                    return (
                                                        <div key={field.id}>
                                                            <Typography style={{ marginLeft: theme.spacing(1) }}>{field.label}</Typography>
                                                            {applicationFields[field.id] && applicationFields[field.id].map((childField: any, childFieldIndex: number) => {
                                                                // if (conditionalCheck(childField.conditional ?? [])) {
                                                                    return (
                                                                        <div>
                                                                            <Typography style={{ marginLeft: theme.spacing(1), fontStyle: 'italic' }}>{`Record: ${childField.id}`}</Typography>
                                                                            {field.fields && field.fields.map((child, childIndex) => <StateApplicationFieldInput fieldType={{ component: 'text', ...child.fieldType, sensitive: child.sensitive }} name={`${field.id}[${childFieldIndex}].${child.id}`} label={child.label} disabled={disabled} helperText={child.description ?? ""} readOnly={readOnly} onCopyClick={handleCopyClick} />)}
                                                                            <input type="hidden" name={`${field.id}[${childFieldIndex}].id`} />
                                                                        </div>);
                                                                // } else {
                                                                //     return null;
                                                                // }
                                                            })}
                                                        </div>);
                                                } else if (conditionalCheck(field.conditional ?? [])) {
                                                    return (
                                                        <div key={field.id}>
                                                            <StateApplicationFieldInput fieldType={fieldType} name={field.id} label={field.label} disabled={disabled} helperText={field.description ?? ""} readOnly={readOnly} onCopyClick={handleCopyClick} />
                                                        </div>);
                                                } else {
                                                    return null;
                                                }
                                            })
                                        ) : (<></>)}
                                    </div>
                                )}
                            />
                        </Form>
                    )
                }}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={state?.open ?? false}
                autoHideDuration={3000}
            >
                <Alert severity="success">{state?.message}</Alert>
            </Snackbar>
        </>);
}