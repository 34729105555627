import {Form, Formik} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {
    CreateSalesChannelApplicationFields,
    CreateSalesChannelApplicationForSalesChannelFields,
    UpdateSalesChannelApplicationFields
} from "./SalesChannelApplicationFields";
import {CreateSalesChannelApplicationRequest, SalesChannelApplication, UpdateSalesChannelApplicationRequest} from "../../../../models/SalesChannelApplication";
import {initialValuesForSalesChannelApplicationUpdate, initialValuesForSalesChannelApplicationCreate, createSalesChannelApplicationSchema, updateSalesChannelApplicationSchema} from "../../../../utils/schemas/sales-channel-application-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {SalesChannel} from "../../../../models/SalesChannel";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdateSalesChannelApplicationForm(props: { salesChannelApplication: SalesChannelApplication, onSalesChannelApplicationUpdated: (salesChannelApplication: SalesChannelApplication) => void }) {
    const { salesChannelApplication, onSalesChannelApplicationUpdated } = props;
    const { salesChannelApplicationRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.salesChannelApplication.update);

    const onSubmit = updateSubmitHandler<UpdateSalesChannelApplicationRequest, SalesChannelApplication>(
        body => salesChannelApplicationRepo.update(salesChannelApplication.id, body),
        initialValuesForSalesChannelApplicationUpdate,
        onSalesChannelApplicationUpdated,
        console.error,
    );

    return (
        <Formik<UpdateSalesChannelApplicationRequest>
            enableReinitialize={true}
            initialValues={initialValuesForSalesChannelApplicationUpdate(salesChannelApplication)}
            validationSchema={updateSalesChannelApplicationSchema}
            onSubmit={onSubmit}
        >
            <Form>
                <VerticalFormLayout fieldset={<UpdateSalesChannelApplicationFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateSalesChannelApplicationForm(props: { onSalesChannelApplicationCreated: (salesChannelApplication: SalesChannelApplication) => void }) {
    const { onSalesChannelApplicationCreated }  = props;
    const { salesChannelApplicationRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateSalesChannelApplicationRequest) => salesChannelApplicationRepo.create(body),
        onSalesChannelApplicationCreated,
        console.error
    );

    return (
        <Formik<CreateSalesChannelApplicationRequest> enableReinitialize={true} initialValues={initialValuesForSalesChannelApplicationCreate()} validationSchema={createSalesChannelApplicationSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateSalesChannelApplicationFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateSalesChannelApplicationForSalesChannelForm(props: { salesChannel: SalesChannel, onSalesChannelApplicationCreated: (salesChannelApplication: SalesChannelApplication) => void }) {
    const { salesChannel, onSalesChannelApplicationCreated }  = props;
    const { salesChannelApplicationRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateSalesChannelApplicationRequest) => salesChannelApplicationRepo.create(body),
        onSalesChannelApplicationCreated,
        console.error
    );

    return (
        <Formik<CreateSalesChannelApplicationRequest> enableReinitialize={true} initialValues={initialValuesForSalesChannelApplicationCreate(salesChannel)} validationSchema={createSalesChannelApplicationSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateSalesChannelApplicationForSalesChannelFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}

export const UpdateSalesChannelApplicationModal = withModal(UpdateSalesChannelApplicationForm, "Update Sales Channel Application");
export const CreateSalesChannelApplicationModal = withModal(CreateSalesChannelApplicationForm, "Create Sales Channel Application");
export const CreateSalesChannelApplicationForSalesChannelModal = withModal(CreateSalesChannelApplicationForSalesChannelForm, "Create Sales Channel Application");