import React from "react";
import {Typography} from "@material-ui/core";

export default function Title(props: { title: string }) {
    return (
        <Typography
            variant={"h6"}
            style={{
                marginBottom: 4,
                marginRight: 10,
                marginTop: 8,
                fontWeight: "bolder",
            }}
        >
            {props.title}
        </Typography>
    );
}
