import {createStyles, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme} from "@material-ui/core";
import {StripeSubscriptionSource, StripeSubscriptionSourceItem} from "../../../../../models/Subscription";
import * as React from "react";
import {formatAmount} from "../../../../../utils/NumberFormatter";

const useStyles = makeStyles((theme: Theme) => createStyles({
    tableHeader: {
        textAlign: 'left'
    }
}));

export default function StripeSubscriptionSourceItemTable(props: { stripeSubscriptionSource: StripeSubscriptionSource }) {
    const { stripeSubscriptionSource } = props;
    const classes = useStyles();

    const items = stripeSubscriptionSource.items || [];

    const formatPriceForItem = (item: StripeSubscriptionSourceItem) => {
        const formattedAmount = formatAmount(item.amount);
        // TODO: - Support other currencies
        const formattedCurrency = item.currency === 'usd' ? "$" : "";
        return `${formattedCurrency}${formattedAmount} / ${item.interval}`
    };

    return (
        <Table>
            <TableHead>
                <TableCell className={classes.tableHeader}>Quantity</TableCell>
                <TableCell className={classes.tableHeader}>Plan</TableCell>
                <TableCell className={classes.tableHeader}>Price</TableCell>
            </TableHead>
            <TableBody>
                {items.map(item => (
                    <TableRow>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.plan ? item.plan.name : '-'}</TableCell>
                        <TableCell>{formatPriceForItem(item)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

