import TableColumnFactory, {ColumnFactory} from "../../TableColumnFactory";
import {MailchimpAccount} from "../../../../models/MailchimpAccount";

export class MailchimpAccountTableColumnFactory implements ColumnFactory<MailchimpAccount>{
    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<MailchimpAccount>([
            TableColumnFactory.fromProperty("Name", "name"),
            TableColumnFactory.fromProperty("Api Key", "apiKey"),
            TableColumnFactory.fromProperty("Server", "server"),
            TableColumnFactory.fromProperty("Audience", "listId"),
            TableColumnFactory.fromProperty("Tag", "tag"),
            TableColumnFactory.makeSalesChannelColumn("Sales Channel", 'salesChannelId', c => c.salesChannel),
            TableColumnFactory.makeResellerColumn("Reseller", 'resellerId',c => c.reseller),
        ]);
    }
}
