const Routes = {
    home: "/",
    login: "/login",
    loginWithRedirect: (route: string) => `/login?redirectUri=${route}`,
    authCallback: '/oauth2/callback',
    logout:"/logout",

    applications: '/applications',
    applicationById: '/applications/:applicationId',
    forApplicationById: (applicationId: string) => `/applications/${applicationId}`,

    billing: '/billing',

    products: '/products',
    productById: '/products/:productId',
    forProductById: (productId: string) => `/products/${productId}`,

    brands: '/brands',

    salesChannels: '/brands?tab=sales-channels',
    salesChannelById: '/sales-channels/:salesChannelId',
    forSalesChannelById: (salesChannelId: string) => `/sales-channels/${salesChannelId}`,

    resellers: '/brands?tab=resellers',
    resellerById: '/resellers/:resellerId',
    forResellerById: (resellerId: string) => `/resellers/${resellerId}`,

    companies: "/companies",
    companyById: '/companies/:companyId',
    forCompanyById: (companyId: string) => `/companies/${companyId}`,

    users: '/users',
    userById: '/users/:userId',
    forUserById: (userId: string) => `/users/${userId}`,

    subscriptionFileUpload: '/subscriptions/upload',
    reports: '/reports',

    employees: '/employees',
    employeeCompanies: '/employees?tab=companies',
    employeeRoles: '/employees?tab=roles',

    employeeById: '/employees/:employeeId',
    forEmployeeById: (employeeId: string) => `/employees/${employeeId}`,

    employee_companies: "/employee-companies",

    ti: {
        dashboard: '/ti',
        settings: {
            home: '/ti/settings',
            topics: '/ti/settings?tab=topics',
            topicById: '/ti/settings/topics/:topicId',
            forTopicById: (topicId: string) => `/ti/settings/topics/${topicId}`,

            sites: '/ti/settings?tab=sites',
            siteById: '/ti/settings/sites/:siteId',
            forSiteById: (siteId: string) => `/ti/settings/sites/${siteId}`,

            consultants: '/ti?tab=consultants',
            members: '/ti?tab=members',
        },
        answers: '/ti/answers',
        questions: '/ti/questions',
        questionById: '/ti/questions/:questionId',
        forQuestionById: (questionId: string) => `/ti/questions/${questionId}`,

        faqs: '/ti/faqs',
        faqById: '/ti/faqs/:faqId',
        forFaqById: (faqId: string) => `/ti/faqs/${faqId}`,
    },
    mail: {
        home: '/mail',
        templates: '/mail?tab=templates',
        templateById: '/templates/:templateId',
        forTemplateById: (templateById: string) => `/templates/${templateById}`,

        triggers: '/mail?tab=triggers',
        senders: '/mail?tab=senders'
    },
    sid: {
        settings: {
            home: '/sid/settings',
            stateApplications: '/sid/settings?tab=state-applications',
            stateApplicationById: '/sid/settings/state-applications/:stateApplicationId',
            forStateApplicationById: (stateApplicationId: string) => `/sid/settings/state-applications/${stateApplicationId}`,

            fieldTypes: '/sid/settings?tab=field-types',
        },
        stateIdApplications: '/sid/applications',
        stateIdApplicationById: '/sid/application/:applicationId',
        forStateIdApplicationById: (applicationId: string) => `/sid/application/${applicationId}`
    },
    integrations: '/integrations'
};

export default Routes
