import {Typography} from "@material-ui/core";
import * as React from "react";
import LicenseModel from "../../../../../models/LicenseModel";
import ChipSelect from "../../../../form/select/ChipSelect";

export interface Props {
    title: string;
    caption?: string;
    licenses: LicenseModel[];
    onLicenseSelected?: (license: LicenseModel) => void;
}

export function LicenseSection({ title, caption, licenses, onLicenseSelected }: Props) {
    return (
        <div>
            <Typography variant='subtitle2'>{title}</Typography>

            {caption && (
                <Typography variant='caption'>{caption}</Typography>
            )}

            <div>
                <ChipSelect items={licenses}
                            label={license => license.applicationName || ''}
                            onItemSelected={model => {
                                if (onLicenseSelected) {
                                    onLicenseSelected(model)
                                }
                            }}/>
            </div>
        </div>
    )
}
