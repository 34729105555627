import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {Plan} from "../../../models/Plan";

export default function PlanDropdown(props: FilterComponentProps<any>) {
    const { planRepo } = useRepo();
    const [plans, setPlans] = useState<Plan[]>([]);

    useEffect(() => {
        planRepo.listAllPlans().then(setPlans).catch(console.error);
    }, [planRepo]);
    
    return (
        <DropdownPicker options={plans} {...props}/>
    )
}