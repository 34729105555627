import React, { useState } from "react";
import { Editor } from '@tinymce/tinymce-react';

const defaultToolbar = [
    {
        name: 'history', items: ['undo', 'redo', '|']
    },
    {
        name: 'styles', items: ['styleselect', '|']
    },
    {
        name: 'formatting', items: ['bold', 'italic', '|']
    },
    {
        name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', '|']
    }
].reduce((str, group) => [str, group.items.join(" ")].join(" "), "");

const toolbar = [defaultToolbar, "link", "|", "numlist", "bullist", "|", "outdent", "indent"].join(" ");
const plugins = ["autoresize", "lists", "advlist", "link", "media", "preview", "table", "code"].join(" ");
const removedItems = ["newdocument", "visualaid"].join(" ");

export default function TextEditor(props: { value: string, onValueChanged: (value: string) => void, maxHeight?: number }) {
    const { onValueChanged, maxHeight } = props;

    const [value, setValue] = useState<string>(props.value);

    function onChange(newValue: string) {
        if (newValue !== value) {
            setValue(newValue);
            onValueChanged(newValue)
        }
    }

    return (
        <Editor
            init={{ plugins: plugins, removed_menuitems: removedItems, max_height: maxHeight || 600, browser_spellcheck: true, contextmenu: false, branding: false, promotion: false, license_key: 'gpl' }}
            toolbar={toolbar}
            initialValue={value}
            value={value}
            onEditorChange={onChange}
        />
    );
}

/*
This snippet was taken from the tinymce source code.
https://github.com/tinymce/tinymce/blob/master/modules/tinymce/src/themes/silver/main/ts/ui/toolbar/Integration.ts
*/

