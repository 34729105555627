import * as React from "react";
import {useState} from "react";
import {User} from "../../models/User";
import {UpdateUserPasswordModal} from "../entities/users/UserUpdatePasswordForm";
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from "@material-ui/core";

export default function useUpdateUserPasswordModal(user: User) {

    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState<{ msg?: string, severity?: "error" | "success" | "info" | "warning" | undefined, show: boolean}>({ show: false });

    const handlePasswordUpdated = (msg?: string) => {

      if(msg){
        setToast({ msg, severity:'error', show: true });
      } else {
        setToast({ msg: 'Password Updated!', severity:'success', show: true });
        setOpen(false);
      }
    };

    const modal = (
      <>
      <UpdateUserPasswordModal
        open={open}
        onClose={() => setOpen(false)}
        user={user}
        onUpdated={handlePasswordUpdated}
      />
       <Snackbar open={toast.show} autoHideDuration={6000} onClose={() => setToast({show: false})}>
            <Alert severity={toast.severity}>{toast.msg}</Alert>
        </Snackbar>
      </>
    );

    return {
        openUpdateUserPasswordModal: () => setOpen(true),
        updateUserPasswordModal: modal
    }
}
