import React, {useEffect, useState} from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Theme} from "@material-ui/core";
import QuestionStatusBadge from "./QuestionStatusBadge";
import moment from "moment";
import yellow from "@material-ui/core/colors/yellow";
import red from "@material-ui/core/colors/red";
import {Question} from "../../../models/ti/Question";
import DateFormatter from "../../../utils/DateFormatter";
import {QuestionStatusIdentifier} from "../../../models/ti/QuestionStatus";
import DeadlineCalculator, {WarningLevel} from "../../../utils/DeadlineCalculator";
import {useNavigation} from "../../../services/navigation/useNavigation";
import {Link} from "react-router-dom";
import Routes from "../../../Routes";

interface StatusBarViewModel {
    readonly salesChannel?: string;
    readonly reseller?: string;
    readonly askedAt?: string;
    readonly askedBy?: string;
    readonly status?: string;
    readonly ribbonColor: string | undefined;
}

class QuestionStatusBarViewModel implements StatusBarViewModel {
    constructor(private question: Question) {}

    get askedAt(): string | undefined {
        const askedAt = this.question.createdAt;
        return DateFormatter.format(askedAt, 'MM/DD/YYYY')
    }

    get askedBy(): string | undefined {
        const member = this.question.member;
        return member ? member.name : undefined;
    }
    get status(): string | undefined {
        if (this.question.status) {
            return this.question.status.identifier;
        }
    }

    get ribbonColor(): string | undefined {

        if (!this.question.status) {
            return undefined;
        }

        const isReleased = this.question.status.identifier === QuestionStatusIdentifier.released;
        const warningLevel = DeadlineCalculator.getStatus(moment(this.question.createdAt));

        if (isReleased) {
            return undefined;
        }

        switch (warningLevel) {
            case WarningLevel.onTrack:
                return 'none';
            case WarningLevel.approachingDeadline:
                return yellow[600].toString();
            case WarningLevel.passedDeadline:
                return red[600].toString()
        }
    }

    get reseller(): string | undefined {
        return this.question.site.resellerName
    }

    get salesChannel(): string | undefined {
        return this.question.site.salesChannelName
    }
}

export default function StatusBar(props: { question: Question, onClick?: () => void }) {
    const { navigateToSalesChannelById, navigateToResellerById, navigateToUserById } = useNavigation();

    const { onClick } = props;
    const [question, setQuestion] = useState<Question>(props.question);

    useEffect(() => {
        setQuestion(props.question)
    }, [props.question]);

    const vm: StatusBarViewModel = new QuestionStatusBarViewModel(question);
    const { status, ribbonColor } = vm;

    const classes = makeStyles((theme: Theme) => createStyles({
        container: {
            '&:hover': onClick ? {
                backgroundColor: theme.palette.grey[100]
            } : {},
            borderRadius: "6px",
            borderColor: theme.palette.grey[400],
            borderWidth: "1px",
            borderStyle: "solid",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            overflow: "hidden"
        },
        leftContainer: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        leftSection: {
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        rightSection: {
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        title: {

        },
        subtitle: {
            marginTop: "2px",
            marginLeft: "8px"
        },
        badge: {
            marginLeft: "8px"
        },
        ribbon: {
            width: "8px",
            height: "100%",
            backgroundColor: ribbonColor
        }
    }))();


    return (
        <div className={classes.container} onClick={onClick}>

            <div className={classes.leftContainer}>

                <div className={classes.ribbon}/>

                <div className={classes.leftSection}>
                    {question.site && (
                        <>
                            <Link className={classes.title} to={Routes.forSalesChannelById(question.site.salesChannelId)}>
                                <h3>{vm.salesChannel}</h3>
                            </Link>

                            <Link className={classes.subtitle} to={Routes.forResellerById(question.site.resellerId)}>
                                <h5>{vm.reseller}</h5>
                            </Link>
                        </>
                        )}

                    {status &&
                    <div className={classes.badge}>
                      <QuestionStatusBadge status={status}/>
                    </div>
                    }
                </div>
            </div>

            <div className={classes.rightSection}>
                {question.member && (
                    <div>Asked <i>{vm.askedAt}</i> by <Link to={Routes.forUserById(question.memberId)}><b>{vm.askedBy}</b></Link></div>
                )}
            </div>
        </div>
    );
}