import SubscriptionRepo from "./SubscriptionRepo";
import {
    CreateSubscriptionFileTypeRequest,
    SubscriptionFileType,
    UpdateSubscriptionFileTypeRequest
} from "../../models/SubscriptionFileType";
import {CreateSubscriptionRequest, UpdateSubscriptionRequest} from "../../models/Subscription";
import {ListQuery} from "../CrudRepo";
import {SubscriptionFileUploadRequest} from "../../models/SubscriptionFileUpload";
import {UpdateSubscriptionFileRecordBody} from "../../models/SubscriptionFileRecord";

export class InMemorySubscriptionRepoCache implements SubscriptionRepo {

    cachedSubscriptionFileTypeList?: SubscriptionFileType[];
    subscriptionFileTypePromise?: Promise<SubscriptionFileType[]>;

    constructor(private inner: SubscriptionRepo) {}

    async listAllSubscriptionFileTypes(): Promise<SubscriptionFileType[]> {
        if (this.cachedSubscriptionFileTypeList) {
            return this.cachedSubscriptionFileTypeList;
        } else {
            if (this.subscriptionFileTypePromise) {
                return this.subscriptionFileTypePromise;
            } else {
                const subscriptionFileTypesPromise = this.inner.listAllSubscriptionFileTypes();
                this.subscriptionFileTypePromise = subscriptionFileTypesPromise;
                const subscriptionFileTypes = await subscriptionFileTypesPromise;
                this.cachedSubscriptionFileTypeList = subscriptionFileTypes;
                this.subscriptionFileTypePromise = undefined;
                return subscriptionFileTypes;
            }
        }
    };

    createSubscription = (body: CreateSubscriptionRequest)  => this.inner.createSubscription(body);

    cancelSubscriptionById = (subscriptionId: string)  => this.inner.cancelSubscriptionById(subscriptionId);

    getSubscriptionById = (subscriptionId: string) => this.inner.getSubscriptionById(subscriptionId);

    listSubscriptionFileRecords = (query: ListQuery) => this.inner.listSubscriptionFileRecords(query);

    listSubscriptionFileTypes = (query: ListQuery) => this.inner.listSubscriptionFileTypes(query);

    listSubscriptionFileUploads = (query: ListQuery) => this.inner.listSubscriptionFileUploads(query);

    listSubscriptions = (query: ListQuery) => this.inner.listSubscriptions(query);

    updateSubscriptionById = (subscriptionId: string, body: UpdateSubscriptionRequest) => this.inner.updateSubscriptionById(subscriptionId, body);

    uploadSubscriptionFile = (body: SubscriptionFileUploadRequest) => this.inner.uploadSubscriptionFile(body);

    createSubscriptionFileType = (body: CreateSubscriptionFileTypeRequest) => this.inner.createSubscriptionFileType(body);

    updateSubscriptionFileTypeById = (subscriptionId: string, body: UpdateSubscriptionFileTypeRequest) => this.inner.updateSubscriptionFileTypeById(subscriptionId, body);

    updateSubscriptionFileRecordById = (recordId: string, body: UpdateSubscriptionFileRecordBody) => this.inner.updateSubscriptionFileRecordById(recordId, body);
}