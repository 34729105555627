import * as React from "react";
import TextInput from "../../../../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
}

export function UpdateStateApplicationSectionFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <TextInput name='description' label='Description' disabled={disabled}/>

            <TextInput name='subHeader' label='Sub-Header' multiline={true} rows={2} disabled={disabled}/>

            <TextInput name='sortOrder' label='Order' type={'number'} disabled={disabled}/>
            
            <TextInput multiline={true} rows={3} name='validate' label='Validate' helperText={'Custom json object to handle section validations.'} disabled={disabled}/>

        </>
    )
}

export function CreateStateApplicationSectionFields({ disabled }: Props) {
    return (
        <>
            <UpdateStateApplicationSectionFields disabled={disabled}/>
        </>
    )
}
