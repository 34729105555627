import { Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect } from "react";
import { User } from "../../../../models/User";
import { useRepo } from "../../../../repos/useRepo";
import { withModal } from "../../../modal";

export function LegalClubClientDetail(props: { user: User }) {
    const { user } = props;
    const { userRepo } = useRepo();


    const [clientData, setClientData] = React.useState<String>();

    const loadClientData = () => {
        setClientData(undefined);
        userRepo.getLegalClubClient(user.id).then((c) => setClientData(JSON.stringify(c, null, 2)));
    }

    const removeClient = () => {
        userRepo.removeLegalClubClient(user.id).then(loadClientData);;
    }

    const insertClient = () => {
        userRepo.createLegalClubClient(user.id).then((c) => setClientData(JSON.stringify(c, null, 2)));
    }

    useEffect(loadClientData, [user])

    return (
        <>
            <Grid container spacing={2} style={{minWidth: 550}}>
                <Grid item xs={4}>
                    <List>
                        <ListItem button onClick={loadClientData} color="primary">
                            <ListItemIcon>
                                <RefreshIcon />
                            </ListItemIcon>
                            <ListItemText primary="Refresh"/>
                        </ListItem>
                        <ListItem button onClick={insertClient}>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Insert Client" />
                        </ListItem>
                        <ListItem button onClick={removeClient}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Remove Client" />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={8}>
                    <div style={{ overflowX: 'auto' }}>
                        <pre>
                            {clientData}
                        </pre>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}


export const CreateLegalClubClientDetailModal = withModal(LegalClubClientDetail, 'User Legal Club Details');

