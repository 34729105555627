import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {Industry} from "../../../models/Industry";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export function IndustryPicker({ ...PickerProps }: Props) {
    const { industryRepo } = useRepo();
    const [industries, setIndustries] = useState<Industry[]>([]);

    useEffect(() => {
        industryRepo.listAll().then(setIndustries).catch(console.error);
    }, []);

    const options = useMemo(() => industries.map(p => ({ id: p.id, label: p.name || p.id })), [industries]);

    return <PickerInput label={'Industry'} options={options} {...PickerProps} />
}