import HttpService from "@gsb/react-http";
import {FaqStatus} from "../../../models/ti/FaqStatus";

export default interface FaqStatusRepo {
  listFaqStatuses(): Promise<FaqStatus[]>;
}

export class HttpFaqStatusRepo implements FaqStatusRepo {
  constructor(private http: HttpService) {}

  listFaqStatuses(): Promise<FaqStatus[]> {
    return this.http.get(['ti', 'v1', 'faqs', 'statuses']);
  }
}
