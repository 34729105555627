import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../../../repos/useRepo";
import {initialValuesForStateIdFieldTypeCreate, initialValuesForStateIdFieldTypeUpdate, createStateIdFieldTypeSchema, updateStateIdFieldTypeSchema} from "../../../../../utils/schemas/state-id-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../../rbac";
import {withModal} from "../../../../modal";
import {VerticalFormLayout} from "../../../../form/FormLayout";
import {CreateStateIdFieldTypeFields, UpdateStateIdFieldTypeFields} from "./FieldTypeFields";
import {FormSubmitButton} from "../../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../../form/helpers/formSubmit";
import { CreateFieldTypeRequest, FieldType, UpdateFieldTypeRequest } from "../../../../../models/sid/FieldType";

export function UpdateStateIdFieldTypeForm(props: { fieldType: FieldType, onFieldTypeUpdated: (fieldType: FieldType) => void }) {
    const { fieldType, onFieldTypeUpdated }  = props;
    const { fieldTypeRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.template.update);

    const onSubmit = updateSubmitHandler<UpdateFieldTypeRequest, FieldType>(
        body => {
            if(body.options && body.options.length > 0){
                body.options = JSON.parse(body.options);
            }
            return fieldTypeRepo.update(fieldType.id, body)
        },
        initialValuesForStateIdFieldTypeUpdate,
        onFieldTypeUpdated,
        console.error,
    );

    return (
        <Formik<UpdateFieldTypeRequest> enableReinitialize={true} initialValues={initialValuesForStateIdFieldTypeUpdate(fieldType)} validationSchema={updateStateIdFieldTypeSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateStateIdFieldTypeFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateStateIdFieldTypeForm(props: { onFieldTypeCreated: (fieldType: FieldType) => void }) {
    const { onFieldTypeCreated }  = props;
    const { fieldTypeRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateFieldTypeRequest) => {
            if(body.options && body.options.length > 0){
                body.options = JSON.parse(body.options);
            }
            return fieldTypeRepo.create(body)
        },
        onFieldTypeCreated,
        console.error
    );

    return (
        <Formik<CreateFieldTypeRequest> initialValues={initialValuesForStateIdFieldTypeCreate()} validationSchema={createStateIdFieldTypeSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateStateIdFieldTypeFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}


export const UpdateStateIdFieldTypeModal = withModal(UpdateStateIdFieldTypeForm, "Update Field Type");
export const CreateStateIdFieldTypeModal = withModal(CreateStateIdFieldTypeForm, "Create Field Type");