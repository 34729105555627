import { StatsReportResponse, StatsReportRequest } from "../../../models/Report";
import { Grid } from '@material-ui/core';
import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import { useRepo } from "../../../repos/useRepo";
import { StatsSearchForm } from './SearchForm';
import { StatsChartItem } from './StatsChartItem'
import { makeStyles } from '@material-ui/core/styles';
import "react-vis/dist/style.css";

export default function StatsReport() {

    const tableRef = useRef<any>(null);
    const { reportRepo } = useRepo();

    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<StatsReportResponse | undefined>();

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        chartItems: {
            padding: '25px'
        }
    }));

    const classes = useStyles();

    const initValues = {
        salesChannelId: '',
        resellerId: '',
        dateOn: moment().toISOString() // TODAY
    }

    const handleSearch = async (filters: StatsReportRequest) => {
        setLoading(true);
        const query = {
            ...filters,
            dateOn: filters.dateOn ? new Date(filters.dateOn).toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10)
        };

        const res = await reportRepo.statsReport(query);
        res.groupBy = (filters.salesChannelId || filters.resellerId ? 'reseller' : 'sales-channel');
        setResults(res);
        setLoading(false);
    }

    useEffect(() => {
        if (!results) {
            handleSearch(initValues);
        }
    });

    return (
        <Grid container spacing={3} className={classes.chartItems}>
            <Grid item xs={12}>
                <StatsSearchForm initValues={initValues} onSearch={handleSearch} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <StatsChartItem title='Active Subscriptions' data={results ? results.activeSubs : []} groupBy={results ? results.groupBy?.toString() : 'sales-channel'} caption='Total Active Subscritions' loading={loading} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <StatsChartItem title='Paid Subscriptions' data={results ? results.paidSubs : []} groupBy={results ? results.groupBy?.toString() : 'sales-channel'} caption='Total Paid Subs' loading={loading} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <StatsChartItem title='Paid New Subscriptions' data={results ? results.paidNewSubs : []} groupBy={results ? results.groupBy?.toString() : 'sales-channel'} caption='Total New Paid Subs On Date' loading={loading} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <StatsChartItem title='Canceled Subscriptions' data={results ? results.canceledSubs : []} groupBy={results ? results.groupBy?.toString() : 'sales-channel'} caption='Total Canceled Subs On Date' loading={loading} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <StatsChartItem title='Renewed Subscriptions' data={results ? results.renewedSubs : []} groupBy={results ? results.groupBy?.toString() : 'sales-channel'} caption='Total Renewed Subs On Date' loading={loading} />
            </Grid>
        </Grid>
    );
}