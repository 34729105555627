import {Employee} from "../../../../models/Employee";
import * as React from "react";
import {UpdateEmployeeForm} from "../EmployeeForm";

export default function EmployeeTab(props: { employee: Employee, onEmployeeUpdated: (employee: Employee) => void }) {
    const { employee, onEmployeeUpdated } = props;
    return (
        <div>
            <UpdateEmployeeForm employee={employee} onEmployeeUpdated={onEmployeeUpdated}/>
        </div>
    )
}
