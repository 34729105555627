import React, {useRef, useState} from 'react';
import Table, {TableColumn} from '../../../table/Table';
import {Paper} from '@material-ui/core';
import TableColumnFactory from '../../TableColumnFactory';
import {Product} from "../../../../models/Product";
import useCrudController from "../../useCrudController";
import {getTableDataFetcherFromRepoFetcher} from "../../../table/TableRepoAdapter";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";
import {useRepo} from "../../../../repos/useRepo";
import {CreateProductFormDialog} from "./ProductForm";
import {useNavigation} from "../../../../services/navigation/useNavigation";

export default function ProductList() {
    const { productRepo } = useRepo();
    const { navigateToProduct } = useNavigation();

    const { can } = useRbac();
    const canCreateProduct = can(actions.product.create);

    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { _list } = useCrudController(productRepo, reloadData);

    const columns: TableColumn<Product>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeProductIdColumn(),
        TableColumnFactory.fromProperty("Name", "name"),
        TableColumnFactory.fromProperty("Identifier", "identifier"),
    ]);

    function onProductCreated(product: Product) {
        setCreateDialogOpen(false);
        reloadData()
    }

    const deleteProduct = (product: Product) => productRepo.delete(product.id).then(reloadData).catch(console.error);

    return (
        <Paper>
            <Table
                tableRef={tableRef}
                title='Products'
                columns={columns}
                actions={[
                    makeCreateAction(() => setCreateDialogOpen(true), { hidden: !canCreateProduct }),
                    makeDeleteAction(deleteProduct)
                ]}
                data={getTableDataFetcherFromRepoFetcher(_list)}
                onRowClick={navigateToProduct}
            />
            <CreateProductFormDialog open={createDialogOpen}
                                     onClose={() => setCreateDialogOpen(false)}
                                     onProductCreated={onProductCreated}/>
        </Paper>
    );
}
