import * as Yup from 'yup';
import {MailchimpAccount} from "../../models/MailchimpAccount";

const baseFields = {
    name: Yup.string().required("Name is required"),
    apiKey: Yup.string().required("Api Key is required"),
    server: Yup.string().required("Server is required"),
    listId: Yup.string().required("Audience Id is required"),
    tag: Yup.string().nullable(),
    salesChannelId: Yup.string().required("Sales Channel is required"),
    resellerId: Yup.string().nullable()
};

export const createMailchimpAccountSchema = Yup.object().shape(baseFields);

export const updateMailchimpAccountSchema = Yup.object().shape(baseFields);

export const initialValuesForMailchimpAccount = (account?: MailchimpAccount) => ({
    name: account?.name ?? "",
    apiKey: account?.apiKey ?? "",
    server: account?.server ?? "",
    listId: account?.listId ?? "",
    tag: account?.tag ?? "",
    salesChannelId: account?.salesChannelId ?? "",
    resellerId: account?.resellerId ?? "",
});
