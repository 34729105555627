import React, {useEffect, useMemo, useState} from "react";
import "./SideBar.css";
import Subtitle from "../Subtitle";
import {useRepo} from "../../../../repos/useRepo";
import {Question} from "../../../../models/ti/Question";
import {Company} from "../../../../models/Company";
import {Consultant} from "../../../../models/ti/Consultant";
import {QuestionStatusIdentifier} from "../../../../models/ti/QuestionStatus";
import ConsultantPicker from "../../../form/pickers/ConsultantPicker";
import CompanyInfo, {DefaultCompanyViewModel} from "./CompanyInfo";
import {Member} from "../../../../models/ti/Member";
import DateFormatter from "../../../../utils/DateFormatter";
import {User} from "../../../../models/User";
import UserInfo, {DefaultUserViewModel} from "./UserInfo";
import QuestionInfo from "./QuestionInfo";
import {Link} from "react-router-dom";
import Routes from "../../../../Routes";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";

interface Props {
    question: Question;
    onQuestionChanged: (question: Question) => void;
}

export function SideBar({ question, onQuestionChanged }: Props) {
    const {questionRepo, userRepo} = useRepo();
    const { can } = useRbac();

    const [user, setUser] = useState<User>();
    const [company, setCompany] = useState<Company>();

    const userId = useMemo(() => question.member ? question.member.id : null, [question.member]);

    useEffect(() => {
        if (userId) {
            userRepo.getById(userId)
                .then(user => {
                    setUser(user);
                    if (user.company) {
                        setCompany(user.company)
                    }
                })
                .catch(console.error);
        }
    }, [userRepo, userId]);

    async function onReviewerAssigned(consultant: Consultant) {
        try {
            const updatedQuestion = await questionRepo.assignReviewerToQuestion(consultant.id, question.id);
            onQuestionChanged(updatedQuestion)

        } catch (err) {
            console.error(err)
        } finally {

        }
    }

    async function onAnswererAssigned(consultant: Consultant) {
        try {
            const updatedQuestion = await questionRepo.assignAnswererToQuestion(consultant.id, question.id);
            onQuestionChanged(updatedQuestion)

        } catch (err) {
            console.error(err)
        } finally {

        }
    }

    const isNotAnswered = question.status.identifier === QuestionStatusIdentifier.submitted
        || question.status.identifier === QuestionStatusIdentifier.researching
        || question.status.identifier === QuestionStatusIdentifier.onHold;

    const isInReview = question.status.identifier === QuestionStatusIdentifier.reviewing;

    const canAssignAnswerer = can(actions.question.assignAnswerer);
    const canAssignReviewer = can(actions.question.assignAnswerer);

    return (
        <div>
            <div>
                {user && (
                    <UserInfo title="User" vm={new DefaultUserViewModel(user)}/>
                )}

                {company && (
                    <CompanyInfo title="Company" vm={new DefaultCompanyViewModel(company)}/>
                )}
            </div>
            <div>
                <QuestionInfo title="Question" question={question} onClick={() => {}} />

                {isNotAnswered &&
                <div>
                  <Subtitle title="Assigned Answerer"/>
                  <ConsultantPicker consultant={question.assignedAnswerer} onConsultantSelected={onAnswererAssigned} disabled={!canAssignAnswerer}/>
                </div>
                }

                {isInReview &&
                <div>
                  <Subtitle title="Assigned Reviewer"/>
                  <ConsultantPicker consultant={question.assignedReviewer} onConsultantSelected={onReviewerAssigned} disabled={!canAssignReviewer}/>
                </div>
                }

                {question.member &&
                <AskedSection askedBy={question.member} askedAt={question.createdAt}/>
                }

                {question.updatedBy && question.updatedAt &&
                <UpdatedSection updatedBy={question.updatedBy}
                                updatedAt={question.updatedAt}/>
                }

                {question.reviewedBy && question.reviewedAt &&
                <ReviewSection reviewedBy={question.reviewedBy}
                               reviewedAt={question.reviewedAt}
                               notes={question.reviewNotes}/>
                }

                {question.releasedBy && question.releasedAt &&
                <ReleasedSection releasedBy={question.releasedBy}
                                 releasedAt={question.releasedAt}/>
                }

            </div>
        </div>
    );
}

function AskedSection(props: { askedBy: Member, askedAt: string }) {
    const { askedBy, askedAt } = props;
    return (
        <div style={{ marginTop: "8px", marginBottom: "8px"}}>
            <Subtitle title="Asked"/>
            <Event subject={askedBy.name} timestamp={askedAt}  href={Routes.forUserById(askedBy.id)}/>
        </div>
    )
}

function ReviewSection(props: { reviewedBy: Consultant, reviewedAt: string, notes?: string }) {
    const { reviewedBy, reviewedAt, notes } = props;
    return (
        <div style={{ marginTop: "8px", marginBottom: "8px"}}>
            <Subtitle title="Reviewed"/>

            <Event subject={reviewedBy.name} timestamp={reviewedAt}  href={Routes.forEmployeeById(reviewedBy.id)}/>

            {notes &&
            <p>{notes}</p>
            }
        </div>
    )
}

function UpdatedSection(props: { updatedBy: Consultant, updatedAt: string }) {
    const { updatedBy, updatedAt } = props;
    return (
        <div style={{ marginTop: "8px", marginBottom: "8px"}}>
            <Subtitle title="Updated"/>

            <Event subject={updatedBy.name} timestamp={updatedAt}  href={Routes.forEmployeeById(updatedBy.id)}/>
        </div>
    )
}

function ReleasedSection(props: { releasedBy: Consultant, releasedAt: string }) {
    const { releasedBy, releasedAt } = props;
    return (
        <div style={{ marginTop: "8px", marginBottom: "8px"}}>
            <Subtitle title="Released"/>

            <Event subject={releasedBy.name} timestamp={releasedAt} href={Routes.forEmployeeById(releasedBy.id)}/>
        </div>
    )
}

function Event(props: { timestamp: string, subject: string, href: string }) {
    const { timestamp, subject, href } = props;
    const timeDescription = DateFormatter.format(timestamp, 'MM/DD/YYYY');

    return (
        <>{timeDescription} by <Link to={href}><b>{subject}</b></Link></>
    )
}