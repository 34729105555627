import TableColumnFactory, {ColumnFactory} from "../../../entities/TableColumnFactory";
import {SiteTopic} from "../../../../models/ti/SiteTopic";
import {Topic} from "../../../../models/ti/Topic";

export class SiteTopicTableColumnFactory implements ColumnFactory<SiteTopic>{
    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<SiteTopic>([
            TableColumnFactory.makeSiteTopicIdColumn(),
            TableColumnFactory.makeTopicColumn("Topic", 'topicId', s => s.topic),
            {
                title: 'Featured',
                field: 'isFeatured',
                type: "boolean",
                sorting: true
            },
            {
                title: 'Sort Order',
                field: 'sortOrder',
                type: "numeric",
                sorting: true
            },
        ]);
    }
}
