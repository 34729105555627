import {Trigger} from "../../models/mail/Trigger";
import TriggerRepo from "./TriggerRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../models";
import {ListQuery} from "../CrudRepo";
import {sanitize} from "../../utils/FormUtils";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';

export default class HttpTriggerRepo extends HttpCrudRepo<Trigger> implements TriggerRepo {
    pathComponents = ['mail', 'v1', 'triggers'];

    list(query: ListQuery): Promise<CollectionResponse<Trigger>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        return this.http.get(this.pathComponents, {query: httpQuery});
    }
    update(id: string, resource: Partial<Trigger>): Promise<Trigger> {
        return this.http.put([...this.pathComponents, id], sanitize(resource))
    }

    listAll(): Promise<Trigger[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<Trigger>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
