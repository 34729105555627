import * as Yup from "yup";
import {Employee} from "../../models/Employee";

export const employeeSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Please enter a valid email address.').required('Email name is required'),
    companyId: Yup.string().nullable(),
    subjectId: Yup.string().nullable()
});

export const initialValuesForEmployee = (employee?: Employee) => ({
    firstName: employee?.firstName ?? '',
    lastName: employee?.lastName ?? '',
    email: employee?.email ?? '',
    companyId: employee?.companyId ?? '',
});

export const initialValuesForNewEmployee = (employee?: Employee) => ({
    ...initialValuesForEmployee(employee),
    subjectId: employee?.subjectId ?? ''
});
