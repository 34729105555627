import {useRepo} from "../../../../repos/useRepo";
import {default as React, useRef} from "react";
import Table, {TableColumn} from "../../../table/Table";
import {ColumnFactory} from "../../TableColumnFactory";
import {SendGridAccount} from "../../../../models/SendGridAccount";
import {SendGridAccountTableColumnFactory} from "./SendGridTableColumnFactory";
import useCrudController from "../../useCrudController";
import {getTableDataFetcherFromRepoFetcher} from "../../../table/TableRepoAdapter";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import useCreateSendGridAccountModal from "../../../modals/useCreateSendGridAccountModal";
import useUpdateSendGridAccountModal from "../../../modals/useUpdateSendGridAccountModal";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";

export default function SendGridAccountTable() {
    const { sendgridAccountRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canCreateSendGridAccount = can(actions.sendgridAccount.create);
    const canUpdateSendGridAccount = can(actions.sendgridAccount.update);
    const canDeleteSendGridAccount = can(actions.sendgridAccount.delete);

    const { setCreateSendGridAccountModalOpen, createSendGridAccountModal } = useCreateSendGridAccountModal(reloadData);
    const { setSendGridAccountForUpdate, updateSendGridAccountModal } = useUpdateSendGridAccountModal(reloadData);

    const {_list} = useCrudController(sendgridAccountRepo, reloadData);

    const columnFactory: ColumnFactory<SendGridAccount> = new SendGridAccountTableColumnFactory();
    const columns: TableColumn<SendGridAccount>[] = columnFactory.makeColumns();

    const deleteTemplate = (template: SendGridAccount) => {
        sendgridAccountRepo.delete(template.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onTemplateSelected = (template: SendGridAccount) => {
        if (canUpdateSendGridAccount) {
            setSendGridAccountForUpdate(template)
        }
    };

    return (
        <>
            <Table<SendGridAccount>
                tableRef={tableRef}
                title={`SendGrid Accounts`}
                columns={columns}
                actions={[
                    makeCreateAction(() => setCreateSendGridAccountModalOpen(true), { disabled: !canCreateSendGridAccount }),
                    makeDeleteAction(deleteTemplate, { disabled: !canDeleteSendGridAccount })
                ]}
                options={{}}
                onRowClick={onTemplateSelected}
                data={getTableDataFetcherFromRepoFetcher(_list)}
            />
            {createSendGridAccountModal}
            {updateSendGridAccountModal}
        </>
    );
}