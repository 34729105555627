import {Product} from "../../../../../models/Product";
import {default as React} from "react";
import PlanTable from "../../plans/PlanTable";

interface Props {
    product: Product;
}

export function PlansTab({ product }: Props) {
    return (
        <>
            <PlanTable product={product}/>
        </>
    )
}