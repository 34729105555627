import React, {useContext, createContext, } from 'react';
import {Company} from "../../models/Company";
import {Employee} from "../../models/Employee";
import {Product} from "../../models/Product";
import {Reseller} from "../../models/Reseller";
import {SalesChannel} from "../../models/SalesChannel";
import {User} from "../../models/User";
import Routes from "../../Routes";
import {
    makeActiveDirectoryLink,
    makeStripeCustomerLink,
    makeStripePlanLink, makeStripeProductLink,
    makeStripeSubscriptionLink
} from "../../factories/LinkFactory";
import {Application} from "../../models/Application";
import {Template} from "../../models/mail/Template";
import {Plan} from "../../models/Plan";
import {Site} from "../../models/ti/Site";
import {Topic} from "../../models/ti/Topic";
import {Question} from "../../models/ti/Question";
import {Faq} from "../../models/ti/Faq";
import {UrlTarget, UrlTargetType} from "../../config/Url";

export interface NavigationHandler {
    navigateToRoute: (route: string) => void;
    navigateToUrl: (url: string, target?: UrlTargetType) => void;

    navigateToLogin: (redirectUri?: string) => void;

    // External
    navigateToStripeCustomer: (stripeCustomerId: string) => void;
    navigateToStripeSubscription: (stripeSubscriptionId: string) => void;
    navigateToStripePlan: (stripePlanId: string) => void;
    navigateToStripeProduct: (stripeProductId: string) => void;

    navigateToActiveDirectoryUser: (subjectId: string) => void;
    navigateToActiveDirectoryB2CUser: (subjectId: string) => void;

    // Masterkey
    navigateToApplication: (application: Application) => void;
    navigateToApplications: () => void;

    navigateToCompanyById: (id: string) => void;
    navigateToCompany: (company: Company) => void;
    navigateToCompanies: () => void;

    navigateToProductById: (id:string) => void;
    navigateToProduct: (product: Product) => void;
    navigateToProducts: () => void;

    navigateToPlan: (plan: Plan) => void;
    navigateToPlans: () => void;

    navigateToResellerById: (id: string) => void;
    navigateToReseller: (reseller: Reseller) => void;
    navigateToResellers: () => void;

    navigateToSalesChannelById: (id: string) => void;
    navigateToSalesChannel: (salesChannel: SalesChannel) => void;
    navigateToSalesChannels: () => void;

    navigateToUserById: (id: string) => void;
    navigateToUser: (user: User) => void;
    navigateToUsers: () => void;

    // Employees
    navigateToEmployeeById: (id: string) => void;
    navigateToEmployee: (employee: Employee) => void;
    navigateToEmployees: () => void;

    // Mail
    navigateToTemplate: (template: Template) => void;
    navigateToTemplates: () => void;
    navigateToTriggers: () => void;
    navigateToSenders: () => void;

    // Tarkenton Institute
    navigateToSiteById: (id: string) => void;
    navigateToSite: (site: Site) => void;
    navigateToSites: () => void;

    navigateToTopic: (topic: Topic) => void;
    navigateToTopics: () => void;

    navigateToQuestion: (question: Question) => void;
    navigateToQuestions: () => void;
    navigateToAnswers: () => void;

    navigateToFaq: (faq: Faq) => void;
    navigateToFaqs: () => void;

    navigateToStateApplications: () => void;
    navigateToStateIdApplications: () => void;
}

export class RouteNavigationHandler implements NavigationHandler {
    constructor(public navigateToRoute: (route: string) => void,
                public navigateToUrl: (url: string, target?: UrlTargetType) => void) {}

    navigateToLogin = (redirectUri?: string) => {
        const route = redirectUri ? Routes.loginWithRedirect(redirectUri) : Routes.login;
        this.navigateToRoute(route);
    };

    openUrlInNewTab = (url: string) => this.navigateToUrl(url, UrlTarget.blank);

    // External
    navigateToStripeCustomer = (stripeCustomerId: string) => this.openUrlInNewTab(makeStripeCustomerLink(stripeCustomerId));
    navigateToStripeSubscription = (stripeSubscriptionId: string) => this.openUrlInNewTab(makeStripeSubscriptionLink(stripeSubscriptionId));
    navigateToStripePlan = (stripePlanId: string) => this.openUrlInNewTab(makeStripePlanLink(stripePlanId));
    navigateToStripeProduct = (stripeProductId: string) => this.openUrlInNewTab(makeStripeProductLink(stripeProductId));

    navigateToActiveDirectoryUser = (subjectId: string) => this.openUrlInNewTab(makeActiveDirectoryLink(subjectId));
    navigateToActiveDirectoryB2CUser = (subjectId: string) => this.openUrlInNewTab(makeActiveDirectoryLink(subjectId));

    // Masterkey
    navigateToApplication = (application: Application) => this.navigateToRoute(Routes.forApplicationById(application.id));
    navigateToApplications = () => this.navigateToRoute(Routes.applications);

    navigateToCompanyById = (id: string) => this.navigateToRoute(Routes.forCompanyById(id));
    navigateToCompany = (company: Company) => this.navigateToCompanyById(company.id);
    navigateToCompanies = () => this.navigateToRoute(Routes.companies);

    navigateToProductById = (id: string) => this.navigateToRoute(Routes.forProductById(id));
    navigateToProduct = (product: Product) => this.navigateToRoute(Routes.forProductById(product.id));
    navigateToProducts = () => this.navigateToRoute(Routes.products);

    // TODO: - Update when plan detail exists
    navigateToPlan = (plan: Plan) => {};
    navigateToPlans = () => {};

    navigateToResellerById = (id: string) => this.navigateToRoute(Routes.forResellerById(id));
    navigateToReseller = (reseller: Reseller) => this.navigateToResellerById(reseller.id);
    navigateToResellers = () => this.navigateToRoute(Routes.resellers);

    navigateToSalesChannelById = (id: string) => this.navigateToRoute(Routes.forSalesChannelById(id));
    navigateToSalesChannel = (salesChannel: SalesChannel) => this.navigateToSalesChannelById(salesChannel.id);
    navigateToSalesChannels = () => this.navigateToRoute(Routes.salesChannels);

    navigateToUserById = (id: string) => this.navigateToRoute(Routes.forUserById(id));
    navigateToUser = (user: User) => this.navigateToUserById(user.id);
    navigateToUsers = () => this.navigateToRoute(Routes.users);

    // Employees
    navigateToEmployeeById = (id: string) => this.navigateToRoute(Routes.forEmployeeById(id));
    navigateToEmployee = (employee: Employee) => this.navigateToEmployeeById(employee.id);
    navigateToEmployees = () => this.navigateToRoute(Routes.employees);

    // Mail
    navigateToTemplate = (template: Template) => this.navigateToRoute(Routes.mail.forTemplateById(template.id));
    navigateToTemplates = () => this.navigateToRoute(Routes.mail.templates);
    navigateToTriggers = () => this.navigateToRoute(Routes.mail.triggers);
    navigateToSenders = () => this.navigateToRoute(Routes.mail.senders);

    // Tarkenton Institute
    navigateToSiteById = (id: string) => this.navigateToRoute(Routes.ti.settings.forSiteById(id));
    navigateToSite = (site: Site) => this.navigateToSiteById(site.id);
    navigateToSites = () => this.navigateToRoute(Routes.ti.settings.sites);

    navigateToTopic = (topic: Topic) => this.navigateToRoute(Routes.ti.settings.forTopicById(topic.id));
    navigateToTopics = () => this.navigateToRoute(Routes.ti.settings.topics);

    navigateToQuestion = (question: Question) => this.navigateToRoute(Routes.ti.forQuestionById(question.id));
    navigateToQuestions = () => this.navigateToRoute(Routes.ti.questions);
    navigateToAnswers = () => this.navigateToRoute(Routes.ti.answers);

    navigateToFaq = (faq: Faq) => this.navigateToRoute(Routes.ti.forFaqById(faq.id));
    navigateToFaqs = () => this.navigateToRoute(Routes.ti.faqs);

    navigateToStateApplications = () => this.navigateToRoute(Routes.sid.settings.stateApplications);
    navigateToStateIdApplications = () => this.navigateToRoute(Routes.sid.stateIdApplications);
}

const NavigationContext = createContext<NavigationHandler>(null as unknown as NavigationHandler);

export const useNavigation = () => useContext(NavigationContext);

interface Props {
    handler: NavigationHandler;
}

export const NavigationProvider: React.FC<Props> = ({ children, handler }) => {
    return (
        <NavigationContext.Provider value={handler}>
            {children}
        </NavigationContext.Provider>
    );
};
