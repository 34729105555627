import {default as React, useState} from "react";
import {User} from "../../../../models/User";
import {CreateLegalClubClientDetailModal} from "./LegalClubClientDetail";


export default function useLegalClubClientDetailModal(user: User) {
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
        <CreateLegalClubClientDetailModal
            open={open}
            user={user}
            onClose={close}
        />
    );

    return { openLegalClubClientDetailModal: () => setOpen(true), legalClubClientDetailModal: modal }
}