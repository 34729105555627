import { MenuItem, MenuItemBuilder } from "../../menu/Menu";
import { User } from "../../../models/User";
import React from "react";
import {
    makeImpersonateUserAction,
    makeCreateUserB2CAction,
    makeViewInActiveDirectoryAction,
    makeUpdateUserPasswordAction,
    makeLegalClubClientDetailAction,
    makeMoveUserAction,
    makeDeleteAction
} from "../../../factories/MenuItemFactory";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../rbac";

export class UserMenuItemBuilder implements MenuItemBuilder {

    constructor(private user: User,
        private onUpdateUserPasswordClicked: () => void,
        private onViewActiveDirectoryUserClicked: (subjectId: string) => void,
        private onImpersonateUserClicked: () => void,
        private onLegalClubClientDetailClicked: () => void,
        private onCreateUserB2CClicked: () => void,
        private onMoveUserClicked: () => void,
        private onDeleteClicked: () => void) { }

    build = () => {
        const { can } = useRbac();
        const menuItems: MenuItem[] = [];
        const { subjectId } = this.user;

        if (subjectId) {
            menuItems.push(
                makeUpdateUserPasswordAction(this.onUpdateUserPasswordClicked),
                makeViewInActiveDirectoryAction(subjectId, this.onViewActiveDirectoryUserClicked),
                makeImpersonateUserAction(this.onImpersonateUserClicked),
            )
        } else {
            if (can(actions.user.createB2C)) {
                menuItems.push(
                    makeCreateUserB2CAction(this.onCreateUserB2CClicked)
                )
            }
        }

        if (can(actions.user.getLegalClubStatus)) {
            menuItems.push(makeLegalClubClientDetailAction(this.onLegalClubClientDetailClicked));
        }

        if (can(actions.user.move)) {
            menuItems.push(makeMoveUserAction(this.onMoveUserClicked));
        }

        if(can(actions.user.delete)) {
            menuItems.push(makeDeleteAction(this.onDeleteClicked));
        }

        return menuItems;
    }
}
