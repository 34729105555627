import React, { useMemo, useCallback } from "react";
import DemeanorPicker from "../../../../form/pickers/DemeanorPicker";
import CallTypePicker from "../../../../form/pickers/CallTypePicker";
import CallStatusPicker from "../../../../form/pickers/CallStatusPicker";
import CallSourcePicker from "../../../../form/pickers/CallSourcePicker";
import { CompanyUserPicker } from "../../../../form/pickers/UserPicker";
import TextInput from "../../../../form/inputs/TextInput";
import { useFormikContext } from "formik";
import { UpdateNoteForApplicationRequest } from "../../../../../models/sid/ApplicationNote";


interface Props {
    companyId: string | null,
    disabled?: boolean;
}

export function UpdateStateIdApplicationNoteFields({ companyId, disabled }: Props) {
    const { values, setFieldValue } = useFormikContext<UpdateNoteForApplicationRequest>();

    const { memberId } = values;
    const emptyGuid = '00000000-0000-0000-0000-000000000000';

    const isUserOther = useMemo(() => memberId === emptyGuid, [memberId]);

    useCallback(() => {
        if (memberId && memberId !== emptyGuid) {
            setFieldValue('userDisplayName', '');
        }
    }, [setFieldValue]);

    const optionalUser = { id: emptyGuid, firstName: 'OTHER', lastName: '', subjectId: '', email: '', companyId: '', salesChannelId: '', resellerId: '', createdAt: '', updatedAt: '' }


    return (
        <>
            <DemeanorPicker name='demeanor' label='Customer Demeanor' disabled={disabled} />

            <CallStatusPicker name='status' label='Call Status' disabled={disabled} />

            {companyId && (
                <>
                    <CompanyUserPicker companyId={companyId} name='memberId' label='User' disabled={disabled} allowEmpty={true} emptyValue={null} emptyValueDescription={'NONE'} allowOptionalUser={true} optionalUser={optionalUser} />

                    {isUserOther && (
                        <TextInput name='userDisplayName' label='User Other' disabled={disabled} />
                    )}
                </>
            )}

        </>
    )
}

export function CreateStateIdApplicationNoteFields({ companyId, disabled }: Props) {
    return (
        <>
            <UpdateStateIdApplicationNoteFields companyId={companyId} disabled={disabled} />
        </>
    )
}
