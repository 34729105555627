import * as React from "react";
import {useState} from "react";
import { StateApplicationField } from "../../models/sid/StateApplicationField";
import {UpdateStateApplicationFieldModal} from "../entities/state-id/settings/state-application/state-application-field/StateApplicationFieldForm";

export default function useCreateStateIdFieldTypeModal(onStateApplicationFieldUpdated: (stateApplication: StateApplicationField) => void) {

    const [stateApplicationField, setStateApplicationField] = useState<StateApplicationField>();

    const handleStateApplicationFieldUpdated = (stateApplication: StateApplicationField) => {
        setStateApplicationField(undefined);
        onStateApplicationFieldUpdated(stateApplication)
    };

    const modal = stateApplicationField ? (
        <UpdateStateApplicationFieldModal
            open={true}
            onClose={() => setStateApplicationField(undefined)}
            stateApplicationField={stateApplicationField}
            onStateApplicationFieldUpdated={handleStateApplicationFieldUpdated}
        />
    ) : null;

    return { setStateApplicationFieldModal: setStateApplicationField, updateStateApplicationFieldModal: modal }
}
