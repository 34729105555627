import {default as React, useState} from "react";
import {User} from "../../models/User";
import { MoveUserModal } from "../entities/users/UserMoveUserForm";
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from "@material-ui/core";

export default function useMoveUserModal(user: User, onUserMoved: () => void) {
    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState<{ msg?: string, severity?: "error" | "success" | "info" | "warning" | undefined, show: boolean}>({ show: false });

    const handleUserMoved = (msg?: string) => {

        if(msg){
          setToast({ msg, severity:'error', show: true });
        } else {
          setToast({ msg: `${user.firstName} User Moved Successfully!`, severity:'success', show: true });
          setOpen(false);
          onUserMoved();
        }
      };

    const modal = (
        <>
        <MoveUserModal
            open={open}
            onClose={() => setOpen(false)}
            user={user}
            onMoved={handleUserMoved}
        />
        <Snackbar open={toast.show} autoHideDuration={6000} onClose={() => setToast({show: false})}>
            <Alert severity={toast.severity}>{toast.msg}</Alert>
        </Snackbar>
    </>
    );

    return { openMoveUserModal: () => setOpen(true), moveUserModal: modal }
}