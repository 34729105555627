import {Company} from "../../../../models/Company";
import React from "react";
import CompanyWebsiteAnalysisTable from "../CompanyWebsiteAnalysisTable";
import {useNavigation} from "../../../../services/navigation/useNavigation";


export default function WebsiteAnalysisTab(props: { company: Company }) {

    const { company } = props;
    const { navigateToUser } = useNavigation();

    return (
        <>
            <CompanyWebsiteAnalysisTable company={company} />
        </>
    )
}
