import React from "react";
import ViewQuestion from "../questions/ViewQuestion";
import {Question} from "../../../models/ti/Question";
import {withModal} from "../../modal";

function ViewQuestionModal(props: { question: Question, onEditQuestion: (question: Question) => void }) {
    const { question, onEditQuestion } = props;

    return (
      <ViewQuestion question={props.question} onEditQuestionClicked={() => onEditQuestion(question)} />
    );
}

export default withModal(ViewQuestionModal, 'Question Details');
