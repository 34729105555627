import React, {useRef, useState} from 'react';
import {TableColumn} from '../../../table/Table';
import {Paper} from '@material-ui/core';
import TableColumnFactory from '../../TableColumnFactory';
import {SalesChannel} from "../../../../models/SalesChannel";
import EntityList from "../../EntityList";
import Detail from "./Detail";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {EntityDialog} from "../../EntityDialog";
import useCrudController from "../../useCrudController";
import {useRepo} from "../../../../repos/useRepo";
import {makeCreateAction} from "../../../table/TableActionFactory";

interface Props {
    onSalesChannelSelected: (salesChannel: SalesChannel) => void;
}

export default function List({ onSalesChannelSelected }: Props) {

    const { salesChannelRepo } = useRepo();
    const { can } = useRbac();

    const permissions = {
        update: can(actions.salesChannel.update),
        delete: can(actions.salesChannel.delete),
        create: can(actions.salesChannel.create)
    };

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const columns: TableColumn<SalesChannel>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeSalesChannelIdColumn(),
        TableColumnFactory.fromProperty("Name", "name"),
        TableColumnFactory.fromProperty("Identifier", "identifier"),
        TableColumnFactory.fromProperty("Domain", "domain"),
        TableColumnFactory.fromProperty("Sales Domain", "salesDomain"),
        {
            title: 'Allows Registration',
            field: 'allowsRegistration',
            type: 'boolean',
        },
        TableColumnFactory.fromProperty("Stripe Success URL", "stripeSuccessUrl"),
        TableColumnFactory.fromProperty("Stripe Cancel URL", "stripeCancelUrl"),
    ]);

    const [draft, setDraft] = useState<Partial<SalesChannel>>();

    const { _save, _delete, _list } = useCrudController(salesChannelRepo, reloadData);

    const [displayAddForm, setDisplayAddForm] = useState(false);

    function renderDetailDialog(onSave: () => Promise<void>,
                                onDelete: () => void,
                                onDismiss: () => void,
                                entity?: SalesChannel) {
        return (
            <EntityDialog
                permissions={permissions}
                onSave={onSave}
                onDelete={onDelete}
                onDismiss={onDismiss}
            >
                {(formProps) => (
                    <Detail
                        salesChannel={entity}
                        isReadOnly={!!entity && !permissions.update}
                        onValuesChange={setDraft}
                        {...formProps}
                    />
                )}
            </EntityDialog>
        )
    }

    const renderAddNewDialog = () => renderDetailDialog(
        _save(undefined, draft, () => setDisplayAddForm(false)),
        () => setDisplayAddForm(false),
        () => setDisplayAddForm(false),
    );

    return (
        <div>
            <Paper>
                <EntityList
                    title='Sales Channels'
                    columns={columns}
                    fetchData={_list}
                    actions={[
                        makeCreateAction(() => setDisplayAddForm(true), { hidden: !permissions.create }),
                    ]}
                    tableRef={tableRef}
                    renderDetail={(channel, onComplete) => renderDetailDialog(
                        _save(channel, draft, onComplete),
                        _delete(channel, onComplete),
                        onComplete,
                        channel
                    )}
                />
            </Paper>
            {displayAddForm && permissions.create && renderAddNewDialog()}
        </div>
    );
}
