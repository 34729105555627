import * as React from "react";
import TextInput from "../../../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
    stateWithholdingTaxLabel?: string | null;
    stateUnemploymentTaxLabel?: string | null;
}

export function UpdateApplicationStateIdFields({ disabled, stateUnemploymentTaxLabel, stateWithholdingTaxLabel }: Props) {

    return (
        <>
            {stateWithholdingTaxLabel && (
                <TextInput name='stateWithholdingTaxId' label={stateWithholdingTaxLabel} disabled={disabled} />
            )}

            {stateUnemploymentTaxLabel && (
                <TextInput name='stateUnemploymentTaxId' label={stateUnemploymentTaxLabel} disabled={disabled} />
            )}
        </>
    )
}

export function CreateApplicationStateIdFields({ disabled, stateUnemploymentTaxLabel, stateWithholdingTaxLabel }: Props) {
    return (
        <>
            <UpdateApplicationStateIdFields disabled={disabled} stateUnemploymentTaxLabel={stateUnemploymentTaxLabel} stateWithholdingTaxLabel={stateWithholdingTaxLabel} />
        </>
    )
}
