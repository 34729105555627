import React, {useCallback, useMemo} from "react";
import TextInput from "../../form/inputs/TextInput";
import TimeZonePicker from "../../form/pickers/TimeZonePicker";
import {ResellerPicker} from "../../form/pickers/ResellerPicker";
import {useFormikContext} from "formik";
import {CreateUserRequest} from "../../../models/User";
import config from "../../../config";
import {Divider} from "@material-ui/core";

interface Props {
    disabled?: boolean
}

export function UpdateUserFields({ disabled, resellerId }: Props & { resellerId: string }) {
    const isLegalShield = useMemo(() => resellerId === config.resellers.legalShield.id, [resellerId]);
    const isAdp = useMemo(() => config.adp.resellers.includes(resellerId), [resellerId]);

    return (
        <>
            <TextInput name="firstName" label="First Name" disabled={disabled}/>

            <TextInput name="lastName" label="Last Name" disabled={disabled}/>

            <TextInput name="phoneNumber" label="Phone Number" type={"phoneNumber"} disabled={disabled}/>

            <TimeZonePicker name='timeZone' disabled={disabled} allowEmpty={true} emptyValue='' emptyValueDescription='-'/>

            <Divider/>

            {isLegalShield && (
                <TextInput name="legalShieldMemberId" label="Legal Shield Member ID" disabled={disabled}/>
            )}

            {isAdp && (
                <TextInput name="adpAssociateId" label="ADP Associate ID" disabled={disabled}/>
            )}
        </>
    )
}

export function CreateUserFields({ disabled }: Props) {
    const { values, setFieldValue } = useFormikContext<CreateUserRequest>();
    const { resellerId } = values;

    const isLegalShield = useMemo(() => resellerId === config.resellers.legalShield.id, [resellerId]);
    const isAdp = useMemo(() => config.adp.resellers.includes(resellerId), [resellerId]);

    useCallback(() => {
        if (resellerId !== config.resellers.legalShield.id) {
            setFieldValue('legalShieldMemberId', '');
        }

        if (!config.adp.resellers.includes(resellerId)) {
            setFieldValue('adpClientId', '');
            setFieldValue('adpAssociateId', '');
            setFieldValue('adpOrganizationId', '');
        }
    }, [resellerId, setFieldValue])

    return (
        <>
            <ResellerPicker name="resellerId"/>

            <TextInput name="companyName" label="Company Name" disabled={disabled}/>

            <TextInput name="email" label="Email" type={"email"} disabled={disabled}/>

            <TextInput name="firstName" label="First Name" disabled={disabled}/>

            <TextInput name="lastName" label="Last Name" disabled={disabled}/>

            <TextInput name="phoneNumber" label="Phone Number" type={"phoneNumber"} disabled={disabled}/>

            <TimeZonePicker name='timeZone' disabled={disabled} allowEmpty={true} emptyValue='' emptyValueDescription='-'/>

            <Divider style={{ margin: 8 }}/>

            {isLegalShield && (
                <TextInput name="legalShieldMemberId" label="Legal Shield Member ID" disabled={disabled}/>
            )}

            {isAdp && (
                <>
                    <TextInput name="adpClientId" label="ADP Client ID" disabled={disabled}/>

                    <TextInput name="adpAssociateId" label="ADP Associate ID" disabled={disabled}/>

                    <TextInput name="adpOrganizationId" label="ADP Organization ID" disabled={disabled}/>
                </>
            )}
        </>
    );
}
