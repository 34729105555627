import React, {useEffect, useState} from 'react';
import EditQuestionForm from "./EditQuestionForm";
import {SideBar} from "./SideBar/Sidebar";
import Button from "@material-ui/core/Button";
import PreviousQuestionList from "./PreviousQuestionList";
import Divider from "@material-ui/core/Divider";
import StatusBar from "./StatusBar";
import Title from "./Title";
import {useRepo} from "../../../repos/useRepo";
import {Question} from "../../../models/ti/Question";

interface Props {
    question: Question;
    onQuestionUpdated: (question: Question) => void;
    onQuestionDeleted: (question: Question) => void;
    onViewQuestion: (question: Question) => void;
}

export default function EditQuestion({ question, onQuestionUpdated, onQuestionDeleted, onViewQuestion }: Props) {

    const { questionRepo } = useRepo();

    const deleteQuestion = () => {
        questionRepo.deleteQuestionById(question.id).then(() => onQuestionDeleted(question)).catch(console.error);
    };

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <div style={{ flexGrow: 8, marginRight: 22, minWidth: "300px"}}>

                    <StatusBar question={question}/>

                    <EditQuestionForm question={question}
                                      onQuestionChanged={onQuestionUpdated}
                                      onDelete={deleteQuestion}
                    />

                    <Divider style={{ margin: "16px"}} />

                    <div style={{ width: "100%"}}>
                        <PreviousQuestionSection question={question} onQuestionClicked={onViewQuestion}/>
                    </div>

                </div>

                <div className={"sticky-sidebar"} style={{maxHeight: "80vh", overflowY: "auto", minWidth: "300px", flex: 1 }}>
                    <SideBar question={question} onQuestionChanged={onQuestionUpdated} />
                </div>

            </div>
        </>
    );

}

function PreviousQuestionSection(props: { question: Question, onQuestionClicked: (question: Question) => void }) {
    const { questionRepo } = useRepo();
    const [previousQuestions, setPreviousQuestions] = useState<Question[]>();
    const { onQuestionClicked } = props;

    const [question, setQuestion] = useState<Question>(props.question);
    const [memberId, setMemberId] = useState<string>();
    const [companyId, setCompanyId] = useState<string>();

    useEffect(() => {
        setQuestion(props.question);
        setPreviousQuestions(undefined);
        setMemberId(question.memberId);
        setCompanyId(question.member?.companyId)
    }, [props.question, question.memberId]);

    async function onShowPreviousQuestionsClicked() {
        try {
            if(companyId){
                const res = await questionRepo.listPreviousQuestionsForCompany(companyId);
                setPreviousQuestions(res.results.filter(({id}) => id !== question.id))
            }
            else if (memberId) {
                const res = await questionRepo.listPreviousQuestionsForMember(memberId);
                setPreviousQuestions(res.results.filter(({id}) => id !== question.id))
            }
        } catch (err) {
            console.error(err)
        }
    }

    if (previousQuestions) {

        if (previousQuestions.length === 0) {
            return (<div>No questions to display.</div>)
        } else {
            return (
                <div>
                    <Title title="Previous Questions"/>
                    <PreviousQuestionList questions={previousQuestions} onQuestionClicked={onQuestionClicked}/>
                </div>
            )
        }

    } else {
        return (<Button style={{alignSelf: "center"}}
                        color={"primary"}
                        variant={"outlined"}
                        onClick={onShowPreviousQuestionsClicked}>View previous questions</Button>);
    }
}
