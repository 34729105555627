import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {CreateTemplateRequest, Template, UpdateTemplateRequest} from "../../../../models/mail/Template";
import {initialValuesForTemplate, createTemplateSchema, updateTemplateSchema} from "../../../../utils/schemas/template-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {CreateTemplateFields, UpdateTemplateFields} from "./TemplateFields";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdateTemplateForm(props: { template: Template, onTemplateUpdated: (template: Template) => void }) {
    const { template, onTemplateUpdated }  = props;
    const { templateRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.template.update);

    const onSubmit = updateSubmitHandler<UpdateTemplateRequest, Template>(
        body => templateRepo.update(template.id, body),
        initialValuesForTemplate,
        onTemplateUpdated,
        console.error,
    );

    return (
        <Formik<UpdateTemplateRequest> enableReinitialize={true} initialValues={initialValuesForTemplate(template)} validationSchema={updateTemplateSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateTemplateFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateTemplateForm(props: { onTemplateCreated: (template: Template) => void }) {
    const { onTemplateCreated }  = props;
    const { templateRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateTemplateRequest) => templateRepo.create(body),
        onTemplateCreated,
        console.error
    );

    return (
        <Formik<CreateTemplateRequest> initialValues={initialValuesForTemplate()} validationSchema={createTemplateSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateTemplateFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}


export const UpdateTemplateModal = withModal(UpdateTemplateForm, "Update Template");
export const CreateTemplateModal = withModal(CreateTemplateForm, "Create Template");