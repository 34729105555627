import * as React from "react";
import {useState} from "react";
import {User} from "../../models/User";
import {ImpersonateUserModal} from "../entities/users/ImpersonateUserForm";

export default function useImpersonateUserModal(user: User) {

    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
      <ImpersonateUserModal
        open={open}
        onClose={close}
        user={user}
      />
    );

    return {
        openImpersonateUserModal: () => setOpen(true),
        impersonateUserModal: modal
    }
}
