import React, { useRef, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Paper, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WebsiteAnalysisReportRequest } from "../../../models/Report";
import { useRepo } from "../../../repos/useRepo";
import { actions } from "../../../rbac";
import { useRbac } from "@gsb/react-rbac";
import MaterialTable, { Action, Column } from 'material-table';
import { WebsiteAnalysisSearchForm } from './SearchForm';
import Routes from '../../../Routes';
import theme from "../../../theme";
import moment from "moment";
import { WebsiteAnalysis } from '../../../models/WebsiteAnalysis';
import { Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

export const requiredActions = [
    actions.report.websiteAnalysis
];

export default function WebsiteAnalysisReportList() {

    const tableRef = useRef<any>(null);
    const { reportRepo } = useRepo();

    const { can } = useRbac();
    const canSendReminders = can(actions.report.websiteAnalysisSendReminder);

    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<WebsiteAnalysis[] | undefined>();
    const [reminderIds, setReminderIds] = React.useState<string[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [sending, setSending] = React.useState<boolean>(false);
    const [toast, setToast] = React.useState<{ msg?: string, severity?: "error" | "success" | "info" | "warning" | undefined, show: boolean }>({ show: false });

    const makeLink = (title: string, href: string) => (
        <Link to={href}
            onClick={e => { e.stopPropagation() }}
            style={{ color: theme.palette.secondary.dark }}>
            {title}
        </Link>
    )

    const columns: Column<WebsiteAnalysis>[] = [
        { title: 'Company Id', field: 'companyId', export: true, hidden: true },
        { title: 'Company', field: 'company.name', render: (entity) => { return makeLink(entity.company?.name || '', Routes.forCompanyById(entity.companyId)) } },
        { title: 'Website', field: 'url' },
        { title: 'Score', field: 'overallScore' },
        { title: 'Status', field: 'status' },
        { title: 'Status At', field: 'statusAt', type: 'date' },
        { title: 'Send To', field: 'sendReportTo' },
        { title: 'Sent Reminder', field: 'sentReminderAt', type: 'date' },
        { title: "Created At", field: "createdAt", type: "date", defaultSort: "desc" },
        { title: "Updated At", field: "updatedAt", type: "date" }
    ];

    const handleSearch = async (filters: WebsiteAnalysisReportRequest) => {
        setLoading(true);
        const query = {
            ...filters,
            dateStatusFrom: filters.dateStatusFrom ? new Date(filters.dateStatusFrom).toISOString().slice(0, 10) : null,
            dateStatusTo: filters.dateStatusTo ? new Date(filters.dateStatusTo).toISOString().slice(0, 10) : null,
            dateReminderFrom: filters.dateReminderFrom ? new Date(filters.dateReminderFrom).toISOString().slice(0, 10) : null,
            dateReminderTo: filters.dateReminderTo ? new Date(filters.dateReminderTo).toISOString().slice(0, 10) : null
        };
        const res = await reportRepo.websiteAnalysisReport(query);
        setResults(res);
        setLoading(false);
    }

    const handleClose = () => {
        setReminderIds([]);
        setOpen(false);
    }

    const handleSendReminders = async () => {
        try {
            setSending(true);
            if (reminderIds.length > 0) {
                const reminderCount = reminderIds.length;
                const failedIds = await reportRepo.websiteAnalysisSendReminder(reminderIds);
                setReminderIds([]);
                if (failedIds.length > 0) {
                    setToast({ msg: `${reminderCount - failedIds.length}/${reminderCount} Email reminders sent successfully!`, severity: 'warning', show: true });
                } else {
                    setToast({ msg: `${reminderCount} Email reminders sent successfully!`, severity: 'success', show: true });
                }
            }
        }
        catch (ex) { console.log(ex) }
        finally {
            setOpen(false);
            setSending(false);
        }
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }));

    const classes = useStyles();

    const initValues: WebsiteAnalysisReportRequest = {
        salesChannelId: null,
        resellerId: null,
        status: 'complete',
        dateStatusFrom: moment().startOf('year').toISOString(),
        dateStatusTo: moment().startOf('week').toISOString(),
        hasReminder: false,
        dateReminderFrom: null,
        dateReminderTo: null
    }

    const rowActions: Action<WebsiteAnalysis>[] = [];
    if (canSendReminders) {
        rowActions.push({
            tooltip: 'Send Reminder Email',
            icon: 'email',
            onClick: (evt: any, data) => {
                if (Array.isArray(data) && data.length > 0) {
                    setReminderIds(data.map(({ id }) => id));
                    setOpen(true);
                }
            }
        });
    }

    useEffect(() => {
        if (!results) {
            handleSearch(initValues);
        }
    }, [reportRepo]);

    return (
        <div>
            <Paper>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Filters</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <WebsiteAnalysisSearchForm initValues={initValues} onSearch={handleSearch} />
                    </AccordionDetails>
                </Accordion>

                <MaterialTable
                    style={{ maxWidth: '100%', marginTop: '30px' }}
                    isLoading={loading}
                    title='Website Analysis Report'
                    tableRef={tableRef}
                    columns={columns}
                    data={results ?? []}
                    options={{
                        search: true,
                        filtering: false,
                        paging: true,
                        toolbar: true,
                        exportButton: true,
                        selection: canSendReminders,
                        exportFileName: 'Website_Analysis_Report',
                        exportAllData: true,
                        rowStyle: (d, index, level) => ({
                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa'
                        }),
                    }}
                    actions={rowActions}
                />
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Send Website Analysis Reminder(s)"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Click 'Send' to email <strong>({reminderIds.length}) Companies</strong> the website analysis reminder message inviting them to re-analyze their website and review their results.<br /><br />
                        Emails will be sent to the company's primary contact email unless the report has an assigned "Send To" email address.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleSendReminders()} color="primary" autoFocus disabled={sending}>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={toast.show} autoHideDuration={6000} onClose={() => setToast({ show: false })}>
                <Alert severity={toast.severity}>{toast.msg}</Alert>
            </Snackbar>
        </div>
    );
}

