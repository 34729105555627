import React from "react";
import {Paper} from "@material-ui/core";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import EmployeeList from "./List";
import EmployeeCompanyList from "./companies/List";
import EmployeeRoleList from "./roles/List";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";

export const requiredActions = [
  actions.employee.list,
  actions.employeeCompany.list,
  actions.employeeRole.list,
]

export function Employees() {

    const { can } = useRbac();

    const canListEmployees = can(actions.employee.list);
    const canListEmployeeCompanies = can(actions.employeeCompany.list);
    const canListEmployeeRoles = can(actions.employeeRole.list);
    const shouldShowList = canListEmployees || canListEmployeeCompanies || canListEmployeeRoles;

    const items: TabNavigationItem[] = [
        {
            name: "Employees",
            identifier: 'employees',
            render: () => <EmployeeList/>,
            hidden: !canListEmployees
          },
          {
            name: "Companies",
            identifier: 'companies',
            render: () => <EmployeeCompanyList/>,
            hidden: !canListEmployeeCompanies
        },
        {
            name: "Roles",
            identifier: 'roles',
            render: () => <EmployeeRoleList/>,
            hidden: !canListEmployeeRoles
        },
    ];

    return (
        <div>
            {shouldShowList &&
            <Paper>
                <TabNavigation items={items} disabledContentPadding/>
            </Paper>
            }
        </div>
    )
}