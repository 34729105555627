import {Employee} from "../../../models/Employee";
import TableColumnFactory, {ColumnFactory} from "../TableColumnFactory";
import {Company} from "../../../models/Company";

export class EmployeeTableColumnFactory implements ColumnFactory<Employee>{

    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<Employee>([
            TableColumnFactory.makeEmployeeIdColumn(),
            TableColumnFactory.makeEmailColumn(),
            {
                title: 'First Name',
                field: 'firstName',
            },
            {
                title: 'Last Name',
                field: 'lastName',
            },
            TableColumnFactory.makeEmployeeCompanyColumn("Company", 'companyId', e => e.company),
            TableColumnFactory.makeActiveDirectoryColumn(employee => employee.subjectId),
        ])
    }
}
