import * as React from "react";
import TextInput from "../../../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
}

export function UpdateStateIdFieldTypeFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <TextInput name='description' label='Description' disabled={disabled}/>

            <TextInput name='component' label='Component' disabled={disabled}/>

            <TextInput multiline={true} rows={3} name='options' label='Options' disabled={disabled}/>

        </>
    )
}

export function CreateStateIdFieldTypeFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='id' label='Id' disabled={disabled}/>

            <UpdateStateIdFieldTypeFields disabled={disabled}/>
        </>
    )
}
