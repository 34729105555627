import TriggerRepo from "./TriggerRepo";
import {Trigger} from "../../models/mail/Trigger";
import {ListQuery} from "../CrudRepo";

export class InMemoryTriggerRepoCache implements TriggerRepo {
    cachedTriggerList?: Trigger[];
    triggerPromise?: Promise<Trigger[]>;

    constructor(private inner: TriggerRepo) {}

    async listAll(): Promise<Trigger[]> {
        if (this.cachedTriggerList) {
            return this.cachedTriggerList;
        } else {
            if (this.triggerPromise) {
                return this.triggerPromise;
            } else {
                const triggersPromise = this.inner.listAll();
                this.triggerPromise = triggersPromise;
                const triggers = await triggersPromise;
                this.cachedTriggerList = triggers;
                this.triggerPromise = undefined;
                return triggers;
            }
        }
    };

    create = (resource: Partial<Trigger>) => this.inner.create(resource);

    delete = (id: string) => this.inner.delete(id);

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<Trigger>) => this.inner.update(id, resource);
}