import React from "react";
import TextInput from "../../../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
}

export default function AdpEventFormFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='eventUrl'
                       label='Failed ADP Webhook URL'
                       //helperText='Enter a failed ADP webhook URL'
                       disabled={disabled} />
        </>
    )
}