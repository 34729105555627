import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {Consultant} from "../../../models/ti/Consultant";
import ConsultantPicker from "../../form/pickers/ConsultantPicker";
import {withModal} from "../../modal";

interface Props {
    onSubmit: (consultant?: Consultant) => void;
}

function AssignReviewerModal(props: Props) {
    const { onSubmit } = props;
    const [consultant, setConsultant] = useState<Consultant>();
    const isSubmitWithConsultantButtonDisabled = !consultant;

    return (
        <div style={{ padding: "12px", minWidth: 400 }}>
            <div style={{ marginBottom: "24px"}}>
                <h4>Assign a reviewer</h4>

                <ConsultantPicker onConsultantSelected={setConsultant}/>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <Button variant="contained"
                        style={{ marginRight: 6 }}
                        onClick={() => onSubmit()}>Submit without Reviewer</Button>

                <Button variant="contained"
                        color="primary"
                        style={{ marginLeft: 6 }}
                        disabled={isSubmitWithConsultantButtonDisabled}
                        disableElevation={true} // Without this, the button is a different size when disabled
                        onClick={() => onSubmit(consultant)}>Submit and Assign</Button>
            </div>

        </div>
    );
}

export default withModal(AssignReviewerModal, "Submit Question for Review");