import React from "react";
import {Paper} from "@material-ui/core";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";
import {useNavigation} from "../../../services/navigation/useNavigation";
import TopicTable from "./topics/TopicTable";
import SiteTable from "./sites/SiteTable";

export const requiredActions = [
  actions.site.list,
  actions.topic.list,
]

export default function TarkentonInstituteDashboard() {
    const { can } = useRbac();
    const { navigateToTopic } = useNavigation();

    const canListSites = can(actions.site.list);
    const canListTopics = can(actions.topic.list);
    const shouldShowList = canListSites || canListTopics;

    const items: TabNavigationItem[] = [
        {
            name: "Sites",
            identifier: "sites",
            render: () => <SiteTable/>,
            hidden: !canListSites,
        },
        {
            name: "Topics",
            identifier: "topics",
            render: () => <TopicTable onTopicSelected={navigateToTopic}/>,
            hidden: !canListTopics
        },
    ];

    if (shouldShowList) {
        return (
            <Paper>
                <TabNavigation items={items} disabledContentPadding/>
            </Paper>
        )
    } else {
        return null;
    }
}