import {Product} from "../../../../models/Product";
import {useRepo} from "../../../../repos/useRepo";
import {default as React, useEffect, useRef} from "react";
import Table, {TableColumn, TableQuery} from "../../../table/Table";
import {ProductLicense} from "../../../../models/ProductLicense";
import TableColumnFactory from "../../TableColumnFactory";
import {
    queryResultFromArray,
} from "../../../table/TableRepoAdapter";
import useUpdateProductLicenseModal from "../../../modals/useUpdateProductLicenseModal";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import useCreateProductLicenseForProductModal
    from "../../../modals/useCreateProductLicenseForProductModal";
import {useNavigation} from "../../../../services/navigation/useNavigation";

interface Props {
    product: Product,
}
export default function ProductLicenseTable({ product }: Props) {
    const { productLicenseRepo, productRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { navigateToApplication } = useNavigation();

    const { can } = useRbac();
    const canCreateProductLicense = can(actions.productLicense.create);
    const canUpdateProductLicense = can(actions.productLicense.update);
    const canDeleteProductLicense = can(actions.productLicense.delete);

    const { setProductForCreateProductLicenseModal, createProductLicenseForProductModal } = useCreateProductLicenseForProductModal(reloadData);
    const { setProductLicenseForUpdate, updateProductLicenseModal } = useUpdateProductLicenseModal(reloadData);

    useEffect(reloadData, [product]);


    const columns: TableColumn<ProductLicense>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeProductLicenseIdColumn(),
        TableColumnFactory.makeApplicationColumn("Application", "applicationId", p => p.application),
        {
            title: 'Base Users',
            field: 'baseUsers',
            type: 'numeric',
            emptyValue: '-'
        },
        TableColumnFactory.booleanColumn("Company License", "isCompanyLicense"),
        TableColumnFactory.fromProperty("Activation Type", "externalActivationType"),
    ]);

    const deleteProductLicense = (productLicense: ProductLicense) => {
        productLicenseRepo.delete(productLicense.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onProductLicenseSelected = (productLicense: ProductLicense) => {
        if (canUpdateProductLicense) {
            setProductLicenseForUpdate(productLicense)
        }
    };

    const fetchData = async (tableQuery: TableQuery<ProductLicense>) => {
        const res = await productRepo.listLicensesForProductById(product.id);
        return queryResultFromArray(res)
    };

    return (
        <>
            <Table<ProductLicense>
                tableRef={tableRef}
                title={`Licenses for ${product.name}`}
                columns={columns}
                actions={[
                    makeCreateAction(() => setProductForCreateProductLicenseModal(product), { disabled: !canCreateProductLicense }),
                    makeDeleteAction(deleteProductLicense, { disabled: !canDeleteProductLicense })
                ]}
                options={{
                    search: false,
                    filtering: false,
                    paging: false
                }}
                onRowClick={onProductLicenseSelected}
                data={fetchData}
            />
            {createProductLicenseForProductModal}
            {updateProductLicenseModal}
        </>
    );
}