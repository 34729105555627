import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {teal} from "@material-ui/core/colors";
import React from "react";

const tarkentonInstituteTheme = createMuiTheme({
  palette: {
    primary: teal
  }
});

export function withTarkentonInstituteTheme<T>(Component: React.ComponentType<T>): React.FC<T> {
  return (props) => {

    return (
        <ThemeProvider theme={tarkentonInstituteTheme}>
          <Component {...props}/>
        </ThemeProvider>
    )
  };
}