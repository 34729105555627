import { IconButton, Typography } from "@material-ui/core";
import React from "react";
import { FieldType } from "../../../models/sid/FieldType";
import PickerInput, { PickerOption } from "./PickerInput";
import TextInput from "./TextInput";
import { DateInput } from "./DateInput";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useField } from "formik";
import CheckboxInput from "./CheckboxInput";
import RadioButtonGroupInput from "./RadiobuttonGroupInput";

type Props = {
    fieldType: Pick<FieldType, "options" | "component"> & { sensitive?: boolean },
    name: string,
    label: string, 
    helperText?: string, 
    description?: string, 
    disabled?: boolean, 
    readOnly?: boolean, 
    emptyValueDescription?: string, 
    emptyValue?: string | number | null,
    onCopyClick?: (val: {name: string, value: string}) => void
};

export default function StateApplicationFieldInput({ fieldType, readOnly, onCopyClick, ...StateApplicationFieldProps }: Props) {

    const parseOptions = (options: { label: string, value: string }[]): PickerOption[] => {
        return options ? options.map((item) => ({ label: item.label, id: item.value, disabled: readOnly })) : [];
    }

    const { component, options } = fieldType;
    const [field, meta] = useField(StateApplicationFieldProps.name);

    const copyIcon = () => {
        if (readOnly === true && onCopyClick)
            return <IconButton onClick={() => onCopyClick({ name: StateApplicationFieldProps.label, value: field.value})} title="Copy Value"><FileCopyIcon /></IconButton>;

        return undefined;
    }

    switch (component) {
        case 'select':
            return (<PickerInput options={parseOptions(options || '')} {...StateApplicationFieldProps} SelectProps={{ readOnly }} />);
        case 'date':
            return (<DateInput {...StateApplicationFieldProps} InputProps={{ readOnly, endAdornment: copyIcon() }} />)
        case 'checkbox':
            if (readOnly) StateApplicationFieldProps.disabled = true;
            return (<CheckboxInput {...StateApplicationFieldProps} />)
        case 'radio':
            if (readOnly) StateApplicationFieldProps.disabled = true;
            return (<RadioButtonGroupInput options={parseOptions(options || [])} {...StateApplicationFieldProps} />)
        default:
            return (<TextInput {...StateApplicationFieldProps} type={fieldType.sensitive == true ? 'password' : undefined} InputProps={{ readOnly, endAdornment: copyIcon() }} />);
    }
}
