import moment from "moment";
import {red, yellow} from "@material-ui/core/colors";
import {Question} from "../../../models/ti/Question";
import DeadlineCalculator, {WarningLevel} from "../../../utils/DeadlineCalculator";
import config from "../../../config";

export class QuestionPresenter {

    getDeadlineColor(question: Question): string {
        if (question.status.identifier === config.questionStatuses.released.identifier || !question.createdAt) {
            return "transparent";
        }

        const warningLevel = DeadlineCalculator.getStatus(moment.utc(question.createdAt));

        switch (warningLevel) {
            case WarningLevel.approachingDeadline:
                return yellow[600].toString();
            case WarningLevel.passedDeadline:
                return red[600].toString();
            default:
                return "transparent";
        }
    }
}