import HttpService from "@gsb/react-http";
import {Consultant} from "../../../models/ti/Consultant";
import {CollectionResponse} from "../../../models";
import config from "../../../config";

export default interface ConsultantRepo {
  listAllConsultants(): Promise<Consultant[]>;
  listAnsweredByConsultants(): Promise<Consultant[]>;
  listAssignedReviewerByConsultants(): Promise<Consultant[]>;
}

export class HttpConsultantRepo implements ConsultantRepo {
  constructor(private http: HttpService) {
  }

  listAllConsultants(): Promise<Consultant[]> {
    const query = {
      pageSize: config.maxDropdownSize
    }

    return this.http
        .get<CollectionResponse<Consultant>>(['ti', 'v1', 'consultants'], {query})
        .then(res => res.results);
  };

  listAnsweredByConsultants(): Promise<Consultant[]> {
    return this.http.get(['ti', 'v1', 'consultants', 'answered-by']);
  }

  listAssignedReviewerByConsultants(): Promise<Consultant[]> {
    return this.http.get(['ti', 'v1', 'consultants', 'assigned-reviewers']);
  }
}
