import {FormGroup} from "@material-ui/core";
import * as React from "react";
import {CreateSalesChannelRequest, SalesChannel, UpdateSalesChannelRequest} from "../../../../models/SalesChannel";
import TextInput from "../../../form/inputs/TextInput";
import CheckboxInput from "../../../form/inputs/CheckboxInput";
import {useFormikContext} from "formik";
import {useEffect} from "react";

interface Props {
    salesChannel?: SalesChannel;
    disabled?: boolean;
}

export default function SalesChannelFields({ salesChannel, disabled }: Props) {
    const { values, setFieldValue } = useFormikContext<CreateSalesChannelRequest | UpdateSalesChannelRequest>();
    const { allowsRegistration } = values;

    useEffect(() => {
        if (!allowsRegistration) {
            setFieldValue('stripeSuccessUrl', '')
            setFieldValue('stripeCancelUrl', '')
        }
    }, [allowsRegistration]);

    return (
        <>
            <TextInput label={"Name"} name={"name"} disabled={disabled}/>

            <TextInput name={"identifier"} label="Identifier" disabled={disabled}/>

            <TextInput label={"Domain"} name={"domain"} disabled={disabled}/>

            <TextInput label={"Sales Domain"} name={"salesDomain"} disabled={disabled}/>

            <FormGroup row>
                <CheckboxInput name='allowsRegistration' label='Allows Registration' disabled={disabled}/>
            </FormGroup>

            <TextInput label={"Stripe Success URL"} name={"stripeSuccessUrl"} disabled={disabled || !allowsRegistration}/>

            <TextInput label={"Stripe Cancel URL"} name={"stripeCancelUrl"} disabled={disabled || !allowsRegistration}/>
        </>
    )
}