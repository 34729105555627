
import React from "react";
import { Application } from "../../../../../models/sid/Application";
import ApplicationNotesTable from "../notes/ApplicationNotesTable";

export default function NotesTab(props: { application: Application }) {

    const { application } = props;

    return (
        <>
            <ApplicationNotesTable application={application} />
        </>
    )
}
