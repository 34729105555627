import React, {useRef, useEffect} from 'react';
import {TableColumn} from '../../table/Table';
import {Accordion, AccordionDetails, AccordionSummary, makeStyles, Paper, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableColumnFactory from '../../entities/TableColumnFactory';
import {Company} from "../../../models/Company";
import {useRepo} from "../../../repos/useRepo";
import {actions} from "../../../rbac";
import MaterialTable from 'material-table';
import { CompanyMetadataSearchForm } from './SearchForm';
import MetadataField from '../../../models/MetadataField';
import {CompanyMetaReportRequest} from "../../../models/Report";
import Routes from "../../../Routes";
import moment from "moment";

export const requiredActions = [
  actions.company.list
];

export default function CompanyMetadataReportList() {

    const tableRef = useRef<any>(null);
    const { reportRepo } = useRepo();

    const [loading, setLoading] = React.useState(false);
    const [filters, setFilters] = React.useState<MetadataField[]>();
    const [results, setResults] = React.useState<Company[] | null>(null);

    const columns: TableColumn<Company>[] = TableColumnFactory.withTimestampColumns([
        { title: 'Company Id', field: 'id', export: true, hidden: true },
        { title: 'Company', field: 'name', render: (entity) => { return TableColumnFactory.makeLink(entity.name, Routes.forCompanyById(entity.id)) } },
        { title: 'Sales-Channel', field: 'salesChannel.name', render: (entity) => { return TableColumnFactory.makeLink(entity.salesChannel?.name ?? "", Routes.forSalesChannelById(entity.salesChannelId)) } },
        { title: 'Reseller', field: 'reseller.name', render: (entity) => { return TableColumnFactory.makeLink(entity.reseller?.name ?? "", Routes.forResellerById(entity.resellerId)) } },
        { title: 'Primary Contact', field: 'primaryContact.fullName', render: (entity) => { return TableColumnFactory.makeLink(entity.primaryContact?.fullName ?? "", Routes.forUserById(entity.primaryContactId ?? "")) } },
        TableColumnFactory.fromProperty("Email Address", "primaryContact.email"),
        TableColumnFactory.fromProperty("Phone Number", "primaryContact.phoneNumber"),
    ]);

    const handleSearch = async (filters: CompanyMetaReportRequest) => {
        setLoading(true);
        const res = await reportRepo.companyMetadataReport(filters);
        res.forEach((x) => {
            x.createdAt = moment(x.createdAt).format("MM/DD/YYYY");
            x.updatedAt = moment(x.updatedAt).format("MM/DD/YYYY");
           if(x.primaryContact) {
               x.primaryContact.fullName = `${x.primaryContact?.firstName ?? ""} ${x.primaryContact?.lastName ?? ""}`
           }
        });
        setResults(res);
        setLoading(false);
    }

    useEffect(() => {reportRepo.companyMetadataFields().then(setFilters)}, [reportRepo]);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }));

    const classes = useStyles();

    return (
        <div>
        <Paper>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {filters && (
                        <CompanyMetadataSearchForm initValues={filters} onSearch={handleSearch} />
                    )}
                </AccordionDetails>
            </Accordion>
            
            <MaterialTable
                style={{ maxWidth: '100%', marginTop: '30px' }}
                isLoading={loading}
                title='Company Metadata Report'
                tableRef={tableRef}
                columns={columns}
                data={results ?? []}
                options={{
                    search: true,
                    filtering: false,
                    paging: true,
                    toolbar: true,
                    exportButton: true,
                    exportFileName: 'Company_Metadata_Report',
                    exportAllData: true,
                    rowStyle: (d, index, level) => ({
                        backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa'
                    }),
                }}
            />
        </Paper>
    </div>
    );
}
