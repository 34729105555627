import React, {useEffect, useState} from "react";
import {StripeAccount} from "../../../../models/stripe/StripeAccount";
import {Company} from "../../../../models/Company";
import {useRepo} from "../../../../repos/useRepo";
import {StripeCustomer} from "../../../../models/stripe/StripeCustomer";
import LoadingIndicator from "../../../LoadingIndicator";
import {withModal} from "../../../modal";
import {Button} from "@material-ui/core";
import {makeStripeCustomerLink} from "../../../../factories/LinkFactory";
import {UrlTarget} from "../../../../config/Url";
import useLinkCompanyToExistingStripeCustomerModal
    from "../link-to-existing-stripe-customer/useLinkCompanyToExistingStripeCustomerModal";

interface Props {
    company: Company;
}

export function ViewCompanyInStripe({ company }: Props) {
    const { stripeRepo, companyRepo } = useRepo();

    const [stripeAccounts, setStripeAccounts] = useState<StripeAccount[]>()
    const [stripeCustomers, setStripeCustomers] = useState<StripeCustomer[]>()
    const [linking, setLinking] = useState(false)

    const loadStripeCustomers = () => {
        companyRepo.listStripeCustomersForCompanyById(company.id).then(setStripeCustomers).catch(console.error);
    }
    const loadStripeAccounts = () => {
        stripeRepo.listStripeAccounts().then(setStripeAccounts).catch(console.error);
    }

    const reloadData = () => {
        loadStripeCustomers()
        loadStripeAccounts()
    }
    const linkToStripeAccount = (stripeAccount: StripeAccount) => {
        setLinking(true)

        companyRepo.linkStripeCustomerForCompanyById(company.id, { stripeAccountIdentifier: stripeAccount.identifier })
            .then(loadStripeCustomers)
            .catch(console.error)
            .finally(() => setLinking(false))
    }

    useEffect(loadStripeAccounts, [stripeRepo]);
    useEffect(loadStripeCustomers, [company.id]);

    const { openLinkCompanyToExistingStripeCustomerModal, linkCompanyToExistingStripeCustomerModal } = useLinkCompanyToExistingStripeCustomerModal(company, reloadData);

    if (stripeAccounts && stripeCustomers) {
        return (
            <div style={{ marginTop: 12, marginBottom: 12 }}>
                <table>
                    <tr>
                        <th style={{ padding: 6, textAlign: 'left' }}>Stripe Account</th>
                        <th style={{ paddingLeft: 16, textAlign: 'right' }}>Stripe Customer</th>
                    </tr>

                    {stripeAccounts.map(stripeAccount => {
                        const stripeAccountIdentifier = stripeAccount.identifier;
                        const stripeCustomer = stripeCustomers?.find(c => c.stripeAccountIdentifier === stripeAccountIdentifier);
                        const stripeCustomerId = stripeCustomer?.stripeCustomerId;

                        const stripeCustomerCell = stripeCustomerId ? (
                            <a href={makeStripeCustomerLink(stripeCustomerId)} target={UrlTarget.blank}>{stripeCustomerId}</a>
                        ) : (
                            <Button variant='contained'
                                    color='secondary'
                                    disabled={linking}
                                    onClick={() => linkToStripeAccount(stripeAccount)}>
                                Link
                            </Button>
                        );

                        return (
                            <tr key={stripeAccountIdentifier} style={{ height: 48 }}>
                                <td style={{ padding: 6, textAlign: 'left' }}>{stripeAccount.name}</td>
                                <td style={{ paddingLeft: 16, textAlign: 'right' }}>{stripeCustomerCell}</td>
                            </tr>
                        )
                    })}
                </table>
                <a href='#'
                   style={{ marginLeft: 6, marginTop: 12 }}
                   onClick={openLinkCompanyToExistingStripeCustomerModal}>Or link to an existing Stripe Customer</a>
                {linkCompanyToExistingStripeCustomerModal}
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export const ViewCompanyInStripeModal = withModal(ViewCompanyInStripe);

