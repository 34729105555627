import React from "react";
import {Paper} from "@material-ui/core";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";
import SenderTable from "./senders/SenderTable";
import TemplateTable from "./templates/TemplateTable";

export const requiredActions = [
  actions.template.list,
  actions.sender.list,
]

export function Mail() {
    const { can } = useRbac();

    const canListTemplates = can(actions.template.list);
    const canListTriggers = can(actions.trigger.list);
    const canListSenders = can(actions.sender.list);
    const shouldShowList = canListTemplates || canListTriggers || canListSenders;

    const items: TabNavigationItem[] = [
        {
            name: "Templates",
            identifier: "templates",
            render: () => <TemplateTable/>,
            disabled: !canListTemplates
        },
        {
            name: "Senders",
            identifier: "senders",
            render: () => <SenderTable/>,
            disabled: !canListSenders
        },
        // TODO: - When mail triggers is a screen we prioritize for development
        // {
        //     name: "Triggers",
        //     identifier: "triggers",
        //     render: () => <TriggerTable/>,
        //     disabled: !canListTriggers
        // },
    ];

    if (shouldShowList) {
        return (
            <Paper>
                <TabNavigation items={items} disabledContentPadding/>
            </Paper>
        )
    } else {
        return null;
    }
}