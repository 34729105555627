import React from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {ListItemIcon} from '@material-ui/core';
import { useRbac } from '@gsb/react-rbac';
import { filterAllowedItems } from '../../routes/Utils';
import {Link} from "react-router-dom";

export interface NavigationGroup {
  title?: string;
  items: NavigationItem[];
}

export interface NavigationItem {
  key: string;
  title?: string;
  icon?: JSX.Element;
  route: string;
  disabled?: boolean;
}

export default function SideNav(props: { groups: NavigationGroup[] }) {
  const { groups } = props;
  const { can } = useRbac();

  return (
    <div>
      {filterAllowedItems(groups, can).map((group, index) => (
          <div key={index}>
            <Divider />
            <List>
              {group.items.map((item, index) => <SideNavItem key={index} item={item}/>)}
            </List>
          </div>
      ))}
    </div>
  );
}

function SideNavItem(props: { item: NavigationItem }) {
  const { item } = props;
  const { title, icon, disabled } = item;

  return (
      <Link to={item.route} style={{ textDecoration: 'none', color: icon ? "dimgray" : "inherit" }}>
        <ListItem button key={title} disabled={disabled}>
          {icon && (
              <ListItemIcon>
                {icon}
              </ListItemIcon>
          )}
          <ListItemText primary={title} style={{ color: 'black' }} />
        </ListItem>
      </Link>
  );
}
