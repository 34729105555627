import * as React from "react";
import {useState} from "react";
import {Sender} from "../../models/mail/Sender";
import {UpdateSenderModal} from "../entities/mail/senders/SenderForm";

export default function useUpdateSenderModal(onSenderUpdated: (sender: Sender) => void) {

    const [sender, setSender] = useState<Sender>();

    const handleSenderUpdated = (sender: Sender) => {
        setSender(undefined);
        onSenderUpdated(sender)
    };

    const modal = sender ? (
        <UpdateSenderModal
            open={true}
            onClose={() => setSender(undefined)}
            sender={sender}
            onSenderUpdated={handleSenderUpdated}
        />
    ) : null;

    return { setSenderForUpdate: setSender, updateSenderModal: modal }
}
