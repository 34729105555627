import { SubscriptionReportItem, SubscriptionReportRequest } from "../../../models/Report";
import { Paper } from '@material-ui/core';
import React, { useRef, useEffect } from "react";
import MaterialTable, { Column } from "material-table"
import moment from "moment";
import {Link} from "react-router-dom";
import { SubscriptionSearchForm } from './SearchForm';
import { useRepo } from "../../../repos/useRepo";
import theme from "../../../theme";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Routes from "../../../Routes";

export default function SubscriptionReport() {

    const tableRef = useRef<any>(null);
    const { reportRepo } = useRepo();

    const makeLink = (title: string, href: string) => (
        <Link to={href}
              onClick={e => { e.stopPropagation() }}
              style={{ color: theme.palette.secondary.dark }}>
          {title}
        </Link>
    )

    const columns: Column<SubscriptionReportItem>[] = [
        { title: 'Subscription Id', field: 'id', export: true, hidden: true },
        { title: 'Sales-Channel', field: 'salesChannelName', render: (entity) => { return makeLink(entity.salesChannelName, Routes.forSalesChannelById(entity.salesChannelId)) } },
        { title: 'Reseller', field: 'resellerName', render: (entity) => { return makeLink(entity.resellerName, Routes.forResellerById(entity.resellerId)) } },
        { title: 'Company', field: 'companyName', render: (entity) => { return makeLink(entity.companyName, Routes.forCompanyById(entity.companyId)) } },
        { title: "Product", field: "productName", render: (entity) => { return makeLink(entity.productName, Routes.forProductById(entity.productId)) } },
        { title: "Plan", field: "planName", emptyValue: "-" },
        { title: "Owner Email", field: "ownerEmail", emptyValue: "-" },
        { title: "Owner Name", field: "ownerName", emptyValue: "-", render: (entity) => { return makeLink(entity.ownerName, Routes.forUserById(entity.ownerId)) } },
        { title: "Phone", field: "ownerPhone", emptyValue: "-" },
        { title: "No-Charge", field: "noCharge", type: "boolean" },
        { title: "Purchased", field: "purchasedAt", type: "date", defaultSort: "desc" },
        { title: "Renewed", field: "renewedAt", type: "date", emptyValue: "-" },
        { title: "Expires", field: "expiresAt", type: "date", emptyValue: "-" },
        { title: "Cancelled", field: "canceledAt", type: "date", emptyValue: "-" }
    ];

    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<SubscriptionReportItem[] | null>(null);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }));

    const classes = useStyles();

    const initValues = {
        salesChannelId: '',
        resellerId: '',
        productId: '',
        renewed: null,
        subscriptionStatus: '',
        dateFrom: moment().startOf('month').toISOString(),
        dateTo: '',
    }

    const handleSearch = async (filters: SubscriptionReportRequest) => {
        setLoading(true);
        const query = {
            ...filters,
            dateFrom: filters.dateFrom ? new Date(filters.dateFrom).toISOString().slice(0,10) : null,
            dateTo: filters.dateTo ? new Date(filters.dateTo).toISOString().slice(0,10) : null
        };
        const res = await reportRepo.subscriptionReport(query);
        setResults(res);
        setLoading(false);
    }

    useEffect(() => {
        if(!results){
            handleSearch(initValues);
        }
    });

    return (
        <div>
            <Paper>
                <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Filters</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <SubscriptionSearchForm initValues={initValues} onSearch={handleSearch} />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                
                <MaterialTable
                    style={{ maxWidth: '100%', marginTop: '30px' }}
                    isLoading={loading}
                    title='Subscriptions Report'
                    tableRef={tableRef}
                    columns={columns}
                    data={results ?? []}
                    
                    options={{
                        search: true,
                        filtering: false,
                        paging: true,
                        toolbar: true,
                        exportButton: true,
                        exportFileName: 'Subscription_Report',
                        exportAllData: true,
                        rowStyle: (d, index, level) => ({
                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa'
                        }),
                    }}
                />
            </Paper>
        </div>
    );
}
