import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import BusinessType from "../../../models/BusinessType";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions{}

export default function BusinessTypePicker({ ...PickerProps }: Props) {
    const { businessTypeRepo } = useRepo();

    const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);

    useEffect(() => {
        businessTypeRepo.list().then(setBusinessTypes).catch(console.error);
    }, [businessTypeRepo]);

    const options = useMemo(() => businessTypes.map(t => ({ id: t.value, label: t.label })), [businessTypes]);

    return <PickerInput label={'Business Type'} options={options} {...PickerProps} />
}