import {Company} from "../../../../models/Company";
import {Form, Formik} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {withModal} from "../../../modal";
import {
    companyResellerSchema,
    initialValuesForCompanyReseller
} from "../../../../utils/schemas/company-schema";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import { updateSubmitHandler } from "../../../form/helpers/formSubmit";
import { ResellerPicker } from "../../../form/pickers/ResellerPicker";


export function UpdateCompanyResellerForm(props: { company: Company, onCompanyUpdated: (company:Company) => void }) {
    const { company, onCompanyUpdated }  = props;
    const { companyRepo } = useRepo();
    const { can } = useRbac();
    const canUpdateReseller = can(actions.company.updateReseller);

    const onSubmit = updateSubmitHandler(
        (body: { resellerId: string }) => companyRepo.changeResellerForCompanyById(company.id, body.resellerId),
        initialValuesForCompanyReseller,
        onCompanyUpdated,
        console.error,
    );

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValuesForCompanyReseller(company)}
                onSubmit={onSubmit}
                validationSchema={companyResellerSchema}>
            <Form>
                <VerticalFormLayout fieldset={
                    <ResellerPicker name={'resellerId'} />
                }
                bottomContent={<FormSubmitButton variant='Update' disabled={!canUpdateReseller}/>}/>
            </Form>
        </Formik>
    )
}

export const CreateCompanyResellerModal = withModal(UpdateCompanyResellerForm, 'Change Company Reseller');
