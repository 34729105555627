import * as React from "react";
import {ResellerPicker} from "../../../form/pickers/ResellerPicker";
import TextInput from "../../../form/inputs/TextInput";
import StripeAccountPicker from "../../../form/pickers/StripeAccountPicker";
import {useFormikContext} from "formik";
import {CreatePlanRequest, UpdatePlanRequest} from "../../../../models/Plan";

interface Props {
    disabled?: boolean;
}

export function UpdatePlanFields({ disabled }: Props) {
    const context = useFormikContext<UpdatePlanRequest | CreatePlanRequest>();

    const { stripeAccountIdentifier } = context.values;

    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <TextInput name='identifier' label='Identifier' disabled={disabled}/>

            <TextInput name='description' label='Description' disabled={disabled}/>

            <StripeAccountPicker name={'stripeAccountIdentifier'} disabled={disabled}/>

            {stripeAccountIdentifier && (
                <TextInput name='stripePlanId' label='Stripe Price or Plan ID' disabled={disabled}/>
            )}

            <TextInput name='adpPlanId' label='ADP Plan ID' disabled={disabled}/>

            <TextInput name='appStoreProductId' label='App Store Product ID' disabled={disabled}/>
        </>
    )
}

export function CreatePlanForProductFields({ disabled }: Props) {
    return (
        <>
            <ResellerPicker name={"resellerId"} disabled={disabled}/>

            <UpdatePlanFields disabled={disabled}/>
        </>
    )
}
