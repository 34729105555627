import React, {useRef} from 'react';
import Table, {TableColumn} from '../../table/Table';
import {Paper} from '@material-ui/core';
import TableColumnFactory from '../../entities/TableColumnFactory';
import useCrudController from "../../entities/useCrudController";
import {useRepo} from "../../../repos/useRepo";
import {getTableDataFetcherFromRepoFetcher} from "../../table/TableRepoAdapter";
import {actions} from "../../../rbac";
import { UserSignIn } from '../../../models/User';

export const requiredActions = [
  actions.company.list
];

export default function CompanyList() {
    const { userSignInRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const columns: TableColumn<UserSignIn>[] = [
        TableColumnFactory.makeUserSignInIdColumn(),
        TableColumnFactory.fromProperty("First Name","firstName"),
        TableColumnFactory.fromProperty("Last Name","lastName"),
        TableColumnFactory.fromProperty("Company Id","companyId"),
        TableColumnFactory.makeCompanyNameColumn('Company', 'name', c => c.company),
        TableColumnFactory.fromProperty("Source","source"),
        TableColumnFactory.fromProperty("User Type","userType"),
        TableColumnFactory.fromProperty("Session","sessionId"),
        TableColumnFactory.fromProperty("OOID","adpOrganizationId"),
        TableColumnFactory.fromProperty("IID","adpClientId"),
        TableColumnFactory.dateTimeColumn("Signed In At", "signedInAt")
    ];

    const {_list} = useCrudController(userSignInRepo, reloadData);
    const tableOptions = { search: false,
        exportButton: true,
        exportFileName: 'User_Sign-In_Report',
        exportAllData: true, }

    return (
        <>
            <Paper>
                <Table<UserSignIn>
                    title='User Sign In Report'
                    columns={columns}
                    data={getTableDataFetcherFromRepoFetcher(_list)}
                    tableRef={tableRef}
                    options={tableOptions}
                />
            </Paper>
        </>
    );
}
