import {BreadcrumbItem} from "../components/layout/Breadcrumbs";
import {SalesChannel} from "../models/SalesChannel";
import {navigationItems} from "../navigation-items";
import {Reseller} from "../models/Reseller";
import {Company} from "../models/Company";
import {Application} from "../models/Application";
import {User} from "../models/User";
import {Product} from "../models/Product";
import {Employee} from "../models/Employee";
import {Site} from "../models/ti/Site";
import {Topic} from "../models/ti/Topic";
import {Faq} from "../models/ti/Faq";
import {Question} from "../models/ti/Question";
import { StateApplication } from "../models/sid/StateApplication";
import { Application as StateIdApplication } from "../models/sid/Application";

export interface BreadcrumbFactory {
    makeBreadcrumbItems(): BreadcrumbItem[];
}

export class TopicDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private topic: Topic, private onTopicsClicked: () => void) {
    }

    makeBreadcrumbItems(): BreadcrumbItem[] {
        return [
            {
                title: "Topics",
                onClick: this.onTopicsClicked
            },
            {
                title: this.topic.name
            }
        ];
    }
}



export class SiteDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private site: Site, private onSitesClicked: () => void) {
    }

    makeBreadcrumbItems(): BreadcrumbItem[] {
        return [
            {
                title: "Sites",
                onClick: this.onSitesClicked
            },
            {
                title: `${this.site.salesChannelName} - ${this.site.resellerName}`
            }
        ];
    }
}

export class SalesChannelDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private salesChannel: SalesChannel, private onSalesChannelsClicked: () => void) {
    }

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.salesChannels;
        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onSalesChannelsClicked
            },
            {
                title: this.salesChannel.name
            }
        ];
    }
}

export class ResellerDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private reseller: Reseller, private onResellersClicked: () => void) {
    }

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.resellers;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onResellersClicked
            },
            {
                title: this.reseller.name
            }
        ];
    }
}


export class CompanyDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private company: Company, private onCompaniesClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.companies;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onCompaniesClicked
            },
            {
                title: this.company.name
            }
        ];
    }
}

export class StateApplicationDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private stateApplication: StateApplication, private onStateApplicationsClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.stateApplicationDetail;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onStateApplicationsClicked
            },
            {
                title: this.stateApplication.name
            }
        ];
    }
}

export class StateIdApplicationDetailBreadcrumbFactory implements BreadcrumbFactory {
    constructor(private application: StateIdApplication, private onStateIdApplicationsClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.stateIdApplications;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onStateIdApplicationsClicked
            },
            {
                title: this.application.legalName
            }
        ];
    }
}

export class ApplicationDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private application: Application, private onApplicationsClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.applications;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onApplicationsClicked
            },
            {
                title: this.application.name
            }
        ];
    }
}

export class UserDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private user: User, private onUsersClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.users;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onUsersClicked
            },
            {
                title: `${this.user.firstName} ${this.user.lastName}`
            }
        ];
    }
}

export class ProductDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private product: Product, private onProductsClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.products;
        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onProductsClicked
            },
            {
                title: this.product.name
            }
        ];
    }
}


export class EmployeeDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private employee: Employee, private onEmployeesClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.employees;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onEmployeesClicked
            },
            {
                title: `${this.employee.firstName} ${this.employee.lastName}`
            }
        ];
    }
}


export class FaqDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private faq: Faq, private onFaqClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.faqs;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onFaqClicked
            },
            {
                title: this.faq.title || this.faq.id
            }
        ];
    }
}

export class QuestionDetailBreadcrumbFactory implements BreadcrumbFactory {

    constructor(private question: Question, private onQuestionClicked: () => void) {}

    makeBreadcrumbItems(): BreadcrumbItem[] {
        const item = navigationItems.questions;

        return [
            {
                title: item.title || item.key,
                icon: item.icon,
                onClick: this.onQuestionClicked
            },
            {
                title: this.question.title || this.question.id
            }
        ];
    }
}
