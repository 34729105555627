import React from 'react';
import {EmployeeCompany} from "../../../../models/EmployeeCompany";
import {DialogChildProps} from "../../EntityDialog";
import Form from "../../../form/Form";
import TextInput from "../../../form/inputs/TextInput";
import {
    employeeCompanySchema,
    initialValuesForEmployeeCompany
} from "../../../../utils/schemas/employee-company-schema";

interface Props extends DialogChildProps {
  company?: EmployeeCompany;
  onValuesChange?: (draft: Partial<EmployeeCompany>) => void;
  isReadOnly?: boolean
}

export default function Detail({ company, isReadOnly, ...formProps}: Props) {
  return (
      <Form
          disabled={isReadOnly}
          validationSchema={employeeCompanySchema}
          initialValues={initialValuesForEmployeeCompany(company)}
          {...formProps}
      >
        <TextInput name={"name"} label="Name" disabled={isReadOnly}/>
      </Form>
  );
}
