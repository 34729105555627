import * as React from "react";
import {useState} from "react";
import { Application } from "../../models/sid/Application";
import { ApplicationAssignStatusModal } from "../entities/state-id/applications/ApplicationAssignStatusForm";

export default function useAssignStatusToApplicationModal(applicationId: string, onStatusAssigned: (application: Application) => void) {

    const [open, setOpen] = useState<boolean>(false);

    const handleStatusAssigned = (application: Application) => {
        setOpen(false);
        onStatusAssigned(application);
    };

    const modal = (
        <ApplicationAssignStatusModal
            open={open}
            title={"Assign Status to Application"}
            onClose={() => setOpen(false)}
            onApplicationAssignUpdated={handleStatusAssigned}
            applicationId={applicationId}
        />
    );

    return { openAssignStatusToApplicationModal: () => setOpen(true), assignStatusToApplicationModal: modal }
}
