export interface TimeZone {
    name: string;
    value: string;
    zone: string;
    country?: string;
}

const timeZones: TimeZone[] = [
    {
        name: "(UTC-12:00) International Date Line West",
        value: "Dateline Standard Time",
        zone: "Etc/GMT+12"
    },
    {
        name: "(UTC-11:00) Coordinated Universal Time-11",
        value: "UTC-11",
        zone: "Etc/GMT+11"
    },
    {
        name: "(UTC-10:00) Aleutian Islands",
        value: "Aleutian Standard Time",
        zone: "America/Adak"
    },
    {
        name: "(UTC-10:00) Hawaii",
        value: "Hawaiian Standard Time",
        zone: "Pacific/Honolulu",
        country: "USA"
    },
    {
        name: "(UTC-09:30) Marquesas Islands",
        value: "Marquesas Standard Time",
        zone: "Pacific/Marquesas"
    },
    {
        name: "(UTC-09:00) Alaska",
        value: "Alaskan Standard Time",
        zone: "America/Anchorage",
        country: "USA"
    },
    {
        name: "(UTC-09:00) Coordinated Universal Time-09",
        value: "UTC-09",
        zone: "Etc/GMT+9"
    },
    {
        name: "(UTC-08:00) Baja California",
        value: "Pacific Standard Time (Mexico)",
        zone: "America/Tijuana"
    },
    {
        name: "(UTC-08:00) Coordinated Universal Time-08",
        value: "UTC-08",
        zone: "Etc/GMT+8"
    },
    {
        name: "(UTC-08:00) Pacific Time (US & Canada)",
        value: "Pacific Standard Time",
        zone: "America/Los_Angeles",
        country: "USA"
    },
    {
        name: "(UTC-07:00) Arizona",
        value: "US Mountain Standard Time",
        zone: "America/Phoenix",
        country: "USA"
    },
    {
        name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
        value: "Mountain Standard Time (Mexico)",
        zone: "America/Chihuahua"
    },
    {
        name: "(UTC-07:00) Mountain Time (US & Canada)",
        value: "Mountain Standard Time",
        zone: "America/Denver",
        country: "USA"
    },
    {
        name: "(UTC-07:00) Yukon",
        value: "Yukon Standard Time",
        zone: "America/Whitehorse"
    },
    {
        name: "(UTC-06:00) Central America",
        value: "Central America Standard Time",
        zone: "America/Guatemala"
    },
    {
        name: "(UTC-06:00) Central Time (US & Canada)",
        value: "Central Standard Time",
        zone: "America/Chicago",
        country: "USA"
    },
    {
        name: "(UTC-06:00) Easter Island",
        value: "Easter Island Standard Time",
        zone: "Pacific/Easter"
    },
    {
        name: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
        value: "Central Standard Time (Mexico)",
        zone: "America/Mexico_City"
    },
    {
        name: "(UTC-06:00) Saskatchewan",
        value: "Canada Central Standard Time",
        zone: "America/Regina"
    },
    {
        name: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
        value: "SA Pacific Standard Time",
        zone: "America/Bogota"
    },
    {
        name: "(UTC-05:00) Chetumal",
        value: "Eastern Standard Time (Mexico)",
        zone: "America/Cancun"
    },
    {
        name: "(UTC-05:00) Eastern Time (US & Canada)",
        value: "Eastern Standard Time",
        zone: "America/New_York",
        country: "USA"
    },
    {
        name: "(UTC-05:00) Haiti",
        value: "Haiti Standard Time",
        zone: "America/Port-au-Prince"
    },
    {
        name: "(UTC-05:00) Havana",
        value: "Cuba Standard Time",
        zone: "America/Havana"
    },
    {
        name: "(UTC-05:00) Indiana (East)",
        value: "US Eastern Standard Time",
        zone: "America/Indiana/Indianapolis"
    },
    {
        name: "(UTC-05:00) Turks and Caicos",
        value: "Turks and Caicos Standard Time",
        zone: "America/Grand_Turk"
    },
    {
        name: "(UTC-04:00) Asuncion",
        value: "Paraguay Standard Time",
        zone: "America/Asuncion"
    },
    {
        name: "(UTC-04:00) Atlantic Time (Canada)",
        value: "Atlantic Standard Time",
        zone: "America/Halifax"
    },
    {
        name: "(UTC-04:00) Caracas",
        value: "Venezuela Standard Time",
        zone: "America/Caracas"
    },
    {
        name: "(UTC-04:00) Cuiaba",
        value: "Central Brazilian Standard Time",
        zone: "America/Cuiaba"
    },
    {
        name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
        value: "SA Western Standard Time",
        zone: "America/La_Paz"
    },
    {
        name: "(UTC-04:00) Santiago",
        value: "Pacific SA Standard Time",
        zone: "America/Santiago"
    },
    {
        name: "(UTC-03:30) Newfoundland",
        value: "Newfoundland Standard Time",
        zone: "America/St_Johns"
    },
    {
        name: "(UTC-03:00) Araguaina",
        value: "Tocantins Standard Time",
        zone: "America/Araguaina"
    },
    {
        name: "(UTC-03:00) Brasilia",
        value: "E. South America Standard Time",
        zone: "America/Sao_Paulo"
    },
    {
        name: "(UTC-03:00) Cayenne, Fortaleza",
        value: "SA Eastern Standard Time",
        zone: "America/Cayenne"
    },
    {
        name: "(UTC-03:00) City of Buenos Aires",
        value: "Argentina Standard Time",
        zone: "America/Argentina/Buenos_Aires"
    },
    {
        name: "(UTC-03:00) Greenland",
        value: "Greenland Standard Time",
        zone: "America/Nuuk"
    },
    {
        name: "(UTC-03:00) Montevideo",
        value: "Montevideo Standard Time",
        zone: "America/Montevideo"
    },
    {
        name: "(UTC-03:00) Punta Arenas",
        value: "Magallanes Standard Time",
        zone: "America/Punta_Arenas"
    },
    {
        name: "(UTC-03:00) Saint Pierre and Miquelon",
        value: "Saint Pierre Standard Time",
        zone: "America/Miquelon"
    },
    {
        name: "(UTC-03:00) Salvador",
        value: "Bahia Standard Time",
        zone: "America/Bahia"
    },
    {
        name: "(UTC-02:00) Coordinated Universal Time-02",
        value: "UTC-02",
        zone: "Etc/GMT+2"
    },
    {
        name: "(UTC-02:00) Mid-Atlantic - Old",
        value: "Mid-Atlantic Standard Time",
        zone: "Etc/GMT+2"
    },
    {
        name: "(UTC-01:00) Azores",
        value: "Azores Standard Time",
        zone: "Atlantic/Azores"
    },
    {
        name: "(UTC-01:00) Cabo Verde Is.",
        value: "Cabo Verde Standard Time",
        zone: "Atlantic/Cape_Verde"
    },
    {
        name: "(UTC) Coordinated Universal Time",
        value: "Coordinated Universal Time",
        zone: "Etc/UTC"
    },
    {
        name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
        value: "GMT Standard Time",
        zone: "Europe/London"
    },
    {
        name: "(UTC+00:00) Monrovia, Reykjavik",
        value: "Greenwich Standard Time",
        zone: "Atlantic/Reykjavik"
    },
    {
        name: "(UTC+00:00) Sao Tome",
        value: "Sao Tome Standard Time",
        zone: "Africa/Sao_Tome"
    },
    {
        name: "(UTC+01:00) Casablanca",
        value: "Morocco Standard Time",
        zone: "Africa/Casablanca"
    },
    {
        name: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        value: "W. Europe Standard Time",
        zone: "Europe/Berlin"
    },
    {
        name: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        value: "Central Europe Standard Time",
        zone: "Europe/Budapest"
    },
    {
        name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
        value: "Romance Standard Time",
        zone: "Europe/Paris"
    },
    {
        name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
        value: "Central European Standard Time",
        zone: "Europe/Warsaw"
    },
    {
        name: "(UTC+01:00) West Central Africa",
        value: "W. Central Africa Standard Time",
        zone: "Africa/Lagos"
    },
    {
        name: "(UTC+02:00) Amman",
        value: "Jordan Standard Time",
        zone: "Asia/Amman"
    },
    {
        name: "(UTC+02:00) Athens, Bucharest",
        value: "GTB Standard Time",
        zone: "Europe/Bucharest"
    },
    {
        name: "(UTC+02:00) Beirut",
        value: "Middle East Standard Time",
        zone: "Asia/Beirut"
    },
    {
        name: "(UTC+02:00) Cairo",
        value: "Egypt Standard Time",
        zone: "Africa/Cairo"
    },
    {
        name: "(UTC+02:00) Chisinau",
        value: "E. Europe Standard Time",
        zone: "Europe/Chisinau"
    },
    {
        name: "(UTC+02:00) Damascus",
        value: "Syria Standard Time",
        zone: "Asia/Damascus"
    },
    {
        name: "(UTC+02:00) Gaza, Hebron",
        value: "West Bank Gaza Standard Time",
        zone: "Asia/Hebron"
    },
    {
        name: "(UTC+02:00) Harare, Pretoria",
        value: "South Africa Standard Time",
        zone: "Africa/Johannesburg"
    },
    {
        name: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        value: "FLE Standard Time",
        zone: "Europe/Kiev"
    },
    {
        name: "(UTC+02:00) Jerusalem",
        value: "Jerusalem Standard Time",
        zone: "Asia/Jerusalem"
    },
    {
        name: "(UTC+02:00) Juba",
        value: "South Sudan Standard Time",
        zone: "Africa/Juba"
    },
    {
        name: "(UTC+02:00) Kaliningrad",
        value: "Russia TZ 1 Standard Time",
        zone: "Europe/Kaliningrad"
    },
    {
        name: "(UTC+02:00) Khartoum",
        value: "Sudan Standard Time",
        zone: "Africa/Khartoum"
    },
    {
        name: "(UTC+02:00) Tripoli",
        value: "Libya Standard Time",
        zone: "Africa/Tripoli"
    },
    {
        name: "(UTC+02:00) Windhoek",
        value: "Namibia Standard Time",
        zone: "Africa/Windhoek"
    },
    {
        name: "(UTC+03:00) Baghdad",
        value: "Arabic Standard Time",
        zone: "Asia/Baghdad"
    },
    {
        name: "(UTC+03:00) Istanbul",
        value: "Turkey Standard Time",
        zone: "Europe/Istanbul"
    },
    {
        name: "(UTC+03:00) Kuwait, Riyadh",
        value: "Arab Standard Time",
        zone: "Asia/Riyadh"
    },
    {
        name: "(UTC+03:00) Minsk",
        value: "Belarus Standard Time",
        zone: "Europe/Minsk"
    },
    {
        name: "(UTC+03:00) Moscow, St. Petersburg",
        value: "Russia TZ 2 Standard Time",
        zone: "Europe/Moscow"
    },
    {
        name: "(UTC+03:00) Nairobi",
        value: "E. Africa Standard Time",
        zone: "Africa/Nairobi"
    },
    {
        name: "(UTC+03:00) Volgograd",
        value: "Volgograd Standard Time",
        zone: "Europe/Volgograd"
    },
    {
        name: "(UTC+03:30) Tehran",
        value: "Iran Standard Time",
        zone: "Asia/Tehran"
    },
    {
        name: "(UTC+04:00) Abu Dhabi, Muscat",
        value: "Arabian Standard Time",
        zone: "Asia/Dubai"
    },
    {
        name: "(UTC+04:00) Astrakhan, Ulyanovsk",
        value: "Astrakhan Standard Time",
        zone: "Europe/Astrakhan"
    },
    {
        name: "(UTC+04:00) Baku",
        value: "Azerbaijan Standard Time",
        zone: "Asia/Baku"
    },
    {
        name: "(UTC+04:00) Izhevsk, Samara",
        value: "Russia TZ 3 Standard Time",
        zone: "Europe/Samara"
    },
    {
        name: "(UTC+04:00) Port Louis",
        value: "Mauritius Standard Time",
        zone: "Indian/Mauritius"
    },
    {
        name: "(UTC+04:00) Saratov",
        value: "Saratov Standard Time",
        zone: "Europe/Saratov"
    },
    {
        name: "(UTC+04:00) Tbilisi",
        value: "Georgian Standard Time",
        zone: "Asia/Tbilisi"
    },
    {
        name: "(UTC+04:00) Yerevan",
        value: "Caucasus Standard Time",
        zone: "Asia/Yerevan"
    },
    {
        name: "(UTC+04:30) Kabul",
        value: "Afghanistan Standard Time",
        zone: "Asia/Kabul"
    },
    {
        name: "(UTC+05:00) Ashgabat, Tashkent",
        value: "West Asia Standard Time",
        zone: "Asia/Tashkent"
    },
    {
        name: "(UTC+05:00) Ekaterinburg",
        value: "Russia TZ 4 Standard Time",
        zone: "Asia/Yekaterinburg"
    },
    {
        name: "(UTC+05:00) Islamabad, Karachi",
        value: "Pakistan Standard Time",
        zone: "Asia/Karachi"
    },
    {
        name: "(UTC+05:00) Qyzylorda",
        value: "Qyzylorda Standard Time",
        zone: "Asia/Qyzylorda"
    },
    {
        name: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        value: "India Standard Time",
        zone: "Asia/Kolkata"
    },
    {
        name: "(UTC+05:30) Sri Jayawardenepura",
        value: "Sri Lanka Standard Time",
        zone: "Asia/Colombo"
    },
    {
        name: "(UTC+05:45) Kathmandu",
        value: "Nepal Standard Time",
        zone: "Asia/Kathmandu"
    },
    {
        name: "(UTC+06:00) Astana",
        value: "Central Asia Standard Time",
        zone: "Asia/Almaty"
    },
    {
        name: "(UTC+06:00) Dhaka",
        value: "Bangladesh Standard Time",
        zone: "Asia/Dhaka"
    },
    {
        name: "(UTC+06:00) Omsk",
        value: "Omsk Standard Time",
        zone: "Asia/Omsk"
    },
    {
        name: "(UTC+06:30) Yangon (Rangoon)",
        value: "Myanmar Standard Time",
        zone: "Asia/Yangon"
    },
    {
        name: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
        value: "SE Asia Standard Time",
        zone: "Asia/Bangkok"
    },
    {
        name: "(UTC+07:00) Barnaul, Gorno-Altaysk",
        value: "Altai Standard Time",
        zone: "Asia/Barnaul"
    },
    {
        name: "(UTC+07:00) Hovd",
        value: "W. Mongolia Standard Time",
        zone: "Asia/Hovd"
    },
    {
        name: "(UTC+07:00) Krasnoyarsk",
        value: "Russia TZ 6 Standard Time",
        zone: "Asia/Krasnoyarsk"
    },
    {
        name: "(UTC+07:00) Novosibirsk",
        value: "Novosibirsk Standard Time",
        zone: "Asia/Novosibirsk"
    },
    {
        name: "(UTC+07:00) Tomsk",
        value: "Tomsk Standard Time",
        zone: "Asia/Tomsk"
    },
    {
        name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        value: "China Standard Time",
        zone: "Asia/Shanghai"
    },
    {
        name: "(UTC+08:00) Irkutsk",
        value: "Russia TZ 7 Standard Time",
        zone: "Asia/Irkutsk"
    },
    {
        name: "(UTC+08:00) Kuala Lumpur, Singapore",
        value: "Malay Peninsula Standard Time",
        zone: "Asia/Singapore"
    },
    {
        name: "(UTC+08:00) Perth",
        value: "W. Australia Standard Time",
        zone: "Australia/Perth"
    },
    {
        name: "(UTC+08:00) Taipei",
        value: "Taipei Standard Time",
        zone: "Asia/Taipei"
    },
    {
        name: "(UTC+08:00) Ulaanbaatar",
        value: "Ulaanbaatar Standard Time",
        zone: "Asia/Ulaanbaatar"
    },
    {
        name: "(UTC+08:45) Eucla",
        value: "Aus Central W. Standard Time",
        zone: "Australia/Eucla"
    },
    {
        name: "(UTC+09:00) Chita",
        value: "Transbaikal Standard Time",
        zone: "Asia/Chita"
    },
    {
        name: "(UTC+09:00) Osaka, Sapporo, Tokyo",
        value: "Tokyo Standard Time",
        zone: "Asia/Tokyo"
    },
    {
        name: "(UTC+09:00) Pyongyang",
        value: "North Korea Standard Time",
        zone: "Asia/Pyongyang"
    },
    {
        name: "(UTC+09:00) Seoul",
        value: "Korea Standard Time",
        zone: "Asia/Seoul"
    },
    {
        name: "(UTC+09:00) Yakutsk",
        value: "Russia TZ 8 Standard Time",
        zone: "Asia/Yakutsk"
    },
    {
        name: "(UTC+09:30) Adelaide",
        value: "Cen. Australia Standard Time",
        zone: "Australia/Adelaide"
    },
    {
        name: "(UTC+09:30) Darwin",
        value: "AUS Central Standard Time",
        zone: "Australia/Darwin"
    },
    {
        name: "(UTC+10:00) Brisbane",
        value: "E. Australia Standard Time",
        zone: "Australia/Brisbane"
    },
    {
        name: "(UTC+10:00) Canberra, Melbourne, Sydney",
        value: "AUS Eastern Standard Time",
        zone: "Australia/Sydney"
    },
    {
        name: "(UTC+10:00) Guam, Port Moresby",
        value: "West Pacific Standard Time",
        zone: "Pacific/Port_Moresby"
    },
    {
        name: "(UTC+10:00) Hobart",
        value: "Tasmania Standard Time",
        zone: "Australia/Hobart"
    },
    {
        name: "(UTC+10:00) Vladivostok",
        value: "Russia TZ 9 Standard Time",
        zone: "Asia/Vladivostok"
    },
    {
        name: "(UTC+10:30) Lord Howe Island",
        value: "Lord Howe Standard Time",
        zone: "Australia/Lord_Howe"
    },
    {
        name: "(UTC+11:00) Bougainville Island",
        value: "Bougainville Standard Time",
        zone: "Pacific/Bougainville"
    },
    {
        name: "(UTC+11:00) Chokurdakh",
        value: "Russia TZ 10 Standard Time",
        zone: "Asia/Srednekolymsk"
    },
    {
        name: "(UTC+11:00) Magadan",
        value: "Magadan Standard Time",
        zone: "Asia/Magadan"
    },
    {
        name: "(UTC+11:00) Norfolk Island",
        value: "Norfolk Standard Time",
        zone: "Pacific/Norfolk"
    },
    {
        name: "(UTC+11:00) Sakhalin",
        value: "Sakhalin Standard Time",
        zone: "Asia/Sakhalin"
    },
    {
        name: "(UTC+11:00) Solomon Is., New Caledonia",
        value: "Central Pacific Standard Time",
        zone: "Pacific/Guadalcanal"
    },
    {
        name: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
        value: "Russia TZ 11 Standard Time",
        zone: "Asia/Kamchatka"
    },
    {
        name: "(UTC+12:00) Auckland, Wellington",
        value: "New Zealand Standard Time",
        zone: "Pacific/Auckland"
    },
    {
        name: "(UTC+12:00) Coordinated Universal Time+12",
        value: "UTC+12",
        zone: "Etc/GMT-12"
    },
    {
        name: "(UTC+12:00) Fiji",
        value: "Fiji Standard Time",
        zone: "Pacific/Fiji"
    },
    {
        name: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
        value: "Kamchatka Standard Time",
        zone: "Asia/Kamchatka"
    },
    {
        name: "(UTC+12:45) Chatham Islands",
        value: "Chatham Islands Standard Time",
        zone: "Pacific/Chatham"
    },
    {
        name: "(UTC+13:00) Coordinated Universal Time+13",
        value: "UTC+13",
        zone: "Etc/GMT-13"
    },
    {
        name: "(UTC+13:00) Nuku'alofa",
        value: "Tonga Standard Time",
        zone: "Pacific/Tongatapu"
    },
    {
        name: "(UTC+13:00) Samoa",
        value: "Samoa Standard Time",
        zone: "Pacific/Apia"
    },
    {
        name: "(UTC+14:00) Kiritimati Island",
        value: "Line Islands Standard Time",
        zone: "Pacific/Kiritimati"
    }
];

export default timeZones;
