import * as React from "react";
import {SalesChannelPicker} from "../../../form/pickers/SalesChannelPicker";
import {ResellerPicker} from "../../../form/pickers/ResellerPicker";
import TextInput from "../../../form/inputs/TextInput";
import CheckboxInput from "../../../form/inputs/CheckboxInput";

interface Props {
    disabled?: boolean;
}

export function UpdateSendGridAccountFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <TextInput name='apiKey' label='Api Key' disabled={disabled}/>

            <TextInput name='listId' label='List Id' disabled={disabled}/>

            <TextInput name='segmentId' label='Custom Field Id' disabled={disabled}/>

            <TextInput name='segment' label='Custom Field Value' disabled={disabled}/>

            <CheckboxInput name='updateOnSubscriptionEvent' label='Update On Subscription Create' helperText="Updates the segment value to the product identifier." disabled={disabled} />

            <SalesChannelPicker name='salesChannelId' disabled={disabled}/>

            <ResellerPicker name='resellerId' disabled={disabled}/>

        </>
    )
}

export function CreateSendGridAccountFields({ disabled }: Props) {
    return (
        <>
            <UpdateSendGridAccountFields disabled={disabled}/>
        </>
    )
}
