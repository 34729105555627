import {CreateSubscriptionFileTypeRequest, SubscriptionFileType, UpdateSubscriptionFileTypeRequest} from "../../../../../models/SubscriptionFileType";
import {Form, Formik} from "formik";
import * as React from "react";
import {useRepo} from "../../../../../repos/useRepo";
import {UpdateFormSubscriptionFileTypeController} from "./UpdateSubscriptionFileTypeFormViewModel";
import {CreateSubscriptionFileTypeFormViewModel} from "./CreateSubscriptionFileTypeFormViewModel";
import {subscriptionFileTypeSchema} from "../../schemas/SubscriptionFileTypeSchema";
import {SubscriptionFileTypeFormViewModel} from "./SubscriptionFileTypeFormViewModel";
import {VerticalFormLayout} from "../../../../form/FormLayout";
import SubscriptionFileTypeFields from "./SubscriptionFileTypeFields";
import {withModal} from "../../../../modal";
import {FormSubmitButton} from "../../../../form/buttons/FormSubmitButton";

interface Props {
    controller: SubscriptionFileTypeFormViewModel<CreateSubscriptionFileTypeRequest | UpdateSubscriptionFileTypeRequest>;
}

function SubscriptionFileTypeForm({ controller }: Props) {
    const { submitButtonVariant, initialValues, onSubmit } = controller;

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={subscriptionFileTypeSchema}>
            <Form>
                <VerticalFormLayout fieldset={<SubscriptionFileTypeFields/>}
                                    bottomContent={<FormSubmitButton variant={submitButtonVariant}/>}/>
            </Form>
        </Formik>
    )
}

export function UpdateSubscriptionFileTypeForm(props: { subscriptionFileType: SubscriptionFileType, onSubscriptionFileTypeUpdated: (SubscriptionFileType: SubscriptionFileType) => void }) {
    const { subscriptionFileType, onSubscriptionFileTypeUpdated }  = props;
    const { subscriptionRepo } = useRepo();
    const controller = new UpdateFormSubscriptionFileTypeController(subscriptionFileType, subscriptionRepo, onSubscriptionFileTypeUpdated);
    return <SubscriptionFileTypeForm controller={controller}/>;
}

function CreateSubscriptionFileTypeForm(props: { onSubscriptionFileTypeCreated: (SubscriptionFileType: SubscriptionFileType) => void }) {
    const { onSubscriptionFileTypeCreated }  = props;
    const { subscriptionRepo } = useRepo();
    const controller = new CreateSubscriptionFileTypeFormViewModel(subscriptionRepo, onSubscriptionFileTypeCreated);
    return <SubscriptionFileTypeForm controller={controller}/>
}

export const CreateSubscriptionFileTypeFormDialog = withModal(CreateSubscriptionFileTypeForm, "Create File Type");
export const UpdateSubscriptionFileTypeFormDialog = withModal(UpdateSubscriptionFileTypeForm, "Update File Type");
