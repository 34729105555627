import React from 'react';
import {createStyles, MenuItem, Theme} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {useRepo} from "../../../repos/useRepo";
import {Topic} from "../../../models/ti/Topic";

interface Props {
    selectedTopics: string[];
    onSelectionChanged: (selectedTopics: string[]) => void
}

export default function TopicMultiselect(props: Props) {
    const { topicRepo } = useRepo()
    const { selectedTopics, onSelectionChanged } = props;

    const [topics, setTopics] = useState<Topic[]>([]);

    const classes = useStyles();

    useEffect(() => {
        topicRepo.listAll()
            .then(setTopics)
            .catch(err => console.error(err))
    }, [topicRepo]);

    function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
        const selectedIds = event.target.value as string[];
        onSelectionChanged(selectedIds);
    }

    const handleDelete = (topic: Topic) => () => {
        const topics = selectedTopics.filter(id => id !== topic.id);
        onSelectionChanged(topics)
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-chip">Topics</InputLabel>
            <Select
                multiple
                displayEmpty
                style={{minHeight: "56px"}}
                value={selectedTopics}
                onChange={handleChange}
                autoWidth={true}
                input={<OutlinedInput labelWidth={80} id="select-multiple-chip" />}
                renderValue={(value: unknown) => {
                    const selectedTopics = value as string[];

                    return (
                        <div className={classes.chips}>
                            {selectedTopics.map(id => topics
                                .find(c => c.id === id))
                                .filter(c => !!c)
                                .map(topic => <Chip
                                    key={topic!.id}
                                    label={topic!.name}
                                    className={classes.chip}
                                    onDelete={handleDelete(topic!)}
                                />)}
                        </div>
                    )
                }}
            >
                {topics && topics.map(topic => (
                    <MenuItem key={topic.id} value={topic.id}>
                        {topic.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            minWidth: 120,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
);
