import React, { useRef, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { useRepo } from "../../../../../repos/useRepo";
import { actions } from "../../../../../rbac";
import MaterialTable, { Column } from 'material-table';
import { useRbac } from '@gsb/react-rbac';
import useCreateStateIdApplicationNoteModal from '../../../../modals/useCreateStateIdApplicationNoteModal';
import useUpdateStateIdApplicationNoteModal from '../../../../modals/useUpdateStateIdApplicationNoteModal';
import { Application } from '../../../../../models/sid/Application';
import { ApplicationNote } from '../../../../../models/sid/ApplicationNote';
import { CompanyNoteDemeanorAvatar } from '../../../companies/notes/CompanyNoteDemeanor';
import { useApplicationNoteMenu } from './ApplicationNoteMenu';
import { makeCreateAction, makeMenuAction } from '../../../../table/TableActionFactory';


export const requiredActions = [
    actions.stateIdApplication.listNotes
];

interface Props {
    application: Application
}

export default function ApplicationNotesTable({ application }: Props) {

    const tableRef = useRef<any>(null);
    const { stateIdApplicationRepo } = useRepo();

    const { can } = useRbac();
    const canCreateApplicationNote = can(actions.stateIdApplication.createNote)
    const canDeleteApplicationNote = can(actions.stateIdApplication.deleteNote)
    const canUpdateApplicationNote = can(actions.stateIdApplication.updateNote);

    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<ApplicationNote[] | undefined>();

    const reloadData = () => {
        stateIdApplicationRepo.listNotes(application.id).then(setResults).then(() => setLoading(false)).catch(console.error);
    }

    const handleApplicationNoteDelete = async (note: ApplicationNote) => {
        const userRes = window.confirm('Are you sure you want to delete this item?');
        if (userRes) {
            stateIdApplicationRepo.deleteNote(application.id, note.id).then(reloadData).catch(console.error);
        }
        return;
    }

    const { openCreateStateIdApplicationNoteModal, createStateIdApplicationNoteModal } = useCreateStateIdApplicationNoteModal(application, reloadData);
    const { setApplicationNoteModal, updateStateIdApplicationNoteModal } = useUpdateStateIdApplicationNoteModal(application, reloadData);
    const { applicationNoteMenu, openApplicationNoteMenu } = useApplicationNoteMenu(
        (canDeleteApplicationNote ? handleApplicationNoteDelete : undefined),
        (canUpdateApplicationNote ? setApplicationNoteModal : undefined),
        (canCreateApplicationNote ? (entity) => openCreateStateIdApplicationNoteModal({ open: true, threadId: entity.id }) : undefined));

    const columns: Column<ApplicationNote>[] = [
        { title: 'Note Id', field: 'id', export: true, hidden: true },
        { title: "Note", field: "body", emptyValue: "-", headerStyle: { width: '30%', minWidth: 250 }, cellStyle: { width: '30%', minWidth: 250 } },
        { title: "Demeanor", field: "demeanor", align: 'center', render: (entity) => { return (<CompanyNoteDemeanorAvatar companyNote={entity} />) }, filtering: false },
        { title: "Call Status", field: "status", emptyValue: "-" },
        { title: "User", field: "userDisplayName", emptyValue: "-" },
        { title: "Created At", field: "createdAt", type: "date", defaultSort: "desc" },
        { title: "Created By", field: "createdBy", emptyValue: "-" },
    ];

    useEffect(reloadData, [application.id]);

    return (
        <div>
            
                <MaterialTable
                    style={{ maxWidth: '100%' }}
                    isLoading={loading}
                    title={`Notes for ${application.legalName}`}
                    tableRef={tableRef}
                    columns={columns}
                    data={results ?? []}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.threadId)}
                    actions={[
                        makeMenuAction(openApplicationNoteMenu),
                        makeCreateAction(() => openCreateStateIdApplicationNoteModal({ open: true }), { disabled: !canCreateApplicationNote })]}
                    options={{
                        search: true,
                        filtering: true,
                        actionsColumnIndex: columns.length,
                        paging: true,
                        toolbar: true,
                        rowStyle: rowData => ({
                            backgroundColor: !!rowData.threadId ? '#fafafa' : '#ffffff'
                        }),
                    }}
                />
            
            {createStateIdApplicationNoteModal}
            {updateStateIdApplicationNoteModal}
            {applicationNoteMenu}
        </div>
    );
}