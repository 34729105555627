import {Form, Formik} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import { UpdateResellerFields } from "./ResellerFields";
import {Reseller, UpdateResellerRequest} from "../../../../models/Reseller";
import { initialValuesForResellerUpdate, updateResellerSchema} from "../../../../utils/schemas/reseller-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdateResellerForm(props: { reseller: Reseller, onResellerUpdated: (reseller: Reseller) => void }) {
    const { reseller, onResellerUpdated }  = props;
    const { resellerRepo } = useRepo();
    const { can } = useRbac();

    const canUpdateReseller = can(actions.reseller.update);
    const disabled =!canUpdateReseller;

    const onSubmit = updateSubmitHandler(
        (body: UpdateResellerRequest) => resellerRepo.update(reseller.id, body),
        initialValuesForResellerUpdate,
        onResellerUpdated,
        console.error,

    );

    return (
        <Formik<UpdateResellerRequest> enableReinitialize={true} initialValues={initialValuesForResellerUpdate(reseller)} validationSchema={updateResellerSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateResellerFields disabled={disabled}/>}
                                    bottomContent={<FormSubmitButton variant='Update' disabled={disabled}/>}/>
            </Form>
        </Formik>
    )
}
