import { WebsiteAnalysis } from "../../../../models/WebsiteAnalysis";
import { WebsiteAnalysisVersion } from "../../../../models/WebsiteAnalysisVersion"
import React, { useRef } from "react";
import { withModal } from "../../../modal";
import MaterialTable, { Column } from "material-table"
import TextField from '@material-ui/core/TextField';

interface Props {
    websiteAnalysis: WebsiteAnalysis
}

function ViewWebsiteAnalysisForm({ websiteAnalysis }: Props) {
    const tableRef = useRef<any>(null);

    const columns: Column<WebsiteAnalysisVersion>[] = [
        { title: "Created", field: "createdAt", type: "date" },
        { title: "Version", render: (entity) => { return <a href={entity.shareLink} target='_blank' title='View Share Link' >{entity.versionId}</a> } },
        { title: "Score", field: "overallScore", type: "numeric" },
    ];

    return (
        <>
        <MaterialTable
            style={{ maxWidth: '100%', marginTop: '30px' }}
            title='Versions'
            tableRef={tableRef}
            columns={columns}
            data={(websiteAnalysis.versions ?? [])}
            options={{
                search: false,
                filtering: false,
                paging: false,
                toolbar: true,
                rowStyle: (d, index, level) => ({
                    backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa'
                }),
            }}
        />
        </>
    )
}

export const ViewWebsiteAnalysisModal = withModal(ViewWebsiteAnalysisForm);

