import {Moment} from "moment-business-days";

const moment = require('moment-business-days');

export enum WarningLevel {
  onTrack,
  approachingDeadline,
  passedDeadline
}

function getStatus(date: Moment): WarningLevel {
  const turnaroundTime = 2;
  const approachingDeadlineThreshold = 1;

  /*
    The library will consider any time the previous business day as 1 day old but if it happened over 24hrs ago in the
    previous day (e.g. 25 hrs ago) it will count it as 2 days.
    We do not want this behavior so are stripping hours from the times being compared.
  */
  const dateWithoutHours = date.startOf("day");
  const today = moment.utc().startOf("day");

  const age = today.businessDiff(dateWithoutHours);

  if (age >= turnaroundTime) {
    return WarningLevel.passedDeadline;
  }

  if (age >= approachingDeadlineThreshold) {
    return WarningLevel.approachingDeadline;
  }

  return WarningLevel.onTrack;
}

export default {getStatus}
