import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {SalesChannel} from "../../../models/SalesChannel";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export function SalesChannelPicker({ ...PickerProps }: Props) {
    const { salesChannelRepo } = useRepo();
    const [salesChannels, setSalesChannels] = useState<SalesChannel[]>([]);

    useEffect(() => {
        salesChannelRepo.listAll().then(setSalesChannels).catch(console.error);
    }, [salesChannelRepo]);

    const options = useMemo(() => salesChannels.map(p => ({ id: p.id, label: p.name || p.id })), [salesChannels]);

    return <PickerInput label={'Sales Channel'} options={options} {...PickerProps} />
}