import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import { ApplicationStatus } from "../../../models/sid/ApplicationStatus";

interface Props extends PickerPropsWithoutOptions{}

export function StateIdApplicationStatusPicker({ ...PickerProps }: Props) {
    const { stateIdApplicationRepo } = useRepo();
    const [statuses, setStatuses] = useState<ApplicationStatus[]>([]);

    useEffect(() => {
        stateIdApplicationRepo.listStatuses().then((d) => setStatuses(d.slice(1))).catch(console.error);
    }, [stateIdApplicationRepo]);

    const options = useMemo(() => statuses.map(p => ({ id: p.id, label: p.name })), [statuses]);

    return <PickerInput label={'Application Status'} options={options} {...PickerProps} />
}