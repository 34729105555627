import * as Yup from 'yup';
import {Site} from "../../models/ti/Site";
import {CreateSiteTopicRequest, SiteTopic, UpdateSiteTopicRequest} from "../../models/ti/SiteTopic";

export const createSiteTopicSchema = Yup.object().shape({
    siteId: Yup.string().required("Site is required"),
    topicId: Yup.string().required("Topic is required"),
    isFeatured: Yup.bool().required(),
    sortOrder: Yup.number().required("Sort Order is required")
});

export const updateSiteTopicSchema = Yup.object().shape({
    isFeatured: Yup.bool().required()
});

export const initialValuesForNewSiteTopicForSite  = (site: Site): CreateSiteTopicRequest => ({
    siteId: site.id,
    topicId: '',
    isFeatured: true,
    sortOrder: 100
});

export const initialValuesForSiteTopic  = (siteTopic: SiteTopic): UpdateSiteTopicRequest => ({
    isFeatured: siteTopic.isFeatured ?? false,
    sortOrder: siteTopic.sortOrder ?? 100

});
