import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import {StripeAccount} from "../../../models/stripe/StripeAccount";

interface Props extends PickerPropsWithoutOptions {
    excludingStripeAccountIdentifiers?: string[];
}

export default function StripeAccountPicker({ excludingStripeAccountIdentifiers, ...PickerProps }: Props) {
    const { stripeRepo } = useRepo();

    const [stripeAccounts, setStripeAccounts] = useState<StripeAccount[]>([]);

    useEffect(() => {
        stripeRepo.listStripeAccounts().then(setStripeAccounts).catch(console.error);
    }, [stripeRepo]);

    const options = useMemo(() => {
        const accountIdentifiersToExclude = excludingStripeAccountIdentifiers || [];
        return stripeAccounts
            .filter(a => accountIdentifiersToExclude.indexOf(a.identifier) === -1)
            .map(s => ({ id: s.identifier, label: s.name }))
    }, [stripeAccounts, excludingStripeAccountIdentifiers]);

    return <PickerInput label={'Stripe Account'} options={options} {...PickerProps} />
}