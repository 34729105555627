import * as React from "react";
import {AppleAppStoreSource } from "../../../../../models/Subscription";
import {useNavigation} from "../../../../../services/navigation/useNavigation";
import {Product} from "../../../../../models/Product";

interface Props {
  appleAppStoreSource: AppleAppStoreSource;
}

type  DetailItem = {
  title: string,
  value: string;
  hidden?: boolean;
  onClick?: () => void;
}

export default function AppleAppStoreSubscriptionDetail({ appleAppStoreSource }: Props) {
  const { navigateToProduct, navigateToPlan } = useNavigation();
  const { originalTransactionId, transactionId, appStoreProductId, plan } = appleAppStoreSource;

  const items: DetailItem[] = [];

  items.push({
    title: "Original Transaction Id",
    value: originalTransactionId,
  });

  items.push({
    title: "Transaction Id",
    value: transactionId,
  });

  items.push({
    title: "App Store Product Id",
    value: appStoreProductId,
  });

  if (plan) {
    const { product } = plan;

    items.push({
      title: "Plan",
      value: plan.name,
      onClick: () => navigateToPlan(plan)
    });

    if (product) {
      items.push({
        title: "Product",
        value: product.name,
        onClick: () => navigateToProduct(product)
      })
    }
  }

  return (
    <table>
      <tbody>
      {items.map(item => (
        <tr>
          <td style={{ padding: 4 }}><b>{item.title}</b></td>
          <td style={{ padding: 4, textAlign: 'left' }}><a onClick={item.onClick}>{item.value}</a></td>
        </tr>
      ))}
      </tbody>
    </table>
  )
}
