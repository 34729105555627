import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useEffect, useState} from "react";
import {useRbac} from "@gsb/react-rbac";
import TabNavigation, {TabNavigationItem} from "../../../tabs/TabNavigation";
import {Paper} from "@material-ui/core";
import {useRepo} from "../../../../repos/useRepo";
import {Reseller} from "../../../../models/Reseller";
import {actions} from "../../../../rbac";
import LoadingIndicator from "../../../LoadingIndicator";
import {useNavigation} from "../../../../services/navigation/useNavigation";
import {UpdateResellerForm} from "./ResellerForm";
import {BreadcrumbFactory, ResellerDetailBreadcrumbFactory,} from "../../../../factories/BreadcrumbFactory";
import {Breadcrumbs} from "../../../layout/Breadcrumbs";
import {useMemo} from "react";

interface Params {
    resellerId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
  actions.reseller.get,
];

function ResellerDetail(props: Props) {
    const [reseller, setReseller] = useState<Reseller>();
    const { resellerRepo } = useRepo();

    const { navigateToResellers} = useNavigation();
    const { can } = useRbac();
    const canListResellers = can(actions.reseller.list);
    const shouldShowList = canListResellers || canListResellers;

    const resellerId = useMemo(() => props.match.params.resellerId, [props.match.params.resellerId]);

    const loadReseller = () => {
        resellerRepo.getById(resellerId).then(setReseller).catch(console.error);
    };

    useEffect(loadReseller, [resellerRepo, resellerId]);

    if (reseller) {

        const items: TabNavigationItem[] = [
            {
                name: 'Reseller',
                identifier: 'reseller',
                render: () => <UpdateResellerForm reseller={reseller} onResellerUpdated={loadReseller}/>
            },
        ];

        const breadcrumbFactory: BreadcrumbFactory = new ResellerDetailBreadcrumbFactory(reseller, navigateToResellers);

        return (
            <div>
                <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

                {shouldShowList &&
                <Paper>
                  <TabNavigation items={items}/>
                </Paper>
                }

            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export default withRouter(ResellerDetail);