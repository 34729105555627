import React from 'react';
import {DialogChildProps} from "../../EntityDialog";
import {EmployeeRole} from "../../../../models/EmployeeRole";
import {ApplicationPicker} from "../../../form/pickers/ApplicationPicker";
import TextInput from "../../../form/inputs/TextInput";
import Form from "../../../form/Form";
import CheckboxInput from "../../../form/inputs/CheckboxInput";

interface Props extends DialogChildProps {
    role?: EmployeeRole;
    onValuesChange?: (draft: Partial<EmployeeRole>) => void;
    isReadOnly?: boolean
}

export default function Detail({ role, isReadOnly, ...formProps }: Props) {
    return (
        <Form
            disabled={isReadOnly}
            initialValues={{
                name: role ? role.name : "",
                identifier: role ? role.identifier : "",
                isDefault: role ? role.isDefault : false,
                applicationId: role ? role.applicationId : "",
            }}
            {...formProps}
        >
            <TextInput name={"name"} label="Name"/>

            <TextInput name={"identifier"} label="Identifier"/>

            <CheckboxInput name={'isDefault'} label={'Default'}/>

            <ApplicationPicker name={"applicationId"}/>

        </Form>
    );
}
