import React from "react";
import MetadataField from "../../../models/MetadataField";
import PickerInput, { PickerOption } from "./PickerInput";
import TextInput from "./TextInput";

type Props = { metadataField: MetadataField, name: string, label: string, helperText?: string, description?: string, disabled?: boolean, allowEmpty?: boolean, emptyValueDescription?: string, emptyValue?: string | number | null } ;

export default function MetadataInput({metadataField, ...MetadataFieldProps }: Props) {

    const parseOptions = (options: string): PickerOption[] => {
        const optionItems = options.split(',');
        return optionItems.map(parseNameValuePair);
    }

    const parseNameValuePair = (item: string): PickerOption => {
        const itemValues = item.split('|');
        return { label: itemValues[0], id: (itemValues.length > 1 ? itemValues[1] : itemValues[0]) };
    }

    const { component, options } = metadataField;
    switch (component) {
        case 'select':
            return (<PickerInput options={parseOptions(options || '')} {...MetadataFieldProps}/>);
        case 'select-multi':{
            const { emptyValue, emptyValueDescription, allowEmpty, ...fieldProps } = MetadataFieldProps;
            return (<PickerInput options={parseOptions(options || '')} multiple={true} {...fieldProps}/>);
        }
        default:
            return (<TextInput {...MetadataFieldProps}/>);
    }
}
