import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getOptionsColumnDefinition, getDateColumnDefinition } from "../Table";
import moment from "moment";
import { Topic } from "../../../models/ti/Topic";
import { useRepo } from "../../../repos/useRepo";
import { Question } from "../../../models/ti/Question";
import { QuestionStatus } from "../../../models/ti/QuestionStatus";
import { QuestionFilterOption } from "../../../repos/ti/question/QuestionRepo";
import { Consultant } from "../../../models/ti/Consultant";
import { SalesChannel } from "../../../models/SalesChannel";

export function useQuestionColumns(disabledColumns?: QuestionFilterOption[]) {
    const { topicRepo, salesChannelRepo, questionStatusRepo, consultantRepo } = useRepo();

    const [consultants, setConsultants] = useState<Consultant[]>([]);
    const [answeredConsultants, setAnsweredConsultants] = useState<Consultant[]>([]);
    const [assignedReviewerConsultants, setAssignedReviewerConsultants] = useState<Consultant[]>([]);
    const [topics, setTopics] = useState<Topic[]>([]);
    const [salesChannels, setSalesChannels] = useState<SalesChannel[]>([]);
    const [statuses, setStatuses] = useState<QuestionStatus[]>([]);

    useEffect(() => {
        consultantRepo.listAllConsultants().then(setConsultants);
    }, [consultantRepo]);

    useEffect(() => {
        consultantRepo.listAnsweredByConsultants().then(setAnsweredConsultants);
    }, [consultantRepo]);

    useEffect(() => {
        consultantRepo.listAssignedReviewerByConsultants().then(setAssignedReviewerConsultants);
    }, [consultantRepo]);

    useEffect(() => {
        topicRepo.listAll().then(setTopics);
    }, [topicRepo]);

    useEffect(() => {
        salesChannelRepo.listAll().then(setSalesChannels);
    }, [salesChannelRepo]);

    useEffect(() => {
        questionStatusRepo.listQuestionStatuses().then(setStatuses);
    }, [questionStatusRepo]);

    const isDisabled = (column: QuestionFilterOption) => {
        if (disabledColumns) {
            return disabledColumns.includes(column)
        } else {
            return false
        }
    };

    const columns = [
        getOptionsColumnDefinition("Topic", QuestionFilterOption.Topic, (i: Question) => _.get(i, "topic.name", ""), topics),
        getOptionsColumnDefinition("Sales Channel", QuestionFilterOption.SalesChannel, (i: Question) => _.get(i, "site.salesChannelName", ""), salesChannels, isDisabled(QuestionFilterOption.SalesChannel)),
        getOptionsColumnDefinition("Status", QuestionFilterOption.Status, (i: Question) => _.get(i, "status.name", ""), statuses, isDisabled(QuestionFilterOption.Status)),
        getDateColumnDefinition("Created", QuestionFilterOption.CreatedAt, (i: Question) => moment.utc(i.createdAt).local().format("MMM D, LT")),
        getDateColumnDefinition("Updated", QuestionFilterOption.UpdatedAt, (i: Question) => moment.utc(i.updatedAt).fromNow()),
        getOptionsColumnDefinition("Updated By", QuestionFilterOption.UpdatedBy, (i: Question) => i.updatedBy && i.updatedBy.name || "", consultants),
        getOptionsColumnDefinition("Reviewer", QuestionFilterOption.AssignedReviewer, (i: Question) => _.get(i, "assignedReviewer.name", ""), assignedReviewerConsultants),
        getOptionsColumnDefinition("Assigned To", QuestionFilterOption.AssignedAnswerer, (i: Question) => i.assignedAnswerer && i.assignedAnswerer.name || "", answeredConsultants, isDisabled(QuestionFilterOption.AssignedAnswerer)),
    ];

    return { columns }
}