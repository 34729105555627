import * as React from "react";
import CheckboxInput from "../../../../../form/inputs/CheckboxInput";
import TextInput from "../../../../../form/inputs/TextInput";
import { FieldTypePicker } from "../../../../../form/pickers/FieldTypePicker";

interface Props {
    disabled?: boolean;
}

export function UpdateStateApplicationFieldFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='label' label='Label' disabled={disabled} />

            <TextInput name='subLabel' label='Sub-Label' disabled={disabled} />

            <TextInput name='sortOrder' label='Sort Order' disabled={disabled} type='number' />

            <FieldTypePicker name='fieldTypeId' label='Field Type' disabled={disabled} />

            <TextInput name='defaultValue' label='Default Value' disabled={disabled} />

            <TextInput name='placeholder' label='Placeholder' disabled={disabled} />

            <TextInput name='classes' label='CSS Classes' disabled={disabled} />

            <TextInput name='mask' label='Mask' disabled={disabled} helperText='example: (###) ###-####' />

            <CheckboxInput name='required' label='Required Field' disabled={disabled} />

            <CheckboxInput name='sensitive' label='Sensitive Field' disabled={disabled} helperText='When true, value will be encrypted on save.' />

        </>
    )
}

export function CreateStateApplicationFieldFields({ disabled }: Props) {
    return (
        <>
            <UpdateStateApplicationFieldFields disabled={disabled} />
        </>
    )
}
