import React, { useEffect, useRef } from 'react';
import { TableColumn } from '../../table/Table';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Paper, Typography } from '@material-ui/core';
import TableColumnFactory from '../../entities/TableColumnFactory';
import { useRepo } from "../../../repos/useRepo";
import { actions } from "../../../rbac";
import moment from "moment";
import {
    AdpCompanySignInReportItem,
    AdpCompanySignInReportRequest,
} from "../../../models/Report";
import MaterialTable from "material-table";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AdpCompanySignInSearchForm } from "./SearchForm";

export const requiredActions = [
    actions.report.adpCompanySignIn
];

export default function CompanyList() {
    const { reportRepo } = useRepo();

    const tableRef = useRef<any>(null);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }));
    const classes = useStyles();

    const columns: TableColumn<AdpCompanySignInReportItem>[] = [
        TableColumnFactory.fromProperty("Company_Name", "companyName"),
        TableColumnFactory.fromProperty("OOID", "ooId"),
        TableColumnFactory.fromProperty("IOID", "ioId"),
        TableColumnFactory.fromProperty("User_Type", "userType"),
        TableColumnFactory.fromProperty("Sign_In_Count", "signInCount"),
        TableColumnFactory.fromProperty("Source", "source"),
        TableColumnFactory.fromProperty("Sign_In_At", "signInAt"),
    ];

    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<AdpCompanySignInReportItem[] | null>(null);
    const [fileName, setFileName] = React.useState<string>('adp_company_sign-in');

    const today = moment(new Date()).subtract(1, 'months');
    const start = today.startOf('month').format('MM/DD/YYYY');
    const end = today.endOf('month').format('MM/DD/YYYY');

    const initValues = {
        dateFrom: start,
        dateTo: end,
        source: 'upnetic'
    }

    const handleSearch = async (filters: AdpCompanySignInReportRequest) => {
        setLoading(true);
        const query = {
            ...filters,
            dateFrom: moment(filters.dateFrom).format('MM/DD/YYYY'),
            dateTo: moment(filters.dateTo).add(1, 'day').format('MM/DD/YYYY')
        };
        const res = await reportRepo.adpCompanySignInReport(query);
        setResults(res);
        setLoading(false);
        setFileName(filters.source + '_' + moment().format('DDMMYYYY'));
    }

    useEffect(() => {
        if (!results) {
            handleSearch(initValues);
        }
    });

    return (
        <>
            <Paper>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Filters</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AdpCompanySignInSearchForm initValues={initValues} onSearch={handleSearch} />
                    </AccordionDetails>
                </Accordion>
                <MaterialTable
                    style={{ maxWidth: '100%', marginTop: '30px' }}
                    isLoading={loading}
                    title='ADP Company Sign In Report'
                    tableRef={tableRef}
                    columns={columns}
                    data={results ?? []}
                    options={{
                        search: false,
                        filtering: false,
                        paging: true,
                        toolbar: true,
                        exportButton: true,
                        exportFileName: fileName,
                        exportAllData: true,
                        rowStyle: (d: any, index: number, level: number) => ({
                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa'
                        }),
                    }}
                />
            </Paper>
        </>
    );
}
