import ResellerRepo from "./ResellerRepo";
import {Reseller} from "../../models/Reseller";
import {ListQuery} from "../CrudRepo";

export class InMemoryResellerRepoCache implements ResellerRepo {
    cachedResellerList?: Reseller[];
    resellerPromise?: Promise<Reseller[]>;

    constructor(private inner: ResellerRepo) {}

    async listAll(): Promise<Reseller[]> {
        if (this.cachedResellerList) {
            return this.cachedResellerList;
        } else {
            if (this.resellerPromise) {
                return this.resellerPromise;
            } else {
                const resellersPromise = this.inner.listAll();
                this.resellerPromise = resellersPromise;
                const resellers = await resellersPromise;
                this.cachedResellerList = resellers;
                this.resellerPromise = undefined;
                return resellers;
            }
        }
    };

    create = (resource: Partial<Reseller>) => this.inner.create(resource);

    delete = (id: string) => this.inner.delete(id);

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<Reseller>) => this.inner.update(id, resource);
}