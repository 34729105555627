import {User} from "../../../../models/User";
import {Role} from "../../../../models/Role";
import {useRbac} from "@gsb/react-rbac";
import {useRepo} from "../../../../repos/useRepo";
import {actions} from "../../../../rbac";
import ChipButton from "../../../form/chips/ChipButton";
import * as React from "react";
import useAssignRolesToUserModal from "../../../modals/useAssignRolesToUserModal";
import {Chip} from "../../../form/chips/Chip";

export default function RolesTab(props: { user: User, roles: Role[], onRoleRevoked: (role: Role) => void; onAddRole: () => void }) {
    const { user, roles, onRoleRevoked, onAddRole } = props;
    const { can } = useRbac();
    const { userRepo } = useRepo();

    const { setUserForAssigningRoles, assignUserRolesModal } = useAssignRolesToUserModal(() => onAddRole());

    const canAssignRole = can(actions.user.assignRole);
    const canRevokeRole = can(actions.user.revokeRole);

    function revokeRole(role: Role) {
        userRepo.revokeRolesForUserById(user.id, { roleIds: [role.id] })
            .then(() => onRoleRevoked(role))
            .catch(console.error)
    }

    return (
        <>
          <div style={{ marginTop: 6, marginBottom: 6 }}>
              {roles.map((role, index) => (
                  <Chip key={`role-chip-${index}`}
                        label={role.name}
                        disabled={!canRevokeRole}
                        onDelete={canRevokeRole ? () => revokeRole(role) : undefined}/>
              ))}

              {canAssignRole &&
              <ChipButton label='Assign Role' color='secondary' onClick={() => setUserForAssigningRoles(user)}/>
              }
          </div>
            {assignUserRolesModal}
        </>
    )
}

