import { Form, Formik } from "formik";
import React from "react";
import { useRepo } from "../../../../repos/useRepo";
import { CompanyNote, CreateNoteForCompanyRequest, UpdateNoteForCompanyRequest } from "../../../../models/Company";
import { initialValuesForCompanyNoteUpdate, companyNoteSchema } from "../../../../utils/schemas/company-schema";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../rbac";
import { withModal } from "../../../modal";
import { TwoColumnFormLayout } from "../../../form/FormLayout";
import { CreateCompanyNoteFields, UpdateCompanyNoteFields } from "./CompanyNoteFields";
import { FormSubmitButton } from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";
import TextInput from "../../../form/inputs/TextInput";

export function UpdateCompanyNoteForm(props: { companyId: string, companyNote: CompanyNote, onCompanyNoteUpdated: (companyNote: CompanyNote) => void }) {
    const { companyId, companyNote, onCompanyNoteUpdated } = props;
    const { companyRepo } = useRepo();

    const { can } = useRbac();
    const isReadOnly = !can(actions.company.updateNote);

    const onSubmit = updateSubmitHandler<UpdateNoteForCompanyRequest, CompanyNote>(
        body => companyRepo.updateNoteForCompanyById(companyNote.companyId, companyNote.id, body),
        initialValuesForCompanyNoteUpdate,
        onCompanyNoteUpdated,
        console.error,
    );

    return (
        <Formik<UpdateNoteForCompanyRequest> enableReinitialize={true} initialValues={initialValuesForCompanyNoteUpdate(companyNote)} validationSchema={companyNoteSchema} onSubmit={onSubmit}>
            <Form>
                <TwoColumnFormLayout
                    col1FieldSet={<UpdateCompanyNoteFields companyId={companyId} disabled={isReadOnly} isThread={companyNote.threadId != null} />}
                    col2FieldSet={<TextInput name='body' label='Note' multiline={true} rows={21} disabled={isReadOnly} />}
                    bottomContent={<FormSubmitButton variant='Update' />} />
            </Form>
        </Formik>
    )
}

export function CreateCompanyNoteForm(props: { companyId: string, onCompanyNoteCreated: (companyNote: CompanyNote) => void, threadId?: string, prefix?: string }) {
    const { companyId, onCompanyNoteCreated, prefix, threadId } = props;
    const { companyRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateNoteForCompanyRequest) => companyRepo.createNoteForCompanyById(companyId, { ...body, body: `${prefix ? prefix + ': ' : ''}${body.body}`, threadId }),
        onCompanyNoteCreated,
        console.error
    );

    return (
        <Formik<CreateNoteForCompanyRequest> initialValues={initialValuesForCompanyNoteUpdate()} validationSchema={companyNoteSchema} onSubmit={onSubmit}>
            <Form>
                <TwoColumnFormLayout
                    col1FieldSet={<CreateCompanyNoteFields companyId={companyId} isThread={threadId != null}/>}
                    col2FieldSet={<TextInput name='body' label='Note' multiline={true} rows={21} />}
                    bottomContent={<FormSubmitButton variant='Create' />} />
            </Form>
        </Formik>
    )
}


export const UpdateCompanyNoteModal = withModal(UpdateCompanyNoteForm, "Update Customer Note");
export const CreateCompanyNoteModal = withModal(CreateCompanyNoteForm, "Create Customer Note");