import TableColumnFactory, {ColumnFactory} from "../../TableColumnFactory";
import {Template} from "../../../../models/mail/Template";
import {Reseller} from "../../../../models/Reseller";

export class TemplateTableColumnFactory implements ColumnFactory<Template>{
    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<Template>([
            TableColumnFactory.makeTemplateIdColumn(),
            TableColumnFactory.fromProperty("Name", "name"),
            TableColumnFactory.fromProperty("Template", "externalTemplateId"),
            TableColumnFactory.makeTriggerColumn("Trigger", 'triggerId', c => c.trigger),
            TableColumnFactory.makeSenderColumn("Sender", 'senderId', c => c.sender),
            TableColumnFactory.makeSalesChannelColumn("Sales Channel", 'salesChannelId', c => c.salesChannel),
            TableColumnFactory.makeResellerColumn("Reseller", 'resellerId',c => c.reseller),
        ]);
    }
}
