import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {Avatar, MenuItem} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import InfoIcon from '@material-ui/icons/Info';
import UserInfo from "../../models/UserInfo";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            display: 'block',
            backgroundColor: theme.palette.secondary.main,
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            // marginRight: 36,
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        grow: {
            flexGrow: 1,
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(7),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 200,
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

interface Props {
    title?: string;
    userInfo?: UserInfo;
    open: boolean
    onHamburgerClick: () => void;
    onProfileClicked?: () => void;
    onLogoutClicked?: () => void;
    onHelpClicked?: () => void;
}

const menuId = 'header-profile-dropdown-menu-id';

export default function Header(props: Props) {
    const classes = useStyles();
    const { title, open, onHamburgerClick, onProfileClicked, onLogoutClicked, onHelpClicked, userInfo } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const initials = userInfo ? userInfo.getInitials() : null;

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    function handleProfileClicked() {
        handleMenuClose();

        if (onProfileClicked) {
            onProfileClicked();
        }
    }

    function handleLogoutClicked() {
        handleMenuClose();

        if (onLogoutClicked) {
            onLogoutClicked();
        }
    }

    const renderMenu = (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {onProfileClicked && (
                <MenuItem onClick={handleProfileClicked}>Manage</MenuItem>
            )}
            <MenuItem onClick={handleLogoutClicked}>Logout</MenuItem>
        </Menu>
    );

    return (
        <div>
            <AppBar
                color='secondary'
                position='fixed'
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                <Toolbar>
                    <IconButton
                        color='inherit'
                        edge='start'
                        onClick={onHamburgerClick}
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h6' noWrap>
                        {title}
                    </Typography>

                    <div className={classes.grow} />

                    {onHelpClicked && (
                        <IconButton color='inherit' onClick={onHelpClicked}>
                            <InfoIcon/>
                        </IconButton>
                    )}

                    {userInfo && (
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <Avatar>{initials}</Avatar>
                        </IconButton>
                    )}
                </Toolbar>

            </AppBar>
            {renderMenu}
        </div>
    );
}
