import {useRepo} from "../../../../repos/useRepo";
import React, { useRef } from "react";
import Table from "../../../table/Table";
import {getTableDataFetcherFromRepoFetcher} from "../../../table/TableRepoAdapter";
import {ColumnFactory} from "../../../entities/TableColumnFactory";
import useCrudController from "../../../entities/useCrudController";
import {Topic} from "../../../../models/ti/Topic";
import {TopicTableColumnFactory} from "./TopicTableColumnFactory";
import useCreateTopicModal from "../../../modals/useCreateTopicModal";
import {useTopicActions} from "./useTopicActions";

interface Props {
    onTopicSelected: (topic: Topic) => void;
}

export default function TopicTable({ onTopicSelected }: Props) {
    const { topicRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { createTopicModal, openCreateTopicModalOpen } = useCreateTopicModal(reloadData);

    const {_list} = useCrudController(topicRepo, reloadData);

    const columnFactory: ColumnFactory<Topic> = new TopicTableColumnFactory();

    const deleteTopic = (topic: Topic) => {
        topicRepo.delete(topic.id).then(reloadData).catch(console.error)
    };

    const actions = useTopicActions(openCreateTopicModalOpen, deleteTopic);

    return (
        <>
            <Table<Topic>
                tableRef={tableRef}
                title={`Topics`}
                actions={actions}
                columns={columnFactory.makeColumns()}
                options={{}}
                onRowClick={onTopicSelected}
                data={getTableDataFetcherFromRepoFetcher(_list)}
            />
            {createTopicModal}
        </>
    );
}
