import {Company} from "../../../../models/Company";
import React from "react";
import CompanyProfilesTable from "../CompanyProfilesTable";

export default function ProfilesTab(props: { company: Company }) {

    const { company } = props;

    return (
        <>
            <CompanyProfilesTable company={company} />
        </>
    )
}
