import * as React from "react";
import {WebsiteAnalysis} from "../../../../models/WebsiteAnalysis";
import {useState} from "react";
import {ViewWebsiteAnalysisModal} from "./ViewWebsiteAnalysisForm";

export default function useViewWebsiteAnalysisModal() {

    const [record, setRecord] = useState<WebsiteAnalysis>();
    const close = () => setRecord(undefined);

    const modal = record ? (
        <ViewWebsiteAnalysisModal
            title='Website Analysis Report'
            open={true}
            onClose={close}
            websiteAnalysis={record}
        />
    ) : null;

    return {
        viewWebsiteAnalysisModal: modal,
        openViewWebsiteAnalysisModal: (websiteAnalysis: WebsiteAnalysis) => setRecord(websiteAnalysis)
    }
}