import SalesChannelRepo from "./SalesChannelRepo";
import {SalesChannel} from "../../models/SalesChannel";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../models";
import {ListQuery} from "../CrudRepo";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';
import {sanitize} from "../../utils/FormUtils";

export default class HttpSalesChannelRepo extends HttpCrudRepo<SalesChannel> implements SalesChannelRepo {
    pathComponents = ['mk', 'v1', 'sales-channels'];

    create(body: object): Promise<SalesChannel> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<SalesChannel>): Promise<SalesChannel> {
        return super.update(id, sanitize(resource));
    }

    list(query: ListQuery): Promise<CollectionResponse<SalesChannel>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    listAll(): Promise<SalesChannel[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<SalesChannel>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
