import ApplicationRepo from "./ApplicationRepo";
import {Application} from "../../models/Application";
import {ListQuery} from "../CrudRepo";

export class InMemoryApplicationRepoCache implements ApplicationRepo {
    cachedApplicationList?: Application[];
    applicationPromise?: Promise<Application[]>;
    
    constructor(private inner: ApplicationRepo) {}

    async listAll(): Promise<Application[]> {
        if (this.cachedApplicationList) {
            return this.cachedApplicationList;
        } else {
            if (this.applicationPromise) {
                return this.applicationPromise;
            } else {
                const applicationsPromise = this.inner.listAll();
                this.applicationPromise = applicationsPromise;
                const applications = await applicationsPromise;
                this.cachedApplicationList = applications;
                this.applicationPromise = undefined;
                return applications;
            }
        }
    };

    create = (resource: Partial<Application>) => this.inner.create(resource);

    delete = (id: string) => this.inner.delete(id);

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<Application>) => this.inner.update(id, resource);
}