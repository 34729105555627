import * as React from "react";
import {AssignRolesToUserModal} from "../forms/AssignRolesForm";
import {useState} from "react";
import {Role} from "../../models/Role";
import {User} from "../../models/User";
import { nameFromUser } from "../modal/utils";

export default function useAssignRolesToUserModal(onRolesAssigned: (roles: Role[]) => void) {

    const [user, setUser] = useState<User>();

    const handleRolesAssigned = (roles: Role[]) => {
        setUser(undefined);
        onRolesAssigned(roles);
    };

    const title = `Assign Roles to: ${nameFromUser(user)}`;

    const modal = user ? (
        <AssignRolesToUserModal
            open={true}
            title={title}
            onClose={() => setUser(undefined)}
            onRolesAssigned={handleRolesAssigned}
            user={user}
        />
    ) : null;

    return { setUserForAssigningRoles: setUser, assignUserRolesModal: modal }
}
