import {Form, Formik} from "formik";
import {WebsiteAnalysis, UpdateWebsiteAnalysisRequest} from "../../../../models/WebsiteAnalysis";
import React from "react";
import TextInput from "../../../form/inputs/TextInput";
import YesNoPicker from "../../../form/pickers/YesNoPicker";
import SendReportDelayPicker from "../../../form/pickers/SendReportDelayPicker";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import {withModal} from "../../../modal";
import {
    updateWebsiteAnalysisSchema,
    initialValuesForWebsiteAnalysisUpdate
} from "../../../../utils/schemas/website-analysis-schema";

import { updateSubmitHandler } from "../../../form/helpers/formSubmit";
import {useRepo} from "../../../../repos/useRepo";

interface Props {
    websiteAnalysis: WebsiteAnalysis
    onWebsiteAnalysisUpdated: (websiteAnalysis: WebsiteAnalysis) => void
}

function UpdateWebsiteAnalysisForm({ websiteAnalysis, onWebsiteAnalysisUpdated }: Props) {
    const { websiteAnalysisRepo } = useRepo()

    const onSubmit = updateSubmitHandler(
        (body: UpdateWebsiteAnalysisRequest) => websiteAnalysisRepo.update(websiteAnalysis.companyId, websiteAnalysis.reportId, body),
        initialValuesForWebsiteAnalysisUpdate,
        onWebsiteAnalysisUpdated,
        console.error
    );

    return (
        <Formik initialValues={initialValuesForWebsiteAnalysisUpdate(websiteAnalysis)}
                validationSchema={updateWebsiteAnalysisSchema}
                onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <p>Updating this record will generate an updated version<br/>of the Silk Tide report for this company.</p>

                <YesNoPicker name='notify_customer' label='Send Report' />

                <TextInput name='notify_customer_email' label='Send Report To'/>

                <SendReportDelayPicker name='notify_customer_delay' label='Send Report Delay' />

                <FormSubmitButton variant='Update' disabled={false} />

            </Form>
        </Formik>
    )
}

export const UpdateWebsiteAnalysisModal = withModal(UpdateWebsiteAnalysisForm);

