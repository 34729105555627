import * as React from "react";
import {useState} from "react";
import {Template} from "../../models/mail/Template";
import { FieldType } from "../../models/sid/FieldType";
import {CreateStateIdFieldTypeModal} from "../entities/state-id/settings/field-type/FieldTypeForm";

export default function useCreateStateIdFieldTypeModal(onFieldTypeCreated: (fieldType: FieldType) => void) {

    const [open, setOpen] = useState(false);

    const handleFieldTypeCreated = (fieldType: FieldType) => {
        setOpen(false);
        onFieldTypeCreated(fieldType)
    };

    const modal = (
        <CreateStateIdFieldTypeModal
            open={open}
            onClose={() => setOpen(false)}
            onFieldTypeCreated={handleFieldTypeCreated}
        />
    );

    return { setCreateStateIdFieldTypeModalOpen: setOpen, createStateIdFieldTypeModal: modal }
}
