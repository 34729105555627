import * as React from "react";
import {useState} from "react";
import {ProductLicense} from "../../models/ProductLicense";
import {Product} from "../../models/Product";
import {CreateProductLicenseForProductModal} from "../entities/billing/product-licenses/ProductLicenseForm";

export default function useCreateProductLicenseForProductModal(onProductLicenseCreated: (productLicense: ProductLicense) => void) {

    const [product, setProduct] = useState<Product>();

    const handleProductLicenseCreated = (productLicense: ProductLicense) => {
        setProduct(undefined);
        onProductLicenseCreated(productLicense)
    };

    const modal = product ? (
        <CreateProductLicenseForProductModal
            open={true}
            onClose={() => setProduct(undefined)}
            product={product}
            onProductLicenseCreated={handleProductLicenseCreated}
        />
    ) : null;

    return { setProductForCreateProductLicenseModal: setProduct, createProductLicenseForProductModal: modal }
}
