import * as Yup from 'yup';
import {SalesChannelApplication} from "../../models/SalesChannelApplication";
import {SalesChannel} from "../../models/SalesChannel";

const baseSalesChannelApplicationFields = {
    alias: Yup.string().nullable(),
    url: Yup.string().required("URL is required"),
    urlType: Yup.string().required("URL type is required"),
    urlTarget: Yup.string().nullable(),
    isSslRequired: Yup.bool().required()
};

export const createSalesChannelApplicationSchema = Yup.object().shape({
    ...baseSalesChannelApplicationFields,
    salesChannelId: Yup.string().required("Sales channel is required"),
    applicationId: Yup.string().required("Application is required")
});

export const updateSalesChannelApplicationSchema = Yup.object().shape(baseSalesChannelApplicationFields);

export const initialValuesForSalesChannelApplicationUpdate = (salesChannelApplication?: SalesChannelApplication) => ({
    alias: salesChannelApplication?.alias ?? "",
    url: salesChannelApplication?.url ?? "",
    urlType: salesChannelApplication?.urlType ?? "",
    urlTarget: salesChannelApplication?.urlTarget ?? "",
    impersonationUrl: salesChannelApplication?.impersonationUrl ?? "",
    isSslRequired: salesChannelApplication?.isSslRequired ?? true,

});

export const initialValuesForSalesChannelApplicationCreate = (salesChannel?: SalesChannel) => ({
    ...initialValuesForSalesChannelApplicationUpdate(),
    salesChannelId: salesChannel?.id ?? '',
    applicationId: '',
});
