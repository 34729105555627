import React, { useMemo } from "react";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";
import { SubscriptionStatus } from '../../../models/Subscription'

interface Props extends PickerPropsWithoutOptions { }

export default function SubscriptionStatusPicker({ ...PickerProps }: Props) {

    interface Option { text: string, value: string }

    const values: Option[] = Object.values(SubscriptionStatus).map(x => ({ text: x.toString().toUpperCase(), value: x.toString() }));

    const options = useMemo(() => values.map(c => ({ id: c.value, label: c.text })), [values]);

    return <PickerInput label={'Status'} options={options} {...PickerProps} />
}
