import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useRepo } from "../../../../../repos/useRepo";
import { initialValuesForStateIdApplicationNoteField, stateIdApplicationNoteFieldSchema } from "../../../../../utils/schemas/state-id-schema";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../../rbac";
import { withModal } from "../../../../modal";
import { TwoColumnFormLayout } from "../../../../form/FormLayout";
import { FormSubmitButton } from "../../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../../form/helpers/formSubmit";
import { ApplicationNote, CreateNoteForApplicationRequest, UpdateNoteForApplicationRequest } from "../../../../../models/sid/ApplicationNote";
import { UpdateStateIdApplicationNoteFields, CreateStateIdApplicationNoteFields } from "./ApplicationNoteFields";
import TextInput from "../../../../form/inputs/TextInput";

export function UpdateStateIdApplicationNoteForm(props: { companyId: string | null, applicationNote: ApplicationNote, onApplicationNoteUpdated: (applicationNote: ApplicationNote) => void }) {
    const { applicationNote, companyId, onApplicationNoteUpdated } = props;
    const { stateIdApplicationRepo } = useRepo();

    const { can } = useRbac();
    const isReadOnly = !can(actions.template.update);

    const onSubmit = updateSubmitHandler<UpdateNoteForApplicationRequest, ApplicationNote>(
        body => stateIdApplicationRepo.updateNote(applicationNote.applicationId, applicationNote.id, body),
        initialValuesForStateIdApplicationNoteField,
        onApplicationNoteUpdated,
        console.error,
    );

    return (
        <Formik<UpdateNoteForApplicationRequest> enableReinitialize={true} initialValues={initialValuesForStateIdApplicationNoteField(applicationNote)} validationSchema={stateIdApplicationNoteFieldSchema} onSubmit={onSubmit}>
            <Form>
                <TwoColumnFormLayout
                    col1FieldSet={<UpdateStateIdApplicationNoteFields companyId={companyId} disabled={isReadOnly} />}
                    col2FieldSet={<TextInput name='body' label='Note' multiline={true} rows={21} disabled={isReadOnly} />}
                    bottomContent={<FormSubmitButton variant='Update' />} />
            </Form>
        </Formik>
    )
}

export function CreateStateIdApplicationNoteForm(props: { companyId: string | null, applicationId: string, threadId?: string,  onApplicationNoteCreated: (applicationNote: ApplicationNote) => void }) {
    const { applicationId, companyId, onApplicationNoteCreated, threadId } = props;
    const { stateIdApplicationRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateNoteForApplicationRequest) => stateIdApplicationRepo.createNote(applicationId, {...body, threadId}),
        onApplicationNoteCreated,
        console.error
    );

    return (
        <Formik<CreateNoteForApplicationRequest> initialValues={initialValuesForStateIdApplicationNoteField()} validationSchema={stateIdApplicationNoteFieldSchema} onSubmit={onSubmit}>
            <Form>
                <TwoColumnFormLayout
                    col1FieldSet={<CreateStateIdApplicationNoteFields companyId={companyId} />}
                    col2FieldSet={<TextInput name='body' label='Note' multiline={true} rows={21} />}
                    bottomContent={<FormSubmitButton variant='Create' />} />
            </Form>
        </Formik>
    )
}


export const UpdateStateIdApplicationNoteModal = withModal(UpdateStateIdApplicationNoteForm, "Update Application Note");
export const CreateStateIdApplicationNoteModal = withModal(CreateStateIdApplicationNoteForm, "Create Application Note");