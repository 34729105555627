import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Theme} from "@material-ui/core";
import {Link} from "react-router-dom";
import Routes from "../../../../Routes";
import { Application } from '../../../../models/sid/Application';
import { StateIdApplicationStatusChip } from './ApplicationStatus';
import { ApplicationMenuButton } from './ApplicationMenu';

interface Props {
    application: Application;
    onDeleteClicked: () => void;
    onAssignStatusClicked: () => void;
}

export default function ApplicationActionBar({ application, onDeleteClicked, onAssignStatusClicked }: Props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>

            <div className={classes.leftSection}>

                <h3 className={classes.title}>
                    {application.legalName}
                </h3>

                {application.member && (
                    <Link className={classes.subtitle} to={Routes.forUserById(application.memberId)}>
                        {application.member.firstName} {application.member.lastName} 
                    </Link>
                )}

            </div>

            <div className={classes.rightSection}>
                <StateIdApplicationStatusChip application={application} />
                <ApplicationMenuButton application={application} onDeleteClicked={onDeleteClicked} onAssignStatusClicked={onAssignStatusClicked}/> 
            </div> 

        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden"
    },
    leftSection: {
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    rightSection: {
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    title: {

    },
    subtitle: {
        marginTop: "2px",
        marginLeft: "8px"
    },
}));
