import React, {useMemo} from 'react';
import _ from "lodash";
import './App.css';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {CssBaseline} from "@material-ui/core";
import {environment} from "./env";
import AuthService, {AzureAuthService, AuthenticationResponse} from "@gsb/react-auth";
import HttpService, {FetchHttpService} from "@gsb/react-http";
import config from "./config";
import theme from "./theme";
import Repo, {HttpRepo} from "./repos/Repo";
import {RepoProvider} from "./repos/useRepo";
import {AuthInterceptor} from "./interceptors/AuthInterceptor";
import {Services} from "./services";
import {ServiceProvider} from "./services/useServices";
import App from "./App";
import withNavigation from "./services/navigation/withNavigation";
import { withRouter, Route, RouteComponentProps, Switch } from 'react-router-dom'
import AuthCallback from "./components/auth/Callback";
import Routes from "./Routes";
import Login from "./components/auth/Login";
import {useAuthState, withAuthState} from "./services/auth/useAuthState";
import useSessionExpirationModals from './components/modals/useSessionExpirationModals';
import {useNavigation} from "./services/navigation/useNavigation";

interface Props extends RouteComponentProps {

}

function AppSetup(props: Props) {
  const { navigateToUrl } = useNavigation();
  const { setAuthResponse, setAuthError } = useAuthState();

  const parseStateFromAuthResponse = (authResponse?: AuthenticationResponse) => {
    if (authResponse && authResponse.accountState) {
      try {
        return JSON.parse(authResponse.accountState);
      } catch (err) {
        console.warn(err)
      }
    }

    return null;
  };

  const authService: AuthService = useMemo(() => {
    return new AzureAuthService(config.auth, (err, res) => {
      if (err) {
        setAuthError(err);
      } else if (res) {
        setAuthResponse(res);
        const state = parseStateFromAuthResponse(res);

        if (state) {
          const { redirectUri } = state;
          navigateToUrl(redirectUri)
        }
      }
    });
  }, []);

  const onUnauthorized = () => {
    setSessionExpired(true);
  };

  const onAuthResponse = (token?: AuthenticationResponse) => {
    setExpiresOn(_.get(token, "expiresOn", null));
  }

  const triggerRefresh = () => {
    authService.loginRedirect({ redirectUri: window.location.href });
  }

  const {
    setSessionExpired,
    setExpiresOn,
    sessionExpiredModal,
    sessionExpiringToast
  } = useSessionExpirationModals(triggerRefresh);

  const httpService: HttpService = useMemo(() => {
    return new FetchHttpService({
      baseUrl: environment.apiBaseUrl,
      interceptors: [new AuthInterceptor(authService, onUnauthorized, onAuthResponse)],
    })
  }, [authService]);

  const services: Services = useMemo(() => ({ authService, httpService }), [authService, httpService]);
  const repo: Repo = useMemo(() => new HttpRepo(httpService), [httpService]);

  return (
      <ServiceProvider services={services}>
        <RepoProvider repo={repo}>
          <ThemeProvider theme={theme}>
            <>
              <CssBaseline />
              <Switch>
                <Route path={Routes.authCallback} render={() => <AuthCallback/>} />
                <Route path={Routes.login} render={() => <Login/>} />
                <Route path={'*'} render={() => <App/>}/>
              </Switch>
              {sessionExpiredModal}
              {sessionExpiringToast}
            </>
          </ThemeProvider>
        </RepoProvider>
      </ServiceProvider>
  );
}

export default withAuthState(withRouter(withNavigation(AppSetup)));
