import React from "react";
import _ from "lodash";
import { Route, RouteProps, Switch } from "react-router-dom";
import { useRbac } from "@gsb/react-rbac";
import { RouteConfigs, RouteConfig } from "./RouteConfigs";

export const RbacRoutes = () => (
  <Switch>
    {
      Object
      .keys(  RouteConfigs )
      .map( (path, key) => {
        const routeConfig = RouteConfigs[ path ];
        return (<RbacRoute key={key} { ...routeConfig } path={path} />)
      } )
    }
  </Switch>
);


const PermissionDenied = () => (
    <div style={{textAlign: "center"}}>
      It seems you don't have permission to access this view.
      <br></br>
      Please contact your administrator if this isn't intended.
    </div>
);

const RbacRoute = ( props: RouteConfig ) => {
  const { can } = useRbac();
  const routeProps: RouteProps = _.omit( props, [ "requiredActions" ])

  let { requiredActions } = props;
  if( !requiredActions || _.isEmpty( requiredActions ) ) {
    return (<Route { ...routeProps }  />);
  }

  if( !_.isArray( requiredActions ) ) {
    requiredActions = [ requiredActions ]
  }

  const hasAccess = requiredActions.some( action => can( action ) )

  if( hasAccess ) {
    return (<Route { ...routeProps }  />);
  }

  return <Route { ...routeProps } render={() => <PermissionDenied />} />;
}

export default RbacRoute;