import * as React from "react";
import TextInput from "../../../form/inputs/TextInput";


interface Props {
    disabled?: boolean;
}

export default function TopicFields({ disabled }: Props) {
    return (
        <>
            <TextInput label={"Name"} name={"name"} disabled={disabled}/>

            <TextInput label={"Detail"} name={"detail"} disabled={disabled}/>

            <TextInput label={"Icon URL"} name={"iconUrl"} disabled={disabled}/>

            <TextInput label={"Icon Identifier"} name={"iconIdentifier"} disabled={disabled}/>
        </>
    )
}