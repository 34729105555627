import React, {useEffect, useMemo, useState} from "react";
import {SubscriptionFileType} from "../../../models/SubscriptionFileType";
import {useRepo} from "../../../repos/useRepo";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export function SubscriptionFileTypePicker({ ...PickerProps }: Props) {

    const { subscriptionRepo } = useRepo();
    const [fileTypes, setFileTypes] = useState<SubscriptionFileType[]>([]);

    useEffect(() => {
        subscriptionRepo.listAllSubscriptionFileTypes().then(setFileTypes).catch(console.error);
    }, []);

    const options = useMemo(() => fileTypes.map(f => ({ id: f.id, label: f.name })), [fileTypes]);

    return (
        <PickerInput label={'File Type'} options={options} {...PickerProps}/>
    )
}