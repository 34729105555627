import * as React from "react";
import {useState} from "react";
import {UpdateSiteTopicModal} from "../tarkenton-institute/settings/sites/SiteTopicForm";
import {SiteTopic} from "../../models/ti/SiteTopic";

export default function useUpdateSiteTopicModal(onSiteTopicUpdated: (siteTopic: SiteTopic) => void) {

    const [siteTopic, setSiteTopic] = useState<SiteTopic>();

    const handleSiteTopicUpdated = (siteTopic: SiteTopic) => {
        setSiteTopic(undefined);
        onSiteTopicUpdated(siteTopic)
    };

    const modal = siteTopic ? (
        <UpdateSiteTopicModal
            open={true}
            onClose={() => setSiteTopic(undefined)}
            siteTopic={siteTopic}
            onSiteTopicUpdated={handleSiteTopicUpdated}
        />
    ) : null;

    return { setSiteTopicForUpdate: setSiteTopic, updateSiteTopicModal: modal }
}
