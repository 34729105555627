import * as React from "react";
import {useState} from "react";
import {SalesChannelApplication} from "../../models/SalesChannelApplication";
import {CreateSalesChannelApplicationForSalesChannelModal} from "../entities/brands/sales-channel-applications/SalesChannelApplicationForm";
import {SalesChannel} from "../../models/SalesChannel";

export default function useCreateSalesChannelApplicationForSalesChannelModal(onSalesChannelApplicationCreated: (salesChannelApplication: SalesChannelApplication) => void) {

    const [salesChannel, setSalesChannel] = useState<SalesChannel>();

    const handleSalesChannelApplicationCreated = (salesChannelApplication: SalesChannelApplication) => {
        setSalesChannel(undefined);
        onSalesChannelApplicationCreated(salesChannelApplication)
    };

    const modal = salesChannel ? (
        <CreateSalesChannelApplicationForSalesChannelModal
            open={true}
            onClose={() => setSalesChannel(undefined)}
            salesChannel={salesChannel}
            onSalesChannelApplicationCreated={handleSalesChannelApplicationCreated}
        />
    ) : null;

    return { setSalesChannelForCreateSalesChannelApplicationModal: setSalesChannel, createSalesChannelApplicationForSalesChannelModal: modal }
}
