import {Product} from "../../../../models/Product";
import {useRepo} from "../../../../repos/useRepo";
import {default as React, useEffect, useRef} from "react";
import Table, {TableColumn, TableQuery} from "../../../table/Table";
import {Plan} from "../../../../models/Plan";
import TableColumnFactory from "../../TableColumnFactory";
import {
    queryResultFromArray,
} from "../../../table/TableRepoAdapter";
import useUpdatePlanModal from "../../../modals/useUpdatePlanModal";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import useCreatePlanForProductModal
    from "../../../modals/useCreatePlanForProductModal";

interface Props {
    product: Product,
}
export default function PlanTable({ product }: Props) {
    const { planRepo, productRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canCreatePlan = can(actions.plan.create);
    const canUpdatePlan = can(actions.plan.update);
    const canDeletePlan = can(actions.plan.delete);

    const { setProductForCreatePlanModal, createPlanForProductModal } = useCreatePlanForProductModal(reloadData);
    const { setPlanForUpdate, updatePlanModal } = useUpdatePlanModal(reloadData);

    useEffect(reloadData, [product]);

    const columns: TableColumn<Plan>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makePlanIdColumn(),
        TableColumnFactory.fromProperty("Name", "name"),
        TableColumnFactory.fromProperty("Identifier", "identifier"),
        TableColumnFactory.makeResellerColumn("Reseller", "resellerId", p => p.reseller),
        TableColumnFactory.makeStripePlanColumn(p => p.stripePlanId),
        {
            title: "Stripe Account",
            field: 'stripeAccountIdentifier',
            emptyValue: '-'
        },
        {
            title: 'ADP Plan ID',
            field: 'adpPlanId',
            emptyValue: '-'
        },
        {
            title: 'App Store Product ID',
            field: 'appStoreProductId',
            emptyValue: '-'
        },
        {
            title: 'Description',
            field: 'description',
            emptyValue: '-'
        },
    ]);

    const deletePlan = (plan: Plan) => {
        planRepo.delete(plan.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onPlanSelected = (plan: Plan) => {
        if (canUpdatePlan) {
            setPlanForUpdate(plan)
        }
    };

    const fetchData = async (tableQuery: TableQuery<Plan>) => {
        const res = await productRepo.listPlansForProductById(product.id);
        return queryResultFromArray(res)
    };

    return (
        <>
            <Table<Plan>
                tableRef={tableRef}
                title={`Plans for ${product.name}`}
                columns={columns}
                actions={[
                    makeCreateAction(() => setProductForCreatePlanModal(product), { disabled: !canCreatePlan }),
                    makeDeleteAction(deletePlan, { disabled: !canDeletePlan })
                ]}
                options={{
                    search: false,
                    filtering: false,
                    paging: false
                }}
                onRowClick={onPlanSelected}
                data={fetchData}
            />
            {createPlanForProductModal}
            {updatePlanModal}
        </>
    );
}
