import React, { useMemo } from "react";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions { }

export default function CallStatusPicker({ ...PickerProps }: Props) {

    const values: string[] = ['N/A', 'Resolved', 'Unresolved', 'Outstanding'];

    const options = useMemo(() => values.map(c => ({ id: c, label: c })), [values]);

    return <PickerInput label={'Call Status'} options={options} {...PickerProps} />
}