import {CollectionResponse, Entity} from "../models";
import {ListQuery} from "./CrudRepo";
import HttpService from "@gsb/react-http";
import {getHttpQueryFromListQuery} from "../utils/QueryUtils";

export abstract class HttpCrudRepo<T extends Entity> {
  protected abstract pathComponents: string[];

  constructor(protected http: HttpService) {}

  list(query: ListQuery): Promise<CollectionResponse<T>> {
    const httpQuery = getHttpQueryFromListQuery(query);
    return this.http.get(this.pathComponents, { query: httpQuery });
  }

  create(body: object): Promise<T> {
    return this.http.post(this.pathComponents, body);
  }

  getById(id: string): Promise<T> {
    return this.http.get([...this.pathComponents, id]);
  }

  update(id: string, resource: Partial<T>): Promise<T> {
    return this.http.put([...this.pathComponents, id], {...resource});
  }

  delete(id: string): Promise<void> {
    return this.http.delete([...this.pathComponents, id]);
  }
}