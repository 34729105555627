import SalesChannelApplicationRepo from "./SalesChannelApplicationRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {CollectionResponse} from "../../models";
import {ListQuery} from "../CrudRepo";
import {SalesChannelApplication} from "../../models/SalesChannelApplication";
import {sanitize} from "../../utils/FormUtils";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from "../../config";

export default class HttpSalesChannelApplicationRepo extends HttpCrudRepo<SalesChannelApplication> implements SalesChannelApplicationRepo {
    pathComponents = ['mk', 'v1', 'sales-channel-applications'];

    listAllForSalesChannel(salesChannelId: string): Promise<CollectionResponse<SalesChannelApplication>> {
        const query = { pageSize: config.maxDropdownSize, filters: [{ field: 'salesChannelId', value: salesChannelId }] };
        return this.list(query);
    }

    list(query: ListQuery): Promise<CollectionResponse<SalesChannelApplication>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "salesChannel,application";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    create(body: object): Promise<SalesChannelApplication> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<SalesChannelApplication>): Promise<SalesChannelApplication> {
        return super.update(id, sanitize(resource));
    }
}
