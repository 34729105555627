import MetadataTypeRepo from "./MetadataTypeRepo";
import MetadataType from "../../models/MetadataType";
import HttpService from "@gsb/react-http";

export default class HttpStateRepo implements MetadataTypeRepo {
    pathComponents = ['mk', 'v1', 'metadata-types'];

    constructor(private http: HttpService) {}

    list(): Promise<MetadataType[]> {
        return this.http.get(this.pathComponents);
    }
}
