import {AuthenticationResponse} from "@gsb/react-auth";
import {Employee} from "./Employee";

export default class UserInfo {
  constructor(
      public email: string,
      public firstName: string,
      public lastName: string) {}

  public getInitials(): string {
    return `${this.firstName[0] || ''}${this.lastName[0] || ''}`
  }

  static from(res?: AuthenticationResponse): UserInfo | undefined {
    if (res && res.idToken) {
      const idToken = res.idToken as any;
      const claims = idToken.claims as any;
      const firstName = claims.given_name as string;
      const lastName = claims.family_name as string;
      const email = claims.email as string;
      return  new UserInfo(email, firstName, lastName);
    } else {
      return undefined;
    }
  }

  static fromEmployee(employee: Employee): UserInfo {
    return new UserInfo(employee.email, employee.firstName, employee.lastName);
  }
}

