import * as React from "react";
import TextInput from "../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
}

export default function ApplicationFields({ disabled }: Props) {
    return (
        <>
            <TextInput name="name" label="Name" disabled={disabled} />

            <TextInput name="identifier" label="Identifier" disabled={disabled}/>
        </>
    )
}