import React from "react";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export interface QuickFilter {
  key: string;
  label: string;
}

interface Props<T extends QuickFilter> {
  quickFilters: T[];
  value?: T;
  onChange: (value: T) => void;
}

export default function QuickFilters<T extends QuickFilter>({ quickFilters, value, onChange }: Props<T>) {

  return (
    <ToggleButtonGroup
      exclusive
      value={value ? value.key : undefined}
      onChange={(event: any, value: string | null) => {
        if (!value) {
          return;
        }

        const filter = quickFilters.find(f => f.key === value);

        if (filter) {
          onChange(filter);
        }
      }}
      style={{ alignSelf: 'center', height: 56, margin: '6px 6px 14px' }}>
      {quickFilters.map((filter) => (
          <ToggleButton
              key={filter.key}
              style={{ minWidth: 100, borderRadius: 8, borderLeftColor: "lightgray", marginRight: 8 }}
              value={filter.key}
          >
              {filter.label}
          </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};