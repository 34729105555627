import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {Reseller} from "../../../models/Reseller";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {
    salesChannelId?: string
}

export function ResellerPicker({ salesChannelId, ...PickerProps }: Props) {
    const { resellerRepo } = useRepo();
    const [resellers, setResellers] = useState<Reseller[]>([]);

    useEffect(() => {
        resellerRepo.listAll().then(resellers => {
            if (salesChannelId) {
                const resellersForSalesChannel = resellers.filter(r => r.salesChannelId === salesChannelId);
                setResellers(resellersForSalesChannel);
            } else {
                setResellers(resellers);
            }
        }).catch(console.error);
    }, [resellerRepo, salesChannelId]);
    
    const options = useMemo(() => resellers.map(p => ({ id: p.id, label: p.name || p.id })), [resellers]);

    return <PickerInput label={'Reseller'} options={options} {...PickerProps} />
}