import * as React from 'react';
import { red } from "@material-ui/core/colors";
import TableColumnFactory, { ColumnFactory, FilterComponentProps } from "../../TableColumnFactory";
import { Application } from "../../../../models/sid/Application";
import { TableColumn } from "../../../table/Table";
import StateDropdown from "../../../table/dropdowns/StateDropdown";
import { StateIdApplicationStatusChip } from './ApplicationStatus';
import StateIdConsultantDropdown from '../../../table/dropdowns/StateIdConsultantDropdown';
import moment from 'moment';

export class ApplicationTableColumnFactory implements ColumnFactory<Application>{
    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<Application>([
            makeStateIdApplicationId(),
            makeOwnerName(),
            TableColumnFactory.fromProperty("Owner Email", "member.email"),
            TableColumnFactory.makeResellerColumn("Reseller", "member.resellerId", x => x.member?.reseller),
            TableColumnFactory.fromProperty("Company", "legalName"),
            makeStateCodeColumn("State", "stateCode"),
            makeStatusColumn(),
            TableColumnFactory.dateColumn("Submitted", "submittedOn"),
            makeAssignedToColumn("Assigned To", "assignedTo")
        ]);
    }
}

const makeAssignedToColumn = (title: string, path: string): TableColumn<Application> => {
    return {
        title,
        path: path,
        field: (path as keyof Application), // This is a hack to get the filter component to appear
        filterComponent: props => <StateIdConsultantDropdown {...props as FilterComponentProps<any>}/>,
        render: (data: Application) => {
            return data.assignedToAgent ? data.assignedToAgent.firstName + ' ' + data.assignedToAgent.lastName : '-';
        }
      }
}

const makeStatusColumn = (): TableColumn<Application> => {
    return { 
        ...TableColumnFactory.makeStateIdApplicationStatusColumn("Status", "statusId", x => x.status),
        render: (data: Application) => {
            return (<StateIdApplicationStatusChip application={data}></StateIdApplicationStatusChip>);
        }
    };
}

const makeStateCodeColumn = (title: string, path: string): TableColumn<Application> => {
    return {
      title,
      path: path,
      field: (path as keyof Application), // This is a hack to get the filter component to appear
      filterComponent: props => <StateDropdown {...props as FilterComponentProps<any>}/>,
    }
  }

const makeOwnerName = (): TableColumn<Application> => {
    return {
        title: 'Owner Name',
        field: 'member.firstName',
        render: (data: Application) => {
            return data.member ? data.member.firstName + ' ' + data.member.lastName : '-';
        }
    }
}

const makeStateIdApplicationId = (): TableColumn<Application> => {
    return {
        ...TableColumnFactory.makeStateIdApplicationIdColumn(),
        cellStyle: (e: Application[], rowData: Application) => {
            const maxSubmittedOn = moment().utc().add(-2, 'day');
            if (rowData.status?.identifier == 'ready' && moment(rowData.submittedOn) < maxSubmittedOn) {
                return { borderLeftWidth: 15, borderLeftStyle: 'solid', borderLeftColor: red[500] };
            }
            return {};
        }
    };
}