import * as React from "react";
import {useState} from "react";
import {SalesChannelApplication} from "../../models/SalesChannelApplication";
import {UpdateSalesChannelApplicationModal} from "../entities/brands/sales-channel-applications/SalesChannelApplicationForm";

export default function useUpdateSalesChannelApplicationModal(onSalesChannelApplicationUpdated: (salesChannelApplication: SalesChannelApplication) => void) {

    const [salesChannelApplication, setSalesChannelApplication] = useState<SalesChannelApplication>();

    const handleSalesChannelApplicationUpdated = (salesChannelApplication: SalesChannelApplication) => {
        setSalesChannelApplication(undefined);
        onSalesChannelApplicationUpdated(salesChannelApplication)
    };

    const modal = salesChannelApplication ? (
        <UpdateSalesChannelApplicationModal
            open={true}
            onClose={() => setSalesChannelApplication(undefined)}
            salesChannelApplication={salesChannelApplication}
            onSalesChannelApplicationUpdated={handleSalesChannelApplicationUpdated}
        />
    ) : null;

    return { setSalesChannelApplicationForUpdated: setSalesChannelApplication, updateSalesChannelApplicationModal: modal }
}
