import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CompanyNote } from '../../../../models/Company';
import moment from 'moment';
import { Divider, Grid, IconButton, withStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { TimestampDetailTooltip } from '../../../form/tooltips/TimestampTooltip';

interface Props {
  threads: CompanyNote[],
  note: CompanyNote,
  onThreadDelete: (note: CompanyNote) => void,
  onThreadEdit: (note: CompanyNote) => void
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    minWidth: 500,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    padding: 0
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    color: theme.palette.text.secondary,
    '& button': {
      margin: theme.spacing(0.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export default function CompanyTimelineNoteThreads({ threads, note, onThreadEdit, onThreadDelete }: Props) {

  const classes = useStyles();

  const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#607d8b',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

  return (
    <>
      
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableHead>
            <TableRow> 
              <StyledTableCell>Threads</StyledTableCell>
              <StyledTableCell style={{ width: 118 }} align='center'>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {threads.map((thread, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" onClick={() => onThreadEdit(thread)} style={{ cursor: 'pointer' }} title={thread.body}>
                  {thread.body.length > 60 ? thread.body.substring(0, 57).trimEnd() + '...' : thread.body}
                </TableCell>
                <TableCell>
                  <Grid container alignItems="center" className={classes.actions}>
                    <TimestampDetailTooltip
                      created={thread.createdBy + ' @ ' + moment(thread.createdAt).format("l")}
                      updated={thread.updatedBy + ' @ ' + moment(thread.updatedAt).format("l")}
                      iconSize='small' />
                    <Divider orientation="vertical" flexItem />
                    <IconButton size='small' title='Delete Thread' onClick={() => onThreadDelete(thread)}><Delete /></IconButton>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}