import React, { useMemo } from "react";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions { }

export default function WebsiteAnalysisStatusPicker({ ...PickerProps }: Props) {

    interface Option { text: string, value: string }

    const values: Option[] = [{ text: 'Completed', value: 'complete' }, { text: 'Running', value: 'running' }];

    const options = useMemo(() => values.map(c => ({ id: c.value.toString(), label: c.text })), [values]);

    return <PickerInput label={'Status'} options={options} {...PickerProps} />
}
