import React, {useEffect, useRef} from "react";
import { queryResultFromArray } from "../../../../table/TableRepoAdapter";
import Table, {TableColumn, TableQuery} from "../../../../table/Table";
import TableColumnFactory from "../../../TableColumnFactory";
import {useRepo} from "../../../../../repos/useRepo";
import {Company} from "../../../../../models/Company";
import {Subscription} from "../../../../../models/Subscription";
import SubscriptionDetailPanel from "./SubscriptionDetailPanel";
import useCreateNoChargeSubscriptionForCompanyModal
    from "../../create-no-charge-subscription/useCreateNoChargeSubscriptionForCompanyModal";
import {makeCreateAction} from "../../../../table/TableActionFactory";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../../rbac";

interface Props {
    company: Company
}

export default function SubscriptionsTable({ company }: Props) {
    const { companyRepo } = useRepo();
    const { can } = useRbac();
    const canCreateNoChargeSubscription = can(actions.company.createNoChargeSubscription)

    const tableRef = useRef<any>(null);

    const reloadData = () => tableRef.current.onQueryChange();

    const { createNoChargeSubscriptionForCompany, openCreateNoChargeSubscriptionForCompanyModal} = useCreateNoChargeSubscriptionForCompanyModal(company, reloadData);

    useEffect(reloadData, [company.id]);

    const columns: TableColumn<Subscription>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeSubscriptionIdColumn(),
        TableColumnFactory.fromProperty("Description", "description"),
        TableColumnFactory.makeSubscriptionTypeColumn(),
        TableColumnFactory.booleanColumn("Active", "isActive"),
        TableColumnFactory.booleanColumn("Trial", "isTrial"),
        TableColumnFactory.booleanColumn("Demo", "isDemo"),
        TableColumnFactory.fromProperty("Status", "status"),
        TableColumnFactory.fromProperty("Status Reason", "statusReason"),
        TableColumnFactory.dateColumn("Activated", "activatedAt"),
        TableColumnFactory.dateColumn("Purchased", "purchasedAt"),
        TableColumnFactory.dateColumn("Expires", "expiresAt"),
        TableColumnFactory.dateColumn("Renews", "renewsAt"),
        TableColumnFactory.dateColumn("Canceled", "canceledAt"),
    ]);

    const fetchData = async (tableQuery: TableQuery<Subscription>) => {
        const res = await companyRepo.listSubscriptionsForCompanyById(company.id);
        return queryResultFromArray(res)
    };

    return (
        <>
            <Table<Subscription>
                tableRef={tableRef}
                title={`Subscriptions for ${company.name}`}
                columns={TableColumnFactory.disableSearchAndFiltering(columns)}
                actions={canCreateNoChargeSubscription ? [makeCreateAction(openCreateNoChargeSubscriptionForCompanyModal)] : []}
                options={{
                    search: false,
                    filtering: false,
                    paging: false
                }}
                detailPanel={subscription => (
                    <SubscriptionDetailPanel subscription={subscription} onSubscriptionCanceled={reloadData}/>
                )}
                data={fetchData}
            />

            {createNoChargeSubscriptionForCompany}
        </>
    );
}
