import React, { useEffect, useState } from "react";
import { Form, Formik, FieldArray } from "formik";
import { Company, CompanyMetadata } from "../../../models/Company";
import MetadataType from "../../../models/MetadataType";
import { useRbac } from "@gsb/react-rbac";
import { useRepo } from "../../../repos/useRepo";
import { actions } from "../../../rbac";
import { verticalFormLayout } from "../../form/styles/layout";
import { FormSubmitButton } from "../../form/buttons/FormSubmitButton";
import { updateSubmitHandler } from "../../form/helpers/formSubmit";
import {TimestampTooltip} from "../../form/tooltips/TimestampTooltip";
import MetadataInput from "../../form/inputs/MetadataInput";


interface Props {
    company: Company
}

export default function CompanyMetadataForm({ company }: Props) {

    const { companyRepo, metadataTypeRepo } = useRepo();
    const { can } = useRbac();

    const canUpdateMetadata = can(actions.company.updateMetadata);

    const [metadata, setMetadata] = useState<CompanyMetadata[]>([]);
    const [metadataTypes, setMetadataTypes] = useState<MetadataType[]>([]);

    useEffect(() => {
        Promise.all([
            companyRepo.listMetadataForCompanyById(company.id),
            metadataTypeRepo.list()
        ])
            .then(([metadata, metadataTypes]) => {
                setMetadataTypes(metadataTypes);
                setMetadata(metadata);
            });
    }, [company.id]);

    const onSubmit = updateSubmitHandler<CompanyMetadata[], CompanyMetadata[]>(
        (body: CompanyMetadata[]) => companyRepo.updateMetadataForCompanyById(company.id,
            body.filter(d => !d.isReadOnly).map(d => {
                if (d.values) {
                    d.value = d.values.join(',');
                }
                return d;
            }).filter(d => d.value !== d.orgValue)),
        (d) => initRecords(metadata.map(record => {
            return Object.assign(record, d.find(child => {
                return child && record.id === child.id;
            }))
        })),
        undefined,
        console.error,
    )

    const initRecords = (records: CompanyMetadata[]) => records.map((r) => {
        r.orgValue = r.value;
        r.metadataType = metadataTypes.find(({ id }) => id === (r.metadataTypeId || 'text'));
        if (r.metadataType && r.metadataType.component === 'select-multi') {
            r.values = r.value?.split(',') || [];
        }
        return r;
    });

    const disabled = !canUpdateMetadata;

    return (<Formik enableReinitialize={true}
        initialValues={initRecords(metadata)}
        onSubmit={onSubmit}
        render={({ values }) => (
            <Form style={verticalFormLayout}>
                <FieldArray
                    name=""
                    render={arrayHelpers => (
                        <div>
                            {values && values.length > 0 ? (
                                values.map((field, index) => {
                                    const metadataField = {
                                        label: field.label,
                                        component: field.metadataType?.component ?? '',
                                        options: field.metadataType?.options ?? ''
                                    }
                                    return (
                                        <div key={index}>
                                            <MetadataInput metadataField={metadataField} name={field.metadataType?.component === 'select-multi' ? `[${index}].values` : `[${index}].value`} label={field.label} disabled={field.isReadOnly || disabled} helperText={field.description}/>
                                            <TimestampTooltip createdAt={field.createdAt} updatedAt={field.updatedAt} style={{ marginTop: '10px' }} />
                                        </div>)
                                })
                            ) : (<></>)}
                            {values && values.some(x => !x.isReadOnly) && (
                                <FormSubmitButton variant='Update' disabled={disabled} />
                            )}
                        </div>
                    )}
                />
            </Form>
        )}
    />);
}
