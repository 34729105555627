import ProductLicenseRepo from "./ProductLicenseRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {ProductLicense} from "../../models/ProductLicense";
import {sanitize} from "../../utils/FormUtils";

export default class HttpProductLicenseRepo extends HttpCrudRepo<ProductLicense> implements ProductLicenseRepo {
    pathComponents = ['mk', 'v1', 'product-licenses'];

    create(body: object): Promise<ProductLicense> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<ProductLicense>): Promise<ProductLicense> {
        return super.update(id, sanitize(resource));
    }
}
