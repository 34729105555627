import * as React from "react";
import {useState} from "react";
import { StateApplication } from "../../models/sid/StateApplication";
import { StateApplicationSection } from "../../models/sid/StateApplicationSection";
import {CreateStateApplicationSectionModal} from "../entities/state-id/settings/state-application/state-application-section/StateApplicationSectionForm";

export default function useCreateStateIdFieldTypeModal(stateApplication: StateApplication, onStateApplicationSectionCreated: (stateApplication: StateApplicationSection) => void) {

    const [open, setOpen] = useState(false);

    const handleStateApplicationSectionCreated = (stateApplication: StateApplicationSection) => {
        setOpen(false);
        onStateApplicationSectionCreated(stateApplication)
    };

    const modal = (
        <CreateStateApplicationSectionModal
            open={open}
            onClose={() => setOpen(false)}
            stateApplication={stateApplication}
            onStateApplicationSectionCreated={handleStateApplicationSectionCreated}
        />
    );

    return { setCreateStateApplicationSectionModalOpen: setOpen, createStateApplicationSectionModal: modal }
}
