import TableColumnFactory, {ColumnFactory} from "../../TableColumnFactory";
import {Sender} from "../../../../models/mail/Sender";

export class SenderTableColumnFactory implements ColumnFactory<Sender>{

    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<Sender>([
            TableColumnFactory.makeSenderIdColumn(),
            TableColumnFactory.fromProperty("Name", "name"),
            TableColumnFactory.fromProperty("Email", "email"),
        ]);
    }
}
