import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import { TimelineConnector, TimelineContent, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import { CompanyNote } from "../../../../models/Company";
import moment from "moment";
import { CompanyNoteMenuButton } from "./CompanyNoteMenu";
import CompanyTimelineNoteThreads from "./CompanyTimelineNoteThreads";
import { CompanyNoteDemeanorTimelineDot } from "./CompanyNoteDemeanor";

interface Props {
    note: CompanyNote,
    isAlternate: boolean,
    isLast: boolean,
    canDelete: boolean,
    canEdit: boolean,
    canAddThread: boolean,
    onCompanyNoteEdit: (note: CompanyNote) => void,
    onCompanyNoteDelete: (note: CompanyNote) => void,
    onCompanyNoteAddThread: (note: CompanyNote) => void
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-evenly',
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& p': {
            margin: theme.spacing(0.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    noteBody: {
        padding: '12px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function TimelineNoteItem({ note, isAlternate, isLast, canDelete, canEdit, canAddThread, onCompanyNoteEdit, onCompanyNoteDelete, onCompanyNoteAddThread }: Props) {
    const { body, demeanor, status, source, sourceLink, userDisplayName, createdBy, createdAt, callType, threads } = note;

    const classes = useStyles();

    return (
        <TimelineItem>
            <TimelineOppositeContent>
                <Typography variant="body1" color="textSecondary"><b>Created:</b> {createdBy} @ {moment(createdAt).format('MM/DD/YYYY, h:mm a')}</Typography>
                {status && (<Typography variant="body2" color="textSecondary"><b>Call Status:</b> {status}</Typography>)}
                {source && (<TimelineNoteSource source={source} sourceLink={sourceLink} />)}
                {callType && (<Typography variant="body2" color="textSecondary"><b>Call Type:</b> {callType}</Typography>)}
                {userDisplayName && (<Typography variant="body2" color="textSecondary"><b>User:</b> {userDisplayName}</Typography>)}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <CompanyNoteDemeanorTimelineDot companyNote={note} />
                {isLast ? <></> : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={3} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '6px 2px' }}>
                        <div style={{ width: '50px', order: isAlternate ? 1 : 2 }}>
                            <CompanyNoteMenuButton
                                companyNote={note}
                                onDeleteClicked={canDelete ? onCompanyNoteDelete : undefined}
                                onEditClicked={canEdit ? onCompanyNoteEdit : undefined}
                                onAddThreadClicked={canAddThread ? onCompanyNoteAddThread : undefined}
                            />
                        </div>
                        <div className={classes.noteBody} style={{ order: isAlternate ? 2 : 1 }}>
                            <Typography style={{ whiteSpace: 'pre-wrap' }}>{body}</Typography>
                        </div>
                    </div>
                    {threads && threads.length > 0 && (
                        <CompanyTimelineNoteThreads threads={threads} note={note} onThreadDelete={onCompanyNoteDelete} onThreadEdit={onCompanyNoteEdit} />
                    )}
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
}


function TimelineNoteSource(props: { source: string, sourceLink?: string }) {
    const { source, sourceLink } = props;

    if (sourceLink) {
        return (<Typography variant="body2" color="textSecondary"><b>Source:</b> <a href={sourceLink} target='_blank' rel="noopener noreferrer" title={sourceLink}>{source}</a></Typography>);
    }

    return (<Typography variant="body2" color="textSecondary"><b>Source:</b> {source}</Typography>);
}