import SiteTopicRepo from "./SiteTopicRepo";
import {HttpCrudRepo} from "../../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../../models";
import {ListQuery} from "../../CrudRepo";
import {CreateSiteTopicRequest, SiteTopic, UpdateSiteTopicRequest} from "../../../models/ti/SiteTopic";
import {getHttpQueryFromListQuery} from "../../../utils/QueryUtils";
import config from '../../../config';

export default class HttpSiteTopicRepo extends HttpCrudRepo<SiteTopic> implements SiteTopicRepo {
    pathComponents = ['ti', 'v1', 'site-topics'];

    list(query: ListQuery): Promise<CollectionResponse<SiteTopic>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "site,topic";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    listAll(): Promise<SiteTopic[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<SiteTopic>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }

    listAvailableTopicsForSiteById(siteId: string): Promise<SiteTopic[]> {
        return this.http.get([...this.pathComponents, siteId, 'topics'], {
            query: {
                status: 'available'
            }
        });
    }

    createTopicForSiteById(siteId: string, body: CreateSiteTopicRequest): Promise<SiteTopic> {
        return this.http.post([...this.pathComponents, siteId, 'topics'], body);
    }

    updateTopicForSiteById(siteId: string, body: UpdateSiteTopicRequest): Promise<SiteTopic> {
        return this.http.put([...this.pathComponents, siteId, 'topics'], body);
    }

    deleteTopicForSiteById(siteId: string, topicId: string): Promise<Response> {
        return this.http.delete([...this.pathComponents, siteId, 'topics', topicId]);
    }
}
