import React, { useEffect, useRef } from "react";
import { queryResultFromArray } from "../../../../../table/TableRepoAdapter";
import Table, { TableColumn, TableQuery } from "../../../../../table/Table";
import TableColumnFactory from "../../../../TableColumnFactory";
import { useRepo } from "../../../../../../repos/useRepo";
import { makeCreateAction, makeDeleteAction } from "../../../../../table/TableActionFactory";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../../../rbac";
import { StateApplicationField } from "../../../../../../models/sid/StateApplicationField";
import { StateApplicationSection } from "../../../../../../models/sid/StateApplicationSection";
import useCreateStateApplicationFieldModal from "../../../../../modals/useCreateStateApplicationFieldModal";
import useUpdateStateApplicationFieldModal from "../../../../../modals/useUpdateStateApplicationFieldModal";
import { useDeleteDialog } from "../../../../../dialog/ConfirmDialog";

interface Props {
    stateApplicationSection: StateApplicationSection
}

export default function StateApplicationFieldTable({ stateApplicationSection }: Props) {
    const { stateApplicationFieldRepo, stateApplicationSectionRepo } = useRepo();
    const { can } = useRbac();
    const canCreateStateApplicationField = can(actions.stateApplication.createField);
    const canUpdateStateApplicationField = can(actions.stateApplication.updateField);
    const canDeleteStateApplicationField = can(actions.stateApplication.deleteField);
    const tableRef = useRef<any>(null);

    const reloadData = () => tableRef.current.onQueryChange();

    const { setCreateStateApplicationFieldModalOpen, createStateApplicationFieldModal } = useCreateStateApplicationFieldModal(stateApplicationSection, reloadData);
    const { setStateApplicationFieldModal, updateStateApplicationFieldModal } = useUpdateStateApplicationFieldModal(reloadData);
    const { openDeleteDialog, confirmDialog } = useDeleteDialog('Delete State Application Field', 'Are you sure you want to delete this state application field?', (e: StateApplicationField) => deleteStateApplicationField(e))


    useEffect(reloadData, [stateApplicationSection.id]);

    const columns: TableColumn<StateApplicationField>[] = TableColumnFactory.withTimestampColumns([
        {...TableColumnFactory.fromProperty("Id", "id"), disableClick: true },
        TableColumnFactory.fromProperty("Label", "label"),
        TableColumnFactory.fromProperty("Field Type", "fieldType.name"),
        TableColumnFactory.fromProperty("Sort Order", "sortOrder"),
        TableColumnFactory.booleanColumn("Required", "required"),
    ]);

    const fetchData = async (tableQuery: TableQuery<StateApplicationField>) => {
        const res = await stateApplicationSectionRepo.listFields(stateApplicationSection.id);
        return queryResultFromArray(res)
    };

    const deleteStateApplicationField = (stateApplicationField: StateApplicationField) => {
        stateApplicationFieldRepo.delete(stateApplicationField.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onStateApplicationFieldSelected = (stateApplicationField: StateApplicationField) => {
        if (canUpdateStateApplicationField) {
            setStateApplicationFieldModal(stateApplicationField)
        }
    };

    return (
        <>
            <Table<StateApplicationField>
                tableRef={tableRef}
                title={`Fields for ${stateApplicationSection.name}`}
                columns={TableColumnFactory.disableSearchAndFiltering(columns)}
                actions={[
                    makeCreateAction(() => setCreateStateApplicationFieldModalOpen(true), { disabled: !canCreateStateApplicationField }),
                    makeDeleteAction(openDeleteDialog, { disabled: !canDeleteStateApplicationField })
                ]}
                options={{
                    search: false,
                    filtering: false,
                    paging: false,

                }}
                parentChildData={(row, rows) => rows.find(a => a.id === row.parentFieldId)}
                onRowClick={onStateApplicationFieldSelected}
                data={fetchData}
            />
            {createStateApplicationFieldModal}
            {updateStateApplicationFieldModal}
            {confirmDialog}
        </>
    );
}
