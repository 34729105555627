import moment from "moment";
import { allForYear } from '@18f/us-federal-holidays';

export default class DateUtils {

    static nowUTC(): Date {
        const now = moment();
        return now.utc().toDate();
    }

    static isToday(date: string | Date): boolean {
        const now = moment();
        return moment(date).isSame(now, 'day');
    }

    static isEarlierThanEndHour(timeValue: string, endLimit: string, lastValue: string | undefined) {
        const timeValueIsEarlier = moment(timeValue, 'hh:mmA').diff(moment(endLimit, 'hh:mmA')) < 0;
        const timeValueIsLaterThanLastValue = lastValue === undefined ? true : moment(lastValue, 'hh:mmA').diff(moment(timeValue,'hh:mmA')) < 0;
        return timeValueIsEarlier && timeValueIsLaterThanLastValue;
    }    

    static roundToMin(value: Date, interval: number, format: string) {
        const remainder = interval - (moment(value).minute() % interval);
        return moment(value).add(remainder, "minutes").format(format);        
    }

    static isAHoliday(date: Date, filters?: string[]){
        let holidays:string[] = [];
        const fedHolidays = allForYear(date.getFullYear(), { shiftSaturdayHolidays: true, shiftSundayHolidays: true });
        if (filters && filters.length > 0) {
            for (const filter of filters) {
                let found = fedHolidays.find(o => o.name === filter)
                if (found) {
                    holidays.push(found.dateString)
                }
            }
        }
        else 
            holidays = fedHolidays.map(h => h.dateString);

        return holidays.some(h => h == moment(date).format("YYYY-MM-DD"));
    }

    static isAWeekend(date: Date) {
        return date.getDay() === 0 || date.getDay() === 6;
    }

}