import * as React from "react";
import {Employee} from "../../../models/Employee";
import {DialogChildProps} from "../EntityDialog";
import EmployeeFields from "./EmployeeFields";
import {employeeSchema, initialValuesForEmployee} from "../../../utils/schemas/employee-schema";
import Form from "../../form/Form";

interface Props extends DialogChildProps {
  employee: Employee;
  onValuesChange?: (draft: Partial<Employee>) => void;
  isReadOnly: boolean
}

export default function Detail({ employee, isReadOnly, ...formProps }: Props) {
  return (
    <Form
      disabled={isReadOnly}
      initialValues={initialValuesForEmployee(employee)}
      validationSchema={employeeSchema}
      {...formProps}
    >
        <EmployeeFields disabled={isReadOnly}/>
    </Form>
  );
}
