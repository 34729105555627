import React, {useRef, useState} from 'react';
import {TableColumn} from '../../table/Table';
import {Paper} from '@material-ui/core';
import TableColumnFactory from '../TableColumnFactory';
import Detail from "./Detail";
import {Application} from "../../../models/Application";
import EntityList from "../EntityList";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import {EntityDialog} from "../EntityDialog";
import useCrudController from "../useCrudController";
import {useRepo} from "../../../repos/useRepo";
import {makeCreateAction} from "../../table/TableActionFactory";
import {useNavigation} from "../../../services/navigation/useNavigation";
import Routes from "../../../Routes";

export const requiredActions = [
  actions.application.list,
]

export default function List() {
    const { applicationRepo } = useRepo();
    const { can } = useRbac();

    const { navigateToApplication } = useNavigation();

    const permissions = {
        update: can(actions.application.update),
        delete: can(actions.application.delete),
        create: can(actions.application.create)
    };

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const columns: TableColumn<Application>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeApplicationIdColumn(),
        TableColumnFactory.fromProperty("Name", "name"),
        TableColumnFactory.fromProperty("Identifier", "identifier")
    ]);

    const [draft, setDraft] = useState<Partial<Application>>();

    const { _save, _delete, _list} = useCrudController(applicationRepo, reloadData);
    const [displayAddForm, setDisplayAddForm] = useState(false);

    function renderDetailDialog(onSave: () => Promise<void>,
                                onDelete: () => void,
                                onDismiss: () => void,
                                entity?: Application) {
        return (
            <EntityDialog
                permissions={permissions}
                onSave={onSave}
                onDelete={onDelete}
                onDismiss={onDismiss}
            >
                {(formProps) => (
                    <Detail
                        application={entity}
                        isReadOnly={!!entity && !permissions.update}
                        onValuesChange={setDraft}
                        {...formProps}
                    />
                )}
            </EntityDialog>
        )
    }

    const renderAddNewDialog = () => renderDetailDialog(
        _save(undefined, draft, () => setDisplayAddForm(false)),
        () => setDisplayAddForm(false),
        () => setDisplayAddForm(false),
    );

    return (
        <>
            <Paper>
                <EntityList
                    title='Applications'
                    columns={columns}
                    fetchData={_list}
                    tableRef={tableRef}
                    actions={[
                        makeCreateAction(() => setDisplayAddForm(true), { hidden: !permissions.create }),
                    ]}
                    renderDetail={(application, onComplete) => renderDetailDialog(
                        _save(application, draft, onComplete),
                        _delete(application, onComplete),
                        onComplete,
                        application
                    )}
                />
            </Paper>
            {displayAddForm && permissions.create && renderAddNewDialog()}
        </>
    );
}



