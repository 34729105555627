import * as React from "react";
import {Reseller} from "../../../../models/Reseller";
import {DialogChildProps} from "../../EntityDialog";
import {SalesChannel} from "../../../../models/SalesChannel";
import {
  createResellerSchema, initialValuesForResellerCreate,
  initialValuesForResellerUpdate,
  updateResellerSchema
} from "../../../../utils/schemas/reseller-schema";
import {CreateResellerFields, UpdateResellerFields} from "./ResellerFields";
import Form from "../../../form/Form";

interface Props extends DialogChildProps {
  salesChannels: SalesChannel[];
  reseller?: Reseller;
  onValuesChange?: (draft: Partial<Reseller>) => void;
  isReadOnly?: boolean
}

export default function Detail({ reseller, isReadOnly, ...formProps}: Props) {
  const Fields = reseller ? <UpdateResellerFields disabled={isReadOnly}/> : <CreateResellerFields disabled={isReadOnly}/>;

  return (
    <Form
      disabled={isReadOnly}
      validationSchema={reseller ? updateResellerSchema : createResellerSchema}
      initialValues={reseller ? initialValuesForResellerUpdate(reseller) : initialValuesForResellerCreate()}
      {...formProps}
    >
      {Fields}
    </Form>
  )
}
