import * as React from "react";
import {User} from "../../../../models/User";
import {useState} from "react";
import {Company} from "../../../../models/Company";
import {CreateUserForCompanyModal} from "./CreateCompanyUserForm";

export default function useCreateUserForCompanyModal(company: Company, onUserCreated: (user: User) => void) {

    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
        <CreateUserForCompanyModal
            title='Add a User'
            open={open}
            onClose={close}
            company={company}
            onUserCreated={(user) => {
                close();
                onUserCreated(user)
            }} />
    )

    return {
        createUserForCompanyModal: modal,
        openCreateUserForCompanyModal: () => setOpen(true)
    }
}