import * as React from "react";
import {useState} from "react";
import { Application } from "../../models/sid/Application";
import { ApplicationNote } from "../../models/sid/ApplicationNote";
import { UpdateStateIdApplicationNoteModal } from "../entities/state-id/applications/notes/ApplicationNoteForm";


export default function useUpdateStateIdApplicationNoteModal(application: Application, onApplicationNoteUpdated: (applicationNote: ApplicationNote) => void) {

    const [applicationNote, setApplicationNote] = useState<ApplicationNote>();

    const handleApplicationNoteCreated = (stateApplication: ApplicationNote) => {
        setApplicationNote(undefined);
        onApplicationNoteUpdated(stateApplication)
    };

    const modal = applicationNote ? (
        <UpdateStateIdApplicationNoteModal
            open={true}
            onClose={() => setApplicationNote(undefined)}
            companyId={application.member?.companyId ?? null}
            applicationNote={applicationNote}
            onApplicationNoteUpdated={handleApplicationNoteCreated}
        />
    ) : null;

    return { setApplicationNoteModal: setApplicationNote, updateStateIdApplicationNoteModal: modal }
}
