import * as React from "react";
import InvoiceLineItemTable from "./InvoiceLineItemTable";
import {Invoice} from "../../../../../models/Invoice";

interface Props {
    invoice: Invoice;
}

export default function InvoiceDetail({ invoice }: Props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <InvoiceLineItemTable invoice={invoice}/>
        </div>
    )
}
