import {CreatePlanRequest, Plan, UpdatePlanRequest} from "../../models/Plan";
import PlanRepo from "./PlanRepo";
import {ListQuery} from "../CrudRepo";
import {CollectionResponse} from "../../models";
import {sanitize} from "../../utils/FormUtils";
import HttpService from "@gsb/react-http";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';

export default class HttpPlanRepo implements PlanRepo {
    pathComponents = ['mk', 'v1', 'plans'];

    constructor(private http: HttpService) {}

    update(id: string, body: UpdatePlanRequest): Promise<Plan> {
        return this.http.put([...this.pathComponents, id], sanitize(body));
    }

    create(body: CreatePlanRequest): Promise<Plan> {
        return this.http.post(this.pathComponents, sanitize(body));
    }

    getById(id: string): Promise<Plan> {
        return this.http.get([...this.pathComponents, id]);
    }

    delete(id: string): Promise<void> {
        return this.http.delete([...this.pathComponents, id]);
    }

    list(query: ListQuery): Promise<CollectionResponse<Plan>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        return this.http.get(this.pathComponents, { query: httpQuery });
    }

    listAllPlans(): Promise<Array<Plan>> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });

        return this.http
            .get<CollectionResponse<Plan>>(this.pathComponents, { query: httpQuery })
            .then(res => res.results);
    }
}
