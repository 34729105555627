import React, { useEffect, useState, useMemo } from "react";
import { useRepo } from "../../../../../repos/useRepo";
import { ApplicationNote } from "../../../../../models/sid/ApplicationNote";
import useCreateApplicationNoteModal from "../../../../modals/useCreateStateIdApplicationNoteModal";
import useUpdateApplicationNoteModal from "../../../../modals/useUpdateStateIdApplicationNoteModal"
import { Timeline } from '@material-ui/lab';
import { makeStyles, IconButton } from "@material-ui/core";
import { AddBox } from "@material-ui/icons"
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../../rbac";
import ApplicationTimelineNote from "./ApplicationTimelineNote";
import { Application } from "../../../../../models/sid/Application";


interface Props {
    application: Application
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '12px',
        cursor: 'pointer'
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    timelineHeader: {
        display: "flex", 
        overflow: "hidden", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        padding: "0 20px" 
    },
    timelineHeaderTitle: {
        flex: 2, 
        textAlign: 'right',
        '& h2': {
            marginLeft: '12px'
        }
    },
    timelineContainer: {
        maxHeight: '800px', 
        overflowY: 'auto' 
    },
    footer: {
        textAlign: "right"
    }
}));

export default function ApplicationNotesTimeline({ application }: Props) {
    const { stateIdApplicationRepo } = useRepo();
    const [applicationNotes, setApplicationNotes] = useState<ApplicationNote[]>();

    const reloadData = () => {
        stateIdApplicationRepo.recentNotes(application.id).then(setApplicationNotes).catch(console.error);
    }

    const { createStateIdApplicationNoteModal, openCreateStateIdApplicationNoteModal } = useCreateApplicationNoteModal(application, reloadData);
    const { updateStateIdApplicationNoteModal, setApplicationNoteModal } = useUpdateApplicationNoteModal(application, reloadData);

    const { can } = useRbac();
    const canDeleteApplicationNote = can(actions.stateIdApplication.deleteNote);
    const canUpdateApplicationNote = can(actions.stateIdApplication.updateNote);
    const canCreateApplicationNote = can(actions.stateIdApplication.createNote);

    useEffect(reloadData, [application.id]);

    const classes = useStyles();
    const loading = useMemo(() => !applicationNotes, [applicationNotes]);

    const handleApplicationNoteDelete = async (note: ApplicationNote) => {
        const userRes = window.confirm('Are you sure you want to delete this item?');
        if (userRes) {
            stateIdApplicationRepo.deleteNote(application.id, note.id).then(reloadData).catch(console.error);
        }
        return;
    }

    return (
        <>
            <div>
                <div className={classes.timelineHeader}>
                    <div className={classes.timelineHeaderTitle}>
                        <h2>Recent Application Notes</h2>
                    </div>
                    <div>
                        {canCreateApplicationNote && (
                        <IconButton aria-label="add" onClick={() => openCreateStateIdApplicationNoteModal({open: true})}><AddBox fontSize="large" /></IconButton>
                        )}
                    </div>
                </div>
                <div>
                    {!loading ? (<Timeline align={"alternate"} >
                        {applicationNotes && applicationNotes.map((note, index) => (
                            <ApplicationTimelineNote key={index}
                                note={note}
                                isAlternate={!(index % 2 === 0)}
                                isLast={applicationNotes.length - 1 === index}
                                onCompanyNoteEdit={setApplicationNoteModal}
                                onCompanyNoteDelete={handleApplicationNoteDelete}
                                onCompanyNoteAddThread={(note) => openCreateStateIdApplicationNoteModal({open: true, threadId: note.id})}
                                canDelete={canDeleteApplicationNote}
                                canEdit={canUpdateApplicationNote}
                                canAddThread={canCreateApplicationNote} />
                        ))}
                    </Timeline>) : (<></>)}
                </div>
            </div>
            {createStateIdApplicationNoteModal}
            {updateStateIdApplicationNoteModal}
        </>
    );
}