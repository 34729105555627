import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {Trigger} from "../../../models/mail/Trigger";

export default function TriggerDropdown(props: FilterComponentProps<any>) {
    const { triggerRepo } = useRepo();
    const [triggers, setTriggers] = useState<Trigger[]>([]);

    useEffect(() => {
        triggerRepo.listAll().then(setTriggers).catch(console.error);
    }, [triggerRepo]);
    
    return (
        <DropdownPicker options={triggers} {...props}/>
    )
}