import {EmployeeRole} from "../../models/EmployeeRole";
import EmployeeRoleRepo from "./EmployeeRoleRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {ListQuery} from "../CrudRepo";
import {CollectionResponse} from "../../models";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";

export default class HttpEmployeeRoleRepo extends HttpCrudRepo<EmployeeRole> implements EmployeeRoleRepo {
    pathComponents = ['emp', 'v1', 'roles'];

    list(query: ListQuery): Promise<CollectionResponse<EmployeeRole>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "application";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }
}
