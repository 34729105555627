import {Divider, ExpansionPanelActions, ExpansionPanelDetails} from "@material-ui/core";
import React from "react";
import { StateApplicationSection } from "../../../../../../models/sid/StateApplicationSection";
import StateApplicationFieldTable from "../state-application-field/StateApplicationFieldTable";

export default function StateApplicationSectionPanel(props: { stateApplicationSection: StateApplicationSection, onSubscriptionCanceled: () => void }) {
    const { stateApplicationSection, onSubscriptionCanceled } = props;

    return (
        <>
            <StateApplicationFieldTable stateApplicationSection={stateApplicationSection} />
        </>
    )
}
