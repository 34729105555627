import * as Yup from 'yup';
import {CreateReferralForUserRequest, CreateUserRequest, UpdateUserRequest, User, UserReferral} from "../../models/User";
import config from "../../config";
import {Company, CreateUserForCompanyRequest} from "../../models/Company";
import theme from '../../theme';

const baseUserFields = {
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phoneNumber: Yup.string()
        .matches(config.regex.phoneNumber, {
            message: "Please enter a valid phone number",
            excludeEmptyString: true })
        .nullable(),
    timeZone: Yup.string().nullable(),
}

const emailField = Yup.string()
    .email('Please enter a valid email')
    .required('Email is required');

export const updateUserSchema = Yup.object().shape(baseUserFields);

export const createUserSchema = Yup.object().shape({
    ...baseUserFields,
    email: emailField,
    resellerId: Yup.string().required("Reseller is required"),
    companyName: Yup.string().required("Company name is required"),
    legalShieldMemberId: Yup.string().when('resellerId', {
        is: config.resellers.legalShield.id,
        then: Yup.string().required('Legal Shield Member ID is required when the reseller is Legal Shield')
    }),
    adpClientId: Yup.string().nullable(),
    adpOrganizationId: Yup.string().nullable(),
    adpAssociateId: Yup.string().nullable(),
});

export const createUserForCompanySchema = Yup.object().shape({
    ...baseUserFields,
    email: emailField
});

const baseValues = (user?: User) => ({
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    timeZone: user?.timeZone ?? ""
})

export const initialValuesForUserUpdate = (user: User): UpdateUserRequest => ({
    ...baseValues(user),
    legalShieldMemberId: user.legalShieldMemberId ?? "",
    adpAssociateId: user.adpAssociateId ?? "",
});

export const initialValuesForCreateUserForCompanyRequest = (): CreateUserForCompanyRequest => ({
    ...baseValues(),
    email: "",
});

export const initialValuesForUserCreate = (): CreateUserRequest => ({
    ...baseValues(),
    resellerId: "",
    companyName: "",
    email: "",
    legalShieldMemberId: "",
    adpClientId: "",
    adpOrganizationId: "",
    adpAssociateId: "",
});

export const createUserReferralSchema = Yup.object().shape({
    userId: Yup.string().required("User is required"),
    client_name: Yup.string().required("Client name is required"),
    business_name: Yup.string().required("Business name is required"),
    email_address: emailField,
    phone_number: Yup.string().required("Phone number is required"),
    schedule_date: Yup.string().when('schedule_type', {
        is: '0',
        then: Yup.string().required("Scheduled Date is required")
    }),
    schedule_time: Yup.string().when('schedule_type', {
        is: '0',
        then: Yup.string().required("Scheduled Time is required")
    })
});

export const initialValuesForCreateUserReferral = (company: Company): UserReferral => ({
    userId: "",
    client_name: "",
    business_name: company?.name || "",
    phone_number: "",
    email_address: "",
    schedule_type: "1", // NOW
    schedule_time: "",
    timeZone: company?.timeZone || "",
    language: "en"
})