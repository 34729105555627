import * as Yup from 'yup';
import {CreateProductRequest, Product, UpdateProductRequest} from "../../models/Product";

const productSchema = Yup.object().shape({
    name: Yup.string()
        .min(1, "Name cannot be empty")
        .max(255, "Must be less than 255 characters")
        .required('Name is required'),
    identifier: Yup.string().required("Identifier is required")
});

export const createProductSchema = productSchema;

export const updateProductSchema = productSchema;

export const initialValuesForProductUpdate = (product: Product): UpdateProductRequest => ({
    name: product.name,
    identifier: product.identifier,
});

export const initialValuesForProductCreate = (): CreateProductRequest => ({
    name: '',
    identifier: ''
});