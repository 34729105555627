import React from "react";

interface Props {

}

export default function NoUsersAvailable(props: Props) {
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 8 }}>
            <div style={{ width: '100%', marginBottom: 18 }}>
                You currently have no users to which you can assign this license.
                This generally means that every user for this company already has access
                to this application.
            </div>
        </div>
    )
}

