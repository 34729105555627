import { SendGridAccount } from "../../models/SendGridAccount";
import SendGridAccountRepo from "./SendGridAccountRepo";
import { HttpCrudRepo } from "../HttpCrudRepo";
import { arrayFromCollection, CollectionResponse } from "../../models";
import { ListQuery } from "../CrudRepo";
import { sanitize } from "../../utils/FormUtils";
import { getHttpQueryFromListQuery } from "../../utils/QueryUtils";
import config from '../../config';

export default class HttpSendGridAccountRepo extends HttpCrudRepo<SendGridAccount> implements SendGridAccountRepo {
    pathComponents = ['mk', 'v1', 'sendgrid-accounts'];

    list(query: ListQuery): Promise<CollectionResponse<SendGridAccount>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "salesChannel,reseller";
        return this.http.get(this.pathComponents, { query: httpQuery });
    }

    create(body: object): Promise<SendGridAccount> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<SendGridAccount>): Promise<SendGridAccount> {
        return this.http.put([...this.pathComponents, id], sanitize(resource))
    }

    listAll(): Promise<SendGridAccount[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<SendGridAccount>>(this.pathComponents, { query: httpQuery })
            .then(arrayFromCollection);
    }
}
