import React, {useRef, useState} from 'react';
import {EmployeeRole} from "../../../../models/EmployeeRole";
import EntityList from "../../EntityList";
import {TableColumn} from "../../../table/Table";
import TableColumnFactory from "../../TableColumnFactory";
import {EntityDialog} from "../../EntityDialog";
import {makeCreateAction} from "../../../table/TableActionFactory";
import useCrudController from "../../useCrudController";
import {useRbac} from "@gsb/react-rbac";
import {Paper} from "@material-ui/core";
import Detail from "./Detail";
import {useRepo} from "../../../../repos/useRepo";
import {actions} from "../../../../rbac";
import {useNavigation} from "../../../../services/navigation/useNavigation";

export default function List() {
    const { employeeRoleRepo } = useRepo();
    const { can } = useRbac();

    const permissions = {
        update: can(actions.employeeRole.update),
        delete: can(actions.employeeRole.delete),
        create: can(actions.employeeRole.create)
    };

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const columns: TableColumn<EmployeeRole>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeEmployeeRoleIdColumn(),
        TableColumnFactory.fromProperty("Name", "name"),
        TableColumnFactory.fromProperty("Identifier", "identifier"),
        TableColumnFactory.fromProperty("Default", "isDefault"),
    ]);

    const [draft, setDraft] = useState<Partial<EmployeeRole>>();

    const {_save, _delete, _list} = useCrudController(employeeRoleRepo, reloadData);
    const [displayAddForm, setDisplayAddForm] = useState(false);

    function renderDetailDialog(onSave: () => Promise<void>,
                                onDelete: () => void,
                                onDismiss: () => void,
                                entity?: EmployeeRole) {
        return (
            <EntityDialog
                permissions={permissions}
                onSave={onSave}
                onDelete={onDelete}
                onDismiss={onDismiss}
            >
                {(formProps) => (
                    <Detail
                        role={entity}
                        isReadOnly={!!entity && !permissions.update}
                        onValuesChange={setDraft}
                        {...formProps}
                    />
                )}
            </EntityDialog>
        )
    }

    const renderAddNewDialog = () => renderDetailDialog(
        _save(undefined, draft, () => setDisplayAddForm(false)),
        () => setDisplayAddForm(false),
        () => setDisplayAddForm(false),
    );

    return (
        <div>
            <Paper>
                <EntityList
                    title='Employee Roles'
                    columns={columns}
                    fetchData={_list}
                    tableRef={tableRef}
                    actions={[
                        makeCreateAction(() => setDisplayAddForm(true), { hidden: !permissions.create }),
                    ]}
                    renderDetail={(role, onComplete) => renderDetailDialog(
                        _save(role, draft, onComplete),
                        _delete(role, onComplete),
                        onComplete,
                        role
                    )}
                />
            </Paper>
            {displayAddForm && permissions.create && renderAddNewDialog()}
        </div>
    );
}



