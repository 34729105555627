import * as React from "react";
import {WebsiteAnalysis} from "../../../../models/WebsiteAnalysis";
import {useState} from "react";
import {Company} from "../../../../models/Company";
import {CreateWebsiteAnalysisForCompanyModal} from "./CreateCompanyWebsiteAnalysisForm";

export default function useCreateWebsiteAnalysisForCompanyModal(company: Company, onWebsiteAnalysisCreated: (websiteAnalysis: WebsiteAnalysis) => void) {

    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
        <CreateWebsiteAnalysisForCompanyModal
            title='Create Website Analysis Report'
            open={open}
            onClose={close}
            company={company}
            onWebsiteAnalysisCreated={(analysis) => {
                close();
                onWebsiteAnalysisCreated(analysis)
            }} />
    )

    return {
        createWebsiteAnalysisForCompanyModal: modal,
        openCreateWebsiteAnalysisForCompanyModal: () => setOpen(true)
    }
}