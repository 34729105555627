import TableColumnFactory from '../TableColumnFactory';
import {SubscriptionFileRecord} from '../../../models/SubscriptionFileRecord';
import React, {useRef, forwardRef, useImperativeHandle} from 'react';
import Table, {TableColumn} from '../../table/Table';
import {getTableDataFetcherFromRepoFetcher} from '../../table/TableRepoAdapter';
import {useRepo} from '../../../repos/useRepo';
import useUpdateSubscriptionFileRecordModal
    from "./update-subscription-file-record/useUpdateSubscriptionFileRecordModal";

const SubscriptionFileRecordList = (props: {}, ref: any) => {
  const { subscriptionRepo } = useRepo();

  const tableRef = useRef<any>(null);
  const reload = () => tableRef.current.onQueryChange()

  useImperativeHandle(ref, () => ({ reload }));

  const columns: TableColumn<SubscriptionFileRecord>[] = TableColumnFactory.withTimestampColumns([
      TableColumnFactory.makeSubscriptionFileRecordIdColumn(),
      {
          title: 'Upload',
          field: 'uploadId',
      },
      TableColumnFactory.booleanColumn('Cancellation', 'isCancellation'),
      TableColumnFactory.makePlanColumn('Plan', 'planId', r => r.plan),
      TableColumnFactory.makeSubscriptionRecordStatusColumn('Status', 'status', r => r.status),
  ]);

    const { updateSubscriptionFileRecordModal, openUpdateSubscriptionFileRecordModal } = useUpdateSubscriptionFileRecordModal(reload)

    return (
        <>
            <Table<SubscriptionFileRecord>
                tableRef={tableRef}
                title='Subscription File Records'
                columns={columns}
                actions={[]}
                onRowClick={openUpdateSubscriptionFileRecordModal}
                data={getTableDataFetcherFromRepoFetcher(subscriptionRepo.listSubscriptionFileRecords.bind(subscriptionRepo))}
            />
            {updateSubscriptionFileRecordModal}
        </>
  );
}

export default forwardRef(SubscriptionFileRecordList);