import React, {useEffect, useState} from "react";
import TopicMultiselect from "../../form/old/TopicMultiselect";
import TextEditor from "../../form/old/TextEditor";
import {Faq} from "../../../models/ti/Faq";
import QuestionStatusBadge from "../questions/QuestionStatusBadge";
import FaqActionBar from "./FaqActionBar";
import TextInput from "../../form/old/TextInput";
import config from "../../../config";
import { Prompt } from "react-router-dom";
import {useRepo} from "../../../repos/useRepo";
import Title from "../questions/Title";
import Subtitle from "../questions/Subtitle";

interface Props {
    faq: Faq;
    onFaqChanged: (faq: Faq) => void;
    onDelete: () => void;
}

export default function EditFaqForm(props: Props) {
    const { faq, onFaqChanged } = props;

    const { faqRepo } = useRepo();

    const [isDirty, setDirty] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [title, setTitle] = useState<string>(faq.title || '');
    const [question, setQuestion] = useState<string>(faq.question || '');
    const [answer, setAnswer] = useState<string>(faq.answer || '');
    const [notes, setNotes] = useState<string>(faq.notes || '');
    const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>();

    useEffect(() => {
        if (faq) {
            const selectedTopics = faq.topics || [];
            const ids = selectedTopics.map(topic => topic.id);
            setSelectedTopicIds(ids)
        }
    }, [faq]);

    function onTitleChanged(title: string) {
        setDirty(true);
        setTitle(title)
    }

    function onQuestionChanged(question: string) {
        setDirty(true);
        setQuestion(question)
    }

    function onAnswerChanged(answer: string) {
        setDirty(true);
        setAnswer(answer)
    }

    function onNotesChanged(notes: string) {
        setDirty(true);
        setNotes(notes)
    }

    function onSelectedTopicsChanged(selectedTopics: string[]) {
        setDirty(true);
        setSelectedTopicIds(selectedTopics)
    }

    async function onSaveFaq() {
        try {
            setLoading(true);

            const updatedFaq = await faqRepo.updateFaqWithId(faq.id, {
                title: title,
                question: question,
                answer: answer,
                notes: notes,
                topics: selectedTopicIds
            });

            setDirty(false);
            onFaqChanged(updatedFaq)
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    async function onReleaseFaq() {
        try {
            setLoading(true);
            const updatedFaq = await faqRepo.releaseFaqWithId(faq.id);
            setDirty(false);
            onFaqChanged(updatedFaq)
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    async function onDeleteFaq() {
        try {
            setLoading(true);
            await faqRepo.deleteFaqById(faq.id);
            setDirty(false);
            props.onDelete();
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>

            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <div style={{ display: "flex", alignItems: "center" }}>

                    <Title title="FAQ"/>
                    {faq.status &&
                    <QuestionStatusBadge status={faq.status.identifier}/>
                    }

                </div>

                <FaqActionBar faq={faq}
                              isLoading={isLoading}
                              isDirty={isDirty}
                              onReleaseClicked={onReleaseFaq}
                              onSaveClicked={onSaveFaq}
                              onDeleteClicked={onDeleteFaq}
                />
                              
            </div>


            <TextInput title="Title" value={title} onValueChanged={onTitleChanged}/>

            <TextInput style={{ marginBottom: "10px" }} title="Question" value={question} onValueChanged={onQuestionChanged}/>


            <div style={{ marginTop: "12px", marginBottom: "12px", width: "100%" }}>
            </div>

            <TopicMultiselect selectedTopics={selectedTopicIds || []}
                              onSelectionChanged={onSelectedTopicsChanged}/>


            <Subtitle title="Answer"/>
            <TextEditor value={answer} onValueChanged={onAnswerChanged}/>

            <Subtitle title="Notes"/>
            <TextEditor value={notes} onValueChanged={onNotesChanged}/>

            <Prompt when={isDirty} message={config.goBackPromptText}/>

        </div>
    );
}

