import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useEffect, useMemo, useState} from "react";
import {useRbac} from "@gsb/react-rbac";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";
import {Paper} from "@material-ui/core";
import {useRepo} from "../../../repos/useRepo";
import {Application} from "../../../models/Application";
import {actions} from "../../../rbac";
import LoadingIndicator from "../../LoadingIndicator";
import {UpdateApplicationForm} from "./ApplicationForm";
import {
    ApplicationDetailBreadcrumbFactory,
    BreadcrumbFactory,
} from "../../../factories/BreadcrumbFactory";
import {Breadcrumbs} from "../../layout/Breadcrumbs";
import {useNavigation} from "../../../services/navigation/useNavigation";

interface Params {
    applicationId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
  actions.application.get,
]

function ApplicationDetail(props: Props) {
    const [application, setApplication] = useState<Application>();
    const { applicationRepo } = useRepo();

    const { navigateToApplications } = useNavigation();
    const { can } = useRbac();
    const canListApplications = can(actions.application.list);
    const shouldShowList = canListApplications || canListApplications;

    const applicationId = useMemo(() => props.match.params.applicationId, [props.match.params.applicationId]);

    const loadApplication = () => {
        applicationRepo.getById(applicationId).then(setApplication).catch(console.error);
    };

    useEffect(loadApplication, [applicationRepo, applicationId]);

    if (application) {

        const items: TabNavigationItem[] = [
            {
                name: 'Application',
                identifier: 'application',
                render: () => <UpdateApplicationForm application={application} onApplicationUpdated={loadApplication}/>
            },
        ];

        const breadcrumbFactory: BreadcrumbFactory = new ApplicationDetailBreadcrumbFactory(application, navigateToApplications);

        return (
            <>
                <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

                {shouldShowList &&
                <Paper style={{ padding: 16 }}>
                  <TabNavigation items={items}/>
                </Paper>
                }
            </>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export default withRouter(ApplicationDetail);