import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useRepo } from "../../../repos/useRepo";
import LoadingIndicator from "../../LoadingIndicator";
import { Paper } from "@material-ui/core";
import { BreadcrumbFactory, QuestionDetailBreadcrumbFactory } from "../../../factories/BreadcrumbFactory";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { useNavigation } from "../../../services/navigation/useNavigation";
import EditQuestion from "./EditQuestion";
import { Question } from "../../../models/ti/Question";
import { withTarkentonInstituteTheme } from "../theme";
import ViewQuestionModal from "../modals/ViewQuestionModal";
import { actions } from "../../../rbac";
import { useRbac } from "@gsb/react-rbac";
import ViewQuestion from "./ViewQuestion";
import {useAuthState} from "../../../services/auth/useAuthState";

interface Params {
    questionId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
    actions.question.get,
]

function QuestionDetail(props: Props) {
    const [question, setQuestion] = useState<Question>();
    const { questionRepo } = useRepo();
    const { navigateToQuestions, navigateToQuestion } = useNavigation();
    const { can } = useRbac();
    const { currentEmployee } = useAuthState();

    const { questionId } = props.match.params;

    const loadQuestion = () => {
        questionRepo.getQuestionById(questionId).then(setQuestion).catch(console.error);
    };

    useEffect(loadQuestion, [questionRepo, questionId]);

    const [selectedQuestion, setSelectedQuestion] = useState<Question>();
    const [isViewQuestionModalOpen, setViewQuestionModalOpen] = useState<boolean>(false);

    const openViewQuestionModal = (question: Question) => {
        setSelectedQuestion(question);
        setViewQuestionModalOpen(true)
    };

    function closeViewQuestionModal() {
        setViewQuestionModalOpen(false);
        setSelectedQuestion(undefined);
    }

    const onEditQuestion = (question: Question) => {
        closeViewQuestionModal();
        navigateToQuestion(question);
    };

    if (question) {
        
        const canEditQuestion = can(actions.question.assignAnswerer) || question.assignedAnswererId == currentEmployee?.id || question.assignedReviewerId == currentEmployee?.id;
        const breadcrumbFactory: BreadcrumbFactory = new QuestionDetailBreadcrumbFactory(question, navigateToQuestions);
        const modalTitle = (body: string, title?: string) => {
            if(title && title.length > 0) return title;
            if(body.length < 75) return body;
            return body.substring(0, 75) + '...'
        }

        return (
            <>
                <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()} />

                {canEditQuestion ?
                    <Paper style={{ padding: 24 }}>
                        <EditQuestion question={question}
                            onQuestionUpdated={loadQuestion}
                            onQuestionDeleted={navigateToQuestions}
                            onViewQuestion={openViewQuestionModal} />
                    </Paper> :
                    <ViewQuestion question={question} />
                }


                {isViewQuestionModalOpen && selectedQuestion &&
                    <ViewQuestionModal open={isViewQuestionModalOpen}
                        onClose={closeViewQuestionModal}
                        question={selectedQuestion}
                        onEditQuestion={onEditQuestion}
                        title={modalTitle(selectedQuestion.body, selectedQuestion.title)}
                        paperStyle={{ width: '100%' }} />
                }
            </>
        );
    } else {
        return <LoadingIndicator />
    }
}

export default withTarkentonInstituteTheme(withRouter(QuestionDetail));