import React, {useEffect, useState} from "react";
import InputLabel from "@material-ui/core/InputLabel";
import {default as ReactSelect} from "react-select";
import {ValueType} from "react-select/src/types";
import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Consultant} from "../../../models/ti/Consultant";
import {useRepo} from "../../../repos/useRepo";

const styles = (theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginBottom: 14,
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.grey[300]
    },
    title: {
        fontWeight: "bolder", color: "gray"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    }
});

const useStyles = makeStyles(styles);

interface Props {
    title?: string;
    consultant?: Consultant | string;
    onConsultantSelected?: (consultant: Consultant) => void;
    disabled?: boolean;
}

type Value = { label: string; value: string };

export default function ConsultantPicker({ title, consultant, onConsultantSelected, disabled }: Props) {
    const { consultantRepo } = useRepo();

    const [assignee, setAssignee] = useState<Value>();
    const [assigneeOptions, setAssigneeOptions] = useState(Array<Consultant>());

    const labelForConsultant = (consultant: Consultant) => {
        if (consultant.name) {
            return consultant.name
        } else {
            return `${consultant.firstName} ${consultant.lastName}`;
        }
    };

    useEffect(() => {
        setAssigneeFromConsultant(consultant);
    }, [consultant]);

    useEffect(() => {
        consultantRepo.listAllConsultants()
            .then(setAssigneeOptions)
            .catch(err => console.error(err))
    }, [consultantRepo]);

    const classes = useStyles();
    const options: Value[] = assigneeOptions.map(c => { return { label: c.name, value: c.id } });

    function onValueChanged(value: Value) {
        setAssignee(value);

        if (assigneeOptions && onConsultantSelected) {
            const consultant = assigneeOptions.find(c => c.id === value.value);

            if (consultant) {
                onConsultantSelected(consultant)
            }
        }
    }

    return (
        <div className={classes.container}
             key={title}>
            <InputLabel className={classes.title} htmlFor="assignee">{title}</InputLabel>
            <ReactSelect
                classes={classes.textField}
                styles={{
                    // ...styles,
                    control: () => ({
                        borderTopWidth: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        fontSize: "1rem",
                    }),
                    valueContainer: () => ({padding: 0})
                }}
                inputId="assignee"
                TextFieldProps={{
                    label: 'Assignee',
                    className: "MuiInputBase-input MuiInput-input MuiInputBase-inputSelect",
                    InputLabelProps: {
                        htmlFor: 'assignee',
                        shrink: true,
                    },
                }}
                placeholder="Unassigned"
                options={options}
                components={{DropdownIndicator: null}}
                value={assignee}
                isDisabled={disabled}
                onChange={(v: ValueType<Value, false>) => onValueChanged(v as Value)}
            />
        </div>
    );

    function setAssigneeFromConsultant(consultant?: Consultant | string) {
        if (consultant) {
            if (typeof consultant === 'string') {
                const foundConsultant = assigneeOptions.find(c => c.id === consultant);

                if (foundConsultant) {
                    setAssignee({ label: labelForConsultant(foundConsultant), value: foundConsultant.id })
                } else {
                    setAssignee({ label: "Loading...", value: consultant })
                }
            } else {
                setAssignee({ label: labelForConsultant(consultant), value: consultant.id })
            }
        } else {
            const unassigned = { label: "Unassigned", value: "" };
            setAssignee(unassigned)
        }
    }
}
