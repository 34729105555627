import TableColumnFactory, {ColumnFactory} from "../../../TableColumnFactory";
import {StateApplication} from "../../../../../models/sid/StateApplication";

export class StateApplicationTableColumnFactory implements ColumnFactory<StateApplication>{
    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<StateApplication>([
            TableColumnFactory.makeStateApplicationIdColumn(),
            TableColumnFactory.fromProperty("Name", "name"),
            TableColumnFactory.fromProperty("State", "stateCode"),
            TableColumnFactory.booleanColumn("Is Live", "isLive")
        ]);
    }
}
