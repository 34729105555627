import * as React from "react";
import {SalesChannelPicker} from "../../../form/pickers/SalesChannelPicker";
import {ApplicationPicker} from "../../../form/pickers/ApplicationPicker";
import UrlTargetPicker from "../../../form/pickers/UrlTargetPicker";
import UrlTypePicker from "../../../form/pickers/UrlTypePicker";
import {FormGroup} from "@material-ui/core";
import TextInput from "../../../form/inputs/TextInput";
import CheckboxInput from "../../../form/inputs/CheckboxInput";

interface Props {
    disabled?: boolean;
}

export function UpdateSalesChannelApplicationFields({ disabled }: Props) {
    return (
        <>
            <TextInput name={"alias"} label="Alias" disabled={disabled}/>

            <TextInput name={"url"} label="URL" disabled={disabled}/>

            <UrlTypePicker name={'urlType'} disabled={disabled}/>

            <UrlTargetPicker name={'urlTarget'} disabled={disabled} allowEmpty emptyValueDescription='None' emptyValue=''/>

          <TextInput name={"impersonationUrl"} label="Impersonation URL" disabled={disabled}/>

          <FormGroup row style={{ width: '100%' }}>
                <CheckboxInput name='isSslRequired' label='SSL Required' disabled={disabled}/>
            </FormGroup>
        </>
    )
}

export function CreateSalesChannelApplicationFields({ disabled }: Props) {
    return (
        <>
            <SalesChannelPicker name='salesChannelId' disabled={disabled}/>

            <ApplicationPicker name='applicationId' disabled={disabled}/>

            <UpdateSalesChannelApplicationFields disabled={disabled}/>
        </>
    )
}

export function CreateSalesChannelApplicationForSalesChannelFields({ disabled }: Props) {
    return (
        <>
            <ApplicationPicker name='applicationId' label='Application' disabled={disabled}/>

            <UpdateSalesChannelApplicationFields disabled={disabled}/>
        </>
    )
}
