import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {Topic} from "../../../models/ti/Topic";
import FilterPicker from "./FilterPicker";

export default function TopicPicker(props: { value?: Topic, onTopicSelected: (topic?: Topic) => void }) {
    const { topicRepo } = useRepo();
    const [topics, setTopics] = useState<Topic[]>([]);

    useEffect(() => {
        topicRepo.listAll().then(setTopics);
    }, [topicRepo]);

    const onChange = (topic: Topic | null) => {
        props.onTopicSelected(topic || undefined)
    };

    return (
        <FilterPicker
            style={{ width: 400 }}
            value={props.value || null}
            options={topics}
            onChange={onChange}
            variant='outlined'
            placeHolderText={"Select a topic..."}
        />
    )
}