import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {EmployeeCompany} from "../../../models/EmployeeCompany";

export default function EmployeeCompanyDropdown(props: FilterComponentProps<any>) {
    const { employeeCompanyRepo } = useRepo();
    const [employeeCompanies, setEmployeeCompanies] = useState<EmployeeCompany[]>([]);

    useEffect(() => {
        employeeCompanyRepo.listAll().then(setEmployeeCompanies).catch(console.error);
    }, [employeeCompanyRepo]);
    
    return (
        <DropdownPicker options={employeeCompanies} {...props}/>
    )
}