import * as React from "react";
import TextField from "@material-ui/core/TextField";
import {textInputStyle} from "../styles/field";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export default function SearchBar({ value, onChange }: Props) {
  const title = 'Search';

  return (
      <TextField
          label={title}
          placeholder={title}
          variant='outlined'
          style={textInputStyle}
          value={value}
          onChange={(event) => onChange(event.target.value as string)}/>
  );
};
