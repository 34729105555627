import React from "react";
import HtmlPreview from "../HtmlPreview";
import {Faq} from "../../../models/ti/Faq";

interface Props {
    faq: Faq;
}

export default function FaqPreview({ faq }: Props) {
    const text = faq.title || faq.question || 'No title provided';
    return (
        <HtmlPreview rawHtml={text}/>
    )
}
