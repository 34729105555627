import {AuthConfig} from "@gsb/react-auth";
import {environment} from "../env";

import questionStatuses from './question-statuses';
import resellers from "./resellers";
import salesChannels from "./sales-channels";
import adp from "./adp";

interface UrlConfig {
    stripeDashboardBaseUrl: string;
    azureBaseUrl: string;
    silktideBaseUrl: string;
}

interface Config {
    goBackPromptText: string;
    auth: AuthConfig;
    url: UrlConfig;
    questionStatuses: typeof questionStatuses;
    maxDropdownSize: number;
    resellers: typeof resellers;
    salesChannels: typeof salesChannels;
    adp: typeof adp;
    regex: {
        phoneNumber: RegExp,
        adpClientId: RegExp,
    },
    externalApplicationServices: Array<string>,
    legalClub: {
        openHour: number,
        closeHour: number,
        timeZone: string,
        holidays: string[]
    }
}

const config: Config = {
    goBackPromptText: 'You have unsaved changes. Are you sure you want to leave?',
    auth: {
        clientId: environment.clientID,
        authorityUrl: environment.authorityUrl,
        redirectUri: environment.redirectUri,
        postLogoutRedirectUri: environment.postLogoutRedirectUri,
        scopes: ["openid", "profile", "email"]
    },
    url: {
        stripeDashboardBaseUrl: environment.stripeDashboardBaseUrl,
        azureBaseUrl: 'https://portal.azure.com/#@gsbdirectory.onmicrosoft.com',
        silktideBaseUrl: 'https://app.prospect.silktide.com'
    },
    regex: {
        phoneNumber: /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
        adpClientId: /^[2|9][\d]{7}$/ // Starts with a 2 or 9 and exactly 8 digits (2/9 + 7 digits)
    },
    questionStatuses,
    adp,
    resellers,
    salesChannels,
    maxDropdownSize: 1000,
    externalApplicationServices: ['legal-club','website-builder','upnetic-website-builder', 'state-id'],
    legalClub: {
        openHour: 9,    // 9:00 am
        closeHour: 19,  // 7:00 pm
        timeZone: 'America/New_York',
        holidays: ['New Year\'s Day', 'Memorial Day', 'Independence Day', 'Labor Day', 'Thanksgiving Day', 'Christmas Day']
    }
};

export default config
