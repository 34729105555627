import {TextField} from "@material-ui/core";
import React from "react";

export interface ReadOnlyProp {
  isReadOnly?: boolean;
}

export default function TextInput(props: ReadOnlyProp & { title: string, value?: string, onValueChanged?: (value: string) => void, rows?: number; style?: React.CSSProperties }) {

    const { title, value, onValueChanged, isReadOnly, rows, style } = props;

    return (
        <TextField
            style={style}
            label={title}
            placeholder={title}
            multiline
            rows={rows}
            margin="normal"
            variant="outlined"
            value={value}
            onChange={(e) => {
                if (onValueChanged) {
                    onValueChanged(e.target.value as string)
                }
            }}
            InputProps={{
                readOnly: isReadOnly || false
            }}/>
    )
}
