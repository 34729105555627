import React from "react";
import {Company, LinkStripeCustomerForCompanyRequest} from "../../../../models/Company";
import {useRepo} from "../../../../repos/useRepo";
import {StripeCustomer} from "../../../../models/stripe/StripeCustomer";
import {withModal} from "../../../modal";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import { createSubmitHandler } from "../../../form/helpers/formSubmit";
import {Form, Formik} from "formik";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import StripeAccountPicker from "../../../form/pickers/StripeAccountPicker";
import TextInput from "../../../form/inputs/TextInput";
import {
    initialValuesForLinkStripeCustomerForCompanyRequest,
    linkStripeCustomerForCompanySchema
} from "../../../../utils/schemas/link-company-to-existing-stripe-customer-schema";
import {verticalFormLayout} from "../../../form/styles/layout";

interface Props {
    company: Company;
    onStripeCustomerLinked: (stripeCustomer: StripeCustomer) => void
}

export function LinkCompanyToExistingStripeCustomer({ company, onStripeCustomerLinked }: Props) {
    const { companyRepo } = useRepo();

    const { can } = useRbac();
    const canCreate = can(actions.company.linkStripeCustomer);
    const disabled = !canCreate;

    const onSubmit = createSubmitHandler(
        (body: LinkStripeCustomerForCompanyRequest) => companyRepo.linkStripeCustomerForCompanyById(company.id, body),
        onStripeCustomerLinked,
        console.error
    );

    return (
        <Formik initialValues={initialValuesForLinkStripeCustomerForCompanyRequest()}
                validationSchema={linkStripeCustomerForCompanySchema}
                onSubmit={onSubmit} >
            <Form style={verticalFormLayout}>
                <StripeAccountPicker name='stripeAccountIdentifier'/>

                <TextInput name='stripeCustomerId' label='Stripe Customer' helperText='From the customer page in Stripe (i.e. cus_abcd1234)'/>

                <FormSubmitButton style={{ alignSelf: 'flex-end' }} variant='Create' label='Link' disabled={disabled}/>
            </Form>
        </Formik>
    )
}

export const LinkCompanyToExistingStripeCustomerModal = withModal(LinkCompanyToExistingStripeCustomer);
