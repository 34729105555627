export interface TableRowOptionProvider {
    getRowPerPageOptions(): number[];
    getCurrentRowsPerPage(): number;
    setCurrentRowsPerPage(rowsPerPage: number): void;
}

export class LocalStorageTableRowOptionProvider implements TableRowOptionProvider {

    private kRowsPerPage = "com.gsb.mk.table.rowsPerPage";

    constructor(private options: number[]) {
        console.assert(options.length > 0, "options length must be greater than 0")
    }

    getRowPerPageOptions = (): number[] => {
        return this.options;
    }

    getCurrentRowsPerPage = (): number => {
        const value = localStorage.getItem(this.kRowsPerPage);

        if (value) {
            return parseInt(value);
        } else {
            return this.options[0] || 10;
        }
    }

    setCurrentRowsPerPage = (rowsPerPage: number): void => {
        localStorage.setItem(this.kRowsPerPage, rowsPerPage.toString());
    }
}