import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export default function BusinessSizePicker({ ...PickerProps }: Props) {
    const { businessSizeRepo } = useRepo();

    const [businessSizes, setBusinessSizes] = useState<string[]>([]);

    useEffect(() => {
        businessSizeRepo.list().then(setBusinessSizes).catch(console.error);
    }, [businessSizeRepo]);

    const options = useMemo(() => businessSizes.map(s => ({ id: s, label: s })), [businessSizes]);

    return <PickerInput label={'Business Size'} options={options} {...PickerProps} />
}