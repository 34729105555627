import {Form, Formik} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {CreateProductLicenseForProductFields, UpdateProductLicenseFields} from "./ProductLicenseFields";
import {CreateProductLicenseRequest, ProductLicense, UpdateProductLicenseRequest} from "../../../../models/ProductLicense";
import {initialValuesForProductLicenseCreateForProduct, initialValuesForProductLicenseUpdate, createProductLicenseSchema, updateProductLicenseSchema} from "../../../../utils/schemas/product-license-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {Product} from "../../../../models/Product";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdateProductLicenseForm(props: { productLicense: ProductLicense, onProductLicenseUpdated: (productLicense: ProductLicense) => void }) {
    const { productLicense, onProductLicenseUpdated }  = props;
    const { productLicenseRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.productLicense.update);

    const onSubmit = updateSubmitHandler(
        (body: UpdateProductLicenseRequest) => productLicenseRepo.update(productLicense.id, body),
        initialValuesForProductLicenseUpdate,
        onProductLicenseUpdated,
        console.error,
    );

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValuesForProductLicenseUpdate(productLicense)}
                validationSchema={updateProductLicenseSchema}
                onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateProductLicenseFields disabled={isReadOnly} applicationId={productLicense.applicationId}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateProductLicenseForProductForm(props: { product: Product, onProductLicenseCreated: (productLicense: ProductLicense) => void }) {
    const { product, onProductLicenseCreated }  = props;
    const { productLicenseRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateProductLicenseRequest) => productLicenseRepo.create(body),
        onProductLicenseCreated,
        console.error
    );

    return (
        <Formik<CreateProductLicenseRequest> initialValues={initialValuesForProductLicenseCreateForProduct(product)}
                                             validationSchema={createProductLicenseSchema}
                                             onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateProductLicenseForProductFields product={product}/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}

export const UpdateProductLicenseModal = withModal(UpdateProductLicenseForm, "Update Product License");
export const CreateProductLicenseForProductModal = withModal(CreateProductLicenseForProductForm, "Create Product License");