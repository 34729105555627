import {useRepo} from "../../../../repos/useRepo";
import {default as React, useRef} from "react";
import Table from "../../../table/Table";
import {getTableDataFetcherFromRepoFetcher} from "../../../table/TableRepoAdapter";
import {ColumnFactory} from "../../../entities/TableColumnFactory";
import useCrudController from "../../../entities/useCrudController";
import {Site} from "../../../../models/ti/Site";
import {SiteTableColumnFactory} from "./SiteTableColumnFactory";
import {useNavigation} from "../../../../services/navigation/useNavigation";

export default function SiteTable() {
    const { siteRepo } = useRepo();
    const { navigateToSite } = useNavigation();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const {_list} = useCrudController(siteRepo, reloadData);

    const columnFactory: ColumnFactory<Site> = new SiteTableColumnFactory();

    return (
        <>
            <Table<Site>
                tableRef={tableRef}
                title={`Sites`}
                columns={columnFactory.makeColumns()}
                options={{}}
                data={getTableDataFetcherFromRepoFetcher(_list)}
                onRowClick={navigateToSite}
            />
        </>
    );
}