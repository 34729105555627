import * as React from "react";
import {useState} from "react";
import { StateApplication } from "../../models/sid/StateApplication";
import {CreateStateApplicationModal} from "../entities/state-id/settings/state-application/StateApplicationForm";

export default function useCreateStateIdFieldTypeModal(onStateApplicationCreated: (stateApplication: StateApplication) => void) {

    const [open, setOpen] = useState(false);

    const handleFieldTypeCreated = (stateApplication: StateApplication) => {
        setOpen(false);
        onStateApplicationCreated(stateApplication)
    };

    const modal = (
        <CreateStateApplicationModal
            open={open}
            onClose={() => setOpen(false)}
            onStateApplicationCreated={handleFieldTypeCreated}
        />
    );

    return { setCreateStateApplicationModalOpen: setOpen, createStateApplicationModal: modal }
}
