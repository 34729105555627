import React, { useMemo } from "react";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";
import config from "../../../config";

interface Props extends PickerPropsWithoutOptions { }

export default function AdpProductPicker({ ...PickerProps }: Props) {

    interface Option { text: string, value: string }

    const values: Option[] = config.adp.products.map(x => ({ text: x.name, value: x.identifier }));

    const options = useMemo(() => values.map(c => ({ id: c.value.toString(), label: c.text })), [values]);

    return <PickerInput label={'Adp Product'} options={options} {...PickerProps} />
}
