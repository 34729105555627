import {useRepo} from "../../../../repos/useRepo";
import {default as React, useRef} from "react";
import Table, {TableColumn} from "../../../table/Table";
import {ColumnFactory} from "../../TableColumnFactory";
import {Template} from "../../../../models/mail/Template";
import {TemplateTableColumnFactory} from "./TemplateTableColumnFactory";
import useCrudController from "../../useCrudController";
import {getTableDataFetcherFromRepoFetcher} from "../../../table/TableRepoAdapter";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import useCreateTemplateModal from "../../../modals/useCreateTemplateModal";
import useUpdateTemplateModal from "../../../modals/useUpdateTemplateModal";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";

export default function TemplateTable() {
    const { templateRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canCreateTemplate = can(actions.template.create);
    const canUpdateTemplate = can(actions.template.update);
    const canDeleteTemplate = can(actions.template.delete);

    const { setCreateTemplateModalOpen, createTemplateModal } = useCreateTemplateModal(reloadData);
    const { setTemplateForUpdate, updateTemplateModal } = useUpdateTemplateModal(reloadData);

    const {_list} = useCrudController(templateRepo, reloadData);

    const columnFactory: ColumnFactory<Template> = new TemplateTableColumnFactory();
    const columns: TableColumn<Template>[] = columnFactory.makeColumns();

    const deleteTemplate = (template: Template) => {
        templateRepo.delete(template.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onTemplateSelected = (template: Template) => {
        if (canUpdateTemplate) {
            setTemplateForUpdate(template)
        }
    };

    return (
        <>
            <Table<Template>
                tableRef={tableRef}
                title={`Templates`}
                columns={columns}
                actions={[
                    makeCreateAction(() => setCreateTemplateModalOpen(true), { disabled: !canCreateTemplate }),
                    makeDeleteAction(deleteTemplate, { disabled: !canDeleteTemplate })
                ]}
                options={{}}
                onRowClick={onTemplateSelected}
                data={getTableDataFetcherFromRepoFetcher(_list)}
            />
            {createTemplateModal}
            {updateTemplateModal}
        </>
    );
}