import {default as React, useState} from "react";
import {User} from "../../models/User";
import { CreateUserB2CModal } from "../entities/users/UserCreateB2CForm";
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from "@material-ui/core";

export default function useCreateB2CModal(user: User, onB2CCreated: () => void) {
    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState<{ msg?: string, severity?: "error" | "success" | "info" | "warning" | undefined, show: boolean}>({ show: false });

    const handleB2CCreated = (msg?: string) => {

        if(msg){
          setToast({ msg, severity:'error', show: true });
        } else {
          setToast({ msg: `${user.firstName} B2C Account Created!`, severity:'success', show: true });
          setOpen(false);
          onB2CCreated();
        }
      };

    const modal = (
        <>
        <CreateUserB2CModal
            open={open}
            onClose={() => setOpen(false)}
            user={user}
            onCreated={handleB2CCreated}
        />
        <Snackbar open={toast.show} autoHideDuration={6000} onClose={() => setToast({show: false})}>
            <Alert severity={toast.severity}>{toast.msg}</Alert>
        </Snackbar>
    </>
    );

    return { openCreateB2CModal: () => setOpen(true), createB2CModal: modal }
}