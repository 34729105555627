import {Form, Formik} from "formik";
import {Company} from "../../../../models/Company";
import {WebsiteAnalysis, CreateWebsiteAnalysisRequest} from "../../../../models/WebsiteAnalysis";
import React from "react";
import TextInput from "../../../form/inputs/TextInput";
import YesNoPicker from "../../../form/pickers/YesNoPicker";
import SendReportDelayPicker from "../../../form/pickers/SendReportDelayPicker";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import {withModal} from "../../../modal";
import {
    createWebsiteAnalysisSchema,
    initialValuesForWebsiteAnalysisCreate
} from "../../../../utils/schemas/website-analysis-schema";

import { createSubmitHandler } from "../../../form/helpers/formSubmit";
import {useRepo} from "../../../../repos/useRepo";

interface Props {
    company: Company
    onWebsiteAnalysisCreated: (websiteAnalysis: WebsiteAnalysis) => void;
}

function CreateWebsiteAnalysisForCompanyForm({ company, onWebsiteAnalysisCreated }: Props) {
    const { websiteAnalysisRepo } = useRepo()

    const onSubmit = createSubmitHandler(
        (body: CreateWebsiteAnalysisRequest) => websiteAnalysisRepo.create(company.id, body),
        onWebsiteAnalysisCreated,
        console.error
    );

    return (
        <Formik initialValues={initialValuesForWebsiteAnalysisCreate(company)}
                validationSchema={createWebsiteAnalysisSchema}
                onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <TextInput name='url' label='Website Url' type='url'/>

                <YesNoPicker name='notify_customer' label='Send Report' />

                <TextInput name='notify_customer_email' label='Send Report To'/>

                <SendReportDelayPicker name='notify_customer_delay' label='Send Report Delay' />

                <FormSubmitButton variant='Create'/>

            </Form>
        </Formik>
    )
}

export const CreateWebsiteAnalysisForCompanyModal = withModal(CreateWebsiteAnalysisForCompanyForm);

