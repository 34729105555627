import MailchimpAccountRepo from "./MailchimpAccountRepo";
import { MailchimpAccount } from "../../models/MailchimpAccount";
import { ListQuery } from "../CrudRepo";

export class InMemoryMailchimpAccountRepoCache implements MailchimpAccountRepo {
    cachedAccountList?: MailchimpAccount[];
    accountPromise?: Promise<MailchimpAccount[]>;

    constructor(private inner: MailchimpAccountRepo) {}

    async listAll(): Promise<MailchimpAccount[]> {
        if (this.cachedAccountList) {
            return this.cachedAccountList;
        } else {
            if (this.accountPromise) {
                return this.accountPromise;
            } else {
                const templatesPromise = this.inner.listAll();
                this.accountPromise = templatesPromise;
                const templates = await templatesPromise;
                this.cachedAccountList = templates;
                this.accountPromise = undefined;
                return templates;
            }
        }
    };

    create = (resource: Partial<MailchimpAccount>) => this.inner.create(resource);

    delete = (id: string) => this.inner.delete(id);

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<MailchimpAccount>) => this.inner.update(id, resource);
}