import React, {useMemo} from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export default function UrlTypePicker({ ...PickerProps }: Props) {

    const types: string[] = ['url', 'subdomain', 'page'];
    
    const options = useMemo(() => types.map(t => ({ id: t, label: t })), [types]);

    return <PickerInput label={'URL Type'} options={options} {...PickerProps} />
}
