import EmployeeCompanyRepo from "./EmployeeCompanyRepo";
import {EmployeeCompany} from "../../models/EmployeeCompany";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../models";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';
import {sanitize} from "../../utils/FormUtils";

export default class HttpEmployeeCompanyRepo extends HttpCrudRepo<EmployeeCompany> implements EmployeeCompanyRepo {
    pathComponents = ['emp', 'v1', 'companies'];

    update(id: string, resource: Partial<EmployeeCompany>): Promise<EmployeeCompany> {
        return super.update(id, sanitize(resource));
    }

    listAll(): Promise<EmployeeCompany[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<EmployeeCompany>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
