import React, {useEffect, useRef} from "react";
import {queryResultFromArray} from "../../table/TableRepoAdapter";
import Table, {TableColumn, TableQuery} from "../../table/Table";
import TableColumnFactory from "../TableColumnFactory";
import {useRepo} from "../../../repos/useRepo";
import {Company} from "../../../models/Company";
import {License} from "../../../models/License";
import {User} from "../../../models/User";
import {Application} from "../../../models/Application";
import useAssignUserToLicenseModal from "../../modals/useAssignUserToLicenseModal";

interface Props {
    company: Company;
    onUserSelected: (user: User) => void;
    onApplicationSelected: (application: Application) => void;
}

export default function CompanyLicensesTable({ company, onUserSelected, onApplicationSelected }: Props) {
    const { companyRepo } = useRepo();

    const tableRef = useRef<any>(null);

    const reloadData = () => tableRef.current.onQueryChange();

    const { setLicenseToBeAssignedToUser, assignLicenseToUserModal } = useAssignUserToLicenseModal(company, reloadData);

    useEffect(reloadData, [company.id]);

    const columns: TableColumn<License>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeApplicationColumn("Application", 'applicationId', l => l.application),
        TableColumnFactory.makeUserNameColumn("User", l => l.user),
        TableColumnFactory.booleanColumn("Active", "isActive"),
        TableColumnFactory.booleanColumn("Trial", "isTrial"),
        TableColumnFactory.booleanColumn("Demo", "isDemo"),
        TableColumnFactory.booleanColumn("Unlimited", "isCompanyLicense"),
    ]);

    const fetchData = async (tableQuery: TableQuery<License>) => {
        const res = await companyRepo.listLicensesForCompanyById(company.id);
        return queryResultFromArray(res)
    };

    return (
        <>
            <Table<License>
                tableRef={tableRef}
                title={`Licenses for ${company.name}`}
                columns={TableColumnFactory.disableSearchAndFiltering(columns)}
                actions={[]}
                options={{
                    search: false,
                    filtering: false,
                    paging: false
                }}
                data={fetchData}
                onRowClick={setLicenseToBeAssignedToUser}
            />
            {assignLicenseToUserModal}
        </>
    );
}