import * as Yup from 'yup';
import {WebsiteAnalysis, CreateWebsiteAnalysisRequest, UpdateWebsiteAnalysisRequest} from "../../models/WebsiteAnalysis";
import {Company} from "../../models/Company";

const baseWebsiteAnalysisFields = {
    notify_customer: Yup.bool(),
    notify_customer_email: Yup.string().when('notify_customer', {
        is: (notify_customer) => notify_customer,
        then: Yup.string().required('Send To Email is required')
    }),
    notify_customer_delay: Yup.number().integer()
    .min(0, 'Please enter a value > 0').required()
}

export const updateWebsiteAnalysisSchema = Yup.object().shape(baseWebsiteAnalysisFields);

export const createWebsiteAnalysisSchema = Yup.object().shape({
    ...baseWebsiteAnalysisFields,
    url: Yup.string().url().nullable()
});

export const initialValuesForWebsiteAnalysisUpdate = (websiteAnalysis: WebsiteAnalysis): UpdateWebsiteAnalysisRequest => ({
    notify_customer: websiteAnalysis.sendReport ?? false,
    notify_customer_email: websiteAnalysis.sendReportTo ?? "",
    notify_customer_delay: websiteAnalysis.sendReportDelay ?? 0
});

export const initialValuesForWebsiteAnalysisCreate = (company: Company): CreateWebsiteAnalysisRequest => ({
    url: company.website ?? "",
    notify_customer: false,
    notify_customer_email: company.primaryContact?.email ?? "",
    notify_customer_delay: 0
});
