import React, { useMemo } from "react";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";
import Demeanors from "../../../config/demeanors";

interface Props extends PickerPropsWithoutOptions { }

export default function DemeanorPicker({ ...PickerProps }: Props) {

    interface Option { text: string, value: string }

    const values: Option[] = Object.keys(Demeanors).map((k) => Demeanors[k]).map((d) => { return { text: d.title, value: d.key } as Option});

    const options = useMemo(() => values.map(c => ({ id: c.value, label: c.text })), [values]);

    return <PickerInput label={'Demeanor'} options={options} {...PickerProps} />
}
