import React from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import timeZones from "../../../config/time-zones";

interface Props extends PickerPropsWithoutOptions {
    usaOnly?: boolean
}

export default function TimeZonePicker({ ...PickerProps }: Props) {
    const { usaOnly } = PickerProps;

    let options;

    if(usaOnly === true)
        options = timeZones.filter(t => t.country === 'USA').map(t => ({ id: t.value, label: t.name }));
    else
        options = timeZones.map(t => ({ id: t.value, label: t.name })); 

    return <PickerInput label={'Time Zone'} options={options} {...PickerProps} />
}
