import EmployeeRepo from "./EmployeeRepo";
import {Employee} from "../../models/Employee";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {ListQuery} from "../CrudRepo";
import {CollectionResponse} from "../../models";
import {AssignRolesRequest, ListRoleQuery, RevokeRoleRequest} from "./EmployeeRepo";
import {EmployeeRole} from "../../models/EmployeeRole";
import {sanitize} from "../../utils/FormUtils";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";

export default class HttpEmployeeRepo extends HttpCrudRepo<Employee> implements EmployeeRepo {
    pathComponents = ['emp', 'v1', 'employees'];

    create(body: object): Promise<Employee> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<Employee>): Promise<Employee> {
        return super.update(id, sanitize(resource));
    }

    getById(id: string): Promise<Employee> {
        const query = { include: "roles" };
        return this.http.get([...this.pathComponents, id], { query });
    }

    list(query: ListQuery): Promise<CollectionResponse<Employee>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "company";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    listRolesForCurrentEmployee(): Promise<EmployeeRole[]> {
        return this.listRolesForEmployeeById('current');
    }

    listRolesForEmployeeById(id: string, query?: ListRoleQuery): Promise<EmployeeRole[]> {
        return this.http.get([...this.pathComponents, id, 'roles'], { query });
    }

    assignRolesForEmployeeById(id: string, body: AssignRolesRequest): Promise<EmployeeRole[]> {
        return this.http.post([...this.pathComponents, id, 'roles'], body);
    }

    revokeRolesForEmployeeById(id: string, query?: RevokeRoleRequest): Promise<void> {
        return this.http.delete([...this.pathComponents, id, 'roles'], { query });
    }
}
