import React, {useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {pickerStyles} from "../pickers/pickerStyles";
import {defaultMargins} from "../styles/field";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Props<T> {
    options: T[];
    onChange: (value: T | null) => void;
    value: T | null;
    style?: React.CSSProperties;
    placeHolderText?: string;
    variant?: 'standard' | 'outlined' | 'filled';
}

export interface FilterPickerItem {
    id: string;
    name: string;
}

const noFilterDescriptor = "None";

export default function FilterPicker<T extends FilterPickerItem>(props: Props<T>) {
    const [open, setOpen] = useState(false);
    const options = props.options || [];
    const value = props.value ? props.value.name : noFilterDescriptor;
    const customStyle = props.style || {};
    const placeHolderText = props.placeHolderText || "Select Filter...";

    return (
        <FormControl style={{ ...defaultMargins, ...customStyle}}>
            <Select
                value={value}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                displayEmpty
                variant={props.variant}
                style={{ ...pickerStyles, width: customStyle.width || '100%' }}
                renderValue={(value: any) => {
                    if (value === noFilterDescriptor) {
                        return (
                            <em style={{fontSize: 14, color: "gray"}}>{placeHolderText}</em>
                        );
                    }

                    return <em style={{fontSize: 14}}>{value}</em>;
                }}
                onChange={
                    (e: React.ChangeEvent<{ value: unknown }>) => {
                        const value = e.target.value as string;

                        const model = options.find(m => m.name === value) || null;

                        props.onChange(model);
                    }
                }
                MenuProps={MenuProps}
            >

                <MenuItem value={noFilterDescriptor} key={noFilterDescriptor}>
                    <em style={{color: "gray"}}>{noFilterDescriptor}</em>
                </MenuItem>
                {options.map((model: T) => (
                    <MenuItem key={model.name} value={model.name}>
                        {model.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
