import {
    Company,
    CompanyMetadata,
    CompanyNote,
    CreateUserForCompanyRequest, LinkStripeCustomerForCompanyRequest,
    UpdateCompanyProfileRequest,
    UpdateCompanyRequest,
    CreateNoteForCompanyRequest,
    UpdateNoteForCompanyRequest,
    CompanyReferral
} from "../../models/Company";
import CompanyRepo, {CreateNoChargeSubscriptionForCompanyRequest, ListLicenseQuery} from "./CompanyRepo";
import {ListQuery} from "../CrudRepo";
import {CollectionResponse} from "../../models";
import {sanitize} from "../../utils/FormUtils";
import {License} from "../../models/License";
import {User} from "../../models/User";
import {Subscription} from "../../models/Subscription";
import HttpService from "@gsb/react-http";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import {StripeCustomer} from "../../models/stripe/StripeCustomer";
import { Business } from "../../models/Business";

export default class HttpCompanyRepo implements CompanyRepo {
    pathComponents = ['mk', 'v1', 'companies'];

    constructor(private http: HttpService) {}

    list(query: ListQuery): Promise<CollectionResponse<Company>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "reseller,salesChannel,owner,primaryContact";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    async update(id: string, body: Partial<Company>): Promise<Company> {
        return this.http.put( [...this.pathComponents, id], sanitize(body));
    }

    updateProfile(companyId: string, body: UpdateCompanyProfileRequest): Promise<Company> {
        return this.http.put([...this.pathComponents, companyId, "profile"], sanitize(body));
    };

    async create(body: Partial<Company>): Promise<Company> {
        return this.http.post(this.pathComponents, sanitize(body));
    }

    createUserForCompanyById(id: string, body: CreateUserForCompanyRequest): Promise<User> {
        return this.http.post([...this.pathComponents, id, 'users'], sanitize(body));
    }

    getById(id: string): Promise<Company> {
        const query = { include: 'salesChannel,reseller,profile,notes' };
        return this.http.get([...this.pathComponents, id], { query });
    }

    delete(id: string): Promise<void> {
        return this.http.delete([...this.pathComponents, id]);
    }

    linkStripeCustomerForCompanyById(id: string, body: LinkStripeCustomerForCompanyRequest): Promise<StripeCustomer> {
        return this.http.post([...this.pathComponents, id, 'stripe-customers'], body);
    }

    listStripeCustomersForCompanyById(id: string): Promise<StripeCustomer[]> {
        return this.http.get([...this.pathComponents, id, 'stripe-customers']);
    }

    listLicensesForCompanyById(id: string, query?: ListLicenseQuery): Promise<License[]> {
        return this.http.get([...this.pathComponents, id, 'licenses'], { query });
    }

    listSubscriptionsForCompanyById(id: string): Promise<Subscription[]> {
        return this.http.get([...this.pathComponents, id, 'subscriptions']);
    }

    updateCompanyById(id: string, body: UpdateCompanyRequest): Promise<Company> {
        return this.http.put([...this.pathComponents, id], sanitize(body));
    }

    listUsersForCompanyById(id: string): Promise<User[]> {
        return this.http.get([...this.pathComponents, id, 'users']);
    }

    deleteUserForCompanyById(companyId: string, query: { userId: string }): Promise<void> {
        return this.http.delete([...this.pathComponents, companyId, 'users'], { query });
    }

    listAvailableUsersForCompanyLicenseById(companyId: string, licenseId: string): Promise<User[]> {
        return this.listUsersForCompanyLicenseById(companyId, licenseId, { status: 'available' });
    }

    private listUsersForCompanyLicenseById(companyId: string, licenseId: string, query: { status: 'available' }): Promise<User[]> {
        return this.http.get([...this.pathComponents, companyId, 'licenses', licenseId, 'users'], { query });
    }

    createNoChargeSubscriptionForCompanyById(id: string, body: CreateNoChargeSubscriptionForCompanyRequest): Promise<void> {
        return this.http.post([...this.pathComponents, id, 'subscriptions', 'no-charge'], sanitize(body));
    }

    listMetadataForCompanyById(id: string): Promise<CompanyMetadata[]> {
        return this.http.get([...this.pathComponents, id, 'metadata']);
    }

    updateMetadataForCompanyById(companyId: string, body: CompanyMetadata[]): Promise<CompanyMetadata[]> {
        return Promise.all(body.map(b => this.updateMetadata(companyId, b)));
    }

    private updateMetadata(companyId: string, body: CompanyMetadata): Promise<CompanyMetadata> {
        const { id, value } = body;
        return this.http.put([...this.pathComponents, companyId, 'metadata'], { id, value });
    }

    createNoteForCompanyById(id: string, body: CreateNoteForCompanyRequest): Promise<CompanyNote> {
        return this.http.post([...this.pathComponents, id, 'notes'], body);
    }

    updateNoteForCompanyById(id: string, noteId: string, body: UpdateNoteForCompanyRequest): Promise<CompanyNote>{
        return this.http.put([...this.pathComponents, id, 'notes', noteId ], body)
    }

    deleteNoteForCompanyById(id: string, noteId: string): Promise<void>{
        return this.http.delete([...this.pathComponents, id, 'notes', noteId], { });
    }

    listNotesForCompanyById(companyId: string): Promise<CompanyNote[]>{
        return this.http.get([...this.pathComponents, companyId, 'notes'],{});
    }

    recentNotesForCompanyById(companyId: string): Promise<CompanyNote[]>{
        const query = { limit: 5 }
        return this.http.get([...this.pathComponents, companyId, 'notes-recent'], { query });
    }

    changeResellerForCompanyById(companyId: string, resellerId: string): Promise<Company>{
        return this.http.put([...this.pathComponents, companyId, 'reseller'], { resellerId })
    }

    listReferralsForCompanyById(id: string): Promise<CompanyReferral[]> {
        return this.http.get([...this.pathComponents, id, 'legal-referrals']);
    }

    listBusinessProfilesById(companyId: string): Promise<Business[]> {
        return this.http.get([...this.pathComponents, companyId, 'businesses']);
    }

    getBusinessProfileById(companyId: string, profileId: string): Promise<Business> {
        return this.http.get([...this.pathComponents, companyId, 'businesses', profileId]);
    }
}
