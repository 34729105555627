import TemplateRepo from "./TemplateRepo";
import {Template} from "../../models/mail/Template";
import {ListQuery} from "../CrudRepo";

export class InMemoryTemplateRepoCache implements TemplateRepo {
    cachedTemplateList?: Template[];
    templatePromise?: Promise<Template[]>;

    constructor(private inner: TemplateRepo) {}

    async listAll(): Promise<Template[]> {
        if (this.cachedTemplateList) {
            return this.cachedTemplateList;
        } else {
            if (this.templatePromise) {
                return this.templatePromise;
            } else {
                const templatesPromise = this.inner.listAll();
                this.templatePromise = templatesPromise;
                const templates = await templatesPromise;
                this.cachedTemplateList = templates;
                this.templatePromise = undefined;
                return templates;
            }
        }
    };

    create = (resource: Partial<Template>) => this.inner.create(resource);

    delete = (id: string) => this.inner.delete(id);

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<Template>) => this.inner.update(id, resource);
}