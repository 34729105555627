import {Product} from "../../../../../models/Product";
import * as React from "react";
import ProductLicenseTable from "../../product-licenses/ProductLicenseTable";

interface Props {
    product: Product;
}

export default function LicensesTab({ product }: Props) {
    return (
        <>
            <ProductLicenseTable product={product}/>
        </>
    )
}

