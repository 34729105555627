import {useRepo} from "../../../../../repos/useRepo";
import {default as React, useRef} from "react";
import Table, {TableColumn} from "../../../../table/Table";
import {ColumnFactory} from "../../../TableColumnFactory";
import {FieldTypeTableColumnFactory} from "./FieldTypeTableColumnFactory";
import useCrudController from "../../../useCrudController";
import {getTableDataFetcherFromRepoFetcher} from "../../../../table/TableRepoAdapter";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../../rbac";
import useCreateStateIdFieldTypeModal from "../../../../modals/useCreateStateIdFieldTypeModal";
import useUpdateStateIdFieldTypeModal from "../../../../modals/useUpdateStateIdFieldTypeModal";
import {makeCreateAction, makeDeleteAction} from "../../../../table/TableActionFactory";
import { FieldType } from "../../../../../models/sid/FieldType";
import { useDeleteDialog } from "../../../../dialog/ConfirmDialog";

export default function StateApplicationTable() {
    const { fieldTypeRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canCreateFieldType = can(actions.stateIdFieldType.create);
    const canUpdateFieldType = can(actions.stateIdFieldType.update);
    const canDeleteFieldType = can(actions.stateIdFieldType.delete);

    const { setCreateStateIdFieldTypeModalOpen, createStateIdFieldTypeModal } = useCreateStateIdFieldTypeModal(reloadData);
    const { setStateIdFieldTypeModal, updateStateIdFieldTypeModal } = useUpdateStateIdFieldTypeModal(reloadData);
    const { openDeleteDialog, confirmDialog } = useDeleteDialog('Delete Field Type', 'Are you sure you want to delete this field type?', (e: FieldType) => handleDeleteFieldType(e))

    const {_list} = useCrudController(fieldTypeRepo, reloadData);

    const columnFactory: ColumnFactory<FieldType> = new FieldTypeTableColumnFactory();
    const columns: TableColumn<FieldType>[] = columnFactory.makeColumns();

    const handleDeleteFieldType = (fieldType: FieldType) => {
        fieldTypeRepo.delete(fieldType.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onTemplateSelected = (fieldType: FieldType) => {
        if (canUpdateFieldType) {
            setStateIdFieldTypeModal(fieldType)
        }
    };

    return (
        <>
            <Table<FieldType>
                tableRef={tableRef}
                title={`Field Type Definitions`}
                columns={columns}
                 actions={[
                     makeCreateAction(() => setCreateStateIdFieldTypeModalOpen(true), { disabled: !canCreateFieldType }),
                     makeDeleteAction(openDeleteDialog, { disabled: !canDeleteFieldType })
                 ]}
                options={{ pageSize: 50 }}
                onRowClick={onTemplateSelected}
                data={getTableDataFetcherFromRepoFetcher(_list)}
            />
            {createStateIdFieldTypeModal}
            {updateStateIdFieldTypeModal} 
            {confirmDialog} 
        </>
    );
}