import {withModal} from "../modal";
import React, {useEffect, useState} from "react";
import LoadingIndicator from "../LoadingIndicator";
import {IconButton} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel'
const ReactMarkdown = require('react-markdown')

interface Props {
    identifier: string;
    title?: string;
    onCloseClicked: () => void;
}


function Help({ identifier, title, onCloseClicked }: Props) {
    const url = `https://gsbstore.blob.core.windows.net/help/${identifier}.md`;

    const [rawMarkdown, setRawMarkdown] = useState<string>()

    const loadMarkdown = () => {
        fetch(url)
            .then(res => {
                if (res.status != 200) {
                    throw new Error();
                }
                return res.text()
            })
            .then(setRawMarkdown)
            .catch(() => setRawMarkdown(`Failed to load help file from ${url}`));
    };

    useEffect(loadMarkdown, []);

    if (rawMarkdown) {
        return (
            <div>
                <IconButton style={{ float: 'right' }} onClick={onCloseClicked}><CancelIcon/></IconButton>

                <div style={{ overflow: 'scroll' }}>
                    <ReactMarkdown source={rawMarkdown}/>
                </div>
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

const HelpModal = withModal(Help);

export function useHelpModal() {

    const [identifier, setIdentifier] = useState<string>();
    const close = () => setIdentifier(undefined);

    const modal = identifier ? (
        <HelpModal
            identifier={identifier}
            open={true}
            onCloseClicked={close}
            onClose={close}
        />
    ): null;

    return { modal, openWithIdentifier: setIdentifier }
}
