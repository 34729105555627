import { Form, Formik, useFormikContext } from "formik";
import { Company, CompanyReferral } from "../../../../models/Company";
import { User, UserReferral } from "../../../../models/User";
import React, { useMemo, useEffect } from "react";
import TextInput from "../../../form/inputs/TextInput";
import { FormSubmitButton } from "../../../form/buttons/FormSubmitButton";
import { withModal } from "../../../modal";
import {
    createUserReferralSchema,
    initialValuesForCreateUserReferral
} from "../../../../utils/schemas/user-schema";
import { createSubmitHandler } from "../../../form/helpers/formSubmit";
import { useRepo } from "../../../../repos/useRepo";
import { CompanyUserPicker } from "../../../form/pickers/UserPicker";
import { DateInput } from "../../../form/inputs/DateInput";
import RadioButtonGroupInput from "../../../form/inputs/RadiobuttonGroupInput";
import moment from "moment";
import { DateTime as LuxonDateTime } from "luxon";
import TimeZonePicker from "../../../form/pickers/TimeZonePicker";
import TimePicker from "../../../form/pickers/TimePicker";
import timeZones, { TimeZone } from "../../../../config/time-zones";
import { TwoColumnFormLayout } from "../../../form/FormLayout";
import config from "../../../../config";
import DateUtils from "../../../../utils/DateUtils";
import LanguagePicker from "../../../form/pickers/LanguagePicker";
import { Typography } from "@material-ui/core";

interface Props {
    company: Company
    onReferralCreated: (referral: CompanyReferral) => void;
}

interface FieldProps {
    companyId: string,
    disabled?: boolean
}
interface ScheduleTimes {
    openTime: string,
    closeTime: string,
    currentTime?: string
}

function CreateScheduleFields() {
    const { values, setFieldValue } = useFormikContext<UserReferral>();
    const { schedule_type, schedule_date, timeZone } = values;

    const isLater = useMemo(() => schedule_type == '0', [schedule_type]);
    const [scheduleTimes, setScheduleTimes] = React.useState<ScheduleTimes>();
    const [userTimeZone, setUserTimeZone] = React.useState<TimeZone | undefined>(timeZones.find((tz) => tz.value == timeZone));

    function onTimeZoneChange(e: any, child?: any) {
        const { value } = e.target;
        setFieldValue('timeZone', value);
        setUserTimeZone(timeZones.find((tz) => tz.value == value));
    }

    const calLocalHours = () => {
        const { openHour: lcOpen, closeHour: lcClose, timeZone: lcTimeZone } = config.legalClub;
        let scheduleToday = false;

       

        if (userTimeZone) {
            const local = LuxonDateTime.utc().setZone(userTimeZone.zone);
            const lcLocal = LuxonDateTime.utc().setZone(lcTimeZone);

            const localOffset = local.offset;
            const lcOffset = lcLocal.offset;

            const remainder = 15 - (local.minute % 15);
            const currentTime = local.plus({ minutes: remainder });

            if(schedule_date){
                scheduleToday = DateUtils.isToday(schedule_date);
            }

            let diffInMinutes = localOffset - lcOffset;
            if (diffInMinutes !== 0) {
                const diffInHours = diffInMinutes / 60;
                const openHour = lcOpen + diffInHours;
                const closeHour = lcClose + diffInHours;
                calLegalClubHour(openHour, closeHour, isOpenToday(), scheduleToday === true && currentTime.hour >= openHour ? currentTime.toFormat('hh:mma') : undefined);
            } else {
                calLegalClubHour(lcOpen, lcClose, isOpenToday(), scheduleToday === true && currentTime.hour >= lcOpen ? currentTime.toFormat('hh:mma') : undefined);
            }
        } else {
            calLegalClubHour(lcOpen, lcClose, false);
        }

        return () => {};
    }

    const formatHour = (hour: number) => moment(`${hour}:00`, 'HH:mm').format('hh:mmA');

    const isOpenToday = () => {
        const { timeZone, openHour, closeHour } = config.legalClub;
        const local = LuxonDateTime.utc().setZone(timeZone);
        return (local.hour >= openHour && local.hour < closeHour) && !DateUtils.isAHoliday(local.toJSDate(), config.legalClub.holidays);
    } 

    const isClosedToday = () => {
        const { timeZone, closeHour } = config.legalClub;
        const local = LuxonDateTime.utc().setZone(timeZone);
        return (local.hour >= closeHour);
    }

    const minDate = () => (isClosedToday() ? LuxonDateTime.local().plus({days: 1}) : LuxonDateTime.local()).toISO();
    const maxDate = () => LuxonDateTime.local().plus({days: (isOpenToday() ? 10 : 11)}).toISO();
    
    function calLegalClubHour(openHour: number, closeHour: number, isOpen: boolean, currentTime?: string) {
        setScheduleTimes({
            openTime: formatHour(openHour),
            closeTime: formatHour(closeHour),
            currentTime
        });
        if(isOpen === false) setFieldValue('schedule_type',  '0');
    }

    useEffect(calLocalHours, [userTimeZone, schedule_date]);

    return (
        <>
            <TimeZonePicker name='timeZone' label='Time-Zone' SelectProps={{ onChange: onTimeZoneChange }} usaOnly={true} />

            {userTimeZone && <>

                <RadioButtonGroupInput name='schedule_type' label='Schedule' options={[{ label: 'NOW', id: '1', disabled: !isOpenToday() }, { label: 'LATER', id: '0' }]} />

                {isLater &&
                    <>
                        <DateInput name='schedule_date' label='Schedule Date' minDate={minDate()} maxDate={maxDate()} disableWeekends={true} disabledHolidays={config.legalClub.holidays} />

                        <TimePicker name='schedule_time' label='Schedule Time' minTime={scheduleTimes?.currentTime || scheduleTimes?.openTime || '9:00am'} maxTime={scheduleTimes?.closeTime || '7:00pm'} minutesStep={15} />
                    </>
                }                

            </>}
        </>
    );
}


function CreateReferralFields({ companyId, disabled }: FieldProps) {
    const { setFieldValue } = useFormikContext<UserReferral>();
    const { userRepo } = useRepo()
    const [user, setUser] = React.useState<User>();

    function onUserIdChange(e: any, child?: any) {
        const { value: userId } = e.target;
        userRepo.getById(userId).then(loadUser);
    }

    function loadUser(user: User) {
        setFieldValue('userId', user.id);
        setFieldValue('client_name', user.firstName + ' ' + user.lastName);
        setFieldValue('email_address', user.email);
        if (user.phoneNumber) {
            setFieldValue('phone_number', user.phoneNumber);
        }
        setUser(user);
    }

    return (
        <>
            <CompanyUserPicker companyId={companyId} name='userId' label='Select User' SelectProps={{ onChange: onUserIdChange }} />

            {user &&
                <>
                    <TextInput name='client_name' label='Client Name' />

                    <TextInput name='business_name' label='Business Name' />

                    <TextInput name='email_address' label='Email Address' />

                    <TextInput name='phone_number' label='Phone Number' />

                    <LanguagePicker name='language' label='Language' />

                </>
            }
        </>
    );

}

function CreateReferralForUserForm({ company, onReferralCreated }: Props) {
    const { userRepo } = useRepo()

    const submit = (form: UserReferral) => {
        const { userId, schedule_type, schedule_date, schedule_time, timeZone, ...body } = form;

        let schedule_on = moment.utc().format()
        if (schedule_type == '0' && schedule_date) {
            const tz = timeZones.find((tz) => tz.value == timeZone);
            const stringDate = schedule_date.toISOString().split('T')[0] + ' ' + schedule_time;
            schedule_on = LuxonDateTime.fromFormat(stringDate, 'yyyy-LL-dd hh:mma', { zone: tz?.zone }).toUTC().toISO();
        }
        return userRepo.createReferralForUserById(userId, { ...body, schedule_on });
    }

    const onSubmit = createSubmitHandler(
        submit,
        onReferralCreated,
        console.error
    );

    return (
        <Formik initialValues={initialValuesForCreateUserReferral(company)}
            validationSchema={createUserReferralSchema}
            onSubmit={onSubmit}>
            <Form>
                <TwoColumnFormLayout
                    col1FieldSet={<CreateReferralFields companyId={company.id} />}
                    col2FieldSet={<CreateScheduleFields />}
                    bottomContent={<FormSubmitButton variant='Create' />} />

                <Typography>* Legal Club Operates Monday through Friday from 9:00 am to 7:00 pm ET.</Typography>
            </Form>
        </Formik>
    )
}

export const CreateReferralForUserModal = withModal(CreateReferralForUserForm);

