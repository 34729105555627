import React from "react";
import Modal, {ModalProps} from "./Modal";

const withModal = <P extends object>(Component: React.ComponentType<P>, defaultTitle?: string): React.FC<P & ModalProps> =>
    (props) => {

        const { title, open, onClose, paperStyle = {}, backdropProps, rootStyle, ...componentProps } = props;

        const ModalProps: ModalProps = {
            title: title || defaultTitle,
            onClose,
            open,
            paperStyle,
            backdropProps,
            rootStyle,
        };

        return (
            <Modal {...ModalProps}>
                <Component {...componentProps as P}/>
            </Modal>
        );
    };

export default withModal;
