import {Topic} from "../../../../models/ti/Topic";
import {useRbac} from "@gsb/react-rbac";
import {Action} from "material-table";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";
import {actions} from "../../../../rbac";

export const useTopicActions = (onCreate: () => void, onDelete: (topic: Topic) => void) => {
    const { can } = useRbac();
    const canCreateTopic = can(actions.topic.create);
    const canDeleteTopic = can(actions.topic.delete);

    const tableActions: Action<Topic>[] = [];

    if (canCreateTopic) tableActions.push(makeCreateAction(onCreate));

    if (canDeleteTopic) tableActions.push(makeDeleteAction(onDelete))

    return tableActions;
}