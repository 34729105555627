import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {DialogContentText, DialogTitle} from "@material-ui/core";

interface Props {
    open: boolean;
    title: string;
    confirmButtonTitle?: string;
    message?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

function ConfirmDialog(props: Props) {
    const {open, title, message, onCancel, onConfirm, confirmButtonTitle } = props;

    const classes = useStyles();

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>

                {message &&
                    <DialogContentText>{message}</DialogContentText>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} >
                    Cancel
                </Button>
                <Button onClick={onConfirm} className={classes.destructiveAction}>
                    {confirmButtonTitle || 'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles({
    destructiveAction: {
        color: "red"
    }
});

export function useConfirmDialog(title: string, message: string, onConfirm: () => void, confirmButtonTitle?: string){

    const [open, setOpen] = useState(false);

    const handleConfirm = () => {
        setOpen(false);
        onConfirm();
    }

    const dialog = <ConfirmDialog
        title={title}
        message={message}
        open={open}
        confirmButtonTitle={confirmButtonTitle}
        onConfirm={handleConfirm}
        onCancel={() => setOpen(false)}
    />;

    return {
        openConfirmDialog: () => setOpen(true),
        confirmDialog: dialog
    }
}

export function useDeleteDialog<T extends Object>(title: string, message: string, onDelete: (item: T) => void){

    const [entity, setEntity] = useState<T>();

    const handleConfirm = (item: T) => {
        setEntity(undefined);
        onDelete(item);
    }

    const dialog = entity ? (<ConfirmDialog
        title={title}
        message={message}
        open={true}
        confirmButtonTitle={'Delete'}
        onConfirm={() => handleConfirm(entity)}
        onCancel={() => setEntity(undefined)}
    />) : null;

    return {
        openDeleteDialog: (item: T) => setEntity(item),
        confirmDialog: dialog
    }
}