import {User} from '../../../models/User';
import TableColumnFactory, {ColumnFactory} from '../TableColumnFactory';
import {Reseller} from '../../../models/Reseller';
import {Company} from '../../../models/Company';
import {TableColumn} from '../../table/Table';
import config from "../../../config";

const makeUserNameColumns: TableColumn<User>[] = [
    {
        title: 'First Name',
        field: 'firstName',
    },
    {
        title: 'Last Name',
        field: 'lastName',
    },
];

export class UserTableColumnFactory implements ColumnFactory<User>{
    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<User>([
            TableColumnFactory.makeUserIdColumn(),
            ...makeUserNameColumns,
            TableColumnFactory.makeEmailColumn(),
            TableColumnFactory.fromProperty('Username', 'userName'),
            TableColumnFactory.makePhoneNumberColumn(),
            TableColumnFactory.makeCompanyNameColumn('Company', 'company.name', u => u.company),
            TableColumnFactory.makeSalesChannelColumn('Sales Channel', 'salesChannelId', u => u.salesChannel),
            TableColumnFactory.makeResellerColumn('Reseller', 'resellerId', u => u.reseller),
            TableColumnFactory.makeFileUploadMemberColumn(user => user.legalShieldMemberId),
            TableColumnFactory.makeAdpAssociateColumn(user => user.adpAssociateId),
            TableColumnFactory.makeActiveDirectoryColumn(user => user.subjectId),
        ]);
    }
}

export class CompanyUserTableColumnFactory implements ColumnFactory<User>{

    constructor(private company: Company) {}

    makeColumns = () => {
        const columns: TableColumn<User>[] = [
            TableColumnFactory.makeUserIdColumn(),
            TableColumnFactory.makeEmailColumn(),
            ...makeUserNameColumns,
            TableColumnFactory.dateColumn("Last Signed In At", "lastSignedInAt"),
            TableColumnFactory.makeActiveDirectoryColumn(user => user.subjectId),
        ]

        const { resellerId } = this.company;

        if (config.adp.resellers.includes(resellerId)) {
            columns.push(TableColumnFactory.makeAdpAssociateColumn<User>(u => u.adpAssociateId))
        } else if (resellerId === config.resellers.legalShield.id) {
            columns.push(TableColumnFactory.makeFileUploadMemberColumn<User>(u => u.legalShieldMemberId))
        }

        columns.push(...TableColumnFactory.makeTimestampColumns<User>())

        return columns;
    }
}
