import { Form, Formik } from "formik";
import React from "react";
import { useRepo } from "../../../../repos/useRepo";
import { AssignApplicationStatusRequest } from "../../../../models/sid/Application";
import { withModal } from "../../../modal";
import {  VerticalFormLayout } from "../../../form/FormLayout";
import { FormSubmitButton } from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler} from "../../../form/helpers/formSubmit";
import TextInput from "../../../form/inputs/TextInput";
import { Application } from "../../../../models/sid/Application";
import { StateIdApplicationStatusPicker } from "../../../form/pickers/StateIdApplicationStatusPicker";
import * as Yup from "yup";


function ApplicationAssignStatusFields(props: { disabled?: boolean })  {
    const { disabled } = props;
    return (<>
        <StateIdApplicationStatusPicker name='statusId' disabled={disabled} />
        <TextInput name='note.body' label='Note' multiline={true} rows={21} disabled={disabled} />
        </>)
}

export function ApplicationAssignStatusForm(props: { applicationId: string, onApplicationAssignUpdated: (application: Application) => void }) {
    const { applicationId, onApplicationAssignUpdated } = props;
    const { stateIdApplicationRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: AssignApplicationStatusRequest) => Promise.all([
            stateIdApplicationRepo.update(applicationId, { statusId: body.statusId }),
            stateIdApplicationRepo.createNote(applicationId, body.note)]),
        (res: any[]) => onApplicationAssignUpdated(res[0]),
        console.error
    );

    const initialValues = { statusId: '', note: { demeanor: 'n/a', body: '', status: 'N/A', memberId: '' } };
    const schema = Yup.object().shape({
        statusId: Yup.string().required('Application Status is required'),
        note: Yup.object().shape({
            body: Yup.string().required("Note is required"),
        })
    });

    return (
        <Formik<AssignApplicationStatusRequest> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        <Form>
            <VerticalFormLayout fieldset={<ApplicationAssignStatusFields/>}
                                bottomContent={<FormSubmitButton variant='Create' label='Save'/>}/>
        </Form>
    </Formik>
    )
}


export const ApplicationAssignStatusModal = withModal(ApplicationAssignStatusForm, "Assign Application(s) Status");