import {Form, Formik} from "formik";
import React from "react";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import {withModal} from "../../../modal";
import {updateSubmitHandler} from "../../../form/helpers/formSubmit";
import {useRepo} from "../../../../repos/useRepo";
import {SubscriptionFileRecord, UpdateSubscriptionFileRecordBody} from "../../../../models/SubscriptionFileRecord";
import TextInput from "../../../form/inputs/TextInput";

interface Props {
    record: SubscriptionFileRecord;
    onRecordUpdated: () => void;
}

const initialValuesForRecord = (record: SubscriptionFileRecord): UpdateSubscriptionFileRecordBody => {
    return {
        data: {
            adpClientId: record.data?.adpClientId ?? '',
            adpOrganizationId: record.data?.adpOrganizationId ?? '',
            legalShieldMemberId: record.data?.legalShieldMemberId ?? '',
            companyName: record.data?.companyName ?? '',
            lastName: record.data?.lastName ?? '',
            firstName: record.data?.firstName ?? '',
            addressLine1: record.data?.addressLine1 ?? '',
            addressLine2: record.data?.addressLine2 ?? '',
            city: record.data?.city ?? '',
            state: record.data?.state ?? '',
            zipCode: record.data?.zipCode ?? '',
            zipCodePlus4: record.data?.zipCodePlus4 ?? '',
            dayPhone: record.data?.dayPhone ?? '',
            eveningPhone: record.data?.eveningPhone ?? '',
            faxNumber: record.data?.faxNumber ?? '',
            emailAddress: record.data?.emailAddress ?? '',
        }
    }
}

function UpdateSubscriptionFileRecordForm({ record, onRecordUpdated }: Props) {
    const { subscriptionRepo } = useRepo()

    const onSubmit = updateSubmitHandler(
        (body: UpdateSubscriptionFileRecordBody) => subscriptionRepo.updateSubscriptionFileRecordById(record.id, body),
        initialValuesForRecord,
        onRecordUpdated,
        console.error,
    );

    return (
        <Formik<UpdateSubscriptionFileRecordBody>
            enableReinitialize={true}
            initialValues={initialValuesForRecord(record)}
            onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <TextInput name='data.adpClientId' label='ADP Client ID'/>
                <TextInput name='data.adpOrganizationId' label='ADP Organization ID'/>
                <TextInput name='data.legalShieldMemberId' label='Legal Shield Member ID'/>
                <TextInput name='data.firstName' label='First Name'/>
                <TextInput name='data.lastName' label='Last Name'/>
                <TextInput name='data.addressLine1' label='Address Line 1'/>
                <TextInput name='data.addressLine2' label='Address Line 2'/>
                <TextInput name='data.city' label='City'/>
                <TextInput name='data.state' label='State'/>
                <TextInput name='data.zipCode' label='Zip Code'/>
                <TextInput name='data.zipCodePlus4' label='Zip Code Extension'/>
                <TextInput name='data.dayPhone' label='Day Phone'/>
                <TextInput name='data.eveningPhone' label='Evening Phone'/>
                <TextInput name='data.faxNumber' label='Fax Number'/>
                <TextInput name='data.emailAddress' label='Email'/>

                <FormSubmitButton variant='Update' label='Retry'/>
            </Form>
        </Formik>
    )
}

export const UpdateSubscriptionFileRecordModal = withModal(UpdateSubscriptionFileRecordForm);
