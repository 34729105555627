import MailIcon from "@material-ui/icons/Mail";
import AccountIcon from "@material-ui/icons/AccountCircle";
import StripeIcon from "@material-ui/icons/CreditCard";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit"
import AddIcon from "@material-ui/icons/Add";
import BrandIcon from "@material-ui/icons/Label";
import SupervisedUserIcon from "@material-ui/icons/SupervisedUserCircle";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import GavelIcon from '@material-ui/icons/Gavel';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TransferWithinStationIcon from '@material-ui/icons/TransferWithinAStation';
import React from "react";
import { AssignmentInd, ChangeHistory } from "@material-ui/icons";

export const makeChangeCompanyResellerAction = (onClick: () => void) => ({
    name: 'Change Reseller',
    icon: <BrandIcon/>,
    onClick
});

export const makeUpdateUserPasswordAction = (onClick: () => void) => ({
    name: 'Change Password',
    icon: <VpnKeyIcon/>,
    onClick
});

export const makeCreateUserB2CAction = (onClick: () => void) => ({
    name: 'Create B2C Account',
    icon: <PersonAddIcon/>,
    onClick
});

export const makeLegalClubClientDetailAction = (onClick: () => void) => ({
    name: 'Legal Club Status',
    icon: <GavelIcon/>,
    onClick
});

export const makeImpersonateUserAction = (onClick: () => void) => ({
    name: 'Impersonate User',
    icon: <SupervisedUserIcon/>,
    onClick
});

export const makeMoveUserAction = (onClick: () => void) => ({
    name: 'Move User',
    icon: <TransferWithinStationIcon/>,
    onClick
});

export const makeSendPasswordResetAction = (onClick: () => void) => ({
    name: 'Send Password Reset Email',
    icon: <MailIcon/>,
    onClick
});

export const makeViewInActiveDirectoryAction = (subjectId: string, onClick: (subjectId: string) => void) => ({
    name: 'View in Active Directory',
    icon: <AccountIcon/>,
    onClick: () => onClick(subjectId)
});

export const makeViewInStripeAction = (onClick: () => void) => ({
    name: 'View in Stripe',
    icon: <StripeIcon/>,
    onClick: () => onClick()
});

export const makeDeleteAction = (onClick: () => void) => ({
    name: 'Delete',
    icon: <DeleteIcon />,
    onClick: () => onClick()
});

export const makeEditAction = (onClick: () => void) => ({
    name: 'Edit',
    icon: <EditIcon />,
    onClick: () => onClick()
});

export const makeAddAction = (onClick: () => void,name?: string) => ({
    name: name ?? 'Add',
    icon: <AddIcon />,
    onClick: () => onClick()
});

export const makeAssignAction = (onClick: () => void, name?: string) => ({
    name: name ?? 'Assign',
    icon: <AssignmentInd />,
    onClick: () => onClick()
});

export const makeAssignStatusAction = (onClick: () => void, name?: string) => ({
    name: name ?? 'Change Status',
    icon: <ChangeHistory />,
    onClick: () => onClick()
});