import {Form, Formik, FormikHelpers} from "formik";
import React, {useEffect, useState} from "react";
import {User} from "../../models/User";
import {AssignUserToLicenseRequest} from "../../repos/user/UserRepo";
import {License} from "../../models/License";
import {FormSubmitButton} from "../form/buttons/FormSubmitButton";
import {useRepo} from "../../repos/useRepo";
import {
    assignUserToLicenseSchema,
    initialValuesForAssignUserToLicenseRequest
} from "../../utils/schemas/license-schema";
import NoUsersAvailable from "./NoUsersAvailable";
import LoadingIndicator from "../LoadingIndicator";
import {withModal} from "../modal";
import {Company} from "../../models/Company";
import {verticalFormLayout} from "../form/styles/layout";
import {UserPicker} from "../form/pickers/UserPicker";
import { createSubmitHandler } from "../form/helpers/formSubmit";

interface Props {
    users?: User[];
    initialValues: AssignUserToLicenseRequest;
    onSubmit: (values: AssignUserToLicenseRequest, {}: FormikHelpers<AssignUserToLicenseRequest>) => void;
}

function AssignUserToLicenseForm({ initialValues, users, onSubmit }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={assignUserToLicenseSchema} onSubmit={onSubmit}>
            <Form style={verticalFormLayout}>

                <UserPicker name='userId' users={users || []}/>

                <FormSubmitButton variant='Update' label='Assign'/>
            </Form>
        </Formik>
    )
}

interface UpdateUserFormProps {
    company: Company;
    license: License;
    onLicensesAssigned: (licenses: License[]) => void;
}

export function AssignUserToLicenseForCompanyForm({ company, license, onLicensesAssigned }: UpdateUserFormProps) {
    const { userRepo, companyRepo } = useRepo();

    const [availableUsers, setAvailableUsers] = useState<User[]>();

    useEffect(() => {
        companyRepo.listAvailableUsersForCompanyLicenseById(company.id, license.id).then(setAvailableUsers).catch(console.error);
    }, [companyRepo]);

    const onSubmit = createSubmitHandler(
      (body: AssignUserToLicenseRequest) => userRepo.assignLicensesForUserById(body.userId, { licenseIds: [license.id] }),
      onLicensesAssigned,
      console.error
    );

    if (availableUsers) {
        const content = availableUsers.length > 0
            ? <AssignUserToLicenseForm initialValues={initialValuesForAssignUserToLicenseRequest()} onSubmit={onSubmit} users={availableUsers}/>
            : <NoUsersAvailable/>;

        return (
            <div style={{ marginTop: 12, marginBottom: 12 }}>
                {content}
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export const AssignUserToLicenseForCompanyModal = withModal(AssignUserToLicenseForCompanyForm);

