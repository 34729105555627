import {Reseller} from "../../models/Reseller";
import ResellerRepo from "./ResellerRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../models";
import {ListQuery} from "../CrudRepo";
import {sanitize} from "../../utils/FormUtils";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';

export default class HttpResellerRepo extends HttpCrudRepo<Reseller> implements ResellerRepo {
    pathComponents = ['mk', 'v1', 'resellers'];

    list(query: ListQuery): Promise<CollectionResponse<Reseller>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "salesChannel";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }
    update(id: string, resource: Partial<Reseller>): Promise<Reseller> {
        return this.http.put([...this.pathComponents, id], sanitize(resource))
    }

    listAll(): Promise<Reseller[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<Reseller>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
