import * as React from "react";
import {useState} from "react";
import {Plan} from "../../models/Plan";
import {UpdatePlanModal} from "../entities/billing/plans/PlanForm";

export default function useUpdatePlanModal(onPlanUpdated: (plan: Plan) => void) {

    const [plan, setPlan] = useState<Plan>();

    const handlePlanUpdated = (plan: Plan) => {
        setPlan(undefined);
        onPlanUpdated(plan)
    };

    const modal = plan ? (
        <UpdatePlanModal
            open={true}
            onClose={() => setPlan(undefined)}
            plan={plan}
            onPlanUpdated={handlePlanUpdated}
        />
    ) : null;

    return { setPlanForUpdate: setPlan, updatePlanModal: modal }
}
