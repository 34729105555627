import {Button, CircularProgress} from "@material-ui/core";
import * as React from "react";
import {CSSProperties, useMemo} from "react";
import {useFormikContext} from "formik";
import {makeStyles} from "@material-ui/core/styles";
import SuccessIcon from '@material-ui/icons/Check';

export type FormVariant = 'Create' | 'Update';

export interface FormSubmitButtonProps {
    label?: string;
    variant: FormVariant;
    disabled?: boolean;
    style?: CSSProperties;
}

export function FormSubmitButton({ label, variant, disabled, style }: FormSubmitButtonProps) {
    const { isSubmitting, dirty, status } = useFormikContext();
    const classes = useStyles();

    const success = useMemo(() => status?.success === true && !dirty, [status, dirty])
    const labelText = success ? 'Success' : (label || variant);
    const disableButton = (disabled !== undefined) ? disabled || isSubmitting : isSubmitting || !dirty;

    return (
        <div className={classes.wrapper}>
            <Button variant='contained'
                    style={style}
                    color={variant === 'Create' ? 'secondary' : 'primary'}
                    type='submit'
                    startIcon={success ? <SuccessIcon/> : undefined}
                    disabled={disableButton}>
                {labelText}</Button>
            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
