import { Company } from "../../../../models/Company";
import * as React from "react";
import { UpdateCompanyForm } from "../CompanyForm";
import CompanyNotesTimeline from "../CompanyNotesTimeline";

export default function CompanyTab(props: { company: Company, onCompanyUpdated: (company: Company) => void }) {
    const { company, onCompanyUpdated } = props;

    return (
        <div style={{ display: "flex", flex: 1}}>
            <div style={{flex:1, order: 2}}><CompanyNotesTimeline company={company} /></div>
            <div style={{width: "415px", order: 1}}><UpdateCompanyForm company={company} onCompanyUpdated={onCompanyUpdated} /></div>
        </div>
    )
}
