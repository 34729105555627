const adp = {
    resellers: [
        '1ed358b0-f484-4448-b162-48c132433606', // ADP
        'dccda43a-e074-49af-ace7-ec6c55eb4412'  // ADP Marketing
    ],
    products: [
        {
            id: 'eb19714d-2568-41ba-bf7b-c0ac38ade677',
            identifier: 'hr-pro',
            name: 'Upnetic BizPro'
        }, {
            id: 'f0a9ead6-9c3b-4ee2-8a7c-62897663ec18',
            identifier: 'marketing',
            name: 'Upnetic Marketing Services'
        }]
}

export default adp;