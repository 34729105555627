import {CreateSalesChannelRequest, SalesChannel, UpdateSalesChannelRequest} from "../../../../../models/SalesChannel";
import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../../../repos/useRepo";
import {updateSalesChannelSchema, initialValuesForSalesChannelUpdate} from "../../../../../utils/schemas/sales-channel-schema";
import SalesChannelFields from "../Fields";
import {FormSubmitButton, FormVariant} from "../../../../form/buttons/FormSubmitButton";
import { updateSubmitHandler } from "../../../../form/helpers/formSubmit";

type SalesChannelRequest = CreateSalesChannelRequest | UpdateSalesChannelRequest;

interface Props {
    isReadOnly?: boolean;
    submitButtonVariant: FormVariant;
    initialValues: SalesChannelRequest;
    salesChannel?: SalesChannel;
    validationSchema: any;
    onSubmit: (values: SalesChannelRequest, actions: FormikHelpers<SalesChannelRequest>) => void;
}

function SalesChannelForm({ submitButtonVariant, initialValues, salesChannel, validationSchema, onSubmit, isReadOnly }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <SalesChannelFields salesChannel={salesChannel} disabled={isReadOnly}/>

                <FormSubmitButton variant={submitButtonVariant} disabled={isReadOnly}/>

            </Form>
        </Formik>
    )
}

export function UpdateSalesChannelForm(props: { salesChannel: SalesChannel, onSalesChannelUpdated: (salesChannel: SalesChannel) => void }) {
    const { salesChannel, onSalesChannelUpdated }  = props;
    const { salesChannelRepo } = useRepo();

    const onSubmit = updateSubmitHandler<SalesChannelRequest, SalesChannel>(
        body => salesChannelRepo.update(salesChannel.id, body),
        initialValuesForSalesChannelUpdate,
        onSalesChannelUpdated,
        console.error,

    );

    return (
        <SalesChannelForm initialValues={initialValuesForSalesChannelUpdate(salesChannel)}
                          salesChannel={salesChannel}
                          validationSchema={updateSalesChannelSchema}
                          onSubmit={onSubmit}
                          submitButtonVariant='Update'/>
    )
}
