import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React from "react";
import { subscriptionFileRecordStatuses } from "../../../config/subscription-record-statuses";

export default function SubscriptionFileRecordStatusDropdown(props: FilterComponentProps<any>) {
    const options = Object.entries(subscriptionFileRecordStatuses).map(([key, value]) => ({
        id: key,
        name: value
    }));

    return (
        <DropdownPicker options={options} {...props}/>
    )
}