import * as React from "react";
import {SenderPicker} from "../../../form/pickers/SenderPicker";
import {TriggerPicker} from "../../../form/pickers/TriggerPicker";
import {SalesChannelPicker} from "../../../form/pickers/SalesChannelPicker";
import {ResellerPicker} from "../../../form/pickers/ResellerPicker";
import TextInput from "../../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
}

export function UpdateTemplateFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <TextInput name='externalTemplateId' label='SendGrid Template ID' disabled={disabled}/>

            <SenderPicker name='senderId' label='From' disabled={disabled}/>

            <TriggerPicker name='triggerId' disabled={disabled}/>

            <SalesChannelPicker name='salesChannelId' disabled={disabled}/>

            <ResellerPicker name='resellerId' disabled={disabled}/>
        </>
    )
}

export function CreateTemplateFields({ disabled }: Props) {
    return (
        <>
            <UpdateTemplateFields disabled={disabled}/>
        </>
    )
}
