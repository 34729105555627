import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useEffect, useState} from "react";
import {useRbac} from "@gsb/react-rbac";
import TabNavigation from "../../../tabs/TabNavigation";
import {Paper} from "@material-ui/core";
import {useRepo} from "../../../../repos/useRepo";
import {SalesChannel} from "../../../../models/SalesChannel";
import {actions} from "../../../../rbac";
import LoadingIndicator from "../../../LoadingIndicator";
import ApplicationsTab from "./tabs/ApplicationsTab";
import SalesChannelTab from "./tabs/SalesChannelTab";
import ResellersTab from "./tabs/ResellersTab";
import {useNavigation} from "../../../../services/navigation/useNavigation";
import {Breadcrumbs} from "../../../layout/Breadcrumbs";
import {BreadcrumbFactory, SalesChannelDetailBreadcrumbFactory} from "../../../../factories/BreadcrumbFactory";
import {useMemo} from "react";

interface Params {
    salesChannelId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
  actions.salesChannel.get,
]

function SalesChannelDetail(props: Props) {
    const [salesChannel, setSalesChannel] = useState<SalesChannel>();
    const { salesChannelRepo } = useRepo();

    const { can } = useRbac();
    const canListSalesChannels = can(actions.salesChannel.list);
    const canListResellers = can(actions.reseller.list);
    const canListApplications = can(actions.application.list);
    const shouldShowList = canListSalesChannels || canListResellers;

    const { navigateToReseller, navigateToSalesChannels } = useNavigation();

    const salesChannelId = useMemo(() => props.match.params.salesChannelId, [props.match.params.salesChannelId]);

    const loadSalesChannel = () => {
        salesChannelRepo.getById(salesChannelId).then(setSalesChannel).catch(console.error);
    };

    useEffect(loadSalesChannel, [salesChannelRepo, salesChannelId]);

    if (salesChannel) {

        const breadcrumbFactory: BreadcrumbFactory = new SalesChannelDetailBreadcrumbFactory(salesChannel, navigateToSalesChannels);

        return (
            <div>
                <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

                {shouldShowList &&
                <Paper>
                  <TabNavigation items={[
                      {
                          name: 'Sales Channel',
                          identifier: 'sales-channel',
                          render: () => <SalesChannelTab salesChannel={salesChannel} onSalesChannelUpdated={() => loadSalesChannel()}/>,
                          hidden: !can(actions.salesChannel.get),
                      },
                      {
                          name: 'Resellers',
                          identifier: 'resellers',
                          render: () => <ResellersTab salesChannel={salesChannel} onResellerSelected={navigateToReseller}/>,
                          hidden: !canListResellers,
                      },
                      {
                          name: 'Applications',
                          identifier: 'applications',
                          render: () => <ApplicationsTab salesChannel={salesChannel}/>,
                          hidden: !canListApplications,
                      },
                  ]}/>
                </Paper>
                }

            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export default withRouter(SalesChannelDetail);