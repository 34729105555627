import * as React from "react";
import {useState} from "react";
import { Application } from "../../models/sid/Application";
import { ApplicationNote } from "../../models/sid/ApplicationNote";
import {CreateStateIdApplicationNoteModal} from "../entities/state-id/applications/notes/ApplicationNoteForm";

export default function useCreateStateIdApplicationNoteModal(application: Application, onApplicationNoteCreated: (applicationNote: ApplicationNote) => void) {

    const [open, setOpen] = useState<{open: boolean, threadId?: string}>({ open: false});

    const handleApplicationNoteCreated = (applicationNote: ApplicationNote) => {
        setOpen({ open: false});
        onApplicationNoteCreated(applicationNote)
    };

    const modal = (
        <CreateStateIdApplicationNoteModal
            open={open.open}
            onClose={() => setOpen({ open: false})}
            companyId={application.member?.companyId ?? null}
            applicationId={application.id}
            onApplicationNoteCreated={handleApplicationNoteCreated}

            threadId={open.threadId}
        />
    );

    return { openCreateStateIdApplicationNoteModal: setOpen, createStateIdApplicationNoteModal: modal }
}
