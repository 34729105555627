import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useRepo } from "../../../../../repos/useRepo";
import { initialValuesForStateApplication, stateApplicationFieldSchema } from "../../../../../utils/schemas/state-id-schema";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../../rbac";
import { withModal } from "../../../../modal";
import { VerticalFormLayout } from "../../../../form/FormLayout";
import { CreateStateApplicationFields, UpdateStateApplicationFields } from "./StateApplicationFields";
import { FormSubmitButton } from "../../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../../form/helpers/formSubmit";
import { CreateStateApplicationRequest, StateApplication, UpdateStateApplicationRequest } from "../../../../../models/sid/StateApplication";

export function UpdateStateApplicationForm(props: { stateApplication: StateApplication, onStateApplicationUpdated: (stateApplication: StateApplication) => void }) {
    const { stateApplication, onStateApplicationUpdated } = props;
    const { stateApplicationRepo } = useRepo();

    const { can } = useRbac();
    const isReadOnly = !can(actions.stateApplication.update);

    const onSubmit = updateSubmitHandler<UpdateStateApplicationRequest, StateApplication>(
        body => stateApplicationRepo.update(stateApplication.id, body),
        initialValuesForStateApplication,
        onStateApplicationUpdated,
        console.error,
    );

    return (
        <Formik<UpdateStateApplicationRequest> enableReinitialize={true} initialValues={initialValuesForStateApplication(stateApplication)} validationSchema={stateApplicationFieldSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateStateApplicationFields disabled={isReadOnly} />}
                    bottomContent={<FormSubmitButton variant='Update' />} />
            </Form>
        </Formik>
    )
}

export function CreateStateApplicationForm(props: { onStateApplicationCreated: (stateApplication: StateApplication) => void }) {
    const { onStateApplicationCreated } = props;
    const { stateApplicationRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateStateApplicationRequest) => stateApplicationRepo.create(body),
        onStateApplicationCreated,
        console.error
    );

    return (
        <Formik<CreateStateApplicationRequest> initialValues={initialValuesForStateApplication()} validationSchema={stateApplicationFieldSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateStateApplicationFields />}
                    bottomContent={<FormSubmitButton variant='Create' />} />
            </Form>
        </Formik>
    )
}


export const UpdateStateApplicationModal = withModal(UpdateStateApplicationForm, "Update State Application");
export const CreateStateApplicationModal = withModal(CreateStateApplicationForm, "Create State Application");