import AdpRepo, {AdpEventBody} from "./AdpRepo";
import HttpService from "@gsb/react-http";

export default class HttpAdpRepo implements AdpRepo {
    pathComponents = ['mk', 'v1', 'adp-subscriptions'];

    constructor(private http: HttpService) {}

    activeOrCancelAdpSubscription(body: AdpEventBody): Promise<void> {
        return this.http.patch(this.pathComponents, body);
    }
}
