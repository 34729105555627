import React from "react";
import { makeAddAction, makeDeleteAction, makeEditAction } from "../../../../../factories/MenuItemFactory";
import { ApplicationNote } from "../../../../../models/sid/ApplicationNote";
import { MenuButton, useMenu } from "../../../../menu/Menu";

interface Props {
    applicationNote: ApplicationNote;
    onDeleteClicked?: (applicationNote: ApplicationNote) => void;
    onEditClicked?: (applicationNote: ApplicationNote) => void;
    onAddThreadClicked?: (applicationNote: ApplicationNote) => void;
}

const makeApplicationNoteMenuItems = (applicationNote: ApplicationNote, onDeleteClicked?: (applicationNote: ApplicationNote) => void, onEditClicked?: (applicationNote: ApplicationNote) => void, onAddThreadClicked?: (applicationNote: ApplicationNote) => void) => {
    const items = [];
    if (onEditClicked) {
        items.push(makeEditAction(() => onEditClicked(applicationNote)));
    }
    if (onAddThreadClicked && !applicationNote.threadId) {
        items.push(makeAddAction(() => onAddThreadClicked(applicationNote), 'Add Thread'));
    }
    if (onDeleteClicked) {
        items.push(makeDeleteAction(() => onDeleteClicked(applicationNote)));
    }
    return items;
}

export function ApplicationNoteMenuButton({ applicationNote, onDeleteClicked, onAddThreadClicked, onEditClicked }: Props) {
    const menuItems = makeApplicationNoteMenuItems(applicationNote, onDeleteClicked, onEditClicked, onAddThreadClicked);
    return (
        <MenuButton items={menuItems} />
    )
}

export function useApplicationNoteMenu(onDeleteClicked?: (applicationNote: ApplicationNote) => void, onEditClicked?: (applicationNote: ApplicationNote) => void, onAddThreadClicked?: (applicationNote: ApplicationNote) => void) {
    const { menu, openMenu } = useMenu();

    return {
        applicationNoteMenu: menu,
        openApplicationNoteMenu: (element: HTMLElement, applicationNote: ApplicationNote) => {
            const items = makeApplicationNoteMenuItems(applicationNote, onDeleteClicked, onEditClicked, onAddThreadClicked);
            openMenu(element, items)
        }
    }
}
