const salesChannels = {
    gsb: {
        id: "d4ff0a55-3061-4b5d-915a-07c7c2daaf04",
        name: "GoSmallBiz",
        identifier: "gsb",
    },
    upnetic: {
        id: "8c0206a1-ec06-4919-9311-e2d86071af8f",
        name: "Upnetic",
        identifier: "upnetic",
    }
};

export default salesChannels;
