import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {State} from "../../../models/State";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export default function StatePicker({ ...PickerProps }: Props) {
    const { stateRepo } = useRepo();

    const [states, setStates] = useState<State[]>([]);

    useEffect(() => {
        stateRepo.list().then(setStates).catch(console.error);
    }, [stateRepo]);

    const options = useMemo(() => states.map(s => ({ id: s.code, label: s.name })), [states]);

    return <PickerInput label={'State'} options={options} {...PickerProps} />
}