import { Form, Formik } from "formik";
import React from "react";
import { useRepo } from "../../../../repos/useRepo";
import { AssignApplicationAgentRequest } from "../../../../models/sid/Application";
import { withModal } from "../../../modal";
import { VerticalFormLayout } from "../../../form/FormLayout";
import { FormSubmitButton } from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler } from "../../../form/helpers/formSubmit";
import * as Yup from "yup";
import { StateIdConsultantPicker } from "../../../form/pickers/StateIdConsultantPicker";


export function ApplicationAssignAgentForm(props: { applicationIds: string[], onApplicationAssignUpdated: () => void }) {
    const { applicationIds, onApplicationAssignUpdated } = props;
    const { stateIdApplicationRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: AssignApplicationAgentRequest) => stateIdApplicationRepo.assignToAgent({ ...body, applicationIds }),
        onApplicationAssignUpdated,
        console.error
    );

    const initialValues = { assignedTo: '', applicationIds: [] };
    const schema = Yup.object().shape({
        assignedTo: Yup.string().required('Assign Agend is required'),
    });

    return (
        <Formik<AssignApplicationAgentRequest> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<StateIdConsultantPicker name='assignedTo' label='Assign Agent' />}
                    bottomContent={<FormSubmitButton variant='Create' label='Assign' />} />
            </Form>
        </Formik>
    )
}


export const ApplicationAssignAgentModal = withModal(ApplicationAssignAgentForm, "Assign Application(s) Agent");