const values = {
    submitted: {
        id: "b5a9a55d-ed80-4480-a362-4c318dedd5ba",
        index: "0,",
        identifier: "submitted",
        name: "Submitted",
    },
    researching: {
        id: "788a84a9-4d47-499e-9a53-f730372228f8",
        index: "1,",
        identifier: "researching",
        name: "Researching",
    },
    hold: {
        id: "c91d4102-2a14-43cc-b07b-947e3f937ec7",
        index: "2,",
        identifier: "on-hold",
        name: "On Hold",
    },
    reviewing: {
        id: "378653a7-b98a-48f3-ad8d-eacbda0a2dc8",
        index: "3,",
        identifier: "reviewing",
        name: "In Review",
    },
    approved: {
        id: "3a655299-5807-4fbd-9e63-f9d9ba5152b6",
        index: "4,",
        identifier: "approved",
        name: "Pending Release",
    },
    released: {
        id: "b1046197-8819-4880-bfa0-59cedce65536",
        index: "5,",
        identifier: "released",
        name: "Released",
    },
};


export default values