import * as React from "react";
import {useState} from "react";
import {Template} from "../../models/mail/Template";
import {UpdateTemplateModal} from "../entities/mail/templates/TemplateForm";

export default function useUpdateTemplateModal(onTemplateUpdated: (template: Template) => void) {

    const [template, setTemplate] = useState<Template>();

    const handleTemplateUpdated = (template: Template) => {
        setTemplate(undefined);
        onTemplateUpdated(template)
    };

    const modal = template ? (
        <UpdateTemplateModal
            open={true}
            onClose={() => setTemplate(undefined)}
            template={template}
            onTemplateUpdated={handleTemplateUpdated}
        />
    ) : null;

    return { setTemplateForUpdate: setTemplate, updateTemplateModal: modal }
}
