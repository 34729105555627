import React, { useEffect, useState, useMemo } from "react";
import { useRepo } from "../../../repos/useRepo";
import { Company, CompanyNote } from "../../../models/Company";
import useCreateCompanyNoteModal from "../../modals/useCreateCompanyNoteModal";
import useUpdateCompanyNoteModal from "../../modals/useUpdateCompanyNoteModal"
import { Timeline } from '@material-ui/lab';
import { makeStyles, IconButton } from "@material-ui/core";
import { AddBox } from "@material-ui/icons"
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../rbac";
import CompanyTimelineNote from "./notes/CompanyTimelineNote";


interface Props {
    company: Company
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '12px',
        cursor: 'pointer'
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
    timelineHeader: {
        display: "flex", 
        overflow: "hidden", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        padding: "0 20px" 
    },
    timelineHeaderTitle: {
        flex: 2, 
        textAlign: 'right',
        '& h2': {
            marginLeft: '12px'
        }
    },
    timelineContainer: {
        maxHeight: '800px', 
        overflowY: 'auto' 
    },
    footer: {
        textAlign: "right"
    }
}));

export default function CompanyNotesTimeline({ company }: Props) {
    const { companyRepo } = useRepo();
    const [companyNotes, setCompanyNotes] = useState<CompanyNote[]>();

    const reloadData = () => {
        companyRepo.recentNotesForCompanyById(company.id).then(setCompanyNotes).catch(console.error);
    }

    const { createCompanyNoteModal, setCreateCompanyNoteModalOpen } = useCreateCompanyNoteModal(company.id, reloadData);
    const { updateCompanyNoteModal, setCompanyNoteForUpdate } = useUpdateCompanyNoteModal(company.id, reloadData);

    const { can } = useRbac();
    const canDeleteCompanyNote = can(actions.company.deleteNote);
    const canUpdateCompanyNote = can(actions.company.updateNote);
    const canCreateCompanyNote = can(actions.company.createNote);

    useEffect(reloadData, [company.id]);

    const classes = useStyles();
    const loading = useMemo(() => !companyNotes, [companyNotes]);

    const handleCompanyNoteDelete = async (note: CompanyNote) => {
        const userRes = window.confirm('Are you sure you want to delete this item?');
        if (userRes) {
            companyRepo.deleteNoteForCompanyById(company.id, note.id).then(reloadData).catch(console.error);
        }
        return;
    }

    return (
        <>
            <div>
                <div className={classes.timelineHeader}>
                    <div className={classes.timelineHeaderTitle}>
                        <h2>Recent Company Notes</h2>
                    </div>
                    <div>
                        {canCreateCompanyNote && (
                        <IconButton aria-label="add" onClick={() => setCreateCompanyNoteModalOpen({open: true})}><AddBox fontSize="large" /></IconButton>
                        )}
                    </div>
                </div>
                <div>
                    {!loading ? (<Timeline align={"alternate"} >
                        {companyNotes && companyNotes.map((note, index) => (
                            <CompanyTimelineNote key={index}
                                note={note}
                                isAlternate={!(index % 2 === 0)}
                                isLast={companyNotes.length - 1 === index}
                                onCompanyNoteEdit={setCompanyNoteForUpdate}
                                onCompanyNoteDelete={handleCompanyNoteDelete}
                                onCompanyNoteAddThread={(note) => setCreateCompanyNoteModalOpen({open: true, threadId: note.id})}
                                canDelete={canDeleteCompanyNote}
                                canEdit={canUpdateCompanyNote}
                                canAddThread={canCreateCompanyNote} />
                        ))}
                    </Timeline>) : (<></>)}
                </div>
            </div>
            {createCompanyNoteModal}
            {updateCompanyNoteModal}
        </>
    );
}