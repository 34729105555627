import {User} from "../../../../models/User";
import * as React from "react";
import {UpdateUserForm} from "../UserForm";

export default function UserTab(props: { user: User, onUserUpdated: (user: User) => void }) {
    const { user, onUserUpdated } = props;
    return (
        <div>
            <UpdateUserForm user={user} onUserUpdated={onUserUpdated}/>
        </div>
    )
}
