import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Card} from "@material-ui/core";
import {Question} from "../../../models/ti/Question";
import DateFormatter from "../../../utils/DateFormatter";

export default function PreviousQuestionList(props: { questions: Question[], onQuestionClicked: (question: Question) => void }) {
    const { questions, onQuestionClicked } = props;

    return (
        <div>
            {questions.map(question => {
                const detail = "Asked " + DateFormatter.formatDate(question.createdAt, "MM/DD/YYYY") + (question.member ? " by " + question.member.firstName + " " + question.member.lastName : "");
                return (
                    <PreviousQuestionItem key={question.id}
                                          title={question.title || question.body}
                                          detail={detail}
                                          onClick={() => onQuestionClicked(question)} />
                    );
            })}
        </div>
    );
}

function PreviousQuestionItem(props: { title: string, detail?: string, onClick: () => void }) {
    const { title, detail, onClick } = props;

    return (
      <Card style={{ margin: "6px", marginBottom: "10px" }}>
          <ListItem button onClick={() => onClick()}>
              <ListItemText primary={title} secondary={detail} />
          </ListItem>
      </Card>
    );
}

