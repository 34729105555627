import React from 'react'
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Theme} from "@material-ui/core";
import StatusBar from "./StatusBar";
import Title from "./Title";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {Question} from "../../../models/ti/Question";
import TextInput from "../../form/old/TextInput";
import HTML from "../../form/old/HTML";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 16,
            minWidth: "560px"
        },
        content: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            [theme.breakpoints.down('xs')]: {
                flexWrap: "wrap",
            }
        }
    })
);

export default function ViewQuestion(props: { question: Question, onEditQuestionClicked?: () => void }) {
    const { question, onEditQuestionClicked } = props;
    const { title, body, answer, notes } = question;
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <div>
                <div className={classes.content}>
                    <div style={{ marginRight: 22, minWidth: "300px", width: "100%"}}>

                        <div style={{ display: "flex", flexDirection: "column" }}>

                            {onEditQuestionClicked && 
                            <Button variant="contained"
                                    color="primary"
                                    onClick={() => onEditQuestionClicked()}
                                    style={{ alignSelf: "flex-end", marginBottom: "12px"}}>Edit</Button>}

                            <StatusBar question={question}/>

                            <TextInput title="Title" value={title || '-'} isReadOnly={true}/>

                            <TextInput title="Question" value={body} isReadOnly={true}/>

                            <Title title="Notes"/>

                            <Divider/>

                            <div style={{ padding: "6px", marginBottom: "8px", marginTop: "4px"}}>
                                <HTML rawHtml={notes || '-'}/>
                            </div>

                            <Title title="Answer"/>

                            <Divider/>

                            <div style={{ padding: "6px", marginBottom: "8px", marginTop: "4px"}}>
                                <HTML rawHtml={answer || '-'}/>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </Paper>
    );
}