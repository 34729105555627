import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import SenderFields from "./SenderFields";
import {CreateSenderRequest, Sender, UpdateSenderRequest} from "../../../../models/mail/Sender";
import {initialValuesForSender, createSenderSchema, updateSenderSchema} from "../../../../utils/schemas/sender-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdateSenderForm(props: { sender: Sender, onSenderUpdated: (sender: Sender) => void }) {
    const { sender, onSenderUpdated }  = props;
    const { senderRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.sender.update);

    const onSubmit = updateSubmitHandler<UpdateSenderRequest, Sender>(
        (body: UpdateSenderRequest) => senderRepo.update(sender.id, body),
        initialValuesForSender,
        onSenderUpdated,
        console.error,
    );

    return (
        <Formik<UpdateSenderRequest>
            enableReinitialize={true}
            initialValues={initialValuesForSender(sender)}
            validationSchema={updateSenderSchema}
            onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<SenderFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateSenderForm(props: { onSenderCreated: (sender: Sender) => void }) {
    const { onSenderCreated }  = props;
    const { senderRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateSenderRequest) => senderRepo.create(body),
        onSenderCreated,
        console.error
    );

    return (
        <Formik<CreateSenderRequest> initialValues={initialValuesForSender()} validationSchema={createSenderSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<SenderFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}


export const UpdateSenderModal = withModal(UpdateSenderForm, "Update Sender");
export const CreateSenderModal = withModal(CreateSenderForm, "Create Sender");