import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {SubscriptionFileType} from "../../../models/SubscriptionFileType";

export default function SubscriptionFileTypeDropdown(props: FilterComponentProps<any>) {

    const { subscriptionRepo } = useRepo();
    const [fileTypes, setFileTypes] = useState<SubscriptionFileType[]>([]);

    useEffect(() => {
        subscriptionRepo.listAllSubscriptionFileTypes().then(setFileTypes).catch(console.error);
    }, [subscriptionRepo]);

    return (
        <DropdownPicker options={fileTypes} {...props}/>
    )
}