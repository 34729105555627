import {Application} from "../../models/Application";
import ApplicationRepo from "./ApplicationRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../models";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';
import {sanitize} from "../../utils/FormUtils";

export default class HttpApplicationRepo extends HttpCrudRepo<Application> implements ApplicationRepo {
    pathComponents = ['mk', 'v1', 'applications'];

    create(body: object): Promise<Application> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<Application>): Promise<Application> {
        return super.update(id, sanitize(resource));
    }

    listAll(): Promise<Application[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<Application>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
