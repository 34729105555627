import React, {useMemo} from 'react';
import './App.css';
import { RouteComponentProps, matchPath, withRouter } from "react-router-dom";
import NavigationDrawer from "./components/layout/Drawer";
import SideNav, {NavigationItem} from "./components/layout/SideNav";
import {navigationGroups} from "./navigation-groups";
import {useServices} from "./services/useServices";
import {navigationItems} from "./navigation-items";
import {useHelpModal} from "./components/help/HelpModal";
import withAuthentication from "./services/auth/withAuthentication";
import {useAuthState} from "./services/auth/useAuthState";
import withStartup from "./AppStartup";
import { RbacRoutes } from './routes/RbacRoute';

interface Props extends RouteComponentProps {

}

function App(props: Props) {
  const { authService } = useServices();

  const { userInfo } = useAuthState();
  const { modal: helpModal, openWithIdentifier: openHelpModal } = useHelpModal();

  const selectedNavigationItemForPath = (path: string): NavigationItem | undefined => {
    return Object.values(navigationItems).find(item => {
      return matchPath(path, {
        path: item.route,
        exact: true,
        strict: false
      })
    });
  };

  const selectedNavigationItem = useMemo(() => selectedNavigationItemForPath([props.location.pathname, props.location.search].join('')), [props.location]);
  const title = selectedNavigationItem && selectedNavigationItem.title ? selectedNavigationItem.title : "Masterkey";

  const logout = () => authService.logout();

  return (
      <>
        <NavigationDrawer
            title={title}
            navigationItem={selectedNavigationItem}
            sideNav={<SideNav groups={navigationGroups}/>}
            userInfo={userInfo}
            onLogoutClicked={logout}
            onHelpClicked={(item) => openHelpModal(item.key)}
        >
          <RbacRoutes/>
        </NavigationDrawer>

        {helpModal}
      </>
  )
}

export default withAuthentication(withStartup(withRouter(App)));
