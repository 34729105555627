import {SalesChannel} from "../../../../../models/SalesChannel";
import * as React from "react";
import SalesChannelResellersTable from "../../resellers/SalesChannelResellersTable";
import {Reseller} from "../../../../../models/Reseller";

interface Props {
    salesChannel: SalesChannel;
    onResellerSelected: (reseller: Reseller) => void
}

export default function ResellersTab({ salesChannel, onResellerSelected }: Props) {
    return (
        <>
            <SalesChannelResellersTable salesChannel={salesChannel} onResellerSelected={onResellerSelected}/>
        </>
    )
}

