import React from "react"
import { Redirect, RouteProps } from "react-router-dom";
import Routes from "../Routes";
import {
  default as CompaniesList,
  requiredActions as companyListActions
} from "../components/entities/companies/CompanyList";
import {
  default as UsersList,
  requiredPermissions as UserListPermissions,
} from "../components/entities/users/UserList";
import {
  default as ApplicationsList,
  requiredActions as applicationListActions,
} from "../components/entities/applications/List";
import QuestionList, {
  requiredActions as questionListActions,
} from "../components/tarkenton-institute/questions/QuestionsList";
import AnswersList, {
  requiredActions as answersListActions,
} from "../components/tarkenton-institute/questions/AnswersList";
import FaqList, {
  requiredActions as faqListActions,
} from "../components/tarkenton-institute/faqs/FaqList";
import {
  Upload,
  requiredActions as uploadActions
} from "../components/entities/subscription/Upload";
import { Brands, requiredActions as brandActions } from "../components/entities/brands";
import Billing, { requiredActions as billingActions } from "../components/entities/billing";
import {
  Employees,
  requiredActions as employeesListActions,
} from "../components/entities/employees";
import TarkentonInstituteDashboard, {
  requiredActions as settingsActions,
} from "../components/tarkenton-institute/settings";
import {
  Mail,
  requiredActions as mailActions,
} from "../components/entities/mail";
import {
  Integrations, 
  requiredActions as integrationActions 
} from "../components/entities/integrations";

import { Reports, requiredActions as reportActions } from "../components/reports/Index";
import ApplicationDetail, {
  requiredActions as applicationDetailActions,
} from "../components/entities/applications/ApplicationDetail";
import ProductDetail, {
  requiredActions as productActions
} from "../components/entities/billing/products/ProductDetail";
import CompanyDetail, {
  requiredActions as companyDetailActions
} from "../components/entities/companies/CompanyDetail";
import SalesChannelDetail, {
  requiredActions as salesChannelActions,
} from "../components/entities/brands/sales-channels/SalesChannelDetail";
import ResellerDetail, {
  requiredActions as resellerActions,
} from "../components/entities/brands/resellers/ResellerDetail";
import EmployeeDetail, {
  requiredActions as employeeActions,
} from "../components/entities/employees/EmployeeDetail";
import UserDetail, {
  requiredActions as UserDetailActions
} from "../components/entities/users/UserDetail";
import QuestionDetail, {
  requiredActions as questionActions,
} from "../components/tarkenton-institute/questions/QuestionDetail";
import FaqDetail, {
  requiredActions as faqActions,
} from "../components/tarkenton-institute/faqs/FaqDetail";
import SiteDetail, {
  requiredActions as siteActions,
} from "../components/tarkenton-institute/settings/sites/SiteDetail";
import TopicDetail, {
  requiredActions as topicActions,
} from "../components/tarkenton-institute/settings/topics/TopicDetail";
import { StateIdSettings, requiredActions as stateIdSettingActions } from "../components/entities/state-id/settings/Index";
import { default as StateIdApplicationsList, requiredActions as stateIdApplicationActions } from "../components/entities/state-id/applications/ApplicationList"
import StateApplicationDetail, {requiredActions as stateApplicationDetailActions } from "../components/entities/state-id/settings/state-application/StateApplicationDetail";
import StateIdApplicationDetail, { requiredActions as stateIdApplicationDetailActions } from "../components/entities/state-id/applications/ApplicationDetail";

export type RouteConfig = RouteProps & {
  requiredActions?: Array<string>;
}

interface RouteConfigsType {
  [path: string]: RouteConfig;
}

export const RouteConfigs: RouteConfigsType = {
  [Routes.home]: {
    exact: true,
    render: () => <div />,
  },

  [Routes.applicationById]: {
    render: () => <ApplicationDetail />,
    requiredActions: applicationDetailActions,
  },
  [Routes.applications]: {
    render: () => <ApplicationsList />,
    exact: true,
    requiredActions: applicationListActions
  },

  [Routes.companyById]: {
    render: () => <CompanyDetail />,
    requiredActions: companyDetailActions,
  },
  [Routes.companies]: {
    render: () => <CompaniesList />,
    exact: true,
    requiredActions: companyListActions,
  },

  [Routes.subscriptionFileUpload]: {
    render: () => <Upload />,
    requiredActions: uploadActions,
  },

  [Routes.salesChannelById]: {
    render: () => <SalesChannelDetail />,
    requiredActions: salesChannelActions,
  },
  [Routes.resellerById]: {
    render: () => <ResellerDetail />,
    requiredActions: resellerActions,
  },
  [Routes.brands]: {
    render: () => <Brands />,
    requiredActions: brandActions,
  },

  [Routes.productById]: {
    render: () => <ProductDetail />,
    requiredActions: productActions,
  },
  [Routes.products]: {
    render: () => <Billing />,
    exact: true,
    requiredActions: billingActions,
  },

  [Routes.employeeById]: {
    render: () => <EmployeeDetail />,
    requiredActions: employeeActions,
  },
  [Routes.employees]: {
    render: () => <Employees />,
    exact: true,
    requiredActions: employeesListActions,
  },

  [Routes.userById]: {
    render: () => <UserDetail />,
    requiredActions: UserDetailActions,
  },
  [Routes.users]: {
    render: () => <UsersList />,
    exact: true,
    requiredActions: UserListPermissions,
  },

  [Routes.ti.questionById]: {
    render: () => <QuestionDetail />,
    requiredActions: questionActions,
  },

  [Routes.ti.questions]: {
    render: () => <QuestionList />,
    exact: true,
    requiredActions: questionListActions,
  },

  [Routes.ti.answers]: {
    render: () => <AnswersList />,
    requiredActions: answersListActions,
    exact: true,
  },

  [Routes.ti.faqById]: {
    render: () => <FaqDetail />,
    requiredActions: faqActions,
  },
  [Routes.ti.faqs]: {
    render: () => <FaqList />,
    requiredActions: faqListActions,
    exact: true,
  },

  [Routes.ti.settings.siteById]: {
    render: () => <SiteDetail />,
    requiredActions: siteActions,
  },
  [Routes.ti.settings.topicById]: {
    render: () => <TopicDetail />,
    requiredActions: topicActions,
  },
  [Routes.ti.settings.home]: {
    render: () => <TarkentonInstituteDashboard />,
    exact: true,
    requiredActions: settingsActions,
  },

  [Routes.mail.home]: {
    render: () => <Mail />,
    requiredActions: mailActions,
  },

  [Routes.reports]: {
    render: () => <Reports />,
    requiredActions: reportActions
  },

  [Routes.sid.settings.stateApplicationById]: {
    render: () => <StateApplicationDetail />,
    requiredActions: stateApplicationDetailActions
  },

  [Routes.sid.settings.home]: {
    render: () => <StateIdSettings />,
    requiredActions: stateIdSettingActions
  },

  [Routes.sid.stateIdApplications]: {
    render: () => <StateIdApplicationsList />,
    requiredActions: stateIdApplicationActions
  },

  [Routes.sid.stateIdApplicationById]: {
    render: () => <StateIdApplicationDetail />,
    requiredActions: stateIdApplicationDetailActions
  },

  [Routes.integrations]: {
    render: () => <Integrations />,
    requiredActions: integrationActions
  },

  "*": {
    render: () => <Redirect to={Routes.home} />
  }
}