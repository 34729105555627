import * as React from "react";

import {SalesChannel} from "../../../../models/SalesChannel";
import {DialogChildProps} from "../../EntityDialog";
import SalesChannelFields from "./Fields";
import {
    createSalesChannelSchema,
    initialValuesForSalesChannelCreate, initialValuesForSalesChannelUpdate, updateSalesChannelSchema
} from "../../../../utils/schemas/sales-channel-schema";
import Form from "../../../form/Form";

interface Props extends DialogChildProps {
    salesChannel?: SalesChannel;
    onValuesChange?: (draft: Partial<SalesChannel>) => void;
    isReadOnly: boolean
}

export default function Detail({ salesChannel, isReadOnly, ...formProps }: Props) {
    return (
        <Form
            disabled={isReadOnly}
            validationSchema={salesChannel ? updateSalesChannelSchema : createSalesChannelSchema}
            initialValues={salesChannel ? initialValuesForSalesChannelUpdate(salesChannel) : initialValuesForSalesChannelCreate()}
            {...formProps}
        >
            <SalesChannelFields salesChannel={salesChannel}/>
        </Form>
    )
}
