import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useEffect, useState } from "react";
import { Company } from "../../../models/Company";
import { useRepo } from "../../../repos/useRepo";
import LoadingIndicator from "../../LoadingIndicator";
import { Paper } from "@material-ui/core";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../rbac";
import TabNavigation, { TabNavigationItem } from "../../tabs/TabNavigation";
import SubscriptionsTab from "./tabs/subscriptions/SubscriptionsTab";
import UsersTab from "./tabs/UsersTab";
import ProfilesTab from "./tabs/ProfilesTab";
import CompanyTab from "./tabs/CompanyTab";
import CompanyActionBar from "./CompanyActionBar";
import { useNavigation } from "../../../services/navigation/useNavigation";
import LicensesTab from "./tabs/LicensesTab";
import WebsiteAnalysisTab from "./tabs/WebsiteAnalysisTab";
import MetadataTab from "./tabs/MetadataTab";
import CompanyNotesTab from "./tabs/NotesTab";
import {
    BreadcrumbFactory,
    CompanyDetailBreadcrumbFactory
} from "../../../factories/BreadcrumbFactory";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import useViewCompanyInStripeModal from "./view-in-stripe/useViewCompanyInStripeModal";
import useChangeCompanyResellerModal from "./change-reseller/useChangeCompanyResellerModal";
import { useConfirmDialog } from "../../dialog/ConfirmDialog";
import CompanyReferralsTab from './tabs/ReferralsTab';

interface Params {
    companyId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
    actions.company.get,
]

function CompanyDetail(props: Props) {
    const [company, setCompany] = useState<Company>();
    const { companyRepo } = useRepo();

    const { navigateToUser, navigateToCompanies, navigateToApplication } = useNavigation();

    const { can } = useRbac();
    const canListSalesChannels = can(actions.salesChannel.list);
    const canListResellers = can(actions.reseller.list);
    const shouldShowList = canListSalesChannels || canListResellers;
    const { companyId } = props.match.params;

    const loadCompany = () => {
        companyRepo.getById(companyId).then(setCompany).catch(console.error);
    };

    const handleCompanyDelete = () => companyRepo.delete(companyId).then(navigateToCompanies);

    const { openViewCompanyInStripeModal, viewCompanyInStripeModal } = useViewCompanyInStripeModal();
    const { openChangeCompanyResellerModal, createCompanyResellerModal } = useChangeCompanyResellerModal(setCompany);
    const { openConfirmDialog, confirmDialog } = useConfirmDialog('Delete Company', 'Are you sure you want to delete this company? All related company & user(s) data will be permanently deleted.  This action can NOT be reversed!', handleCompanyDelete);

    useEffect(loadCompany, [companyRepo, companyId]);

    if (company) {

        const items: TabNavigationItem[] = [
            {
                name: 'Profile',
                identifier: 'profile',
                render: () => <CompanyTab company={company} onCompanyUpdated={loadCompany} />
            },
            {
                name: 'Users',
                identifier: 'users',
                render: () => <UsersTab company={company} />,
                hidden: !can(actions.company.listUsers),
            },
            {
                name: 'Subscriptions',
                identifier: 'subscriptions',
                render: () => <SubscriptionsTab company={company} />,
                hidden: !can(actions.company.listSubscriptions),
            },
            {
                name: 'Licenses',
                identifier: 'licenses',
                render: () => <LicensesTab company={company} onUserSelected={navigateToUser} onApplicationSelected={navigateToApplication} />,
                hidden: !can(actions.company.listLicenses),
            },
            {
                name: 'Notes',
                identifier: 'notes',
                render: () => <CompanyNotesTab company={company} />,
                hidden: !can(actions.company.listNotes),
            },
            {
                name: 'Website Analysis',
                identifier: 'website-analysis',
                render: () => <WebsiteAnalysisTab company={company} />,
                hidden: !can(actions.company.listWebsiteAnalysis),
            },
            {
                name: 'Metadata',
                identifier: 'metadata',
                render: () => <MetadataTab company={company} />,
                hidden: !can(actions.company.listMetadata),
            },
            {
                name: 'Legal',
                identifier: 'legal-referrals',
                render: () => <CompanyReferralsTab company={company} />,
                hidden: !can(actions.company.listReferrals),
            },
            {
                name: 'Business Profiles',
                identifier: 'business-profiles',
                render: () => <ProfilesTab company={company} />
            }
        ];

        const breadcrumbFactory: BreadcrumbFactory = new CompanyDetailBreadcrumbFactory(company, navigateToCompanies);

        return (
            <>
                <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()} />

                <Paper style={{ marginBottom: 12 }}>
                    <CompanyActionBar company={company}
                        onViewInStripeClicked={() => openViewCompanyInStripeModal(company)}
                        onChangeResellerClicked={() => openChangeCompanyResellerModal(company)}
                        onDeleteClicked={openConfirmDialog} />
                </Paper>

                {shouldShowList &&
                    <Paper>
                        <TabNavigation items={items} />
                    </Paper>
                }

                {viewCompanyInStripeModal}
                {createCompanyResellerModal}
                {confirmDialog}
            </>
        );
    } else {
        return <LoadingIndicator />
    }
}

export default withRouter(CompanyDetail);