import * as React from "react";
import {withRouter, RouteComponentProps} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {Product} from "../../../../models/Product";
import {useRepo} from "../../../../repos/useRepo";
import {PlansTab} from "./tabs/PlansTab";
import {Paper} from "@material-ui/core";
import LoadingIndicator from "../../../LoadingIndicator";
import TabNavigation, {TabNavigationItem} from "../../../tabs/TabNavigation";
import {BreadcrumbFactory, ProductDetailBreadcrumbFactory} from "../../../../factories/BreadcrumbFactory";
import {Breadcrumbs} from "../../../layout/Breadcrumbs";
import {useNavigation} from "../../../../services/navigation/useNavigation";
import LicensesTab from "./tabs/LicensesTab";
import {UpdateProductForm} from "./ProductForm";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../rbac";

interface Params {
    productId: string;
}

interface Props extends RouteComponentProps<Params> {}

export const requiredActions = [
  actions.product.get,
];

function ProductDetail(props: Props) {

    const { navigateToProducts } = useNavigation();
    const { productRepo } = useRepo();
    const { can } = useRbac();

    const [product, setProduct] = useState<Product>();

    const productId = useMemo(() => props.match.params.productId, [props.match.params.productId]);

    useEffect(() => {
        productRepo.getById(productId).then(setProduct).catch(console.error);
    }, [productId]);

    if (product) {
        const items: TabNavigationItem[] = [
            {
                name: "Product",
                identifier: "product",
                render: () => <UpdateProductForm product={product} onProductUpdated={setProduct}/>,
                hidden: !can(actions.product.get),
            },
            {
                name: "Licenses",
                identifier: "licenses",
                render: () => <LicensesTab product={product}/>,
                hidden: !can(actions.productLicense.list),
            },
            {
                name: "Plans",
                identifier: "plans",
                render: () => <PlansTab product={product}/>,
                hidden: !can(actions.plan.list),
            },
        ];

        const breadcrumbFactory: BreadcrumbFactory = new ProductDetailBreadcrumbFactory(product, navigateToProducts);

        return (
            <>
                <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

                <Paper>
                    <TabNavigation items={items}/>
                </Paper>
            </>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export default withRouter(ProductDetail);