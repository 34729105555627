import SenderRepo from "./SenderRepo";
import {Sender} from "../../models/mail/Sender";
import {ListQuery} from "../CrudRepo";

export class InMemorySenderRepoCache implements SenderRepo {
    cachedSenderList?: Sender[];
    senderPromise?: Promise<Sender[]>;

    constructor(private inner: SenderRepo) {}

    async listAll(): Promise<Sender[]> {
        if (this.cachedSenderList) {
            return this.cachedSenderList;
        } else {
            if (this.senderPromise) {
                return this.senderPromise;
            } else {
                const sendersPromise = this.inner.listAll();
                this.senderPromise = sendersPromise;
                const senders = await sendersPromise;
                this.cachedSenderList = senders;
                this.senderPromise = undefined;
                return senders;
            }
        }
    };

    create = (resource: Partial<Sender>) => this.inner.create(resource);

    delete = (id: string) => this.inner.delete(id);

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<Sender>) => this.inner.update(id, resource);
}