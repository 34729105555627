import {UpdateSubscriptionFileTypeRequest, SubscriptionFileType} from "../../../../../models/SubscriptionFileType";
import {FormikHelpers} from "formik";
import SubscriptionRepo from "../../../../../repos/subscription/SubscriptionRepo";
import {SubscriptionFileTypeFormViewModel} from "./SubscriptionFileTypeFormViewModel";
import {FormVariant} from "../../../../form/buttons/FormSubmitButton";

export class UpdateFormSubscriptionFileTypeController implements SubscriptionFileTypeFormViewModel<UpdateSubscriptionFileTypeRequest> {

    submitButtonVariant: FormVariant = 'Update';

    initialValues: UpdateSubscriptionFileTypeRequest;

    constructor(private subscriptionFileType: SubscriptionFileType,
                private subscriptionRepo: SubscriptionRepo,
                private onSubscriptionFileTypeUpdated: (subscriptionFileType: SubscriptionFileType) => void) {
        this.initialValues = this.initialValuesFromSubscriptionFileType(subscriptionFileType);
    }

    onSubmit = (values: UpdateSubscriptionFileTypeRequest, { setSubmitting, resetForm }: FormikHelpers<UpdateSubscriptionFileTypeRequest>) => {
        setSubmitting(true);

        this.subscriptionRepo.updateSubscriptionFileTypeById(this.subscriptionFileType.id, values)
            .then(subscriptionFileType => {
                this.initialValues = this.initialValuesFromSubscriptionFileType(subscriptionFileType);
                this.onSubscriptionFileTypeUpdated(subscriptionFileType);
                resetForm({ values: this.initialValues })

            })
            .catch(console.error)
            .finally(() => setSubmitting(false))
    };

    private initialValuesFromSubscriptionFileType = (subscriptionFileType: SubscriptionFileType) => ({
        name: subscriptionFileType.name || '',
        parserIdentifier: subscriptionFileType.parserIdentifier || '',
        fileNames: subscriptionFileType.fileNames || '',
        isCancellation: subscriptionFileType.isCancellation || false,
        includesHeaders: subscriptionFileType.includesHeaders || false,
        columnCount: subscriptionFileType.columnCount || 1,
        planId: subscriptionFileType.planId || '',
    })
}
