import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextInput from "../../form/old/TextInput";
import {withModal} from "../../modal";

interface Props {
    onAccept: (notes?: string) => void;
    onReject: (notes?: string) => void;
}

function ReviewAnswerModal(props: Props) {
    const { onAccept, onReject } = props;
    const [notes, setNotes] = useState<string>();

    return (
        <div style={{ padding: "12px", minWidth: 400 }}>
            <div style={{ marginBottom: "18px", width: '100%'}}>
                <TextInput rows={3}
                           title="Review Notes"
                           value={notes}
                           onValueChanged={setNotes}
                           style={{ width: '100%' }}/>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <Button variant="contained"
                        onClick={() => onReject(notes)}>Reject</Button>

                <Button variant="contained"
                        color="primary"
                        onClick={() => onAccept(notes)}>Approve</Button>
            </div>


        </div>
    );
}

export default withModal(ReviewAnswerModal, "Leave a Review")