import * as Yup from 'yup';
import {Company, UpdateCompanyRequest, CompanyNote, UpdateNoteForCompanyRequest} from "../../models/Company";
import {adpClientId} from "../validations";

export const companyResellerSchema = Yup.object().shape({
    resellerId: Yup.string().required("Reseller is required")
});

export const companyProfileSchema = Yup.object().shape({
    industryId: Yup.string().nullable(),
    businessSize: Yup.string().nullable(),
    businessType: Yup.string().nullable(),
    entityType: Yup.string().nullable(),
    stateCode: Yup.string().nullable(),
    zipCode: Yup.string().nullable(),
    yearFounded: Yup.number().integer()
        .min(1970, 'Please enter a year between 1970 and 2099. If the company was founded prior to 1970 then enter 1970.')
        .max(2099, 'Please enter a year between 1970 and 2099. If the company was founded prior to 1970 then enter 1970.')
        .nullable()
});

export const companyNoteSchema = Yup.object().shape({
    body: Yup.string().required('Note is required'),
    demeanor: Yup.string().required('Customer Demeanor is required'),
    source: Yup.string().required('Source is required'),
    sourceLink: Yup.string().nullable(),
    status: Yup.string().required('Call Status is required'),
    callType: Yup.string().nullable(),
    userId: Yup.string().nullable(),
    userDisplayName: Yup.string().nullable()
});

export const initialValuesForCompanyReseller = (company?: Company): { resellerId: string } => {
    return {
        resellerId: company?.resellerId ?? ""
    };
}

export const initialValuesForCompanyNoteUpdate = (companyNote?: CompanyNote) : UpdateNoteForCompanyRequest => {
    return {
        body: companyNote?.body ?? "",
        callType: companyNote?.callType ?? "",
        demeanor: companyNote?.demeanor ?? "",
        status: companyNote?.status ??  "",
        source: companyNote?.source ?? "",
        sourceLink: companyNote?.sourceLink ?? "",
        userId: companyNote?.userId ?? "",
        userDisplayName: companyNote?.userDisplayName ?? ""
    };
}

export const companySchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    timeZone: Yup.string().nullable(),
    primaryContactId: Yup.string().required("Primary contact is required"),
    profile: companyProfileSchema,
    adpClientId: adpClientId().nullable(),
    adpOrganizationId: Yup.string().nullable(),
    legalShieldMemberId: Yup.string().nullable(),
});

export const initialValuesForCompanyUpdate = (company?: Company): UpdateCompanyRequest => {
    const profile = company && company.profile ? company.profile : null;
    return {
        isDisabled: company?.isDisabled ?? false,
        name: company?.name ?? "",
        timeZone: company?.timeZone ?? "",
        primaryContactId: company?.primaryContactId ?? "",
        profile: {
            yearFounded: profile?.yearFounded ?? undefined,
            entityType: profile?.entityType ?? "",
            businessType: profile?.businessType ?? "",
            businessSize: profile?.businessSize ?? "",
            stateCode: profile?.stateCode ?? "",
            industryId: profile?.industryId ?? "",
            zipCode: profile?.zipCode ?? "",
        },
        adpClientId: company?.adpClientId ?? "",
        adpOrganizationId: company?.adpOrganizationId ?? "",
        legalShieldMemberId: company?.legalShieldMemberId ?? "",
        website: company?.website ?? ""
    }
};
