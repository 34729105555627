import React, {useState} from 'react';
import {Paper} from '@material-ui/core';
import Table, {TableColumn} from "../table/Table";
import {Entity} from "../../models";
import {getTableDataFetcherFromRepoFetcher} from "../table/TableRepoAdapter";
import {ListQuery} from "../../repos/CrudRepo";
import {CollectionResponse} from "../../models";
import {Action} from "material-table";

interface Props<E extends Entity> {
  title: string;
  fetchData: (query: ListQuery) => Promise<CollectionResponse<E>>
  renderDetail: (
    selectedItem: E,
    onComplete: () => void) => JSX.Element;
  columns: TableColumn<E>[];
  actions?: (Action<E> | ((rowData: E) => Action<E>))[];
  tableRef?: any;
}

export default function EntityList<E extends Entity>(props: Props<E>) {
  const [selectedEntity, setSelectedEntity] = useState<E | null>(null);

  function renderDetail() {
    if (selectedEntity === null) {
      return null;
    }

    const onComplete = () => setSelectedEntity(null);

    return props.renderDetail(selectedEntity, onComplete);
  }

  const fetchData = getTableDataFetcherFromRepoFetcher(props.fetchData);

  return (
    <Paper>
      <Table
        title={props.title}
        columns={props.columns}
        actions={props.actions}
        tableRef={props.tableRef}
        onRowClick={setSelectedEntity}
        data={fetchData}
      />
      {renderDetail()}
    </Paper>
  );
}

