import * as React from "react";
import {useState} from "react";
import {Company} from "../../../../models/Company";
import {LinkCompanyToExistingStripeCustomerModal} from "./LinkCompanyToExistingStripeCustomerModal";
import {StripeCustomer} from "../../../../models/stripe/StripeCustomer";

export default function useLinkCompanyToExistingStripeCustomerModal(
    company: Company,
    onStripeCustomerLinked: (stripeCustomer: StripeCustomer) => void) {

    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
        <LinkCompanyToExistingStripeCustomerModal
            open={open}
            onClose={close}
            company={company}
            onStripeCustomerLinked={(customer) => {
                close();
                onStripeCustomerLinked(customer);
            }}
            title={`Link Existing Stripe Customer for ${company.name}`}
        />
    );

    return {
        openLinkCompanyToExistingStripeCustomerModal: () => setOpen(true),
        linkCompanyToExistingStripeCustomerModal: modal
    }
}
