import * as React from "react";
import {useState} from "react";
import { StateApplicationSection } from "../../models/sid/StateApplicationSection";
import {UpdateStateApplicationSectionModal} from "../entities/state-id/settings/state-application/state-application-section/StateApplicationSectionForm";

export default function useCreateStateIdFieldTypeModal(onStateApplicationSectionUpdated: (stateApplication: StateApplicationSection) => void) {

    const [stateApplicationSection, setStateApplicationSection] = useState<StateApplicationSection>();

    const handleStateApplicationSectionCreated = (stateApplication: StateApplicationSection) => {
        setStateApplicationSection(undefined);
        onStateApplicationSectionUpdated(stateApplication)
    };

    const modal = stateApplicationSection ? (
        <UpdateStateApplicationSectionModal
            open={true}
            onClose={() => setStateApplicationSection(undefined)}
            stateApplicationSection={stateApplicationSection}
            onStateApplicationSectionUpdated={handleStateApplicationSectionCreated}
        />
    ) : null;

    return { setStateApplicationSectionModal: setStateApplicationSection, updateStateApplicationSectionModal: modal }
}
