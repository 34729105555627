import * as React from "react";
import {AssignRolesToEmployeeModal} from "../forms/AssignEmployeeRolesForm";
import {useState} from "react";
import {EmployeeAssignRole} from "../../models/Employee";
import {EmployeeRole} from "../../models/EmployeeRole";
import { nameFromUser } from "../modal/utils";

export default function useAssignRolesToEmployeeModal(onRolesAssigned: (roles: EmployeeRole[]) => void) {

    const [employee, setEmployee] = useState<EmployeeAssignRole>();

    const handleRolesAssigned = (roles: EmployeeRole[]) => {
        setEmployee(undefined);
        onRolesAssigned(roles);
    };

    const title = `Assign Roles to: ${nameFromUser(employee)}`;

    const modal = employee ? (
        <AssignRolesToEmployeeModal
            open={true}
            title={title}
            onClose={() => setEmployee(undefined)}
            allowSysAdminRole={employee.allowSysAdminRole}
            onRolesAssigned={handleRolesAssigned}
            employee={employee}
        />    ) : null;

    return { setEmployeeForAssigningRoles: setEmployee, assignEmployeeRolesModal: modal }
}
