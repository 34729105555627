import {FormikHelpers} from "formik";

export const createSubmitHandler = <Request, Response>(
    submit: (body: Request) => Promise<Response>,
    onSuccess: (res: Response) => void,
    onError: (err: any) => void) => {
    return (body: Request, { setSubmitting }: FormikHelpers<Request>) => {

        setSubmitting(true);

        submit(body)
            .then(res => {
                if (onSuccess) {
                    onSuccess(res);
                }
            })
            .catch(err => {
                console.error(err);

                if (onError) {
                    onError(err);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
};
export const updateSubmitHandler = <Request, Response>(
    submit: (body: Request) => Promise<Response>,
    onReinitialize: (res: Response) => Request,
    onSuccess?: (res: Response) => void,
    onError?: (err: any) => void) => {
    return (body: Request, { setSubmitting, resetForm, setStatus }: FormikHelpers<Request>) => {
        setSubmitting(true);

        submit(body)
            .then(res => {
                setStatus()

                // This will also clear the "dirty" property from the current context
                resetForm({
                    values: onReinitialize(res),
                    status: { success: true }
                })

                if (onSuccess) {
                    onSuccess(res);
                }
            })
            .catch(err => {
                console.error(err);

                setStatus({ success: false })

                if (onError) {
                    onError(err);
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
};
