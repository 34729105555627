import * as React from "react";
import {useState} from "react";
import {MailchimpAccount} from "../../models/MailchimpAccount";
import {UpdateTemplateModal} from "../entities/integrations/mailchimp/MailchimpForm";

export default function useUpdateTemplateModal(onMailchimpAccountUpdated: (mailchimpAccount: MailchimpAccount) => void) {

    const [mailchimpAccount, setMailchimpAccount] = useState<MailchimpAccount>();

    const handleTemplateUpdated = (mailchimpAccount: MailchimpAccount) => {
        setMailchimpAccount(undefined);
        onMailchimpAccountUpdated(mailchimpAccount)
    };

    const modal = mailchimpAccount ? (
        <UpdateTemplateModal
            open={true}
            onClose={() => setMailchimpAccount(undefined)}
            mailchimpAccount={mailchimpAccount}
            onMailchimpAccountUpdated={handleTemplateUpdated}
        />
    ) : null;

    return { setMailchimpAccountForUpdate: setMailchimpAccount, updateMailchimpAccountModal: modal }
}
