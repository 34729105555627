import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {Sender} from "../../../models/mail/Sender";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions{}

export function SenderPicker({ ...PickerProps }: Props) {
    const { senderRepo } = useRepo();
    const [senders, setSenders] = useState<Sender[]>([]);

    useEffect(() => {
        senderRepo.listAll().then(setSenders).catch(console.error);
    }, [senderRepo]);

    const options = useMemo(() => senders.map(p => ({ id: p.id, label: p.name || p.id })), [senders]);

    return <PickerInput label={'Sender'} options={options} {...PickerProps} />
}