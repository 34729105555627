import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "@material-ui/core";
import React from "react";
import * as Yup from 'yup';
import { createSubmitHandler } from "../../form/helpers/formSubmit";
import { ResellerPicker } from "../../form/pickers/ResellerPicker";
import { SalesChannelPicker } from "../../form/pickers/SalesChannelPicker";
import { WebsiteAnalysisReportRequest } from "../../../models/Report";
import { DateInput } from "../../form/inputs/DateInput";
import WebsiteAnalysisStatusPicker from "../../form/pickers/WebsiteAnalysisStatusPicker";
import CheckboxInput from "../../form/inputs/CheckboxInput";

interface Props {
    initialValues: WebsiteAnalysisReportRequest;
    validationSchema: any;
    onSubmit: (values: WebsiteAnalysisReportRequest, actions: FormikHelpers<WebsiteAnalysisReportRequest>) => void;
}

function SearchForm<T>({ initialValues, validationSchema, onSubmit }: Props) {
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, resetForm, handleSubmit }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <SalesChannelPicker name='salesChannelId' label='Sales Channel' allowEmpty={true} emptyValueDescription='-- ALL --' emptyValue='' />
                        <ResellerPicker name='resellerId' label='Reseller' allowEmpty={true} emptyValueDescription='-- ALL --' emptyValue='' />
                        <WebsiteAnalysisStatusPicker name='status' label='Status' allowEmpty={true} emptyValueDescription='-- ALL --' emptyValue='' /><br />
                        <DateInput name='dateStatusFrom' label='Status From' maxDate={new Date()} helperText="Last status update from date" />
                        <DateInput name='dateStatusTo' label='Status To' maxDate={new Date()} helperText="Last status update to date" /><br />

                        <DateInput name='dateReminderFrom' label='Reminder From' maxDate={new Date()} helperText="Last reminder sent from date" disabled={values.hasReminder !== true} />
                        <DateInput name='dateReminderTo' label='Reminder To' maxDate={new Date()} helperText="Last reminder sent to date" disabled={values.hasReminder !== true} />
                        <CheckboxInput name='hasReminder' label='Reminder Sent' /><br />

                        <Button type='submit' variant='contained' color='primary' style={{ margin: '14px' }}>Search</Button>
                        <Button type='submit' variant='contained' color='secondary' style={{ margin: '14px' }} onClick={() => { resetForm(); }}>Clear</Button>
                    </Form>
                );
            }}
        </Formik>
    )
}

export function WebsiteAnalysisSearchForm(props: { initValues: WebsiteAnalysisReportRequest, onSearch: (results: WebsiteAnalysisReportRequest) => void }) {
    const { initValues, onSearch } = props;

    const validationSchema = Yup.object().shape({
        salesChannelId: Yup.string()
            .nullable(),

        resellerId: Yup.string()
            .nullable(),

        status: Yup.string()
            .nullable(),

        dateStatusTo: Yup.date()
            .required("Must enter from date"),

        dateStatusFrom: Yup.date()
            .nullable()
            .default(null),

        dateReminderTo: Yup.date()
            .nullable()
            .default(null),

        dateReminderFrom: Yup.date()
            .nullable()
            .default(null)

    });

    const onSubmit =
        createSubmitHandler(
            (body: WebsiteAnalysisReportRequest) => Promise.resolve(body),
            onSearch,
            console.error
        );

    return (
        <SearchForm initialValues={initValues} validationSchema={validationSchema} onSubmit={onSubmit} />
    );
}