import * as React from "react";

export default function HTML(props: { rawHtml: string }) {
    const { rawHtml } = props;
    const html = { __html: rawHtml  };

    return (
        <div dangerouslySetInnerHTML={html}/>
    );
}
