import * as React from "react";
import { useState } from "react";
import { CompanyNote } from "../../models/Company";
import { UpdateCompanyNoteModal } from "../entities/companies/notes/CompanyNoteForm";

export default function useUpdateCompanyNoteModal(companyId: string, onCompanyNoteUpdated: (companyNote: CompanyNote) => void) {

    const [companyNote, setCompanyNote] = useState<CompanyNote>();

    const handleCompanyNoteUpdated = (companyNote: CompanyNote) => {
        setCompanyNote(undefined);
        onCompanyNoteUpdated(companyNote);
    };

    const modal = companyNote ? (
        <UpdateCompanyNoteModal
            open={true}
            onClose={() => { if(companyNote) setCompanyNote(undefined)}}
            companyId={companyId}
            companyNote={companyNote}
            onCompanyNoteUpdated={handleCompanyNoteUpdated}
        />
    ) : null;

    return { setCompanyNoteForUpdate: setCompanyNote, updateCompanyNoteModal: modal }
}
