import {User} from "../../../../../models/User";
import * as React from "react";
import {UpdateUsernameForUserForm} from "./update-username/UpdateUsernameForUserForm";
import {UpdateEmailForUserForm} from "./update-email/UpdateEmailForUserForm";
import {UpdateSubjectIdForUserForm} from "./update-subjectid/UpdateSubjectIdForUserForm";
import {Divider} from "@material-ui/core";

interface Props {
    user: User;
    onUserUpdated: (user: User) => void;
}

export default function AccountTab({ user, onUserUpdated }: Props) {

    return (
        <>
            <div>
                <UpdateEmailForUserForm user={user} onUserUpdated={onUserUpdated}/>
            </div>

            <Divider style={{ marginTop: 24, marginBottom: 24 }}/>

            <div>
                <UpdateUsernameForUserForm user={user} onUserUpdated={onUserUpdated}/>
            </div>

            <Divider style={{ marginTop: 24, marginBottom: 24 }}/>

            <div>
                <UpdateSubjectIdForUserForm user={user} onUserUpdated={onUserUpdated}/>
            </div>
        </>
    )
}

