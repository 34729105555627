
import React from "react";
import { Application } from "../../../../../models/sid/Application";
import { UpdateApplicationStateIdForm } from "../state-id/ApplicationStateIdForm";

export default function StateIdTab(props: { application: Application, onApplicationUpdated: (application: Application) => void }) {

    const { application, onApplicationUpdated } = props;

    return (
        <>
            <UpdateApplicationStateIdForm application={application} onApplicationUpdated={onApplicationUpdated} />
        </>
    )
}
