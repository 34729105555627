import { MailchimpAccount } from "../../models/MailchimpAccount";
import MailchimpAccountRepo from "./MailchimpAccountRepo";
import { HttpCrudRepo } from "../HttpCrudRepo";
import { arrayFromCollection, CollectionResponse } from "../../models";
import { ListQuery } from "../CrudRepo";
import { sanitize } from "../../utils/FormUtils";
import { getHttpQueryFromListQuery } from "../../utils/QueryUtils";
import config from '../../config';

export default class HttpMailchimpAccountRepo extends HttpCrudRepo<MailchimpAccount> implements MailchimpAccountRepo {
    pathComponents = ['mk', 'v1', 'mailchimp-accounts'];

    list(query: ListQuery): Promise<CollectionResponse<MailchimpAccount>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "salesChannel,reseller";
        return this.http.get(this.pathComponents, { query: httpQuery });
    }

    create(body: object): Promise<MailchimpAccount> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<MailchimpAccount>): Promise<MailchimpAccount> {
        return this.http.put([...this.pathComponents, id], sanitize(resource))
    }

    listAll(): Promise<MailchimpAccount[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<MailchimpAccount>>(this.pathComponents, { query: httpQuery })
            .then(arrayFromCollection);
    }
}
