import * as React from "react";
import { useState } from "react";
import { Company, CompanyReferral } from "../../../../models/Company";
import { CreateReferralForUserModal } from "./CreateReferralForUserForm";

export default function useCreateUserForCompanyModal(company: Company, onReferralCreated: (referral: CompanyReferral) => void) {

    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
        <CreateReferralForUserModal
            title='Create Referral'
            open={open}
            onClose={close}
            company={company}
            onReferralCreated={(referral) => {
                close();
                onReferralCreated(referral)
            }} />
    )

    return {
        createReferralForUserModal: modal,
        openCreateReferralForUserModal: () => setOpen(true)
    }
}