import {User} from "../../../models/User";
import {Form, Formik, FormikHelpers} from "formik";
import React, {useEffect, useMemo, useState} from "react";
import {SalesChannelApplicationPicker} from "../../form/pickers/SalesChannelApplicationPicker";
import {withModal} from "../../modal";
import {FormSubmitButton} from "../../form/buttons/FormSubmitButton";
import {VerticalFormLayout} from "../../form/FormLayout";
import {useNavigation} from "../../../services/navigation/useNavigation";
import {useRepo} from "../../../repos/useRepo";
import {SalesChannelApplication} from "../../../models/SalesChannelApplication";
import { PickerOption } from "../../../components/form/inputs/PickerInput";
import {UrlTarget} from "../../../config/Url";
import {createSubmitHandler} from "../../form/helpers/formSubmit";

export function ImpersonateUserForm(props: { user: User }) {
  const { user }  = props;

  const { navigateToUrl } = useNavigation();



  const onSubmit = (values: { salesChannelApplicationId: string }, { setSubmitting }: FormikHelpers<any>) => {
    const url = salesChannelApplications
      .find(sa => sa.id === values.salesChannelApplicationId)
      ?.impersonationUrl
      ?.replace('{OBJECT_ID}', user.subjectId);
    if (!url) {
      console.warn("Impersonation url for application not found.");
      return;
    }
    navigateToUrl(url, UrlTarget.blank);
    setSubmitting(false);
  };


  const { salesChannelApplicationRepo } = useRepo();
  const [salesChannelApplications, setSalesChannelApplications] = useState<SalesChannelApplication[]>([]);

  useEffect(() => {
    salesChannelApplicationRepo.listAllForSalesChannel(user.salesChannelId)
      .then((res) => setSalesChannelApplications(res.results)).catch(console.error);
  }, [salesChannelApplicationRepo]);


  const options: PickerOption[] = useMemo(() => salesChannelApplications.map(p => ({ id: p.id, label: p.alias || p.id })), [salesChannelApplications]);

  return (
    <Formik<{salesChannelApplicationId: string}>
      initialValues={{salesChannelApplicationId: ""}}
      validate={(values) => {
        if (!salesChannelApplications
          .find(sa => sa.id === values.salesChannelApplicationId)
          ?.impersonationUrl) {
          return {
            salesChannelApplicationId: "Application has no impersonation url configured"
          }
        }
      }}
      onSubmit={onSubmit}>
      <Form>
        <VerticalFormLayout
          fieldset={<SalesChannelApplicationPicker
            label={'Choose Application'}
            name={'salesChannelApplicationId'}
            options={options}
          />}
          bottomContent={<FormSubmitButton label={"Go"} variant='Update' disabled={false} />}
        />
      </Form>
    </Formik>
  )
}

export const ImpersonateUserModal = withModal(ImpersonateUserForm, 'Impersonate User');
