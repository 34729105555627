import * as React from "react";
import {useState} from "react";
import {Sender} from "../../models/mail/Sender";
import {CreateSenderModal} from "../entities/mail/senders/SenderForm";

export default function useCreateSenderModal(onSenderCreated: (sender: Sender) => void) {

    const [open, setOpen] = useState(false);

    const handleSenderCreated = (sender: Sender) => {
        setOpen(false);
        onSenderCreated(sender)
    };

    const modal = (
        <CreateSenderModal
            open={open}
            onClose={() => setOpen(false)}
            onSenderCreated={handleSenderCreated}
        />
    );

    return { setCreateSenderModalOpen: setOpen, createSenderModal: modal }
}
