import {createStyles, Theme, Typography} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Company} from "../../../../models/Company";
import {Link} from "react-router-dom";
import Routes from "../../../../Routes";

export interface CompanyViewModel {
    readonly companyId: string;
    readonly name: string;
    readonly industry?: string;
    readonly size?: string;
    readonly age?: string;
    readonly entityType?: string;
    readonly businessType?: string;
    readonly state?: string;
    readonly zip?: string;
}

export class DefaultCompanyViewModel implements CompanyViewModel {

    constructor(private company: Company) {}

    get companyId(): string {
        return this.company.id
    }

    get name(): string {
        return this.company.name
    }

    get age(): string | undefined {
        if (this.company.profile && this.company.profile && this.company.profile.yearFounded) {
            const yearFounded = this.company.profile.yearFounded;
            return "Founded " + yearFounded;
        } else {
            return undefined
        }
    }

    get entityType(): string | undefined {
        if (this.company && this.company.profile) {
            return  this.company.profile.entityType
        } else {
            return undefined;
        }
    }

    get industry(): string | undefined {
        if (this.company && this.company.profile && this.company.profile.industry) {
            return  this.company.profile.industry.name
        } else {
            return undefined;
        }
    }

    get size(): string | undefined {
        if (this.company && this.company.profile) {
            return  this.company.profile.businessSize
        } else {
            return undefined;
        }
    }
    get state(): string | undefined {
        if (this.company && this.company.profile) {
            return  this.company.profile.stateCode
        } else {
            return undefined;
        }
    }

    get zip(): string | undefined {
        if(this.company && this.company.profile){
            return this.company.profile.zipCode;
        } else {
            return undefined;
        }
    }

    get businessType(): string | undefined {
        if (this.company && this.company.profile) {
            return  this.company.profile.businessType
        } else {
            return undefined;
        }
    }
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        fontWeight: "bolder", color: "gray"
    },
}));


export default function CompanyInfo(props: { title: string, vm: CompanyViewModel }) {
    const { title, vm } = props;
    const classes = useStyles();

    const details = detailsForViewModel(vm);

    return (
        <div
            key={title}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginBottom: 14
            }}
        >
            <Typography className={classes.title} style={{ marginBottom: 4, marginRight: 10 }}>
                {title}
            </Typography>
            <Typography>
                <Link to={Routes.forCompanyById(vm.companyId)}>
                    {vm.name}
                </Link>
            </Typography>
            {vm.industry && (
                <Typography>
                    {vm.industry}
                </Typography>
            )}
            {details.length > 0 && (
                <Typography>
                    {details.join(' | ')}
                </Typography>
            )}
        </div>
    );
}


const detailsForViewModel = (vm: CompanyViewModel): string[] => {
    const details: string[] = [];

    if (vm.age) {
        details.push(vm.age);
    }

    if (vm.size) {
        details.push(`${vm.size} employees`);
    }

    if (vm.entityType) {
        details.push(vm.entityType);
    }

    if (vm.businessType) {
        details.push(vm.businessType);
    }

    if (vm.state) {
        details.push(vm.state);
    }

    if (vm.zip) {
        details.push(vm.zip);
    }

    return details;
}
