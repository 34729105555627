import * as React from "react";
import {useState} from "react";
import { Company } from "../../../../models/Company";
import { CreateCompanyResellerModal } from "./changeCompanyResellerForm";

export default function useChangeCompanyResellerModal(onCompanyUpdated: (company: Company) => void) {
    const [company, setCompany] = useState<Company>();
    const close = () => setCompany(undefined);

    const handleCompanyUpdated = (company: Company) => {
        close();
        onCompanyUpdated(company)
    };

    const modal = company ? (
        <CreateCompanyResellerModal
            open={true}
            onClose={close}
            company={company}
            onCompanyUpdated={handleCompanyUpdated}
        />
        ) : null;

    return { 
        openChangeCompanyResellerModal: (company: Company) => setCompany(company), 
        createCompanyResellerModal: modal 
    };
}