import React, {useEffect, useMemo, useState} from "react";
import {User} from "../../../models/User";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import {useRepo} from "../../../repos/useRepo";


interface Props extends PickerPropsWithoutOptions {
    users: User[];
}

export function UserPicker({ users, ...PickerProps }: Props) {
    const options = useMemo(() => users.map(u => ({ id: u.id, label: `${u.firstName} ${u.lastName}`})), [users]);
    return <PickerInput label={'User'} options={options} {...PickerProps} />
}

export function CompanyUserPicker(props: { companyId: string, optionalUser?: User, allowOptionalUser?: boolean } & PickerPropsWithoutOptions) {
    const { companyId, allowOptionalUser, optionalUser, ...PickerProps } = props;
    const { companyRepo } = useRepo();

    const [users, setUsers] = useState<User[]>([]);

    const setCompanyUsers = (users: User[]) => {
        if(allowOptionalUser && optionalUser){
            users.push(optionalUser);
        }
        setUsers(users);
    }

    useEffect(() => {
        companyRepo.listUsersForCompanyById(companyId).then(setCompanyUsers).catch(console.warn);
    }, [companyId, companyRepo]);

    return <UserPicker users={users} {...PickerProps} />
}