import {SalesChannel} from "../../../../models/SalesChannel";
import {useRepo} from "../../../../repos/useRepo";
import {default as React, useEffect, useMemo, useRef} from "react";
import Table, {TableColumn, TableQuery} from "../../../table/Table";
import TableColumnFactory from "../../TableColumnFactory";
import {buildQueryForTableQuery, queryResultFromCollectionResponseForQuery} from "../../../table/TableRepoAdapter";
import {Filter} from "../../../../repos/CrudRepo";
import {Reseller} from "../../../../models/Reseller";


interface Props {
    salesChannel: SalesChannel,
    onResellerSelected: (reseller: Reseller) => void;
}
export default function SalesChannelResellersTable({ salesChannel, onResellerSelected }: Props) {
    const { resellerRepo } = useRepo();

    const tableRef = useRef<any>(null);

    const salesChannelId = useMemo(() => salesChannel.id, [salesChannel.id]);

    const reloadData = () => tableRef.current.onQueryChange();

    useEffect(reloadData, [salesChannelId]);

    const columns: TableColumn<Reseller>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeResellerIdColumn(),
        {
            title: 'Name',
            field: 'name',
        },
    ]);

    const fetchData = async (tableQuery: TableQuery<Reseller>) => {
        const query = buildQueryForTableQuery(tableQuery);
        const salesChannelFilter: Filter = { field: "salesChannelId", "value": salesChannelId };
        if (query.filters) {
            query.filters.push(salesChannelFilter);
        } else {
            query.filters = [salesChannelFilter];
        }
        const res = await resellerRepo.list(query);
        return queryResultFromCollectionResponseForQuery(res)
    };

    return (
        <>
            <Table<Reseller>
                tableRef={tableRef}
                title={`Resellers for ${salesChannel.name}`}
                columns={columns}
                actions={[]}
                options={{
                    search: false,
                    filtering: false,
                }}
                onRowClick={onResellerSelected}
                data={fetchData}
            />
        </>
    );
}
