import { User } from "../../../models/User";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { withModal } from "../../modal";
import { FormSubmitButton } from "../../form/buttons/FormSubmitButton";
import { VerticalFormLayout } from "../../form/FormLayout";
import { useRepo } from "../../../repos/useRepo";
import * as Yup from "yup";
import TextInput from "../../form/inputs/TextInput";
import { Typography } from "@material-ui/core";

export function UserUpdatePasswordForm(props: { user: User, onUpdated: (msg?: string) => void }) {
    const { user, onUpdated } = props;
    const { userRepo } = useRepo();

    interface passwordForm {
        password: string,
        confirmPassword: string
    };

    const schema = Yup.object<passwordForm>().shape({
        password: Yup.string().required("This field is required"),
        confirmPassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
            )
        })
    });

    const onSubmit = (values: passwordForm, { setSubmitting }: FormikHelpers<any>) => {
        // Submit Password Reset
        userRepo.updatePasswordForUserById(user.id, values.password)
            .then(() => onUpdated())
            .catch(() => onUpdated('Invalid Password, Please Try Again.'));

        setSubmitting(false);
    };

    return (
        <Formik<passwordForm>
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={schema}
            onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout
                    fieldset={
                        <>
                            <TextInput label={'New Password'} name={'password'} />
                            <TextInput label={'Confirm Password'} name={'confirmPassword'} />
                            <Typography variant="caption" display="block" style={{ marginRight: 6, marginLeft: 6 }}>Password must be at least 8 characters long with<br />one uppercase, one lowercase and one numeric character.</Typography>
                        </>
                    }
                    bottomContent={<FormSubmitButton label={"Update"} variant='Update' disabled={false} />}
                />
            </Form>
        </Formik>
    )
}

export const UpdateUserPasswordModal = withModal(UserUpdatePasswordForm, 'Change Password');
