import * as React from "react";
import {useState} from "react";
import {SendGridAccount} from "../../models/SendGridAccount";
import {CreateTemplateModal} from "../entities/integrations/sendgrid/SendGridForm";

export default function useCreateSendGridAccountModal(onSendGridAccountCreated: (sendgridAccount: SendGridAccount) => void) {

    const [open, setOpen] = useState(false);

    const handleSendGridAccountCreated = (sendgridAccount: SendGridAccount) => {
        setOpen(false);
        onSendGridAccountCreated(sendgridAccount)
    };

    const modal = (
        <CreateTemplateModal
            open={open}
            onClose={() => setOpen(false)}
            onSendGridAccountCreated={handleSendGridAccountCreated}
        />
    );

    return { setCreateSendGridAccountModalOpen: setOpen, createSendGridAccountModal: modal }
}
