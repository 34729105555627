import {Form, Formik, FormikHelpers} from "formik";
import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Role} from "../../models/Role";
import {AssignRolesRequest} from "../../repos/user/UserRepo";
import MultipleSelectInput from "../form/select/MultipleSelectInput";
import {assignRoleSchema, initialValuesForAssignRoleRequest} from "../../utils/schemas/role-schema";
import {User} from "../../models/User";
import {useRepo} from "../../repos/useRepo";
import LoadingIndicator from "../LoadingIndicator";
import {withModal} from "../modal";
import {FormSubmitButton} from "../form/buttons/FormSubmitButton";
import { createSubmitHandler } from "../form/helpers/formSubmit";

interface Props {
    roles: Role[];
    initialValues: AssignRolesRequest;
    onSubmit: (values: AssignRolesRequest, {}: FormikHelpers<AssignRolesRequest>) => void;
}

function AssignRolesForm({ initialValues, roles, onSubmit }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={assignRoleSchema} onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <MultipleSelectInput name='roleIds' label='Roles' items={roles.map(r => ({ id: r.id, name: r.name }))}/>

                <FormSubmitButton variant='Update' label='Assign'/>
            </Form>
        </Formik>
    )
}

interface UpdateLicenseFormProps {
    user: User;
    onRolesAssigned: (roles: Role[]) => void;
}

export function AssignRolesToUserForm({ user, onRolesAssigned }: UpdateLicenseFormProps) {
    const { userRepo, companyRepo } = useRepo();

    const [availableRoles, setAvailableRoles] = useState<Role[]>();

    useEffect(() => {
        userRepo.listRolesForUserById(user.id, { status: 'available' }).then(setAvailableRoles).catch(console.error);
    }, [companyRepo]);

    const onSubmit = createSubmitHandler(
      (body: AssignRolesRequest) => userRepo.assignRolesForUserById(user.id, body),
      onRolesAssigned,
      console.error
    );

    if (availableRoles) {
        const content = availableRoles.length > 0
            ? <AssignRolesForm initialValues={initialValuesForAssignRoleRequest()} onSubmit={onSubmit} roles={availableRoles || []}/>
            : `No roles available for ${user.firstName} ${user.lastName}`;

        return (
            <div>
                <div style={{ marginTop: 12, marginBottom: 12 }}>
                    {content}
                </div>
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export const AssignRolesToUserModal = withModal(AssignRolesToUserForm);
