import * as React from "react";
import { Application } from "../../../../../models/sid/Application";
import { UpdateApplicationForm } from "../ApplicationForm";
import ApplicationNotesTimeline from "../notes/ApplicationNoteTimeline";

export default function ApplicationTab(props: { application: Application, onSubmitApplication: () => void }) {
    const { application, onSubmitApplication } = props;

    return (<>
        <div style={{ display: "flex", flex: 1 }}>
            <div style={{ flex: 1, order: 2 }}><ApplicationNotesTimeline application={application} /> </div>
            <div style={{ width: "500px", order: 1 }}><UpdateApplicationForm application={application} onSubmitApplication={onSubmitApplication} /></div>
        </div>
    </>);
}
