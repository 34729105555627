import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import { Consultant } from "../../../models/sid/Consultant";

export default function StateIdConsultantDropdown(props: FilterComponentProps<any>) {
    const { stateIdConsultantRepo } = useRepo();
    const [consultants, setConsultants] = useState<Consultant[]>([]);

    useEffect(() => {
        stateIdConsultantRepo.list().then(setConsultants).catch(console.error);
    }, [stateIdConsultantRepo]);
    
    return (
        <DropdownPicker options={consultants} {...props}/>
    )
}