import React from "react";
import {NavigationGroup} from "./components/layout/SideNav";
import {navigationItems as items} from "./navigation-items";

export const navigationGroups: NavigationGroup[] = [
    {
        items: [items.upload, items.companies, items.users]
    },
    {
        items: [items.applications, items.brands, items.products]
    },
    {
        items: [items.employees]
    },
    {
        title: "Tarkenton Institute",
        items: [items.questions, items.answers, items.faqs, items.tarkentonInstituteSettings]
    },
    {
        items: [items.mail, items.integrations]
    },
    {
        items: [items.reports]
    },
    {
        title: "State Applications",
        items: [items.stateIdApplications, items.stateIdSettings]
    }
];
