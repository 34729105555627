import { FieldType } from "../../../models/sid/FieldType";
import FieldTypeRepo from "./FieldTypeRepo";
import { HttpCrudRepo } from "../../HttpCrudRepo";
import { getHttpQueryFromListQuery } from "../../../utils/QueryUtils";
import config from "../../../config";
import { arrayFromCollection, CollectionResponse } from "../../../models/CollectionResponse";

export default class HttpFieldTypeRepo extends HttpCrudRepo<FieldType> implements FieldTypeRepo {
    pathComponents = ['sid', 'v1', 'field-type'];

    listAll(): Promise<FieldType[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<FieldType>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
