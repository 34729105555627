import {SubscriptionFileUpload} from "../../../models/SubscriptionFileUpload";
import {useRepo} from "../../../repos/useRepo";
import React, {useRef, forwardRef, useImperativeHandle} from "react";
import {getTableDataFetcherFromRepoFetcher} from "../../table/TableRepoAdapter";
import Table, {TableColumn} from "../../table/Table";
import TableColumnFactory from "../TableColumnFactory";

const SubscriptionFileUploadList = (props: { onSubscriptionFileUploadSelected: (fileUpload: SubscriptionFileUpload) => void }, ref: any) => {
  const { onSubscriptionFileUploadSelected } = props;
  const { subscriptionRepo } = useRepo();

  const tableRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    reload: () => tableRef.current.onQueryChange(),
  }));

  const columns: TableColumn<SubscriptionFileUpload>[] = TableColumnFactory.withTimestampColumns([
    TableColumnFactory.makeSubscriptionFileUploadIdColumn(),
    TableColumnFactory.fromProperty("Name", "fileName"),
    TableColumnFactory.fromProperty("URL", "fileUrl"),
    TableColumnFactory.makeSubscriptionFileTypeColumn("Type", "fileTypeId", u => u.fileType),
  ]);

  return (
      <Table<SubscriptionFileUpload>
          tableRef={tableRef}
          title='Subscription File Uploads'
          columns={columns}
          onRowClick={onSubscriptionFileUploadSelected}
          data={getTableDataFetcherFromRepoFetcher(subscriptionRepo.listSubscriptionFileUploads.bind(subscriptionRepo))}
      />
  );
}

export default forwardRef(SubscriptionFileUploadList)