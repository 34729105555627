import {CSSProperties} from "react";

export const verticalFormLayout: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: 240,
    maxWidth: 640,
    justifyContent: "stretch"
};
