import React, {useEffect, useMemo, useState} from "react";
import {EmployeeCompany} from "../../../models/EmployeeCompany";
import {useRepo} from "../../../repos/useRepo";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export default function EmployeeCompanyPicker({ ...PickerProps }: Props) {
    const { employeeCompanyRepo } = useRepo();
    const [employeeCompanies, setEmployeeCompanies] = useState<EmployeeCompany[]>([]);

    useEffect(() => {
        employeeCompanyRepo.listAll().then(setEmployeeCompanies).catch(console.error);
    }, []);

    const options = useMemo(() => employeeCompanies.map(p => ({ id: p.id, label: p.name || p.id })), [employeeCompanies]);

    return <PickerInput label={'Company'} options={options} {...PickerProps} />
}