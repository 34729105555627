import {createStyles, Theme, Typography} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {User} from "../../../../models/User";
import {Link} from "react-router-dom";
import Routes from "../../../../Routes";

export interface UserViewModel {
    readonly userId: string;
    readonly name: string;
    readonly legalShieldMemberId?: string;
    readonly adpAssociateId?: string;
    readonly adpOrganizationId?: string;
}

export class DefaultUserViewModel implements UserViewModel {

    constructor(private user: User) {}

    get userId(): string {
        return this.user.id;
    }

    get name(): string {
        return `${this.user.firstName} ${this.user.lastName}`
    }

    get legalShieldMemberId(): string | undefined {
        return this.user.legalShieldMemberId;
    }

    get adpAssociateId(): string | undefined {
        return this.user.adpAssociateId;
    }

    get adpOrganizationId(): string | undefined {
        return this.user.company?.adpOrganizationId;
    }
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        fontWeight: "bolder", color: "gray"
    },
}));


export default function UserInfo(props: { title: string, vm: UserViewModel }) {
    const { title, vm } = props;
    const classes = useStyles();
    const { userId, name, legalShieldMemberId, adpAssociateId, adpOrganizationId } = vm;

    return (
        <div
            key={title}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginBottom: 14
            }}
        >
            <Typography className={classes.title} style={{ marginBottom: 4, marginRight: 10 }}>
                {title}
            </Typography>
            <Typography>
                <Link to={Routes.forUserById(userId)}>
                    {name}
                </Link>
            </Typography>
            {legalShieldMemberId && (
                <Typography>
                    Legal Shield Member ID: {legalShieldMemberId}
                </Typography>
            )}
            {adpOrganizationId && (
                <Typography>
                    ADP Organization ID: {adpOrganizationId}
                </Typography>
            )}
            {adpAssociateId && (
                <Typography>
                    ADP Associate ID: {adpAssociateId}
                </Typography>
            )}
        </div>
    );
}

