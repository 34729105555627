import * as React from "react";
import {useState} from "react";
import {ProductLicense} from "../../models/ProductLicense";
import {UpdateProductLicenseModal} from "../entities/billing/product-licenses/ProductLicenseForm";

export default function useUpdateProductLicenseModal(onProductLicenseUpdated: (productLicense: ProductLicense) => void) {

    const [productLicense, setProductLicense] = useState<ProductLicense>();

    const handleProductLicenseUpdated = (productLicense: ProductLicense) => {
        setProductLicense(undefined);
        onProductLicenseUpdated(productLicense)
    };

    const modal = productLicense ? (
        <UpdateProductLicenseModal
            open={true}
            onClose={() => setProductLicense(undefined)}
            productLicense={productLicense}
            onProductLicenseUpdated={handleProductLicenseUpdated}
        />
    ) : null;

    return { setProductLicenseForUpdate: setProductLicense, updateProductLicenseModal: modal }
}
