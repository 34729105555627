import * as Yup from 'yup';
import {Topic} from "../../models/ti/Topic";
import { uri } from "@hapi/address";

const uriRegex = uri.regex({
    allowRelative: true
}).regex;

const fields = {
    name: Yup.string().required("Name is required"),
    detail: Yup.string().nullable(),
    iconUrl: Yup.string().matches(uriRegex, "Icon URL must be a valid url or relative url").nullable(),
    iconIdentifier: Yup.string().nullable(),
};

export const createTopicSchema = Yup.object().shape(fields);

export const updateTopicSchema = Yup.object().shape(fields);

export const initialValuesForTopic = (topic?: Topic) => ({
    name: topic?.name ?? "",
    detail: topic?.detail ?? "",
    iconUrl: topic?.iconUrl ?? "",
    iconIdentifier: topic?.iconIdentifier ?? "",
});
