import React, { useMemo } from "react";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions { }

export default function SendReportDelayPicker({ ...PickerProps }: Props) {

    interface Option { text: string, value: number }

    const values: Option[] = [
        { text: 'Immediately', value: 0 }, 
    { text: '5 mins', value: 5 }, 
    { text: '10 mins', value: 10 },
    { text: '15 mins', value: 15 },
    { text: '20 mins', value: 20 },
    { text: '25 mins', value: 25 },
    { text: '30 mins', value: 30 },
    { text: '45 mins', value: 45 },
    { text: '60 mins', value: 60 }];

    const options = useMemo(() => values.map(c => ({ id: c.value.toString(), label: c.text })), [values]);

    return <PickerInput label={'Send Report Delay'} options={options} {...PickerProps} />
}
