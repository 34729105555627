import * as React from "react";
import {useState} from "react";
import {Employee} from "../../models/Employee";
import {CreateEmployeeModal} from "../entities/employees/EmployeeForm";

export default function useCreateEmployeeModal(onEmployeeCreated: (employee: Employee) => void) {

    const [open, setOpen] = useState(false);

    const handleEmployeeCreated = (employee: Employee) => {
        setOpen(false);
        onEmployeeCreated(employee)
    };

    const modal = (
        <CreateEmployeeModal
            open={open}
            onClose={() => setOpen(false)}
            onEmployeeCreated={handleEmployeeCreated}
        />
    );

    return { setCreateEmployeeModalOpen: setOpen, createEmployeeModal: modal }
}
