import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {EntityType} from "../../../repos/entity-type/EntityTypeRepo";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export default function EntityTypePicker({ ...PickerProps }: Props) {
    const { entityTypeRepo } = useRepo();

    const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);

    useEffect(() => {
        entityTypeRepo.list().then(setEntityTypes).catch(console.error);
    }, [entityTypeRepo]);

    const options = useMemo(() => entityTypes.map(t => ({ id: t, label: t })), [entityTypes]);

    return <PickerInput label={'Entity Type'} options={options} {...PickerProps} />
}