import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Theme} from "@material-ui/core";
import {Employee} from "../../../models/Employee";
import {MenuItemBuilder, MenuButton, MenuItem} from "../../menu/Menu";
import {EmployeeMenuItemBuilder} from "./EmployeeMenuItemBuilder";
import {useNavigation} from "../../../services/navigation/useNavigation";

interface Props {
    employee: Employee;
}

export default function EmployeeActionBar({ employee }: Props) {
    const classes = useStyles();

    const { navigateToActiveDirectoryUser } = useNavigation();

    const menuItemBuilder: MenuItemBuilder = new EmployeeMenuItemBuilder(employee, navigateToActiveDirectoryUser);
    const actionItems: MenuItem[] = menuItemBuilder.build();

    return (
        <div className={classes.container}>

            <div className={classes.leftSection}>

                <h3 className={classes.title}>
                    {employee.firstName} {employee.lastName}
                </h3>
            </div>

            <div className={classes.rightSection}>
                {actionItems.length > 0 && (
                    <MenuButton items={actionItems}/>
                )}
            </div>

        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden"
    },
    leftSection: {
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    rightSection: {
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    title: {

    },
    subtitle: {
        marginTop: "2px",
        marginLeft: "8px"
    },
}));
