import HttpService, { HttpQueryParams } from "@gsb/react-http";
import ReportRepo from "./ReportRepo";
import {
    SubscriptionReportRequest,
    SubscriptionReportItem,
    StatsReportRequest,
    StatsReportResponse,
    CompanyNoteReportRequest,
    CompanyMetaReportRequest,
    WebsiteAnalysisReportRequest, AdpCompanySignInReportRequest, AdpCompanySignInReportItem
} from "../../models/Report";
import { CompanyNote, Company } from "../../models/Company";
import MetadataField from "../../models/MetadataField";
import { WebsiteAnalysis } from "../../models/WebsiteAnalysis";

export default class HttpReportRepo implements ReportRepo {

    pathComponents = ['mk', 'v1', 'reports'];

    constructor(private http: HttpService) { }

    subscriptionReport(query: SubscriptionReportRequest): Promise<SubscriptionReportItem[]> {
        let httpQuery: HttpQueryParams = { ...query, renewed: query.renewed?.toString() }
        console.log(httpQuery);
        return this.http.get([...this.pathComponents, 'subscriptions'], { query: httpQuery });
    }

    statsReport(query: StatsReportRequest): Promise<StatsReportResponse> {
        let httpQuery: HttpQueryParams = { ...query }
        console.log(httpQuery);
        return this.http.get([...this.pathComponents, 'stats'], { query: httpQuery });
    }

    companyMetadataReport(body: CompanyMetaReportRequest): Promise<Company[]> {
        const reqBody = { ...body, metaFields: body.metaFields.map(f => { return { label: f.label, value: f.value } }) };
        return this.http.post([...this.pathComponents, 'company-metadata'], reqBody);
    }

    companyMetadataFields(): Promise<MetadataField[]> {
        let httpQuery: HttpQueryParams = { entity: 'company' };
        return this.http.get(['mk', 'v1', 'metadata-fields'], { query: httpQuery });
    }

    companyNotesReport(query: CompanyNoteReportRequest): Promise<CompanyNote[]> {
        let httpQuery: HttpQueryParams = { ...query }
        console.log(httpQuery);
        return this.http.get([...this.pathComponents, 'company-notes'], { query: httpQuery });
    }

    websiteAnalysisReport(query: WebsiteAnalysisReportRequest): Promise<WebsiteAnalysis[]> {
        let httpQuery: HttpQueryParams = { ...query }
        console.log(httpQuery);
        return this.http.get([...this.pathComponents, 'website-analysis'], { query: httpQuery });
    }

    websiteAnalysisSendReminder(websiteAnalysisIds: string[]): Promise<string[]> {
        return this.http.post(['mk', 'v1', 'jobs', 'website-analysis', 'send-reminder'], { websiteAnalysisIds });
    }

    adpCompanySignInReport(query: AdpCompanySignInReportRequest) : Promise<AdpCompanySignInReportItem[]> {
        let httpQuery: HttpQueryParams = { ...query }
        return this.http.get([...this.pathComponents, 'adp-company-sign-in'], { query: httpQuery });
    }
}