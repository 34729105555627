import config from "../../../config";
import { arrayFromCollection, CollectionResponse } from "../../../models/CollectionResponse";
import { Application, AssignApplicationAgentRequest } from "../../../models/sid/Application";
import { ApplicationNote, CreateNoteForApplicationRequest, UpdateNoteForApplicationRequest } from "../../../models/sid/ApplicationNote";
import { ApplicationStatus } from "../../../models/sid/ApplicationStatus";
import { sanitize } from "../../../utils/FormUtils";
import { getHttpQueryFromListQuery } from "../../../utils/QueryUtils";
import { ListQuery } from "../../CrudRepo";
import { HttpCrudRepo } from "../../HttpCrudRepo";
import ApplicationRepo from "./ApplicationRepo";

export default class HttpApplicationRepo extends HttpCrudRepo<Application> implements ApplicationRepo {
    pathComponents = ['sid', 'v1', 'application'];

    list(query: ListQuery): Promise<CollectionResponse<Application>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "status,member,assignedToAgent";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    update(id: string, resource: Partial<Application>): Promise<Application> {
        return this.http.put([...this.pathComponents, id], sanitize(resource))
    }

    listStatuses(): Promise<ApplicationStatus[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<ApplicationStatus>>([...this.pathComponents, 'statuses'], {query: httpQuery})
            .then(arrayFromCollection);
    }

    getFieldsObj(id: string): Promise<any> {
        return this.http.get([...this.pathComponents, id, 'fields']);
    }

    updateFields(id: string, body: any): Promise<any> {
        return this.http.put([...this.pathComponents, id, 'fields'], body );
    }

    createNote(applicationId: string, body: CreateNoteForApplicationRequest): Promise<ApplicationNote> {
        return this.http.post([...this.pathComponents, applicationId, 'notes'], body);
    }

    updateNote(applicationId: string, noteId: string, body: UpdateNoteForApplicationRequest): Promise<ApplicationNote> {
        return this.http.put([...this.pathComponents, applicationId, 'notes', noteId], body);
    }
    deleteNote(applicationId: string, noteId: string): Promise<void> {
        return this.http.delete([...this.pathComponents, applicationId,'notes', noteId]);
    }
    recentNotes(applicationId: string): Promise<ApplicationNote[]>{
        return this.http.get([...this.pathComponents, applicationId, 'notes-recent']);
    }
    listNotes(applicationId: string): Promise<ApplicationNote[]> {
        return this.http.get([...this.pathComponents, applicationId, 'notes']);
    }

    assignToAgent (body: AssignApplicationAgentRequest): Promise<void> {
        return this.http.post([...this.pathComponents, 'assign-agent'], body);
    }
}
