import { StateApplication } from "../../../models/sid/StateApplication";
import { StateApplicationSection } from "../../../models/sid/StateApplicationSection";
import { sanitize } from "../../../utils/FormUtils";
import { HttpCrudRepo } from "../../HttpCrudRepo";
import StateApplicationRepo from "./StateApplicationRepo";

export default class HttpStateApplicationRepo extends HttpCrudRepo<StateApplication> implements StateApplicationRepo {
    pathComponents = ['sid', 'v1', 'state-application'];

    create(body: object): Promise<StateApplication> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<StateApplication>): Promise<StateApplication> {
        return this.http.put([...this.pathComponents, id], sanitize(resource))
    }

    listSections(id: string): Promise<StateApplicationSection[]> {
        return this.http.get([...this.pathComponents, id, 'sections']);
    }
}
