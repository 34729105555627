import { CollectionResponse } from "../../models";
import {UserSignIn} from "../../models/User";
import { getHttpQueryFromListQuery } from "../../utils/QueryUtils";
import { ListQuery } from "../CrudRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import UserSignInRepo from "./UserSignInRepo";

export default class HttpUserSignInRepo extends HttpCrudRepo<UserSignIn> implements UserSignInRepo {
    pathComponents = ['mk', 'v1', 'reports', 'user-sign-in'];

    list(query: ListQuery): Promise<CollectionResponse<UserSignIn>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "user,company";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }
}
