import { Form, Formik } from "formik";
import { useRbac } from "@gsb/react-rbac";
import React from "react";
import { useRepo } from "../../../../../repos/useRepo";
import { actions } from "../../../../../rbac";
import { verticalFormLayout } from "../../../../form/styles/layout";
import { FormSubmitButton } from "../../../../form/buttons/FormSubmitButton";
import { UpdateApplicationStateIdFields } from "./ApplicationStateIdFields";
import { updateSubmitHandler } from "../../../../form/helpers/formSubmit";
import { Application, UpdateStateIdApplicationRequest } from "../../../../../models/sid/Application";
import { stateIdApplicationFieldSchema, initialValuesForStateIdApplicatioField } from "../../../../../utils/schemas/state-id-schema";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface UpdateApplicationFormProps {
    application: Application;
    onApplicationUpdated: (application: Application) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        header: {
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(1)
        }
    }),
);

export function UpdateApplicationStateIdForm({ application, onApplicationUpdated }: UpdateApplicationFormProps) {
    const { stateIdApplicationRepo } = useRepo();
    const { can } = useRbac();

    const classes = useStyles();

    const canUpdateApplication = can(actions.stateIdApplication.update);


    const onSubmit = updateSubmitHandler<UpdateStateIdApplicationRequest, Application>(
        (body: UpdateStateIdApplicationRequest) => stateIdApplicationRepo.update(application.id, body),
        initialValuesForStateIdApplicatioField,
        onApplicationUpdated,
        console.error,
    )
    const disabled = !canUpdateApplication;

    return (
        <>
            <h2 className={classes.header}>Application State Id's</h2>
            <Formik enableReinitialize={true}
                initialValues={initialValuesForStateIdApplicatioField(application)}
                validationSchema={stateIdApplicationFieldSchema}
                onSubmit={onSubmit}>
                <Form style={verticalFormLayout}>

                    <UpdateApplicationStateIdFields stateUnemploymentTaxLabel={application.stateApplication?.stateUnemploymentTaxLabel} stateWithholdingTaxLabel={application.stateApplication?.stateWithholdingTaxLabel} disabled={disabled} />

                    <FormSubmitButton variant='Update' disabled={disabled} />
                </Form>
            </Formik>
        </>
    );

}