import React, { useEffect, useRef } from "react";
import { queryResultFromArray } from "../../table/TableRepoAdapter";
import Table, { TableColumn, TableQuery } from "../../table/Table";
import TableColumnFactory from "../TableColumnFactory";
import { useRepo } from "../../../repos/useRepo";
import { Company, CompanyReferral } from "../../../models/Company";
import useCreateReferralForCompanyModal from "./create-referral/useCreateReferralForCompanyModal"
import { actions } from "../../../rbac";
import { useRbac } from '@gsb/react-rbac';
import { makeCreateAction } from "../../table/TableActionFactory";

interface Props {
    company: Company
}

export default function CompanyReferralsTable({ company }: Props) {
    const { companyRepo } = useRepo();
    const tableRef = useRef<any>(null);

    const { can } = useRbac();
    const canCreateUserReferral = can(actions.user.createReferral)

    const reloadData = () => tableRef.current.onQueryChange();

    const { createReferralForUserModal, openCreateReferralForUserModal } = useCreateReferralForCompanyModal(company, reloadData);

    useEffect(reloadData, [company.id]);

    const columns: TableColumn<CompanyReferral>[] = [
        TableColumnFactory.makeUserNameColumn('User', r => r.user),
        TableColumnFactory.fromProperty('Referral Status', 'status'),
        TableColumnFactory.fromProperty('Referral Type', 'type'),
        TableColumnFactory.fromProperty('Assigned To', 'assignedTo'),
        TableColumnFactory.dateTimeStaticColumn('Assigned On (ET)', 'assignedOn'),
        TableColumnFactory.fromProperty('Memo', 'note'),
        TableColumnFactory.dateTimeColumn('Scheduled For', 'scheduledOn'),
        TableColumnFactory.dateTimeColumn('Created', 'createdAt'),
    ];

    return (
        <>
            <Table<CompanyReferral>
                tableRef={tableRef}
                title={`Referrals for ${company.name}`}
                columns={columns}
                actions={[makeCreateAction(() => openCreateReferralForUserModal(), { disabled: !canCreateUserReferral })]}
                options={{
                    search: false,
                    filtering: false,
                    paging: false,
                    sorting: false
                }}
                data={async (tableQuery: TableQuery<CompanyReferral>) => {
                    const res = await companyRepo.listReferralsForCompanyById(company.id);
                    return queryResultFromArray(res)
                }}
            />
            {createReferralForUserModal}
        </>
    );
}