import {Product} from "../../models/Product";
import ProductRepo from "./ProductRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {Plan} from "../../models/Plan";
import {ProductLicense} from "../../models/ProductLicense";
import {sanitize} from "../../utils/FormUtils";
import {Application} from "../../models/Application";
import config from '../../config';

export default class HttpProductRepo extends HttpCrudRepo<Product> implements ProductRepo {
    pathComponents = ['mk', 'v1', 'products'];

    listLicensesForProductById = (productId: string) => {
        return this.http.get<ProductLicense[]>([...this.pathComponents, productId, 'licenses'], {});
    };

    listAvailableApplicationsForProductById = (productId: string) => {
        return this.http.get<Application[]>([...this.pathComponents, productId, 'applications'], {
            query: {
                status: 'available'
            }
        });
    };

    listPlansForProductById = (productId: string) => {
        return this.http.get<Plan[]>([...this.pathComponents, productId, 'plans'], {});
    };

    listAllProducts(): Promise<Array<Product>> {
        return this.list({pageSize: config.maxDropdownSize}).then(res => res.results);
    }

    create(body: object): Promise<Product> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<Product>): Promise<Product> {
        return super.update(id, sanitize(resource));
    }
}
