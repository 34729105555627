import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import { useField, useFormikContext } from "formik";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePickerProps,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { textInputStyle } from "../styles/field";
import DateUtils from "../../../utils/DateUtils";

//interface Props extends KeyboardDatePickerProps { }
type Props = {
    name: string,
    disableWeekends?: boolean,
    disabledHolidays?: string[]
} & Omit<KeyboardDatePickerProps, 'onChange' | 'value'>;

export function DateInput({ name, disableWeekends = false, disabledHolidays, ...TextFieldProps }: Props) {

    const [field, meta] = useField(name);
    const { value, ...fieldProps } = field;
    const { setFieldValue } = useFormikContext();

    const handleChange = (date: any, value: string | null | undefined) => {
        if (value === null || value === undefined) {
            setFieldValue(field.name, '');
        }
        else if (date instanceof Date && !isNaN(date.getTime())) {
            setFieldValue(field.name, date);
        }
    }

    const shouldDisableDate = (date: any) => {
        if (date) {
            if (disableWeekends) {
                // Disable Saturday and Sunday
                if(DateUtils.isAWeekend(date))
                    return true;
            }
            if (disabledHolidays) {
                // Disable Federal Holiday's
                if(DateUtils.isAHoliday(date, disabledHolidays))
                    return true;
            }
        }
        return false;
    }

    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            error={!!meta.error && meta.touched}
            helperText={meta.error}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format={"MM/dd/yyyy"}
            autoOk={true}
            style={{ ...textInputStyle }}
            value={value || null}
            onChange={handleChange}
            shouldDisableDate={shouldDisableDate}
            {...TextFieldProps}
        />
    </MuiPickersUtilsProvider>
}
