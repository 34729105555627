import {CollectionResponse} from "../../models";
import SubscriptionRepo from "./SubscriptionRepo";
import {Subscription} from "../../models/Subscription";
import {ListQuery} from "../CrudRepo";
import {
    CreateSubscriptionFileTypeRequest,
    SubscriptionFileType,
    UpdateSubscriptionFileTypeRequest
} from "../../models/SubscriptionFileType";
import {SubscriptionFileRecord, UpdateSubscriptionFileRecordBody} from "../../models/SubscriptionFileRecord";
import {SubscriptionFileUpload, SubscriptionFileUploadRequest} from "../../models/SubscriptionFileUpload";
import {sanitize} from "../../utils/FormUtils";
import HttpService from "@gsb/react-http";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';

export default class HttpSubscriptionRepo implements SubscriptionRepo {

    pathComponents = ['mk', 'v1', 'subscriptions'];

    constructor(private http: HttpService) {}

    listSubscriptions(query: ListQuery): Promise<CollectionResponse<Subscription>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        return this.http.get(this.pathComponents, { query: httpQuery });
    }

    createSubscription(body: object): Promise<Subscription> {
        return this.http.post(this.pathComponents, body);
    }

    getSubscriptionById(subscriptionId: string): Promise<Subscription> {
        return this.http.get([...this.pathComponents, subscriptionId]);
    }

    updateSubscriptionById(subscriptionId: string, body: object): Promise<Subscription> {
        return this.http.put([...this.pathComponents, subscriptionId], body);
    }

    cancelSubscriptionById(subscriptionId: string): Promise<Response> {
        return this.http.post([...this.pathComponents, subscriptionId, 'cancel'], {});
    }

    listSubscriptionFileUploads(query: ListQuery): Promise<CollectionResponse<SubscriptionFileUpload>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "fileType";
        return this.http.get([...this.pathComponents, 'files', 'uploads'], { query: httpQuery });
    }

    listSubscriptionFileRecords(query: ListQuery): Promise<CollectionResponse<SubscriptionFileRecord>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "upload,plan";
        return this.http.get([...this.pathComponents, 'files', 'records'], { query: httpQuery });
    }

    uploadSubscriptionFile(body: SubscriptionFileUploadRequest): Promise<void> {
        const data = new FormData();
        data.set('file', body.file);
        data.set('fileTypeId', body.fileTypeId);
        return this.http.post([...this.pathComponents, 'files', 'uploads'], data);
    }


    listSubscriptionFileTypes(query: ListQuery): Promise<CollectionResponse<SubscriptionFileType>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "plan";
        return this.http.get([...this.pathComponents, 'files', 'types'], { query: httpQuery });
    }

    listAllSubscriptionFileTypes(): Promise<Array<SubscriptionFileType>> {
        const query = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });

        return this.http
            .get<CollectionResponse<SubscriptionFileType>>([...this.pathComponents, 'files', 'types'], { query })
            .then(res => res.results);
    }

    createSubscriptionFileType(body: CreateSubscriptionFileTypeRequest): Promise<SubscriptionFileType> {
        return this.http.post([...this.pathComponents, 'files', 'types'], sanitize(body));
    }

    updateSubscriptionFileTypeById(subscriptionId: string, body: UpdateSubscriptionFileTypeRequest): Promise<SubscriptionFileType> {
        return this.http.put([...this.pathComponents, 'files', 'types', subscriptionId], sanitize(body));
    }

    updateSubscriptionFileRecordById(recordId: string, body: UpdateSubscriptionFileRecordBody): Promise<SubscriptionFileRecord> {
        return this.http.put([...this.pathComponents, 'files', 'records', recordId], sanitize(body));
    }
}
