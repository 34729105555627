import WebsiteAnalysisRepo from "./WebsiteAnalysisRepo";
import {WebsiteAnalysis, CreateWebsiteAnalysisRequest, UpdateWebsiteAnalysisRequest} from "../../models/WebsiteAnalysis";
import HttpService from "@gsb/react-http";
import {sanitize} from "../../utils/FormUtils";

export default class HttpWebsiteAnalysisRepo implements WebsiteAnalysisRepo {
    pathComponents = ['mk', 'v1', 'companies'];

    constructor(private http: HttpService) {}

    create(companyId: string, body: CreateWebsiteAnalysisRequest): Promise<WebsiteAnalysis> {
        return this.http.post([...this.pathComponents, companyId, 'website-analysis'], sanitize(body));
    };

    update(companyId: string, reportId: string, body: UpdateWebsiteAnalysisRequest): Promise<WebsiteAnalysis> {
        return this.http.post([...this.pathComponents, companyId, 'website-analysis', reportId], sanitize(body));
    };

    list(companyId: string): Promise<Array<WebsiteAnalysis>> {
        return this.http.get([...this.pathComponents, companyId, 'website-analysis'], {});
    };
}

