import { green, red, orange, yellow, blue } from "@material-ui/core/colors";

interface Demeanor {
    key: string;
    title: string;
    icon?: JSX.Element;
    color: string;
}

const values: { [key: string]: Demeanor } = {
    na: {
        key: 'n/a',
        title: 'N/A',
        color: '#fff'
    },
    happy: {
        key: 'happy',
        title: 'Happy',
        color: green[500]
    },
    normal: {
        key: 'normal',
        title: 'Normal',
        color: blue[500]
    },
    mildlyUpset: {
        key: 'mildlyUpset',
        title: 'Mildly Upset but Resolved',
        color: yellow[600]
    },
    upset: {
        key: 'upset',
        title: 'Angry',
        color: orange[600]
    },
    extremelyUpset: {
        key: 'extremelyUpset',
        title: 'Extremely Upset',
        color: red[500]
    }
}

export default values;