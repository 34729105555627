import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "@material-ui/core";
import React from "react";
import * as Yup from 'yup';
import { StatsReportRequest } from "../../../models/Report";
import { createSubmitHandler } from "../../form/helpers/formSubmit";
import { SalesChannelPicker } from "../../form/pickers/SalesChannelPicker";
import { ResellerPicker } from "../../form/pickers/ResellerPicker";
import { DateInput } from '../../form/inputs/DateInput';

interface Props<T> {
    initialValues: T;
    validationSchema: any;
    onSubmit: (values: T, actions: FormikHelpers<T>) => void;
}

function SearchForm<T>({ initialValues, validationSchema, onSubmit }: Props<T>) {
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ resetForm, handleSubmit }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <SalesChannelPicker name='salesChannelId' label='Sales Channel' allowEmpty={true} emptyValueDescription='-- ALL --' emptyValue='' />
                        <ResellerPicker name='resellerId' label='Reseller' allowEmpty={true} emptyValueDescription='-- ALL --' emptyValue='' />
                        <DateInput name='dateOn' label='Date' maxDate={new Date()} />
                        <Button type='submit' variant='contained' color='primary' style={{ margin: '14px' }}>Search</Button>
                        <Button type='submit' variant='contained' color='secondary' style={{ margin: '14px' }} onClick={() => { resetForm(); }}>Clear</Button>
                    </Form>
                );
            }}
        </Formik>
    )
}

export function StatsSearchForm(props: { initValues: StatsReportRequest, onSearch: (results: StatsReportRequest) => void }) {
    const { initValues, onSearch } = props;
    

    const validationSchema = Yup.object().shape({
        salesChannelId: Yup.string()
            .nullable(),

        dateOn: Yup.date()
            .required("Must enter from date")
    });

    const onSubmit =
        createSubmitHandler(
            (body: StatsReportRequest) => Promise.resolve(body),
            onSearch,
            console.error
    );

    return (
        <SearchForm initialValues={initValues} validationSchema={validationSchema} onSubmit={onSubmit} />
    );
}
