import * as React from 'react';
import { TableColumn } from '../table/Table';
import { Entity } from "../../models";
import DateFormatter from "../../utils/DateFormatter";
import { get } from 'lodash';
import { makeActiveDirectoryLink, makeSilkTideReportLink, makeStripePlanLink } from '../../factories/LinkFactory';
import theme from "../../theme";
import { Subscription } from "../../models/Subscription";
import { UrlTarget } from "../../config/Url";
import { Column } from 'material-table';
import { Link } from "react-router-dom";
import Routes from "../../Routes";
import { User, UserSignIn } from "../../models/User";
import { Company } from "../../models/Company";
import { Reseller } from "../../models/Reseller";
import { SalesChannel } from "../../models/SalesChannel";
import { Application } from "../../models/Application";
import ApplicationDropdown from "../table/dropdowns/ApplicationDropdown";
import ResellerDropdown from "../table/dropdowns/ResellerDropdown";
import { SubscriptionFileType } from "../../models/SubscriptionFileType";
import SubscriptionFileTypeDropdown from "../table/dropdowns/SubscriptionFileTypeDropdown";
import SubscriptionFileRecordStatusDropdown from "../table/dropdowns/SubscriptionFileRecordStatusDropdown";
import { Plan } from "../../models/Plan";
import PlanDropdown from "../table/dropdowns/PlanDropdown";
import SalesChannelDropdown from "../table/dropdowns/SalesChannelDropdown";
import { Product } from "../../models/Product";
import { Employee } from "../../models/Employee";
import { Site } from "../../models/ti/Site";
import { Topic } from "../../models/ti/Topic";
import { ProductLicense } from "../../models/ProductLicense";
import { SalesChannelApplication } from "../../models/SalesChannelApplication";
import { SubscriptionFileUpload } from "../../models/SubscriptionFileUpload";
import { SubscriptionFileRecord } from "../../models/SubscriptionFileRecord";
import { EmployeeCompany } from "../../models/EmployeeCompany";
import { EmployeeRole } from "../../models/EmployeeRole";
import DateRangePicker from "../table/pickers/DateRangePicker";
import { SiteTopic } from "../../models/ti/SiteTopic";
import { Sender } from "../../models/mail/Sender";
import { Trigger } from "../../models/mail/Trigger";
import { Template } from "../../models/mail/Template";
import EmployeeCompanyDropdown from "../table/dropdowns/EmployeeCompanyDropdown";
import TriggerDropdown from "../table/dropdowns/TriggerDropdown";
import SenderDropdown from "../table/dropdowns/SenderDropdown";
import TopicDropdown from "../table/dropdowns/TopicDropdown";
import { MailchimpAccount } from '../../models/MailchimpAccount';
import moment from 'moment';
import { StateApplication } from '../../models/sid/StateApplication';
import { Application as StateIdApplication } from '../../models/sid/Application';
import { ApplicationStatus } from '../../models/sid/ApplicationStatus';
import StateIdApplicationStatusDropdown from "../table/dropdowns/StateIdApplicationStatusDropdown";
import { FieldType } from '../../models/sid/FieldType';
import { StateApplicationSection } from '../../models/sid/StateApplicationSection';
import { WebsiteAnalysis } from '../../models/WebsiteAnalysis';

const emptyValue = '-';

export interface ColumnFactory<T extends object> {
  makeColumns: () => TableColumn<T>[];
}

interface FilterColumnDef<T extends object> extends Column<T> {
  title?: string;
  tableData: {
    columnOrder: number,
    filterValue: any;
  };
}

export interface FilterComponentProps<T extends object> {
  columnDef: FilterColumnDef<T>,
  onFilterChanged: (rowId: string, value: any) => void
}

function renderDate(dateString?: string | null) {
  if (dateString) {
    return DateFormatter.format(dateString, "l");
  } else {
    return null;
  }
}

function makeIdColumn<E extends Entity>(): TableColumn<E> {
  return {
    title: 'ID',
    field: 'id',
    sorting: false,
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
  };
}

function dateColumn<E extends Entity>(title: string, field: keyof E): TableColumn<E> {
  return {
    title,
    field,
    type: 'date',
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
  };
}

function dateTimeColumn<E extends Entity>(title: string, field: keyof E): TableColumn<E> {
  return {
    title,
    field,
    type: 'date',
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    render: rowData => moment.utc(rowData[field]).local().format('MM/DD/YYYY h:mm:ss a')
  };
}

function dateTimeStaticColumn<E extends Entity>(title: string, field: keyof E): TableColumn<E> {
  return {
    title,
    field,
    type: 'date',
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    render: rowData => moment.utc(rowData[field]).format('MM/DD/YYYY h:mm:ss a')
  };
}

function makeTimestampColumn<E extends Entity>(title: string, field: keyof E, date: (data: E) => string | undefined): TableColumn<E> {
  return {
    title,
    field,
    type: 'date',
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    // @ts-ignore we've declared a custom interface since the dependency seems to be missing a valid type
    filterComponent: props => <DateRangePicker {...props} />,
    render: data => renderDate(date(data))
  };
}

function makeCreatedAtColumn<E extends Entity>(): TableColumn<E> {
  return makeTimestampColumn('Created', 'createdAt', e => e.createdAt);
}

function makeUpdatedAtColumn<E extends Entity>(): TableColumn<E> {
  return makeTimestampColumn('Updated', 'updatedAt', e => e.updatedAt);
}

function makeEmailColumn<T extends object>(overrides?: TableColumn<T>): TableColumn<T> {
  return Object.assign({
    title: "Email",
    field: "email",
  }, overrides)
}

function makePhoneNumberColumn<T extends object>(overrides?: TableColumn<T>): TableColumn<T> {
  return Object.assign({
    title: "Phone Number",
    field: "phoneNumber",
  }, overrides)
}

function fromProperty<O extends object>(title: string,
  path: string,
  format?: (object: O) => string): TableColumn<O> {
  return {
    title,
    path: path,
    field: (path as keyof O), // This is a hack to get the filter component to appear
    render: (entity) => {
      const formatted = format ? format(entity) : get(entity, path);
      return formatted ?? emptyValue;
    }
  }
}

function makeStripePlanColumn<T extends object>(stripePlanIdResolver: ((data: T) => string | undefined)): TableColumn<T> {
  return {
    title: 'Stripe Plan',
    disableClick: true,
    render: data => {
      const stripePlanId = stripePlanIdResolver(data);

      if (stripePlanId) {
        const url = makeStripePlanLink(stripePlanId);
        return <a href={url} target={UrlTarget.blank}>{stripePlanId}</a>
      } else {
        return emptyValue
      }
    }
  }
}

function makeFileUploadMemberColumn<T extends object>(legalShieldMemberIdResolver: ((data: T) => string | undefined)): TableColumn<T> {
  return {
    title: 'Member ID',
    field: 'legalShieldMemberId',
    sorting: false,
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    disableClick: true,
    render: data => {
      const legalShieldMemberId = legalShieldMemberIdResolver(data);

      if (legalShieldMemberId) {
        // TODO: - Link to file upload record
        return legalShieldMemberId;
      } else {
        return emptyValue;
      }
    }
  }
}

function makeAdpOrganizationColumn<T extends object>(adpOrganizationIdResolver: ((data: T) => string | undefined)): TableColumn<T> {
  return {
    title: 'ADP Organization',
    field: 'adpOrganizationId',
    sorting: false,
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    disableClick: true,
    render: data => {
      const adpOrganizationId = adpOrganizationIdResolver(data);

      if (adpOrganizationId) {
        // TODO: - Link to ADP if we have a url for that
        return adpOrganizationId;
      } else {
        return emptyValue;
      }
    }
  }
}

function makeAdpAssociateColumn<T extends object>(adpAssociateIdResolver: ((data: T) => string | undefined)): TableColumn<T> {
  return {
    title: 'ADP Associate',
    field: 'adpAssociateId',
    sorting: false,
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    disableClick: true,
    render: data => {
      const adpAssociateId = adpAssociateIdResolver(data);

      if (adpAssociateId) {
        // TODO: - Link to ADP if we have a url for that
        return adpAssociateId;
      } else {
        return emptyValue;
      }
    }
  }
}

function makeAdpClientColumn<T extends object>(adpClientIdResolver: ((data: T) => string | undefined)): TableColumn<T> {
  return {
    title: 'ADP Client',
    field: 'adpClientId',
    sorting: false,
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    disableClick: true,
    render: data => {
      const adpClientId = adpClientIdResolver(data);

      if (adpClientId) {
        // TODO: - Link to ADP if we have a url for that
        return adpClientId;
      } else {
        return emptyValue;
      }
    }
  }
}

function makeIdLinkColumn<E extends Entity>(hrefProvider: (data: E) => string): TableColumn<E> {
  return {
    title: 'ID',
    field: 'id',
    sorting: false,
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    disableClick: true,
    render: data => makeLink(data.id, hrefProvider(data))
  };
}

function makeFieldLinkColumn<E extends Entity>(title: string, field: string, hrefProvider: (data: E) => string, fieldProvider: (data: E) => string, target: string = UrlTarget.self): TableColumn<E> {
  return {
    title: title,
    field: field,
    sorting: false,
    filtering: true,
    searchable: false,
    emptyValue: emptyValue,
    disableClick: true,
    render: data => makeLink(fieldProvider(data), hrefProvider(data), target)
  };
}

function makeActiveDirectoryColumn<T extends object>(subjectIdResolver: ((data: T) => string | undefined)): TableColumn<T> {
  return {
    title: 'AD B2C',
    disableClick: true,
    render: data => {
      const subjectId = subjectIdResolver(data);

      if (subjectId) {
        const url = makeActiveDirectoryLink(subjectId);
        return <a href={url} target={UrlTarget.blank}>{subjectId}</a>
      } else {
        return emptyValue
      }
    }
  }
}

function makeSilkTideReportColumn<T extends object>(title: string, reportIdResolver: ((data: T) => string | undefined)): TableColumn<T> {
  return {
    title,
    disableClick: true,
    render: data => {
      const reportId = reportIdResolver(data);

      if (reportId) {
        const url = makeSilkTideReportLink(reportId);
        return <a href={url} target={UrlTarget.blank}>{reportId}</a>
      } else {
        return emptyValue;
      }
    }
  }
}

function disableSearchAndFiltering<T extends Entity>(columns: TableColumn<T>[]): TableColumn<T>[] {
  return columns.map(column => ({
    ...column,
    filtering: false,
    searchable: false
  }))
}

function makeTimestampColumns<E extends Entity>(): TableColumn<E>[] {
  return [
    makeCreatedAtColumn(),
    makeUpdatedAtColumn(),
  ]
}

function withTimestampColumns<E extends Entity>(columns: TableColumn<E>[]): TableColumn<E>[] {
  return [
    ...columns,
    ...makeTimestampColumns<E>()
  ];
}

function makeSubscriptionTypeColumn(): TableColumn<Subscription> {
  return {
    title: 'Type',
    disableClick: true,
    render: data => {
      const { stripeSourceId, noChargeSource, appleAppStoreSource, invoice } = data;

      if (stripeSourceId) {
        return "Stripe"
      } else if (noChargeSource) {
        return "No Charge"
      } else if (appleAppStoreSource) {
        return "Apple App Store"
      } else if (invoice) {
        return "Invoice"
      } else {
        return null;
      }
    }
  };
}

function booleanColumn<O extends object>(title: string, path: string): TableColumn<O> {
  return {
    title,
    type: 'boolean',
    path: path,
    field: (path as keyof O), // This is a hack to get the filter component to appear
  }
}

const makeLink = (title: string, href: string, target: string = UrlTarget.self) => {
  return target == UrlTarget.self ? (<Link to={href}
    onClick={e => { e.stopPropagation() }}
    style={{ color: theme.palette.secondary.dark }}>
    {title}
  </Link>) :
    (<a href={href} target={target} style={{ color: theme.palette.secondary.dark }} title='Open Website URL in New Tab'>
      {title}
    </a>)
}

function makeObjectColumn<O extends object, E extends object>(
  title: string,
  path: string,
  entityProvider: ((object: O) => E | undefined),
  linkProvider: (entity: E) => { title: string, href: string }
): TableColumn<O> {
  return {
    title,
    path: path,
    field: (path as keyof O), // This is a hack to get the filter component to appear
    render: (object) => {
      const entity = entityProvider(object);

      if (entity) {
        const link = linkProvider(entity);
        return makeLink(link.title, link.href);
      } else {
        return emptyValue;
      }
    }
  }
}

function makeApplicationColumn<E extends object>(title: string, path: string, applicationProvider: ((entity: E) => Application | undefined)): TableColumn<E> {
  return {
    filterComponent: props => <ApplicationDropdown {...props as FilterComponentProps<any>} />,
    ...makeObjectColumn(title, path, applicationProvider, (application) => ({
      title: application.name,
      href: Routes.forApplicationById(application.id)
    })),
  }
}

function makePlanColumn<E extends object>(title: string, path: string, planProvider: ((entity: E) => Plan | undefined)): TableColumn<E> {
  return {
    title,
    path: path,
    field: (path as keyof E), // This is a hack to get the filter component to appear
    filterComponent: props => <PlanDropdown {...props as FilterComponentProps<any>} />,
    render: (entity) => {
      const plan = planProvider(entity)
      return plan?.name ?? emptyValue;
    }
  }
}

function makeTopicColumn<E extends object>(title: string, path: string, topicProvider: ((entity: E) => Topic | undefined)): TableColumn<E> {
  return {
    title,
    path: path,
    field: (path as keyof E), // This is a hack to get the filter component to appear
    filterComponent: props => <TopicDropdown {...props as FilterComponentProps<any>} />,
    render: (entity) => {
      const topic = topicProvider(entity)
      return topic ? makeLink(topic.name, Routes.ti.settings.forTopicById(topic.id)) : emptyValue;
    }
  }
}

function makeSubscriptionFileTypeColumn<E extends object>(title: string, path: string, fileTypeProvider: ((entity: E) => SubscriptionFileType | undefined)): TableColumn<E> {
  return {
    title,
    path: path,
    field: (path as keyof E), // This is a hack to get the filter component to appear
    filterComponent: props => <SubscriptionFileTypeDropdown {...props as FilterComponentProps<any>} />,
    render: (entity) => {
      const fileType = fileTypeProvider(entity)
      return fileType?.name ?? emptyValue;
    }
  }
}

function makeSubscriptionRecordStatusColumn<E extends object>(title: string, path: string, statusProvider: ((entity: E) => string | undefined)): TableColumn<E> {
  return {
    title,
    path: path,
    field: (path as keyof E), // This is a hack to get the filter component to appear
    filterComponent: props => <SubscriptionFileRecordStatusDropdown {...props as FilterComponentProps<any>} />,
    render: (entity) => {
      const status = statusProvider(entity);
      return status ?? emptyValue;
    }
  }
}

function makeCompanyNameColumn<E extends object>(title: string, path: string, companyProvider: ((entity: E) => Company | undefined)): TableColumn<E> {
  return makeObjectColumn(title, path, companyProvider, (company) => ({
    title: company.name,
    href: Routes.forCompanyById(company.id)
  }))
}

function makeEmployeeCompanyColumn<E extends object>(title: string, path: string, employeeCompanyProvider: ((entity: E) => EmployeeCompany | undefined)): TableColumn<E> {
  return {
    title,
    path: path,
    field: (path as keyof E), // This is a hack to get the filter component to appear
    filterComponent: props => <EmployeeCompanyDropdown {...props as FilterComponentProps<any>} />,
    render: (entity) => {
      const company = employeeCompanyProvider(entity);
      return company?.name ?? emptyValue;
    }
  }
}

function makeTriggerColumn<E extends object>(title: string, path: string, triggerProvider: ((entity: E) => Trigger | undefined)): TableColumn<E> {
  return {
    title,
    path: path,
    field: (path as keyof E), // This is a hack to get the filter component to appear
    filterComponent: props => <TriggerDropdown {...props as FilterComponentProps<any>} />,
    render: (entity) => {
      const trigger = triggerProvider(entity);
      return trigger?.name ?? emptyValue;
    }
  }
}

function makeSenderColumn<E extends object>(title: string, path: string, senderProvider: ((entity: E) => Sender | undefined)): TableColumn<E> {
  return {
    title,
    path: path,
    field: (path as keyof E), // This is a hack to get the filter component to appear
    filterComponent: props => <SenderDropdown {...props as FilterComponentProps<any>} />,
    render: (entity) => {
      const sender = senderProvider(entity);
      return sender?.name ?? sender?.email ?? emptyValue;
    }
  }
}

function makeResellerColumn<E extends object>(title: string, path: string, resellerProvider: ((entity: E) => Reseller | undefined)): TableColumn<E> {
  return {
    filterComponent: props => <ResellerDropdown {...props as FilterComponentProps<any>} />,
    ...makeObjectColumn(title, path, resellerProvider, (reseller) => ({
      title: reseller.name,
      href: Routes.forResellerById(reseller.id)
    })),
  }
}

function makeStateIdApplicationStatusColumn<E extends object>(title: string, path: string, applicationStatusProvider: ((entity: E) => ApplicationStatus | undefined)): TableColumn<E> {
  return {
    title,
    path: path,
    field: (path as keyof E), // This is a hack to get the filter component to appear
    filterComponent: props => <StateIdApplicationStatusDropdown {...props as FilterComponentProps<any>} />,
    render: (entity) => {
      const status = applicationStatusProvider(entity);
      return status?.name ?? emptyValue;
    }
  }
}

function makeSalesChannelColumn<E extends object>(title: string, path: string, salesChannelProvider: ((entity: E) => SalesChannel | undefined)): TableColumn<E> {
  return {
    filterComponent: props => <SalesChannelDropdown {...props as FilterComponentProps<any>} />,
    ...makeObjectColumn(title, path, salesChannelProvider, (salesChannel) => ({
      title: salesChannel.name,
      href: Routes.forSalesChannelById(salesChannel.id)
    })),
  }
}

function makeSiteSalesChannelColumn<E extends object>(title: string, path: string, siteProvider: ((entity: E) => Site | undefined)): TableColumn<E> {
  return {
    filterComponent: props => <SalesChannelDropdown {...props as FilterComponentProps<any>} />,
    ...makeObjectColumn(title, path, siteProvider, (site) => ({
      title: site.salesChannelName,
      href: Routes.forSalesChannelById(site.salesChannelId)
    })),
  }
}

function makeSiteResellerColumn<E extends object>(title: string, path: string, siteProvider: ((entity: E) => Site | undefined)): TableColumn<E> {
  return {
    filterComponent: props => <ResellerDropdown {...props as FilterComponentProps<any>} />,
    ...makeObjectColumn(title, path, siteProvider, (site) => ({
      title: site.resellerName,
      href: Routes.forResellerById(site.resellerId)
    })),
  }
}

function makeUserNameColumn<E extends object>(title: string, userProvider: (entity: E) => User | undefined): TableColumn<E> {
  return {
    title,
    filtering: false,
    sorting: false,
    render: (entity) => {
      const user = userProvider(entity)
      return user ? makeLink(`${user.firstName} ${user.lastName}`, Routes.forUserById(user.id)) : emptyValue;
    }
  }
}


const makeApplicationIdColumn = () => makeIdLinkColumn<Application>(a => Routes.forApplicationById(a.id));
const makeCompanyIdColumn = () => makeIdLinkColumn<Company>(c => Routes.forCompanyById(c.id));
const makeEmployeeIdColumn = () => makeIdLinkColumn<Employee>(e => Routes.forEmployeeById(e.id));
const makeEmployeeCompanyIdColumn = () => makeIdColumn<EmployeeCompany>();
const makeEmployeeRoleIdColumn = () => makeIdColumn<EmployeeRole>();
const makeUserIdColumn = () => makeIdLinkColumn<User>(u => Routes.forUserById(u.id));
const makeUserSignInIdColumn = () => makeFieldLinkColumn<UserSignIn>("User Id", "userId", u => Routes.forUserById(u.userId), u => u.userId);
const makeWebsiteAnalysisUrlColumn = () => makeFieldLinkColumn<WebsiteAnalysis>("URL", "url", w => w.url, w => w.url, '_blank');
const makePlanIdColumn = () => makeIdColumn<Plan>();
const makeProductIdColumn = () => makeIdLinkColumn<Product>(p => Routes.forProductById(p.id));
const makeProductLicenseIdColumn = () => makeIdColumn<ProductLicense>();
const makeSalesChannelIdColumn = () => makeIdLinkColumn<SalesChannel>(s => Routes.forSalesChannelById(s.id));
const makeSalesChannelApplicationIdColumn = () => makeIdColumn<SalesChannelApplication>();
const makeResellerIdColumn = () => makeIdLinkColumn<Reseller>(r => Routes.forResellerById(r.id));
const makeSiteIdColumn = () => makeIdLinkColumn<Site>(s => Routes.ti.settings.forSiteById(s.id));
const makeTopicIdColumn = () => makeIdLinkColumn<Topic>(t => Routes.ti.settings.forTopicById(t.id));
const makeSiteTopicIdColumn = () => makeIdColumn<SiteTopic>();
const makeSubscriptionIdColumn = () => makeIdColumn<Subscription>();
const makeSubscriptionFileUploadIdColumn = () => makeIdColumn<SubscriptionFileUpload>();
const makeSubscriptionFileRecordIdColumn = () => makeIdColumn<SubscriptionFileRecord>();
const makeSubscriptionFileTypeIdColumn = () => makeIdColumn<SubscriptionFileType>();
const makeSenderIdColumn = () => makeIdColumn<Sender>();
const makeTriggerIdColumn = () => makeIdColumn<Trigger>();
const makeStateApplicationIdColumn = () => makeIdLinkColumn<StateApplication>(st => Routes.sid.settings.forStateApplicationById(st.id));
const makeTemplateIdColumn = () => makeIdColumn<Template>();
const makeMailchimpAccountIdColumn = () => makeIdColumn<MailchimpAccount>();
const makeStateIdApplicationIdColumn = () => makeIdLinkColumn<StateIdApplication>(a => Routes.sid.forStateIdApplicationById(a.id));
const makeStateApplicationSectionIdColumn = () => makeIdColumn<StateApplicationSection>();
const makeFieldTypeIdColumn = () => fromProperty<FieldType>("ID", "id");

export default {
  makeApplicationIdColumn,
  makeCompanyIdColumn,
  makeEmployeeIdColumn,
  makeEmployeeCompanyIdColumn,
  makeEmployeeRoleIdColumn,
  makeEmployeeCompanyColumn,
  makeUserIdColumn,
  makePlanIdColumn,
  makeProductIdColumn,
  makeSalesChannelIdColumn,
  makeSalesChannelApplicationIdColumn,
  makeResellerIdColumn,
  makeSiteIdColumn,
  makeTopicIdColumn,
  makeSiteTopicIdColumn,
  makeProductLicenseIdColumn,
  makeSubscriptionIdColumn,
  makeSubscriptionFileUploadIdColumn,
  makeSubscriptionFileRecordIdColumn,
  makeSubscriptionFileTypeIdColumn,
  makeSenderIdColumn,
  makeTriggerIdColumn,
  makeTemplateIdColumn,
  makeSilkTideReportColumn,
  makeMailchimpAccountIdColumn,
  makeUserSignInIdColumn,

  makeEmailColumn,
  makePhoneNumberColumn,
  makeStripePlanColumn,
  makeActiveDirectoryColumn,
  makeFileUploadMemberColumn,
  makeAdpOrganizationColumn,
  makeAdpAssociateColumn,
  makeAdpClientColumn,
  disableSearchAndFiltering,
  withTimestampColumns,
  fromProperty,
  dateColumn,
  dateTimeColumn,
  dateTimeStaticColumn,
  makeSubscriptionTypeColumn,
  booleanColumn,
  makeApplicationColumn,
  makeResellerColumn,
  makeSalesChannelColumn,
  makePlanColumn,
  makeCompanyNameColumn,
  makeSubscriptionFileTypeColumn,
  makeSubscriptionRecordStatusColumn,
  makeSiteSalesChannelColumn,
  makeSiteResellerColumn,
  makeUserNameColumn,
  makeTriggerColumn,
  makeSenderColumn,
  makeTopicColumn,
  makeTimestampColumns,
  makeFieldLinkColumn,
  makeLink,
  // State Ids
  makeStateApplicationIdColumn,
  makeStateApplicationSectionIdColumn,
  makeStateIdApplicationIdColumn,
  makeStateIdApplicationStatusColumn,
  makeFieldTypeIdColumn,
  makeWebsiteAnalysisUrlColumn
}
