import React, { useEffect, useRef } from "react";
import { queryResultFromArray } from "../../../../../table/TableRepoAdapter";
import Table, { TableColumn, TableQuery } from "../../../../../table/Table";
import TableColumnFactory from "../../../../TableColumnFactory";
import { useRepo } from "../../../../../../repos/useRepo";
import { makeCreateAction, makeDeleteAction } from "../../../../../table/TableActionFactory";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../../../rbac";
import { StateApplication } from "../../../../../../models/sid/StateApplication";
import { StateApplicationSection } from "../../../../../../models/sid/StateApplicationSection";
import useCreateStateApplicationSectionModal from "../../../../../modals/useCreateStateApplicationSectionModal";
import useUpdateStateApplicationSectionModal from "../../../../../modals/useUpdateStateApplicationSectionModal";
import { useDeleteDialog } from "../../../../../dialog/ConfirmDialog";
import StateApplicationSectionPanel from "./StateApplicationSectionPanel";

interface Props {
    stateApplication: StateApplication
}

export default function StateApplicationSectionTable({ stateApplication }: Props) {
    const { stateApplicationRepo, stateApplicationSectionRepo } = useRepo();
    const { can } = useRbac();
    const canCreateStateApplicationSection = can(actions.stateApplication.createSection);
    const canUpdateStateApplicationSection = can(actions.stateApplication.updateSection);
    const canDeleteStateApplicationSection = can(actions.stateApplication.deleteSection);
    const tableRef = useRef<any>(null);

    const reloadData = () => tableRef.current.onQueryChange();

    const { setCreateStateApplicationSectionModalOpen, createStateApplicationSectionModal } = useCreateStateApplicationSectionModal(stateApplication, reloadData);
    const { setStateApplicationSectionModal, updateStateApplicationSectionModal } = useUpdateStateApplicationSectionModal(reloadData);
    const { openDeleteDialog, confirmDialog } = useDeleteDialog('Delete State Application Section', 'Are you sure you want to delete this state application section?', (e: StateApplicationSection) => deleteStateApplicationSection(e))


    useEffect(reloadData, [stateApplication.id]);

    const columns: TableColumn<StateApplicationSection>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.fromProperty("Name", "name"),
        TableColumnFactory.fromProperty("Sort Order", "sortOrder"),
    ]);

    const fetchData = async (tableQuery: TableQuery<StateApplicationSection>) => {
        const res = await stateApplicationRepo.listSections(stateApplication.id);
        return queryResultFromArray(res)
    };

    const deleteStateApplicationSection = (stateApplicationSection: StateApplicationSection) => {
        stateApplicationSectionRepo.delete(stateApplicationSection.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onStateApplicationSectionSelected = (stateApplicationSection: StateApplicationSection) => {
        if (canUpdateStateApplicationSection) {
            setStateApplicationSectionModal(stateApplicationSection)
        }
    };

    return (
        <>
            <Table<StateApplicationSection>
                tableRef={tableRef}
                title={`Sections for ${stateApplication.name}`}
                columns={TableColumnFactory.disableSearchAndFiltering(columns)}
                actions={[
                    makeCreateAction(() => setCreateStateApplicationSectionModalOpen(true), { disabled: !canCreateStateApplicationSection }),
                    makeDeleteAction(openDeleteDialog, { disabled: !canDeleteStateApplicationSection })]}
                options={{
                    search: false,
                    filtering: false,
                    paging: false
                }}
                detailPanel={stateApplicationSection => (
                     <StateApplicationSectionPanel stateApplicationSection={stateApplicationSection} onSubscriptionCanceled={reloadData}/>
                 )}
                onRowClick={onStateApplicationSectionSelected}
                data={fetchData}
            />
            {createStateApplicationSectionModal}
            {updateStateApplicationSectionModal}
            {confirmDialog}
        </>
    );
}
