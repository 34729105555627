import SortDirection from "../repos/helpers/SortDirection";
import {Filter, FilterOperator, FilterValue, ListQuery, Sort} from "../repos/CrudRepo";
import moment from "moment";
import {HttpQueryParams} from "@gsb/react-http";
import _ from "lodash";

export const formatValue = (value: FilterValue): string | null => {
    if (value === null) {
        return null;
    } else if (typeof value === 'string') {
        return `'${value}'`
    } else {
        return value.toString()
    }
};

export const getSortDirection = (isDesc: boolean) => isDesc ? SortDirection.Descending : SortDirection.Ascending;

export const makeStringForFilter = ({ field, value, operator }: Filter): string => {
    if (value instanceof Date) {
        const startOfDay = moment(value).startOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL);
        const endOfDay = moment(value).endOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL);
        return `${field} gt datetime'${startOfDay}' and ${field} lt datetime'${endOfDay}'`
    }
    else if( _.isArray( value ) && value[0] instanceof Date && value[1] instanceof Date) {
      const rangeStart = moment(value[0]).startOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL);
      const rangeEnd = moment(value[1]).endOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL);
      return `${field} gt datetime'${rangeStart}' and ${field} lt datetime'${rangeEnd}'`;
    }
    else if( _.isArray( value ) && moment.isMoment(value[0]) && moment.isMoment(value[1])) {
      const rangeStart = value[0].startOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL);
      const rangeEnd = value[1].endOf("day").format(moment.HTML5_FMT.DATETIME_LOCAL);
      return `${field} gt datetime'${rangeStart}' and ${field} lt datetime'${rangeEnd}'`;
    }
    else {
        const formattedValue = formatValue(value);
        const op: FilterOperator = operator || 'eq';
        return `${field} ${op} ${formattedValue}`;
    }
};

export const makeFilterString = (filters: Filter[]) => {
    return filters
        .filter(f => f.value !== undefined)
        .map(makeStringForFilter)
        .join(" and ")
};

export const makeSortString = (sort: Sort) => {
    return `${sort.field} ${sort.direction}`;
};

export const getHttpQueryFromListQuery = (query: ListQuery): HttpQueryParams => {
    let params: HttpQueryParams = {
        search: query.search,
        page: query.page,
        pageSize: query.pageSize
    };

    if (query.filters) {
        params.filter = makeFilterString(query.filters);
    }

    if (query.sort) {
        params.orderBy = makeSortString(query.sort);
    }

    return params;
};
