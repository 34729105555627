import * as React from "react";
import {NoChargeSubscriptionSource} from "../../../../../models/Subscription";
import {useNavigation} from "../../../../../services/navigation/useNavigation";
import {Product} from "../../../../../models/Product";

interface Props {
    noChargeSource: NoChargeSubscriptionSource;
}

type  DetailItem = {
    title: string,
    value: string;
    hidden?: boolean;
    onClick?: () => void;
}

export default function NoChargeSubscriptionDetail({ noChargeSource }: Props) {
    const { navigateToProduct, navigateToReseller, navigateToPlan } = useNavigation();
    const { reseller, plan } = noChargeSource;

    const items: DetailItem[] = [];

    if (reseller) {
        items.push({
            title: "Reseller",
            value: reseller.name,
            onClick: () => navigateToReseller(reseller)
        })
    }

    if (plan) {
        const { product } = plan;

        items.push({
            title: "Plan",
            value: plan.name,
            onClick: () => navigateToPlan(plan)
        });

        if (product) {
            items.push({
                title: "Product",
                value: product.name,
                onClick: () => navigateToProduct(product)
            })
        }
    }

    return (
        <table>
            {items.map(item => (
                <tr>
                    <td style={{ padding: 4 }}><b>{item.title}</b></td>
                    <td style={{ padding: 4, textAlign: 'left' }}><a onClick={item.onClick}>{item.value}</a></td>
                </tr>
            ))}
        </table>
    )
}
