import _ from "lodash";
import SubscriptionFileUpload from "./SubscriptionFileUpload";
import SubscriptionFileTypeList from "./SubscriptionFileTypeList";
import React, { useRef } from "react";
import SubscriptionFileUploadList from "./SubscriptionFileUploadList";
import {Paper} from "@material-ui/core";
import SubscriptionFileRecordList from "./SubscriptionFileRecordList";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";
import UpneticTab from "./tabs/upnetic/UpneticTab";

interface Props {

}

export const requiredActions = [
    actions.subscriptionFileUpload.list,
    actions.subscriptionFileRecord.list,
    actions.subscriptionFileType.list,
    actions.subscriptionFileUpload.create
];

export function Upload(props: Props) {
    const uploadsRef = useRef<any>(null);
    const recordsRef = useRef<any>(null);

    const { can } = useRbac();
    const canListUploads = can(actions.subscriptionFileUpload.list);
    const canListRecords = can(actions.subscriptionFileRecord.list);
    const canListTypes = can(actions.subscriptionFileType.list);
    const canUploadFile = can(actions.subscriptionFileUpload.create);
    const shouldShowList = canListUploads || canListRecords || canListTypes;

    const items: TabNavigationItem[] = [
        {
            name: "Uploads",
            identifier: "uploads",
            render: () => <SubscriptionFileUploadList ref={uploadsRef} onSubscriptionFileUploadSelected={() => console.log('onSubscriptionFileUploadSelected')}/>,
            hidden: !canListUploads,
        },
        {
            name: "Records",
            identifier: "records",
            render: () => <SubscriptionFileRecordList ref={recordsRef}/>,
            hidden: !canListRecords,
        },
        {
            name: "Types",
            identifier: "types",
            render: () => <SubscriptionFileTypeList/>,
            hidden: !canListTypes,
        },
        {
            name: "Upnetic",
            identifier: "upnetic",
            render: () => <UpneticTab/>
        },
    ];

    const onFileUploaded = () => {
        [ uploadsRef, recordsRef ].map( (ref: any) => {
            if( _.isFunction(_.get(ref.current, "reload")) ) ref.current.reload();
        })
    }

    return (
        <div>
            {canUploadFile &&
            <SubscriptionFileUpload onFileUploaded={onFileUploaded}/>
            }

            {shouldShowList &&
            <Paper style={{ marginTop: 24 }}>
              <TabNavigation items={items} disabledContentPadding/>
            </Paper>
            }
        </div>
    )
}