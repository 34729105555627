import React, {useRef} from 'react';
import Table, {TableColumn} from '../../table/Table';
import {Paper} from '@material-ui/core';
import TableColumnFactory from '../TableColumnFactory';
import {Company} from "../../../models/Company";
import useCrudController from "../useCrudController";
import {useRepo} from "../../../repos/useRepo";
import {getTableDataFetcherFromRepoFetcher} from "../../table/TableRepoAdapter";
import {actions} from "../../../rbac";
import useViewCompanyInStripeModal from "./view-in-stripe/useViewCompanyInStripeModal";
import {useCompanyMenu} from "./CompanyMenu";
import {makeCreateAction, makeMenuAction} from "../../table/TableActionFactory";
import useCreateUserModal from "../users/useCreateUserModal";
import {useRbac} from "@gsb/react-rbac";

export const requiredActions = [
  actions.company.list
];

export default function CompanyList() {
    const { companyRepo } = useRepo();
    const { can } = useRbac();
    const canCreateUser = can(actions.user.create);

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { openViewCompanyInStripeModal, viewCompanyInStripeModal } = useViewCompanyInStripeModal();
    const { companyMenu, openCompanyMenu } = useCompanyMenu(openViewCompanyInStripeModal);
    const { createUserModal, openCreateUserModal } = useCreateUserModal(reloadData);

    const columns: TableColumn<Company>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeCompanyIdColumn(),
        TableColumnFactory.makeCompanyNameColumn('Name', 'name', c => c),
        TableColumnFactory.makeSalesChannelColumn("Sales Channel", "salesChannelId", c => c.salesChannel),
        TableColumnFactory.makeResellerColumn("Reseller", "resellerId",c => c.reseller),
        TableColumnFactory.makeUserNameColumn("Owner", c => c.owner),
        TableColumnFactory.makeUserNameColumn("Primary Contact", c => c.primaryContact),
        TableColumnFactory.makeFileUploadMemberColumn(user => user.legalShieldMemberId),
        TableColumnFactory.makeAdpOrganizationColumn(user => user.adpOrganizationId),
        TableColumnFactory.makeAdpClientColumn(user => user.adpClientId),
        TableColumnFactory.booleanColumn("Disabled", "isDisabled"),
    ]);

    const {_list} = useCrudController(companyRepo, reloadData);

    return (
        <>
            <Paper>
                <Table<Company>
                    title='Companies'
                    columns={columns}
                    data={getTableDataFetcherFromRepoFetcher(_list)}
                    tableRef={tableRef}
                    actions={[
                        makeMenuAction(openCompanyMenu),
                        makeCreateAction(openCreateUserModal, { disabled: !canCreateUser }),
                    ]}
                />
            </Paper>
            {viewCompanyInStripeModal}
            {companyMenu}
            {createUserModal}
        </>
    );
}
