import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {Application} from "../../../models/Application";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

function BaseApplicationPicker(props: Props & { applications: Application[] }) {
    const { applications, ...PickerProps } = props;

    const options = useMemo(() => applications.map(p => ({ id: p.id, label: p.name || p.id })), [applications]);

    return <PickerInput label={'Application'} options={options} {...PickerProps} />
}

export function ApplicationPicker({ ...PickerProps }: Props) {
    const { applicationRepo } = useRepo();
    const [applications, setApplications] = useState<Application[]>([]);

    useEffect(() => {
        applicationRepo.listAll().then(setApplications).catch(console.error);
    }, []);

    return <BaseApplicationPicker applications={applications} {...PickerProps} />
}

export function ProductApplicationPicker(props: Props & { productId: string }) {
    const { productId, ...PickerProps } = props;

    const { productRepo } = useRepo();
    const [applications, setApplications] = useState<Application[]>([]);

    useEffect(() => {
        productRepo.listAvailableApplicationsForProductById(productId).then(setApplications).catch(console.error);
    }, []);

    return <BaseApplicationPicker applications={applications} {...PickerProps} />
}
