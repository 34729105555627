import * as Yup from 'yup';
import {Sender} from "../../models/mail/Sender";

const fields = {
    email: Yup.string().email().required("Email is required"),
    name: Yup.string().nullable()
};

export const createSenderSchema = Yup.object().shape(fields);

export const updateSenderSchema = Yup.object().shape(fields);

export const initialValuesForSender = (sender?: Sender) => ({
    name: sender?.name ?? "",
    email: sender?.email ?? ""
});
