import React, {useMemo} from "react";
import {License} from "../../../../../models/License";
import LicenseListModel from "../../../../../models/LicenseListModel";
import {LicenseSection} from "./LicenseSection";
import {LicenseGroupSection} from "./LicenseGroupSection";

interface Props {
    licenses: License[];
}

export default function SubscriptionDetailLicensesSection({ licenses }: Props) {
    const model = useMemo(() => LicenseListModel.fromLicenses(licenses), [licenses]);

    const assignableLicenseGroup = model.groupAssignableLicensesByApplication();

    return (
      <div>
          {model.hasCompanyLicenses && (
            <LicenseSection title={"Company Licenses"} licenses={model.companyLicenses}/>
          )}

          {model.hasAssignableLicenses && (
            <LicenseGroupSection title={"Licenses"} licenseGroups={assignableLicenseGroup}/>
          )}
      </div>
    );
}