import { StateApplicationField } from "../../../models/sid/StateApplicationField";
import { StateApplicationSection } from "../../../models/sid/StateApplicationSection";
import { HttpCrudRepo } from "../../HttpCrudRepo";
import StateApplicationSectionRepo from "./StateApplicationSectionRepo";
export class HttpStateApplicationSectionRepo extends HttpCrudRepo<StateApplicationSection> implements StateApplicationSectionRepo {
    pathComponents = ['sid', 'v1', 'state-application-section'];

    listFields(id: string): Promise<StateApplicationField[]> {
        return this.http.get([...this.pathComponents, id, 'fields']);
    }
}
