import React, {useEffect, useRef} from "react";
import {queryResultFromArray} from "../../table/TableRepoAdapter";
import { Paper } from '@material-ui/core';
import Table, {TableColumn, TableQuery} from "../../table/Table";
import TableColumnFactory from "../TableColumnFactory";
import {useRepo} from "../../../repos/useRepo";
import {Company} from "../../../models/Company";
import {useRbac} from "@gsb/react-rbac";
import MaterialTable, { Column } from 'material-table';
import {actions} from "../../../rbac";
import { Business } from "../../../models/Business";
import CompanyProfileDetail from "./CompanyProfileDetail";

interface Props {
    company: Company
}

export default function CompanyProfilesTable({ company }: Props) {
    const { companyRepo } = useRepo();
    const { can } = useRbac();
    const tableRef = useRef<any>(null);

    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<Business[] | undefined>();
    const reloadData = () => {
        companyRepo.listBusinessProfilesById(company.id).then(setResults).then(() => setLoading(false)).catch(console.error);
    }

    useEffect(reloadData, [company.id]);

    const columns: Column<Business>[] = [
        { title: 'Id', field: 'id' },
        { title: "Name", field: "name" },
        { title: "Owner", field: "ownerName" },
        { title: "Created", field: "createdAt", type: "date", defaultSort: "desc" },
        { title: "Updated", field: "updatedAt", type: "date" }
    ];

    return (
        <div>
        <Paper>
            <MaterialTable
                style={{ maxWidth: '100%' }}
                isLoading={loading}
                title={`Business Profiles for ${company.name}`}
                tableRef={tableRef}
                columns={columns}
                data={results ?? []}
                options={{
                    search: true,
                    filtering: true,
                    actionsColumnIndex: columns.length,
                    paging: true,
                    toolbar: true,
                }}
                detailPanel={rowData => (
                      <CompanyProfileDetail business={rowData} companyId={company.id} />
                    )}
            />
        </Paper>
    </div>
          
    );
}