import React from 'react';
import {Application} from "../../../models/Application";
import {DialogChildProps} from "../EntityDialog";
import ApplicationFields from "./ApplicationFields";
import {applicationSchema, initialValuesForApplication} from "../../../utils/schemas/application-schema";
import Form from "../../form/Form";

interface Props extends DialogChildProps {
  application?: Application;
  onValuesChange?: (draft: Partial<Application>) => void;
  isReadOnly?: boolean
}

export default function Detail({ application, isReadOnly, ...formProps}: Props) {
  return (
    <Form
      disabled={isReadOnly}
      validationSchema={applicationSchema}
      initialValues={initialValuesForApplication(application)}
      {...formProps}
    >
        <ApplicationFields disabled={isReadOnly}/>
    </Form>
  );
}
