import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useEffect, useMemo, useState} from "react";
import TabNavigation from "../../../tabs/TabNavigation";
import {Paper} from "@material-ui/core";
import {useRepo} from "../../../../repos/useRepo";
import {actions} from "../../../../rbac";
import LoadingIndicator from "../../../LoadingIndicator";
import {useNavigation} from "../../../../services/navigation/useNavigation";
import {Breadcrumbs} from "../../../layout/Breadcrumbs";
import {BreadcrumbFactory, SiteDetailBreadcrumbFactory} from "../../../../factories/BreadcrumbFactory";
import {Site} from "../../../../models/ti/Site";
import SiteTopicTable from "./SiteTopicTable";

interface Params {
    siteId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
  actions.siteTopic.list,
];

function SiteDetailController(props: Props) {
    const [site, setSite] = useState<Site>();
    const { siteRepo } = useRepo();

    const siteId = useMemo(() => props.match.params.siteId, [props.match.params.siteId]);

    const loadSite = () => {
        siteRepo.getById(siteId).then(setSite).catch(console.error);
    };

    useEffect(loadSite, [siteRepo, siteId]);

    if (site) {
        return <SiteDetail site={site}/>
    } else {
        return <LoadingIndicator/>
    }
}

function SiteDetail(props: { site: Site }) {
    const { site } = props;
    const { navigateToSites } = useNavigation();

    const breadcrumbFactory: BreadcrumbFactory = new SiteDetailBreadcrumbFactory(site, navigateToSites);

    return (
        <div>
            <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

            <Paper>
                <TabNavigation items={[
                    {
                        name: 'Topics',
                        identifier: 'topics',
                        render: () => <SiteTopicTable site={site}/>
                    },
                ]} disabledContentPadding/>
            </Paper>
        </div>
    )
}

export default withRouter(SiteDetailController);