import * as Yup from 'yup';
import {Template} from "../../models/mail/Template";

const baseFields = {
    name: Yup.string().required("Name is required"),
    externalTemplateId: Yup.string().required("External template ID is required"),
    triggerId: Yup.string().required("Trigger is required"),
    senderId: Yup.string().required("Sender is required"),
    salesChannelId: Yup.string().nullable(),
    resellerId: Yup.string().nullable()
};

export const createTemplateSchema = Yup.object().shape(baseFields);

export const updateTemplateSchema = Yup.object().shape(baseFields);

export const initialValuesForTemplate = (template?: Template) => ({
    name: template?.name ?? "",
    externalTemplateId: template?.externalTemplateId ?? "",
    triggerId: template?.triggerId ?? "",
    senderId: template?.senderId ?? "",
    salesChannelId: template?.salesChannelId ?? "",
    resellerId: template?.resellerId ?? "",
});
