import React, { useMemo, useCallback } from "react";
import DemeanorPicker from "../../../form/pickers/DemeanorPicker";
import CallTypePicker from "../../../form/pickers/CallTypePicker";
import CallStatusPicker from "../../../form/pickers/CallStatusPicker";
import CallSourcePicker from "../../../form/pickers/CallSourcePicker";
import { CompanyUserPicker } from "../../../form/pickers/UserPicker";
import TextInput from "../../../form/inputs/TextInput";
import { useFormikContext } from "formik";
import { UpdateNoteForCompanyRequest } from "../../../../models/Company";


interface Props {
    companyId: string,
    disabled?: boolean;
    isThread: boolean;
}

export function UpdateCompanyNoteFields({ companyId, disabled, isThread = false }: Props) {
    const { values, setFieldValue } = useFormikContext<UpdateNoteForCompanyRequest>();

    const { source, userId } = values;
    const emptyGuid = '00000000-0000-0000-0000-000000000000';

    const sourceWLink = ['Live Chat', 'Support Center Email'];
    const isSourceLink = useMemo(() => source?.length > 0 && sourceWLink.indexOf(source) > -1, [source]);
    const isUserOther = useMemo(() => userId === emptyGuid, [userId]);

    useCallback(() => {
        console.log('Callback Called');
        if (source === 'Call') {
            setFieldValue('sourceLink', '');
        }
        if (userId && userId !== emptyGuid) {
            setFieldValue('userDisplayName', '');
        }
    }, [setFieldValue]);

    const optionalUser = { id: emptyGuid, firstName: 'OTHER', lastName: '', subjectId: '', email: '', companyId: '', salesChannelId: '', resellerId: '', createdAt: '', updatedAt: ''}


    return (
        <>
            <DemeanorPicker name='demeanor' label='Customer Demeanor' disabled={disabled} />

            <CallStatusPicker name='status' label='Call Status' disabled={disabled} />

            <CallSourcePicker name='source' label='Call Source' disabled={disabled} />

            {isSourceLink && (
                <TextInput name='sourceLink' label='Source Link' disabled={disabled} helperText={'Link to Live Chat Transcript or Support Center Email'} />
            )}

            {!isThread && ( 
                <CallTypePicker name='callType' label='Call Type' disabled={disabled} allowEmpty={true} emptyValue={null} emptyValueDescription={'NONE'} />
             )}            

            <CompanyUserPicker companyId={companyId} name='userId' label='User' disabled={disabled} allowEmpty={true} emptyValue={null} emptyValueDescription={'NONE'} allowOptionalUser={true} optionalUser={optionalUser} />

            {isUserOther && (
                <TextInput name='userDisplayName' label='User Other' disabled={disabled} />
            )}

        </>
    )
}

export function CreateCompanyNoteFields({ companyId, disabled, isThread }: Props) {
    return (
        <>
            <UpdateCompanyNoteFields companyId={companyId} disabled={disabled} isThread={isThread} />
        </>
    )
}
