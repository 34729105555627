import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {SalesChannel} from "../../../models/SalesChannel";

export default function SalesChannelDropdown(props: FilterComponentProps<any>) {
    const { salesChannelRepo } = useRepo();
    const [salesChannels, setSalesChannels] = useState<SalesChannel[]>([]);

    useEffect(() => {
        salesChannelRepo.listAll().then(setSalesChannels).catch(console.error);
    }, [salesChannelRepo]);
    
    return (
        <DropdownPicker options={salesChannels} {...props}/>
    )
}