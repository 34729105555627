import React, {useMemo} from "react";
import {Topic} from "../../../models/ti/Topic";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {
    topics: Topic[];
}

export default function TopicPicker({ topics, ...PickerProps }: Props) {
    const options = useMemo(() => topics.map(t => ({ id: t.id, label: t.name})), [topics]);

    return <PickerInput label={'Topic'} options={options} {...PickerProps} />
}
