import {Topic, CreateTopicRequest, UpdateTopicRequest} from "../../../../models/ti/Topic";
import {Form, Formik} from "formik";
import TopicFields from "./TopicFields";
import {VerticalFormLayout} from "../../../form/FormLayout";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {updateSubmitHandler, createSubmitHandler} from "../../../form/helpers/formSubmit";
import {
    createTopicSchema,
    initialValuesForTopic,
    updateTopicSchema
} from "../../../../utils/schemas/topic-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";

export function CreateTopicForm(props: { onTopicCreated: (topic: Topic) => void }) {
    const { onTopicCreated }  = props;
    const { topicRepo } = useRepo();
    const { can } = useRbac();

    const canCreateTopic = can(actions.topic.create);

    const onSubmit = createSubmitHandler(
        (body: CreateTopicRequest) => topicRepo.create(body),
        onTopicCreated, console.error
    );

    return (
        <Formik<CreateTopicRequest> initialValues={initialValuesForTopic()} validationSchema={createTopicSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<TopicFields disabled={!canCreateTopic}/>}
                                    bottomContent={<FormSubmitButton variant='Create' disabled={!canCreateTopic}/>}/>
            </Form>
        </Formik>
    )
}

export function UpdateTopicForm(props: { topic: Topic, onTopicUpdated: (topic: Topic) => void }) {
    const { topic, onTopicUpdated }  = props;
    const { topicRepo } = useRepo();
    const { can } = useRbac();

    const canUpdateTopic = can(actions.topic.update);

    const onSubmit = updateSubmitHandler<UpdateTopicRequest, Topic>(
        (body: UpdateTopicRequest) => topicRepo.update(topic.id, body),
        initialValuesForTopic,
        onTopicUpdated,
        console.error,
    );

    return (
        <Formik<UpdateTopicRequest> enableReinitialize={true}
                                    initialValues={initialValuesForTopic(topic)}
                                    validationSchema={updateTopicSchema}
                                    onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<TopicFields disabled={!canUpdateTopic}/>}
                                    bottomContent={<FormSubmitButton variant='Update' disabled={!canUpdateTopic}/>}/>
            </Form>
        </Formik>
    )
}

export const CreateTopicModal = withModal(CreateTopicForm, "Create Topic");
export const UpdateTopicModal = withModal(UpdateTopicForm, "Update Topic");
