import React from "react";
import {NavigationItem} from "./components/layout/SideNav";
import Routes from "./Routes";
import UsersIcon from "@material-ui/icons/People";
import UploadIcon from "@material-ui/icons/CloudUpload";
import ReportIcon from "@material-ui/icons/BarChart";
import BrandIcon from "@material-ui/icons/Label";
import CompanyIcon from "@material-ui/icons/Work";
import ApplicationIcon from "@material-ui/icons/Apps";
import EmployeeIcon from "@material-ui/icons/SupervisedUserCircle";
import ProductIcon from "@material-ui/icons/ShoppingCart";
import MailIcon from "@material-ui/icons/Mail";
import DonutLargeIcon from '@material-ui/icons/DonutLarge';

import SettingsIcon from "@material-ui/icons/Settings";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import StarIcon from "@material-ui/icons/Star";
import HistoryIcon from "@material-ui/icons/History";
import GavelIcon from '@material-ui/icons/Gavel';

export const navigationItems: { [key: string]: NavigationItem } = {
    upload: {
        key: 'upload',
        title: 'Upload',
        icon: <UploadIcon />,
        route: Routes.subscriptionFileUpload,
    },
    mail: {
        key: 'mail',
        title: 'Mail',
        icon: <MailIcon />,
        route: Routes.mail.home,
    },
    integrations: {
        key: 'integrations',
        title: 'Integrations',
        icon: <DonutLargeIcon />,
        route: Routes.integrations
    },
    companies: {
        key: 'companies',
        title: "Companies",
        icon: <CompanyIcon/>,
        route: Routes.companies,
    },
    companyDetail: {
        key: 'company-detail',
        route: Routes.companyById,
    },
    users: {
        key: 'users',
        title: 'Users',
        icon: <UsersIcon/>,
        route: Routes.users,
    },
    userDetail: {
        key: 'user-detail',
        route: Routes.userById,
    },
    applications: {
        key: 'applications',
        title: 'Applications',
        icon: <ApplicationIcon/>,
        route: Routes.applications,
    },
    applicationDetail: {
        key: 'application-detail',
        route: Routes.applicationById,
    },
    brands: {
        key: 'sales-channels',
        title: 'Brands',
        icon: <BrandIcon/>,
        route: Routes.brands,
    },
    salesChannels: {
        key: 'sales-channels',
        title: 'Sales Channels',
        icon: <BrandIcon/>,
        route: Routes.salesChannels,
    },
    salesChannelDetail: {
        key: 'sales-channel-detail',
        route: Routes.salesChannelById,
    },
    resellers: {
        key: 'resellers',
        title: 'Resellers',
        icon: <BrandIcon/>,
        route: Routes.resellers,
    },
    products: {
        key: 'products',
        title: 'Products',
        icon: <ProductIcon/>,
        route: Routes.products,
    },
    productDetail: {
        key: 'product-detail',
        route: Routes.productById,
    },
    employees: {
        key: 'employees',
        title: 'Employees',
        icon: <EmployeeIcon/>,
        route: Routes.employees,
    },
    employeeDetail: {
        key: 'employee-detail',
        route: Routes.employeeById,
    },
    reports: {
        key: 'reports',
        title: 'Reports',
        icon: <ReportIcon/>,
        route: Routes.reports,
    },
    tarkentonInstituteSettings: {
        key: 'ti-settings',
        title: 'Settings',
        icon: <SettingsIcon />,
        route: Routes.ti.settings.home,
    },
    questions: {
        key: "ti-questions",
        title: "Questions",
        route: Routes.ti.questions,
        icon: <QuestionAnswerIcon />
    },
    questionDetail: {
        key: "ti-question-detail",
        title: "Tarkenton Institute",
        route: Routes.ti.questionById,
    },
    answers: {
        key: "ti-answers",
        title: "Answers",
        route: Routes.ti.answers,
        icon: <HistoryIcon />
    },
    faqs: {
        key: "ti-faqs",
        title: "FAQs",
        route: Routes.ti.faqs,
        icon: <StarIcon />
    },
    faqDetail: {
        key: "ti-faq-detail",
        title: "Tarkenton Institute",
        route: Routes.ti.faqById,
    },
    stateIdSettings: {
        key: "sid-settings",
        title: "State Id Settings",
        route: Routes.sid.settings.home,
        icon: <SettingsIcon />
    },
    stateApplicationDetail: {
        key: 'sid-state-application-detail',
        title: 'State Applications',
        route: Routes.sid.settings.stateApplicationById,
    },
    stateIdApplications: {
        key: "sid-applications",
        title: "State Id Applications",
        route: Routes.sid.stateIdApplications,
        icon: <GavelIcon />
    },
    stateIdApplicationDetail: {
        key: 'sid-application-detail',
        title: 'State Id Application',
        route: Routes.sid.stateIdApplicationById
    }
};
