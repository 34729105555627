import {useRbac} from "@gsb/react-rbac";
import {useRepo} from "../../../../repos/useRepo";
import {actions} from "../../../../rbac";
import {Chip, Typography} from "@material-ui/core";
import ChipButton from "../../../form/chips/ChipButton";
import * as React from "react";
import {EmployeeRole} from "../../../../models/EmployeeRole";
import {Employee} from "../../../../models/Employee";
import useAssignRolesToEmployeeModal from "../../../modals/useAssignRolesToEmployeeModal";

interface Props {
    employee: Employee;
    roles: EmployeeRole[];
    onRoleRevoked: (role: EmployeeRole) => void;
    onAddRole: () => void
}

export default function RolesTab({ employee, roles, onRoleRevoked, onAddRole }: Props) {
    const { can } = useRbac();
    const { employeeRepo } = useRepo();

    const { setEmployeeForAssigningRoles, assignEmployeeRolesModal } = useAssignRolesToEmployeeModal(onAddRole);

    const canAssignRole = can(actions.employee.assignRole);
    const canRevokeRole = can(actions.employee.revokeRole);
    const canAssignSysAdminRole = can(actions.employee.assignSysAdminRole);
    const canRevokeSysAdminRole = can(actions.employee.revokeSysAdminRole);

    function revokeRole(role: EmployeeRole) {
        employeeRepo.revokeRolesForEmployeeById(employee.id, { roleIds: [role.id] })
            .then(() => onRoleRevoked(role))
            .catch(console.error)
    }

    function canRevokeEmployeeRole(role: EmployeeRole)  {
        if(role.identifier === 'gsbSysAdmin')
            return canRevokeSysAdminRole;
        else
            return canRevokeRole;
    }

    return (
        <>
            <div style={{ marginTop: 6, marginBottom: 6 }}>
                <Typography variant='caption' display='block'>Roles</Typography>

                <div>
                    {roles.map((role, index) => (
                        <Chip key={`role-chip-${index}`}
                              label={role.name}
                              style={{ margin: 3 }}
                              disabled={!canRevokeEmployeeRole(role)}
                              onDelete={canRevokeRole ? () => revokeRole(role) : undefined}/>
                    ))}

                    {canAssignRole &&
                    <ChipButton label='Assign Role' color='secondary' onClick={() => setEmployeeForAssigningRoles({...employee, allowSysAdminRole: canAssignSysAdminRole})}/>
                    }

                </div>

            </div>

            {assignEmployeeRolesModal}
        </>
    )
}
