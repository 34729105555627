import {User} from "../../../../models/User";
import {useRbac} from "@gsb/react-rbac";
import {useRepo} from "../../../../repos/useRepo";
import {actions} from "../../../../rbac";
import ChipButton from "../../../form/chips/ChipButton";
import * as React from "react";
import useAssignLicensesToUserModal from "../../../modals/useAssignLicensesToUserModal";
import {Chip} from "../../../form/chips/Chip";
import {License} from "../../../../models/License";

interface Props {
    user: User;
    licenses: License[];
    onLicensesAssigned: (licenses: License[]) => void;
    onLicenseRevoked: (license: License) => void;
}

export function LicensesTab({ user, licenses, onLicensesAssigned, onLicenseRevoked }: Props) {
    const { can } = useRbac();
    const { userRepo } = useRepo();

    const canAssignLicense = can(actions.user.assignLicense);
    const canRevokeLicense = can(actions.user.revokeLicense);

    const { setUserForAssigningLicenses, assignUserLicensesModal } = useAssignLicensesToUserModal(onLicensesAssigned);

    function revokeLicense(license: License) {
        userRepo.revokeLicensesForUserById(user.id, { licenseIds: [license.id] })
            .then(() => onLicenseRevoked(license))
            .catch(console.error)
    }

    return (
        <>
          <div style={{ marginTop: 6, marginBottom: 6 }}>
              {licenses.map((license, index) => (
                  <Chip key={`license-chip-${index}`}
                        disabled={!canRevokeLicense}
                        label={license.application ? license.application.name : license.name}
                        onDelete={canRevokeLicense ? () => revokeLicense(license) : undefined}/>
              ))}

              {canAssignLicense &&
              <ChipButton label='Add License' color='secondary' onClick={() => setUserForAssigningLicenses(user)}/>
              }
          </div>
            {assignUserLicensesModal}
        </>
    )
}

