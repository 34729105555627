import React, { useRef, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { Company, CompanyNote } from "../../../models/Company"
import { useRepo } from "../../../repos/useRepo";
import { actions } from "../../../rbac";
import MaterialTable, { Column } from 'material-table';
import { useCompanyNoteMenu } from './notes/CompanyNoteMenu';
import { CompanyNoteDemeanorAvatar } from './notes/CompanyNoteDemeanor';
import { useRbac } from '@gsb/react-rbac';
import { makeCreateAction, makeMenuAction } from '../../table/TableActionFactory';
import useCreateCompanyNoteModal from '../../modals/useCreateCompanyNoteModal';
import useUpdateCompanyNoteModal from '../../modals/useUpdateCompanyNoteModal';


export const requiredActions = [
    actions.company.listNotes
];

interface Props {
    company: Company
}

export default function CompanyNotesTable({ company }: Props) {

    const tableRef = useRef<any>(null);
    const { companyRepo } = useRepo();

    const { can } = useRbac();
    const canCreateCompanyNote = can(actions.company.createNote)
    const canDeleteCompanyNote = can(actions.company.deleteNote)
    const canUpdateCompanyNote = can(actions.company.updateNote);

    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<CompanyNote[] | undefined>();

    const reloadData = () => {
        companyRepo.listNotesForCompanyById(company.id).then(setResults).then(() => setLoading(false)).catch(console.error);
    }

    const handleCompanyNoteDelete = async (note: CompanyNote) => {
        const userRes = window.confirm('Are you sure you want to delete this item?');
        if (userRes) {
            companyRepo.deleteNoteForCompanyById(company.id, note.id).then(reloadData).catch(console.error);
        }
        return;
    }

    const { createCompanyNoteModal, setCreateCompanyNoteModalOpen } = useCreateCompanyNoteModal(company.id, reloadData);
    const { updateCompanyNoteModal, setCompanyNoteForUpdate } = useUpdateCompanyNoteModal(company.id, reloadData);
    const { companyNoteMenu, openCompanyNoteMenu } = useCompanyNoteMenu(
        (canDeleteCompanyNote ? handleCompanyNoteDelete : undefined),
        (canUpdateCompanyNote ? setCompanyNoteForUpdate : undefined),
        (canCreateCompanyNote ? (entity) => setCreateCompanyNoteModalOpen({ open: true, threadId: entity.id}) : undefined));

    const columns: Column<CompanyNote>[] = [
        { title: 'Note Id', field: 'id', export: true, hidden: true },
        { title: "Note", field: "body", emptyValue: "-", headerStyle: { width: '30%', minWidth: 250 }, cellStyle: { width: '30%', minWidth: 250 } },
        { title: "Demeanor", field: "demeanor", align: 'center', render: (entity) => { return (<CompanyNoteDemeanorAvatar companyNote={entity} />) }, filtering: false },
        { title: "Call Status", field: "status", emptyValue: "-" },
        { title: "Call Source", field: "source", emptyValue: "-", render: (entity) => { return (<CompanyNoteSource source={entity.source} sourceLink={entity.sourceLink} />)} },
        { title: "Call Type", field: "callType", emptyValue: "-" },
        { title: "User", field: "userDisplayName", emptyValue: "-" },
        { title: "Created At", field: "createdAt", type: "date", defaultSort: "desc" },
        { title: "Created By", field: "createdBy", emptyValue: "-" },
    ];

    useEffect(reloadData, [company.id]);

    return (
        <div>
            <Paper>
                <MaterialTable
                    style={{ maxWidth: '100%' }}
                    isLoading={loading}
                    title={`Notes for ${company.name}`}
                    tableRef={tableRef}
                    columns={columns}
                    data={results ?? []}
                    parentChildData={(row, rows) => rows.find(a => a.id === row.threadId)}
                    actions={[
                        makeMenuAction(openCompanyNoteMenu),
                        makeCreateAction(() => setCreateCompanyNoteModalOpen({ open: true }), { disabled: !canCreateCompanyNote})]}
                    options={{
                        search: true,
                        filtering: true,
                        actionsColumnIndex: columns.length,
                        paging: true,
                        toolbar: true,
                        rowStyle: rowData => ({
                            backgroundColor: !!rowData.threadId ? '#fafafa' : '#ffffff'
                        }),
                    }}
                />
            </Paper>
            {createCompanyNoteModal}
            {updateCompanyNoteModal}
            {companyNoteMenu}
        </div>
    );
}

function CompanyNoteSource(props: { source: string, sourceLink?: string }) {
    const { source, sourceLink } = props;

    if (sourceLink) {
        return (<a href={sourceLink} target='_blank' rel="noopener noreferrer" title={sourceLink}>{source}</a>);
    }

    return (<>{source}</>);
}