import * as React from 'react';
import { Application } from "../../../../models/sid/Application";
import Statuses from "../../../../config/state-id-application-statuses"
import { Chip, Tooltip } from "@material-ui/core";

export function StateIdApplicationStatusChip(props: {application: Application}) {
    const {application} = props;
    const status = getApplicationStatus(application);
    
    return (<Tooltip title={status.title}><Chip style={{ background: status.backgroundColor, color: status.color, display: 'flex', justifyContent: 'center' }} label={status.text}></Chip></Tooltip>);
}

const getApplicationStatus = (application: Application) => {
    const {status} = application;
    const d = Statuses[status?.identifier ?? 'pending'];
    d.text = status?.name ?? "Pending";
    return d;
}