import * as React from "react";
import {useState} from "react";
import { ApplicationAssignAgentModal } from "../entities/state-id/applications/ApplicationAssignAgentForm";

export default function useAssignAgentToApplicationModal( onAgentAssigned: () => void) {

    const [applicationIds, setApplicationIds] = useState<string[]>();

    const handleAgentAssigned = () => {
        setApplicationIds(undefined);
        onAgentAssigned();
    };

    const modal = applicationIds ? (
        <ApplicationAssignAgentModal
            open={true}
            title={"Assign Agent to Application(s)"}
            onClose={() => setApplicationIds(undefined)}
            onApplicationAssignUpdated={handleAgentAssigned}
            applicationIds={applicationIds}
        />
    ) : null;

    return { setAssignAgentToApplicationModal: setApplicationIds, assignAgentToApplicationModal: modal }
}
