import * as React from "react";
import {useState} from "react";
import {Company} from "../../../../models/Company";
import {ViewCompanyInStripeModal} from "./ViewCompanyInStripeModal";

export default function useViewCompanyInStripeModal() {

    const [company, setCompany] = useState<Company>();
    const close = () => setCompany(undefined);

    const modal = company ? (
        <ViewCompanyInStripeModal
            open={true}
            onClose={close}
            company={company}
            title={`Stripe Customers for ${company.name}`}
        />
    ) : null;

    return {
        openViewCompanyInStripeModal: (company: Company) => setCompany(company),
        viewCompanyInStripeModal: modal
    }
}
