import * as React from "react";
import {useState} from "react";
import {CompanyNote} from "../../models/Company";
import {CreateCompanyNoteModal} from "../entities/companies/notes/CompanyNoteForm";

export default function useCreateCompanyNoteModal(companyId: string, onCompanyNoteCreated: (companyNote: CompanyNote) => void, prefix?: string) {

    const [open, setOpen] = useState<{open: boolean, threadId?: string}>({ open: false});

    const handleCompanyNoteCreated = (companyNote: CompanyNote) => {
        setOpen({ open: false});
        onCompanyNoteCreated(companyNote)
    };

    const modal = (
        <CreateCompanyNoteModal
            open={open.open}
            onClose={() => setOpen({ open: false })}
            companyId={companyId}
            onCompanyNoteCreated={handleCompanyNoteCreated}
            prefix={prefix}
            threadId={open.threadId}
        />
    );

    return { setCreateCompanyNoteModalOpen: setOpen, createCompanyNoteModal: modal }
}
