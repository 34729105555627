import {SalesChannel} from "../../../../models/SalesChannel";
import {useRepo} from "../../../../repos/useRepo";
import {default as React, useEffect, useMemo, useRef} from "react";
import Table, {TableColumn, TableQuery} from "../../../table/Table";
import {SalesChannelApplication} from "../../../../models/SalesChannelApplication";
import TableColumnFactory from "../../TableColumnFactory";
import {buildQueryForTableQuery, queryResultFromCollectionResponseForQuery} from "../../../table/TableRepoAdapter";
import {Filter} from "../../../../repos/CrudRepo";
import useUpdateSalesChannelApplicationModal from "../../../modals/useUpdateSalesChannelApplicationModal";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import useCreateSalesChannelApplicationForSalesChannelModal
    from "../../../modals/useCreateSalesChannelApplicationForSalesChannelModal";
import {useNavigation} from "../../../../services/navigation/useNavigation";


interface Props {
    salesChannel: SalesChannel,
}

export default function SalesChannelApplicationTable({ salesChannel  }: Props) {
    const { salesChannelApplicationRepo } = useRepo();
    const { navigateToApplication } = useNavigation();
    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canCreateSalesChannelApplication = can(actions.salesChannelApplication.create);
    const canDeleteSalesChannelApplication = can(actions.salesChannelApplication.delete);

    const { setSalesChannelForCreateSalesChannelApplicationModal, createSalesChannelApplicationForSalesChannelModal } = useCreateSalesChannelApplicationForSalesChannelModal(reloadData);
    const { setSalesChannelApplicationForUpdated, updateSalesChannelApplicationModal } = useUpdateSalesChannelApplicationModal(reloadData);

    const salesChannelId = useMemo(() => salesChannel.id, [salesChannel.id]);

    useEffect(reloadData, [salesChannelId]);

    const deleteSalesChannelApplication = (salesChannelApplication: SalesChannelApplication) => {
        salesChannelApplicationRepo.delete(salesChannelApplication.id)
            .then(reloadData)
            .catch(console.error)
    };

    const columns: TableColumn<SalesChannelApplication>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeSalesChannelApplicationIdColumn(),
        TableColumnFactory.fromProperty("Alias", "alias"),
        TableColumnFactory.fromProperty("URL", "url"),
        TableColumnFactory.fromProperty("URL Type", "urlType"),
        TableColumnFactory.fromProperty("URL Target", "urlTarget"),
        {
            title: 'SSL',
            field: 'isSslRequired',
            type: 'boolean',
            emptyValue: '-'
        },

        TableColumnFactory.makeApplicationColumn("Application", "applicationId", p => p.application),
        TableColumnFactory.fromProperty("Impersonation Url", "impersonationUrl"),
    ]);

    const fetchData = async (tableQuery: TableQuery<SalesChannelApplication>) => {
        const query = buildQueryForTableQuery(tableQuery);
        const salesChannelFilter: Filter = { field: "salesChannelId", "value": salesChannelId };
        if (query.filters) {
            query.filters.push(salesChannelFilter);
        } else {
            query.filters = [salesChannelFilter];
        }
        const res = await salesChannelApplicationRepo.list(query);
        return queryResultFromCollectionResponseForQuery(res)
    };

    return (
        <>
            <Table<SalesChannelApplication>
                tableRef={tableRef}
                title={`Applications for ${salesChannel.name}`}
                columns={columns}
                actions={[
                    makeCreateAction(() => setSalesChannelForCreateSalesChannelApplicationModal(salesChannel), { disabled: !canCreateSalesChannelApplication }),
                    makeDeleteAction(deleteSalesChannelApplication, { disabled: !canDeleteSalesChannelApplication })
                ]}
                options={{
                    search: false,
                    filtering: false,
                }}
                onRowClick={setSalesChannelApplicationForUpdated}
                data={fetchData}
            />
            {createSalesChannelApplicationForSalesChannelModal}
            {updateSalesChannelApplicationModal}
        </>
    );
}
