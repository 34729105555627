import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useEffect, useState} from "react";
import {Faq} from "../../../models/ti/Faq";
import {useRepo} from "../../../repos/useRepo";
import LoadingIndicator from "../../LoadingIndicator";
import {Paper} from "@material-ui/core";
import {BreadcrumbFactory, FaqDetailBreadcrumbFactory} from "../../../factories/BreadcrumbFactory";
import {Breadcrumbs} from "../../layout/Breadcrumbs";
import {useNavigation} from "../../../services/navigation/useNavigation";
import {Question} from "../../../models/ti/Question";
import Title from "../questions/Title";
import StatusBar from "../questions/StatusBar";
import EditFaqForm from "./EditFaqForm";
import {withTarkentonInstituteTheme} from "../theme";
import { actions } from "../../../rbac";

interface Params {
    faqId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
  actions.faq.get,
]

function FaqDetail(props: Props) {
    const [faq, setFaq] = useState<Faq>();
    const { faqRepo, questionRepo } = useRepo();
    const { navigateToFaqs, navigateToQuestion } = useNavigation();

    const { faqId } = props.match.params;

    const loadFaq = () => {
        faqRepo.getFaqById(faqId, true).then(setFaq).catch(console.error);
    };

    useEffect(loadFaq, [faqRepo, faqId]);

    const [question, setQuestion] = useState<Question>();


    useEffect(() => {
        faqRepo.getFaqById(faqId, true)
            .then(setFaq)
            .catch(err => console.error(err))
    }, [faqRepo, faqId]);

    useEffect(() => {
        if (faq) {
            questionRepo.getQuestionById(faq.questionId)
                .then(setQuestion)
                .catch(err => console.error(err))
        }
    }, [faq, questionRepo]);

    function navigateBack() {
        props.history.goBack()
    }

    if (faq) {

        const breadcrumbFactory: BreadcrumbFactory = new FaqDetailBreadcrumbFactory(faq, navigateToFaqs);

        return (
            <>
                <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

                <Paper style={{ padding: 24 }}>

                    {faq.questionId && question ?
                      <div style={{ marginBottom: "12px" }}>
                        <Title title="Original Question"/>
                        <StatusBar question={question} onClick={() => navigateToQuestion(question)}/>
                      </div>
                      : null
                    }

                    <EditFaqForm
                        faq={faq}
                        onFaqChanged={setFaq}
                        onDelete={navigateBack}
                    />

                </Paper>
            </>
        );
    } else {
        return <LoadingIndicator/>
    }
}

export default withTarkentonInstituteTheme(withRouter(FaqDetail));