import React, {useMemo, useRef, useState} from "react";
import Table, {TableRef} from "../Table";
import {Filter as TableFilter, SortingRule as TableSort} from "react-table";
import {Paper} from "@material-ui/core";
import QuickFilters, {QuickFilter} from "../QuickFilters";
import SearchBar from "../../form/old/SearchBar";
import TableRepoAdapter, { transformTableFilter } from "../TableRepoAdapter";
import {useQuestionColumns} from "./QuestionColumnFactory";
import {QuestionPresenter} from "./QuestionPresenter";
import {useRepo} from "../../../repos/useRepo";
import {Question} from "../../../models/ti/Question";
import {QuestionFilter, QuestionFilterOption, QuestionSort} from "../../../repos/ti/question/QuestionRepo";
import { actions } from "../../../rbac";
import config from "../../../config";
import QuestionPreview from "./QuestionPreview";
import Routes from "../../../Routes";
import {useRbac} from "@gsb/react-rbac";


type QuestionQuickFilter = QuickFilter & { filters: TableFilter[], disabledColumns: QuestionFilterOption[] }

const quickFilters: { all: QuestionQuickFilter[], assigned: QuestionQuickFilter[] } = {
  all: [
    {
      key: 'All',
      label: 'all',
      filters: [],
      disabledColumns: [],
    },
    {
      key: 'assigned-to-me',
      label: 'Assigned To Me',
      filters: [
        {
          id: QuestionFilterOption.AssignedAnswerer,
          value: "current"
        }
      ],
      disabledColumns: [QuestionFilterOption.AssignedAnswerer],
    },
    {
      key: config.salesChannels.gsb.identifier,
      label: config.salesChannels.gsb.name,
      filters: [
        {
          id: QuestionFilterOption.SalesChannel,
          value: config.salesChannels.gsb.id
        }
      ],
      disabledColumns: [QuestionFilterOption.SalesChannel],
    },
    {
      key: config.salesChannels.upnetic.identifier,
      label: config.salesChannels.upnetic.name,
      filters: [
        {
          id: QuestionFilterOption.SalesChannel,
          value: config.salesChannels.upnetic.id
        }
      ],
      disabledColumns: [QuestionFilterOption.SalesChannel],
    },
  ],
  assigned: [
    {
      key: 'assigned-to-me',
      label: 'All',
      filters: [
        {
          id: QuestionFilterOption.AssignedToMe,
          value: "current"
        }
      ],
      disabledColumns: [QuestionFilterOption.AssignedAnswerer],
    },
    {
      key: config.salesChannels.gsb.identifier,
      label: config.salesChannels.gsb.name,
      filters: [
        {
          id: QuestionFilterOption.SalesChannel,
          value: config.salesChannels.gsb.id
        },
        {
          id: QuestionFilterOption.AssignedToMe,
          value: "current"
        }
      ],
      disabledColumns: [QuestionFilterOption.SalesChannel, QuestionFilterOption.AssignedAnswerer],
    },
    {
      key: config.salesChannels.upnetic.identifier,
      label: config.salesChannels.upnetic.name,
      filters: [
        {
          id: QuestionFilterOption.SalesChannel,
          value: config.salesChannels.upnetic.id
        },
        {
          id: QuestionFilterOption.AssignedToMe,
          value: "current"
        }
      ],
      disabledColumns: [QuestionFilterOption.SalesChannel, QuestionFilterOption.AssignedAnswerer],
    },
  ]
};

export const requiredActions = [
  actions.question.list
]

export default function QuestionList() {
  const { can } = useRbac();
  const { questionRepo } = useRepo();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [pages, setPages] = useState(0);

  const tableRef = useRef<TableRef>(null);

  const questionPresenter = new QuestionPresenter();

  const [searchQuery, setSearchQuery] = useState("");

  const allowedQuickFilters = can(actions.question.assignAnswerer) ? quickFilters.all : quickFilters.all;

  const [quickFilterSelection, setQuickFilterSelection] = useState<QuestionQuickFilter>(allowedQuickFilters[0]);
  const [tableFilters, setTableFilters] = useState<TableFilter[]>([]);

  const tableQuickFilters = useMemo(() => quickFilterSelection.filters, [quickFilterSelection]);
  const disabledColumns = useMemo(() => quickFilterSelection.disabledColumns, [quickFilterSelection]);

  const { columns } = useQuestionColumns(disabledColumns);

  const onQuickFilterChanged = (quickFilter: QuestionQuickFilter) => {
    // Remove any table filters related to the existing quick filter selection or the new quick filter
    const updatedTableFilters = tableFilters.filter(f => {
      return !quickFilterSelection.disabledColumns.includes(f.id as QuestionFilterOption)
        && !quickFilter.disabledColumns.includes(f.id as QuestionFilterOption)
    });

    // Apply the new quick filter
    setTableFilters(updatedTableFilters);
    setQuickFilterSelection(quickFilter)
  };

  async function fetchQuestions(pageSize: number = 10, page: number = 0, sorts: TableSort[] = [], filters: TableFilter[] = [], search?: string) {
    const releasedFilter: QuestionFilter = {
      on: QuestionFilterOption.Status,
      value: config.questionStatuses.released.id,
      operator: 'ne'
    }

    const repoFilters: QuestionFilter[] = filters
        .map(transformTableFilter)
        .filter((f): f is QuestionFilter => !!f);

    const repoSorts: QuestionSort[] = sorts
        .map(TableRepoAdapter.QuestionRepo.getSortFromTableSort)
        .filter((s): s is QuestionSort => !!s);

    const response = await questionRepo.listQuestions(pageSize, page, repoSorts, [releasedFilter, ...repoFilters], search);
    setQuestions(response.results);
    setPages(response.pages);
  }

  return (
      <Paper>
        <div style={{padding: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", maxWidth: "100%", flexWrap: "wrap"}}>
          <SearchBar value={searchQuery} onChange={setSearchQuery} />
          <QuickFilters
              quickFilters={allowedQuickFilters}
              value={quickFilterSelection}
              onChange={onQuickFilterChanged}/>
        </div>
        <Table
            ref={tableRef}
            fetchItems={fetchQuestions}
            columns={columns}
            filters={tableFilters}
            additionalFilters={tableQuickFilters}
            searchQuery={searchQuery}
            onFiltersChanged={setTableFilters}
            items={questions}
            pages={pages}
            preview={(question) => <QuestionPreview question={question}/>}
            hrefProvider={question => Routes.ti.forQuestionById(question.id)}
            getRibbonColor={questionPresenter.getDeadlineColor} />
      </Paper>
  );
}
