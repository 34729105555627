import * as React from "react";
import {IndustryPicker} from "../../form/pickers/IndustryPicker";
import EntityTypePicker from "../../form/pickers/EntityTypePicker";
import BusinessTypePicker from "../../form/pickers/BusinessTypePicker";
import StatePicker from "../../form/pickers/StatePicker";
import BusinessSizePicker from "../../form/pickers/BusinessSizePicker";
import TextInput from "../../form/inputs/TextInput";
import {Company} from "../../../models/Company";
import {CompanyUserPicker} from "../../form/pickers/UserPicker";
import TimeZonePicker from "../../form/pickers/TimeZonePicker";
import {useMemo} from "react";
import config from "../../../config";
import CheckboxInput from "../../form/inputs/CheckboxInput";

interface Props {
    company: Company;
    disabled?: boolean;
}

export default function CompanyFields({ company, disabled }: Props) {
    const { resellerId } = company;

    const isLegalShield = useMemo(() => resellerId === config.resellers.legalShield.id, [resellerId]);
    const isAdp = useMemo(() => config.adp.resellers.includes(resellerId), [resellerId]);

    return (
        <>
            <TextInput name="name" label="Name" disabled={disabled}/>

            <TimeZonePicker name='timeZone' disabled={disabled} allowEmpty={true} emptyValue='' emptyValueDescription='-'/>

            {company && (
                <CompanyUserPicker companyId={company.id} name='primaryContactId' label='Primary Contact'/>
            )}

            <IndustryPicker name={"profile.industryId"} disabled={disabled}/>

            <BusinessSizePicker name={"profile.businessSize"} disabled={disabled}/>

            <TextInput name={"profile.yearFounded"} label={"Year Founded"} type="number" disabled={disabled}/>

            <EntityTypePicker name={"profile.entityType"} disabled={disabled}/>

            <BusinessTypePicker name={"profile.businessType"} disabled={disabled}/>

            <StatePicker name={"profile.stateCode"} disabled={disabled}/>

            <TextInput name="profile.zipCode" label="Zip Code" disabled={disabled}/>

            <TextInput name="website" label="Website" type="url" disabled={disabled}/>

            {isLegalShield && (
                <TextInput name="legalShieldMemberId" label="Legal Shield Member ID" disabled={disabled}/>
            )}

            {isAdp && (
                <>
                    <TextInput name="adpClientId" label="ADP Client ID" disabled={disabled}/>

                    <TextInput name="adpOrganizationId" label="ADP Organization ID" disabled={disabled}/>
                </>
            )}

            <CheckboxInput name="isDisabled" label="Disabled"/>
        </>
    )
}
