import * as React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Paper } from "@material-ui/core";
import { actions } from "../../../../rbac";
import { useRepo } from "../../../../repos/useRepo";
import LoadingIndicator from "../../../LoadingIndicator";
import { BreadcrumbFactory, StateIdApplicationDetailBreadcrumbFactory } from "../../../../factories/BreadcrumbFactory";
import { useNavigation } from "../../../../services/navigation/useNavigation";
import { Breadcrumbs } from "../../../layout/Breadcrumbs";
import { Application } from "../../../../models/sid/Application";
import ApplicationActionBar from "./ApplicationActionBar";
import { useConfirmDialog } from "../../../dialog/ConfirmDialog";
import { useRbac } from "@gsb/react-rbac";
import TabNavigation, { TabNavigationItem } from "../../../tabs/TabNavigation";
import ApplicationTab from "./tabs/ApplicationTab";
import useAssignStatusToApplicationModal from "../../../modals/useAssignStatusToApplicationModal";
import ApplicationNotesTab from "./tabs/ApplicationNotesTab";
import ApplicationStateIdTab from "./tabs/ApplicationStateIdTab";

interface Params {
    applicationId: string
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
    actions.stateApplication.list,
]

function StateIdApplicationDetail(props: Props) {
    const [application, setApplication] = useState<Application>();
    const { stateIdApplicationRepo } = useRepo();

    const { navigateToStateIdApplications } = useNavigation();

    const { applicationId } = props.match.params;

    const loadStateIdApplication = () => {
        stateIdApplicationRepo.getById(applicationId).then(setApplication).catch(console.error);
    };

    const { can } = useRbac();
    const canDeleteApplication = can(actions.stateIdApplication.delete);

    const handleApplicationDelete = () => {
        if (canDeleteApplication) {
            stateIdApplicationRepo.delete(applicationId).then(navigateToStateIdApplications);
        }
    }

    useEffect(loadStateIdApplication, [stateIdApplicationRepo, applicationId]);
    const { openAssignStatusToApplicationModal, assignStatusToApplicationModal } = useAssignStatusToApplicationModal(applicationId, loadStateIdApplication);
    const { openConfirmDialog, confirmDialog } = useConfirmDialog('Delete Application', 'Are you sure you want to delete this application? All related data will be permanently deleted.  This action can NOT be reversed!', handleApplicationDelete);

    if (application) {

        const breadcrumbFactory: BreadcrumbFactory = new StateIdApplicationDetailBreadcrumbFactory(application, navigateToStateIdApplications);

        const items: TabNavigationItem[] = [
            {
                name: 'Application',
                identifier: 'application',
                render: () => <ApplicationTab application={application} onSubmitApplication={openAssignStatusToApplicationModal}  />
            },
            {
                name: 'State Id\'s',
                identifier: 'application-state-id',
                render: () => <ApplicationStateIdTab application={application} onApplicationUpdated={loadStateIdApplication} />
            },
            {
                name: 'Notes',
                identifier: 'application-notes',
                render: () => <ApplicationNotesTab application={application} />
            }];

        return (<>

            <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()} />

            <Paper style={{ marginBottom: 12 }}>
                <ApplicationActionBar application={application} onDeleteClicked={openConfirmDialog} onAssignStatusClicked={openAssignStatusToApplicationModal} />
            </Paper>

            <Paper>
                <TabNavigation items={items} />
            </Paper>

            {assignStatusToApplicationModal}
            {confirmDialog}
        </>);
    } else {
        return <LoadingIndicator />
    }
}

export default withRouter(StateIdApplicationDetail);