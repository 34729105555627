import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {ReactNode, useState} from "react";
import {verticalFormLayout} from "../form/styles/layout";
import DeleteButton from "../form/buttons/DeleteButton";

export interface DialogChildProps {
  onErrorStateChange: (hasErrors: boolean) => void;
  onDirtyStateChange?: (isDirty: boolean) => void;
  hasAttemptedSave: boolean;
}

interface Props {
  onDismiss: () => void;
  onSave: () => Promise<void>;
  onDelete: () => void;
  children: (props: DialogChildProps) => ReactNode;
  permissions: {update: boolean, delete: boolean}
}

export function EntityDialog(props: Props) {
  const {permissions} = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasAttemptedSave, setHasAttemptedSave] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  async function save() {
    setHasAttemptedSave(true);
    setIsSubmitting(true);

    try {
      await props.onSave();
    } finally {
      setIsSubmitting(false);
    }
  }

  function dismiss() {
    if (isDirty) {
      const userConfirmed = window.confirm("Any unsaved changes will be lost. Are you sure you want to cancel?");

      if (!userConfirmed) {
        return;
      }
    }

    props.onDismiss()
  }

  async function destroy() {
    await props.onDelete();
    dismiss();
  }


  const children = props.children
    ? props.children({ onErrorStateChange: setHasErrors, onDirtyStateChange: setIsDirty,  hasAttemptedSave})
    : null;

  return (
    <Dialog
      open={true}
      disableEnforceFocus={true}
      onBackdropClick={dismiss}
    >
      <DialogContent>
        <div style={verticalFormLayout}>
          {children}
        </div>
      </DialogContent>
      <DialogActions>
        <DeleteButton disabled={!permissions.delete || isSubmitting} onClick={destroy}/>
        <Button onClick={dismiss} >
          Cancel
        </Button>
        <Button disabled={!permissions.update || isSubmitting || hasErrors || !isDirty} onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

