import React, { useEffect, useRef } from "react";
import { queryResultFromArray } from "../../table/TableRepoAdapter";
import Table, { TableColumn, TableQuery } from "../../table/Table";
import TableColumnFactory from "../TableColumnFactory";
import { useRepo } from "../../../repos/useRepo";
import { Company } from "../../../models/Company";
import { WebsiteAnalysis } from "../../../models/WebsiteAnalysis";
import useCreateWebsiteAnalysisForCompanyModal from "./website-analysis/useCreateWebsiteAnalysisForCompanyModal";
import useUpdateWebsiteAnalysisForm from "./website-analysis/useUpdateWebsiteAnalysisForm";
import useViewWebsiteAnalysisForm from "./website-analysis/useViewWebsiteAnalysisForm";
import { makeCreateAction, makeUpdateAction } from "../../table/TableActionFactory";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../rbac";

interface Props {
    company: Company
}

export default function CompanyWebsiteAnalysisTable({ company }: Props) {
    const { websiteAnalysisRepo } = useRepo();
    const { can } = useRbac();
    const canCreateWebsiteAnalysisForCompany = can(actions.company.createWebsiteAnalysis)
    const canUpdateWebsiteAnalysisForCompany = can(actions.company.updateWebsiteAnalysis);
    const tableRef = useRef<any>(null);

    const reloadData = () => tableRef.current.onQueryChange();

    const { createWebsiteAnalysisForCompanyModal, openCreateWebsiteAnalysisForCompanyModal } = useCreateWebsiteAnalysisForCompanyModal(company, reloadData);
    const { updateWebsiteAnalysisModal, openUpdateWebsiteAnalysisModal } = useUpdateWebsiteAnalysisForm(reloadData);
    const { viewWebsiteAnalysisModal, openViewWebsiteAnalysisModal } = useViewWebsiteAnalysisForm();

    useEffect(reloadData, [company.id]);

    const columns: TableColumn<WebsiteAnalysis>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeSilkTideReportColumn("Silk Tide Report Id", (l) => l.reportId),
        TableColumnFactory.makeWebsiteAnalysisUrlColumn(),
        TableColumnFactory.fromProperty("Score", 'overallScore'),
        TableColumnFactory.fromProperty("Status", 'status'),
        TableColumnFactory.booleanColumn("Send Report", "sendReport"),
        TableColumnFactory.fromProperty("Sent To", 'sendReportTo'),
        TableColumnFactory.dateColumn("Report Sent", "sentReportAt"),
        TableColumnFactory.fromProperty("Sent Status", 'sentReportAtDescription'),
    ]);

    return (
        <>
            <Table<WebsiteAnalysis>
                tableRef={tableRef}
                title={`Website Anlysis for ${company.name}`}
                actions={[
                    makeCreateAction(() => openCreateWebsiteAnalysisForCompanyModal(), { disabled: !canCreateWebsiteAnalysisForCompany }),
                    makeUpdateAction(openUpdateWebsiteAnalysisModal, { disabled: !canUpdateWebsiteAnalysisForCompany })
                ]}
                columns={columns}
                options={{
                    search: false,
                    filtering: false,
                    paging: false
                }}
                onRowClick={openViewWebsiteAnalysisModal}
                data={async (tableQuery: TableQuery<WebsiteAnalysis>) => {
                    const res = await websiteAnalysisRepo.list(company.id);
                    return queryResultFromArray(res)
                }}
            />
            {createWebsiteAnalysisForCompanyModal}
            {updateWebsiteAnalysisModal}
            {viewWebsiteAnalysisModal}
        </>
    );
}