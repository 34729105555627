import React from "react";
import {Paper} from "@material-ui/core";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import SalesChannelList from "./sales-channels/List";
import ResellerList from "./resellers/List";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";
import {useNavigation} from "../../../services/navigation/useNavigation";

export const requiredActions = [
  actions.salesChannel.list,
  actions.reseller.list,
]

export function Brands() {
    const { can } = useRbac();
    const { navigateToSalesChannel } = useNavigation();

    const canListSalesChannels = can(actions.salesChannel.list);
    const canListResellers = can(actions.reseller.list);
    const shouldShowList = canListSalesChannels || canListResellers;

    const items: TabNavigationItem[] = [
        {
            name: "Sales Channels",
            identifier: "sales-channels",
            render: () => <SalesChannelList onSalesChannelSelected={navigateToSalesChannel}/>,
            hidden: !canListSalesChannels
        },
        {
            name: "Resellers",
            identifier: "resellers",
            render: () => <ResellerList/>,
            hidden: !canListResellers
        }
    ];

    if (shouldShowList) {
        return (
            <Paper>
                <TabNavigation items={items} disabledContentPadding/>
            </Paper>
        )
    } else {
        return null;
    }
}