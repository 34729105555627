import moment from "moment";
import React from "react";
import DateUtils from "../../../utils/DateUtils";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {
    minTime: string,
    maxTime: string,
    minutesStep: number
}

export default function TimePicker({ ...PickerProps }: Props) {
    const format = 'hh:mmA';
    const { minTime: startTime, maxTime: endTime, minutesStep } = PickerProps;

    let timeValue = startTime;
    let lastValue;
    const endLimit = endTime;

    const options = [];

    while(DateUtils.isEarlierThanEndHour(timeValue, endLimit, lastValue )){
        options.push({ id: timeValue, label: timeValue });
        lastValue = timeValue;
        timeValue = moment(timeValue, format).add(minutesStep, 'minutes').format(format);
    }
    
    return <PickerInput label={'Time Picker'} options={options} {...PickerProps}/>
} 
