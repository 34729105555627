import IndustryRepo from "./IndustryRepo";
import {Industry} from "../../models/Industry";

export class InMemoryIndustryRepoCache implements IndustryRepo {
    cachedIndustryList?: Industry[];
    industryPromise?: Promise<Industry[]>;

    constructor(private inner: IndustryRepo) {}

    async listAll(): Promise<Industry[]> {
        if (this.cachedIndustryList) {
            return this.cachedIndustryList;
        } else {
            if (this.industryPromise) {
                return this.industryPromise;
            } else {
                const industriesPromise = this.inner.listAll();
                this.industryPromise = industriesPromise;
                const industries = await industriesPromise;
                this.cachedIndustryList = industries;
                this.industryPromise = undefined;
                return industries;
            }
        }
    };
}