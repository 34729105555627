import _ from "lodash";
import React, {useMemo} from "react";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FilterComponentProps } from "../../entities/TableColumnFactory";

type SelectOptions = {
    [id: string]: string;
};

export type DropdownPickerItem = {
    id: string;
    name: string;
};

const selectStyle = {
    minWidth: "100%",
    paddingBottom: "4px",
};

interface Props extends FilterComponentProps<any> {
    options: DropdownPickerItem[];
}

export default function DropdownPicker(props: Props) {
    const { options, columnDef, onFilterChanged } = props;

    const optionsById: SelectOptions = useMemo(() => _.reduce(options, (agg: SelectOptions, curr) => {
        agg[curr.id] = curr.name;
        return agg;
    }, {}), [options]);

    return (
        <Select
            value={columnDef.tableData.filterValue === undefined ? '' : columnDef.tableData.filterValue}
            onChange={event => onFilterChanged(columnDef.tableData.columnOrder.toString(), event.target.value)}
            style={selectStyle}
            renderValue={val => _.isString(val) ? optionsById[val] : ""}
            SelectDisplayProps={{
                'aria-label': columnDef.title,
            }}
        >
            {columnDef.tableData.filterValue &&
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            }
            {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)
            )}
        </Select>
    )
}