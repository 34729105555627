import {IdentifiableResource} from "../IdentifiableResource";

export interface FaqStatus extends IdentifiableResource {
    name: string;
    identifier: string;
}

export enum FaqStatusIdentifier {
    pending = "pending",
    released = "released"
}

