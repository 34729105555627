import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {Reseller} from "../../../models/Reseller";

export default function ResellerDropdown(props: FilterComponentProps<any>) {
    const { resellerRepo } = useRepo();
    const [resellers, setResellers] = useState<Reseller[]>([]);

    useEffect(() => {
        resellerRepo.listAll().then(setResellers).catch(console.error);
    }, [resellerRepo]);
    
    return (
        <DropdownPicker options={resellers} {...props}/>
    )
}