import {Question} from "../../../models/ti/Question";
import HttpService from "@gsb/react-http";
import Sort from "../.././helpers/Sort";
import SortDirection from "../.././helpers/SortDirection";
import {CollectionResponse} from "../../../models";
import {QuestionStatusIdentifier} from "../../../models/ti/QuestionStatus";
import {Filter} from "../../helpers/Filter";
import {sanitize} from "../../../utils/FormUtils";
import { makeStringForFilter } from "../../../utils/QueryUtils";

export enum QuestionFilterOption {
  Topic = "topicId",
  Reseller = "site.resellerId",
  SalesChannel = "site.salesChannelId",
  Status = "statusId",
  AssignedToMe = "assignedToMe",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  UpdatedBy = "updatedById",
  AssignedReviewer = "assignedReviewerId",
  AssignedAnswerer = "assignedAnswererId"
}

export interface QuestionFilter extends Filter {
  on: QuestionFilterOption
}

export enum QuestionSortOption {
  Topic = "topic.name",
  Reseller = "site.resellerName",
  SalesChannel = "site.salesChannelName",
  Status = "status.name",
  Created = "createdAt",
  Updated = "updatedAt",
  UpdatedBy = "updatedBy.firstName",
  AssignedAnswerer = "assignedAnswererId.firstName"
}

export interface QuestionSort extends Sort {
  on: QuestionSortOption;
}

export default interface QuestionRepo {
  getQuestionById(id: string): Promise<Question>;
  listPreviousQuestionsForMember(memberId: string): Promise<CollectionResponse<Question>>;
  listPreviousQuestionsForCompany(companyId: string): Promise<CollectionResponse<Question>>;
  answerQuestion(id: string, body: Partial<Question>): Promise<Question>;
  listQuestions(pageSize: number, page: number, sorts: QuestionSort[], filters: QuestionFilter[], search?: string): Promise<CollectionResponse<Question>>;
  deleteQuestionById(id: string): Promise<void>;
  updateStatusForQuestion(questionId: string, status: QuestionStatusIdentifier): Promise<Question>;
  submitQuestionForReview(questionId: string, consultantId?: string): Promise<Question>;
  releaseQuestionWithId(id: string): Promise<Question>;
  leaveReviewForQuestion(questionId: string, isApproved: boolean, notes?: string): Promise<Question>;
  assignAnswererToQuestion(consultantId: string, questionId: string): Promise<Question>;
  assignReviewerToQuestion(consultantId: string, questionId: string): Promise<Question>;
}

export class HttpQuestionRepo implements QuestionRepo {
  constructor(private http: HttpService) {}

  getQuestionById(id: string): Promise<Question> {
    return this.http.get(['ti', 'v1', 'questions', id], { query: { include: "member" } });
  }

  deleteQuestionById(id: string): Promise<void> {
    return this.http.delete(['ti', 'v1', 'questions', id]);
  }

  listPreviousQuestionsForMember(memberId: string): Promise<CollectionResponse<Question>> {
    return this.http.get(['ti', 'v1', 'questions'], {
      query: {
        include: "topic,site,status,updatedBy",
        filter: `memberId eq '${memberId}'`
      }
    });
  }

  listPreviousQuestionsForCompany(companyId: string): Promise<CollectionResponse<Question>> {
    return this.http.get(['ti', 'v1', 'questions'], {
      query: {
        include: "topic,site,status,updatedBy,member",
        filter: `member/companyId eq '${companyId}'`
      }
    });
  }

  listQuestions(pageSize: number, page: number, sorts: QuestionSort[] = [], filters: QuestionFilter[] = [], search?: string): Promise<CollectionResponse<Question>> {
    const params: { [key: string]: string } = {};

    const salesChannelFilter = filters.find(f => f.on === QuestionFilterOption.SalesChannel);
    const resellerFilter = filters.find(f => f.on === QuestionFilterOption.Reseller);

    if (filters.length > 0) {

      params.filter = filters
          .filter(f => f.on !== QuestionFilterOption.SalesChannel && f.on !== QuestionFilterOption.Reseller)
          .map(({ on, value, operator }) => {
        return makeStringForFilter( { field: on, value, operator } )
      }).join(" and ").replace("assignedToMe eq 'current'","assignedAnswererId eq 'current' or assignedReviewerId eq 'current'");
    }

    const defaultSort = {on: QuestionSortOption.Created, direction: SortDirection.Descending};

    if (sorts.length > 0) {
      params.orderBy = sorts.map(s => ([s.on, s.direction].join(" "))).join(",");
    } else {
      sorts.push(defaultSort); 
    }

    if (search) {
      params.search = search;
    }

    const normalizedPage = page + 1;
    params.page = normalizedPage.toString();
    params.pageSize = pageSize.toString();

    const includes = ['status', 'topic', 'updatedBy', 'assignedAnswerer', 'assignedReviewer', 'member'];

    if (salesChannelFilter && resellerFilter) {
      includes.push(`site($filter=salesChannelId eq '${salesChannelFilter.value}' and resellerId eq '${resellerFilter.value}')`)
    } else if (salesChannelFilter) {
      includes.push(`site($filter=salesChannelId eq '${salesChannelFilter.value}')`)
    } else if (resellerFilter) {
      includes.push(`site($filter=resellerId eq '${resellerFilter.value}')`)
    } else {
      includes.push('site')
    }

    params.include = includes.join(',');

    return this.http.get(['ti', 'v1', 'questions'],{
      query: params
    })
  }

  assignAnswererToQuestion(consultantId: string, questionId: string): Promise<Question> {
    return this.http.post(['ti', 'v1', 'questions', questionId, 'answerers'], {
      consultantId: consultantId
    });
  }

  assignReviewerToQuestion(consultantId: string, questionId: string): Promise<Question> {
    return this.http.post(['ti', 'v1', 'questions', questionId, 'reviewers'], {
      consultantId: consultantId
    });
  }

  updateStatusForQuestion(questionId: string, status: QuestionStatusIdentifier): Promise<Question> {
    return this.http.put(['ti', 'v1', 'questions', questionId, 'status'], {
      status: status
    });
  }

  submitQuestionForReview(questionId: string, consultantId?: string): Promise<Question> {
    return this.http.put(['ti', 'v1', 'questions', questionId, 'status', 'review'], {
      consultantId: consultantId
    });
  }

  releaseQuestionWithId(id: string): Promise<Question> {
    return this.http.put(['ti', 'v1', 'questions', id, 'status', 'release'], {});
  }

  leaveReviewForQuestion(questionId: string, isApproved: boolean, notes?: string): Promise<Question> {
    return this.http.post(['ti', 'v1', 'questions', questionId, 'review'], {
      isApproved: isApproved,
      notes: notes
    });
  }

  answerQuestion(id: string, body: Partial<Question>): Promise<Question> {
    const answerBody = {
      title: body.title,
      body: body.body,
      draft: body.draft,
      notes: body.notes,
    };

    return this.http.post(['ti', 'v1', 'questions', id, 'answer'], sanitize(answerBody));
  }
}
