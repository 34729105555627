import React, {useContext, createContext, useMemo,} from 'react';
import Repo from "./Repo";
import CompanyRepo from "./company/CompanyRepo";
import UserRepo from "./user/UserRepo";
import ApplicationRepo from "./application/ApplicationRepo";
import SalesChannelRepo from "./sales-channel/SalesChannelRepo";
import ResellerRepo from "./reseller/ResellerRepo";
import ProductRepo from "./product/ProductRepo";
import EmployeeRepo from "./employee/EmployeeRepo";
import EmployeeCompanyRepo from "./employee-company/EmployeeCompanyRepo";
import IndustryRepo from "./industry/IndustryRepo";
import {BusinessSizeRepo} from "./business-size/BusinessSizeRepo";
import {EntityTypeRepo} from "./entity-type/EntityTypeRepo";
import {BusinessTypeRepo} from "./business-type/BusinessTypeRepo";
import StateRepo from "./state/StateRepo";
import PlanRepo from "./plan/PlanRepo";
import ProductLicenseRepo from "./product-license/ProductLicenseRepo";
import {StripeRepo} from "./stripe/StripeRepo";
import SubscriptionRepo from "./subscription/SubscriptionRepo";
import EmployeeRoleRepo from "./employee-role/EmployeeRoleRepo";
import SalesChannelApplicationRepo from "./sales-channel-application/SalesChannelApplicationRepo";
import SenderRepo from "./sender/SenderRepo";
import TemplateRepo from "./template/TemplateRepo";
import TriggerRepo from "./trigger/TriggerRepo";
import TopicRepo from "./ti/topic/TopicRepo";
import SiteRepo from "./ti/site/SiteRepo";
import SiteTopicRepo from "./ti/site-topic/SiteTopicRepo";
import ConsultantRepo from "./ti/consultant/ConsultantRepo";
import FaqRepo from "./ti/faq/FaqRepo";
import QuestionRepo from "./ti/question/QuestionRepo";
import FaqStatusRepo from "./ti/faq/FaqStatusRepo";
import QuestionStatusRepo from "./ti/question/QuestionStatusRepo";
import ReportRepo from "./report/ReportRepo";
import AdpRepo from "./adp/AdpRepo";
import WebsiteAnalysisRepo from "./website-analysis/WebsiteAnalysisRepo";
import MetadataTypeRepo from "./metadata-type/MetadataTypeRepo";
import MailchimpAccountRepo from "./mailchimp-account/MailchimpAccountRepo";
import UserSignInRepo from "./user-sign-in/UserSignInRepo";
import StateApplicationRepo from './sid/state-application/StateApplicationRepo';
import StateIdApplicationRepo from './sid/application/ApplicationRepo';
import FieldTypeRepo from './sid/field-type/FieldTypeRepo';
import StateApplicationSectionRepo from './sid/state-application-section/StateApplicationSectionRepo';
import StateApplicationFieldRepo from './sid/state-application-field/StateApplicationFieldRepo';
import {default as StateIdConsultantRepo } from './sid/consultant/ConsultantRepo';
import SendGridAccountRepo from './sendgrid-account/SendGridAccountRepo';

interface ProvidedRepo {
    adpRepo: AdpRepo;
    companyRepo: CompanyRepo,
    userRepo: UserRepo,
    userSignInRepo: UserSignInRepo,
    applicationRepo: ApplicationRepo,
    salesChannelRepo: SalesChannelRepo,
    salesChannelApplicationRepo: SalesChannelApplicationRepo,
    resellerRepo: ResellerRepo,
    productRepo: ProductRepo,
    productLicenseRepo: ProductLicenseRepo,
    employeeRepo: EmployeeRepo,
    employeeCompanyRepo: EmployeeCompanyRepo,
    employeeRoleRepo: EmployeeRoleRepo,
    industryRepo: IndustryRepo,
    businessSizeRepo: BusinessSizeRepo,
    entityTypeRepo: EntityTypeRepo,
    businessTypeRepo: BusinessTypeRepo,
    stateRepo: StateRepo,
    planRepo: PlanRepo,
    stripeRepo: StripeRepo;
    subscriptionRepo: SubscriptionRepo;
    reportRepo: ReportRepo;
    websiteAnalysisRepo: WebsiteAnalysisRepo;
    metadataTypeRepo: MetadataTypeRepo;
    mailchimpAccountRepo: MailchimpAccountRepo;
    sendgridAccountRepo: SendGridAccountRepo;

    // Mail
    senderRepo: SenderRepo;
    templateRepo: TemplateRepo;
    triggerRepo: TriggerRepo;

    // Tarkenton Institute
    topicRepo: TopicRepo;
    siteRepo: SiteRepo;
    siteTopicRepo: SiteTopicRepo;
    consultantRepo: ConsultantRepo;
    faqRepo: FaqRepo;
    faqStatusRepo: FaqStatusRepo;
    questionRepo: QuestionRepo;
    questionStatusRepo: QuestionStatusRepo;

    // State Id
    stateApplicationRepo: StateApplicationRepo;
    stateIdApplicationRepo: StateIdApplicationRepo;
    fieldTypeRepo: FieldTypeRepo;
    stateApplicationSectionRepo: StateApplicationSectionRepo;
    stateApplicationFieldRepo: StateApplicationFieldRepo;
    stateIdConsultantRepo: StateIdConsultantRepo;
}

const RepoContext = createContext<ProvidedRepo>(null as unknown as ProvidedRepo);

export const useRepo = () => useContext(RepoContext);

interface Props {
    repo: Repo;
}

export const RepoProvider: React.FC<Props> = ({ children, repo }) => {

    const value = useMemo(() => ({
        adpRepo: repo.adp,
        companyRepo: repo.companies,
        userRepo: repo.users,
        userSignInRepo: repo.userSignIns,
        applicationRepo: repo.applications,
        salesChannelRepo: repo.salesChannels,
        salesChannelApplicationRepo: repo.salesChannelApplications,
        resellerRepo: repo.resellers,
        productRepo: repo.products,
        productLicenseRepo: repo.productLicenses,
        employeeRepo: repo.employees,
        employeeRoleRepo: repo.employeeRoles,
        employeeCompanyRepo: repo.employeeCompanies,
        industryRepo: repo.industries,
        businessSizeRepo: repo.businessSize,
        entityTypeRepo: repo.entityType,
        businessTypeRepo: repo.businessType,
        stateRepo: repo.state,
        planRepo: repo.plan,
        stripeRepo: repo.stripe,
        subscriptionRepo: repo.subscription,
        senderRepo: repo.sender,
        templateRepo: repo.template,
        triggerRepo: repo.trigger,
        topicRepo: repo.topic,
        siteRepo: repo.site,
        siteTopicRepo: repo.siteTopic,
        consultantRepo: repo.consultant,
        faqRepo: repo.faq,
        faqStatusRepo: repo.faqStatus,
        questionRepo: repo.question,
        questionStatusRepo: repo.questionStatus,
        reportRepo: repo.report,
        websiteAnalysisRepo: repo.websiteAnalysis,
        metadataTypeRepo: repo.metadataType,
        mailchimpAccountRepo: repo.mailchimpAccount,
        sendgridAccountRepo: repo.sendgridAccount,
        stateApplicationRepo: repo.stateApplication,
        stateApplicationSectionRepo: repo.stateApplicationSection,
        stateApplicationFieldRepo: repo.stateApplicationField,
        stateIdApplicationRepo: repo.stateIdApplication,
        fieldTypeRepo: repo.fieldType,
        stateIdConsultantRepo: repo.stateIdConsultant
        
    }), [repo]);

    return (
        <RepoContext.Provider value={value}>
            {children}
        </RepoContext.Provider>
    );
};
