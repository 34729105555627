import StateRepo from "./StateRepo";
import {State} from "../../models/State";
import HttpService from "@gsb/react-http";

export default class HttpStateRepo implements StateRepo {
    pathComponents = ['mk', 'v1', 'states'];

    constructor(private http: HttpService) {}

    list(): Promise<State[]> {
        return this.http.get(this.pathComponents);
    }
}
