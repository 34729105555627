import * as Yup from 'yup';
import {LinkStripeCustomerForCompanyRequest} from "../../models/Company";

export const linkStripeCustomerForCompanySchema = Yup.object().shape({
    stripeAccountIdentifier: Yup.string().required("Stripe Account is required"),
    stripeCustomerId: Yup.string().required("Stripe Customer is required"),
});

export const initialValuesForLinkStripeCustomerForCompanyRequest = (): LinkStripeCustomerForCompanyRequest => ({
    stripeAccountIdentifier: "",
    stripeCustomerId: "",
});
