import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import { TimelineConnector, TimelineContent, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import { ApplicationNote } from "../../../../../models/sid/ApplicationNote";
import moment from "moment";
import { ApplicationNoteMenuButton } from "./ApplicationNoteMenu";
import ApplicationTimelineNoteThreads from "./ApplicationTimelineNoteThreads";
import { CompanyNoteDemeanorTimelineDot } from "../../../companies/notes/CompanyNoteDemeanor";

interface Props {
    note: ApplicationNote,
    isAlternate: boolean,
    isLast: boolean,
    canDelete: boolean,
    canEdit: boolean,
    canAddThread: boolean,
    onCompanyNoteEdit: (note: ApplicationNote) => void,
    onCompanyNoteDelete: (note: ApplicationNote) => void,
    onCompanyNoteAddThread: (note: ApplicationNote) => void
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-evenly',
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& p': {
            margin: theme.spacing(0.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    noteBody: {
        padding: '12px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

export default function TimelineNoteItem({ note, isAlternate, isLast, canDelete, canEdit, canAddThread, onCompanyNoteEdit, onCompanyNoteDelete, onCompanyNoteAddThread }: Props) {
    const { body, status, userDisplayName, createdBy, createdAt, threads } = note;

    const classes = useStyles();

    return (
        <TimelineItem>
            <TimelineOppositeContent>
                <Typography variant="body1" color="textSecondary"><b>Created:</b> {createdBy} @ {moment(createdAt).format('MM/DD/YYYY, h:mm a')}</Typography>
                {status && (<Typography variant="body2" color="textSecondary"><b>Call Status:</b> {status}</Typography>)}
                {userDisplayName && (<Typography variant="body2" color="textSecondary"><b>User:</b> {userDisplayName}</Typography>)}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <CompanyNoteDemeanorTimelineDot companyNote={note} />
                {isLast ? <></> : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={3} >
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '6px 2px' }}>
                        <div style={{ width: '50px', order: isAlternate ? 1 : 2 }}>
                            <ApplicationNoteMenuButton
                                applicationNote={note}
                                onDeleteClicked={canDelete ? onCompanyNoteDelete : undefined}
                                onEditClicked={canEdit ? onCompanyNoteEdit : undefined}
                                onAddThreadClicked={canAddThread ? onCompanyNoteAddThread : undefined}
                            />
                        </div>
                        <div className={classes.noteBody} style={{ order: isAlternate ? 2 : 1 }}>
                            <Typography style={{ whiteSpace: 'pre-wrap' }}>{body}</Typography>
                        </div>
                    </div>
                    {threads && threads.length > 0 && (
                        <ApplicationTimelineNoteThreads threads={threads} note={note} onThreadDelete={onCompanyNoteDelete} onThreadEdit={onCompanyNoteEdit} />
                    )}
                </Paper>
            </TimelineContent>
        </TimelineItem>
    );
}