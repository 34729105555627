export const subscriptionFileRecordStatuses = {
    'pending': 'Pending',
    'record-failed': 'Record Failed',
    'record-not-found': 'Record Not Found',
    'not-processed': 'Not Processed',
    'processed-successfully': 'Success',
    'subscription-not-found': 'Subscription Not Found',
    'unknown-error': 'Unknown Error',
    'upload-failed': 'Upload Failed',
}
