import {Question} from "../../../models/ti/Question";
import {Faq} from "../../../models/ti/Faq";
import {CollectionResponse} from "../../../models";
import HttpService from "@gsb/react-http";
import {Filter} from "../../helpers/Filter";
import Sort from "../../helpers/Sort";
import {sanitize} from "../../../utils/FormUtils";
import SortDirection from "../../helpers/SortDirection";
import { makeStringForFilter } from "../../../utils/QueryUtils";

type FaqUpdateBody = {
  title: string,
  question: string,
  answer: string,
  notes: string,
  topics: string[]
}

export enum FaqFilterOption {
  Title = "title",
  Topic = "topics.id",
  Status = "statusId",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  UpdatedBy = "updatedById",
}

export interface FaqFilter extends Filter {
  on: FaqFilterOption
}

export enum FaqSortOption {
  Status = "status.name",
  Created = "createdAt",
  Updated = "updatedAt",
}

export interface FaqSort extends Sort {
  on: FaqSortOption
}

export default interface FaqRepo {
  createFaqFromQuestion(question: Question): Promise<Faq>
  listFaqs(pageSize: number, page: number, sorts: FaqSort[], filters: FaqFilter[], search?: string): Promise<CollectionResponse<Faq>>;
  getFaqById(id: string, includeTopics: boolean): Promise<Faq>;
  deleteFaqById(id: string): Promise<void>;
  updateFaqWithId(id: string, body: Partial<FaqUpdateBody>): Promise<Faq>;
  releaseFaqWithId(id: string): Promise<Faq>;
}

export class HttpFaqRepo implements FaqRepo {
  constructor(private http: HttpService) {}

  createFaqFromQuestion(question: Question): Promise<Faq> {
    const body = {
      questionId: question.id,
      topicId: question.topicId,
      title: question.title,
      question: question.body,
      answer: question.draft
    };

    return this.http.post(['ti', 'v1', 'faqs'], sanitize(body));
  }

  listFaqs(pageSize: number, page: number, sorts: FaqSort[] = [], filters: FaqFilter[] = [], search?: string): Promise<CollectionResponse<Faq>> {
      const params: { [key: string]: string } = {};

      const topicFilter = filters.find(f => f.on === FaqFilterOption.Topic);

      if (filters.length > 0) {
        params.filter = filters
            .filter(f => f.on !== FaqFilterOption.Topic)
            .map(({on, value }) => makeStringForFilter( { field: on, value } ) )
            .join(" and ")
      }

    const defaultSort = {on: FaqSortOption.Created, direction: SortDirection.Descending};

    sorts.push(defaultSort);

    if (sorts.length > 0) {
        params.orderBy = sorts.map(s => ([s.on, s.direction].join(" "))).join(",")
      }

      if (search) {
        params.search = search;
      }

      const includes = ['status', 'updatedBy'];
      const normalizedPage = page + 1;
      params.page = normalizedPage.toString();
      params.pageSize = pageSize.toString();

      if (topicFilter) {
        includes.push(`topics($filter=id eq '${topicFilter.value}')`)
      }

    params.include = includes.join(',');

    return this.http.get(['ti', 'v1', 'faqs'], {
      query: params
    })
  }

  getFaqById(id: string, includeTopics: boolean): Promise<Faq> {
    return this.http.get(['ti', 'v1', 'faqs', id], {
      query: includeTopics ? {
        include: 'topics'
      } : undefined
    })
  }

  releaseFaqWithId(id: string): Promise<Faq> {
    return this.http.post(['ti', 'v1', 'faqs', id, 'release'], {})
  }

  updateFaqWithId(id: string, body: Partial<FaqUpdateBody>): Promise<Faq> {
    return this.http.put(['ti', 'v1', 'faqs', id], sanitize(body))
  }

  deleteFaqById(id: string): Promise<void> {
    return this.http.delete(['ti', 'v1', 'faqs', id])
  }
}
