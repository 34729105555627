import * as React from "react";
import TextInput from "../../../form/inputs/TextInput";

interface Props {
    disabled?: boolean;
}

export default function SenderFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <TextInput name='email' label='Email' disabled={disabled}/>
        </>
    )
}
