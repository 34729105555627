import {useRepo} from "../../../../repos/useRepo";
import {default as React, useRef} from "react";
import Table, {TableQuery} from "../../../table/Table";
import {buildQueryForTableQuery, queryResultFromCollectionResponseForQuery} from "../../../table/TableRepoAdapter";
import {useNavigation} from "../../../../services/navigation/useNavigation";
import {ColumnFactory} from "../../../entities/TableColumnFactory";
import {SiteTopic} from "../../../../models/ti/SiteTopic";
import {SiteTopicTableColumnFactory} from "./SiteTopicTableColumnFactory";
import {Site} from "../../../../models/ti/Site";
import {Filter} from "../../../../repos/CrudRepo";
import {CreateSiteTopicForSiteForm} from "./SiteTopicForm";
import {detailTableOptions} from "../../../table/options";
import {Action} from "material-table";
import {makeDeleteAction} from "../../../table/TableActionFactory";
import {FormRef} from "../../../form/FormRef";
import useUpdateSiteTopicModal from "../../../modals/useUpdateSiteTopicModal";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../rbac";

interface Props {
    site: Site;
}

export default function SiteTopicTable({ site }: Props) {
    const { siteTopicRepo } = useRepo();

    const { can } = useRbac();
    const canCreateSiteTopic = can(actions.siteTopic.create);

    const tableRef = useRef<any>(null);
    const formRef = useRef<FormRef>(null);

    const reloadData = () => tableRef.current.onQueryChange();
    const reloadForm = () => formRef.current!.reload();

    const deleteSiteTopic = (siteTopic: SiteTopic) => {
        siteTopicRepo.delete(siteTopic.id).then(() => {
            reloadData();
            reloadForm();
        }).catch(console.error)
    };

    const { setSiteTopicForUpdate, updateSiteTopicModal } = useUpdateSiteTopicModal(reloadData);

    const columnFactory: ColumnFactory<SiteTopic> = new SiteTopicTableColumnFactory();
    const tableActions: Action<SiteTopic>[] = [
        makeDeleteAction(deleteSiteTopic, { hidden: !can(actions.siteTopic.delete)})
    ];

    return (
        <>
            {canCreateSiteTopic && (
                <div style={{ margin: 16 }}>
                    <CreateSiteTopicForSiteForm ref={formRef} site={site} onSiteTopicCreated={reloadData}/>
                </div>
            )}
            <Table<SiteTopic>
                tableRef={tableRef}
                actions={tableActions}
                columns={columnFactory.makeColumns()}
                title={'Site Topics'}
                options={detailTableOptions}
                onRowClick={setSiteTopicForUpdate}
                data={async (tableQuery: TableQuery<SiteTopic>) => {
                    const query = buildQueryForTableQuery(tableQuery);
                    query.pageSize = 1000;
                    //query.sort = { field:'sortOrder', direction: 'asc' };
                    const siteFilter: Filter = { field: "siteId", "value": site.id };
                    if (query.filters) {
                        query.filters.push(siteFilter);
                    } else {
                        query.filters = [siteFilter];
                    }
                    const res = await siteTopicRepo.list(query);
                    return queryResultFromCollectionResponseForQuery(res)
                }}
            />
            {updateSiteTopicModal}
        </>
    );
}
