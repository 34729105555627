import HttpService from "@gsb/react-http";
import { Consultant, CreateConsultantRequest, UpdateConsultantRequest } from "../../../models/sid/Consultant";
import ConsultantRepo from "./ConsultantRepo";

export default class HttpConsultantRepo implements ConsultantRepo {
    pathComponents = ['sid', 'v1', 'consultant'];

    constructor(private http: HttpService) {}

    list(): Promise<Consultant[]> {
        return this.http.get(this.pathComponents);
    }

    update(id: string, body: UpdateConsultantRequest): Promise<Consultant> {
        return this.http.put([...this.pathComponents, id], body);
    }

    create(body: CreateConsultantRequest): Promise<Consultant> {
        return this.http.post([...this.pathComponents], body);
    }
   
}
