import {Button, createStyles, Divider, IconButton, makeStyles, Paper, Theme, Typography} from "@material-ui/core";
import React, {CSSProperties, useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {Check, Delete} from "@material-ui/icons";
import {blue, green, red} from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import {useRepo} from "../../../repos/useRepo";
import {SubscriptionFileTypePicker} from "../../form/pickers/SubscriptionFileTypePicker";
import {Formik, FormikHelpers} from "formik";
import {SubscriptionFileUploadRequest} from "../../../models/SubscriptionFileUpload";
import {subscriptionFileUploadSchema} from "./schemas/SubscriptionFileUploadSchema";
import {SubscriptionFileType} from "../../../models/SubscriptionFileType";

type UploadStatus = 'pending' | 'uploading' | 'failed' | 'success';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            marginLeft: 12,
            width: 248,
        },
        fileName: {
            width: 120,
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonFailure: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: theme.palette.secondary.main,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);

const baseStyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 9,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

interface Props {
    onFileUploaded: () => void;
}

export default function SubscriptionFileUpload({ onFileUploaded }: Props) {
    const [files, setFiles] = useState<File[]>([]);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: ['.txt', 'text/plain', '.csv' ,'text/csv', 'text/*', 'txt/*',
            '.xls', '.xlsx', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ],
        multiple: true,
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles)
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? { borderColor: blue[400] } : {}),
        ...(isDragAccept ? { borderColor: blue[300] } : {}),
        ...(isDragReject ? { borderColor: red[500] } : {})
    }), [isDragActive, isDragReject, isDragAccept]);

    function deleteFile(file: File) {
        const updatedFiles = files.filter(f => f.name !== file.name);
        setFiles(updatedFiles)
    }

    return (
        <Paper style={{ padding: 16 }}>

            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Drop subscription files here or click to select files</p>
            </div>

            {files.length > 0 &&
            <div>
              <Divider style={{ marginTop: 18, marginBottom: 8 }}/>

                {files.map(file => (
                    <FileRow key={file.name} file={file} disabled={false} onDeleteClicked={deleteFile} onFileUploaded={onFileUploaded}/>
                ))}
            </div>
            }

        </Paper>
    )
}

function FileRow(props: { file: File, disabled: boolean, onDeleteClicked: (file: File) => void, onFileUploaded: () => void }) {
    const { file, onDeleteClicked, onFileUploaded } = props;
    const { subscriptionRepo } = useRepo();

    const [status, setStatus] = useState<UploadStatus>('pending');
    const pending = status === 'uploading';
    const loading = status === 'uploading';
    const success = status === 'success';
    const failed = status === 'failed';
    const disabled = loading || success || props.disabled;
    const isUploadButtonDisabled = pending || disabled;

    const classes = useStyles();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
        [classes.buttonFailure]: failed,
    });

    const [fileTypes, setFileTypes] = useState<SubscriptionFileType[]>([]);

    const fileTypeId = useMemo(() => {
        const fileType = fileTypes.find(f => f.fileNames && f.fileNames.toLowerCase().includes(file.name.toLowerCase()));
        return fileType ? fileType.id : '';
    }, [file, fileTypes]);

    useEffect(() => {
        subscriptionRepo.listAllSubscriptionFileTypes().then(setFileTypes).catch(console.error)
    }, []);

    function uploadFile(values: SubscriptionFileUploadRequest, { setSubmitting }: FormikHelpers<SubscriptionFileUploadRequest>) {
        setSubmitting(true);
        setStatus('uploading');

        subscriptionRepo.uploadSubscriptionFile(values)
            .then(res => {
                setStatus('success')
                onFileUploaded()
            })
            .catch(err => setStatus('failed'))
            .finally(() => setSubmitting(false));
    }

    function uploadButtonTitleForStatus(status: UploadStatus): string {
        switch (status) {
            case "failed":
                return  "Try Again";
            case "success":
                return "Success";
            case "pending":
            case "uploading":
                return "Upload";
        }
    }

    return (
        <Formik<SubscriptionFileUploadRequest>
            enableReinitialize={true}
            initialValues={{ fileTypeId: fileTypeId, file: file }}
            validationSchema={subscriptionFileUploadSchema}
            onSubmit={uploadFile}>
            {({ handleSubmit }) => {

                return (
                    <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>

                            <IconButton aria-label="delete" onClick={() => onDeleteClicked(file)} disabled={disabled}>
                                <Delete />
                            </IconButton>

                            <SubscriptionFileTypePicker name='fileTypeId' disabled={disabled}/>

                            <Typography className={classes.fileName} align='left' color='textSecondary' variant='subtitle2' display='inline'>{file.name}</Typography>

                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>

                            {failed &&
                            <Typography variant='caption'>
                              Failed to upload. Please check the file type and try again.
                            </Typography>
                            }

                            <div className={classes.wrapper}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={buttonClassname}
                                    disabled={isUploadButtonDisabled}
                                    size='large'
                                    startIcon={success ? <Check/> : <CloudUploadIcon />}
                                    type='submit'>
                                    {uploadButtonTitleForStatus(status)}
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>

                        </div>

                    </form>
                );
            }}

        </Formik>
    )
}
