import {Company} from "../../../../models/Company";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {User} from "../../../../models/User";
import CompanyUsersTable from "../CompanyUsersTable";
import {useNavigation} from "../../../../services/navigation/useNavigation";


export default function UsersTab(props: { company: Company }) {

    const { company } = props;
    const { navigateToUser } = useNavigation();
    const { companyRepo } = useRepo();

    return (
        <>
            <CompanyUsersTable company={company} onUserSelected={navigateToUser}/>
        </>
    )
}
