import {default as React, useState} from "react";
import {User} from "../../../models/User";
import {CreateUserModal} from "./UserForm";


export default function useCreateUserModal(onUserCreated: (user: User) => void) {
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const handleUserCreated = (user: User) => {
        close();
        onUserCreated(user)
    };

    const modal = (
        <CreateUserModal
            open={open}
            onClose={close}
            onUserCreated={handleUserCreated}
        />
    );

    return { openCreateUserModal: () => setOpen(true), createUserModal: modal }
}