import * as React from "react";
import {useState} from "react";
import {SendGridAccount} from "../../models/SendGridAccount";
import {UpdateTemplateModal} from "../entities/integrations/sendgrid/SendGridForm";

export default function useUpdateTemplateModal(onSendGridAccountUpdated: (sendgridAccount: SendGridAccount) => void) {

    const [sendgridAccount, setSendGridAccount] = useState<SendGridAccount>();

    const handleTemplateUpdated = (sendgridAccount: SendGridAccount) => {
        setSendGridAccount(undefined);
        onSendGridAccountUpdated(sendgridAccount)
    };

    const modal = sendgridAccount ? (
        <UpdateTemplateModal
            open={true}
            onClose={() => setSendGridAccount(undefined)}
            sendgridAccount={sendgridAccount}
            onSendGridAccountUpdated={handleTemplateUpdated}
        />
    ) : null;

    return { setSendGridAccountForUpdate: setSendGridAccount, updateSendGridAccountModal: modal }
}
