import { User } from "../../../models/User";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { withModal } from "../../modal";
import { FormSubmitButton } from "../../form/buttons/FormSubmitButton";
import { VerticalFormLayout } from "../../form/FormLayout";
import { useRepo } from "../../../repos/useRepo";
import * as Yup from "yup";
import TextInput from "../../form/inputs/TextInput";
import { Typography } from "@material-ui/core";

export function UserCreateB2CForm(props: { user: User, onCreated: (msg?: string) => void }) {
    const { user, onCreated } = props;
    const { userRepo } = useRepo();

    interface b2cForm {
        password: string,
        confirmPassword: string
    };

    const schema = Yup.object<b2cForm>().shape({
        password: Yup.string().required("This field is required"),
        confirmPassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
            )
        })
    });

    const onSubmit = (values: b2cForm, { setSubmitting }: FormikHelpers<any>) => {
        // Submit Password Reset
        userRepo.createUserB2CById(user.id, values.password)
            .then(() => onCreated())
            .catch(() => onCreated('Invalid Password, Please Try Again.'));

        setSubmitting(false);
    };

    return (
        <Formik<b2cForm>
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={schema}
            onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout
                    fieldset={
                        <>
                            <TextInput label={'New Password'} name={'password'} />
                            <TextInput label={'Confirm Password'} name={'confirmPassword'} />
                            <Typography variant="caption" display="block" style={{ marginRight: 6, marginLeft: 6 }}>Password must be at least 8 characters long with<br />one uppercase, one lowercase and one numeric character.</Typography>
                        </>
                    }
                    bottomContent={<FormSubmitButton label={"Create"} variant='Create' disabled={false} />}
                />
            </Form>
        </Formik>
    )
}

export const CreateUserB2CModal = withModal(UserCreateB2CForm, 'Create User B2C Account');
