import * as React from "react";
import {useState} from "react";
import {User} from "../../models/User";
import {License} from "../../models/License";
import {AssignLicensesToUserModal} from "../forms/AssignLicensesForm";
import { nameFromUser } from "../modal/utils";

export default function useAssignLicensesToUserModal(onLicensesAssigned: (licenses: License[]) => void) {

    const [user, setUser] = useState<User>();

    const handleLicensesAssigned = (licenses: License[]) => {
        setUser(undefined);
        onLicensesAssigned(licenses);
    };

    const title = `Assign Licenses to: ${nameFromUser(user)}`

    const modal = user ? (
        <AssignLicensesToUserModal
            open={true}
            title={title}
            onClose={() => setUser(undefined)}
            onLicensesAssigned={handleLicensesAssigned}
            user={user}
        />
    ) : null;

    return { setUserForAssigningLicenses: setUser, assignUserLicensesModal: modal }
}
