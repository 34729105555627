import * as React from "react";
import {useState} from "react";
import {Template} from "../../models/mail/Template";
import {CreateTemplateModal} from "../entities/mail/templates/TemplateForm";

export default function useCreateTemplateModal(onTemplateCreated: (template: Template) => void) {

    const [open, setOpen] = useState(false);

    const handleTemplateCreated = (template: Template) => {
        setOpen(false);
        onTemplateCreated(template)
    };

    const modal = (
        <CreateTemplateModal
            open={open}
            onClose={() => setOpen(false)}
            onTemplateCreated={handleTemplateCreated}
        />
    );

    return { setCreateTemplateModalOpen: setOpen, createTemplateModal: modal }
}
