import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteConfirmationDialog from "../modals/DeleteConfirmationDialog";
import {FaqStatusIdentifier} from "../../../models/ti/FaqStatus";
import {Faq} from "../../../models/ti/Faq";

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        margin: theme.spacing(1),
    }
}));

interface Props {
    isDirty: boolean;
    isLoading: boolean;
    faq: Faq;

    onSaveClicked: () => void;
    onReleaseClicked: () => void;
    onDeleteClicked: () => void;
}

interface ActionBarViewModel {
    readonly isSaveVisible: boolean;
    readonly isReleaseVisible: boolean;
}

export class FaqStatusModel {
    isReleased = this.status === FaqStatusIdentifier.released;

    constructor(private status: FaqStatusIdentifier) {}
}

function viewModelForStatus(status: FaqStatusModel): ActionBarViewModel {

    const { isReleased } = status;

    return {
        isSaveVisible: true,
        isReleaseVisible: !isReleased,
    };
}

export default function FaqActionBar(props: Props) {
    const classes = useStyles();
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const {
        isDirty,
        isLoading,
        faq,
        onSaveClicked,
        onReleaseClicked,
        onDeleteClicked,
    } = props;

    const identifier = faq.status && faq.status.identifier ? faq.status.identifier as FaqStatusIdentifier : FaqStatusIdentifier.pending;
    const model = new FaqStatusModel(identifier);
    const vm = viewModelForStatus(model);
    const { isSaveVisible, isReleaseVisible} = vm;

    const isSaveButtonDisabled = isLoading || !isDirty;
    const isActionButtonDisabled = isLoading || isDirty;

    return (
        <div style={{display: "flex", justifyContent: "space-between" }}>
            <div>
                <Button
                  style={{backgroundColor: "#e57373", color: "white", marginRight: 10}}
                  variant="contained"
                  className={classes.button}
                  disabled={isActionButtonDisabled}
                  onClick={() => setIsConfirmDeleteDialogOpen(true)}>
                    Delete
                </Button>
            </div>
            <div>

                {isReleaseVisible &&
                <Button
                  style={{marginRight: 10}}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={isActionButtonDisabled}
                  onClick={() => onReleaseClicked()}>
                  Release
                </Button>
                }
            </div>

            <div>

                {isSaveVisible &&
                <Button key="save-button"
                        style={{ marginRight: 10 }}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={isSaveButtonDisabled}
                        onClick={() => onSaveClicked()}>
                  Save
                </Button>
                }

            </div>
            <DeleteConfirmationDialog
              open={isConfirmDeleteDialogOpen}
              onCancel={() => setIsConfirmDeleteDialogOpen(false)}
              onConfirm={onDeleteClicked}
            />
        </div>
    )
};
