import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../../../../repos/useRepo";
import {initialValuesForStateApplicationSection, stateApplicationSectionFieldSchema } from "../../../../../../utils/schemas/state-id-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../../../rbac";
import {withModal} from "../../../../../modal";
import {VerticalFormLayout} from "../../../../../form/FormLayout";
import {CreateStateApplicationSectionFields, UpdateStateApplicationSectionFields} from "./StateApplicationSectionFields";
import {FormSubmitButton} from "../../../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../../../form/helpers/formSubmit";
import { CreateStateApplicationSectionRequest, StateApplicationSection, UpdateStateApplicationSectionRequest } from "../../../../../../models/sid/StateApplicationSection";
import { StateApplication } from "../../../../../../models/sid/StateApplication";

export function UpdateStateApplicationSectionForm(props: { stateApplicationSection: StateApplicationSection, onStateApplicationSectionUpdated: (stateApplicationSection: StateApplicationSection) => void }) {
    const { stateApplicationSection, onStateApplicationSectionUpdated }  = props;
    const { stateApplicationSectionRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.template.update);

    const onSubmit = updateSubmitHandler<UpdateStateApplicationSectionRequest, StateApplicationSection>(
        body => {
            if(body.validate && body.validate.length > 0){
                body.validate = JSON.parse(body.validate);
            }
            return stateApplicationSectionRepo.update(stateApplicationSection.id, body)
        },
        initialValuesForStateApplicationSection,
        onStateApplicationSectionUpdated,
        console.error,
    );

    return (
        <Formik<UpdateStateApplicationSectionRequest> enableReinitialize={true} initialValues={initialValuesForStateApplicationSection(stateApplicationSection)} validationSchema={stateApplicationSectionFieldSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateStateApplicationSectionFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateStateApplicationSectionForm(props: { stateApplication: StateApplication, onStateApplicationSectionCreated: (stateApplicationSection: StateApplicationSection) => void }) {
    const { onStateApplicationSectionCreated, stateApplication }  = props;
    const { stateApplicationSectionRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateStateApplicationSectionRequest) => {
            if(body.validate && body.validate.length > 0){
                body.validate = JSON.parse(body.validate);
            }
            return stateApplicationSectionRepo.create({...body, stateApplicationId: stateApplication.id})
        },
        onStateApplicationSectionCreated,
        console.error
    );

    return (
        <Formik<CreateStateApplicationSectionRequest> initialValues={initialValuesForStateApplicationSection()} validationSchema={stateApplicationSectionFieldSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateStateApplicationSectionFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}

export const UpdateStateApplicationSectionModal = withModal(UpdateStateApplicationSectionForm, "Update State Application Section");
export const CreateStateApplicationSectionModal = withModal(CreateStateApplicationSectionForm, "Create State Application Section");