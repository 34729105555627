import StateRepo from "./StateRepo";
import {State} from "../../models/State";

export class InMemoryStateRepoCache implements StateRepo {
    cachedStateList?: State[];
    resellerPromise?: Promise<State[]>;

    constructor(private inner: StateRepo) {}

    async list(): Promise<State[]> {
        if (this.cachedStateList) {
            return this.cachedStateList;
        } else {
            if (this.resellerPromise) {
                return this.resellerPromise;
            } else {
                const resellersPromise = this.inner.list();
                this.resellerPromise = resellersPromise;
                const resellers = await resellersPromise;
                this.cachedStateList = resellers;
                this.resellerPromise = undefined;
                return resellers;
            }
        }
    };
}