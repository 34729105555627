import {ButtonProps as MaterialButtonProps} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

export default function DeleteButton({ ...ButtonProps }: MaterialButtonProps) {
    const classes = useStyles({});

    return (
        <Button className={classes.destructiveAction} {...ButtonProps}>
            Delete
        </Button>
    )
}

const useStyles = makeStyles({
    destructiveAction: {
        color: "red"
    }
});
