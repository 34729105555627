import React, {useEffect, useState} from "react";
import ReviewAnswerModal from "../modals/ReviewAnswerModal";
import AssignReviewerModal from "../modals/AssignReviewerModal";
import {Prompt} from "react-router-dom";
import Subtitle from "./Subtitle";
import {useRepo} from "../../../repos/useRepo";
import {Question} from "../../../models/ti/Question";
import {QuestionStatusIdentifier} from "../../../models/ti/QuestionStatus";
import {Consultant} from "../../../models/ti/Consultant";
import TextInput from "../../form/old/TextInput";
import QuestionActionBar from "./QuestionActionBar";
import TextEditor from "../../form/old/TextEditor";
import config from "../../../config";

interface Props {
    question: Question;
    onQuestionChanged: (question: Question) => void;
    onDelete: () => void;
}

export default function EditQuestionForm(props: Props) {
    const { question, onQuestionChanged, onDelete } = props;
    const { questionRepo, faqRepo } = useRepo();

    const [isLeaveReviewModalOpen, setLeaveReviewModalOpen] = useState<boolean>(false);
    const [isAssignReviewerModalOpen, setAssignReviewerModalOpen] = useState<boolean>(false);
    const [isDirty, setDirty] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(question.title || '');
    const [draft, setDraft] = useState<string>(question.draft || '');
    const [notes, setNotes] = useState<string>(question.notes || '');

    useEffect(() => {
        setDirty(false)
    }, [question]);

    async function onSubmitQuestionForFaq() {
        try {
            setLoading(true);
            await faqRepo.createFaqFromQuestion(question);

            // Reload the question because faqId should be set now
            const updatedQuestion = await questionRepo.getQuestionById(question.id);
            onQuestionChanged(updatedQuestion)
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    async function onSaveQuestion() {
        try {
            setLoading(true);
            const updatedQuestion = await questionRepo.answerQuestion(question.id, {
                title: title,
                draft: draft,
                notes: notes
            });

            onQuestionChanged(updatedQuestion)
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    function onTitleChanged(title: string) {
        setDirty(true);
        setTitle(title)
    }

    function onAnswerChanged(answer: string) {
        setDirty(true);
        setDraft(answer)
    }

    function onNotesChanged(notes: string) {
        setDirty(true);
        setNotes(notes)
    }

    function onBeginResearching() {
        performQuestionOperation(() => questionRepo.updateStatusForQuestion(question.id, QuestionStatusIdentifier.researching))
    }

    async function performQuestionOperation(operation: () => Promise<Question>) {
        try {
            setLoading(true);
            const updatedQuestion = await operation();
            onQuestionChanged(updatedQuestion)
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    function onPlaceOnHold() {
        performQuestionOperation(() => questionRepo.updateStatusForQuestion(question.id, QuestionStatusIdentifier.onHold))
    }

    function onSubmitForReview() {
        setAssignReviewerModalOpen(true)
    }

    function onSubmitQuestionForReview(consultant?: Consultant) {
        setAssignReviewerModalOpen(false);
        const consultantId = consultant ? consultant.id : undefined;
        performQuestionOperation(() => questionRepo.submitQuestionForReview(question.id, consultantId))
    }

    function onLeaveReview() {
        setLeaveReviewModalOpen(true)
    }

    function onReviewApproved(reviewNotes?: string) {
        setLeaveReviewModalOpen(false);
        performQuestionOperation(() => questionRepo.leaveReviewForQuestion(question.id, true, reviewNotes))
    }

    function onReviewRejected(reviewNotes?: string) {
        setLeaveReviewModalOpen(false);
        performQuestionOperation(() => questionRepo.leaveReviewForQuestion(question.id, false, reviewNotes))
    }

    function onReleaseQuestion() {
        performQuestionOperation(() => questionRepo.releaseQuestionWithId(question.id))
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>

            <TextInput title="Title" value={title} onValueChanged={onTitleChanged}/>

            <QuestionActionBar isLoading={isLoading}
                               isDirty={isDirty}
                               question={question}
                               onSaveClicked={onSaveQuestion}
                               onSubmitForFaqClicked={onSubmitQuestionForFaq}
                               onResearchingClicked={onBeginResearching}
                               onOnHoldClicked={onPlaceOnHold}
                               onSubmitForReviewClicked={onSubmitForReview}
                               onLeaveReviewClicked={onLeaveReview}
                               onReleaseClicked={onReleaseQuestion}
                               onDeleteClicked={onDelete}
            />

            <TextInput title="Question" value={question.body} isReadOnly={true}/>

            <div>
                <div>
                    <Subtitle title="Answer"/>
                    <TextEditor value={draft} onValueChanged={onAnswerChanged}/>
                </div>
                <div>
                    <Subtitle title="Notes"/>
                    <TextEditor value={notes} onValueChanged={onNotesChanged}/>
                </div>
            </div>

            <ReviewAnswerModal open={isLeaveReviewModalOpen}
                               onAccept={onReviewApproved}
                               onReject={onReviewRejected}
                               onClose={() => setLeaveReviewModalOpen(false)}/>

            <AssignReviewerModal open={isAssignReviewerModalOpen}
                               onSubmit={onSubmitQuestionForReview}
                               onClose={() => setAssignReviewerModalOpen(false)}/>

            <Prompt when={isDirty} message={config.goBackPromptText}/>
        </div>
    );
}
