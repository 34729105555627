import { Form, Formik } from "formik";
import { Company } from "../../../../models/Company";
import React, { useMemo } from "react";
import { FormSubmitButton } from "../../../form/buttons/FormSubmitButton";
import { withModal } from "../../../modal";
import { createSubmitHandler } from "../../../form/helpers/formSubmit";
import { useRepo } from "../../../../repos/useRepo";
import { PlanPicker } from "../../../form/pickers/PlanPicker";
import DemeanorPicker from "../../../form/pickers/DemeanorPicker";
import TextInput from "../../../form/inputs/TextInput";
import { CreateNoChargeSubscriptionForCompanyRequest } from "../../../../repos/company/CompanyRepo";
import { CreateNoteForCompanyRequest } from "../../../../models/Company";
import * as Yup from "yup";
import CallTypePicker from "../../../form/pickers/CallTypePicker";
import CallStatusPicker from "../../../form/pickers/CallStatusPicker";
import CallSourcePicker from "../../../form/pickers/CallSourcePicker";
import { CompanyUserPicker } from "../../../form/pickers/UserPicker";
import { TwoColumnFormLayout } from "../../../form/FormLayout";
import { companyNoteSchema } from "../../../../utils/schemas/company-schema";

interface Props {
    company: Company
    onSubscriptionCreated: () => void;
}

type CreateNoChargeSubscriptionAndNoteForCompany = {
    subscription: CreateNoChargeSubscriptionForCompanyRequest,
    note: CreateNoteForCompanyRequest
}

function CreateNoChargeSubscriptionForCompanyForm({ company, onSubscriptionCreated }: Props) {
    const { companyRepo } = useRepo()
    const emptyGuid = '00000000-0000-0000-0000-000000000000';

    const onSubmit = createSubmitHandler(
        (body: CreateNoChargeSubscriptionAndNoteForCompany) => Promise.all([
            companyRepo.createNoChargeSubscriptionForCompanyById(company.id, body.subscription),
            companyRepo.createNoteForCompanyById(company.id, { ...body.note, body: `Created No Charge Subscription: ${body.note.body}` })]),
        onSubscriptionCreated,
        console.error
    );

    const optionalUser = { id: emptyGuid, firstName: 'OTHER', lastName: '', subjectId: '', email: '', companyId: '', salesChannelId: '', resellerId: '', createdAt: '', updatedAt: '' }
    const initialValues = { subscription: { planId: '' }, note: { demeanor: '', body: '', status: '', source: '', userId: '' } };

    const schema = Yup.object().shape({
        subscription: Yup.object().shape({
            planId: Yup.string().required("Plan is required"),
        }),
        note: companyNoteSchema
    });

    const sourceWLink = ['Live Chat', 'Support Center Email'];

    return (
        <Formik<CreateNoChargeSubscriptionAndNoteForCompany> initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}>
            {(form) => (
                <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                    <TwoColumnFormLayout
                        col1FieldSet={
                            <>
                                <PlanPicker name='subscription.planId' label='Plan' />

                                <DemeanorPicker name='note.demeanor' label='Customer Demeanor' />

                                <CallStatusPicker name='note.status' label='Call Status' />

                                <CallSourcePicker name='note.source' label='Call Source' />

                                {(form.values.note.source?.length > 0 && sourceWLink.indexOf(form.values.note.source) > -1) && (
                                    <TextInput name='note.sourceLink' label='Source Link' helperText={'Link to Live Chat Transcript or Support Center Email'} />
                                )}

                                <CallTypePicker name='note.callType' label='Call Type' allowEmpty={true} emptyValue={null} emptyValueDescription={'NONE'} />


                                <CompanyUserPicker companyId={company.id} name='note.userId' label='User' allowEmpty={true} emptyValue={null} emptyValueDescription={'NONE'} allowOptionalUser={true} optionalUser={optionalUser} />

                                {(form.values.note.userId === emptyGuid) && (
                                    <TextInput name='note.userDisplayName' label='User Other' />
                                )}
                            </>}
                        col2FieldSet={<TextInput name='note.body' label='Note' multiline={true} rows={21} />}
                        bottomContent={<FormSubmitButton variant='Create' />} />
                </Form>
            )}
        </Formik>
    )
}

export const CreateNoChargeSubscriptionForCompanyModal = withModal(CreateNoChargeSubscriptionForCompanyForm);
