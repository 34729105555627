import React from "react";
import {verticalFormLayout} from "./styles/layout";

interface FormLayoutProps {
    fieldset: JSX.Element | React.ReactNode;
    bottomContent: JSX.Element | React.ReactNode;
}

interface TwoColumnFormLayoutProps {
    col1FieldSet: JSX.Element | React.ReactNode;
    col2FieldSet: JSX.Element | React.ReactNode;
    bottomContent: JSX.Element | React.ReactNode;
}


export const VerticalFormLayout: React.FC<FormLayoutProps> = ({ fieldset, bottomContent }) => {
    return (
        <div style={verticalFormLayout}>
            {fieldset}

            <div style={{ marginTop: 24 }}>
                {bottomContent}
            </div>
        </div>
    )
};

export const TwoColumnFormLayout: React.FC<TwoColumnFormLayoutProps> = ({ col1FieldSet, col2FieldSet, bottomContent }) => {
return (
    <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flex: 1 }} >
                <div style={verticalFormLayout}>
                    {col1FieldSet}
                </div>
            </div>
            <div style={{ display: 'flex', flex: 1 }} >
                <div style={verticalFormLayout}>
                    {col2FieldSet}
                </div>
            </div>
        </div>
        <div style={{ marginTop: 24 }}>
            {bottomContent}
        </div>
    </div>
    )
};

// export const HorizontalFormLayout: React.FC<FormLayoutProps> = ({ fieldset, bottomContent }) => {
//     return (
//         <div style={{ minWidth: 240, maxWidth: 640 }}>
//             <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'leading' }}>
//                 {fieldset}
//             </div>
//
//             <div style={{ marginTop: 24 }}>
//                 {bottomContent}
//             </div>
//         </div>
//     )
// };

export const InlineFormLayout: React.FC<FormLayoutProps> = ({ fieldset, bottomContent }) => {
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', justifyItems: 'center', alignItems: 'center', alignContent: 'center' }}>
            {fieldset}
            {bottomContent}
        </div>
    )
};