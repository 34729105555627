import * as Yup from 'yup';
import {CreatePlanRequest, Plan, UpdatePlanRequest} from "../../models/Plan";
import {Product} from "../../models/Product";

const baseFields = {
    name: Yup.string().min(1, 'Name cannot be empty').required('Name is required'),
    description: Yup.string().nullable(),
    stripeAccountIdentifier: Yup.string().nullable(),
    stripePlanId: Yup.string().nullable(),
    adpPlanId: Yup.string().nullable(),
    appStoreProductId: Yup.string().nullable(),
};

export const createPlanSchema = Yup.object().shape({
    ...baseFields,
    productId: Yup.string().required("Product is required"),
    resellerId: Yup.string().required('Reseller is required'),
    identifier: Yup.string().required("Identifier is required"),
});

export const updatePlanSchema = Yup.object().shape(baseFields);

export const initialValuesForPlanUpdate = (plan?: Plan): UpdatePlanRequest => ({
    name: plan?.name ?? '',
    identifier: plan?.identifier ?? '',
    description: plan?.description ?? '',
    stripeAccountIdentifier: plan?.stripeAccountIdentifier ?? '',
    stripePlanId: plan?.stripePlanId ?? '',
    adpPlanId: plan?.adpPlanId ?? '',
    appStoreProductId: plan?.appStoreProductId ?? '',
});

export const initialValuesForPlanCreateForProduct = (product: Product): CreatePlanRequest => ({
    ...initialValuesForPlanUpdate(),
    productId: product.id,
    resellerId: '',
});



