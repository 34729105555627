import React from 'react';
import { Theme, WithTheme} from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import {blue, green, lightBlue, lightGreen} from "@material-ui/core/colors";

interface Props extends WithTheme{
    status: string
}

export class QuestionStatus {
    static pending = "pending";
    static submitted = "submitted";
    static researching = "researching";
    static onHold = "on-hold";
    static reviewing = "reviewing";
    static approved = "approved";
    static released = "released";
}

export interface QuestionStatusModel {
    title: string;
    textColor?: string;
    color?: string;
    borderColor?: string;
}

function modelForStatusWithTheme(status: string, theme: Theme): QuestionStatusModel {
    switch (status) {
        case QuestionStatus.pending:
            return { title: "Not Released", color: "white", textColor: theme.palette.grey[500] };
        case QuestionStatus.submitted:
            return { title: "Pending", color: "white", textColor: theme.palette.grey[500] };
        case QuestionStatus.researching:
            return { title: "Researching", color: "white", textColor: theme.palette.grey[500] };
        case QuestionStatus.onHold:
            return { title: "On Hold", color: theme.palette.error.light, borderColor: theme.palette.error.dark };
        case QuestionStatus.reviewing:
            return { title: "Needs Review", color: theme.palette.error.light, borderColor: theme.palette.error.dark };
        case QuestionStatus.approved:
            return { title: "Ready to Release", color: lightGreen[500], borderColor: lightGreen[600] };
        case QuestionStatus.released:
            return { title: "Released", color: lightBlue[300], borderColor: blue[400] };
        default:
            return { title: status, color: green[300], textColor: "black" }
    }
}

 function QuestionStatusBadge(props: Props) {

    const { status, theme } = props;
    const model = modelForStatusWithTheme(status, theme);

     return (
         <div style={{
             textAlign: "center",
             backgroundColor: model.color,
             color: model.textColor || "white",
             borderWidth: "1px",
             borderStyle: "solid",
             borderColor: model.borderColor || theme.palette.grey[300],
             borderRadius: "7px",
             padding: "4px 7px",
             fontSize: "10px",
             fontWeight: 600,
             margin: "6px",
             fontFamily: "Helvetica",
             whiteSpace: "nowrap"
         }}>
             {model.title}
         </div>
     );
}

export default withTheme(QuestionStatusBadge)