import * as React from "react";
import CheckboxInput from "../../../../form/inputs/CheckboxInput";
import TextInput from "../../../../form/inputs/TextInput";
import StatePicker from "../../../../form/pickers/StatePicker";

interface Props {
    disabled?: boolean;
}

export function UpdateStateApplicationFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <StatePicker name='stateCode' label='State' disabled={disabled}/>

            <TextInput name='description' label='Description' disabled={disabled}/>

            <TextInput name='stateWithholdingTaxLabel' label='State Withholding Tax Name' disabled={disabled}/>

            <TextInput name='stateUnemploymentTaxLabel' label='State Unemployment Tax Name' disabled={disabled}/>

            <CheckboxInput name='isLive' label='Is Live' disabled={disabled} />

        </>
    )
}

export function CreateStateApplicationFields({ disabled }: Props) {
    return (
        <>
            <UpdateStateApplicationFields disabled={disabled}/>
        </>
    )
}
