import React, {useRef} from 'react';
import MaterialTable, {Action, Column, DetailPanel, Filter, Options, Query, QueryResult} from 'material-table';
import {LocalStorageTableRowOptionProvider, TableRowOptionProvider} from "./TableRowOptionProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

export type TableColumn<T extends object> = Column<T> & {path?: string};
export type TableOptions<T extends object> = Options<T>;
export type TableFilter<T extends object> = Filter<T> & {column: TableColumn<T>};
export type TableQuery<T extends object> = Query<T> & {filterFieldName?: string, filters: TableFilter<T>[]};
export type TableQueryResult<T extends object> = QueryResult<T>;
export type TableColumnType = ('string' | 'boolean' | 'numeric' | 'date' | 'datetime' | 'time' | 'currency')

interface Props<T extends object> {
    title?: string;
    tableRef?: any;
    actions?: (Action<T> | ((rowData: T) => Action<T>))[];
    columns: TableColumn<T>[];
    data: (query: TableQuery<T>) => Promise<TableQueryResult<T>>;
    options?: TableOptions<T>;
    onRowClick?: (item: T, toggleDetailPanel?: (panelIndex?: number) => void) => void;
    detailPanel?: ((rowData: T) => React.ReactNode) | (DetailPanel<T> | ((rowData: T) => DetailPanel<T>))[];
    parentChildData?: (row: T, rows: T[]) => T | undefined;
}

export default function Table<T extends object>(props: Props<T>) {
    const { title, actions, data, detailPanel, columns, parentChildData } = props;
    const tableRepo: TableRowOptionProvider = new LocalStorageTableRowOptionProvider([5, 10, 15, 25, 50, 100]);
    const options: TableOptions<T> = props.options || {};

    const ref = useRef<any>(null);
    const tableRef = props.tableRef || ref;

    function onQueryChanged(query: Query<T>) {
        const { filters, ...dataQuery } = query;

        return data({
            ...dataQuery,
            filters: filters as TableFilter<T>[]
        })
    }

    const isPagingEnabled = options.paging !== false;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MaterialTable<T>
            style={{ maxWidth: '100%' }}
            tableRef={tableRef}
            title={title}
            actions={actions}
            onChangeRowsPerPage={tableRepo.setCurrentRowsPerPage}
            options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 400,
                pageSizeOptions: isPagingEnabled ? tableRepo.getRowPerPageOptions() : undefined,
                pageSize: isPagingEnabled ? tableRepo.getCurrentRowsPerPage() : undefined,
                showTitle: !!title,
                actionsColumnIndex: columns.length,
                sorting: true,
                filtering: true,
                rowStyle: (d, index, level) => ({
                    backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa'
                }),
                ...options
            }}
            detailPanel={detailPanel}
            columns={columns}
            data={onQueryChanged}
            parentChildData={parentChildData}
            onRowClick={(event, rowData, toggleDetailPanel) => props.onRowClick && props.onRowClick(rowData as T, toggleDetailPanel)}
        />
      </MuiPickersUtilsProvider>
    );
}

