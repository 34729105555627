import { FieldArray, Form, Formik } from "formik";
import { useRbac } from "@gsb/react-rbac";
import React, { useEffect, useState } from "react";
import { useRepo } from "../../../../repos/useRepo";
import { actions } from "../../../../rbac";
import { verticalFormLayout } from "../../../form/styles/layout";
import { FormSubmitButton } from "../../../form/buttons/FormSubmitButton";
import { updateSubmitHandler } from "../../../form/helpers/formSubmit";
import { StateApplicationSection } from "../../../../models/sid/StateApplicationSection";
import StateApplicationFieldInput from "../../../form/inputs/StateApplicationFieldInput";
import { Button, Step, StepContent, StepLabel, Typography } from "@material-ui/core";
import theme from "../../../../theme";
import { UpdateApplicationSectionForm } from "./ApplicationSectionForm";

interface UpdateApplicationSectionStepProps {
    applicationId: string;
    stateApplicationSection: StateApplicationSection;
    applicationFields: any;
    onBackClick: () => void;
    onNextClick: () => void;
    disableBack: boolean;
    disableNext: boolean;
    btnClass: string;
    btnNextClass: string;
    onApplicationUpdated: (values: any) => void;
}

export function UpdateApplicationSectionStep({ applicationId, stateApplicationSection, applicationFields, onBackClick, onNextClick, disableBack, disableNext, btnClass, btnNextClass, onApplicationUpdated }: UpdateApplicationSectionStepProps) {
    const { can } = useRbac();
    const canUpdateApplication = can(actions.stateIdApplication.update);

    const [readOnly, setReadOnly] = useState<boolean>(true);

    const disabled = !canUpdateApplication;

    let submitSectionForm: any = null;
    const handleSubmitSectionForm = (e: any) => {
        if (submitSectionForm) submitSectionForm(e);
    }
    const bindSubmitSectionForm = (submit: any) => submitSectionForm = submit;

    const handleOnApplicationSectionUpdated = (values: any) => {
        setReadOnly(true);
        onApplicationUpdated(values);
    }

    return (
        <>
            <UpdateApplicationSectionForm applicationId={applicationId} stateApplicationSection={stateApplicationSection} applicationFields={applicationFields} readOnly={readOnly} bindSubmit={bindSubmitSectionForm} onApplicationSectionUpdated={handleOnApplicationSectionUpdated} />
            <div >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {readOnly ? (<Button disabled={disableBack} onClick={onBackClick} className={btnClass}>Back</Button>) : null}
                    </div>
                    <div>
                        <Button disabled={disabled} onClick={() => setReadOnly(!readOnly)} className={btnClass}>{readOnly ? 'Edit' : 'Cancel'}</Button>
                        {readOnly ? (<Button variant="contained" color="primary" onClick={onNextClick} className={btnNextClass}>{disableNext ? 'Update Status' : 'Next'}</Button>) : (<Button onClick={handleSubmitSectionForm} className={btnNextClass}>Update</Button>)}
                    </div>
                </div>
            </div>
        </>
    )
}