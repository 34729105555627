import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../../../../repos/useRepo";
import {initialValuesForStateApplicationField, stateApplicationFieldFieldSchema } from "../../../../../../utils/schemas/state-id-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../../../rbac";
import {withModal} from "../../../../../modal";
import {TwoColumnFormLayout, VerticalFormLayout} from "../../../../../form/FormLayout";
import {CreateStateApplicationFieldFields, UpdateStateApplicationFieldFields} from "./StateApplicationFieldFields";
import {FormSubmitButton} from "../../../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../../../form/helpers/formSubmit";
import { CreateStateApplicationFieldRequest, StateApplicationField, UpdateStateApplicationFieldRequest } from "../../../../../../models/sid/StateApplicationField";
import { StateApplicationSection } from "../../../../../../models/sid/StateApplicationSection";
import TextInput from "../../../../../form/inputs/TextInput";

export function UpdateStateApplicationFieldForm(props: { stateApplicationField: StateApplicationField, onStateApplicationFieldUpdated: (stateApplicationField: StateApplicationField) => void }) {
    const { stateApplicationField, onStateApplicationFieldUpdated }  = props;
    const { stateApplicationFieldRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.template.update);

    const onSubmit = updateSubmitHandler<UpdateStateApplicationFieldRequest, StateApplicationField>(
        body => {
            if(body.options && body.options.length > 0){
                body.options = JSON.parse(body.options);
            }
            return stateApplicationFieldRepo.update(stateApplicationField.id, body)
        },
        initialValuesForStateApplicationField,
        onStateApplicationFieldUpdated,
        console.error,
    );

    return (
        <Formik<UpdateStateApplicationFieldRequest> enableReinitialize={true} initialValues={initialValuesForStateApplicationField(stateApplicationField)} validationSchema={stateApplicationFieldFieldSchema} onSubmit={onSubmit}>
            <Form>
            <TwoColumnFormLayout
                    col1FieldSet={<UpdateStateApplicationFieldFields disabled={isReadOnly}/>}
                    col2FieldSet={<>
                        <TextInput name='description' label='Description' multiline={true} rows={12} disabled={isReadOnly} helperText='Tooltip / Modal Popup Content' />
                        <TextInput name='options' label='Options' multiline={true} rows={12} disabled={isReadOnly} helperText='Custom json object to handle field options.'/>
                        </>}
                    bottomContent={<FormSubmitButton variant='Update' />} />
            </Form>
        </Formik>
    )
}

export function CreateStateApplicationFieldForm(props: { stateApplicationSection: StateApplicationSection, onStateApplicationFieldCreated: (stateApplicationField: StateApplicationField) => void }) {
    const { onStateApplicationFieldCreated, stateApplicationSection }  = props;
    const { stateApplicationFieldRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateStateApplicationFieldRequest) => stateApplicationFieldRepo.create({...body, stateApplicationSectionId: stateApplicationSection.id}),
        onStateApplicationFieldCreated,
        console.error
    );

    return (
        <Formik<CreateStateApplicationFieldRequest> initialValues={initialValuesForStateApplicationField()} validationSchema={stateApplicationFieldFieldSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateStateApplicationFieldFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}

export const UpdateStateApplicationFieldModal = withModal(UpdateStateApplicationFieldForm, "Update State Application Field");
export const CreateStateApplicationFieldModal = withModal(CreateStateApplicationFieldForm, "Create State Application Field");