import React, {useState} from "react";
import {UpdateSubscriptionFileRecordModal} from "./UpdateSubscriptionFileRecordForm";
import {SubscriptionFileRecord} from "../../../../models/SubscriptionFileRecord";

export default function useUpdateSubscriptionFileRecordModal(onRecordUpdated: () => void) {

    const [record, setRecord] = useState<SubscriptionFileRecord>();
    const close = () => setRecord(undefined);

    const modal = record ? (
        <UpdateSubscriptionFileRecordModal
            open={true}
            onClose={close}
            record={record}
            onRecordUpdated={() => {
                close();
                onRecordUpdated()
            }}
            title={`Update Record`}
        />
    ) : null;

    return {
        openUpdateSubscriptionFileRecordModal: (record: SubscriptionFileRecord) => setRecord(record),
        updateSubscriptionFileRecordModal: modal
    }
}
