import React, {useEffect, useRef, useState} from 'react';
import {TableColumn} from '../../../table/Table';
import {Paper} from '@material-ui/core';
import TableColumnFactory from '../../TableColumnFactory';
import {Reseller} from "../../../../models/Reseller";
import {SalesChannel} from "../../../../models/SalesChannel";
import {entityDictionaryFromList} from "../../../../models";
import EntityList from "../../EntityList";
import Detail from "./Detail";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {EntityDialog} from "../../EntityDialog";
import useCrudController from "../../useCrudController";
import {useRepo} from "../../../../repos/useRepo";
import {makeCreateAction} from "../../../table/TableActionFactory";
import {useNavigation} from "../../../../services/navigation/useNavigation";
import Routes from "../../../../Routes";

export default function List() {

    const { resellerRepo, salesChannelRepo } = useRepo();

    const { can } = useRbac();
    const { navigateToReseller, navigateToSalesChannel } = useNavigation();

    const permissions = {
        update: can(actions.reseller.update),
        delete: can(actions.reseller.delete),
        create: can(actions.reseller.create)
    };

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const [salesChannelsDictionary, setSalesChannelDictionary] = useState<Dictionary<SalesChannel>>({});

    useEffect(() => {
        salesChannelRepo.list({})
            .then(response => setSalesChannelDictionary(entityDictionaryFromList(response.results)));
    }, [salesChannelRepo]);

    const columns: TableColumn<Reseller>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeResellerIdColumn(),
        TableColumnFactory.fromProperty("Name", "name"),
        TableColumnFactory.fromProperty("Identifier", "identifier"),
        TableColumnFactory.makeSalesChannelColumn("Sales Channel", "salesChannel.id", c => c.salesChannel),
    ]);

    const [draft, setDraft] = useState<Partial<Reseller>>();

    const { _save, _delete, _list } = useCrudController(resellerRepo, reloadData);

    const [displayAddForm, setDisplayAddForm] = useState(false);

    function renderDetailDialog(onSave: () => Promise<void>,
                                onDelete: () => void,
                                onDismiss: () => void,
                                entity?: Reseller) {
        return (
            <EntityDialog
                permissions={permissions}
                onSave={onSave}
                onDelete={onDelete}
                onDismiss={onDismiss}
            >
                {(formProps) => (
                    <Detail
                        reseller={entity}
                        salesChannels={Object.values(salesChannelsDictionary)}
                        isReadOnly={!!entity && !permissions.update}
                        onValuesChange={setDraft}
                        {...formProps}
                    />
                )}
            </EntityDialog>
        )
    }

    const renderAddNewDialog = () => renderDetailDialog(
        _save(undefined, draft, () => setDisplayAddForm(false)),
        () => setDisplayAddForm(false),
        () => setDisplayAddForm(false),
    );

    return (
        <div>
            <Paper>
                <EntityList
                    title='Resellers'
                    columns={columns}
                    fetchData={_list}
                    tableRef={tableRef}
                    actions={[
                        makeCreateAction(() => setDisplayAddForm(true), { hidden: !permissions.create }),
                    ]}
                    renderDetail={(reseller, onComplete) => renderDetailDialog(
                        _save(reseller, draft, onComplete),
                        _delete(reseller, onComplete),
                        onComplete,
                        reseller
                    )}
                />
            </Paper>
            {displayAddForm && permissions.create && renderAddNewDialog()}
        </div>
    );
}
