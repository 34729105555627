import { useRbac } from "@gsb/react-rbac";
import React, { useState, useEffect } from "react";
import { useRepo } from "../../../../repos/useRepo";
import { actions } from "../../../../rbac";
import { Application } from "../../../../models/sid/Application";
import { StateApplication } from "../../../../models/sid/StateApplication";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { green } from '@material-ui/core/colors';
import StepContent from '@material-ui/core/StepContent';
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { UpdateApplicationSectionStep } from "./ApplicationSectionStep";

interface UpdateApplicationFormProps {
    application: Application;
    onSubmitApplication: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        header: {
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(1)
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        buttonNext: {
            marginTop: theme.spacing(1),
            backgroundColor: green[400]
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        stepLabel: {
            cursor: 'pointer !important',
            fontSize: '1rem'
        }
    }),
);


export function UpdateApplicationForm({ application, onSubmitApplication }: UpdateApplicationFormProps) {
    const classes = useStyles();
    const { stateApplicationRepo, stateIdApplicationRepo } = useRepo();
    const { can } = useRbac();

    const canUpdateApplication = can(actions.stateIdApplication.update);

    const [stateApplication, setStateApplication] = useState<StateApplication>();
    const [appFields, setAppFields] = useState();

    useEffect(() => {
        Promise.all([
            stateApplicationRepo.getById(application.stateCode),
            stateIdApplicationRepo.getFieldsObj(application.id)
        ]).then(([stateApp, fields]) => {
            setStateApplication(stateApp);
            setAppFields(fields)
        }).catch(console.error);
    }, [application.id]);

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if(stateApplication?.sections && activeStep === stateApplication.sections.length - 1)
        {
            onSubmitApplication();
        } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <h2 className={classes.header}>Application Sections</h2>
            {stateApplication && stateApplication.sections ? <>
                <Stepper activeStep={activeStep} orientation="vertical" >
                    {stateApplication.sections.map((section, index) => {
                        return (
                            <Step key={section.id}>
                                <StepLabel className={classes.stepLabel} onClick={() => setActiveStep(index)}>{section.name}</StepLabel>
                                <StepContent>
                                    <UpdateApplicationSectionStep onApplicationUpdated={setAppFields} applicationId={application.id} stateApplicationSection={section} applicationFields={appFields} onBackClick={handleBack} onNextClick={handleNext} btnClass={classes.button} btnNextClass={classes.buttonNext} disableBack={index === 0} disableNext={(stateApplication.sections && activeStep === stateApplication.sections.length - 1) ?? false} />
                                </StepContent>
                            </Step>
                        )
                    })}
                </Stepper>
            </> : null}
        </>);

}