import React, {useMemo} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {RouteComponentProps, withRouter} from "react-router-dom";

export interface TabNavigationItem {
    name: string;
    identifier: string;
    render: () => JSX.Element;
    disabled?: boolean;
    disableContentPadding?: boolean;
    hidden?: boolean;
}

export interface TabNavigationProps extends RouteComponentProps {
    items: TabNavigationItem[];
    disabledContentPadding?: boolean;
}

function TabNavigation(props: TabNavigationProps) {
    const { items, disabledContentPadding } = props;

    const params = useMemo(() => new URLSearchParams(props.location.search), [props.location]);
    const tabIdentifier = useMemo(() => params.get('tab'), [params]);
    const initialTabIndex = useMemo(() => items.findIndex(i => i.identifier === tabIdentifier), [items, tabIdentifier]);
    const initialTab = initialTabIndex > -1 ? initialTabIndex : 0;

    const [tabIndex, setTabIndex] = React.useState(initialTab);

    const tabForIndex = (index: number) => tabItems.length > 0 ? items[index] : null;

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const item = tabForIndex(newValue);

        if (item) {
            setTabIndex(newValue);
            props.history.replace({ search: `?tab=${item.identifier}` })
        }
    };

    const tabItems = useMemo(() => items.filter(item => !item.hidden), [items]);
    const tabItem = useMemo(() => tabForIndex(tabIndex), [tabItems, tabIndex]);

    const getPaddingForTabItem = (tabItem: TabNavigationItem) => {
        if (tabItem.disableContentPadding !== undefined) {
            // If there is an explicit value for the tab item use that value
            return tabItem.disableContentPadding ? 0 : 16;
        } else {
            // Use the fallback for the control
            return disabledContentPadding ? 0 : 16;
        }
    };

    return (
        <>
            <Tabs value={tabIndex} onChange={handleChange}>
                {tabItems.map((item, index) => (
                    <Tab label={item.name} key={`tab-${index}`} value={index} disabled={item.disabled}/>
                ))}
            </Tabs>

            {tabItem && (
                <div style={{ padding: getPaddingForTabItem(tabItem) }}>
                    {tabItem.render()}
                </div>
            )}
        </>
    )
}

export default withRouter(TabNavigation);