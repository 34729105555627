import * as React from "react";
import {useState} from "react";
import {MailchimpAccount} from "../../models/MailchimpAccount";
import {CreateTemplateModal} from "../entities/integrations/mailchimp/MailchimpForm";

export default function useCreateMailchimpAccountModal(onMailchimpAccountCreated: (mailchimpAccount: MailchimpAccount) => void) {

    const [open, setOpen] = useState(false);

    const handleMailchimpAccountCreated = (mailchimpAccount: MailchimpAccount) => {
        setOpen(false);
        onMailchimpAccountCreated(mailchimpAccount)
    };

    const modal = (
        <CreateTemplateModal
            open={open}
            onClose={() => setOpen(false)}
            onMailchimpAccountCreated={handleMailchimpAccountCreated}
        />
    );

    return { setCreateMailchimpAccountModalOpen: setOpen, createMailchimpAccountModal: modal }
}
