import { Form, Formik, FormikHelpers, FieldArray } from "formik";
import { Button } from "@material-ui/core";
import React from "react";
import * as Yup from 'yup';
import { createSubmitHandler } from "../../form/helpers/formSubmit";
import { CompanyNoteReportRequest } from "../../../models/Report";
import { DateInput } from "../../form/inputs/DateInput";
import TextInput from "../../form/inputs/TextInput";
import CallTypePicker from "../../form/pickers/CallTypePicker";
import CallSourcePicker from "../../form/pickers/CallSourcePicker";

interface Props<T> {
    initialValues: T;
    validationSchema: any;
    onSubmit: (values: T, actions: FormikHelpers<T>) => void;
}

function SearchForm<T>({ initialValues, validationSchema, onSubmit }: Props<T>) {
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ resetForm, handleSubmit }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <CallSourcePicker name='source' label='Call Source' />
                        <CallTypePicker name='callType' label='Call Type' />
                        <TextInput name='note' label='Note (contains)' />
                        <DateInput name='dateFrom' label='Date From' maxDate={new Date()} />
                        <DateInput name='dateTo' label='Date To' maxDate={new Date()} />
                        <Button type='submit' variant='contained' color='primary' style={{ margin: '14px' }}>Search</Button>
                        <Button type='submit' variant='contained' color='secondary' style={{ margin: '14px' }} onClick={() => { resetForm(); }}>Clear</Button>
                    </Form>
                );
            }}
        </Formik>
    )
}

export function CompanyNoteSearchForm(props: { initValues: CompanyNoteReportRequest, onSearch: (results: CompanyNoteReportRequest) => void }) {
    const { initValues, onSearch } = props;

    const validationSchema = Yup.object().shape({
        callType: Yup.string()
            .nullable(),

        note: Yup.string()
            .nullable(),

        dateFrom: Yup.date()
            .required("Must enter from date"),

        dateTo: Yup.date()
            .nullable()
            .default(null)
            .when("dateFrom", (dateFrom: Date, Yup: any) => dateFrom && Yup.min(dateFrom, "Date To cannot be before date from"))
    });

    const onSubmit =
        createSubmitHandler(
            (body: CompanyNoteReportRequest) => Promise.resolve(body),
            onSearch,
            console.error
        );

    return (
        <SearchForm initialValues={initValues} validationSchema={validationSchema} onSubmit={onSubmit} />
    );
}