import * as Yup from 'yup';
import {EmployeeCompany} from "../../models/EmployeeCompany";

export const employeeCompanySchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
});

export const initialValuesForEmployeeCompany = (company?: EmployeeCompany) => {
    return {
        name: company?.name ?? "",
    }
};
