import * as React from "react";
import { useEffect, useState } from "react";
import { StateApplication } from "../../../../../models/sid/StateApplication";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Paper } from "@material-ui/core";
import { actions } from "../../../../../rbac";
import { useRepo } from "../../../../../repos/useRepo";
import LoadingIndicator from "../../../../LoadingIndicator";
import { BreadcrumbFactory, StateApplicationDetailBreadcrumbFactory } from "../../../../../factories/BreadcrumbFactory";
import { useNavigation } from "../../../../../services/navigation/useNavigation";
import { Breadcrumbs } from "../../../../layout/Breadcrumbs";
import StateApplicationSectionTable from "./state-application-section/StateApplicationSectionTable";

interface Params {
    stateApplicationId: string
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
    actions.stateApplication.list,
]

function StateApplicationDetail(props: Props) {
    const [stateApplication, setStateApplication] = useState<StateApplication>();
    const { stateApplicationRepo } = useRepo();

    const { navigateToStateApplications } = useNavigation();

    const { stateApplicationId } = props.match.params;

    const loadStateApplication = () => {
        stateApplicationRepo.getById(stateApplicationId).then(setStateApplication).catch(console.error);
    };

    useEffect(loadStateApplication, [stateApplicationRepo, stateApplicationId]);

    if(stateApplication){

        const breadcrumbFactory: BreadcrumbFactory = new StateApplicationDetailBreadcrumbFactory(stateApplication, navigateToStateApplications);

        return (<>

            <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()} />

            <Paper style={{ marginBottom: 12 }}>
                <StateApplicationSectionTable stateApplication={stateApplication} />
            </Paper>
        </>);
    } else {
        return <LoadingIndicator />
    }
}

export default withRouter(StateApplicationDetail);