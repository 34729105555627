import React from 'react';
import {AddBox, Delete, Update, AssignmentInd} from "@material-ui/icons";
import {Action} from "material-table";
import MoreIcon from '@material-ui/icons/MoreVert';

export function makeCreateAction<T extends object>(onClick: () => void, overrides?: Partial<Action<T>>): Action<T> {
  const action: Action<T> = {
    isFreeAction: true,
    icon: () => <AddBox fontSize='large'/>,
    iconProps: {
      color: 'secondary'
    },
    tooltip: 'Create',
    onClick
  };

  Object.assign(action, overrides);

  return action
}

export function makeDeleteAction<T extends object>(onDelete: (item: T) => void, overrides?: Partial<Action<T>>): Action<T> {
  const action: Action<T> = {
    isFreeAction: false,
    icon: () => <Delete/>,
    iconProps: {
      color: 'error'
    },
    tooltip: 'Delete',
    onClick: (e: any, data: T | T[]) => {
      if (!Array.isArray(data)) {
        onDelete(data)
      }
    }
  };

  Object.assign(action, overrides);

  return action
}

export function makeUpdateAction<T extends object>(onUpdate: (item: T) => void, overrides?: Partial<Action<T>>): Action<T> {
  const action: Action<T> = {
    isFreeAction: false,
    icon: () => <Update/>,
    tooltip: 'Update',
    onClick: (e: any, data: T | T[]) => {
      if (!Array.isArray(data)) {
        onUpdate(data)
      }
    }
  };

  Object.assign(action, overrides);

  return action
}

export function makeAssignAction<T extends object>(onAssigned: (item: T) => void, overrides?: Partial<Action<T>>): Action<T> {
  const action: Action<T> = {
    isFreeAction: false,
    icon: () => <AssignmentInd />,
    tooltip: 'Assign',
    onClick: (e: any, data: T | T[]) => {
      if (!Array.isArray(data)) {
        onAssigned(data)
      }
    }
  };

  Object.assign(action, overrides);

  return action
}

export function makeAssignMultiAction<T extends object>(onAssigned: (items: T[]) => void, overrides?: Partial<Action<T>>): Action<T> {
  const action: Action<T> = {
    isFreeAction: false,
    icon: () => <AssignmentInd />,
    tooltip: 'Assign',
    onClick: (e: any, data: T | T[]) => {
      if (Array.isArray(data)) {
        onAssigned(data)
      }
    }
  };

  Object.assign(action, overrides);

  return action
}


export function makeMenuAction<T extends object>(openMenu: (element: HTMLElement, item: T) => void): Action<T> {
  return {
    isFreeAction: false,
    icon: () => <MoreIcon/>,
    tooltip: 'Menu',
    onClick: (e: any, data: T | T[]) => {
      if (!Array.isArray(data)) {
        openMenu(e.currentTarget as HTMLElement, data)
      }
    }
  };
}


