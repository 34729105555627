import {QuestionStatus} from "../../../models/ti/QuestionStatus";
import HttpService from "@gsb/react-http";

export default interface QuestionStatusRepo {
  listQuestionStatuses(): Promise<QuestionStatus[]>;
}

export class HttpQuestionStatusRepo implements QuestionStatusRepo {
  constructor(private http: HttpService) {}

  listQuestionStatuses(): Promise<QuestionStatus[]> {
    return this.http.get(['ti', 'v1', 'questions', 'statuses']);
  }
}
