import {CreateSubscriptionFileTypeRequest, SubscriptionFileType,} from "../../../../../models/SubscriptionFileType";
import {FormikHelpers} from "formik";
import SubscriptionRepo from "../../../../../repos/subscription/SubscriptionRepo";
import {SubscriptionFileTypeFormViewModel} from "./SubscriptionFileTypeFormViewModel";
import {FormVariant} from "../../../../form/buttons/FormSubmitButton";

export class CreateSubscriptionFileTypeFormViewModel implements SubscriptionFileTypeFormViewModel<CreateSubscriptionFileTypeRequest> {

    submitButtonVariant: FormVariant = 'Create';

    initialValues: CreateSubscriptionFileTypeRequest = {
        name: '',
        parserIdentifier: '',
        fileNames: '',
        isCancellation: false,
        includesHeaders: false,
        columnCount: 1,
        planId: '',
    };

    constructor(private subscriptionFileTypeRepo: SubscriptionRepo,
                private onSubscriptionFileTypeCreated: (subscriptionFileType: SubscriptionFileType) => void) {}

    onSubmit = (values: CreateSubscriptionFileTypeRequest, { setSubmitting, setValues }: FormikHelpers<CreateSubscriptionFileTypeRequest>) => {
        setSubmitting(true);

        this.subscriptionFileTypeRepo.createSubscriptionFileType(values)
            .then(subscriptionFileType => {
                this.onSubscriptionFileTypeCreated(subscriptionFileType);
                setValues(subscriptionFileType)
            })
            .catch(console.error)
            .finally(() => setSubmitting(false))
    };
}
