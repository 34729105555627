import * as React from "react";
import { FormGroup} from "@material-ui/core";
import {PlanPicker} from "../../../../form/pickers/PlanPicker";
import TextInput from "../../../../form/inputs/TextInput";
import CheckboxInput from "../../../../form/inputs/CheckboxInput";

interface Props {
    disabled?: boolean;
}

export default function SubscriptionFileTypeFields({ disabled }: Props) {
    return (
        <>
            <TextInput name='name' label='Name' disabled={disabled}/>

            <TextInput name='parserIdentifier' label='Parser' disabled={disabled}/>

            <TextInput name='fileNames' label='File Names' disabled={disabled}/>

            <TextInput name="columnCount" label="Column Count" type="number" disabled={disabled}/>

            <PlanPicker name='planId' disabled={disabled}/>

            <FormGroup row>
                <CheckboxInput name='isCancellation' label='Is Cancellation' disabled={disabled}/>
                <CheckboxInput name='includesHeaders' label='Includes Headers' disabled={disabled}/>
            </FormGroup>
        </>
    )
}