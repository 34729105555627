import * as Yup from 'yup';
import { uri } from "@hapi/address";

const uriRegex = uri.regex({
    allowRelative: true
}).regex;

export const adpEventSchema = Yup.object().shape({
    eventUrl: Yup.string().matches(uriRegex, 'Please enter a valid URL').required("Event URL is required"),
    appId: Yup.string().required("Adp Product is required")
});
