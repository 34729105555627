import * as React from "react";
import TextInput from "../../../form/inputs/TextInput";

interface Props {
    disabled: boolean;
}

const ProductFields = ({ disabled } : Props) => (
    <>
        <TextInput name='name' label='Name' disabled={disabled}/>

        <TextInput name='identifier' label='Identifier' disabled={disabled}/>
    </>
);

export const CreateProductFields = ProductFields;

export const UpdateProductFields = ProductFields;