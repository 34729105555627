import { MenuButton } from "../../../menu/Menu";
import { makeAssignStatusAction, makeDeleteAction } from "../../../../factories/MenuItemFactory";
import React from "react";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../rbac";
import { Application } from "../../../../models/sid/Application";

interface Props {
    application: Application;
    onDeleteClicked: () => void;
    onAssignStatusClicked: () => void;
}


export function ApplicationMenuButton({ application, onDeleteClicked, onAssignStatusClicked }: Props) {
    const { can } = useRbac();

    const menuItems = [];

    if (can(actions.stateIdApplication.update)) {
        menuItems.push(makeAssignStatusAction(onAssignStatusClicked));
    }

    if (can(actions.stateIdApplication.delete)) {
        menuItems.push(makeDeleteAction(onDeleteClicked));
    }



    return (
        <MenuButton items={menuItems} />
    )
}

// export function useCompanyMenu(onViewCompanyInStripeClicked: (company: Company) => void) {
//     const { menu, openMenu } = useMenu();

//     return {
//         companyMenu: menu,
//         openCompanyMenu: (element: HTMLElement, company: Company) => {
//             const items = makeCompanyMenuItems(company, onViewCompanyInStripeClicked);
//             openMenu(element, items)
//         }
//     }
// }
