import {IdentifiableResource} from "../IdentifiableResource";

export interface QuestionStatus extends IdentifiableResource {
    name: string;
    identifier: string;
}

export enum QuestionStatusIdentifier {
    submitted = "submitted",
    researching = "researching",
    onHold = "on-hold",
    reviewing = "reviewing",
    approved = "approved",
    released = "released"
}

