import {Form, Formik, FormikHelpers} from "formik";
import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {AssignRolesRequest} from "../../repos/employee/EmployeeRepo";
import MultipleSelectInput from "../form/select/MultipleSelectInput";
import {assignRoleSchema, initialValuesForAssignRoleRequest} from "../../utils/schemas/role-schema";
import {Employee} from "../../models/Employee";
import {useRepo} from "../../repos/useRepo";
import LoadingIndicator from "../LoadingIndicator";
import {withModal} from "../modal";
import {EmployeeRole} from "../../models/EmployeeRole";
import {FormSubmitButton} from "../form/buttons/FormSubmitButton";
import { createSubmitHandler } from "../form/helpers/formSubmit";

interface Props {
    roles: EmployeeRole[];
    initialValues: AssignRolesRequest;
    onSubmit: (values: AssignRolesRequest, {}: FormikHelpers<AssignRolesRequest>) => void;
}

function AssignEmployeeRolesForm({ initialValues, roles, onSubmit }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={assignRoleSchema} onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <MultipleSelectInput name='roleIds' label='Roles' items={roles.map(r => ({ id: r.id, name: r.name }))}/>

                <FormSubmitButton variant='Update' label='Assign'/>
            </Form>
        </Formik>
    )
}

interface UpdateEmployeeRolesFormProps {
    employee: Employee;
    allowSysAdminRole: boolean;
    onRolesAssigned: (roles: EmployeeRole[]) => void;
}

export function AssignRolesToEmployeeForm({ employee, allowSysAdminRole, onRolesAssigned }: UpdateEmployeeRolesFormProps) {
    const { employeeRepo, companyRepo } = useRepo();

    const [availableRoles, setAvailableRoles] = useState<EmployeeRole[]>();

    useEffect(() => {
        employeeRepo.listRolesForEmployeeById(employee.id, { status: 'available' }).then(setAvailableRoles).catch(console.error);
    }, [companyRepo]);

    const onSubmit = createSubmitHandler(
      (body: AssignRolesRequest) => employeeRepo.assignRolesForEmployeeById(employee.id, body),
      onRolesAssigned,
      console.error
    );

    const filteredRoles = () => (allowSysAdminRole ? availableRoles : availableRoles?.filter(r => r.identifier !== 'gsbSysAdmin')) || [];

    if (availableRoles) {
        const content = availableRoles.length > 0
            ? <AssignEmployeeRolesForm initialValues={initialValuesForAssignRoleRequest()} onSubmit={onSubmit} roles={filteredRoles()}/>
            : `No roles available for ${employee.firstName} ${employee.lastName}`;

        return (
            <div>
                <div style={{ marginTop: 12, marginBottom: 12 }}>
                    {content}
                </div>
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export const AssignRolesToEmployeeModal = withModal(AssignRolesToEmployeeForm);
