import TableColumnFactory, {ColumnFactory} from "../../../entities/TableColumnFactory";
import {Topic} from "../../../../models/ti/Topic";

export class TopicTableColumnFactory implements ColumnFactory<Topic>{

    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<Topic>([
            TableColumnFactory.makeTopicIdColumn(),
            TableColumnFactory.fromProperty("Name", "name"),
            TableColumnFactory.fromProperty("Detail", "detail"),
            TableColumnFactory.fromProperty("Icon URL", "iconUrl"),
            TableColumnFactory.fromProperty("Icon Identifier", "iconIdentifier"),
        ]);
    }
}
