import {Subscription} from "../../../../../models/Subscription";
import {Divider, ExpansionPanelActions, ExpansionPanelDetails} from "@material-ui/core";
import SubscriptionDetail from "./SubscriptionDetail";
import SubscriptionDetailActions from "./SubscriptionDetailActions";
import React from "react";

export default function SubscriptionDetailPanel(props: { subscription: Subscription, onSubscriptionCanceled: () => void }) {
    const { subscription, onSubscriptionCanceled } = props;

    const detailActions = <SubscriptionDetailActions subscription={subscription} onSubscriptionCanceled={onSubscriptionCanceled}/>;

    return (
        <div>
            <ExpansionPanelDetails>
                <SubscriptionDetail subscription={subscription}/>
            </ExpansionPanelDetails>

            {detailActions && (
                <>
                    <Divider/>

                    <ExpansionPanelActions>{detailActions}</ExpansionPanelActions>
                </>
            )}
        </div>
    )
}
