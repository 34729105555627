import {Company} from "../../../../models/Company";
import React from "react";
import CompanyMetadataForm from "../CompanyMetadataForm";

export default function WebsiteAnalysisTab(props: { company: Company }) {

    const { company } = props;

    return (
        <>
            <CompanyMetadataForm company={company} />
        </>
    )
}
