import IndustryRepo from "./IndustryRepo";
import {Industry} from "../../models/Industry";
import HttpService from "@gsb/react-http";
import {CollectionResponse} from "../../models";

export default class HttpIndustryRepo implements IndustryRepo {
    pathComponents = ['mk', 'v1', 'industries'];

    constructor(private http: HttpService) {}

    listAll(): Promise<Industry[]> {
        return this.http.get<CollectionResponse<Industry>>(['mk', 'v1', 'industries']).then(res => res.results)
    }
}
