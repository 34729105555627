import {Form, Formik} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {CreatePlanForProductFields, UpdatePlanFields} from "./PlanFields";
import {CreatePlanRequest, Plan, UpdatePlanRequest} from "../../../../models/Plan";
import {
    initialValuesForPlanUpdate,
    initialValuesForPlanCreateForProduct,
    updatePlanSchema,
    createPlanSchema
} from "../../../../utils/schemas/plan-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {Product} from "../../../../models/Product";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdatePlanForm(props: { plan: Plan, onPlanUpdated: (plan: Plan) => void }) {
    const { plan, onPlanUpdated }  = props;
    const { planRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.plan.update);

    const onSubmit = updateSubmitHandler(
        (body: UpdatePlanRequest) => planRepo.update(plan.id, body),
        initialValuesForPlanUpdate,
        onPlanUpdated,
        console.error,
    );

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValuesForPlanUpdate(plan)}
                validationSchema={updatePlanSchema}
                onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdatePlanFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreatePlanForProductForm(props: { product: Product, onPlanCreated: (plan: Plan) => void }) {
    const { product, onPlanCreated }  = props;
    const { planRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreatePlanRequest) => planRepo.create(body),
        onPlanCreated,
        console.error
    );

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValuesForPlanCreateForProduct(product)}
                validationSchema={createPlanSchema}
                onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreatePlanForProductFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}


export const UpdatePlanModal = withModal(UpdatePlanForm, "Update Plan");
export const CreatePlanForProductModal = withModal(CreatePlanForProductForm, "Create Plan");