import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import { FieldType } from "../../../models/sid/FieldType";

interface Props extends PickerPropsWithoutOptions{}

export function FieldTypePicker({ ...PickerProps }: Props) {
    const { fieldTypeRepo } = useRepo();
    const [fieldTypes, setFieldTypes] = useState<FieldType[]>([]);

    useEffect(() => {
        fieldTypeRepo.listAll().then(setFieldTypes).catch(console.error);
    }, [fieldTypeRepo]);

    const options = useMemo(() => fieldTypes.map(p => ({ id: p.id, label: p.name || p.id })), [fieldTypes]);

    return <PickerInput label={'Field Type'} options={options} {...PickerProps} />
}