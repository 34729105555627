import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteConfirmationDialog from "../modals/DeleteConfirmationDialog";
import {QuestionStatusIdentifier} from "../../../models/ti/QuestionStatus";
import {Question} from "../../../models/ti/Question";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        margin: theme.spacing(1),
    }
}));

interface Props {
    isDirty: boolean;
    isLoading: boolean;
    question: Question;

    onSaveClicked: () => void;
    onSubmitForFaqClicked: () => void;
    onResearchingClicked: () => void;
    onOnHoldClicked: () => void;
    onSubmitForReviewClicked: () => void;
    onLeaveReviewClicked: () => void;
    onReleaseClicked: () => void;
    onDeleteClicked: () => void;
}

interface ActionBarViewModel {
    readonly isSaveVisible: boolean;
    readonly isSubmitForFaqVisible: boolean;
    readonly isResearchingVisible: boolean;
    readonly isOnHoldVisible: boolean;
    readonly isSubmitForReviewVisible: boolean;
    readonly isLeaveReviewVisible: boolean;
    readonly isReleaseVisible: boolean;
}

export class QuestionStatusModel {
    isPending = this.status === QuestionStatusIdentifier.submitted;
    isResearching = this.status === QuestionStatusIdentifier.researching;
    isOnHold = this.status === QuestionStatusIdentifier.onHold;
    isAnswered = this.status === QuestionStatusIdentifier.reviewing;
    isApproved = this.status === QuestionStatusIdentifier.approved;

    constructor(private status: QuestionStatusIdentifier) {}
}

function viewModelForStatus(status: QuestionStatusModel): ActionBarViewModel {

    const { isPending, isResearching, isOnHold, isAnswered, isApproved } = status;

    return {
        isSaveVisible: true,
        isSubmitForFaqVisible: true,
        isResearchingVisible: !isResearching,
        isOnHoldVisible: !isOnHold,
        isSubmitForReviewVisible: isResearching || isOnHold || isPending,
        isLeaveReviewVisible: isAnswered,
        isReleaseVisible: isApproved,
    };
}

type ActionBarButton = { title: string, action: () => void, isVisible: boolean, isDisabled: boolean }

export default function QuestionActionBar(props: Props) {
    const classes = useStyles();
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);

    const {
        isDirty,
        isLoading,
        question,
        onSaveClicked,
        onSubmitForFaqClicked,
        onResearchingClicked,
        onOnHoldClicked,
        onSubmitForReviewClicked,
        onLeaveReviewClicked,
        onReleaseClicked,
        onDeleteClicked,
    } = props;

    const { can } = useRbac();
    const canDeleteQuestion = can(actions.question.delete);

    const canSubmitForFaq = !question.faqId;

    const model = new QuestionStatusModel(question.status.identifier as QuestionStatusIdentifier);
    const vm = viewModelForStatus(model);
    const {
        isSaveVisible,
        isSubmitForFaqVisible,
        isResearchingVisible,
        isOnHoldVisible,
        isSubmitForReviewVisible,
        isLeaveReviewVisible,
        isReleaseVisible,
    } = vm;

    const isSubmitForFaqButtonDisabled = isLoading || isDirty || !canSubmitForFaq;
    const isSaveButtonDisabled = isLoading || !isDirty;

    const isActionButtonDisabled = isLoading || isDirty;

    const buttons: ActionBarButton[] = [
        {
            title: 'Researching',
            isVisible: isResearchingVisible,
            isDisabled: isActionButtonDisabled,
            action: onResearchingClicked
        },
        {
            title: 'On Hold',
            isVisible: isOnHoldVisible,
            isDisabled: isActionButtonDisabled,
            action: onOnHoldClicked
        },
        {
            title: 'Submit for Review',
            isVisible: isSubmitForReviewVisible,
            isDisabled: isActionButtonDisabled,
            action: onSubmitForReviewClicked
        },
        {
            title: 'Leave a Review',
            isVisible: isLeaveReviewVisible,
            isDisabled: isActionButtonDisabled,
            action: onLeaveReviewClicked
        },
        {
            title: 'Release',
            isVisible: isReleaseVisible,
            isDisabled: isActionButtonDisabled,
            action: onReleaseClicked
        },
    ].filter(button => button.isVisible);

    return (
        <div style={{display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
            <div>

                {buttons.map(button => {
                    return (
                        <Button
                            key={button.title}
                            style={{marginRight: 10}}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={button.isDisabled}
                            onClick={() => button.action()}>
                            {button.title}
                        </Button>
                    )
                })}

                {isSubmitForFaqVisible &&
                <Button key="submit-for-faq-button"
                        variant="contained"
                        className={classes.button}
                        disabled={isSubmitForFaqButtonDisabled}
                        onClick={() => onSubmitForFaqClicked()}>
                  Submit for FAQ
                </Button>
                }

            </div>

            <div style={{display: "flex"}}>
                {canDeleteQuestion && (
                    <Button
                        style={{backgroundColor: "#e57373", color: "white", marginRight: 10}}
                        variant="contained"
                        className={classes.button}
                        disabled={isActionButtonDisabled}
                        onClick={() => setIsConfirmDeleteDialogOpen(true)}>
                        Delete
                    </Button>
                )}
                {isSaveVisible &&
                <Button key="save-button"
                        style={{ marginRight: 10 }}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={isSaveButtonDisabled}
                        onClick={() => onSaveClicked()}>
                  Save
                </Button>
                }

            </div>
            <DeleteConfirmationDialog
              open={isConfirmDeleteDialogOpen}
              onCancel={() => setIsConfirmDeleteDialogOpen(false)}
              onConfirm={onDeleteClicked}
            />
        </div>
    )
};
