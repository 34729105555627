import * as React from "react";
import {WebsiteAnalysis} from "../../../../models/WebsiteAnalysis";
import {useState} from "react";
import {UpdateWebsiteAnalysisModal} from "./UpdateWebsiteAnalysisForm";

export default function useUpdateWebsiteAnalysisModal(onWebsiteAnalysisUpdated: (websiteAnalysis: WebsiteAnalysis) => void) {

    const [record, setRecord] = useState<WebsiteAnalysis>();
    const close = () => setRecord(undefined);

    const modal = record ? (
        <UpdateWebsiteAnalysisModal
            title='Update Website Analysis Report'
            open={true}
            onClose={close}
            websiteAnalysis={record}
            onWebsiteAnalysisUpdated={(analysis) => {
                close();
                onWebsiteAnalysisUpdated(analysis)
            }} 
        />
    ) : null;

    return {
        updateWebsiteAnalysisModal: modal,
        openUpdateWebsiteAnalysisModal: (websiteAnalysis: WebsiteAnalysis) => setRecord(websiteAnalysis)
    }
}