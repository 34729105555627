import * as Yup from 'yup';
import {SalesChannel} from "../../models/SalesChannel";

const baseSalesChannelFields = {
    name: Yup.string().required("Name is required"),
    identifier: Yup.string().required("Identifier is required"),
    domain: Yup.string().required("Domain is required"),
    salesDomain: Yup.string().required("Sales domain is required"),
    allowsRegistration: Yup.bool().required("Allows registration is required"),
    stripeSuccessUrl: Yup.string().when('allowsRegistration', {
        is: true,
        then: Yup.string().required('Stripe success URL is required when the sales channel allows registration'),
        otherwise: Yup.string().nullable()
    }),
    stripeCancelUrl: Yup.string().when('allowsRegistration', {
        is: true,
        then: Yup.string().required('Stripe cancel URL is required when the sales channel allows registration'),
        otherwise: Yup.string().nullable()
    }),
};

export const createSalesChannelSchema = Yup.object().shape(baseSalesChannelFields);

export const updateSalesChannelSchema = Yup.object().shape({
    ...baseSalesChannelFields,
});

export const initialValuesForSalesChannelCreate = () => ({
    name: "",
    identifier: "",
    domain: "",
    salesDomain: "",
    stripeSuccessUrl: "",
    stripeCancelUrl: "",
    allowsRegistration: false,
});

export const initialValuesForSalesChannelUpdate = (salesChannel: SalesChannel) => ({
    name: salesChannel?.name ?? "",
    identifier: salesChannel?.identifier ?? "",
    domain: salesChannel?.domain ?? "",
    salesDomain: salesChannel?.salesDomain ?? "",
    stripeSuccessUrl: salesChannel?.stripeSuccessUrl ?? "",
    stripeCancelUrl: salesChannel?.stripeCancelUrl ?? "",
    allowsRegistration: salesChannel?.allowsRegistration ?? false,
});
