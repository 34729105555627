import {SalesChannel} from "../../../../../models/SalesChannel";
import * as React from "react";
import {UpdateSalesChannelForm} from "../forms/SalesChannelForm";

interface Props {
    salesChannel: SalesChannel;
    onSalesChannelUpdated: (salesChannel: SalesChannel) => void;
}

export default function SalesChannelTab({ salesChannel, onSalesChannelUpdated }: Props) {
    return (
        <div>
            <UpdateSalesChannelForm salesChannel={salesChannel} onSalesChannelUpdated={onSalesChannelUpdated}/>
        </div>
    )
}
