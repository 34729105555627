import * as Yup from 'yup';
import { Application } from '../../models/sid/Application';
import { ApplicationNote } from '../../models/sid/ApplicationNote';
import {FieldType} from "../../models/sid/FieldType";
import { StateApplication } from '../../models/sid/StateApplication';
import { StateApplicationField } from '../../models/sid/StateApplicationField';
import { StateApplicationSection } from '../../models/sid/StateApplicationSection';

const baseStateIdFieldTypeFields = {
    name: Yup.string().required("Name is required"),
    description: Yup.string().nullable(),
    component: Yup.string().required("Component is required"),
    options: Yup.string().nullable()
};

export const createStateIdFieldTypeSchema = Yup.object().shape({
    ...baseStateIdFieldTypeFields,
    id: Yup.string().required("Id is required")
});

export const updateStateIdFieldTypeSchema = Yup.object().shape(baseStateIdFieldTypeFields);

export const initialValuesForStateIdFieldTypeCreate = () => ({
    id: "",
    name: "",
    description: null,
    component: "",
    options: null
});

export const initialValuesForStateIdFieldTypeUpdate = (fieldType?: FieldType) => ({
    name: fieldType?.name ?? "",
    description: fieldType?.description ?? "",
    component: fieldType?.component ?? "",
    options: fieldType?.options ? JSON.stringify(fieldType.options, null, 2) : null
});


export const stateApplicationFieldSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().nullable(),
    stateCode: Yup.string().required("State Code is required"),
    stateWithholdingTaxLabel: Yup.string().nullable(),
    stateUnemploymentTaxLabel: Yup.string().nullable(),
    isLive: Yup.bool()
});

export const initialValuesForStateApplication = (stateApplication?: StateApplication) => ({
    name: stateApplication?.name ?? "",
    description: stateApplication?.description,
    stateCode: stateApplication?.stateCode ?? "",
    stateWithholdingTaxLabel: stateApplication?.stateWithholdingTaxLabel,
    stateUnemploymentTaxLabel: stateApplication?.stateUnemploymentTaxLabel,
    isLive: stateApplication?.isLive ?? false
});

export const stateApplicationSectionFieldSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().nullable(),
    subHeader: Yup.string().nullable(),
    sortOrder: Yup.number(),
    validate: Yup.string().nullable()
});

export const initialValuesForStateApplicationSection = (stateApplicationSection?: StateApplicationSection) => ({
    name: stateApplicationSection?.name ?? "",
    description: stateApplicationSection?.description,
    subHeader: stateApplicationSection?.subHeader,
    sortOrder: stateApplicationSection?.sortOrder ?? 0,
    validate: stateApplicationSection?.validate ? JSON.stringify(stateApplicationSection.validate, null, 2) : null
});

export const stateApplicationFieldFieldSchema = Yup.object().shape({
    label: Yup.string().required("Label is required"),
    subLabel: Yup.string().nullable(),
    fieldTypeId: Yup.string().required("Field Type is required"),
    defaultValue: Yup.string().nullable(),
    placeholder: Yup.string().nullable(),
    required: Yup.boolean().default(false),
    sensitive: Yup.boolean().default(false),
    classes: Yup.string().nullable(),
    mask: Yup.string().nullable(),
    sortOrder: Yup.number().required(),
    options: Yup.string().nullable(),

});

export const initialValuesForStateApplicationField = (stateApplicationField?: StateApplicationField) => ({
    label: stateApplicationField?.label ?? "",
    subLabel: stateApplicationField?.subLabel,
    description: stateApplicationField?.description,
    fieldTypeId: stateApplicationField?.fieldTypeId ?? "",
    defaultValue: stateApplicationField?.defaultValue,
    placeholder: stateApplicationField?.placeholder,
    required: stateApplicationField?.required ?? false,
    sensitive: stateApplicationField?.sensitive ?? false,
    classes: stateApplicationField?.classes,
    sortOrder: stateApplicationField?.sortOrder ?? 0,
    mask: stateApplicationField?.mask,
    options: stateApplicationField?.options ? JSON.stringify(stateApplicationField.options, null, 2) : undefined,
});

export const stateIdApplicationNoteFieldSchema = Yup.object().shape({
    body: Yup.string().required('Note is required'),
    demeanor: Yup.string().required('Customer Demeanor is required'),
    status: Yup.string().required('Call Status is required'),
    memberId: Yup.string().nullable(),
    userDisplayName: Yup.string().nullable()
});

export const initialValuesForStateIdApplicationNoteField = (applicationNote?: ApplicationNote) => ({
    body: applicationNote?.body ?? "",
        demeanor: applicationNote?.demeanor ?? "",
        status: applicationNote?.status ??  "",
        memberId: applicationNote?.memberId ?? "",
        userDisplayName: applicationNote?.userDisplayName ?? ""
});


export const stateIdApplicationFieldSchema = Yup.object().shape({
    stateWithholdingTaxId: Yup.string().nullable(),
    stateUnemploymentTaxId: Yup.string().nullable()
})

export const initialValuesForStateIdApplicatioField = (application?: Application) => ({
    stateWithholdingTaxId: application?.stateWithholdingTaxId ?? null,
    stateUnemploymentTaxId: application?.stateUnemploymentTaxId ?? null
})