import * as Yup from 'yup';
import {CreateResellerRequest, Reseller} from "../../models/Reseller";

const baseFields = {
    name: Yup.string().required("Name is required"),
    identifier: Yup.string().required("Identifier is required"),
    allowsUserInvitation: Yup.boolean().required("Required")
};

export const createResellerSchema = Yup.object().shape({
    ...baseFields,
    salesChannelId: Yup.string().required("Sales channel is required"),
});

export const updateResellerSchema = Yup.object().shape(baseFields);

export const initialValuesForResellerCreate = (): CreateResellerRequest => ({
    salesChannelId: "",
    name: "",
    identifier: "",
    allowsUserInvitation: false
});

export const initialValuesForResellerUpdate = (reseller?: Reseller) => ({
    name: reseller?.name ?? "",
    identifier: reseller?.identifier ?? "",
    allowsUserInvitation: reseller?.allowsUserInvitation ?? false
});
