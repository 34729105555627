import { Form, Formik, FormikHelpers, FieldArray } from "formik";
import { Button } from "@material-ui/core";
import React from "react";
import { createSubmitHandler } from "../../form/helpers/formSubmit";
import MetadataField from "../../../models/MetadataField";
import MetadataInput from "../../form/inputs/MetadataInput";
import {CompanyMetaReportRequest} from "../../../models/Report";
import {DateInput} from "../../form/inputs/DateInput";
import * as Yup from "yup";
import moment from "moment";

interface Props {
    initialValues: CompanyMetaReportRequest;
    validationSchema: any;
    onSubmit: (values: CompanyMetaReportRequest, actions: FormikHelpers<CompanyMetaReportRequest>) => void;
}

function SearchForm({ initialValues, validationSchema, onSubmit }: Props) {

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
            {({ resetForm, handleSubmit, values }) => {
                return (<Form>
                    <DateInput name='dateFrom' label='Metadata Date From' maxDate={new Date()} helperText="Metadata field created from date" />

                    <DateInput name='dateTo' label='Metadata Date To' maxDate={new Date()}  helperText="Metadata field created to date" />

                    <FieldArray
                        name="metaFields"
                        render={arrayHelpers => (
                            <div>
                                {values && values.metaFields.length > 0 ? (
                                    values.metaFields.map((field, index) => {
                                        return (<MetadataInput key={field.label} metadataField={field} name={`metaFields[${index}].value`} label={field.label} allowEmpty={true} emptyValueDescription={'-- ALL --'} emptyValue={''} />);
                                    })
                                ) : (<></>)}
                                <Button type='submit' variant='contained' color='primary' style={{ margin: '14px' }}>Search</Button>
                                <Button type='submit' variant='contained' color='secondary' style={{ margin: '14px' }} onClick={() => { resetForm(); }}>Clear</Button>
                            </div>
                        )}
                    />
                </Form>);
            }}
        </Formik>);
}

export function CompanyMetadataSearchForm(props: { initValues: MetadataField[], onSearch: (results: CompanyMetaReportRequest) => void }) {
    const { initValues, onSearch } = props;

    const onSubmit =
        createSubmitHandler(
            (body: CompanyMetaReportRequest) => Promise.resolve({ ...body, metaFields: body.metaFields.filter(f => f.value && f.value.length > 0)}),
            onSearch,
            console.error
        );

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date()
            .required("Must enter from date"),

        dateTo: Yup.date()
            .nullable()
            .default(null)
            .when("dateFrom", (dateFrom: Date, Yup: any) => dateFrom && Yup.min(dateFrom, "Date To cannot be before date from"))
    });

    const values = {
        dateFrom: moment().startOf('month').toISOString(),
        dateTo: null,
        metaFields: initValues.map(v => { return { ...v, value: (v.component === 'select-multi' ? [] : '') }})
    };

    return (
        <SearchForm validationSchema={validationSchema} initialValues={values} onSubmit={onSubmit} />
    );
}
