import {Filter as TableFilter, SortingRule as TableSort} from "react-table";
import {
  QuestionFilter,
  QuestionFilterOption,
  QuestionSort,
  QuestionSortOption
} from "../../repos/ti/question/QuestionRepo";
import {FaqFilter, FaqFilterOption, FaqSort, FaqSortOption} from "../../repos/ti/faq/FaqRepo";
import {getSortDirection} from "../../utils/QueryUtils";

export const transformTableFilter = ( { value, id }: TableFilter ): QuestionFilter | FaqFilter => ({
  value,
  on: id as QuestionFilterOption | FaqFilterOption,
})

class QuestionRepo {
  static getSortFromTableSort(tableSort: TableSort): QuestionSort | null {
    const direction = getSortDirection(tableSort.desc);
    const sortOption = tableSort.id as QuestionSortOption;

    if (!sortOption) {
      return null;
    }

    return {on: sortOption, direction};
  }
}

class FaqRepo {
  static getSortFromTableSort(tableSort: TableSort) : FaqSort | null {
    const direction = getSortDirection(tableSort.desc);
    const sortOption = tableSort.id as FaqSortOption;

    if (!sortOption) {
      return null;
    }

    return {on: sortOption, direction};
  };

}

export default { QuestionRepo, FaqRepo };
