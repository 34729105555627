import {Company} from "../../../../models/Company";
import * as React from "react";
import CompanyLicensesTable from "../CompanyLicensesTable";
import {User} from "../../../../models/User";
import {Application} from "../../../../models/Application";

interface Props {
    company: Company;
    onUserSelected: (user: User) => void;
    onApplicationSelected: (application: Application) => void;
}

export default function LicensesTab({ company, onUserSelected, onApplicationSelected }: Props) {
    return (
        <>
            <CompanyLicensesTable company={company} onUserSelected={onUserSelected} onApplicationSelected={onApplicationSelected}/>
        </>
    );
}

