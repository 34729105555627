import config from '../config';

const { stripeDashboardBaseUrl, azureBaseUrl, silktideBaseUrl } = config.url;

export const makeStripeCustomerLink = (stripeCustomerId: string) => `${stripeDashboardBaseUrl}/customers/${stripeCustomerId}`;

export const makeStripeSubscriptionLink = (stripeSubscriptionId: string) => `${stripeDashboardBaseUrl}/subscriptions/${stripeSubscriptionId}`;

export const makeStripePlanLink = (stripePlanId: string) => `${stripeDashboardBaseUrl}/plans/${stripePlanId}`;

export const makeStripeProductLink = (stripeProductId: string) => `${stripeDashboardBaseUrl}/products/${stripeProductId}`;

export const makeActiveDirectoryLink = (subjectId: string) => `${azureBaseUrl}/blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Profile/userId/${subjectId}/adminUnitObjectId/`;

export const makeSilkTideReportLink = (reportId: string) => `${silktideBaseUrl}/en_US/summary/${reportId}`;