import {SubscriptionFileType} from "../../../models/SubscriptionFileType";
import {useRepo} from "../../../repos/useRepo";
import {useRbac} from "@gsb/react-rbac";
import React, {useRef, useState} from "react";
import {getTableDataFetcherFromRepoFetcher} from "../../table/TableRepoAdapter";
import Table, {TableColumn} from "../../table/Table";
import TableColumnFactory from "../TableColumnFactory";
import {actions} from "../../../rbac";
import {makeCreateAction} from "../../table/TableActionFactory";
import {CreateSubscriptionFileTypeFormDialog, UpdateSubscriptionFileTypeFormDialog} from "./forms/subscription-file-type/SubscriptionFileTypeForm";

export default function SubscriptionFileTypeList() {
    const { subscriptionRepo } = useRepo();

    const { can } = useRbac();
    const canCreateSubscriptionFileType = can(actions.subscriptionFileType.create);

    const [selectedFileType, setSelectedFileType] = useState<SubscriptionFileType>();
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const tableRef = useRef<any>(null);

    const columns: TableColumn<SubscriptionFileType>[] = TableColumnFactory.withTimestampColumns([
        TableColumnFactory.makeSubscriptionFileTypeIdColumn(),
        TableColumnFactory.fromProperty("Name", "name"),
        TableColumnFactory.fromProperty("Parser", "parserIdentifier"),
        TableColumnFactory.fromProperty("File Names", "fileNames"),
        {
            title: 'Cancellation',
            field: 'isCancellation',
            type: 'boolean',
        },
        {
            title: 'Includes Headers',
            field: 'includesHeaders',
            type: 'boolean',
        },
        {
            title: 'Column Count',
            field: 'columnCount',
            type: 'numeric',
        },
        TableColumnFactory.makePlanColumn('Plan', 'planId', r => r.plan),
    ]);

    function dismissAndReload() {
        setCreateDialogOpen(false);
        setSelectedFileType(undefined);
        tableRef.current.onQueryChange()
    }

    return (
        <>
            <Table<SubscriptionFileType>
                tableRef={tableRef}
                title='Subscription File Types'
                columns={columns}
                actions={[
                    makeCreateAction(() => setCreateDialogOpen(true), { disabled: !canCreateSubscriptionFileType }),
                ]}
                onRowClick={setSelectedFileType}
                data={getTableDataFetcherFromRepoFetcher(subscriptionRepo.listSubscriptionFileTypes.bind(subscriptionRepo))}
            />
            <CreateSubscriptionFileTypeFormDialog open={createDialogOpen}
                                                  onClose={() => setCreateDialogOpen(false)}
                                                  onSubscriptionFileTypeCreated={dismissAndReload}/>
            {selectedFileType &&
            <UpdateSubscriptionFileTypeFormDialog open={true}
                                                  subscriptionFileType={selectedFileType}
                                                  onClose={() => setSelectedFileType(undefined)}
                                                  onSubscriptionFileTypeUpdated={dismissAndReload}/>
            }
        </>
    );
}
