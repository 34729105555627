import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import { ApplicationStatus } from "../../../models/sid/ApplicationStatus";

export default function ApplicationStatusDropdown(props: FilterComponentProps<any>) {
    const { stateIdApplicationRepo } = useRepo();
    const [statuses, setStatuses] = useState<ApplicationStatus[]>([]);

    useEffect(() => {
        stateIdApplicationRepo.listStatuses().then(setStatuses).catch(console.error);
    }, [stateIdApplicationRepo]);
    
    return (
        <DropdownPicker options={statuses} {...props}/>
    )
}