import React from 'react';
import {Divider, Modal as MaterialModal, Paper, Typography, BackdropProps, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export interface ModalProps {
    title?: string;
    open: boolean;
    paperStyle?: React.CSSProperties;
    rootStyle?: React.CSSProperties;
    backdropProps?: BackdropProps;
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ title, open, onClose, children, paperStyle = {}, backdropProps = {}, rootStyle = {} }) => {
    return (
        <MaterialModal
          open={open}
          onClose={onClose}
          style={rootStyle}
          BackdropProps={{ open, ...backdropProps}}
        >
            <div
                 style={{ width: '100vw', height: '100vh', padding: 12, display: 'flex', justifyContent: 'center', alignContent: 'center', overflowY: 'scroll' }}>
                <Paper onClick={(event) => event.stopPropagation()}
                       style={{
                         margin: 'auto',
                         padding: 12,
                         maxWidth: '80%',
                         ...paperStyle,
                        }}>
                    {title && (
                        <>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography>{title}</Typography>
                            <IconButton aria-label="Close" onClick={onClose} title="Close">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                            <Divider style={{ marginTop: 12, marginBottom: 18 }}/>
                        </>
                    )}
                    {children}
                </Paper>
            </div>
        </MaterialModal>
    )
};

export default Modal;
