import * as React from "react";
import {SalesChannelPicker} from "../../../form/pickers/SalesChannelPicker";
import TextInput from "../../../form/inputs/TextInput";
import CheckboxInput from "../../../form/inputs/CheckboxInput";

interface Props {
    disabled?: boolean;
}

export function UpdateResellerFields({ disabled }: Props) {
    return (
        <>
            <TextInput name={"name"} label="Name" disabled={disabled}/>

            <TextInput name={"identifier"} label="Identifier" disabled={disabled}/>

            <CheckboxInput name={"allowsUserInvitation"}
                           label={"Allows Users to Invite Other Users"}
                           helperText={`Leaving this box unchecked will prevent a company admin from manually adding users for resellers where users are managed automatically from an external partnership or where the product allows "per user" licenses to be granted.`}
                           disabled={disabled}/>
        </>
    )
}

export function CreateResellerFields({ disabled }: Props) {
    return (
        <>
            <SalesChannelPicker name='salesChannelId' label='Sales Channel' disabled={disabled}/>

            <UpdateResellerFields disabled={disabled}/>
        </>
    )
}
