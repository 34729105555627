import SiteRepo from "./SiteRepo";
import {Site} from "../../../models/ti/Site";
import {HttpCrudRepo} from "../../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../../models";
import {ListQuery} from "../../CrudRepo";
import {CreateSiteTopicRequest, SiteTopic, UpdateSiteTopicRequest} from "../../../models/ti/SiteTopic";
import {Topic} from "../../../models/ti/Topic";
import {getHttpQueryFromListQuery} from "../../../utils/QueryUtils";
import config from '../../../config';

export default class HttpSiteRepo extends HttpCrudRepo<Site> implements SiteRepo {
    pathComponents = ['ti', 'v1', 'sites'];

    list(query: ListQuery): Promise<CollectionResponse<Site>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    listAll(): Promise<Site[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<Site>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }

    listAvailableTopicsForSiteById = (siteId: string): Promise<Topic[]> => {
        return this.http.get([...this.pathComponents, siteId, 'topics'], {
            query: {
                status: 'available'
            }
        });
    }

    createTopicForSiteById(siteId: string, body: CreateSiteTopicRequest): Promise<SiteTopic> {
        return this.http.post([...this.pathComponents, siteId, 'topics'], body);
    }

    updateTopicForSiteById(siteId: string, body: UpdateSiteTopicRequest): Promise<SiteTopic> {
        return this.http.put([...this.pathComponents, siteId, 'topics'], body);
    }

    deleteTopicForSiteById(siteId: string, topicId: string): Promise<Response> {
        return this.http.delete([...this.pathComponents, siteId, 'topics', topicId]);
    }
}
