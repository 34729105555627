import UserRepo from "./user/UserRepo";
import CompanyRepo from "./company/CompanyRepo";
import EmployeeCompanyRepo from "./employee-company/EmployeeCompanyRepo";
import ResellerRepo from "./reseller/ResellerRepo";
import ProductRepo from "./product/ProductRepo";
import SalesChannelRepo from "./sales-channel/SalesChannelRepo";
import ApplicationRepo from "./application/ApplicationRepo";
import EmployeeRepo from "./employee/EmployeeRepo";
import HttpCompanyRepo from "./company/HttpCompanyRepo";
import HttpUserRepo from "./user/HttpUserRepo";
import HttpSalesChannelRepo from "./sales-channel/HttpSalesChannelRepo";
import HttpResellerRepo from "./reseller/HttpResellerRepo";
import HttpProductRepo from "./product/HttpProductRepo";
import HttpEmployeeRepo from "./employee/HttpEmployeeRepo";
import HttpEmployeeCompanyRepo from "./employee-company/HttpEmployeeCompanyRepo";
import HttpService from "@gsb/react-http";
import HttpApplicationRepo from "./application/HttpApplicationRepo";
import IndustryRepo from "./industry/IndustryRepo";
import {BusinessSizeRepo} from "./business-size/BusinessSizeRepo";
import {HardcodedBusinessSizeRepo} from "./business-size/HardcodedBusinessSizeRepo";
import {EntityTypeRepo} from "./entity-type/EntityTypeRepo";
import {HardcodedEntityTypeRepo} from "./entity-type/HardcodedEntityTypeRepo";
import {BusinessTypeRepo} from "./business-type/BusinessTypeRepo";
import {HardcodedBusinessTypeRepo} from "./business-type/HardcodedBusinessTypeRepo";
import StateRepo from "./state/StateRepo";
import HttpStateRepo from "./state/HttpStateRepo";
import PlanRepo from "./plan/PlanRepo";
import HttpPlanRepo from "./plan/HttpPlanRepo";
import {InMemorySalesChannelRepoCache} from "./sales-channel/InMemorySalesChannelRepoCache";
import {InMemoryResellerRepoCache} from "./reseller/InMemoryResellerRepoCache";
import ProductLicenseRepo from "./product-license/ProductLicenseRepo";
import HttpProductLicenseRepo from "./product-license/HttpProductLicenseRepo";
import {InMemoryApplicationRepoCache} from "./application/InMemoryApplicationRepoCache";
import {StripeRepo} from "./stripe/StripeRepo";
import HttpStripeRepo from "./stripe/HttpStripeRepo";
import SubscriptionRepo from "./subscription/SubscriptionRepo";
import HttpSubscriptionRepo from "./subscription/HttpSubscriptionRepo";
import {InMemorySubscriptionRepoCache} from "./subscription/InMemorySubscriptionRepoCache";
import EmployeeRoleRepo from "./employee-role/EmployeeRoleRepo";
import HttpEmployeeRoleRepo from "./employee-role/HttpEmployeeRoleRepo";
import HttpIndustryRepo from "./industry/HttpIndustryRepo";
import {InMemoryIndustryRepoCache} from "./industry/InMemoryIndustryRepoCache";
import {InMemoryStateRepoCache} from "./state/InMemoryStateRepoCache";
import SalesChannelApplicationRepo from "./sales-channel-application/SalesChannelApplicationRepo";
import HttpSalesChannelApplicationRepo from "./sales-channel-application/HttpSalesChannelApplicationRepo";
import SenderRepo from "./sender/SenderRepo";
import TemplateRepo from "./template/TemplateRepo";
import TriggerRepo from "./trigger/TriggerRepo";
import {InMemoryTemplateRepoCache} from "./template/InMemoryTemplateRepoCache";
import {InMemorySenderRepoCache} from "./sender/InMemorySenderRepoCache";
import {InMemoryTriggerRepoCache} from "./trigger/InMemoryTriggerRepoCache";
import HttpTemplateRepo from "./template/HttpTemplateRepo";
import HttpSenderRepo from "./sender/HttpSenderRepo";
import HttpTriggerRepo from "./trigger/HttpTriggerRepo";
import TopicRepo from "./ti/topic/TopicRepo";
import SiteRepo from "./ti/site/SiteRepo";
import {InMemoryTopicRepoCache} from "./ti/topic/InMemoryTopicRepoCache";
import HttpTopicRepo from "./ti/topic/HttpTopicRepo";
import {InMemorySiteRepoCache} from "./ti/site/InMemorySiteRepoCache";
import HttpSiteRepo from "./ti/site/HttpSiteRepo";
import SiteTopicRepo from "./ti/site-topic/SiteTopicRepo";
import {InMemorySiteTopicRepoCache} from "./ti/site-topic/InMemorySiteTopicRepoCache";
import HttpSiteTopicRepo from "./ti/site-topic/HttpSiteTopicRepo";
import ConsultantRepo, {HttpConsultantRepo} from "./ti/consultant/ConsultantRepo";
import FaqRepo, {HttpFaqRepo} from "./ti/faq/FaqRepo";
import FaqStatusRepo, {HttpFaqStatusRepo} from "./ti/faq/FaqStatusRepo";
import QuestionRepo, {HttpQuestionRepo} from "./ti/question/QuestionRepo";
import QuestionStatusRepo, {HttpQuestionStatusRepo} from "./ti/question/QuestionStatusRepo";
import ReportRepo from "./report/ReportRepo";
import HttpReportRepo from "./report/HttpReportRepo";
import AdpRepo from "./adp/AdpRepo";
import HttpAdpRepo from "./adp/HttpAdpRepo";
import WebsiteAnalysisRepo from "./website-analysis/WebsiteAnalysisRepo";
import HttpWebsiteAnalysisRepo from "./website-analysis/HttpWebsiteAnalysisRepo";
import MetadataTypeRepo from "./metadata-type/MetadataTypeRepo";
import HttpMetadataTypeRepo from "./metadata-type/HttpMetadataTypeRepo";
import MailchimpAccountRepo from "./mailchimp-account/MailchimpAccountRepo";
import HttpMailchimpAccountRepo from "./mailchimp-account/HttpMailchimpAccountRepo"
import { InMemoryMailchimpAccountRepoCache} from "./mailchimp-account/InMemoryMailchimpAccountRepoCache"; 
import UserSignInRepo from "./user-sign-in/UserSignInRepo";
import HttpUserSignInRepo from "./user-sign-in/HttpUserSignInRepo";
import HttpStateApplicationRepo from "./sid/state-application/HttpStateApplicationRepo";
import StateApplicationRepo from "./sid/state-application/StateApplicationRepo";
import HttpStateIdApplicationRepo from "./sid/application/HttpApplicationRepo";
import StateIdApplicationRepo from "./sid/application/ApplicationRepo";
import FieldTypeRepo from "./sid/field-type/FieldTypeRepo";
import HttpFieldTypeRepo from "./sid/field-type/HttpFieldTypeRepo";
import StateApplicationSectionRepo from "./sid/state-application-section/StateApplicationSectionRepo";
import { HttpStateApplicationSectionRepo } from "./sid/state-application-section/HttpStateApplicationSectionRepo";
import StateApplicationFieldRepo from "./sid/state-application-field/StateApplicationFieldRepo";
import { HttpStateApplicationFieldRepo } from "./sid/state-application-field/HttpStateApplicationFieldRepo";
import {default as StateIdConsultantRepo} from "./sid/consultant/ConsultantRepo";
import { default as HttpStateIdConsultantRepo } from "./sid/consultant/HttpConsultantRepo";
import SendGridAccountRepo from "./sendgrid-account/SendGridAccountRepo";
import { InMemorySendGridAccountRepoCache } from "./sendgrid-account/InMemorySendGridAccountRepoCache";
import HttpSendGridAccountRepo from "./sendgrid-account/HttpSendGridAccountRepo";

export default interface Repo {
  adp: AdpRepo;
  companies: CompanyRepo;
  users: UserRepo;
  userSignIns: UserSignInRepo;
  applications: ApplicationRepo;
  salesChannels: SalesChannelRepo;
  salesChannelApplications: SalesChannelApplicationRepo;
  resellers: ResellerRepo;
  products: ProductRepo;
  productLicenses: ProductLicenseRepo;
  industries: IndustryRepo;
  businessSize: BusinessSizeRepo;
  entityType: EntityTypeRepo;
  businessType: BusinessTypeRepo;
  state: StateRepo;
  plan: PlanRepo;
  stripe: StripeRepo;
  subscription: SubscriptionRepo;
  report: ReportRepo;
  websiteAnalysis: WebsiteAnalysisRepo;
  metadataType: MetadataTypeRepo;
  mailchimpAccount: MailchimpAccountRepo;
  sendgridAccount: SendGridAccountRepo;

  // Employees
  employees: EmployeeRepo;
  employeeCompanies: EmployeeCompanyRepo;
  employeeRoles: EmployeeRoleRepo;

  // Mail
  sender: SenderRepo;
  template: TemplateRepo;
  trigger: TriggerRepo;

  // Tarkenton Institute
  topic: TopicRepo;
  site: SiteRepo;
  siteTopic: SiteTopicRepo;
  consultant: ConsultantRepo;
  faq: FaqRepo;
  faqStatus: FaqStatusRepo;
  question: QuestionRepo;
  questionStatus: QuestionStatusRepo;

  // State Ids
  stateApplication: StateApplicationRepo;
  stateIdApplication: StateIdApplicationRepo;
  stateApplicationSection: StateApplicationSectionRepo;
  stateApplicationField: StateApplicationFieldRepo;
  fieldType: FieldTypeRepo;
  stateIdConsultant: StateIdConsultantRepo;
  
}

export class HttpRepo implements Repo {
  adp: AdpRepo = new HttpAdpRepo(this.http);
  companies: CompanyRepo = new HttpCompanyRepo(this.http);
  users: UserRepo = new HttpUserRepo(this.http);
  userSignIns: UserSignInRepo = new HttpUserSignInRepo(this.http);
  applications: ApplicationRepo = new InMemoryApplicationRepoCache(new HttpApplicationRepo(this.http));
  salesChannels: SalesChannelRepo = new InMemorySalesChannelRepoCache(new HttpSalesChannelRepo(this.http));
  salesChannelApplications: SalesChannelApplicationRepo = new HttpSalesChannelApplicationRepo(this.http);
  resellers: ResellerRepo = new InMemoryResellerRepoCache(new HttpResellerRepo(this.http));
  products: ProductRepo = new HttpProductRepo(this.http);
  productLicenses: ProductLicenseRepo = new HttpProductLicenseRepo(this.http);
  state: StateRepo = new InMemoryStateRepoCache(new HttpStateRepo(this.http));
  industries: IndustryRepo = new InMemoryIndustryRepoCache(new HttpIndustryRepo(this.http));
  businessSize: BusinessSizeRepo = new HardcodedBusinessSizeRepo();
  entityType: EntityTypeRepo = new HardcodedEntityTypeRepo();
  businessType: BusinessTypeRepo = new HardcodedBusinessTypeRepo();
  plan: PlanRepo = new HttpPlanRepo(this.http);
  stripe = new HttpStripeRepo(this.http);
  subscription = new InMemorySubscriptionRepoCache(new HttpSubscriptionRepo(this.http));
  report = new HttpReportRepo(this.http);
  websiteAnalysis = new HttpWebsiteAnalysisRepo(this.http);
  metadataType = new HttpMetadataTypeRepo(this.http);
  mailchimpAccount: MailchimpAccountRepo = new InMemoryMailchimpAccountRepoCache(new HttpMailchimpAccountRepo(this.http));
  sendgridAccount: SendGridAccountRepo = new InMemorySendGridAccountRepoCache(new HttpSendGridAccountRepo(this.http));

  // Employees
  employees: EmployeeRepo = new HttpEmployeeRepo(this.http);
  employeeCompanies: EmployeeCompanyRepo = new HttpEmployeeCompanyRepo(this.http);
  employeeRoles: EmployeeRoleRepo = new HttpEmployeeRoleRepo(this.http);

  // Mail
  sender: SenderRepo = new InMemorySenderRepoCache(new HttpSenderRepo(this.http));
  template: TemplateRepo = new InMemoryTemplateRepoCache(new HttpTemplateRepo(this.http));
  trigger: TriggerRepo = new InMemoryTriggerRepoCache(new HttpTriggerRepo(this.http));

  // Tarkenton Institute
  topic: TopicRepo = new InMemoryTopicRepoCache(new HttpTopicRepo(this.http));
  site: SiteRepo = new InMemorySiteRepoCache(new HttpSiteRepo(this.http));
  siteTopic: SiteTopicRepo = new InMemorySiteTopicRepoCache(new HttpSiteTopicRepo(this.http));
  consultant: ConsultantRepo = new HttpConsultantRepo(this.http);
  faq: FaqRepo = new HttpFaqRepo(this.http);
  faqStatus: FaqStatusRepo = new HttpFaqStatusRepo(this.http);
  question: QuestionRepo = new HttpQuestionRepo(this.http);
  questionStatus: QuestionStatusRepo = new HttpQuestionStatusRepo(this.http);

  // State Id
  stateApplication: StateApplicationRepo = new HttpStateApplicationRepo(this.http);
  stateApplicationSection: StateApplicationSectionRepo = new HttpStateApplicationSectionRepo(this.http);
  stateApplicationField: StateApplicationFieldRepo = new HttpStateApplicationFieldRepo(this.http);
  stateIdApplication: StateIdApplicationRepo = new HttpStateIdApplicationRepo(this.http);
  fieldType: FieldTypeRepo = new HttpFieldTypeRepo(this.http);
  stateIdConsultant: StateIdConsultantRepo = new HttpStateIdConsultantRepo(this.http);

  constructor(private http: HttpService) {}
}
