import React, { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import 'react-dates/initialize';
import _ from "lodash";
import ReactTable, {
  Column, ExpandedRows,
  Filter as TableFilter,
  FilterRender,
  RowInfo,
  SortingRule as TableSort
} from "react-table";
import "react-table/react-table.css";
import 'react-dates/lib/css/_datepicker.css';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createStyles, Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FilterPicker, { FilterPickerItem } from "../form/old/FilterPicker";
import { debounce } from "lodash";
import RangePicker, { DateRangeChangeValue } from "../table/pickers/RangePicker";
import { defaultMargins } from "../form/styles/field";
import classnames from 'classnames'

type TableState = { filtered: TableFilter[], sorted: TableSort[], page: number, pageSize: number };

interface Props<T> {
  columns: Column<T>[];
  pages: number;
  fetchItems: (pageSize: number, page: number, sorts: TableSort[], filters: TableFilter[], search?: string) => Promise<void>;
  items: T[];
  preview: (item: T) => React.Component | JSX.Element;
  getRibbonColor?: (item: T) => string;
  hrefProvider?: (item: T) => string;
  filters: TableFilter[];
  additionalFilters?: TableFilter[];
  searchQuery?: string;
  onFiltersChanged: (filters: TableFilter[]) => void
}

const Table = forwardRef(<T extends any>({ items, filters, additionalFilters, searchQuery, fetchItems, hrefProvider, onFiltersChanged, preview, getRibbonColor, columns, pages }: Props<T>, ref: Ref<TableRef>) => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(_.toInteger(localStorage.getItem("react-table-pageSize")) || 10);
  const [expanded, setExpanded] = useState<ExpandedRows>({});
  const tableRef = useRef<any>(null);
  const classes = useStyles();

  const tableColumns = useMemo(() => columns, [columns]);

  const updatePageSize = (size: number) => {
    localStorage.setItem("react-table-pageSize", `${size}`);
    setPageSize(size)
  }

  const reloadData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fireFetchData()
    }
  };

  const debouncedReload = useCallback(debounce(reloadData, 500), []);

  useImperativeHandle(ref, () => ({
    reloadData: () => reloadData()
  }));

  useEffect(reloadData, [filters, additionalFilters]);

  useEffect(debouncedReload, [searchQuery]);

  /*
  This makes an object to expand all rows.
  It has a key that is the index of the row and a value of true.
  */
  useEffect(() => {
    const defaultExpanded = new Array(items.length)
      .fill(true)
      .reduce((accum, value, index) =>
        ({ ...accum, [index.toString()]: value }),
        {}
      );

    setExpanded(defaultExpanded);
  }, [items]);

  function fetchData(state?: TableState) {
    if (state === undefined) {
      return;
    }

    const {
      pageSize,
      page,
      sorted,
      filtered
    } = state;

    const tableFilters: TableFilter[] = (filtered || [])
      .filter(f => !!f.value)
      .map(f => ({ id: f.id, value: f.value.id }));

    const extraFilters: TableFilter[] = additionalFilters || [];
    const allFilters: TableFilter[] = [...tableFilters, ...extraFilters];

    setLoading(true);

    fetchItems(pageSize, page, sorted, allFilters, searchQuery)
      .then(() => console.log('Table items loaded'))
      .catch(console.error)
      .finally(() => setLoading(false));
  }

  function getTrGroupProps(finalState: any, rowInfo?: RowInfo): object {
    const groupProps = {
      className: [classes.card, "MuiPaper-root", "MuiPaper-elevation1", "MuiPaper-rounded"].join(" ")
    };

    const item: T | undefined = rowInfo && rowInfo.original;
    const href = item && hrefProvider && hrefProvider(item);

    let ribbonColor = (item && getRibbonColor) ? getRibbonColor(item) : "transparent";

    return {
      ...groupProps,
      style: { borderLeftColor: ribbonColor, textDecoration: 'none' },
      href
    };
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ReactTable<T>
          ref={tableRef}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={items}
          columns={tableColumns}
          loading={loading}
          onFetchData={fetchData}
          pages={pages}
          pageSize={pageSize}
          onPageSizeChange={updatePageSize}
          expanded={expanded}
          onExpandedChange={expanded => setExpanded(expanded)}
          filterable
          filtered={filters}
          onFilteredChange={onFiltersChanged}
          style={{ backgroundColor: "whitesmoke" }}
          minRows={0}
          getTrGroupProps={getTrGroupProps}
          TrGroupComponent={({ children, className, ...rest }) => (
            <a className={classnames('rt-tr-group', className)} role="rowgroup" {...rest}>
              {children}
            </a>
          )}
          SubComponent={rowInfo => preview(rowInfo.original)}
          ExpanderComponent={() => null}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
});

export function getDateColumnDefinition<I, O extends FilterPickerItem>(displayName: string, id: string, accessor: (item: I) => string): Column<I> {
  const filter: FilterRender = ({ filter, onChange }) => <RangePicker
    value={_.get(filter, "value.id", [])}
    style={{ ...defaultMargins }}
    onChange={(value: DateRangeChangeValue) => {
      if (value === null) {
        onChange(null)
      }
      else {
        // doing this to prevent making more changes to the way filters are setup
        onChange({
          id: value
        })
      }
    }}
  />

  return {
    Header: displayName,
    id,
    accessor: accessor,
    filterable: true,
    Filter: filter,
  };
}

export function getOptionsColumnDefinition<I, O extends FilterPickerItem>(displayName: string, id: string, accessor: (item: I) => string, filterOptions?: O[], disabled?: boolean): Column<I> {
  const hasFilterOptions = filterOptions && filterOptions.length > 0;

  const filter: FilterRender = ({ filter, onChange }) => <FilterPicker
    options={filterOptions || []}
    value={(filter && filter.value) || null}
    onChange={(value) => onChange(value)}
  />;

  return {
    Header: displayName,
    id,
    accessor: accessor,
    filterable: !!hasFilterOptions && !disabled,
    Filter: hasFilterOptions && !disabled ? filter : undefined,
  };
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    border: "solid",
    borderWidth: 0,
    borderLeftWidth: "8px",
    overflow: "hidden",
    margin: "8px",
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    },
  },
}));

export interface TableRef {
  reloadData: () => void;
}

export default Table;
