import {Sender} from "../../models/mail/Sender";
import SenderRepo from "./SenderRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../models";
import {ListQuery} from "../CrudRepo";
import {sanitize} from "../../utils/FormUtils";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';

export default class HttpSenderRepo extends HttpCrudRepo<Sender> implements SenderRepo {
    pathComponents = ['mail', 'v1', 'senders'];

    list(query: ListQuery): Promise<CollectionResponse<Sender>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        return this.http.get(this.pathComponents, {query: httpQuery});
    }
    update(id: string, resource: Partial<Sender>): Promise<Sender> {
        return this.http.put([...this.pathComponents, id], sanitize(resource))
    }

    listAll(): Promise<Sender[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<Sender>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
