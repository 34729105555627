import {useRepo} from "../../../../../repos/useRepo";
import {default as React, useRef} from "react";
import Table, {TableColumn} from "../../../../table/Table";
import {ColumnFactory} from "../../../TableColumnFactory";
import {StateApplication} from "../../../../../models/sid/StateApplication";
import {StateApplicationTableColumnFactory} from "./StateApplicationTableColumnFactory";
import useCrudController from "../../../useCrudController";
import {getTableDataFetcherFromRepoFetcher} from "../../../../table/TableRepoAdapter";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../../rbac";
import {makeCreateAction, makeDeleteAction} from "../../../../table/TableActionFactory";
import useCreateStateApplicationModal from "../../../../modals/useCreateStateApplicationModal";
import useUpdateStateApplicationModal from "../../../../modals/useUpdateStateApplicationModal";


export default function StateApplicationTable() {
    const { stateApplicationRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canCreateStateApplication = can(actions.stateApplication.create);
    const canUpdateStateApplication = can(actions.stateApplication.update);
    const canDeleteStateApplication = can(actions.stateApplication.delete);

    const { setCreateStateApplicationModalOpen, createStateApplicationModal } = useCreateStateApplicationModal(reloadData);
    const { setStateStateApplicationModal, updateStateApplicationModal } = useUpdateStateApplicationModal(reloadData);

    const {_list} = useCrudController(stateApplicationRepo, reloadData);

    const columnFactory: ColumnFactory<StateApplication> = new StateApplicationTableColumnFactory();
    const columns: TableColumn<StateApplication>[] = columnFactory.makeColumns();

    // const deleteTemplate = (template: Template) => {
    //     templateRepo.delete(template.id)
    //         .then(reloadData)
    //         .catch(console.error)
    // };

     const onStateApplicationSelected = (stateApplication: StateApplication) => {
         if (canUpdateStateApplication) {
            setStateStateApplicationModal(stateApplication)
         }
    };

    return (
        <>
            <Table<StateApplication>
                tableRef={tableRef}
                title={`State Applications`}
                columns={columns}
                actions={[
                     makeCreateAction(() => setCreateStateApplicationModalOpen(true), { disabled: !canCreateStateApplication }),
                //     makeDeleteAction(deleteTemplate, { disabled: !canDeleteTemplate })
                 ]}
                options={{}}
                onRowClick={onStateApplicationSelected}
                data={getTableDataFetcherFromRepoFetcher(_list)}
            />
            {createStateApplicationModal}
            {updateStateApplicationModal}
        </>
    );
}