import React, { useMemo } from "react";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions { }

export default function YesNoPicker({ ...PickerProps }: Props) {

    interface Option { text: string, value: boolean }

    const values: Option[] = [{ text: 'YES', value: true }, { text: 'NO', value: false }];

    const options = useMemo(() => values.map(c => ({ id: c.value.toString(), label: c.text })), [values]);

    return <PickerInput label={'Yes/No'} options={options} {...PickerProps} />
}
