import React, { useMemo, useCallback, useState, useEffect } from "react";
import { ProductApplicationPicker } from "../../../form/pickers/ApplicationPicker";
import TextInput from "../../../form/inputs/TextInput";
import CheckboxInput from "../../../form/inputs/CheckboxInput";
import ExternalActivationTypePicker from "../../../form/pickers/ExternalActivationTypePicker";
import { Product } from "../../../../models/Product";
import { Application } from "../../../../models/Application";
import config from "../../../../config";
import { useFormikContext } from "formik";
import { CreateProductLicenseRequest } from "../../../../models/ProductLicense";
import { useRepo } from "../../../../repos/useRepo";
import {Divider} from "@material-ui/core";

interface Props {
    disabled?: boolean;
}

export function UpdateProductLicenseFields({ disabled, applicationId }: Props & { applicationId: string }) {
    const [application, setApplication] = useState<Application>();
    const isExternal = useMemo(() => application && config.externalApplicationServices.includes(application.identifier), [application]);

    const { applicationRepo } = useRepo();
    useEffect(() => {
        applicationRepo.getById(applicationId).then(setApplication);
    }, [applicationRepo, applicationId]);

    return (
        <>
            <TextInput name="baseUsers" label="Base Users" type="number" disabled={disabled} />

            <CheckboxInput name='isCompanyLicense' label='Company License' disabled={disabled} />

            {isExternal && (
                <>
                    <Divider />
                    <TextInput name="externalApiKey" label="External Api Key/Code" type="string" disabled={disabled} />
                    <ExternalActivationTypePicker name="externalActivationType" label="Activation Type" disabled={disabled} />
                </>
            )}
        </>
    )
}

export function CreateProductLicenseForProductFields(props: { disabled?: boolean, product: Product }) {
    const { disabled, product } = props;
    const { values } = useFormikContext<CreateProductLicenseRequest>();
    const { applicationId } = values;
    return (
        <>
            <ProductApplicationPicker productId={product.id} name={"applicationId"} disabled={disabled} />

            <UpdateProductLicenseFields disabled={disabled} applicationId={applicationId} />
        </>
    )
}
