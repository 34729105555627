import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useEffect, useState} from "react";
import {Employee} from "../../../models/Employee";
import {useRepo} from "../../../repos/useRepo";
import LoadingIndicator from "../../LoadingIndicator";
import RolesTab from "./tabs/RolesTab";
import EmployeeTab from "./tabs/EmployeeTab";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";
import {Paper} from "@material-ui/core";
import EmployeeActionBar from "./EmployeeActionBar";
import {BreadcrumbFactory, EmployeeDetailBreadcrumbFactory} from "../../../factories/BreadcrumbFactory";
import {Breadcrumbs} from "../../layout/Breadcrumbs";
import {useNavigation} from "../../../services/navigation/useNavigation";
import { actions } from "../../../rbac";
import {useMemo} from "react";

interface Params {
    employeeId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
  actions.employee.get,
];

function EmployeeDetailController({ match }: Props) {
    const [employee, setEmployee] = useState<Employee>();
    const { employeeRepo } = useRepo();

    const employeeId = useMemo(() => match.params.employeeId, [match.params.employeeId]);

    const loadEmployee = () => {
        employeeRepo.getById(employeeId).then(setEmployee).catch(console.error);
    };

    useEffect(loadEmployee, [employeeRepo, employeeId]);

    if (employee) {
        return <EmployeeDetail employee={employee} onEmployeeUpdated={loadEmployee} />
    } else {
        return <LoadingIndicator/>
    }
}

function EmployeeDetail({ employee, onEmployeeUpdated }: { employee: Employee, onEmployeeUpdated: () => void }) {
    const { navigateToEmployees } = useNavigation();

    const items: TabNavigationItem[] = [
        {
            name: "Profile",
            identifier: "profile",
            render: () => <EmployeeTab employee={employee} onEmployeeUpdated={onEmployeeUpdated}/>
        },
        {
            name: "Roles",
            identifier: "roles",
            render: () => <RolesTab employee={employee} roles={employee.roles || []} onRoleRevoked={onEmployeeUpdated} onAddRole={onEmployeeUpdated}/>
        },
    ];

    const breadcrumbFactory: BreadcrumbFactory = new EmployeeDetailBreadcrumbFactory(employee, navigateToEmployees);

    return (
        <>
            <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

            <Paper style={{ marginBottom: 12 }}>
                <EmployeeActionBar employee={employee}/>
            </Paper>
            <Paper>
                <TabNavigation items={items}/>
            </Paper>
        </>
    );
}

export default withRouter(EmployeeDetailController);