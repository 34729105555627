import {EntityType, EntityTypeRepo} from "./EntityTypeRepo";

export class HardcodedEntityTypeRepo implements EntityTypeRepo {
  private options = [
    "Sole Proprietorship",
    "Partnership",
    "LLC",
    "Corporation",
    "Other"
  ];

  async list(): Promise<EntityType[]> {
    return Promise.resolve(this.options);
  }
}
