import HTML from "../form/old/HTML";
import React from "react";
import striptags from "striptags";

interface Props {
    rawHtml: string;
}

export default function HtmlPreview({ rawHtml }: Props) {
    const html = striptags(rawHtml);

    return (
        <div style={{ marginTop: 12, marginBottom: 12, marginLeft: 6, marginRight: 6, color: "dimgray" }}>
            <HTML rawHtml={html} />
        </div>
    );
}

