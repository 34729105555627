const resellers = {
    adp: {
        id: "1ed358b0-f484-4448-b162-48c132433606",
        name: "ADP",
        identifier: "adp",
        salesChannelId: "8c0206a1-ec06-4919-9311-e2d86071af8f",
    },
    adpMarketing: {
        id: "dccda43a-e074-49af-ace7-ec6c55eb4412",
        name: "ADP Marketing",
        identifier: "adp-marketing",
        salesChannelId: "8c0206a1-ec06-4919-9311-e2d86071af8f",
    },
    legalShield: {
        id: "a62670b5-5465-4bd1-8bd3-9f0bc6a9f9f2",
        name: "Legal Shield",
        identifier: "legal-shield",
        salesChannelId: "d4ff0a55-3061-4b5d-915a-07c7c2daaf04",
    }
};

export default resellers;
