import React from "react";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import ProductList from "./products/ProductList";

export const requiredActions = [
  actions.product.list,
]

export default function Billing() {
    const { can } = useRbac();
    const canListProducts = can(actions.product.list);

    if (canListProducts) {
        return <ProductList/>
    } else {
        return null;
    }
}