import React, { useRef, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Paper, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CompanyNote } from "../../../models/Company"
import { CompanyNoteReportRequest } from "../../../models/Report";
import { useRepo } from "../../../repos/useRepo";
import { actions } from "../../../rbac";
import MaterialTable, { Column } from 'material-table';
import { CompanyNoteSearchForm } from './SearchForm';
import Routes from '../../../Routes';
import theme from "../../../theme";
import moment from "moment";
import { CompanyNoteDemeanorAvatar } from '../../entities/companies/notes/CompanyNoteDemeanor';

export const requiredActions = [
    actions.company.list
];

export default function CompanyNotesReportList() {

    const tableRef = useRef<any>(null);
    const { reportRepo } = useRepo();

    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<CompanyNote[] | undefined>();

    const makeLink = (title: string, href: string) => (
        <Link to={href}
            onClick={e => { e.stopPropagation() }}
            style={{ color: theme.palette.secondary.dark }}>
            {title}
        </Link>
    )

    const columns: Column<CompanyNote>[] = [
        { title: 'Company Id', field: 'companyId', export: true, hidden: true },
        { title: 'Company', field: 'company.name', render: (entity) => { return makeLink(entity.company?.name || '', Routes.forCompanyById(entity.companyId)) } },
        { title: "Note", field: "body", emptyValue: "-", headerStyle: { width: '35%', minWidth: 400 }, cellStyle: { width: '35%', minWidth: 400 } },
        { title: "Demeanor", field: "demeanor", align:'center', render: (entity) => { return (<CompanyNoteDemeanorAvatar companyNote={entity} />) } },
        { title: "Call Status", field: "status", emptyValue: "-" },
        { title: "Call Source", field: "source", emptyValue: "-", render: (entity) => { return (<CompanyNoteSource source={entity.source} sourceLink={entity.sourceLink} />)} },
        { title: "Call Type", field: "callType", emptyValue: "-" },
        { title: "User", field: "userDisplayName", emptyValue: "-" },
        { title: "State", field: "company.profile.stateCode", emptyValue: "-"},
        { title: "Zip Code", field: "company.profile.zipCode", emptyValue: "-"},
        { title: "Created At", field: "createdAt", type: "date", defaultSort: "desc" },
        { title: "Created By", field: "createdBy", emptyValue: "-" },
        { title: "Updated At", field: "updatedAt", type: "date" },
        { title: "Updated By", field: "updatedBy", emptyValue: "-" },
    ];

    const handleSearch = async (filters: CompanyNoteReportRequest) => {
        setLoading(true);
        const query = {
            ...filters,
            dateFrom: filters.dateFrom ? new Date(filters.dateFrom).toISOString().slice(0, 10) : null,
            dateTo: filters.dateTo ? new Date(filters.dateTo).toISOString().slice(0, 10) : null
        };
        const res = await reportRepo.companyNotesReport(query);
        setResults(res);
        setLoading(false);
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    }));

    const classes = useStyles();

    const initValues: CompanyNoteReportRequest = {
        note: '',
        callType: '',
        source: '',
        dateFrom: moment().startOf('week').toISOString(),
        dateTo: '',
    }

    useEffect(() => {
        if (!results) {
            handleSearch(initValues);
        }
    }, [reportRepo]);

    return (
        <div>
            <Paper>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Filters</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CompanyNoteSearchForm initValues={initValues} onSearch={handleSearch} />
                    </AccordionDetails>
                </Accordion>

                <MaterialTable
                    style={{ maxWidth: '100%', marginTop: '30px' }}
                    isLoading={loading}
                    title='Company Notes Report'
                    tableRef={tableRef}
                    columns={columns}
                    data={results ?? []}
                    options={{
                        search: true,
                        filtering: false,
                        paging: true,
                        toolbar: true,
                        exportButton: true,
                        exportFileName: 'Company_Note_Report',
                        exportAllData: true,
                        rowStyle: (d, index, level) => ({
                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#fafafa'
                        }),
                    }}
                />
            </Paper>
        </div>
    );
}

function CompanyNoteSource(props: { source: string, sourceLink?: string }) {
    const { source, sourceLink } = props;

    if (sourceLink) {
        return (<a href={sourceLink} target='_blank' rel="noopener noreferrer" title={sourceLink}>{source}</a>);
    }

    return (<>{source}</>);
}