import {Paper, Button} from "@material-ui/core";
import * as React from 'react';
import {useServices} from "../../services/useServices";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {useMemo} from "react";

interface Props extends RouteComponentProps {

}

function Login({ location }: Props) {
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const redirectUri: string = useMemo(() => params.get('redirectUri') || '/', [params]);

    const { authService } = useServices();

    const onLoginClicked = () => {
        const state = { redirectUri };
        authService.loginRedirect(state)
    };

    return (
        <div className="App">
            <div style={{ backgroundColor: "#009688", height: "50vh", width: "100%" }}>
                <p style={{ fontFamily: "Niconne", color: "white", fontSize: "7vh", marginTop: "-1px" }}>Masterkey 2.0</p>
            </div>
            <div style={{ position: "absolute", top: "45vh", left: 0, width: "100vw" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", maxHeight: 500}}>
                        <Paper style={{padding: 16, width: 500, overflow: "scroll"}}>
                            <p>{`Welcome to Masterkey 2.0`}</p>
                            <Button
                                style={{color: "white", backgroundColor: "teal"}}
                                variant="contained"
                                onClick={onLoginClicked}>
                                Log In
                            </Button>
                        </Paper>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "lightgray", height: "50vh", width: "100%" }} />
        </div>
    );
}


export default withRouter(Login);