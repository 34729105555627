import React from 'react';
import { emphasize, withStyles, Theme } from '@material-ui/core/styles';
import { Breadcrumbs as MaterialBreadcrumbs } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const StyledBreadcrumb = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591


export interface BreadcrumbItem {
    title: string;
    icon?: JSX.Element;
    onClick?: () => void;
}

interface Props {
    items: BreadcrumbItem[]
}

export function Breadcrumbs({ items }: Props) {
    if (items.length > 0) {
        return (
            <MaterialBreadcrumbs aria-label="breadcrumb" style={{ marginTop: 6, marginBottom: 16 }}>
                {items.map((item, index) => (
                    <StyledBreadcrumb
                        key={`breadcrumb-${index}-${item.title}`}
                        component="a"
                        label={item.title}
                        // icon={item.icon}
                        disabled={!item.onClick}
                        onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
                            event.preventDefault();

                            if (item.onClick) {
                                item.onClick()
                            }
                        }}
                    />
                ))}
            </MaterialBreadcrumbs>
        );
    } else {
        return null;
    }
}