import * as React from "react";
import {useState} from "react";
import {Plan} from "../../models/Plan";
import {Product} from "../../models/Product";
import {CreatePlanForProductModal} from "../entities/billing/plans/PlanForm";

export default function useCreatePlanForProductModal(onPlanCreated: (plan: Plan) => void) {

    const [product, setProduct] = useState<Product>();

    const handlePlanCreated = (plan: Plan) => {
        setProduct(undefined);
        onPlanCreated(plan)
    };

    const modal = product ? (
        <CreatePlanForProductModal
            open={true}
            onClose={() => setProduct(undefined)}
            product={product}
            onPlanCreated={handlePlanCreated}
        />
    ) : null;

    return { setProductForCreatePlanModal: setProduct, createPlanForProductModal: modal }
}
