import React, {useEffect, useRef} from "react";
import {queryResultFromArray} from "../../table/TableRepoAdapter";
import Table, {TableColumn, TableQuery} from "../../table/Table";
import TableColumnFactory, {ColumnFactory} from "../TableColumnFactory";
import {useRepo} from "../../../repos/useRepo";
import {Company} from "../../../models/Company";
import {User} from "../../../models/User";
import {CompanyUserTableColumnFactory} from "../users/UserTableColumnFactory";
import {makeCreateAction} from "../../table/TableActionFactory";
import useCreateUserForCompanyModal from "./create-user/useCreateUserForCompanyModal";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";

interface Props {
    company: Company,
    onUserSelected: (user: User) => void;
}

export default function CompanyUsersTable({ company, onUserSelected  }: Props) {
    const { companyRepo } = useRepo();
    const { can } = useRbac();
    const canCreateUserForCompany = can(actions.company.createUser)
    const tableRef = useRef<any>(null);

    const reloadData = () => tableRef.current.onQueryChange();

    const { createUserForCompanyModal, openCreateUserForCompanyModal } = useCreateUserForCompanyModal(company, reloadData);

    useEffect(reloadData, [company.id]);

    const columnFactory: ColumnFactory<User> = new CompanyUserTableColumnFactory(company);
    const columns: TableColumn<User>[] = TableColumnFactory.disableSearchAndFiltering(columnFactory.makeColumns());

    return (
        <>
            <Table<User>
                tableRef={tableRef}
                title={`Users for ${company.name}`}
                columns={columns}
                actions={canCreateUserForCompany ? [makeCreateAction(openCreateUserForCompanyModal)] : []}
                options={{
                    search: false,
                    filtering: false,
                    paging: false
                }}
                onRowClick={onUserSelected}
                data={async (tableQuery: TableQuery<User>) => {
                    const res = await companyRepo.listUsersForCompanyById(company.id);
                    return queryResultFromArray(res)
                }}
            />

            {createUserForCompanyModal}
        </>
    );
}