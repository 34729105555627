import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {Product} from "../../../models/Product";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export function ProductPicker({ ...PickerProps }: Props) {
    const { productRepo } = useRepo();
    const [products, setProducts] = useState<Product[]>([]);

    useEffect(() => {
        productRepo.listAllProducts().then(setProducts).catch(console.error);
    }, [productRepo]);

    const options = useMemo(() => products.map(p => ({ id: p.id, label: p.name || p.id })), [products]);

    return <PickerInput label={'Product'} options={options} {...PickerProps} />
}