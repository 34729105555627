import TopicRepo from "./TopicRepo";
import {Topic} from "../../../models/ti/Topic";
import {ListQuery} from "../../CrudRepo";

export class InMemoryTopicRepoCache implements TopicRepo {
    cachedTopicList?: Topic[];
    topicPromise?: Promise<Topic[]>;
    
    constructor(private inner: TopicRepo) {}

    async listAll(): Promise<Topic[]> {
        if (this.cachedTopicList) {
            return this.cachedTopicList;
        } else {
            if (this.topicPromise) {
                return this.topicPromise;
            } else {
                const topicsPromise = this.inner.listAll();
                this.topicPromise = topicsPromise;
                const topics = await topicsPromise;
                this.cachedTopicList = topics;
                this.topicPromise = undefined;
                return topics;
            }
        }
    };

    create = (resource: Partial<Topic>) => {
        this.cachedTopicList = undefined;
        return this.inner.create(resource)
    };

    delete = (id: string) => {
        this.cachedTopicList = undefined;
        return this.inner.delete(id);
    };

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<Topic>) => {
        this.cachedTopicList = undefined;
        return this.inner.update(id, resource);
    };
}