import {Moment} from "moment";
import RangePicker from "./RangePicker";
import * as React from "react";
import {FilterComponentProps} from "../../entities/TableColumnFactory";

export type DateFilterValue = Array<Date> | null

export default function DateRangePicker<T extends object>(props: FilterComponentProps<T>) {
    const { columnDef, onFilterChanged } = props

    const onChange = ( value: Array<Moment> | null ) => {
        let filterValue: DateFilterValue

        if( value === null ) {
            filterValue = null
        }
        else {
            const [ begin, end ] = value
            filterValue = [new Date(begin.valueOf()), new Date(end.valueOf())]
        }

        return onFilterChanged( columnDef.tableData.columnOrder.toString(), filterValue )
    }

    return (<RangePicker onChange={onChange} />)
}
