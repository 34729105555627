import {Form, Formik, FormikHelpers} from "formik";
import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {AssignLicensesForUserRequest} from "../../repos/user/UserRepo";
import {License} from "../../models/License";
import {assignLicenseSchema, initialValuesForAssignLicenseRequest} from "../../utils/schemas/license-schema";
import MultipleSelectInput from "../form/select/MultipleSelectInput";
import {User} from "../../models/User";
import LoadingIndicator from "../LoadingIndicator";
import {withModal} from "../modal";
import {useRepo} from "../../repos/useRepo";
import {FormSubmitButton} from "../form/buttons/FormSubmitButton";
import {verticalFormLayout} from "../form/styles/layout";
import { createSubmitHandler } from "../form/helpers/formSubmit";

interface Props {
    licenses: License[];
    initialValues: AssignLicensesForUserRequest;
    onSubmit: (values: AssignLicensesForUserRequest, {}: FormikHelpers<AssignLicensesForUserRequest>) => void;
}

function AssignLicensesForm({ initialValues, licenses, onSubmit }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={assignLicenseSchema} onSubmit={onSubmit}>
            <Form style={verticalFormLayout}>
                <MultipleSelectInput name='licenseIds' label='Licenses' items={licenses.map(l => ({ id: l.id, name: l.application ? l.application.name : l.name }))}/>

                <FormSubmitButton variant='Update' label='Assign'/>
            </Form>
        </Formik>
    )
}

interface UpdateUserFormProps {
    user: User;
    onLicensesAssigned: (licenses: License[]) => void;
}

export function AssignLicensesToUserForm({ user, onLicensesAssigned }: UpdateUserFormProps) {
    const { userRepo, companyRepo } = useRepo();

    const [availableLicenses, setAvailableLicense] = useState<License[]>();

    useEffect(() => {
        userRepo.listAvailableLicensesForUserById(user.id).then(setAvailableLicense).catch(console.error);
    }, [companyRepo]);

    const onSubmit = createSubmitHandler(
      (body: AssignLicensesForUserRequest) => userRepo.assignLicensesForUserById(user.id, body),
      onLicensesAssigned,
      console.error
    );

    if (availableLicenses) {
        const content = availableLicenses.length > 0
            ? <AssignLicensesForm initialValues={initialValuesForAssignLicenseRequest()} onSubmit={onSubmit} licenses={availableLicenses}/>
            : `No licenses available for ${user.firstName} ${user.lastName}`;

        return (
            <div>
                <div style={{ marginTop: 12, marginBottom: 12 }}>
                    {content}
                </div>
            </div>
        )
    } else {
        return <LoadingIndicator/>
    }
}

export const AssignLicensesToUserModal = withModal(AssignLicensesToUserForm);

