import {createStyles, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme} from "@material-ui/core";
import * as React from "react";
import {formatAmount} from "../../../../../utils/NumberFormatter";
import {Invoice, InvoiceLineItem} from "../../../../../models/Invoice";
import {Link} from "react-router-dom";
import Routes from "../../../../../Routes";

const useStyles = makeStyles((theme: Theme) => createStyles({
    tableHeader: {
        textAlign: 'left'
    }
}));

export default function InvoiceLineItemTable(props: { invoice: Invoice }) {
    const { invoice } = props;
    const classes = useStyles();

    const lineItems = invoice.lineItems || [];

    const formatPriceForItem = (item: InvoiceLineItem) => {
        const formattedAmount = formatAmount(item.amount);
        // TODO: - Support other currencies
        const formattedCurrency = item.currency === 'usd' ? "$" : "";
        return `${formattedCurrency}${formattedAmount}`
    };

    return (
        <Table>
            <TableHead>
                <TableCell className={classes.tableHeader}>Quantity</TableCell>
                <TableCell className={classes.tableHeader}>Product</TableCell>
                <TableCell className={classes.tableHeader}>Price</TableCell>
            </TableHead>
            <TableBody>
                {lineItems.map(item => (
                    <TableRow>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell><Link to={Routes.forProductById(item.productId)}>{item.product?.name ?? '-'}</Link></TableCell>
                        <TableCell>{formatPriceForItem(item)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
