import {Question} from "../../../models/ti/Question";
import React from "react";
import HtmlPreview from "../HtmlPreview";

interface Props {
    question: Question;
}

export default function QuestionPreview({ question }: Props) {
    const text = question.title || question.body;
    return (
        <HtmlPreview rawHtml={text}/>
    )
}
