import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {Plan} from "../../../models/Plan";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export function PlanPicker({ ...PickerProps }: Props) {
    const { planRepo } = useRepo();
    const [plans, setPlans] = useState<Plan[]>([]);

    useEffect(() => {
        planRepo.listAllPlans().then(setPlans).catch(console.error);
    }, [planRepo]);

    const options = useMemo(() => plans.map(p => ({ id: p.id, label: p.name || p.id })), [plans]);

    return <PickerInput label={'Plan'} options={options} {...PickerProps} />
}