import Routes from "./Routes";

export interface Environment {
    authorityUrl: string;
    clientID: string;
    apiBaseUrl: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    stripeDashboardBaseUrl: string;
}

const env = (window as any)._env_;

export const environment: Environment = {
    authorityUrl: env.REACT_APP_AUTHORITY_URL || process.exit(1),
    redirectUri: env.REACT_APP_REDIRECT_URI || process.exit(1),
    postLogoutRedirectUri: `${window.location.origin}${Routes.login}`,
    clientID: env.REACT_APP_CLIENT_ID || process.exit(1),
    apiBaseUrl: env.REACT_APP_API_BASE_URL || process.exit(1),
    stripeDashboardBaseUrl: env.REACT_APP_STRIPE_DASHBOARD_BASE_URL || process.exit(1),
};
