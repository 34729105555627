import {Company} from "../../../../../models/Company";
import * as React from "react";
import SubscriptionsTable from "./SubscriptionsTable";

export default function SubscriptionsTab(props: { company: Company }) {
    const { company } = props;

    return (
        <>
            <SubscriptionsTable company={company}/>
        </>
    );
}

