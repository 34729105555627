import React, { useState, useEffect } from "react";
import _ from "lodash"
import { DatePicker } from "@material-ui/pickers";
import { useUtils } from "@material-ui/pickers";
import { fade } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { Moment } from "moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateFormat } from "../../../utils/DateFormatter";

// Component found here, made some small adjustments https://github.com/mui-org/material-ui-pickers/issues/364

export type DateRangeChangeValue = Array<Moment | undefined> | null

interface ClassesProp {
  day: string,
  hidden: string,
  current: string,
  isDisabled: string,
  focusedRange: string,
  focusedFirst: string,
  focusedLast: string,
  beginCap: string,
  endCap: string,
  dateRangePickerDialog: string,
}

interface DateRangePickerProps {
  value?: DateRangeChangeValue,
  classes: ClassesProp,
  format?: string,
  emptyLabel?: string,
  autoOk?: boolean,
  open?: boolean,
  onOpen?: () => void,
  onClose?: () => void,
  onChange: ( value: DateRangeChangeValue ) => void,
  labelFunc?: ( value: DateRangeChangeValue, invalidLabel: string ) => string,
}

function DateRangePicker({
  classes,
  value,
  onChange,
  labelFunc,
  format,
  emptyLabel,
  autoOk,
  onOpen,
  onClose,
  open: openForward,
  ...props
} : DateRangePickerProps) {
  let [begin, setBegin] = useState<Moment | undefined>(_.get( value, "[0]" ));
  let [end, setEnd] = useState<Moment | undefined>(_.get( value, "[1]" ));
  const [prevBegin, setPrevBegin] = useState<Moment | undefined>(undefined);
  const [prevEnd, setPrevEnd] = useState<Moment | undefined>(undefined);
  const [hasClicked, setHasClicked] = useState(false);

  const [hover, setHover] = useState(undefined);
  const [accepted, setAccepted] = useState(false);
  const utils = useUtils();

  //@ts-ignore
  const min = Math.min(begin, end || hover);
  //@ts-ignore
  const max = Math.max(begin, end || hover);

  const [open, setOpen] = useState(false);

  const isOpen = openForward !== undefined ? openForward : open;

  useEffect(() => {
    //Only way to get to this state is is openForward is used
    if (isOpen && accepted && !prevBegin && !prevEnd) {
      setAccepted(false);
      setPrevBegin(begin);
      setPrevEnd(end);
      return;
    }

    if( !isOpen && !accepted ) {
      if( !prevBegin || !prevEnd ) {
        if( _.isEmpty( value ) ) {
          setBegin(undefined)
          setEnd(undefined)
        }
      }
      else if( prevBegin || prevEnd ) {
        prevBegin && setBegin( prevBegin )
        prevEnd && setEnd( prevEnd )
      }
    }

    //Auto ok and hasn't been accepted, but has all the items set, accept and close.
    //This will also triger the on change event by setting isOpen to false
    if (isOpen && autoOk && !accepted && begin && end && hasClicked) {
      setAccepted(true);
      onClose ? onClose() : setOpen(false);
    }
    if (accepted && begin && end && !isOpen && hasClicked) {
      setHasClicked(false);
      onChange([ begin, end ]);
      onClose ? onClose() : setOpen(false);
    }
  }, [begin, end, autoOk, accepted, isOpen, prevBegin, hasClicked, prevEnd]);

  // @ts-ignore
  function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
    return React.cloneElement(dayComponent, {
      onClick: e => {
        setHasClicked(true);
        e.stopPropagation();
        if (!begin) setBegin(day);
        else if (!end) {
          if (utils.isBeforeDay(day, begin)) {
            setEnd(begin);
            setBegin(day);
          } else {
            setEnd(day);
          }
          if (autoOk) {
            setPrevBegin(undefined);
            setPrevEnd(undefined);
          }
        } else {
          setBegin(day);
          setEnd(undefined);
        }
      },
      onMouseEnter: () => requestAnimationFrame(() => setHover(day)),
      onFocus: () => requestAnimationFrame(() => setHover(day)),
      className: clsx(classes.day, {
        [classes.hidden]: dayComponent.props.hidden,
        [classes.current]: dayComponent.props.current,
        [classes.isDisabled]: dayComponent.props.disabled,
        [classes.focusedRange]:
          //@ts-ignore
          (utils.isAfterDay(day, min) && utils.isBeforeDay(day, max)) ||
          //@ts-ignore
          (utils.isSameDay(day, min) && !utils.isSameDay(day, max)) ||
          //@ts-ignore
          (utils.isSameDay(day, max) && !utils.isSameDay(day, min)),
        [classes.focusedFirst]:
          //@ts-ignore
          utils.isSameDay(day, min) && !utils.isSameDay(day, max),
        [classes.focusedLast]:
          //@ts-ignore
          utils.isSameDay(day, max) && !utils.isSameDay(day, min),
        //@ts-ignore
        [classes.beginCap]: utils.isSameDay(day, min),
        //@ts-ignore
        [classes.endCap]: utils.isSameDay(day, max)
      })
    });
  }

  const formatDate = ( date: MaterialUiPickersDate ) => utils.format(date, format || DateFormat.slashDelimitedDate );
  //@ts-ignore
  const labelFunction = () => [ begin, end ].filter( val => val ).map( val => formatDate(val) ).join( " - " )

  return (
    // @ts-ignore
    <DatePicker
      {...props}
      inputProps={{
        placeholder: "Select Date...",
        style: {
          fontSize: "14px",
          fontStyle: "italic",
          textAlign: "center",
          background: "transparent",
          border: "transparent",
          paddingBottom: "9px"
        }
      }}
      clearable
      value={begin}
      renderDay={renderDay}
      open={isOpen}
      onOpen={() => {
        setAccepted(false);
        setPrevBegin(begin);
        setPrevEnd(end);
        onOpen ? onOpen() : setOpen(true);
      }}
      onAccept={() => {
        if (!begin || !end) {
          // @ts-ignore
          if (hover && utils.isBeforeDay(begin, hover)) {
            setEnd(hover);
          } else {
            setEnd(begin);
            setBegin(hover);
          }
        }
        setPrevBegin(undefined);
        setPrevEnd(undefined);
        if (!autoOk) {
          setAccepted(true);
        }
      }}
      onClose={() => {
        onClose ? onClose() : setOpen(false);
      }}
      onChange={val => {
        if( val === null ){
          onChange(val)
          setBegin(undefined)
          setEnd(undefined)
        }
      }}
      labelFunc={labelFunction}
      DialogProps={{ className: classes.dateRangePickerDialog }}
    />
  );
}
// @ts-ignore
export const styles = theme => {
  const focusedRangeColor = fade(theme.palette.primary.main, 0.3);
  const focusedRangeGradient = `linear-gradient(to right, ${focusedRangeColor}, ${focusedRangeColor})`;
  const transparentRangeGradient = `linear-gradient(to right, rgba(0,0,0,0.0), rgba(0,0,0,0.0))`;
  return {
    dateRangePickerDialog: {
      "& .MuiPickersCalendar-transitionContainer": {
        minHeight: 218,
        marginTop: 10
      }
    },
    day: {
      width: 40,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: 0,
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      padding: 0,
      transition: "none",
      "&::after": {
        borderRadius: "100%",
        bottom: 0,
        boxSizing: "border-box",
        content: '""',
        height: 36,
        width: 36,
        left: 0,
        margin: "auto",
        position: "absolute",
        right: 0,
        top: 0,
        transform: "scale(0)",
        zIndex: 2
      },
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
        "&::after": {
          backgroundColor: theme.palette.background.paper,
          border: `2px solid ${theme.palette.primary.main}`,
          bottom: -2,
          left: -2,
          height: 36,
          width: 36,
          right: -2,
          top: -2,
          boxSizing: "content-box",
          transform: "scale(1)"
        }
      },
      "& > .MuiIconButton-label": {
        zIndex: 3
      }
    },
    hidden: {
      opacity: 0,
      pointerEvents: "none"
    },
    current: {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    focusedRange: {
      color: theme.palette.primary.contrastText,
      background: `${focusedRangeGradient} no-repeat 0/20px 40px, ${focusedRangeGradient} no-repeat 20px 0/20px 40px`,
      fontWeight: theme.typography.fontWeightMedium,
      width: 40,
      marginRight: 0,
      marginLeft: 0,
      borderRadius: 0
    },
    dayDisabled: {
      pointerEvents: "none",
      color: theme.palette.text.hint
    },
    beginCap: {
      "&::after": {
        transform: "scale(1)",
        backgroundColor: theme.palette.primary.main
      }
    },
    endCap: {
      "&::after": {
        transform: "scale(1)",
        backgroundColor: theme.palette.primary.main
      }
    },
    focusedFirst: {
      background: `${transparentRangeGradient} no-repeat 0/20px 40px,${focusedRangeGradient} no-repeat 20px 0/20px 40px`
    },
    focusedLast: {
      background: `${focusedRangeGradient} no-repeat 0/20px 40px,${transparentRangeGradient} no-repeat 20px 0/20px 40px`
    }
  };
};

// @ts-ignore
export default withStyles(styles, { name: "DateRangePicker" })(DateRangePicker);

