import * as React from "react";
import {useState} from "react";
import { StateApplicationField } from "../../models/sid/StateApplicationField";
import { StateApplicationSection } from "../../models/sid/StateApplicationSection";
import {CreateStateApplicationFieldModal} from "../entities/state-id/settings/state-application/state-application-field/StateApplicationFieldForm";

export default function useCreateStateIdFieldTypeModal(stateApplicationSection: StateApplicationSection, onStateApplicationFieldCreated: (stateApplication: StateApplicationField) => void) {

    const [open, setOpen] = useState(false);

    const handleStateApplicationFieldCreated = (stateApplicationField: StateApplicationField) => {
        setOpen(false);
        onStateApplicationFieldCreated(stateApplicationField)
    };

    const modal = (
        <CreateStateApplicationFieldModal
            open={open}
            onClose={() => setOpen(false)}
            stateApplicationSection={stateApplicationSection}
            onStateApplicationFieldCreated={handleStateApplicationFieldCreated}
        />
    );

    return { setCreateStateApplicationFieldModalOpen: setOpen, createStateApplicationFieldModal: modal }
}
