import { useRepo } from "../../../../repos/useRepo";
import { default as React, useRef } from "react";
import Table, { TableColumn } from "../../../table/Table";
import { ColumnFactory } from "../../TableColumnFactory";
import { Application } from "../../../../models/sid/Application";
import { ApplicationTableColumnFactory } from "./ApplicationTableColumnFactory";
import useCrudController from "../../useCrudController";
import { getTableDataFetcherFromRepoFetcher } from "../../../table/TableRepoAdapter";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../rbac";
import { makeAssignMultiAction } from "../../../table/TableActionFactory";
import { Paper } from "@material-ui/core";
import useAssignAgentToApplicationModal from "../../../modals/useAssignAgentToApplicationModal";

export const requiredActions = [
    actions.stateIdApplication.list
];

export default function StateIdApplicationList() {
    const { stateIdApplicationRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canAssignAgent = can(actions.stateIdApplication.assignAgent);

    const { _list } = useCrudController(stateIdApplicationRepo, reloadData);

    const columnFactory: ColumnFactory<Application> = new ApplicationTableColumnFactory();
    const columns: TableColumn<Application>[] = columnFactory.makeColumns();

    const { setAssignAgentToApplicationModal, assignAgentToApplicationModal } = useAssignAgentToApplicationModal(reloadData);

    return (
        <>
            <Paper>
                <Table<Application>
                    tableRef={tableRef}
                    title={`State Id Applications`}
                    columns={columns}
                    options={{ selection: true }}
                    //onRowClick={onTemplateSelected}
                    data={getTableDataFetcherFromRepoFetcher(_list)}
                    actions={[
                        makeAssignMultiAction((rows) => setAssignAgentToApplicationModal(rows.map((application) => application.id)), { disabled: !canAssignAgent })
                    ]}
                />
            </Paper>
            {assignAgentToApplicationModal}
        </>
    );
}