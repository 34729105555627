import {Form, Formik} from "formik";
import {useRbac} from "@gsb/react-rbac";
import {Company, UpdateCompanyRequest} from "../../../models/Company";
import React from "react";
import {companySchema, initialValuesForCompanyUpdate} from "../../../utils/schemas/company-schema";
import {useRepo} from "../../../repos/useRepo";
import {actions} from "../../../rbac";
import {verticalFormLayout} from "../../form/styles/layout";
import {FormSubmitButton} from "../../form/buttons/FormSubmitButton";
import CompanyFields from "./CompanyFields";
import { updateSubmitHandler } from "../../form/helpers/formSubmit";

interface UpdateCompanyFormProps {
    company: Company;
    onCompanyUpdated: (company: Company) => void;
}

export function UpdateCompanyForm({ company, onCompanyUpdated }: UpdateCompanyFormProps) {
    const { companyRepo } = useRepo();
    const { can } = useRbac();

    const canUpdateCompany = can(actions.company.update);

    const onSubmit = updateSubmitHandler<UpdateCompanyRequest, Company>(
        (body: UpdateCompanyRequest) => companyRepo.updateCompanyById(company.id, body),
        initialValuesForCompanyUpdate,
        onCompanyUpdated,
        console.error,
    )
    const disabled = !canUpdateCompany;

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValuesForCompanyUpdate(company)}
                validationSchema={companySchema}
                onSubmit={onSubmit}>
            <Form style={verticalFormLayout}>

                <CompanyFields company={company} disabled={disabled}/>

                <FormSubmitButton variant='Update' disabled={disabled}/>
            </Form>
        </Formik>
    );

}