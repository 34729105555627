import * as Yup from 'yup';
import {CreateProductLicenseRequest, ProductLicense, UpdateProductLicenseRequest} from "../../models/ProductLicense";
import {Product} from "../../models/Product";

const baseFields = {
    baseUsers: Yup.number()
        .min(1 ,'Must be greater than 0')
        .max(255 ,'Must be less than 256')
        .required('Base users is required'),
    isCompanyLicense: Yup.bool().required('Required'),
    externalApiKey: Yup.string().nullable(),
    externalActivationType: Yup.string().nullable()
};

export const updateProductLicenseSchema = Yup.object().shape(baseFields);

export const createProductLicenseSchema = Yup.object().shape({
    ...baseFields,
    applicationId: Yup.string().required('Application is required'),
    productId: Yup.string().required('Product is required'),
});

export const initialValuesForProductLicenseUpdate = (productLicense?: ProductLicense): UpdateProductLicenseRequest => ({
    baseUsers: productLicense?.baseUsers ?? 2,
    isCompanyLicense: productLicense?.isCompanyLicense ?? false,
    externalApiKey: productLicense?.externalApiKey ?? '',
    externalActivationType: productLicense?.externalActivationType ?? ''
});

export const initialValuesForProductLicenseCreateForProduct = (product: Product): CreateProductLicenseRequest => ({
    productId: product.id,
    applicationId: "",
    ...initialValuesForProductLicenseUpdate(),
});