import {useRepo} from "../../../../repos/useRepo";
import {default as React, useRef} from "react";
import Table, {TableColumn} from "../../../table/Table";
import {ColumnFactory} from "../../TableColumnFactory";
import {MailchimpAccount} from "../../../../models/MailchimpAccount";
import {MailchimpAccountTableColumnFactory} from "./MailchimpTableColumnFactory";
import useCrudController from "../../useCrudController";
import {getTableDataFetcherFromRepoFetcher} from "../../../table/TableRepoAdapter";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import useCreateMailchimpAccountModal from "../../../modals/useCreateMailchimpAccountModal";
import useUpdateMailchimpAccountModal from "../../../modals/useUpdateMailchimpAccountModal";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";

export default function MailchimpAccountTable() {
    const { mailchimpAccountRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canCreateMailchimpAccount = can(actions.mailchimpAccount.create);
    const canUpdateMailchimpAccount = can(actions.mailchimpAccount.update);
    const canDeleteMailchimpAccount = can(actions.mailchimpAccount.delete);

    const { setCreateMailchimpAccountModalOpen, createMailchimpAccountModal } = useCreateMailchimpAccountModal(reloadData);
    const { setMailchimpAccountForUpdate, updateMailchimpAccountModal } = useUpdateMailchimpAccountModal(reloadData);

    const {_list} = useCrudController(mailchimpAccountRepo, reloadData);

    const columnFactory: ColumnFactory<MailchimpAccount> = new MailchimpAccountTableColumnFactory();
    const columns: TableColumn<MailchimpAccount>[] = columnFactory.makeColumns();

    const deleteTemplate = (template: MailchimpAccount) => {
        mailchimpAccountRepo.delete(template.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onTemplateSelected = (template: MailchimpAccount) => {
        if (canUpdateMailchimpAccount) {
            setMailchimpAccountForUpdate(template)
        }
    };

    return (
        <>
            <Table<MailchimpAccount>
                tableRef={tableRef}
                title={`Mailchimp Accounts`}
                columns={columns}
                actions={[
                    makeCreateAction(() => setCreateMailchimpAccountModalOpen(true), { disabled: !canCreateMailchimpAccount }),
                    makeDeleteAction(deleteTemplate, { disabled: !canDeleteMailchimpAccount })
                ]}
                options={{}}
                onRowClick={onTemplateSelected}
                data={getTableDataFetcherFromRepoFetcher(_list)}
            />
            {createMailchimpAccountModal}
            {updateMailchimpAccountModal}
        </>
    );
}