import { green, red, orange, yellow, blue } from "@material-ui/core/colors";

interface StateIdApplicationStatus {
    title: string;
    color: string;
    backgroundColor: string;
    text?: string;
}

const values: { [key: string]: StateIdApplicationStatus } = {
    pending: {
        title: 'User has created application but not yet submitted',
        color: orange[900],
        backgroundColor: orange[100]
    },
    ready: {
        title: 'User has submitted application and is ready for review/assignment',
        color: '#842029',
        backgroundColor: '#f8d7da'
    },
    submitted: {
        title: 'Application has been submitted to the State and is waiting for response',
        color: '#055160',
        backgroundColor: '#cff4fc'
    },
    complete: {
        title: 'State Id\'s have been received and assigned',        
        color: green[900],
        backgroundColor: green[100]
    }
}

export default values;