import {StripeProduct} from "../../models/stripe/StripeProduct";
import {StripePlan} from "../../models/stripe/StripePlan";
import {StripeRepo} from "./StripeRepo";
import HttpService from "@gsb/react-http";
import {StripeAccount} from "../../models/stripe/StripeAccount";

export default class HttpStripeRepo implements StripeRepo {
    pathComponents = ['stripe', 'v1'];

    constructor(private http: HttpService) {}

    listStripeAccounts(): Promise<Array<StripeAccount>> {
        return this.http.get([...this.pathComponents, 'accounts']);
    }

    listStripeProducts(stripeAccountIdentifier: string): Promise<Array<StripeProduct>> {
        return this.http.get([...this.pathComponents, 'products'], {
            query: {
                stripeAccountIdentifier
            }
        });
    }

    listStripePlans(stripeAccountIdentifier: string): Promise<Array<StripePlan>> {
        return this.http.get([...this.pathComponents, 'plans'], {
            query: {
                stripeAccountIdentifier
            }
        });
    }
}
