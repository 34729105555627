import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {CreateMailchimpAccountRequest, MailchimpAccount, UpdateMailchimpAccountRequest} from "../../../../models/MailchimpAccount";
import {initialValuesForMailchimpAccount, createMailchimpAccountSchema, updateMailchimpAccountSchema} from "../../../../utils/schemas/mailchimp-account-schema";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {CreateMailchimpAccountFields, UpdateMailchimpAccountFields} from "./MailchimpFields";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdateMailchimpAccountForm(props: { mailchimpAccount: MailchimpAccount, onMailchimpAccountUpdated: (mailchimpAccount: MailchimpAccount) => void }) {
    const { mailchimpAccount, onMailchimpAccountUpdated }  = props;
    const { mailchimpAccountRepo } = useRepo();

    const {can} = useRbac();
    const isReadOnly = !can(actions.mailchimpAccount.update);

    const onSubmit = updateSubmitHandler<UpdateMailchimpAccountRequest, MailchimpAccount>(
        body => mailchimpAccountRepo.update(mailchimpAccount.id, body),
        initialValuesForMailchimpAccount,
        onMailchimpAccountUpdated,
        console.error,
    );

    return (
        <Formik<UpdateMailchimpAccountRequest> enableReinitialize={true} initialValues={initialValuesForMailchimpAccount(mailchimpAccount)} validationSchema={updateMailchimpAccountSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateMailchimpAccountFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export function CreateMailchimpAccountForm(props: { onMailchimpAccountCreated: (mailchimpAccount: MailchimpAccount) => void }) {
    const { onMailchimpAccountCreated }  = props;
    const { mailchimpAccountRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateMailchimpAccountRequest) => mailchimpAccountRepo.create(body),
        onMailchimpAccountCreated,
        console.error
    );

    return (
        <Formik<CreateMailchimpAccountRequest> initialValues={initialValuesForMailchimpAccount()} validationSchema={createMailchimpAccountSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<CreateMailchimpAccountFields/>}
                                    bottomContent={<FormSubmitButton variant='Create'/>}/>
            </Form>
        </Formik>
    )
}


export const UpdateTemplateModal = withModal(UpdateMailchimpAccountForm, "Update Mailchimp Account");
export const CreateTemplateModal = withModal(CreateMailchimpAccountForm, "Create Mailchimp Account");