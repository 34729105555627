import TableColumnFactory, {ColumnFactory} from "../../../entities/TableColumnFactory";
import {Site} from "../../../../models/ti/Site";
import * as React from "react";

export class SiteTableColumnFactory implements ColumnFactory<Site>{

    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<Site>([
            TableColumnFactory.makeSiteIdColumn(),
            TableColumnFactory.makeSiteSalesChannelColumn<Site>('Sales Channel', 'salesChannelId', s => s),
            TableColumnFactory.makeSiteResellerColumn<Site>('Reseller', 'resellerId', s => s),
        ]);
    }
}
