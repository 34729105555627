import BusinessType from "../../models/BusinessType";
import {BusinessTypeRepo} from "./BusinessTypeRepo";

export class HardcodedBusinessTypeRepo implements BusinessTypeRepo {
  private options = [
    {label: "Business-to-Business (B2B)", value: "B2B"},
    {label: "Business-to-Consumer (B2C)", value: "B2C"}
  ];

  async list(): Promise<BusinessType[]> {
    return Promise.resolve(this.options);
  }
}
