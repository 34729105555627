import {CreateProductRequest, Product, UpdateProductRequest} from "../../../../models/Product";
import {Form, Formik} from "formik";
import * as React from "react";
import {useRepo} from "../../../../repos/useRepo";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {withModal} from "../../../modal";
import {
    createProductSchema,
    initialValuesForProductCreate,
    initialValuesForProductUpdate, updateProductSchema,
} from "../../../../utils/schemas/product-schema";
import {VerticalFormLayout} from "../../../form/FormLayout";
import {CreateProductFields, UpdateProductFields} from "./ProductFields";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";

export function UpdateProductForm(props: { product: Product, onProductUpdated: (Product: Product) => void }) {
    const { product, onProductUpdated }  = props;
    const { productRepo } = useRepo();
    const { can } = useRbac();
    const canUpdate = can(actions.product.update);
    const disabled = !canUpdate;

    const onSubmit = updateSubmitHandler(
        (body: UpdateProductRequest) => productRepo.update(product.id, body),
        initialValuesForProductUpdate,
        onProductUpdated,
        console.error,
    );

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValuesForProductUpdate(product)}
                onSubmit={onSubmit}
                validationSchema={updateProductSchema}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateProductFields disabled={disabled}/>}
                                    bottomContent={<FormSubmitButton variant='Update' disabled={disabled}/>}/>
            </Form>
        </Formik>
    )
}


function CreateProductForm(props: { onProductCreated: (Product: Product) => void }) {
    const { onProductCreated }  = props;
    const { productRepo } = useRepo();
    const { can } = useRbac();
    const canCreate = can(actions.product.create);
    const disabled = !canCreate;

    const onSubmit = createSubmitHandler(
        (body: CreateProductRequest) => productRepo.create(body),
        onProductCreated,
        console.error
    );

    return (
        <Formik initialValues={initialValuesForProductCreate()} onSubmit={onSubmit} validationSchema={createProductSchema}>
            <Form>
                <VerticalFormLayout fieldset={<CreateProductFields disabled={disabled}/>}
                                    bottomContent={<FormSubmitButton variant='Create' disabled={disabled}/>}/>
            </Form>
        </Formik>
    )
}

export const CreateProductFormDialog = withModal(CreateProductForm, 'Create Product');
