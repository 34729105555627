import {useRepo} from "../../../../repos/useRepo";
import {default as React, useRef} from "react";
import Table, {TableColumn} from "../../../table/Table";
import {Sender} from "../../../../models/mail/Sender";
import {ColumnFactory} from "../../TableColumnFactory";
import useUpdateSenderModal from "../../../modals/useUpdateSenderModal";
import {makeCreateAction, makeDeleteAction} from "../../../table/TableActionFactory";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import {SenderTableColumnFactory} from "./SenderTableColumnFactory";
import useCreateSenderModal from "../../../modals/useCreateSenderModal";
import useCrudController from "../../useCrudController";
import {getTableDataFetcherFromRepoFetcher} from "../../../table/TableRepoAdapter";

export default function SenderTable() {
    const { senderRepo } = useRepo();

    const tableRef = useRef<any>(null);
    const reloadData = () => tableRef.current.onQueryChange();

    const { can } = useRbac();
    const canCreateSender = can(actions.sender.create);
    const canUpdateSender = can(actions.sender.update);
    const canDeleteSender = can(actions.sender.delete);

    const {_list} = useCrudController(senderRepo, reloadData);

    const { setCreateSenderModalOpen, createSenderModal } = useCreateSenderModal(reloadData);
    const { setSenderForUpdate, updateSenderModal } = useUpdateSenderModal(reloadData);

    const columnFactory: ColumnFactory<Sender> = new SenderTableColumnFactory();
    const columns: TableColumn<Sender>[] = columnFactory.makeColumns();
    
    const deleteSender = (sender: Sender) => {
        senderRepo.delete(sender.id)
            .then(reloadData)
            .catch(console.error)
    };

    const onSenderSelected = (sender: Sender) => {
        if (canUpdateSender) {
            setSenderForUpdate(sender)
        }
    };

    return (
        <>
            <Table<Sender>
                tableRef={tableRef}
                title={`Senders`}
                columns={columns}
                actions={[
                    makeCreateAction(() => setCreateSenderModalOpen(true), { disabled: !canCreateSender }),
                    makeDeleteAction(deleteSender, { disabled: !canDeleteSender })
                ]}
                options={{}}
                onRowClick={onSenderSelected}
                data={getTableDataFetcherFromRepoFetcher(_list)}
            />
            {createSenderModal}
            {updateSenderModal}
        </>
    );
}