import {Filter as RepoFilter, ListQuery, Sort as RepoSort} from "../../repos/CrudRepo";
import {Column} from "material-table";
import {Entity} from "../../models";
import {TableFilter, TableQuery, TableQueryResult} from "./Table";
import {CollectionResponse} from "../../models";

type TableSort<T extends object> = Column<T>;
type TableDirection = "asc" | "desc";

function valueForFilter<T extends object>(tableFilter: TableFilter<T>) {
  const {type} = tableFilter.column;

  if (type === 'boolean') {
    if (tableFilter.value === 'checked') {
      return  true;
    } else if (tableFilter.value === 'unchecked') {
      return false;
    }
  }

  return tableFilter.value
}

export function getFilterFromTableFilter<T extends object>(tableFilter: TableFilter<T>): RepoFilter | null {
  const {path, field} = tableFilter.column;

  const odataField = path
    ? path.replace(/\./g,"/")
    : field;

  const value = valueForFilter(tableFilter);

  if (!odataField || value === null || value === undefined) {
    return null;
  }

  return {field: odataField.toString(), value: value};
}

export function getRepoFiltersFromTableFilters<T extends object>(tableFilters: TableFilter<T>[]): RepoFilter[] {
  return tableFilters.reduce((filters, filter) => {
    const repoFilter = getFilterFromTableFilter(filter);

    if (!repoFilter) {
      return filters;
    }

    return [...filters, repoFilter];
  }, Array<RepoFilter>())
}

export function getRepoSortFromTableSort<T extends object>(tableSort: TableSort<T>, direction: TableDirection): RepoSort | null {
  if (!tableSort || !tableSort.field) {
    return null;
  }
  return {field: tableSort.field.toString(), direction};
}

type DataFetcher<T extends Entity> = (query: TableQuery<T>) => Promise<TableQueryResult<T>>;
type RepoFetcher<T extends Entity> = (query: ListQuery) => Promise<CollectionResponse<T>>;

export function buildQueryForTableQuery<T extends object>(query: TableQuery<T>): ListQuery {
  const {
    filters: tableFilters,
    orderBy,
    orderDirection,
    search,
    page,
    pageSize
  } = query;

  const filters = getRepoFiltersFromTableFilters(tableFilters);

  const sort = getRepoSortFromTableSort(orderBy, orderDirection) || undefined;

  return { filters, sort, search, page: page + 1, pageSize }
}

export function queryResultFromCollectionResponseForQuery<T extends object>(res: CollectionResponse<T>): TableQueryResult<T> {
  return { data: res.results, page: res.page - 1, totalCount: res.total }
}

export function queryResultFromArray<T extends object>(items: T[]): TableQueryResult<T> {
  return { data: items, page: 0, totalCount: items.length }
}

export function getTableDataFetcherFromRepoFetcher<T extends object & Entity>(repoFetcher: RepoFetcher<T>): DataFetcher<T> {
  return async function fetchData<T extends Entity>(query: TableQuery<T>) {
    try {
      const listQuery = buildQueryForTableQuery(query);
      const res = await repoFetcher(listQuery);
      return queryResultFromCollectionResponseForQuery(res);
    } catch (error) {
      console.warn("table fetch error: \n" + JSON.stringify(error));
      throw error
    }
  }
}
