import React, {useContext, createContext, useState, useMemo,} from 'react';
import {AuthenticationResponse} from "@gsb/react-auth";
import UserInfo from "../../models/UserInfo";
import {Employee} from "../../models/Employee";

export interface AuthState {
    isAuthenticated?: boolean;
    authResponse?: AuthenticationResponse;
    authError?: any;
    userInfo?: UserInfo;
    setAuthResponse: (authResponse?: AuthenticationResponse) => void;
    currentEmployee?: Employee;
    setCurrentEmployee: (employee?: Employee) => void;
    setAuthError: (authError?: any) => void;
}

const AuthStateContext = createContext<AuthState>(null as unknown as AuthState);

export const useAuthState = () => useContext(AuthStateContext);

interface Props {

}

export const AuthStateProvider: React.FC<Props> = ({ children  }) => {
    const [authResponse, setAuthResponse] = useState<AuthenticationResponse>();
    const [currentEmployee, setCurrentEmployee] = useState<Employee>();
    const [authError, setAuthError] = useState<any>();

    const userInfo = useMemo(() => currentEmployee ? UserInfo.fromEmployee(currentEmployee) : undefined, [currentEmployee]);

    const isAuthenticated = useMemo(() => {
        if (authError) {
            return false
        } else if (authResponse) {
            return true
        } else {
            return undefined;
        }
    }, [authError, authResponse]);

    const authState: AuthState = useMemo(() => ({
        isAuthenticated,
        authResponse,
        setAuthResponse,
        authError,
        setAuthError,
        userInfo,
        currentEmployee,
        setCurrentEmployee
    }), [isAuthenticated, authResponse, setAuthResponse, authError, setAuthError, userInfo, currentEmployee, setCurrentEmployee]);

    return (
        <AuthStateContext.Provider value={authState}>
            {children}
        </AuthStateContext.Provider>
    );
};

export function withAuthState<T>(Component: React.ComponentType<T>): React.FC<T> {
    return (props) => {

        return (
            <AuthStateProvider>
                <Component {...props}/>
            </AuthStateProvider>
        );
    };
}
