import UserRepo, {
    AssignLicensesForUserRequest,
    AssignRolesRequest, ListLicenseQuery,
    ListRoleQuery,
    RevokeLicensesRequest,
    RevokeRoleRequest, UpdateEmailRequest, UpdateSubjectIdRequest, UpdateUsernameRequest
} from "./UserRepo";
import {CreateReferralForUserRequest, User} from "../../models/User";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {CollectionResponse} from "../../models";
import {ListQuery} from "../CrudRepo";
import {License} from "../../models/License";
import {Role} from "../../models/Role";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import {sanitize} from "../../utils/FormUtils";
import { CompanyReferral } from "../../models/Company";

export default class HttpUserRepo extends HttpCrudRepo<User> implements UserRepo {
    pathComponents = ['mk', 'v1', 'users'];

    create(body: object): Promise<User> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<User>): Promise<User> {
        return super.update(id, sanitize(resource));
    }

    getById(id: string): Promise<User> {
        const query = { include: "company,roles,licenses,salesChannel,reseller" };
        return this.http.get([...this.pathComponents, id], { query });
    }

    list(query: ListQuery): Promise<CollectionResponse<User>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "company,salesChannel,reseller";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    listAvailableLicensesForUserById(id: string): Promise<License[]> {
        return this.listLicensesForUserById(id, {
            status: "available"
        })
    }

    private listLicensesForUserById(id: string, query?: ListLicenseQuery): Promise<License[]> {
        return this.http.get([...this.pathComponents, id, 'licenses'], { query });
    }

    assignLicensesForUserById(id: string, body: AssignLicensesForUserRequest): Promise<License[]> {
        return this.http.post([...this.pathComponents, id, 'licenses'], body);
    }

    revokeLicensesForUserById(id: string, query?: RevokeLicensesRequest): Promise<void> {
        return this.http.delete([...this.pathComponents, id, 'licenses'], { query });
    }

    listRolesForUserById(id: string, query?: ListRoleQuery): Promise<Role[]> {
        return this.http.get([...this.pathComponents, id, 'roles'], { query });
    }

    assignRolesForUserById(id: string, body: AssignRolesRequest): Promise<Role[]> {
        return this.http.post([...this.pathComponents, id, 'roles'], body);
    }

    revokeRolesForUserById(id: string, query?: RevokeRoleRequest): Promise<void> {
        return this.http.delete([...this.pathComponents, id, 'roles'], { query });
    }

    sendPasswordResetEmailForUserById(id: string): Promise<void> {
        return this.http.post([...this.pathComponents, id, 'account', 'password', 'reset'], {});
    }

    createUserB2CById(id: string, password: string): Promise<User> {
        return this.http.post([...this.pathComponents, id, 'b2c'], { password });
    }

    moveUserById(id: string, companyId: string): Promise<User> {
        return this.http.post([...this.pathComponents, id, 'move'], { companyId });
    }

    updatePasswordForUserById(id:string, password: string) : Promise<void> {
        return this.http.put([...this.pathComponents, id, 'password'], { password });
    }

    updateEmailForUserById(id: string, body: UpdateEmailRequest): Promise<User> {
        return this.http.put([...this.pathComponents, id, 'email'], sanitize(body))
    }

    updateUsernameForUserById(id: string, body: UpdateUsernameRequest): Promise<User> {
        return this.http.put([...this.pathComponents, id, 'username'], sanitize(body))
    }

    updateSubjectIdForUserById(id: string, body: UpdateSubjectIdRequest): Promise<User> {
        return this.http.put([...this.pathComponents, id, 'subjectid'], sanitize(body))
    }

    createReferralForUserById(id: string, body: CreateReferralForUserRequest): Promise<CompanyReferral> {
        return this.http.post([...this.pathComponents, id, 'legal-referral'], sanitize(body));
    }

    getLegalClubClient(id: string) : Promise<any> {
        return this.http.get([...this.pathComponents, id, 'legal-club']);
    }
    createLegalClubClient(id: string): Promise<any>{
        return this.http.post([...this.pathComponents, id, 'legal-club'], {});
    }
    removeLegalClubClient(id: string): Promise<void>{
        return this.http.delete([...this.pathComponents, id, 'legal-club']);
    }
}

