import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Theme} from "@material-ui/core";
import {Company} from "../../../models/Company";
import {CompanyMenuButton} from "./CompanyMenu";
import {Link} from "react-router-dom";
import Routes from "../../../Routes";

interface Props {
    company: Company;
    onViewInStripeClicked: () => void;
    onChangeResellerClicked: () => void;
    onDeleteClicked: () => void;
}

export default function CompanyActionBar({ company, onViewInStripeClicked, onChangeResellerClicked, onDeleteClicked }: Props) {
    const classes = useStyles();

    return (
        <div className={classes.container}>

            <div className={classes.leftSection}>

                <h3 className={classes.title}>
                    {company.name}
                </h3>

                {company.salesChannel && (
                    <Link className={classes.subtitle} to={Routes.forSalesChannelById(company.salesChannelId)}>
                        {company.salesChannel.name}
                    </Link>
                )}

                {company.reseller && (
                    <Link className={classes.subtitle} to={Routes.forResellerById(company.resellerId)}>
                        {company.reseller.name}
                    </Link>
                )}
            </div>

            <div className={classes.rightSection}>
                <CompanyMenuButton company={company} onViewInStripeClicked={onViewInStripeClicked} onChangeResellerClicked={onChangeResellerClicked} onDeleteClicked={onDeleteClicked}/>
            </div>

        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden"
    },
    leftSection: {
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    rightSection: {
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    title: {

    },
    subtitle: {
        marginTop: "2px",
        marginLeft: "8px"
    },
}));
