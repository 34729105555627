import SalesChannelRepo from "./SalesChannelRepo";
import {SalesChannel} from "../../models/SalesChannel";
import {ListQuery} from "../CrudRepo";

export class InMemorySalesChannelRepoCache implements SalesChannelRepo {
    cachedSalesChannelList?: SalesChannel[];
    salesChannelPromise?: Promise<SalesChannel[]>;
    
    constructor(private inner: SalesChannelRepo) {}

    async listAll(): Promise<SalesChannel[]> {
        if (this.cachedSalesChannelList) {
            return this.cachedSalesChannelList;
        } else {
            if (this.salesChannelPromise) {
                return this.salesChannelPromise;
            } else {
                const salesChannelsPromise = this.inner.listAll();
                this.salesChannelPromise = salesChannelsPromise;
                const salesChannels = await salesChannelsPromise;
                this.cachedSalesChannelList = salesChannels;
                this.salesChannelPromise = undefined;
                return salesChannels;
            }
        }
    };

    create = (resource: Partial<SalesChannel>) => this.inner.create(resource);

    delete = (id: string) => this.inner.delete(id);

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<SalesChannel>) => this.inner.update(id, resource);
}