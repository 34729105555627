import TopicRepo from "./TopicRepo";
import {Topic} from "../../../models/ti/Topic";
import {HttpCrudRepo} from "../../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../../models";
import {ListQuery} from "../../CrudRepo";
import {getHttpQueryFromListQuery} from "../../../utils/QueryUtils";
import {sanitize} from "../../../utils/FormUtils";
import config from '../../../config';

export default class HttpTopicRepo extends HttpCrudRepo<Topic> implements TopicRepo {
    pathComponents = ['ti', 'v1', 'topics'];

    create(body: object): Promise<Topic> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<Topic>): Promise<Topic> {
        return super.update(id, sanitize(resource));
    }

    list(query: ListQuery): Promise<CollectionResponse<Topic>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    listAll(): Promise<Topic[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<Topic>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
