import React from "react";
import { makeAddAction, makeDeleteAction, makeEditAction } from "../../../../factories/MenuItemFactory";
import { CompanyNote } from "../../../../models/Company";
import { MenuButton, useMenu } from "../../../menu/Menu";

interface Props {
    companyNote: CompanyNote;
    onDeleteClicked?: (companyNote: CompanyNote) => void;
    onEditClicked?: (companyNote: CompanyNote) => void;
    onAddThreadClicked?: (companyNote: CompanyNote) => void;
}

const makeCompanyNoteMenuItems = (companyNote: CompanyNote, onDeleteClicked?: (companyNote: CompanyNote) => void, onEditClicked?: (companyNote: CompanyNote) => void, onAddThreadClicked?: (companyNote: CompanyNote) => void) => {
    const items = [];
    if (onEditClicked) {
        items.push(makeEditAction(() => onEditClicked(companyNote)));
    }
    if (onAddThreadClicked && !companyNote.threadId) {
        items.push(makeAddAction(() => onAddThreadClicked(companyNote), 'Add Thread'));
    }
    if (onDeleteClicked) {
        items.push(makeDeleteAction(() => onDeleteClicked(companyNote)));
    }
    return items;
}

export function CompanyNoteMenuButton({ companyNote, onDeleteClicked, onAddThreadClicked, onEditClicked }: Props) {
    const menuItems = makeCompanyNoteMenuItems(companyNote, onDeleteClicked, onEditClicked, onAddThreadClicked);
    return (
        <MenuButton items={menuItems} />
    )
}

export function useCompanyNoteMenu(onDeleteClicked?: (companyNote: CompanyNote) => void, onEditClicked?: (companyNote: CompanyNote) => void, onAddThreadClicked?: (companyNote: CompanyNote) => void) {
    const { menu, openMenu } = useMenu();

    return {
        companyNoteMenu: menu,
        openCompanyNoteMenu: (element: HTMLElement, companyNote: CompanyNote) => {
            const items = makeCompanyNoteMenuItems(companyNote, onDeleteClicked, onEditClicked, onAddThreadClicked);
            openMenu(element, items)
        }
    }
}
