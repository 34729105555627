import SiteTopicRepo from "./SiteTopicRepo";
import {ListQuery} from "../../CrudRepo";
import {SiteTopic} from "../../../models/ti/SiteTopic";

export class InMemorySiteTopicRepoCache implements SiteTopicRepo {
    cachedSiteList?: SiteTopic[];
    sitePromise?: Promise<SiteTopic[]>;
    
    constructor(private inner: SiteTopicRepo) {}

    async listAll(): Promise<SiteTopic[]> {
        if (this.cachedSiteList) {
            return this.cachedSiteList;
        } else {
            if (this.sitePromise) {
                return this.sitePromise;
            } else {
                const sitesPromise = this.inner.listAll();
                this.sitePromise = sitesPromise;
                const sites = await sitesPromise;
                this.cachedSiteList = sites;
                this.sitePromise = undefined;
                return sites;
            }
        }
    };

    create = (resource: Partial<SiteTopic>) => {
        this.cachedSiteList = undefined;
        return this.inner.create(resource)
    };

    delete = (id: string) => {
        this.cachedSiteList = undefined;
        return this.inner.delete(id);
    };

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<SiteTopic>) => {
        this.cachedSiteList = undefined;
        return this.inner.update(id, resource);
    };

    listAvailableTopicsForSiteById = this.inner.listAvailableTopicsForSiteById;

    createTopicForSiteById = this.inner.createTopicForSiteById;

    updateTopicForSiteById = this.inner.updateTopicForSiteById;

    deleteTopicForSiteById = this.inner.deleteTopicForSiteById;
}