import * as Yup from 'yup';

export const subscriptionFileTypeSchema = Yup.object().shape({
    name: Yup.string()
        .min(1, "Name cannot be empty")
        .required('Required'),
    parserIdentifier: Yup.string()
        .min(1, "Parser cannot be empty")
        .required('Required'),
    fileNames: Yup.string()
        // .matches(/\w+?(,\w+)*/, "Must be comma separated list")
        .nullable(),
    isCancellation: Yup.bool()
        .required('Is cancellation is required'),
    includesHeaders: Yup.bool()
        .required('Is cancellation is required'),
    columnCount: Yup.number()
        .min(1, 'Must be greater than 0')
        .required('Column count is required'),
    planId: Yup.string()
        .required('Plan is required'),
});
