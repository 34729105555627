import React from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Theme} from "@material-ui/core";
import {User} from "../../../models/User";
import {MenuItem, MenuItemBuilder, MenuButton} from "../../menu/Menu";
import {UserMenuItemBuilder} from "./UserMenuItemBuilder";
import {useNavigation} from "../../../services/navigation/useNavigation";
import Routes from "../../../Routes";
import { Link } from 'react-router-dom';
import useImpersonateUserModal from "../../modals/useImpersonateUserModal";
import useUpdateUserPasswordModal from "../../modals/useUpdateUserPasswordModal";
import useCreateUserB2CModal from "../../modals/useCreateUserB2CModal";
import useMoveUserModal from "../../modals/useMoveUserModal";
import useLegalClubClientDetailModal from './legal-club/useLegalClubClientDetail';

interface Props {
    user: User;
    onUserUpdated: () => void;
    onDeleteClicked: () => void;
}

export default function UserActionBar({ user, onUserUpdated, onDeleteClicked }: Props) {
    const classes = useStyles();

    const { openImpersonateUserModal, impersonateUserModal } = useImpersonateUserModal(user);
    const { openUpdateUserPasswordModal, updateUserPasswordModal } = useUpdateUserPasswordModal(user);
    const { openLegalClubClientDetailModal, legalClubClientDetailModal } = useLegalClubClientDetailModal(user);
    const { openCreateB2CModal, createB2CModal } = useCreateUserB2CModal(user, onUserUpdated);
    const { openMoveUserModal, moveUserModal } = useMoveUserModal(user, onUserUpdated);
    

    const { navigateToActiveDirectoryB2CUser } = useNavigation();
    const menuItemBuilder: MenuItemBuilder = new UserMenuItemBuilder(user, 
        openUpdateUserPasswordModal, 
        navigateToActiveDirectoryB2CUser, 
        openImpersonateUserModal, 
        openLegalClubClientDetailModal,
        openCreateB2CModal,
        openMoveUserModal,
        onDeleteClicked);

    const actionItems: MenuItem[] = menuItemBuilder.build();

    return (
        <div className={classes.container}>

            <div className={classes.leftSection}>

                <h3 className={classes.title}>
                    {user.firstName} {user.lastName}
                </h3>

                {user.company && (
                    <Link className={classes.subtitle} to={Routes.forCompanyById(user.companyId)}>
                        {user.company.name}
                    </Link>
                )}

                {user.salesChannel && (
                    <Link className={classes.subtitle} to={Routes.forSalesChannelById(user.salesChannelId)}>
                        {user.salesChannel.name}
                    </Link>
                )}

                {user.reseller && (
                    <Link className={classes.subtitle} to={Routes.forResellerById(user.resellerId)}>
                        {user.reseller.name}
                    </Link>
                )}
            </div>

            <div className={classes.rightSection}>
                {actionItems.length > 0 && (
                    <MenuButton items={actionItems}/>
                )}
            </div>
            {impersonateUserModal}
            {updateUserPasswordModal}
            {legalClubClientDetailModal}
            {createB2CModal}
            {moveUserModal}
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        overflow: "hidden"
    },
    leftSection: {
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    rightSection: {
        padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    title: {

    },
    subtitle: {
        marginTop: "2px",
        marginLeft: "8px"
    },
}));
