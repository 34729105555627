import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {Topic} from "../../../models/ti/Topic";

export default function TopicDropdown(props: FilterComponentProps<any>) {
    const { topicRepo } = useRepo();
    const [topics, setTopics] = useState<Topic[]>([]);

    useEffect(() => {
        topicRepo.listAll().then(setTopics).catch(console.error);
    }, [topicRepo]);
    
    return (
        <DropdownPicker options={topics} {...props}/>
    )
}