import React from "react";
import { Tooltip, IconButton, withStyles, TooltipProps as MaterialTooltipPros } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import moment from "moment";

type Props = { createdAt: Date, updatedAt: Date } & Omit<MaterialTooltipPros, 'title' | 'children'>;

type PropsDetail = { created: string, updated?: string, iconSize?: "medium" | "small" | undefined } & Omit<MaterialTooltipPros, 'title' | 'children'>;

function TimestampTooltip({ createdAt, updatedAt, ...TooltipProps }: Props) {
    return (
        <TimestampDetailTooltip created={moment(createdAt).format("l")} updated={moment(updatedAt).format("l")} {...TooltipProps} />
    );
}

function TimestampDetailTooltip({ created, updated, iconSize, ...TooltipProps }: PropsDetail) {

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 250,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
            ' & dl': {
                padding: '0 5px',
            },
            ' & dt': {
                float: 'left',
                clear: 'left',
                width: 75,
                textAlign: 'right',
                fontWeight: 'bold',
            },
            ' & dt:after': {
                content: ':',
            },
            ' & dd': {
                margin: '0 0 0 85px',
                padding: '0 0 5px 0',
            },
            ' & dd:last-child': {
                padding: 0
            }
        },
    }))(Tooltip);

    return (
        <HtmlTooltip
            placement='right'
            arrow
            title={
                <React.Fragment>
                    <dl>
                        <dt>Created:</dt>
                        <dd>{created}</dd>
                        <dt>Updated:</dt>
                        <dd>{updated}</dd>
                    </dl>
                </React.Fragment>
            }
            {...TooltipProps}
        >
            <IconButton aria-label="info" color="secondary" size={iconSize}>
                <Info />
            </IconButton>
        </HtmlTooltip>
    );
}

export {
    TimestampDetailTooltip,
    TimestampTooltip
}