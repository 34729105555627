import * as Yup from 'yup';
import {SendGridAccount} from "../../models/SendGridAccount";

const baseFields = {
    name: Yup.string().required("Name is required"),
    apiKey: Yup.string().required("Api Key is required"),
    listId: Yup.string().required("Audience Id is required"),
    segmentId: Yup.string().nullable(),
    segment: Yup.string().nullable(),
    salesChannelId: Yup.string().required("Sales Channel is required"),
    resellerId: Yup.string().nullable(),
    updateOnSubscriptionEvent: Yup.boolean().default(false)
};

export const createSendGridAccountSchema = Yup.object().shape(baseFields);

export const updateSendGridAccountSchema = Yup.object().shape(baseFields);

export const initialValuesForSendGridAccount = (account?: SendGridAccount) => ({
    name: account?.name ?? "",
    apiKey: account?.apiKey ?? "",
    listId: account?.listId ?? "",
    segmentId: account?.segmentId ?? "",
    segment: account?.segment ?? "",
    salesChannelId: account?.salesChannelId ?? "",
    resellerId: account?.resellerId ?? "",
    updateOnSubscriptionEvent: account?.updateOnSubscriptionEvent ?? false
});
