import {Template} from "../../models/mail/Template";
import TemplateRepo from "./TemplateRepo";
import {HttpCrudRepo} from "../HttpCrudRepo";
import {arrayFromCollection, CollectionResponse} from "../../models";
import {ListQuery} from "../CrudRepo";
import {sanitize} from "../../utils/FormUtils";
import {getHttpQueryFromListQuery} from "../../utils/QueryUtils";
import config from '../../config';

export default class HttpTemplateRepo extends HttpCrudRepo<Template> implements TemplateRepo {
    pathComponents = ['mail', 'v1', 'templates'];

    list(query: ListQuery): Promise<CollectionResponse<Template>> {
        const httpQuery = getHttpQueryFromListQuery(query);
        httpQuery.include = "sender,trigger,salesChannel,reseller";
        return this.http.get(this.pathComponents, {query: httpQuery});
    }

    create(body: object): Promise<Template> {
        return super.create(sanitize(body));
    }

    update(id: string, resource: Partial<Template>): Promise<Template> {
        return this.http.put([...this.pathComponents, id], sanitize(resource))
    }

    listAll(): Promise<Template[]> {
        const httpQuery = getHttpQueryFromListQuery({
            pageSize: config.maxDropdownSize
        });
        return this.http
            .get<CollectionResponse<Template>>(this.pathComponents, {query: httpQuery})
            .then(arrayFromCollection);
    }
}
