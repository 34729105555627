import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {Typography} from "@material-ui/core";

export interface Props {
  id: string;
  keepMounted: boolean;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  body: string;
  isDestructive?: boolean;
  title?: string;
  confirmationText?: string;
}

export default function ConfirmationDialog(props: Props) {
  const { onConfirm, onCancel, open, isDestructive, confirmationText } = props;
  const classes = useStyles();
  const destructiveButtonStyle = {color: "#e57373"};

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      classes={{paper: classes.paper}}
    >
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <Typography>{props.body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" style={isDestructive ? destructiveButtonStyle : {}}>
          {confirmationText || "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      width: '80%',
      maxHeight: 435,
    },
  }),
);
