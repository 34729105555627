import * as React from "react";
import {useState} from "react";
import {License} from "../../models/License";
import {AssignUserToLicenseForCompanyModal} from "../forms/AssignUserToLicenseForm";
import {Company} from "../../models/Company";

export default function useAssignUserToLicenseModal(company: Company, onLicenseAssignedToUser: () => void) {

    const [license, setLicense] = useState<License>();

    const titleForLicense = (l: License) => {
        const applicationName = l.application?.name;
        return applicationName ? `Assign ${applicationName} License to User` : 'Assign License to User'
    }

    const modal = license ? (
        <AssignUserToLicenseForCompanyModal
            open={true}
            onClose={() => setLicense(undefined)}
            onLicensesAssigned={() => {
                setLicense(undefined);
                onLicenseAssignedToUser()
            }}
            company={company}
            license={license}
            title={titleForLicense(license)}
        />
    ) : null;

    return { setLicenseToBeAssignedToUser: setLicense, assignLicenseToUserModal: modal }
}
