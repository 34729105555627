import * as React from "react";
import {useState} from "react";
import {Company} from "../../../../models/Company";
import {CreateNoChargeSubscriptionForCompanyModal} from "./CreateNoChargeSubscriptionForCompanyForm";

export default function useCreateNoChargeSubscriptionForCompanyModal(company: Company, onSubscriptionCreated: () => void) {

    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);

    const modal = (
        <CreateNoChargeSubscriptionForCompanyModal
            open={open}
            onClose={close}
            company={company}
            onSubscriptionCreated={() => {
                close();
                onSubscriptionCreated()
            }}
            title={`Create No Charge Subscription for ${company.name}`}
        />
    );

    return {
        openCreateNoChargeSubscriptionForCompanyModal: () => setOpen(true),
        createNoChargeSubscriptionForCompany: modal
    }
}
