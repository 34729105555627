import * as React from "react";
import {User} from "../../../models/User";
import {DialogChildProps} from "../EntityDialog";
import {initialValuesForUserUpdate, updateUserSchema} from "../../../utils/schemas/user-schema";
import Form from "../../form/Form";
import {UpdateUserFields} from "./UserFields";

interface Props extends DialogChildProps {
  user: User;
  onValuesChange?: (draft: Partial<User>) => void;
  isReadOnly: boolean
}

export default function Detail({ user, isReadOnly, ...formProps}: Props) {
  return (
    <Form
      disabled={isReadOnly}
      initialValues={initialValuesForUserUpdate(user)}
      validationSchema={updateUserSchema}
      {...formProps}
    >
        <UpdateUserFields disabled={isReadOnly} resellerId={user.resellerId}/>
    </Form>
  );
}
