import {Form, Formik} from "formik";
import {Company, CreateUserForCompanyRequest} from "../../../../models/Company";
import {User} from "../../../../models/User";
import React from "react";
import TextInput from "../../../form/inputs/TextInput";
import TimeZonePicker from "../../../form/pickers/TimeZonePicker";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import {withModal} from "../../../modal";
import {
    createUserForCompanySchema,
    initialValuesForCreateUserForCompanyRequest
} from "../../../../utils/schemas/user-schema";
import { createSubmitHandler } from "../../../form/helpers/formSubmit";
import {useRepo} from "../../../../repos/useRepo";

interface Props {
    company: Company
    onUserCreated: (user: User) => void;
}

function CreateUserForCompanyForm({ company, onUserCreated }: Props) {
    const { companyRepo } = useRepo()

    const onSubmit = createSubmitHandler(
        (body: CreateUserForCompanyRequest) => companyRepo.createUserForCompanyById(company.id, body),
        onUserCreated,
        console.error
    );

    return (
        <Formik initialValues={initialValuesForCreateUserForCompanyRequest()}
                validationSchema={createUserForCompanySchema}
                onSubmit={onSubmit}>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

                <TextInput name='firstName' label='First Name'/>

                <TextInput name='lastName' label='Last Name'/>

                <TextInput name='email' label='Email'/>

                <TextInput name='phoneNumber' label='Phone Number'/>

                <TimeZonePicker name='timeZone' allowEmpty={true} emptyValue='' emptyValueDescription='-'/>

                <FormSubmitButton variant='Create'/>

            </Form>
        </Formik>
    )
}

export const CreateUserForCompanyModal = withModal(CreateUserForCompanyForm);

