import * as React from "react";
import { Divider, makeStyles, TextField } from "@material-ui/core";
import { Business } from "../../../models/Business";
import { useRepo } from "../../../repos/useRepo";

interface Props {
    companyId: string,
    business: Business;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '2rem'
      },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',

        '& label': {
            fontWeight: 'bold',
            display: 'block'
        }
      }
  }));

export default function CompanyProfileDetail({ companyId, business }: Props) {

    const { companyRepo } = useRepo();

    const classes = useStyles();

    const [result, setResult] = React.useState<Business | undefined>();

    const reloadData = () => {
        companyRepo.getBusinessProfileById(companyId, business.id).then(setResult).catch(console.error);
    }

    React.useEffect(reloadData, [business.id]);

    return (result ?
        <div className={classes.root}>
            <div className={classes.textField} >
                <label>Company Name</label>
                <span>{result?.name}</span>
            </div>
            <div className={classes.textField} >
                <label>Owner Name</label>
                <span>{result?.ownerName}</span>
            </div>
            <div className={classes.textField} >
                <label>DBA Name</label>
                <span>{result?.profile?.company?.dba}</span>
            </div>
            <div className={classes.textField} >
                <label>Short Name</label>
                <span>{result?.profile?.company?.shortName}</span>
            </div>
            <div className={classes.textField} >
                <label>Entity Type</label>
                <span>{result?.profile?.company?.entityType}</span>
            </div>
            <div className={classes.textField} >
                <label>Company Address</label>
                <span>{result?.profile?.companyAddress?.address1} {result?.profile?.companyAddress?.address2}<br/>
                {result?.profile?.companyAddress?.city}, {result?.profile?.companyAddress?.stateCode}  {result?.profile?.companyAddress?.zipCode}
                </span>
            </div>
            <div className={classes.textField} >
                <label>Legal: Federal Tax Id</label>
                <span>{result?.profile?.legal?.federalTaxId}</span>
            </div>
            <div className={classes.textField} >
                <label>Legal: State Law</label>
                <span>{result?.profile?.legal?.stateLaw}</span>
            </div>
            <div className={classes.textField} >
                <label>Legal: Signatory</label>
                <span>{result?.profile?.legal?.signatory?.title} {result?.profile?.legal?.signatory?.name}</span>
            </div>
            
            
        </div> : <div></div>
    )
}
