import * as React from "react";
import {ErrorMessage, useField} from "formik";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@material-ui/core";
import {defaultMargins} from "../styles/field";

interface Props {
    name: string;
    label?: string;
    helperText?: string;
    disabled?: boolean;
}

export default function CheckboxInput({ name, label, helperText, disabled }: Props) {
    const [field, meta] = useField<boolean | undefined>(name);
    const { checked, ...fieldProps } = field;

    return (
        <FormControl style={defaultMargins} variant='outlined' error={!!meta.error} disabled={disabled}>
            <FormControlLabel label={label}
                              control={ <Checkbox name={name}
                                                  checked={fieldProps.value}
                                                  indeterminate={fieldProps.value === null || fieldProps.value === undefined}
                                                  {...fieldProps}/>} />
            <ErrorMessage name={name}/>

            {helperText && (
                <FormHelperText>{helperText}</FormHelperText>
            )}

        </FormControl>
    );
}
