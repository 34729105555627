import React, {useEffect, useState} from "react";
import {useRepo} from "./repos/useRepo";
import {RbacProvider} from "@gsb/react-rbac";
import {grants} from "./rbac";
import { withRouter } from "react-router";
import {useAuthState} from "./services/auth/useAuthState";
import LoadingIndicator from "./components/LoadingIndicator";

interface Props {

}

const AppStartup: React.FC<Props> = ({ children }) => {
    const { isAuthenticated, setCurrentEmployee } = useAuthState();
    const [roles, setRoles] = useState<string[]>();
    const { employeeRepo } = useRepo();

    useEffect(() => {
        if (isAuthenticated === true) {
            employeeRepo.getById('current').then(employee => {
                const roles = employee.roles || [];
                const flattenedRoles = roles.map(role => role.identifier);
                setCurrentEmployee(employee);
                setRoles(flattenedRoles);
            }).catch(console.error);
        }
    }, [isAuthenticated]);

    if (roles) {
        return (
            <>
                <RbacProvider grants={grants} roleProvider={() => roles} onEvent={() => {}}>
                    {children}
                </RbacProvider>
            </>
        )
    } else {
        return (
            <LoadingIndicator/>
        )
    }
};

const StartupWithRouter = withRouter(AppStartup);

const withStartup = <P extends any>(Component: React.ComponentType<P>): React.FC<P> =>
    (props) => {
        return (
            <StartupWithRouter>
                <Component {...props}/>
            </StartupWithRouter>
        );
    };

export default withStartup;