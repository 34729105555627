import {CreateEmployeeRequest, Employee, UpdateEmployeeRequest} from "../../../models/Employee";
import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../repos/useRepo";
import {withModal} from "../../modal";
import {employeeSchema, initialValuesForEmployee} from "../../../utils/schemas/employee-schema";
import EmployeeFields from "./EmployeeFields";
import {VerticalFormLayout} from "../../form/FormLayout";
import {FormSubmitButton, FormVariant} from "../../form/buttons/FormSubmitButton";
import { createSubmitHandler, updateSubmitHandler } from "../../form/helpers/formSubmit";

type EmployeeRequest = CreateEmployeeRequest | UpdateEmployeeRequest;

interface Props {
    isReadOnly?: boolean;
    isCreate?: boolean;
    submitButtonVariant: FormVariant;
    initialValues: EmployeeRequest;
    onSubmit: (values: EmployeeRequest, actions: FormikHelpers<EmployeeRequest>) => void;
}


function EmployeeForm({ submitButtonVariant, initialValues, onSubmit, isReadOnly, isCreate }: Props) {

    return (
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit} validationSchema={employeeSchema}>
            <Form>
                <VerticalFormLayout fieldset={<EmployeeFields disabled={isReadOnly} showSubjectId={isCreate}/>}
                                    bottomContent={<FormSubmitButton variant={submitButtonVariant} disabled={isReadOnly} />}/>
            </Form>
        </Formik>
    )
}

export function CreateEmployeeForm(props: { onEmployeeCreated: (employee: Employee) => void }) {
    const { onEmployeeCreated }  = props;
    const { employeeRepo } = useRepo();

    const onSubmit = createSubmitHandler(
        (body: CreateEmployeeRequest) => employeeRepo.create(body),
        onEmployeeCreated,
        console.error
    );

    return <EmployeeForm initialValues={initialValuesForEmployee()} onSubmit={onSubmit} submitButtonVariant='Create' isCreate={true}/>
}

export function UpdateEmployeeForm(props: { employee: Employee, onEmployeeUpdated: (employee: Employee) => void }) {
    const { employee, onEmployeeUpdated }  = props;
    const { employeeRepo } = useRepo();

    const onSubmit = updateSubmitHandler(
        (body: UpdateEmployeeRequest) => employeeRepo.update(employee.id, body),
        initialValuesForEmployee,
        onEmployeeUpdated,
        console.error,
    );

    return <EmployeeForm initialValues={initialValuesForEmployee(employee)} onSubmit={onSubmit} submitButtonVariant='Update' isCreate={false}/>
}


export const CreateEmployeeModal = withModal(CreateEmployeeForm, 'Invite Employee');