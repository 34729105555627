import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";
import { Consultant } from "../../../models/ti/Consultant";

interface Props extends PickerPropsWithoutOptions{}

export function StateIdConsultantPicker({ ...PickerProps }: Props) {
    const { stateIdConsultantRepo } = useRepo();
    const [consultants, setConsultants] = useState<Consultant[]>([]);

    useEffect(() => {
        stateIdConsultantRepo.list().then(setConsultants).catch(console.error);
    }, [stateIdConsultantRepo]);

    const options = useMemo(() => consultants.map(p => ({ id: p.id, label: p.name || p.id })), [consultants]);

    return <PickerInput label={'Assigned Agent'} options={options} {...PickerProps} />
}