import * as Yup from 'yup';
import {Application} from "../../models/Application";

export const applicationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    identifier: Yup.string().required("Identifier is required"),
});

export const initialValuesForApplication = (application?: Application) => ({
    name: application?.name ?? "",
    identifier: application?.identifier ?? "",
});
