import {SalesChannel} from "../../../../../models/SalesChannel";
import * as React from "react";
import SalesChannelApplicationTable from "../../sales-channel-applications/SalesChannelApplicationTable";

interface Props {
    salesChannel: SalesChannel;
}

export default function ApplicationsTab({ salesChannel }: Props) {
    return (
        <>
            <SalesChannelApplicationTable salesChannel={salesChannel}/>
        </>
    )
}
