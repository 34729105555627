import {MenuButton, useMenu} from "../../menu/Menu";
import {makeViewInStripeAction, makeChangeCompanyResellerAction, makeDeleteAction} from "../../../factories/MenuItemFactory";
import {Company} from "../../../models/Company";
import React from "react";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";

interface Props {
    company: Company;
    onViewInStripeClicked: () => void;
    onChangeResellerClicked: () => void;
    onDeleteClicked: () => void;
}

const makeCompanyMenuItems = (company: Company, onViewCompanyInStripeClicked: (company: Company) => void) => [
    makeViewInStripeAction(() => onViewCompanyInStripeClicked(company)),
]

export function CompanyMenuButton({ company, onViewInStripeClicked, onChangeResellerClicked, onDeleteClicked }: Props) {
    const { can } = useRbac();

    const menuItems = makeCompanyMenuItems(company, onViewInStripeClicked);

    if(can(actions.company.updateReseller)){
        menuItems.push(makeChangeCompanyResellerAction(onChangeResellerClicked));
    }

    if(can(actions.company.delete)){
        menuItems.push(makeDeleteAction(onDeleteClicked));
    }

    return (
        <MenuButton items={menuItems}/>
    )
}

export function useCompanyMenu(onViewCompanyInStripeClicked: (company: Company) => void) {
    const { menu, openMenu } = useMenu();

    return {
        companyMenu: menu,
        openCompanyMenu: (element: HTMLElement, company: Company) => {
            const items = makeCompanyMenuItems(company, onViewCompanyInStripeClicked);
            openMenu(element, items)
        }
    }
}
