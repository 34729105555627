import { StatsReportItem } from "../../../models/Report";
import React from "react";
import { Card, CardContent, CardHeader, CircularProgress, Chip, Avatar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RadialChart, Hint } from 'react-vis';
import "react-vis/dist/style.css";


function ChartItem({ title, data, totalCount, caption, loading }: { title: string, data: Array<any>, totalCount: number, caption: string | null, loading: boolean }) {
    const useStyles = makeStyles((theme) => ({
        caption: {
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.caption.fontWeight
        },
        chart: {
            '& .rv-radial-chart__series--pie__slice:hover': {
                stroke: '#000000 !important',
                strokeWidth: '2px'
            }
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 0
        },
        hint: {
            background: 'black',
            borderRadius: '3px',
            padding: '3px 8px',
            whiteSpace: 'nowrap'
        },
        legend: {
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: 0,
            margin: 0,
          },
          chip: {
            margin: theme.spacing(0.5),
          },
    }));

    const [state, setState] = React.useState<{ value: any }>({ value: false });
    const classes = useStyles();

    const onMouseOver = (v: any) => {
        setState({ value: v });
    }

    const numberWithCommas = (x: number): string => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Card>
            <CardHeader title={title} subheader={caption + ': ' + numberWithCommas(totalCount)} />
            <CardContent className={classes.content}>
                {loading ?
                    (<CircularProgress size={150} color={'secondary'} />) :
                    (<RadialChart
                        className={classes.chart}
                        data={data}
                        innerRadius={85}
                        radius={125}
                        height={275}
                        width={275}
                        padAngle={0.04}
                        labelsAboveChildren={false}
                        //showLabels={true}
                        colorType={'literal'}
                        labelsRadiusMultiplier={0.95}
                        labelsStyle={{ stroke: '#fff', strokeWidth: 0.25, fontWeight: 'bold' }}
                        onValueMouseOver={onMouseOver}
                        onSeriesMouseOut={() => setState({ value: false })}
                    >
                        {state.value !== false && <Hint value={state.value}><div className={classes.hint}><b>{state.value.label}: {numberWithCommas(state.value.count)}</b></div></Hint>}
                    </RadialChart>)}
            </CardContent>
            <CardContent>
            <ul className={classes.legend}>
                { data.map((d:any) => (<li key={d.id}><Chip size='small' label={d.label + ': ' + numberWithCommas(d.count)} className={classes.chip} variant="outlined" avatar={<Avatar style={{backgroundColor: d.color}}> </Avatar>}  /></li>))}
            </ul>
            </CardContent>
        </Card>
    );
}

export function StatsChartItem(props: { title: string, data: Array<StatsReportItem>, caption: string | null, loading: boolean, groupBy: string | undefined }) {

    const { title, data, caption, loading, groupBy } = props;

    interface itemGroup {
        id: string,
        title: string,
        count: number,
        color: string
    }

    const resellerColors: { [key: string]: string } = {
        '1ed358b0-f484-4448-b162-48c132433606': '#037ef3',
        'e5946a26-8239-4d43-97d6-5915a9d2d66e': '#f85a40',
        'a62670b5-5465-4bd1-8bd3-9f0bc6a9f9f2': '#30c39e',
        '3a09195d-dd52-4b47-9652-27804a237ffb': '#0a8ea0',
        '424aa2d3-f7a3-45bf-807c-1fa0713de481': '#5655a5',
        'da34b9fd-0c44-482e-a3bf-b129d7e18e4a': '#ba0c2f',
        'ff6cf7fb-1bbf-44b8-9808-26cee51fe988': '#52565e'
    }

    const salesChannelColors: { [key: string]: string } = {
        'd4ff0a55-3061-4b5d-915a-07c7c2daaf04': '#037ef3',
        '28f3a036-d826-4b83-9398-0d5f6d63c061': '#f85a40',
        '5880ad28-5f3a-41c0-8942-684aeedb2a91': '#ba0c2f',
        '4b019689-9e26-4a88-b757-f9a0a7302cd8': '#0a8ea0',
        '8c0206a1-ec06-4919-9311-e2d86071af8f': '#5655a5'
    }

    const getColor = (colors: { [key: string]: string }, identifier: string): string => {
        if (Object.keys(colors).includes(identifier))
            return colors[identifier];
        else
            return '#ff6c5f';
    }

    const mapData = (d: itemGroup, t: number) => {
        return {
            id: d.id,
            angle: calAngle(d.count,t),
            label: d.title,
            count: d.count,
            color: d.color
        };
    }

    const groupBySalesChannel = (d: Array<StatsReportItem>): (Array<itemGroup>) => {
        return d.reduce((res: itemGroup[], value: StatsReportItem) => {
            let item = res.find(v => v.id === value.salesChannelId);
            if (!item) {
                item = { id: value.salesChannelId, title: value.salesChannel, count: 0, color: getColor(salesChannelColors, value.salesChannelId) };
                res.push(item);
            }
            item.count += parseInt(value.count);
            return res;
        }, []);
    }

    const groupByReseller = (d: Array<StatsReportItem>): (Array<itemGroup>) => {
        return d.reduce((res: itemGroup[], value: StatsReportItem) => {
            let item = res.find(v => v.id === value.resellerId);
            if (!item) {
                item = { id: value.resellerId, title: value.reseller, count: 0, color: getColor(resellerColors, value.resellerId) };
                res.push(item);
            }
            item.count += parseInt(value.count);
            return res;
        }, []);
    }

    const groupByType = (d: Array<StatsReportItem>, t: string): (Array<any>) => {

        if (d.length === 0) return [];

        switch (t) {
            case 'reseller': {
                return groupByReseller(d).map(g => mapData(g, totalCount(d)));
            }
            case 'sales-channel':
            default: {
                return groupBySalesChannel(d).map(g => mapData(g, totalCount(d)));
            }
        }
    }

    const totalCount = (d: StatsReportItem[]): number => {
        return data.reduce((a, b) => a + (parseInt(b.count) || 0), 0);
    }

    const calAngle = (p: number, t: number): number => {
        if (p === 0) return 0;
        return Math.round(Math.max(((360/100) * ((p / t) * 100)), 10));
    }

    return (
        <ChartItem title={title}
            data={groupByType(data, groupBy ?? 'sales-channel')}
            caption={caption}
            totalCount={totalCount(data)}
            loading={loading} />
    );
}
