import {CollectionResponse, Entity} from "../../models";
import CrudRepo, {ListQuery} from "../../repos/CrudRepo";

export function useCrudListController<E extends Entity>(repo: CrudRepo<E>, onComplete: () => void) {

  function _list(query: ListQuery): Promise<CollectionResponse<E>> {
    return repo.list(query).finally(onComplete);
  }

  return { _list }
}

export function useCrudDeleteController<E extends Entity>(repo: CrudRepo<E>, onDelete: () => void) {

  function _delete(selectedEntity: E, onComplete: () => void) { return async () => {
    try {
      await repo.delete(selectedEntity.id);
      onComplete();
      onDelete()
    } catch(error) {
      console.warn(error);
    }
  }}

  return { _delete }
}

export function useCrudSaveController<E extends Entity>(repo: CrudRepo<E>, onSave: () => void) {

  function _save(selectedEntity?: E, draft?: Partial<E>, onComplete?: () => void) { return async () => {
    if (!draft) {
      console.error("The draft to be saved is empty.");
      return;
    }

    if (selectedEntity) {
      await repo.update(selectedEntity.id, draft);
    } else {
      await repo.create(draft)
    }

    onSave();
    onComplete && onComplete();
  }}


  return { _save }
}

export default function useCrudController<E extends Entity>(repo: CrudRepo<E>, onDataStale: () => void) {
  const { _save } = useCrudSaveController(repo, onDataStale);
  const { _delete } = useCrudDeleteController(repo, onDataStale);
  const { _list } = useCrudListController(repo, () => {});
  return { _save, _delete, _list};
}
