import * as React from "react";
import { useField, useFormikContext } from "formik";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { defaultMargins } from "../styles/field";

interface Props {
    name: string;
    label?: string;
    helperText?: string;
    options: { label: string, id: string, disabled?: boolean }[];
    disabled?: boolean;
}

export default function RadioButtonGroupInput({ name, label, helperText, options, disabled }: Props) {
    const [field, meta] = useField<string | undefined>(name);
    const { value, ...fieldProps } = field;
    const { setFieldValue } = useFormikContext();

    const radioButtons = [];
    for(var i = 0; i < options.length; i++){
        const option = options[i];
        radioButtons.push(<FormControlLabel key={`rb_${i}`} value={option.id} disabled={option.disabled} control={<Radio />} label={option.label} />);
    }

    return (
        <FormControl style={defaultMargins} component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup name={name} value={value} onChange={(e) => setFieldValue(field.name, e.target.value) } row={true}>
                {radioButtons}
            </RadioGroup>
            {helperText && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
}
