import * as React from "react";
import {useState} from "react";
import {Topic} from "../../models/ti/Topic";
import {CreateTopicModal} from "../tarkenton-institute/settings/topics/TopicForm";

export default function useCreateTopicModal(onTopicCreated: (sender: Topic) => void) {

    const [open, setOpen] = useState(false);

    const handleTopicCreated = (sender: Topic) => {
        setOpen(false);
        onTopicCreated(sender)
    };

    const modal = (
        <CreateTopicModal
            open={open}
            onClose={() => setOpen(false)}
            onTopicCreated={handleTopicCreated}
        />
    );

    return { openCreateTopicModalOpen: () => setOpen(true), createTopicModal: modal }
}
