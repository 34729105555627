import TableColumnFactory, {ColumnFactory} from "../../TableColumnFactory";
import {SendGridAccount} from "../../../../models/SendGridAccount";

export class SendGridAccountTableColumnFactory implements ColumnFactory<SendGridAccount>{
    makeColumns = () => {
        return TableColumnFactory.withTimestampColumns<SendGridAccount>([
            TableColumnFactory.fromProperty("Name", "name"),
            TableColumnFactory.fromProperty("Api Key", "apiKey"),
            TableColumnFactory.fromProperty("List Id", "listId"),
            TableColumnFactory.fromProperty("Segment Id", "segmentId"),
            TableColumnFactory.fromProperty("Segment Value", "segment"),
            TableColumnFactory.makeSalesChannelColumn("Sales Channel", 'salesChannelId', c => c.salesChannel),
            TableColumnFactory.makeResellerColumn("Reseller", 'resellerId',c => c.reseller),
        ]);
    }
}
