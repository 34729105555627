import TableColumnFactory, {ColumnFactory} from "../../../TableColumnFactory";
import { FieldType } from "../../../../../models/sid/FieldType";
import { TableColumn } from "../../../../table/Table";

export class FieldTypeTableColumnFactory implements ColumnFactory<FieldType>{
    makeColumns = (): TableColumn<FieldType>[] => {
        return [
            TableColumnFactory.makeFieldTypeIdColumn(),
            TableColumnFactory.fromProperty("Name", "name"),
            TableColumnFactory.fromProperty("Description", "description"),
            TableColumnFactory.fromProperty("Component", "component")
        ];
    }
}
