import {IdentifiableResource} from "./IdentifiableResource";

export interface Entity extends IdentifiableResource {
    createdAt: string;
    updatedAt: string;
    deletedAt?: string;
}

export function entityDictionaryFromList<E extends Entity>(list: E[]) {
    return list.reduce(
      (dictionary, result) => ({ ...dictionary, [result.id]: result }),
      {}
    );
}
