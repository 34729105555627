import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { textInputStyle } from "../../form/styles/field";
import { Collapse, Link } from "@material-ui/core";
import { isUUID } from "../../../utils/StringUtils";

interface Props {
    value?: { id: string, value: string }[];
    onChange: (value: { id: string, value: string }[]) => void;
}

export interface AdvancedSearch {
    memberId?: string,
    companyId?: string
}

export default function AdvanceSearchBar({ onChange }: Props) {
    const [checked, setChecked] = React.useState(false);
    const [values, setValues] = React.useState<AdvancedSearch>({});

    const handleOnChange = (val: AdvancedSearch) => {
        const filters = [];
        if (val.memberId && isUUID(val.memberId)) {
            filters.push({ id: 'memberId', value: val.memberId });
        }
        if (val.companyId && isUUID(val.companyId)) {
            filters.push({ id: 'member/companyId', value: val.companyId });
        }

        onChange(filters);
    }

    return (
        <div style={{ padding: 10, paddingTop: 0, maxWidth: "100%" }}>
            <Link onClick={() => setChecked(!checked)} style={{ margin: 6 }}>{checked ? 'Hide Advanced Search' : 'Show Advanced Search'}</Link>
            <Collapse in={checked}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", maxWidth: "100%", flexWrap: "wrap" }}>
                    <TextField
                        label='User Id'
                        placeholder='User Id'
                        variant='outlined'
                        style={textInputStyle}
                        onChange={(event) => handleOnChange({ ...values, memberId: event.target.value })}
                    />
                    <TextField
                        label='Company Id'
                        placeholder='Company Id'
                        variant='outlined'
                        style={textInputStyle}
                        onChange={(event) => handleOnChange({ ...values, companyId: event.target.value })}
                    />
                </div>
            </Collapse>
        </div>
    );
};
