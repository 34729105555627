import {useRepo} from "../../../repos/useRepo";
import React, {useEffect, useMemo, useState} from "react";
import {Trigger} from "../../../models/mail/Trigger";
import PickerInput, {PickerPropsWithoutOptions} from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions {}

export function TriggerPicker({ ...PickerProps }: Props) {
    const { triggerRepo } = useRepo();
    const [triggers, setTriggers] = useState<Trigger[]>([]);

    useEffect(() => {
        triggerRepo.listAll().then(setTriggers).catch(console.error);
    }, [triggerRepo]);

    const options = useMemo(() => triggers.map(p => ({ id: p.id, label: p.name || p.id })), [triggers]);

    return <PickerInput label={'Trigger'} options={options} {...PickerProps} />
}