import React, { useMemo } from "react";
import PickerInput, { PickerPropsWithoutOptions } from "../inputs/PickerInput";

interface Props extends PickerPropsWithoutOptions { }

export default function ExternalActivationTypePicker({ ...PickerProps }: Props) {

    interface Option { text: string, value: string }

    const values: Option[] = [
        { text: 'All', value: 'all' }, 
        { text: 'Paid Only', value: 'paid-only' },
        { text: 'No Charge Only', value: 'no-charge-only' }, 
    ];

    const options = useMemo(() => values.map(c => ({ id: c.value.toString(), label: c.text })), [values]);

    return <PickerInput label={'Activation Type'} options={options} {...PickerProps} />
}
