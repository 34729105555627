import React, {useState} from 'react';
import MaterialMenu from '@material-ui/core/Menu';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {IconButton} from "@material-ui/core";
import MoreIcon from '@material-ui/icons/MoreVert';

export interface MenuItem {
    name: string;
    icon?: JSX.Element;
    onClick: () => void;
}

export interface MenuItemBuilder {
    build: () => MenuItem[];
}

interface Props {
    items: MenuItem[];
    icon?: JSX.Element;
}

export function MenuButton({ icon, items }: Props) {
    const { menu, openMenu } = useMenu();

    return (
        <>
            <IconButton aria-label="delete" onClick={e => openMenu(e.currentTarget, items)}>
                {icon || <MoreIcon />}
            </IconButton>
            {menu}
        </>
    );
}

export function useMenu() {
    const [items, setItems] = useState<MenuItem[]>()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemSelected = (item: MenuItem) => {
        handleClose()
        item.onClick()
    };

    const menu = (
        <MaterialMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {items && items.map((item, index) => (
                <MaterialMenuItem key={`menu-item-${index}`} onClick={() => handleItemSelected(item)}>
                    {item.icon && (
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                    )}
                    <ListItemText primary={item.name}/>
                </MaterialMenuItem>
            ))}
        </MaterialMenu>
    )

    return {
        openMenu: (element: HTMLElement, items: MenuItem[]) => {
            setItems(items);
            setAnchorEl(element);
        },
        menu
    }
}