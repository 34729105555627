import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {useRepo} from "../../../repos/useRepo";
import ApplicationFields from "./ApplicationFields";
import {CreateApplicationRequest, Application, UpdateApplicationRequest} from "../../../models/Application";
import {initialValuesForApplication, applicationSchema} from "../../../utils/schemas/application-schema";
import {VerticalFormLayout} from "../../form/FormLayout";
import {FormSubmitButton, FormVariant} from "../../form/buttons/FormSubmitButton";
import { updateSubmitHandler } from "../../form/helpers/formSubmit";

type ApplicationRequest = CreateApplicationRequest | UpdateApplicationRequest;

interface Props {
    isReadOnly?: boolean;
    submitButtonVariant: FormVariant;
    initialValues: ApplicationRequest;
    validationSchema: any;
    onSubmit: (values: ApplicationRequest, actions: FormikHelpers<ApplicationRequest>) => void;
}

function ApplicationForm({ submitButtonVariant, initialValues, validationSchema, onSubmit, isReadOnly }: Props) {
    return (
        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout fieldset={<ApplicationFields disabled={isReadOnly}/>}
                                    bottomContent={<FormSubmitButton variant={submitButtonVariant} disabled={isReadOnly}/>}/>
            </Form>
        </Formik>
    )
}

export function UpdateApplicationForm(props: { application: Application, onApplicationUpdated: (application: Application) => void }) {
    const { application, onApplicationUpdated }  = props;
    const { applicationRepo } = useRepo();

    const onSubmit = updateSubmitHandler(
        (body: UpdateApplicationRequest) => applicationRepo.update(application.id, body),
        initialValuesForApplication,
        onApplicationUpdated,
        console.error,
    );

    return (
        <ApplicationForm initialValues={initialValuesForApplication(application)}
                         validationSchema={applicationSchema}
                         onSubmit={onSubmit}
                         submitButtonVariant='Update'/>
    )
}
