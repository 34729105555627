import {MenuItem, MenuItemBuilder} from "../../menu/Menu";
import {Employee} from "../../../models/Employee";
import React from "react";

import { makeViewInActiveDirectoryAction } from '../../../factories/MenuItemFactory';

export class EmployeeMenuItemBuilder implements MenuItemBuilder {

    constructor(private employee: Employee,
                private onViewActiveDirectoryEmployeeClicked: (subjectId: string) => void) {}

    build = () => {
        const actions: MenuItem[] = [];
        const { subjectId } = this.employee;

        if (subjectId) {
            actions.push(
                makeViewInActiveDirectoryAction(subjectId, this.onViewActiveDirectoryEmployeeClicked)
            )
        }

        return actions;
    }
}