import SendGridAccountRepo from "./SendGridAccountRepo";
import { SendGridAccount } from "../../models/SendGridAccount";
import { ListQuery } from "../CrudRepo";

export class InMemorySendGridAccountRepoCache implements SendGridAccountRepo {
    cachedAccountList?: SendGridAccount[];
    accountPromise?: Promise<SendGridAccount[]>;

    constructor(private inner: SendGridAccountRepo) {}

    async listAll(): Promise<SendGridAccount[]> {
        if (this.cachedAccountList) {
            return this.cachedAccountList;
        } else {
            if (this.accountPromise) {
                return this.accountPromise;
            } else {
                const templatesPromise = this.inner.listAll();
                this.accountPromise = templatesPromise;
                const templates = await templatesPromise;
                this.cachedAccountList = templates;
                this.accountPromise = undefined;
                return templates;
            }
        }
    };

    create = (resource: Partial<SendGridAccount>) => this.inner.create(resource);

    delete = (id: string) => this.inner.delete(id);

    getById = (id: string) => this.inner.getById(id);

    list = (query: ListQuery) => this.inner.list(query);

    update = (id: string, resource: Partial<SendGridAccount>) => this.inner.update(id, resource);
}