import {createStyles, Theme, Typography} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Question} from "../../../../models/ti/Question";

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        fontWeight: "bolder", color: "gray"
    },
}));

export default function QuestionInfo(props: { title: string, question: Question, onClick: () => void }) {
    const { title, question, onClick } = props;
    const classes = useStyles();

    return (
        <div
            key={title}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginBottom: 14
            }}
            onClick={onClick}
        >
            <Typography className={classes.title} style={{ marginBottom: 4, marginRight: 10 }}>
                {title}
            </Typography>
            <Typography>
                ID: {question.id}
            </Typography>
        </div>
    );
}
