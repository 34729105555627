import {Site} from "../../../../models/ti/Site";
import {CreateSiteTopicRequest, SiteTopic, UpdateSiteTopicRequest} from "../../../../models/ti/SiteTopic";
import {useRepo} from "../../../../repos/useRepo";
import {default as React, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Topic} from "../../../../models/ti/Topic";
import { createSubmitHandler, updateSubmitHandler } from "../../../form/helpers/formSubmit";
import {Form, Formik} from "formik";
import {InlineFormLayout, VerticalFormLayout} from "../../../form/FormLayout";
import TopicPicker from "../../../form/pickers/TopicPicker";
import CheckboxInput from "../../../form/inputs/CheckboxInput";
import TextInput from "../../../form/inputs/TextInput";
import {FormSubmitButton} from "../../../form/buttons/FormSubmitButton";
import {FormRef} from "../../../form/FormRef";
import {withModal} from "../../../modal";
import {
    createSiteTopicSchema,
    initialValuesForNewSiteTopicForSite,
    initialValuesForSiteTopic, updateSiteTopicSchema
} from "../../../../utils/schemas/site-topic-schema";


interface Props {
    site: Site;
    onSiteTopicCreated: (siteTopic: SiteTopic) => void
}

export const CreateSiteTopicForSiteForm = forwardRef<FormRef, Props>((props, ref) => {

    const { site, onSiteTopicCreated } = props;
    const { siteRepo, siteTopicRepo } = useRepo();

    const [topics, setTopics] = useState<Topic[]>([]);

    const loadTopics = () => {
        siteRepo.listAvailableTopicsForSiteById(site.id).then(setTopics).catch(console.error);
    };

    const onCreated = (siteTopic: SiteTopic) => {
        loadTopics();
        onSiteTopicCreated(siteTopic);
    };

    const onSubmit = createSubmitHandler(
        (body: CreateSiteTopicRequest) => siteTopicRepo.create(body),
        onCreated,
        console.error
    );

    useEffect(loadTopics, [site]);

    useImperativeHandle(ref, () => ({
        reload: () => loadTopics()
    }));

    return (
        <Formik<CreateSiteTopicRequest> initialValues={initialValuesForNewSiteTopicForSite(site)} validationSchema={createSiteTopicSchema} onSubmit={onSubmit}>
            <Form>
                <InlineFormLayout
                    fieldset={(
                        <>
                            <TopicPicker topics={topics} name={"topicId"}/>

                            <CheckboxInput name='isFeatured' label='Is Featured'/>
                        </>
                    )}
                    bottomContent={<FormSubmitButton variant='Create' label='Add'/>}/>
            </Form>
        </Formik>
    )
});

export function UpdateSiteTopicForm(props: { siteTopic: SiteTopic, onSiteTopicUpdated: (siteTopic: SiteTopic) => void }) {
    const { siteTopic, onSiteTopicUpdated } = props;
    const { siteTopicRepo } = useRepo();

    const onSubmit = updateSubmitHandler(
        (body: UpdateSiteTopicRequest) => siteTopicRepo.update(siteTopic.id, body),
        initialValuesForSiteTopic,
        onSiteTopicUpdated,
        console.error,
    );

    return (
        <Formik<UpdateSiteTopicRequest> enableReinitialize={true}
                                        initialValues={initialValuesForSiteTopic(siteTopic)}
                                        validationSchema={updateSiteTopicSchema}
                                        onSubmit={onSubmit}>
            <Form>
                <VerticalFormLayout
                    fieldset={(
                        <>
                            <CheckboxInput name='isFeatured' label='Is Featured'/>

                            <TextInput name='sortOrder' label='Sort Order' type="number" />
                        </>
                    )}
                    bottomContent={<FormSubmitButton variant='Update'/>}/>
            </Form>
        </Formik>
    )
}

export const UpdateSiteTopicModal = withModal(UpdateSiteTopicForm);