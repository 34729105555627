import {CreateUserRequest, User, UpdateUserRequest} from "../../../models/User";
import {Form, Formik} from "formik";
import React from "react";
import {useRepo} from "../../../repos/useRepo";
import {withModal} from "../../modal";
import {
    initialValuesForUserUpdate,
    initialValuesForUserCreate,
    createUserSchema,
    updateUserSchema
} from "../../../utils/schemas/user-schema";
import {VerticalFormLayout} from "../../form/FormLayout";
import {FormSubmitButton} from "../../form/buttons/FormSubmitButton";
import {CreateUserFields, UpdateUserFields} from "./UserFields";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import { createSubmitHandler, updateSubmitHandler } from "../../form/helpers/formSubmit";

export function CreateUserForm(props: { onUserCreated: (user: User) => void }) {
    const { onUserCreated }  = props;
    const { userRepo } = useRepo();

    const { can } = useRbac();
    const canCreateUser = can(actions.user.create);

    const onSubmit = createSubmitHandler(
        (body: CreateUserRequest) => userRepo.create(body),
        onUserCreated,
        console.error
    );

    return (
        <Formik initialValues={initialValuesForUserCreate()}
                onSubmit={onSubmit}
                validationSchema={createUserSchema}>
            <Form>
                <VerticalFormLayout fieldset={<CreateUserFields disabled={!canCreateUser}/>}
                                    bottomContent={<FormSubmitButton variant='Create' disabled={!canCreateUser}/>}/>
            </Form>
        </Formik>
    )
}

export function UpdateUserForm(props: { user: User, onUserUpdated: (user: User) => void }) {
    const { user, onUserUpdated }  = props;
    const { userRepo } = useRepo();
    const { can } = useRbac();
    const canUpdateUser = can(actions.user.update);

    const onSubmit = updateSubmitHandler(
        (body: UpdateUserRequest) => userRepo.update(user.id, body),
        initialValuesForUserUpdate,
        onUserUpdated,
        console.error,
    );

    return (
        <Formik enableReinitialize={true}
                initialValues={initialValuesForUserUpdate(user)}
                onSubmit={onSubmit}
                validationSchema={updateUserSchema}>
            <Form>
                <VerticalFormLayout fieldset={<UpdateUserFields disabled={!canUpdateUser} resellerId={user.resellerId}/>}
                                    bottomContent={<FormSubmitButton variant='Update' disabled={!canUpdateUser}/>}/>
            </Form>
        </Formik>
    )
}

export const CreateUserModal = withModal(CreateUserForm, 'Create User');
