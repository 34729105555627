import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker from "../pickers/DropdownPicker";
import React, {useEffect, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import {Application} from "../../../models/Application";

export default function ApplicationDropdown(props: FilterComponentProps<any>) {
    const { applicationRepo } = useRepo();
    const [applications, setApplications] = useState<Application[]>([]);

    useEffect(() => {
        applicationRepo.listAll().then(setApplications).catch(console.error);
    }, [applicationRepo]);
    
    return (
        <DropdownPicker options={applications} {...props}/>
    )
}