import React from "react";
import {Paper} from "@material-ui/core";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../../rbac";
import TabNavigation, {TabNavigationItem} from "../../../tabs/TabNavigation";
import StateApplicationTable from "./state-application/StateApplicationTable";
import FieldTypeTable from "./field-type/FieldTypeTable";

export const requiredActions = [
  actions.stateApplication.list
]

export function StateIdSettings() {
    const { can } = useRbac();

    const canListStateApplications = can(actions.stateApplication.list)
    const canListFieldTypes = can(actions.stateIdFieldType.list);
    const shouldShowList = canListStateApplications || canListFieldTypes;

    const items: TabNavigationItem[] = [
        {
            name: "State Applications",
            identifier: "state-applications",
            render: () => <StateApplicationTable/>,
            disabled: !canListStateApplications
        },
        {
            name: "Field Type Definitions",
            identifier: "field-types",
            render: () => <FieldTypeTable/>,
            disabled: !canListFieldTypes
        }
    ];

    if (shouldShowList) {
        return (
            <Paper>
                <TabNavigation items={items} disabledContentPadding/>
            </Paper>
        )
    } else {
        return null;
    }
}