import { Subscription } from "../../../../../models/Subscription";
import { Button } from "@material-ui/core";
import React from "react";
import { useRbac } from "@gsb/react-rbac";
import { actions } from "../../../../../rbac";
import { useRepo } from "../../../../../repos/useRepo";
import { useNavigation } from "../../../../../services/navigation/useNavigation";
import useCreateCompanyNoteModal from "../../../../modals/useCreateCompanyNoteModal"

interface Props {
    subscription: Subscription;
    onSubscriptionCanceled: () => void;
}

export default function SubscriptionDetailActions({ subscription, onSubscriptionCanceled }: Props) {
    const { can } = useRbac();
    const { subscriptionRepo } = useRepo();
    const { navigateToStripeSubscription } = useNavigation();
    const { stripeSource, noChargeSource } = subscription;

    const cancelSubscription = () => {
        subscriptionRepo.cancelSubscriptionById(subscription.id).then(onSubscriptionCanceled).catch(console.error)
    };

    const { createCompanyNoteModal, setCreateCompanyNoteModalOpen } = useCreateCompanyNoteModal(subscription.companyId ?? '', cancelSubscription, 'Canceled No Charge Subscription');

    if (stripeSource) {
        return <Button onClick={() => navigateToStripeSubscription(stripeSource.stripeSubscriptionId)} size='small'>View in Stripe</Button>
    } else if (noChargeSource && subscription.isActive) {
        const canCancelSubscription = can(actions.subscription.cancel);
        return (<><Button onClick={() => setCreateCompanyNoteModalOpen({ open: true })} size='small' disabled={!canCancelSubscription}>Cancel</Button>{createCompanyNoteModal}</>)
    } else {
        // TODO: - ADP Subscription Source and Apple IAP Subscription Source
        return null;
    }
}