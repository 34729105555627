import {FilterComponentProps} from "../../entities/TableColumnFactory";
import DropdownPicker, {DropdownPickerItem} from "../pickers/DropdownPicker";
import React, {useEffect, useMemo, useState} from "react";
import {useRepo} from "../../../repos/useRepo";
import { State } from "../../../models/State";

export default function SenderDropdown(props: FilterComponentProps<any>) {
    const { stateRepo } = useRepo();
    const [states, setStates] = useState<State[]>([]);

    useEffect(() => {
        stateRepo.list().then(setStates).catch(console.error);
    }, [stateRepo]);

    const options: DropdownPickerItem[] = useMemo(() => states.map(s => ({ id: s.code, name: s.name })), [states]);
    
    return (
        <DropdownPicker options={options} {...props}/>
    )
}