import _ from "lodash";
import { NavigationGroup, NavigationItem } from "../components/layout/SideNav";
import { RouteConfigs } from "./RouteConfigs";

type canType = (action: string) => boolean;

const isItemAllowed = (item: NavigationItem, can: canType) => {
  if( !item.route ) {
    return true;
  }

  const config = RouteConfigs[ item.route ];
  if( !config ) {
    return true;
  }

  const itemRequiredActions = config.requiredActions;
  return !itemRequiredActions || itemRequiredActions.some( action => can( action ) );
}

export const filterAllowedItems = (groups: NavigationGroup[], can: canType) => groups
  .map( group => ({
    ...group,
    items: group.items.filter( item => isItemAllowed(item, can) )
  }))
  .filter( ({ items }) => !_.isEmpty( items ) )