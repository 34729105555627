import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {useEffect, useState} from "react";
import {useRbac} from "@gsb/react-rbac";
import TabNavigation from "../../../tabs/TabNavigation";
import {Paper} from "@material-ui/core";
import {useRepo} from "../../../../repos/useRepo";
import {actions} from "../../../../rbac";
import LoadingIndicator from "../../../LoadingIndicator";
import {useNavigation} from "../../../../services/navigation/useNavigation";
import {Breadcrumbs} from "../../../layout/Breadcrumbs";
import {BreadcrumbFactory, TopicDetailBreadcrumbFactory} from "../../../../factories/BreadcrumbFactory";
import {Topic} from "../../../../models/ti/Topic";
import {UpdateTopicForm} from "./TopicForm";
import {useMemo} from "react";

interface Params {
    topicId: string;
}

interface Props extends RouteComponentProps<Params> {

}

export const requiredActions = [
  actions.topic.get,
];

function TopicDetailController(props: Props) {
    const [topic, setTopic] = useState<Topic>();
    const { topicRepo } = useRepo();

    const topicId = useMemo(() => props.match.params.topicId, [props.match.params.topicId]);

    const loadTopic = () => {
        topicRepo.getById(topicId).then(setTopic).catch(console.error);
    };

    useEffect(loadTopic, [topicRepo, topicId]);

    if (topic) {
        return <TopicDetail topic={topic} onTopicUpdated={setTopic}/>
    } else {
        return <LoadingIndicator/>
    }
}

function TopicDetail(props: { topic: Topic, onTopicUpdated: (topic: Topic) => void }) {
    const { topic, onTopicUpdated } = props;
    const { navigateToTopics } = useNavigation();

    const breadcrumbFactory: BreadcrumbFactory = new TopicDetailBreadcrumbFactory(topic, navigateToTopics);

    return (
        <div>
            <Breadcrumbs items={breadcrumbFactory.makeBreadcrumbItems()}/>

            <Paper>
                <TabNavigation items={[
                    {
                        name: 'Topic',
                        identifier: 'topics',
                        render: () => <UpdateTopicForm topic={topic} onTopicUpdated={onTopicUpdated} />
                    },
                ]}/>
            </Paper>
        </div>
    )
}

export default withRouter(TopicDetailController);