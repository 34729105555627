import React from "react";
import {Paper} from "@material-ui/core";
import {useRbac} from "@gsb/react-rbac";
import {actions} from "../../../rbac";
import TabNavigation, {TabNavigationItem} from "../../tabs/TabNavigation";
import MailchimpAccountTable from "./mailchimp/MailchimpTable";
import SendGridAccountTable from "./sendgrid/SendGridTable";

export const requiredActions = [
  actions.mailchimpAccount.list,
  actions.sendgridAccount.list
]

export function Integrations() {
    const { can } = useRbac();

    const canListMailchimpAccounts = can(actions.mailchimpAccount.list);
    const canListSendGridAccounts = can(actions.sendgridAccount.list);
    const shouldShowList = canListMailchimpAccounts || canListSendGridAccounts;

    const items: TabNavigationItem[] = [
        {
            name: "SendGrid",
            identifier: "sendgrid",
            render: () => <SendGridAccountTable/>,
            disabled: !canListSendGridAccounts
        },
        {
            name: "Mailchimp",
            identifier: "mailchimp",
            render: () => <MailchimpAccountTable/>,
            disabled: !canListMailchimpAccounts
        }
    ];

    if (shouldShowList) {
        return (
            <Paper>
                <TabNavigation items={items} disabledContentPadding/>
            </Paper>
        )
    } else {
        return null;
    }
}