import { createMuiTheme } from '@material-ui/core/styles';
import {blueGrey, grey} from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: grey[400],
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: blueGrey[500],
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff',
    },
    // error: will use the default color
  },
  overrides: {
    MuiButton: {
      textPrimary: {
        color: "#000000"
      },
    }
  }
});

export default theme;
// export default responsiveFontSizes(theme);


/*
$color-brand: rgb(232,68,71);

$color-background-gray: #fafafa;

$color-form-border: #e7e7e7;
$color-form-bg: #f8f8f8;

$color-background-gray-alt: #f9f9f9;

$color-text-gray: #a4a4a4;

$color-brand-red: #e84447;
$color-brand-red-border: #c82238;
$color-brand-red-disabled: #FD6B6F;
$color-brand-blue: #5bc0de;
$color-brand-blue-border: #46b8da;
$color-brand-blue-disabled: #85D5EC;
$color-brand-green: #2ccc71;
$color-brand-green-border: #1a9c52;
$color-brand-yellow: #f7ca18;
$color-brand-purple: #8e44ad;

$color-subtitle-text: #a9a9a9;
$color-rating-star-yellow: #ffc802;

 */
