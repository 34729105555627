import * as React from "react";
import {Subscription} from "../../../../../models/Subscription";
import {SubscriptionSourceDetail} from "./SubscriptionSourceDetail";
import SubscriptionDetailLicensesSection from "./SubscriptionDetailLicensesSection";
import {Divider} from "@material-ui/core";

interface Props {
    subscription: Subscription;
}

export default function SubscriptionDetail({ subscription }: Props) {
    return (
        <div style={{ width: '100%' }}>
            <SubscriptionSourceDetail subscription={subscription}/>

            {subscription.licenses && subscription.licenses.length > 0 && (
                <>
                    <Divider style={{ margin: 8 }}/>

                    <SubscriptionDetailLicensesSection licenses={subscription.licenses}/>
                </>
            )}
        </div>
    )
}
